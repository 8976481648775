import { memo, useEffect, useState } from "react"
import MappedUserListUI from "./MappedUserListUI"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../../store/actions/commonAction"

import { closeModal, openModal } from "../../../store/actions/modalAction"
import { Box, CircularProgress, IconButton } from "@mui/material"
import { useMemo } from "react"
import CreateController from "./MappedUserCreateController"
import { Delete } from "@mui/icons-material"

import { MODAL_KEYS } from "../../../utils/constants/modal.constant"
import MessageDilog from "../../../components/MessageDilog"

import { deleteOpenSolarIntegratedUserApi, getOpenSolarIntegratedUsersApi } from "../../../apis/opensolarIntegration.api"


const ActionComponent = memo(({ params, setParams, deleteApi }) => {
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)
    const deleteFun = async () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await deleteApi({ id: params._id }),
            (response) => {
                setParams({})
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
        dispatch(closeModal())
    }


    const onDelete = () => {
        dispatch(openModal(<MessageDilog onSubmit={deleteFun} title="Alert!" message={`Are you sure to remove "${params.user_id?.name}" ?`} />, "sm"))
    }


    return <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
        {loading && <CircularProgress color="primary" fontSize="inherit" />}
        {!loading && <IconButton disabled={loading} size="inherit" onClick={onDelete}>
            <Delete color="error" fontSize="inherit" />
        </IconButton>}
    </Box>
})


const MappedUserListController = ({ createModalKey = MODAL_KEYS.OPEN_SOLAR_INTEGRATION_USER }) => {
    const dispatch = useDispatch()


    const title = "Mapped User"

    const fetchApi = getOpenSolarIntegratedUsersApi
    const deleteApi = deleteOpenSolarIntegratedUserApi

    const columns = useMemo(() => [

        { id: 1, fieldName: 'user_id.name', label: 'CRM User', align: "left", sort: true,renderValue:(params)=>params?.user_id?.name },
        { id: 2, fieldName: 'open_solar_user_name', label: 'Open Solar Username', align: "left", sort: true },
        { id: 3, fieldName: 'open_solar_user_id', label: 'Open Solar User Id', align: "left" },



        {
            id: 5,
            fieldName: '',
            label: 'Action',

            align: "right",
            renderValue: (params, setParams) => <ActionComponent createModalKey={createModalKey} deleteApi={deleteApi} params={params} setParams={setParams} />,
        },

    ], [dispatch]);


    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 10,
        search: '',

        searchable: ['user_id.name'],
        sort: '',
        sortDirection: 1

    })





    // do not change 

    const [loading, setLoading] = useState(false)
    const [list, setList] = useState({})









    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchApi({ ...filters }),
            (response) => {
                setList(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }
    const onCreateBtnClick = () => {
        dispatch(openModal(<CreateController modalKey={createModalKey} callBack={async () => { fetchList() }} />, "sm", undefined, createModalKey))
    }



    useEffect(() => {
        fetchList()
    }, [filters])



    return (
        <>
            <MappedUserListUI

                title={title}
                onCreateBtnClick={onCreateBtnClick}

                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}
                columns={columns}


            />

        </>
    )
}
export default MappedUserListController