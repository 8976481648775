import { useEffect, useState } from "react";
import ListUi from "./MainUi";
import { useDispatch } from "react-redux";
import { callApiAction } from "../../../store/actions/commonAction";

import { checkAircallIntegration } from "../../../apis/aircallIntegration.api";

const MainController = ({}) => {
  const dispatch = useDispatch();

  const title = "Aircall Integration ";

  const checkAPi = checkAircallIntegration;

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",

    searchable: ["user_id.name"],
    sort: "",
    sortDirection: 1,
  });

  // do not change

  const [loading, setLoading] = useState(true);
  const [integrationDetails, setIntegrationList] = useState(null);

  const checkIntegration = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await checkAPi({ ...filters }),
        (response) => {
          setIntegrationList(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    checkIntegration();
  }, [filters]);

  return (
    <>
      <ListUi
        title={title}
        callBack={checkIntegration}
        filters={filters}
        setFilters={setFilters}
        loading={loading}
        integrationDetails={integrationDetails}
      />
    </>
  );
};
export default MainController;
