import { memo, useCallback, useEffect, useState } from "react"
import ListUi from "./ListUi"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { getCustomerApi } from "../../apis/user.api"
import { Link } from "react-router-dom"
import { openModal } from "../../store/actions/modalAction"
import { findObjectKeyByValue } from "../../utils/helpers/helper"
import { Box, Button, ButtonBase, Chip, Typography } from "@mui/material"
import { useMemo } from "react"
import fileDownload from "js-file-download"
import { MODAL_KEYS } from "../../utils/constants/modal.constant";

import LeadDetailedViewController from "../lead/detailed-view/LeadDetailedViewController"

const ProjectNo = memo(({ params }) => {
    const dispatch = useDispatch()
    const onClick = (id) => {
        dispatch(openModal(<LeadDetailedViewController project={id} />, 'xl', undefined, MODAL_KEYS.LEAD_DETAILS))
    }
    return (
    <>
        {params.projects.map((item) => (
            <Chip size="small" label={item.uid} onClick={()=>{
                onClick(item?._id)
            }} />
        ))}
      </>)
})


const ListController = () => {
    const dispatch = useDispatch()


    const title = "Customers"
    
    const fetchApi = getCustomerApi


    const columns = useMemo(() => [

        { id: 1, fieldName: 'f_name', label: 'Name', align: "left", sort: true,renderValue:(params)=> params.f_name +" "+ params.l_name },
        {
            id: 2, fieldName: 'email', label: 'Email', align: "left",
            minWidth: "250px",
        },
        {
            id: 3, fieldName: 'uid', label: 'Customer Id', align: "left",
            minWidth: "250px",
        },
        {
            id: 3, fieldName: 'phone', minWidth: "200px", label: 'Phone no.', align: "left", 
        },
        {
            id: 4, fieldName: 'projects', minWidth: "200px", label: 'Project Id', align: "left", 
             renderValue: (params, setParams) =>params.projects.map((item)=>item.uid) ?
             <ProjectNo params={params} /> : "NA"
        },
       
    ], [dispatch]);


    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 10,
        search: '',
        status:null,
        role: '',
        sort: 'createdAt',
        sortDirection: -1,
        searchable:['full_name','email','phone']

    })





    // do not change 

    const [loading, setLoading] = useState(false)
    const [exportLoading, setexportLoading] = useState(false)
    const [list, setList] = useState({})









    const fetchList = (isExport) => {

        if (!isExport) {
            setLoading(true)
            dispatch(callApiAction(
                async () => await fetchApi({ ...filters }),
                (response) => {
                    console.log("Responsoess",response);
                    setList(response)
                    setLoading(false)
                },
                (err) => {
                    setLoading(false)
                }
            ))
        } else {
            setexportLoading(true)
            dispatch(callApiAction(
                async () => await fetchApi({ ...filters, csv: true }),
                (response) => {
                    fileDownload(response, "customers.csv")
                    setexportLoading(false)
                },
                (err) => {
                    
                    setexportLoading(false)
                },
                true
            ))
        }
    }




    useEffect(() => {
        fetchList()
    }, [filters])

    const onExportBtnClick = () => { fetchList(true) }


    return (
        <>
            <ListUi
                title={title}
                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}
                columns={columns}

                onExportBtnClick={onExportBtnClick}
                exportLoading={exportLoading}
            />

        </>
    )
}
export default ListController