import * as React from 'react';

import MenuItem from '@mui/material/MenuItem';
import { Alert, AlertTitle, CircularProgress, Typography } from '@mui/material';
import { AddCircle, Refresh } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import MessageDilog from '../../../components/MessageDilog';
import { closeModal, openModal } from '../../../store/actions/modalAction';
import { MODAL_KEYS } from '../../../utils/constants/modal.constant';
import { callApiAction } from '../../../store/actions/commonAction';
import { addLeadInOpenSolarApi, refreshLeadInOpenSolarApi } from '../../../apis/lead.api';
import { callSnackBar } from '../../../store/actions/snackbarAction';
import { SNACK_BAR_VARIETNS } from '../../../utils/constants/constants';

const LeadRefreshFromOpenSolarButton = ({ openSolarProjectId, callBack = () => { } }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = React.useState(false)
    const submitFun = async () => {
        setLoading(true);
        dispatch(
            callApiAction(
                async () => await refreshLeadInOpenSolarApi({ open_solar_id: openSolarProjectId }),
                (response) => {
                    callBack()
                    dispatch(callSnackBar("Data refresh from open solar", SNACK_BAR_VARIETNS.suceess))
                    setLoading(false);
                },
                (err) => {

                    dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
                    setLoading(false);
                }
            )
        );
        dispatch(closeModal(MODAL_KEYS.LEAD_OPEN_SOLAR_MODAL));
    };

    const onCreateButtonClick = () => {
        dispatch(openModal(
            <MessageDilog
                modalKey={MODAL_KEYS.LEAD_OPEN_SOLAR_MODAL}
                onSubmit={() => { submitFun() }}
                title='Alert!'
                message="Are you sure to refetch data from open solar?"
            />,
            "sm",
            false,
            MODAL_KEYS.LEAD_OPEN_SOLAR_MODAL
        ))
    }



    return (
        <MenuItem onClick={onCreateButtonClick}>

            {loading ? <CircularProgress size={15} /> : <Refresh fontSize='inherit' />} &nbsp;Refresh</MenuItem>
    );
}
export default LeadRefreshFromOpenSolarButton