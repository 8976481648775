import { Grid } from "@mui/material"
import GoogleAddress from "../../../components/inputs/GoogleAddress"
import { TitleBox } from "../../../components/layouts/common/boxes"
import SelectDropDown from "../../../components/inputs/SelectDropDown"
import { PHASE_TYPE, PROPERTY_TYPE, ROOF_PITCH_TYPE, ROOF_TYPES, STOREY_TYPE, SWITCH_BOARD } from "../../../utils/constants/project.constant"
import { titleCase } from "../../../utils/helpers/helper"
import EnergyPartnerDropDown from "../../energy-partners/dropdown/EnergyPartnerDropDown"
import { ENERGY_PARTNER_TYPE } from "../../../utils/constants/energypartner.constant"
import CustomInput from "../../../components/inputs/CustomInput"

const SiteDetailComponent = ({ fields, setFields, loading }) => {

    return <TitleBox title="Site Details" >
        <GoogleAddress
            key={loading}
            onChange={(val) => {
                setFields({
                    ...fields,
                    err: '',                                        // ...val
                    postcode: val?.postCode,
                    country: val?.country,
                    state: val?.state,
                    locality: val?.locality,
                    address: val?.address,
                    lat: val?.lat,
                    lon: val?.lon,

                })
            }}
            defaultAddress={
                {
                    postCode: fields.postcode,
                    country: fields.country,
                    state: fields.state,
                    locality: fields.locality,
                    address: fields.address,
                    lat: fields.lat,
                    lon: fields.lon,
                }
            }

        />
        <Grid container spacing={2} mt={3} >

            <Grid item xs={6} >
                <SelectDropDown
                    disabled={loading}
                    title="Property Type"
                    value={fields.property_type}
                    onChange={(e) => setFields({ ...fields, err: '', property_type: e.target.value })}
                    data={Object.keys(PROPERTY_TYPE).map((item) => ({ value: PROPERTY_TYPE[item], label: titleCase(item) }))}
                />

            </Grid>
            <Grid item xs={6} >
                <SelectDropDown
                    disabled={loading}
                    title="No of Storey"
                    value={fields.no_of_storeys}
                    onChange={(e) => setFields({ ...fields, err: '', no_of_storeys: e.target.value })}
                    data={Object.keys(STOREY_TYPE).map((item) => ({ value: STOREY_TYPE[item], label: titleCase(item) }))}
                />

            </Grid>

            <Grid item xs={6} >
                <SelectDropDown
                    disabled={loading}
                    title="Roof Type"
                    value={fields.roof_type}
                    onChange={(e) => setFields({ ...fields, err: '', roof_type: e.target.value })}
                    data={Object.keys(ROOF_TYPES).map((item) => ({ value: item, label: ROOF_TYPES[item], itemProps: { sx: { fontWeight: [16, 19, 20, 21, 24].includes(Number(item)) ? 800 : "unset" } } }))}
                />

            </Grid>
            <Grid item xs={6} >
                <SelectDropDown
                    disabled={loading}
                    title="Roof Pitch"
                    value={fields.roof_pitch}
                    onChange={(e) => setFields({ ...fields, err: '', roof_pitch: e.target.value })}
                    data={Object.keys(ROOF_PITCH_TYPE).map((item) => ({ value: ROOF_PITCH_TYPE[item], label: titleCase(item) }))}
                />

            </Grid>

            <Grid item xs={6} >
                <SelectDropDown
                    disabled={loading}
                    title="Phase"
                    value={fields.phase}
                    onChange={(e) => setFields({ ...fields, err: '', phase: e.target.value })}
                    data={Object.keys(PHASE_TYPE).map((item) => ({ value: PHASE_TYPE[item], label: titleCase(item) }))}
                />

            </Grid>
            <Grid item xs={6} >
                <SelectDropDown
                    disabled={loading}
                    title="Switch Board"
                    value={fields.switch_board}
                    onChange={(e) => setFields({ ...fields, err: '', switch_board: e.target.value })}
                    data={Object.keys(SWITCH_BOARD).map((item) => ({ value: SWITCH_BOARD[item], label: titleCase(item) }))}
                />

            </Grid>


            <Grid item xs={6} >
                <CustomInput
                    disabled={loading}
                    value={fields.meter_identifier}
                    onChange={(e) => setFields({ ...fields, err: '', meter_identifier: e.target.value })}
                    type="text"
                    label={"Meter No."}
                    size="small"
                    margin="none"

                />
            </Grid>
            <Grid item xs={6} >
                <CustomInput
                    disabled={loading}
                    value={fields.nmi_no}
                    onChange={(e) => setFields({ ...fields, err: '', nmi_no: e.target.value })}
                    type="text"
                    label={"NMI No."}
                    size="small"
                    margin="none"

                />
            </Grid>

            <Grid item xs={6} >
                <EnergyPartnerDropDown

                    value={fields.e_distributor}
                    onChange={(val) => setFields({ ...fields, err: '', e_distributor: val ?? null })}
                    type={ENERGY_PARTNER_TYPE.DISTRIBUTOR}
                    postcode={fields.postcode}
                />
            </Grid>
            <Grid item xs={6} >
                <EnergyPartnerDropDown

                    value={fields.e_retailer}

                    onChange={(val) => setFields({ ...fields, err: '', e_retailer: val ?? null })}
                    type={ENERGY_PARTNER_TYPE.RETAILER}
                    postcode={fields.postcode}
                />
            </Grid>
        </Grid>
    </TitleBox>
}
export default SiteDetailComponent