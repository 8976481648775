import { memo, useEffect, useMemo, useState } from "react"
import useValidate from "../../../store/hooks/useValidator"
import CreateUi from "./CreateUi"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../../store/actions/commonAction"
import { closeModal, openModal } from "../../../store/actions/modalAction"
import { SNACK_BAR_VARIETNS, USER_ROLES } from "../../../utils/constants/constants"
import { addLeadApi } from "../../../apis/lead.api"
import { validateEmail, validatePhoneNumber } from "../../../utils/helpers/helper"
import { callSnackBar } from "../../../store/actions/snackbarAction"
import { PHASE_TYPE, PROPERTY_TYPE, ROOF_PITCH_TYPE, ROOF_TYPES, STOREY_TYPE, SWITCH_BOARD } from "../../../utils/constants/project.constant"
import moment from "moment"
import DuplicateLeadComponent from "../duplicate/DuplicateLeadComponent"
import { MODAL_KEYS } from "../../../utils/constants/modal.constant"
import { validateMeterNo, validateNMI } from "../../../utils/helpers/lead.helper"

const CreateController = ({ callBack }) => {
    const { user } = useSelector(state => state)
    const validate = useValidate()
    const dispatch = useDispatch()


    const title = "Lead "
    const createApi = addLeadApi


    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',

        customerDetails: {
            f_name: '',
            l_name: '',
            m_name: '',
            dob: null,
            phone: '',
            email: '',
            customer_id: null,
            customer_uid: null
        },
        partnerDetails: {
            f_name: '',
            l_name: '',
            m_name: '',
            dob: null,
            phone: '',
            email: '',
            customer_id: null,
            customer_uid: null
        },


        address: undefined,
        locality: undefined,
        country: undefined,
        state: undefined,
        postcode: undefined,
        lat: undefined,
        lon: undefined,

        property_type: PROPERTY_TYPE.RESIDENTIAL,
        roof_type: -1,
        meter_identifier: "",
        nmi_no: "",
        no_of_storeys: STOREY_TYPE.NONE,
        phase: PHASE_TYPE.NONE,
        switch_board: SWITCH_BOARD.NONE,
        roof_pitch: ROOF_PITCH_TYPE.NONE,
        e_distributor: null,
        e_retailer: null,


        source: null,
        step: null,
        tags: [],
        remarks: '',

        assigned_sales_person: user.data.role != USER_ROLES.sales ? null : user.data,
        schedule_call: true,
        scheduled_call_date: moment().add(2, "hours").toISOString()

    })
    const [alredyExistData, setAlreadyExistData] = useState([])




    const validationSchemaForCreate = useMemo(() => ([
        {
            value: fields.customerDetails,
            field: 'Customer Details',
            custom: () => {

                if (!fields.customerDetails.f_name || fields.customerDetails.f_name == '')
                    return "Customer first name is required."
                /* checking phone no */
                if ((!fields.customerDetails.email || fields.customerDetails.email?.trim() == '') && (!fields.customerDetails.phone || fields.customerDetails.phone?.trim() == '')) {
                    return "Enter atleast one field from email and phone"
                }
                if (fields.customerDetails.email != '' && !validateEmail(fields.customerDetails.email)) {
                    return "Email is invalid."
                }
                if (fields.customerDetails.phone && fields.customerDetails.phone.trim() != '') {
                    if (!validatePhoneNumber(fields.customerDetails.phone)) {
                        return "Phone no should be 10 letters & starts with 0"
                    }
                }
                return true
            }
        },

        {
            value: fields.partnerDetails,
            field: 'Partner Details',
            custom: () => {

                let partnerHasDetails = false
                for (let item in fields.partnerDetails) {
                    if (fields.partnerDetails[item] && fields.partnerDetails[item]?.trim?.() != '') {
                        partnerHasDetails = true
                        break;
                    }
                }
                if (partnerHasDetails) {

                    if (!fields.partnerDetails.f_name || fields.partnerDetails.f_name == '')
                        return "Customer first name is required."

                    if ((!fields.partnerDetails.email || fields.partnerDetails.email?.trim() == '') && (!fields.partnerDetails.phone || fields.partnerDetails.phone?.trim() == '')) {
                        return "Enter atleast one field from email and phone for partner"
                    }
                    if (fields.partnerDetails.email != '' && !validateEmail(fields.partnerDetails.email)) {
                        return "Partner email is invalid."
                    }
                    if (fields.partnerDetails.phone && fields.partnerDetails.phone.trim() != '') {
                        if (!validatePhoneNumber(fields.partnerDetails.phone)) {
                            return "Partner phone no should be 10 letters & starts with 0"
                        }
                    }
                }
                return true
            }
        },


        {
            value: fields.nmi_no,
            field: "NMI No",
            custom: () => {
                if (fields.nmi_no && fields.nmi_no != '')
                    return validateNMI(fields.nmi_no)

                return true
            }
        },
        {
            value: fields.meter_identifier,
            field: "Meter No",
            custom: () => {
                if (fields.meter_identifier && fields.meter_identifier != '')
                    return validateMeterNo(fields.meter_identifier)

                return true
            }
        },
        {
            required: true,
            value: fields.source,
            field: 'Source',
        },
        {
            required: true,
            value: fields.assigned_sales_person,
            field: 'Assigned To',
        },
        {
            required: true,
            value: fields.step,
            field: 'Step',
        },
    ]), [fields])





    const createFunction = async (forcefully) => {
        const validationResponse = validate(validationSchemaForCreate)

        if (validationResponse === true) {

            const dataToBeSend = {

            }
            const dataCopy = JSON.parse(JSON.stringify(fields))


            const contacts = [
                dataCopy.customerDetails
            ]
            if (dataCopy.partnerDetails?.f_name && dataCopy.partnerDetails?.f_name != '') {
                contacts.push(dataCopy.partnerDetails)
            }

            dataToBeSend['contacts'] = contacts
            delete dataCopy['customerDetails']
            delete dataCopy['partnerDetails']

            for (let item in dataCopy) {
                if (typeof dataCopy[item] === "object" && !Array.isArray(dataCopy[item])) {
                    dataToBeSend[item] = dataCopy[item]?._id
                } else
                    dataToBeSend[item] = dataCopy[item]
            }




            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await createApi({ ...dataToBeSend }),
                    async (response) => {
                        await callBack(response)
                        setLoading(false)
                        dispatch(closeModal())
                    },
                    (err, code, responseData) => {

                        setLoading(false)
                        setFields({ ...fields, err })

                        dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))

                        if (code === 406) {
                            // setAlreadyExistData(responseData)
                            dispatch(openModal(<DuplicateLeadComponent leads={responseData} onReassignLead={() => {
                                dispatch(closeModal())
                                dispatch(closeModal(MODAL_KEYS.DUPLICATE_LEAD))
                                callBack()
                            }} />, 'sm', true, MODAL_KEYS.DUPLICATE_LEAD))
                        } else {
                            // setAlreadyExistData([])
                        }
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }





    const onSubmit = async (e) => {
        e.preventDefault()

        createFunction()

    }


    return <CreateUi createFunction={createFunction} alredyExistData={alredyExistData} title={title} loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
}
export default memo(CreateController)