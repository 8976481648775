
import ProjectFilesUi from "./OpenSolarFilesUi"

import { useEffect, useState } from "react"

import { callApiAction } from "../../../../store/actions/commonAction"
// import { getProjectsFilesByIdApi } from "../../../apis/projects.api"
import { MODAL_KEYS } from "../../../../utils/constants/modal.constant"
import { useDispatch } from "react-redux"
import { MenuItem } from "@mui/material"
import { FolderCopy } from "@mui/icons-material"
import { openModal } from "../../../../store/actions/modalAction"
import { getOpenSoalrFilesFilesApi } from "../../../../apis/lead.api"

export const OpenSolarFilesViewButton = ({ openSolarFiles, setOpenSolarFiles, projectId }) => {
  const dispatch = useDispatch()
  const onClick = () => {
    dispatch(openModal(
      <OpenSolarFilesController
        openSolarFiles={openSolarFiles}
        setOpenSolarFiles={setOpenSolarFiles}
        projectId={projectId}

      />,
      "sm",
      undefined,
      MODAL_KEYS.OPEN_SOLAR_DOCS
    ))
  }

  return <MenuItem onClick={onClick}>

    <FolderCopy fontSize='inherit' /> &nbsp;Files</MenuItem>
}
const OpenSolarFilesController = ({ openSolarFiles, setOpenSolarFiles, projectId }) => {
  const dispatch = useDispatch()
  const modalKey = MODAL_KEYS.OPEN_SOLAR_DOCS
  const [loading, setLoading] = useState(false)
  const [files, setFiles] = useState(openSolarFiles ?? [])

  const fetchFilesProjectById = () => {
    setLoading(true)
    dispatch(callApiAction(
      async () => await getOpenSoalrFilesFilesApi({ id: projectId }),
      (response) => {
        setFiles(response)
        setOpenSolarFiles(response)
        setLoading(false)
      },
      (err) => {
        setLoading(false)
      }
    ))
  }
  useEffect(() => {

    if (files.length == 0)
      fetchFilesProjectById(projectId)

  }, [])


  return <>
    <ProjectFilesUi modalKey={modalKey} files={files} loading={loading} />
  </>
}
export default OpenSolarFilesController