import { Autocomplete, Box, FormControl, InputLabel, ListSubheader, MenuItem, Select, Typography } from "@mui/material"
import { Fragment, useMemo } from "react"
import { useSelector } from "react-redux"
import CustomInput from "../../../components/inputs/CustomInput"

const LeadStepDropDown = ({ value, onChange }) => {

    const { user } = useSelector((state) => state)

    const options = useMemo(()=>{
        return user.data?.pipelines?.lead.map((option) => {
            
            return option.steps?.map((step)=>({...step,pipeline:option?.title,}))
            
        })?.flat(1);
    },[user.data?.pipelines?.lead])


    return <Autocomplete
        size="small"
        id="grouped-demo"
        options={options.sort((a, b) => -b.pipeline.localeCompare(a.pipeline))}
        groupBy={(option) => option.pipeline}
        renderOption={(props, option) =><MenuItem {...props}>  <Box sx={{mr:1,height:"15px",width:"15px",borderRadius:"100%",background:option.color??"#000"}} ></Box> {option.title}</MenuItem>}
        getOptionLabel={(option) => option.title}
        fullWidth
        renderInput={(params) => <CustomInput {...params} label="Step*" size="small" />}
        defaultValue={value}
        onChange={onChange}
    />

 

}
export default LeadStepDropDown