import { storeRecording } from "./aircallIntegration.api";
import { schedulecall } from "./schedulecall.api";

const mode = "local";
let domain = process.env.REACT_APP_BASE_DOMAIN;
//'http://localhost:8000/'//

//process.env.REACT_APP_BASE_DOMAIN;

const endpoints = {
  root: `${domain}`,
  activityBase: `${domain}api/activity`,
  activityActiveStatus: `${domain}api/activity/active-status`,
  activityById: `${domain}api/activity/by-id`,
  activityByTitle: `${domain}api/activity/by-title`,
  activityPriority: `${domain}api/activity/priority`,

  attendenceBase: `${domain}api/attendence/`,
  attendenceStatus: `${domain}api/attendence/status`,

  bulkInvoice: `${domain}api/invoice/bulk`,

  chatBaseUrl: `${domain}api/chat`,
  chatFetch: `${domain}api/chat/chats`,
  chatGroupsBaseUrl: `${domain}api/chat/groups`,
  chatGrouypsById: `${domain}api/chat/groups/id`,

  complainBase: `${domain}api/complain/`,
  complainById: `${domain}api/complain/by-id`,
  complainCategory: `${domain}api/complain/category`,
  complainCounts: `${domain}api/complain/counts`,
  complainNotes: `${domain}api/complain/note`,

  companyBase: `${domain}api/company`,
  companyById: `${domain}api/company/by-id`,

  createInvoiceFromProject: `${domain}api/invoice/create-from-project`,

  customerBase: `${domain}api/customer/`,

  domain: `${domain}`,
  emailList: `${domain}api/email`,

  expenseBase: `${domain}api/finance/expenses`,
  expenseById: `${domain}api/finance/expenses/by-id`,
  expenseFromPreDefinedExpenseBase: `${domain}api/finance/create-from-pre-exp`,
  expenseSuggetionBase: `${domain}api/finance/expenses/suggestion`,

  energyPartnerBase: `${domain}api/energy-partners`,
  energyPartnerById: `${domain}api/energy-partners/by-id`,
  energyPartnerPostCode: `${domain}api/energy-partners/postcode`,
  energyPartnerSearchByPostCode: `${domain}api/energy-partners/from-postcode`,

  fbIntegrationById: `${domain}api/fb-integration/by-id`,
  fbIntegrationsBase: `${domain}api/fb-integration/`,

  financeBase: `${domain}api/finance/`,
  financeById: `${domain}api/finance/id/`,
  financeCounts: `${domain}api/finance/counts`,

  fileBase: `${domain}api/file`,
  fileFile: `${domain}api/file/file`,
  fileImage: `${domain}api/file/image`,

  getautoComplete: `${domain}api/location/autocomplete`,
  getlatandLon: `${domain}api/location/lat-lon`,

  holiday: `${domain}api/holiday`,

  installerUserBase: `${domain}api/user/installer/`,
  installerUserById: `${domain}api/user/installer/by-id`,

  invoiceAnanalysis: `${domain}api/invoice/analysis/`,
  invoiceBase: `${domain}api/invoice/`,
  invoiceById: `${domain}api/invoice/id/`,
  invoiceDownload: `${domain}api/invoice/download/`,
  invoiceOverAllSummary: `${domain}api/invoice/all-summary`,
  invoiceRemarks: `${domain}api/invoice/remarks`,
  invoiceSummary: `${domain}api/invoice/summary`,

  jobToStc: `${domain}api/projects/send-job-stc`,

  latestVersions: `${domain}api/version/latest`,

  leadBase: `${domain}api/lead/`,
  leadById: `${domain}api/lead/by-id`,
  leadActivityLogsBase: `${domain}api/lead/activity-logs`,
  leadSourceUpdate: `${domain}api/lead/source`,
  leadContactsUpdate: `${domain}api/lead/contacts`,
  leadSiteDetailsUpdate: `${domain}api/lead/site-details`,
  leadLastUpdatedDateUpdate: `${domain}api/lead/date`,
  leadSalesPersonUpdate: `${domain}api/lead/sales-person`,
  leadUIDUpdate: `${domain}api/lead/uid`,
  leadStepUpdate: `${domain}api/lead/step`,
  leadTagsUpdate: `${domain}api/lead/tags`,
  leadSearch: `${domain}api/lead/search`,
  leadStatusUpdate: `${domain}api/lead/status`,
  leadPipelineCounts: `${domain}api/lead/pipeline-view`,
  leadIndividualSalesReport: `${domain}api/lead/individual-sales-report`,


  leadOpenSolarBase: `${domain}api/lead/open-solar`,
  leadOpenSolarFiles: `${domain}api/lead/open-solar/files`,

  leadSalesReport: `${domain}api/lead/report/sales-report`,
  leadSourceReport: `${domain}api/lead/report/source`,
  leadStateReport: `${domain}api/lead/report/state`,
  leadRejectionReasonReport: `${domain}api/lead/report/rejection-reason`,

  leadAnalysisBySource: `${domain}api/lead/analysis-by-source`,
  leadAnalysisBySourceAndSalesPerson: `${domain}api/lead/analysis-by-source-sales-person`,
  leadAnalysisByStatusesAndSalesPerson: `${domain}api/lead/analysis-by-status-sales-person`,
  leadAnalysisByStatusesAndSource: `${domain}api/lead/analysis-by-status-source`,
  leadAnalysisByStatus: `${domain}api/lead/analysis-by-status`,


  leadCallBase: `${domain}api/lead/calls`,

  

  leadUnhandled: `${domain}api/lead/unhandled`,
  leadUpdateDate: `${domain}api/lead/date-update`,

  leadFormBase: `${domain}api/lead-form/`,
  leadFormById: `${domain}api/lead-form/by-id`,

  loanBenefitsBase: `${domain}api/loanbenefits/`,
  loanbenefitById: `${domain}api/loanbenefits/by-id`,

  messageBase: `${domain}api/message/`,
  messagesConversation: `${domain}api/message/conversation`,
  messagesList: `${domain}api/message/sms`,
  messagesMobiles: `${domain}api/message/mobile`,

  moduleBase: `${domain}/api/modules`,
  moduleFetch: `${domain}api/modules/fetch`,
  moduleFetchById: `${domain}api/modules/by-id`,
  moduleUpdate: `${domain}api/modules/update`,
  moduleUpload: `${domain}api/modules/upload`,

  notificationFetch: `${domain}api/notification`,

  paypendingPayments: `${domain}api/invoice/close-payment`,

  pendingPayments: `${domain}api/invoice/pending-payments`,

  preDefinedExpenseBase: `${domain}api/finance/predefined-expenses`,

  projectAvailableDocumentTypes: `${domain}api/projects/available-documets-type`,
  projectBase: `${domain}api/projects/`,
  projectById: `${domain}api/projects/id/`,
  projectByContact: `${domain}api/projects/contact/`,
  projectCounts: `${domain}api/projects/counts`,
  projectDocumentBase: `${domain}api/projects/documets`,
  projectFiles: `${domain}api/projects/files`,
  projectFlag: `${domain}api/projects/flag`,
  projectJobPackDownload: `${domain}api/projects/download-jobpack`,
  projectLogBase: `${domain}api/projects/logs/`,
  projectNoteCreate: `${domain}api/projects/notes/`,
  projectNoteFavorite: `${domain}api/projects/notes/favorite`,
  projectOrdersBase: `${domain}api/projects/order`,
  projectOrdersRecieve: `${domain}api/projects/order-recieve`,
  projectPOId: `${domain}api/projects/po`,
  projectSelectSystem: `${domain}api/projects/select-system`,
  projectStock: `${domain}api/projects/stock`,
  projectStockItems: `${domain}api/projects/stock-items`,
  projectSystems: `${domain}api/projects/systems`,
  projectUpdateInstaller: `${domain}api/projects/installer`,

  pipelinBase: `${domain}api/pipeline`,
  pipelinById: `${domain}api/pipeline/by-id`,
  pipelinDefault: `${domain}api/pipeline/default`,
  pipelinStepBase: `${domain}api/pipeline/step`,
  pipelinUserBase: `${domain}api/pipeline/users`,

  preDefinedTagsCategoryBase: `${domain}api/predefined-tag/category`,
  preDefinedTagsCategoryBaseById: `${domain}api/predefined-tag/category/by-id`,
  preDefinedTagsAdd: `${domain}api/predefined-tag/add-tag`,
  preDefinedTagsRemove: `${domain}api/predefined-tag/remove-tag`,

  productBase: `${domain}api/product/`,
  productCSVBase: `${domain}api/product/csv`,
  productById: `${domain}api/product/by-id`,
  
  productCategoryBase: `${domain}api/product/category/`,
  productCategoryById: `${domain}api/product/category/by-id`,

  rejectPendingPayments: `${domain}api/invoice/reject-payment`,

  rejectionReasons: `${domain}api/rejection-reasons/`,

  resetPassword: `${domain}api/auth/reset-password`,
  resetToken: `${domain}api/auth/reset-token`,

  salesPersonCapabilityBase: `${domain}api/sales-person-capability/`,
  salesPersonCapabilityById: `${domain}api/sales-person-capability/by-id`,

  scheduledcall: `${domain}api/scheduled-call`,
  scheduledcallcounts: `${domain}api/scheduled-call/count`,

  secretsBase: `${domain}api/secrets/`,
  secretsById: `${domain}api/secrets/by-id`,

  signIn: `${domain}api/auth/login`,

  sort: this,

  sourceCapabilityBase: `${domain}api/source-capability/`,
  sourceCapabilityById: `${domain}api/source-capability/by-id`,

  sourcesBase: `${domain}api/sources/`,
  sourcesById: `${domain}api/sources/by-id`,

  supportEmailBase: `${domain}api/user/supporting-emails/`,
  supportEmailByUser: `${domain}api/user/supporting-emails-by-user/`,

  taskBase: `${domain}api/task/`,
  taskById: `${domain}api/task/by-id`,
  taskStatus: `${domain}api/task/status`,

  teamBase: `${domain}api/team/`,
  teamById: `${domain}api/team/by-id`,

  templateBase: `${domain}api/template/`,
  templateFetch: `${domain}api/template/fetch`,
  templateFetchByid: `${domain}api/template/by-id`,
  templateUpdate: `${domain}api/template/update`,

  termsAndConditionBase: `${domain}api/terms-condition/`,
  termsAndConditionById: `${domain}api/terms-condition/by-id`,

  userAndSystemFetchByToken: `${domain}api/user/fetch-by-token`,
  userBase: `${domain}api/user/`,
  userById: `${domain}api/user/fetch-by-id`,
  userDeleteUndo: `${domain}api/user/undo-delete`,
  userPassword: `${domain}api/user/reset-password`,

  verifypendingPayments: `${domain}api/invoice/verify-payment`,

  versions: `${domain}api/version`,

  whiteListedBase: `${domain}api/white-listed/`,
  whiteListedById: `${domain}api/white-listed/by-id`,

  openSolarBase: `${domain}api/open-solar/`,
  checkOpenSolarIntegration: `${domain}api/open-solar/check`,
  openSolarIntegratedUser: `${domain}api/open-solar/integrated-user`,
  openSolarSignIn: `${domain}api/open-solar/sign-in`,

  aircallBase: `${domain}api/aircall/`,
  checkAircallIntegration: `${domain}api/aircall/check`,
  aircallIntegratedUser: `${domain}api/aircall/integrated-user`,
  aircallSignIn: `${domain}api/aircall/sign-in`,
  storeRecording: `${domain}api/aircall/store-recording`,
  unknownCallLog: `${domain}api/aircall/unknown-calllog`,

  announcementBase: `${domain}api/announcement/create`,
  announcementFetch: `${domain}api/announcement/fetch`,
  announcementFetchByid: `${domain}api/announcement/by-id`,
  AnnouncementDelete: `${domain}api/announcement/delete`,

  blogBase: `${domain}api/blog`,
  blogFetch: `${domain}api/blog/fetch`,
  blogFetchByid: `${domain}api/blog/by-id`,
  blogDelete: `${domain}api/blog/delete`,

  OfficesBase: `${domain}api/offices`,
  OfficesById: `${domain}api/offices/by-id`,
  OfficesBase: `${domain}api/offices`,
  OfficesById: `${domain}api/offices/by-id`,

  ratingsBase: `${domain}api/rating`,
  ratingsById: `${domain}api/rating/by-id`,
  ratingsBase: `${domain}api/rating`,
  ratingsById: `${domain}api/rating/by-id`,

  supplierBase: `${domain}api/supplier/`,
  supplierFetch: `${domain}api/supplier/fetch`,
  supplierFetchByid: `${domain}api/supplier/by-id`,
  supplierUpdate: `${domain}api/supplier/update`,
  supplierDelete: `${domain}api/supplier/delete`,
  isCompanySupplier: `${domain}api/supplier/company-supplier`,
  supplierDeleteUndo: `${domain}api/supplier/undo-delete`,

  installerBase: `${domain}api/installer/`,
  installerFetch: `${domain}api/installer/fetch`,
  installerFetchByid: `${domain}api/installer/by-id`,
  installerUpdate: `${domain}api/installer/update`,
  installerDelete: `${domain}api/installer/delete`,
  installerDeleteUndo: `${domain}api/installer/undo-delete`,
  installerPassword: `${domain}api/installer/reset-password`,
  installerupdateField:`${domain}api/installer/installer-field`,

  aggrementBase: `${domain}api/aggrement`,
  aggrementById: `${domain}api/aggrement/by-id`,
  aggrementActive: `${domain}api/aggrement/active`,
  NotesTypeBase: `${domain}api/notes-type/`,
  NotesTypeFetch: `${domain}api/notes-type/fetch`,
  NotesTypeDelete: `${domain}api/notes-type/delete`,
  showInstallerUpdate: `${domain}api/notes-type/show`,

  emailListening: `${domain}api/email/listen`,
  emailIntegration: `${domain}api/email/creds`,

  favouriteActivity:`${domain}api/lead/favourite`,
  favouriteLead:`${domain}api/lead/favourite-lead`,

  unassignLead:`${domain}api/lead/unassign-lead`,

  CompanyCertificateBase:`${domain}api/certificate/`,
  CompanyCertificateById:`${domain}api/certificate/by-id`

};

export default endpoints;
