import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

import { Avatar, Badge, Box, IconButton, List,  ListItemButton,  ListSubheader } from '@mui/material';
import { NotificationsNone } from '@mui/icons-material';
import { center } from '../../assets/css/theme/common';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNotificationAction, readNotificationAction } from '../../store/actions/notificationAction';
import moment from 'moment';
import { openModal } from '../../store/actions/modalAction';
import { NOTIFICATION_TYPE } from '../../utils/constants/constants';
import DetailedViewController from '../lead/detailed-view/LeadDetailedViewController';
import ProjectDetailsController from '../projects/projectdetails/ProjectDetailsController';
import { MODAL_KEYS } from '../../utils/constants/modal.constant';


export default function NotificationModel() {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const dispatch = useDispatch()
    const { notifications } = useSelector(state => state)
    const handleClick = (event) => {
        dispatch(readNotificationAction())
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const onNotificationButtonClick = React.useCallback((type, id) => {
        if (type == NOTIFICATION_TYPE.lead) {

            dispatch(openModal(<DetailedViewController id={id} />, 'md', undefined, MODAL_KEYS.LEAD_DETAILS))
        }
        if (type == NOTIFICATION_TYPE.project) {

            dispatch(openModal(<ProjectDetailsController project={id} />, 'xl', undefined, MODAL_KEYS.PROJECT))
        }

    }, [dispatch])
    React.useEffect(() => {

        dispatch(fetchNotificationAction())

        let interval = setInterval(() => {
            dispatch(fetchNotificationAction())
        }, (1000 * 60 * 5))
        return () => {
            clearInterval(interval)
        }
    }, [])

    return (
        <>

            <IconButton aria-describedby={id} sx={{ mr: 1, ml: 1 }} onClick={handleClick}>
                {notifications.new && notifications.new != 0 ? <Badge color="error" overlap="circular" badgeContent={notifications.new && notifications.new != 0 ? notifications.new : ""} variant={notifications.new && notifications.new != 0 ? '' : "dot"} >
                    <NotificationsNone />
                </Badge> : <NotificationsNone />}

            </IconButton>
            <Popover
                // sx={(theme) => ({ marginTop: theme.spacing(3) })}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}

            >
                <List

                    sx={{ width: '380px', maxWidth: '100%', bgcolor: 'background.paper' }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader component="div" variant='subtitle1' sx={(theme) => ({ color: theme.palette.text.primary })} id="nested-list-subheader">

                            Notifications

                        </ListSubheader>
                    }
                >
                    {
                        notifications && notifications.data && Array.isArray(notifications.data) && notifications.data.length > 0 ?

                            notifications.data.map((notification) => (
                                // <Link onClick={handleClose} key={notification._id} to={notification.redirect ? notification.redirect : ''}>


                                // </Link>
                                <ListItemButton disableTouchRipple key={notification._id} sx={{ borderTop: "1px solid lightgray" }} onClick={()=>{onNotificationButtonClick(notification.notificationType,notification?.contentId)}} >

                                    <Avatar sx={{ mr: 3 }} src={notification.senderId && notification.senderId.profile_url}>
                                        {notification.senderId && notification.senderId.name && notification.senderId.name[0]}
                                    </Avatar>
                                    <Box sx={{ flexDirection: "column", ...center, alignItems: "flex-start" }}>
                                        <Typography variant='subtitle1' color={'text.primary'} sx={{ lineHeight: "100%" }}>{notification.senderId && notification.senderId.name}</Typography>
                                        <Typography variant='subtitle2' color={"text.primary"} sx={{ mt: 0 }}>{moment(notification.createdAt).format("DD-MM-YYYY HH:mm")}</Typography>
                                        <Typography variant='subtitle2' color={"text.primary"} sx={{ mt: 0 }}>{notification.message}</Typography>
                                    </Box>

                                </ListItemButton>
                            ))
                            : <Typography variant='body2' mb={3} color='grey' align="center">No Notification</Typography>
                    }




                </List>
            </Popover>
        </>
    );
}