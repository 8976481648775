import { FileCopyOutlined } from "@mui/icons-material"
import { FileViewrBox } from "../../components/inputs/FileInput"
import CustomDialog from "../../components/layouts/common/CustomDialog"
import { Box, ButtonBase, Typography } from "@mui/material"
import DownloadDemoCSVButton from "../../components/layouts/common/DownloadDemoCSvButton"
import { downloadProductDemoCSV } from "../../apis/product.api"

const UploadCsvUI = ({ product_category, modalKey, onSubmit, loading, fields, setFields }) => {



    return <CustomDialog
        id={modalKey}
        err={fields.err}
        title="import CSV"
        loading={loading}
        onSubmit={onSubmit}
        confirmText="Upload Data"
    >
        <Box display="flex" mb={3}>
            <Box>
                <DownloadDemoCSVButton title="Download Demo" lazyFun={downloadProductDemoCSV} />
            </Box>
        </Box>

        <FileViewrBox p={2}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h6"><FileCopyOutlined fontSize="inherit" /></Typography>
                <Typography variant="body1" ml={2}>Upload CSV</Typography>
            </Box>
            <ButtonBase sx={{ opacity: 0, position: "absolute", top: "0px", left: "0px", height: "100%", width: "100%", zIndex: 11 }} >
                <input type="file" style={{ opacity: 0, height: "100%", width: "100%", cursor: "pointer" }} accept=".xlsx, .xls, .csv" onChange={(e) => { setFields({ ...fields, file: e?.target?.files?.[0] }) }} />
            </ButtonBase>
        </FileViewrBox>

        {fields.file && <Box p={1}>
            <Typography variant="caption" fontWeight={600} ml={2}>Selected File: </Typography>        <Typography variant="caption" ml={2}> {fields.file?.name}</Typography>
        </Box>}

    </CustomDialog>
}
export default UploadCsvUI