import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Typography,
} from "@mui/material";
import { memo } from "react";
import { useSelector } from "react-redux";

import CustomInput from "../../components/inputs/CustomInput";
import { USER_ROLES } from "../../utils/constants/constants";
import {
  findNameByRole,
  titleCase,
  toTitleCase,
} from "../../utils/helpers/helper";

import CustomDialog from "../../components/layouts/common/CustomDialog";
import { CenteredBox } from "../../components/layouts/OneViewBox";

import InstallerFields from "../installer/InstallerFields";
import getTeamApi from "../../apis/team.api";
import FileInput from "../../components/inputs/FileInput";
import CustomEditor from "../../components/inputs/CustomEditor";
import ImageComponent from "../../components/ImageComponent";
import { Link } from "react-router-dom";
import SubmitButton from "../../components/button/SubmitButton";
import { AddCircleOutline } from "@mui/icons-material";

const CreateRatingsUi = ({
  modalKey,
  params,
  title,
  isInstaller,
  isUpdate,
  fields,
  setFields,
  loading,
  onSubmit,
  placeholder,
}) => {
  const { user } = useSelector((state) => state);

  let allowedRoles = [
    ...Object.keys(USER_ROLES).map((key) => ({
      label: titleCase(key),
      _id: USER_ROLES[key],
    })),
  ];

  if (user.data.role === USER_ROLES.partner_admin) {
    allowedRoles = [];
    Object.keys(USER_ROLES).forEach((key) => {
      if (USER_ROLES[key] != USER_ROLES.admin)
        allowedRoles.push({ label: titleCase(key), _id: USER_ROLES[key] });
    });
  }

  return (
    <>
      <CustomDialog
        id={modalKey}
        loading={loading}
        err={fields.err}
        onSubmit={onSubmit}
        title={`${isUpdate ? "Update" : "Add"} ${title}`}
        closeText="Close"
        confirmText={`${isUpdate ? "Update" : "Add"}`}
      >
        {loading ? (
          <CenteredBox>
            <CircularProgress />
          </CenteredBox>
        ) : (
          <>
            <Grid container spacing={2} columnSpacing={5}>
              <Grid item xs={12}>
                {/* <CustomInput
                  autoFocus={true}
                  disabled={loading}
                  value={fields.name}
                  onChange={(e) =>
                    setFields({
                      ...fields,
                      err: "",
                      company_id: e.target.value,
                    })
                  }
                  type="text"
                  label={"Company Name*"}
                /> */}
                <CustomInput
                    disabled={loading}
                    value={fields.platform_url}
                    onChange={(e) =>
                      setFields({
                        ...fields,
                        err: "",
                        platform_url: e.target.value,
                      })
                    }
                    type="text"
                    label={"Platform Url*"}
                  />
                 {
                  <CustomInput
                    disabled={loading}
                    value={fields.platform}
                    onChange={(e) =>
                      setFields({
                        ...fields,
                        err: "",
                        platform: e.target.value,
                      })
                    }
                    type="tel"
                    label={"Platform Name*"}
                  />
                }
                <CustomInput
                  disabled={loading}
                  value={fields.total_rating}
                  onChange={(e) =>
                    setFields({
                      ...fields,
                      err: "",
                      total_rating: e.target.value,
                    })
                  }
                  type="Number"
                  label={"Platform Total Ratings*"}
                />

                {
                  <CustomInput
                    disabled={loading}
                    value={fields.rating}
                    onChange={(e) =>
                      setFields({
                        ...fields,
                        err: "",
                        rating: e.target.value,
                      })
                    }
                    type="Number    "
                    label={"Platform Rating*"}
                  />
                }
               
                 <FileInput
                  onlyImage={true}
                  defaults={fields.logo ? [fields.logo] : []}
                  title="Platform Logo"
                  subTitle="Only .png with aspect ratio of 13/6"
                  accept=".png"
                  onChange={(newUrl) => {
                    setFields({ ...fields, logo: newUrl });
                  }}
                />
                {/* {
                  <CustomInput
                    disabled={loading}
                    value={fields.ratings}
                    onChange={(e) =>
                      setFields({
                        ...fields,
                        err: "",
                        ratings: e.target.value,
                      })
                    }
                    type="tel"
                    label={"Company Rating*"}
                  />
                } */}
              </Grid>
            </Grid>

            {/* </Box> */}
          </>
        )}
      </CustomDialog>
    </>
  );
};
export default memo(CreateRatingsUi);
