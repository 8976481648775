import { Edit, MyLocation } from "@mui/icons-material"
import { Box, IconButton, Paper, Typography } from "@mui/material"
import { memo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { openModal } from "../../../store/actions/modalAction"
import { MODAL_KEYS } from "../../../utils/constants/modal.constant"
import UpdateLeadSourceController from "../update/source/UpdateLeadSourceController"
import { moduleAccessCondition } from "../../../utils/helpers/helper"
import MODULES from "../../../utils/constants/module.constants"

const LeadSourceComponent = ({ projectId, onSourceChange = () => { }, sourceId, name, size, editable }) => {
    const dispatch = useDispatch()
    const { user } = useSelector(state => state)
    const isEditable = editable && moduleAccessCondition(user, [MODULES.LEAD_ADMIN])

    const onClick = () => {
        if (isEditable) {
            dispatch(openModal(<UpdateLeadSourceController id={projectId} callBack={onSourceChange} source={{
                _id: sourceId,
                name: name
            }} />, 'xs', undefined, MODAL_KEYS.LEAD_UPDATE_SOURCE))
        }
    }
    if (size == "large")
        return (
            <Box >
                <Box display="flex" justifyContent="space-between" alignItems="center" >
                    <Typography lineHeight="100%" variant="body1" display="flex" alignItems="center" mb={2}>
                        <MyLocation fontSize="inherit" />&nbsp;Lead Source
                    </Typography>
                  {isEditable &&  <IconButton size="small" onClick={onClick} >
                        <Edit fontSize="inherit" />
                    </IconButton>}
                </Box>



                <Paper variant="outlined" component={Box} p={2} >
                    <Typography variant="body2" >
                        {name}
                    </Typography>
                </Paper>
            </Box>
        )

    return <Typography variant="body2"  >
        {name}
    </Typography>
}
export default memo(LeadSourceComponent)