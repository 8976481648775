import { actions } from "../../utils/constants/constants";

/*
{
[predefined_tag_type]:[
{
category_title:
tags:[]
}

]
}
*/
const initialState = {


}
const preDefinedTagsReducer = (state = initialState, action) => {

    switch (action.type) {

        case actions.ADD_TAG_DATA: {
            return { ...state, [action.tagType]: action.value }
        };

        default: return { ...state }
    }

}
export default preDefinedTagsReducer