import axios from "axios";
import { getHeaders } from "../utils/helpers/helper";
import endpoints from "./endpoints";

export const getAircallIntegratedUsersApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.aircallIntegratedUser,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const getAircallUserApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.aircallBase,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const addAircallIntegratedUserApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.aircallIntegratedUser,
    method: "POST",
    headers: getHeaders(),

    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const deleteAircallIntegratedUserApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.aircallIntegratedUser,
    method: "DELETE",
    headers: getHeaders(),

    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const signInAircallUser = async (data) => {
  console.log(data);
  const callResponse = await axios({
    url: endpoints.aircallSignIn,
    method: "POST",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};
export const checkAircallIntegration = async (data) => {
  const callResponse = await axios({
    url: endpoints.checkAircallIntegration,
    method: "GET",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const storeRecording = async (data) => {
  console.log(data);
  const callResponse = await axios({
    url: endpoints.storeRecording,
    method: "POST",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const createUnknownCallLog = async (data) => {
  const callResponse = await axios({
    url: endpoints.unknownCallLog,
    method: "POST",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
}
