import { Autocomplete, Box, CircularProgress } from "@mui/material";

import { Fragment, memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import CustomInput from "../../components/inputs/CustomInput";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import { CenteredBox } from "../../components/layouts/OneViewBox";
import { AUS_STATES } from "../../utils/constants/constants";
import FileInput from "../../components/inputs/FileInput";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { TroubleshootOutlined } from "@mui/icons-material";

const CreateUi = ({
  title,
  modalKey,
  isUpdate,
  fields,
  setFields,
  loading,
  onSubmit,
}) => {
  return (
    <>
      <CustomDialog
        loading={loading}
        id={modalKey}
        err={fields.err}
        onSubmit={onSubmit}
        title={`${isUpdate ? "Update" : "Create"} ${title}`}
        closeText="Close"
        confirmText={`${isUpdate ? "Update" : "Create"}`}
      >
        {loading ? (
          <CenteredBox>
            <CircularProgress />
          </CenteredBox>
        ) : (
          <>
            <CustomInput
              autoFocus={true}
              disabled={loading}
              value={fields.title}
              onChange={(e) =>
                setFields({ ...fields, err: "", title: e.target.value })
              }
              type="text"
              label={"Title*"}
            />

            <DesktopDatePicker
              label="Date"
              inputFormat="DD/MM/yyyy"
              renderInput={(props) => <CustomInput {...props} />}
              value={fields.date ? moment(fields.date) : null}
              onChange={(e) =>
                setFields({ ...fields, err: "", date: e.valueOf() })
              }
            />

            <DesktopDatePicker
              label="Expiry Date"
              inputFormat="DD/MM/yyyy"
              renderInput={(props) => <CustomInput {...props} />}
              value={fields.expiry_date ? moment(fields.expiry_date) : null}
              onChange={(e) =>
                setFields({ ...fields, err: "", expiry_date: e.valueOf() })
              }
            />

            <Box mt={3} mb={3}></Box>
            <FileInput
              defaults={fields.aggrement_file ? [fields.aggrement_file] : []}
              title="Agreement File*"
              subTitle="Only .pdf  less than 2mb are valid."
              accept=".pdf"
              onChange={(newUrl) => {
                setFields({ ...fields, aggrement_file: newUrl });
              }}
            />
          </>
        )}
      </CustomDialog>
    </>
  );
};
export default memo(CreateUi);
