import { memo, useEffect, useMemo, useState } from "react"
import useValidate from "../../../../store/hooks/useValidator"

import { useDispatch } from 'react-redux'
import { callApiAction } from "../../../../store/actions/commonAction"
import { closeModal } from "../../../../store/actions/modalAction"


import {  updateLeadLastUpdatedDateApi } from "../../../../apis/lead.api"

import LeadLastUpdateDateUI from "./LeadLastUpdateDateUI"
import { callSnackBar } from "../../../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../../../utils/constants/constants"
import { MODAL_KEYS } from "../../../../utils/constants/modal.constant"

const LeadLastUpdateDateController = ({ callBack = () => { }, id,title="Last Movement Date ", date_field, date = null }) => {
    const validate = useValidate()
    const dispatch = useDispatch()

    const modalKey = MODAL_KEYS.LEAD_UPDATE_MODAL
    // const title = "Last Movement Date "
    const updateStatusApi = updateLeadLastUpdatedDateApi

    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',
        id,
        date,
        date_field
    })



    const validationSchemaForStatusChange = useMemo(() => ([
        {
            required: true,
            value: fields.date,
            field: 'Date',
        },
    ]), [fields])





    const submitFun = async () => {
        const validationResponse = validate(validationSchemaForStatusChange)

        if (validationResponse === true) {

            const passData = { ...fields }


            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await updateStatusApi(passData),
                    async (response) => {

                        await callBack(response)
                        setLoading(false)
                        dispatch(callSnackBar("Lead updated successfully", SNACK_BAR_VARIETNS.suceess))
                        dispatch(closeModal(modalKey))
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }




    const onSubmit = async (e) => {
        e.preventDefault()
        submitFun()


    }




    return <LeadLastUpdateDateUI title={title} isUpdate={id} modalKey={modalKey} loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
}
export default memo(LeadLastUpdateDateController)