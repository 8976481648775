import {
  CircularProgress,
  Box,
  Paper,
  IconButton
} from "@mui/material";
import { memo, useEffect, useRef, useState } from "react";
import CustomInput from "../../components/inputs/CustomInput";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import { CenteredBox } from "../../components/layouts/OneViewBox";

import CustomEditor from "../../components/inputs/CustomEditor";
import { Description, PlaylistAddCircle } from "@mui/icons-material";
import { EMAIL_TEMPLATE_TYPE, TEMPLATE_VARIABLES } from "../../utils/constants/emailTemplate.constant";
import { convertHTMLVariableToStringVariable, reventToHTMLVariableFromStringVariable, titleCase } from "../../utils/helpers/helper";
import SelectDropDown from "../../components/inputs/SelectDropDown";
import { getVariableAccordingToTemplate } from "../../utils/helpers/emailTemplate.hepler";

const CreateUi = ({
  title,
  isUpdate,
  fields,
  setFields,
  loading,
  onSubmit,
}) => {
  const editorRef = useRef(null);



  return (
    <>
      <CustomDialog
        loading={loading}
        err={fields.err}
        onSubmit={onSubmit}
        title={`${isUpdate ? "Update" : "Email"} ${title}`}
        closeText="Close"
        confirmText={`${isUpdate ? "Update" : "Create"}`}
      >
        {loading ? (
          <CenteredBox>
            <CircularProgress />
          </CenteredBox>
        ) : (
          <>
            {
              <>
                <SelectDropDown

                  title="Template Type*"
                  value={fields.type}
                  size=""
                  margin="dense"
                  onChange={(e) => setFields({ ...fields, err: '', type: e.target.value })}
                  data={Object.keys(EMAIL_TEMPLATE_TYPE).map((item) => ({ value: EMAIL_TEMPLATE_TYPE[item], label: titleCase(item) }))}
                />
                <CustomInput
                  autoFocus={true}
                  disabled={loading}
                  value={fields.title}
                  onChange={(e) =>
                    setFields({
                      ...fields,
                      err: "",
                      title: e.target.value,
                    })
                  }
                  type="text"
                  label={"Title*"}
                />
                <CustomInput

                  disabled={loading}
                  value={fields.subject}
                  onChange={(e) =>
                    setFields({
                      ...fields,
                      err: "",
                      subject: e.target.value,
                    })
                  }
                  type="text"
                  label={"Subject*"}
                />


                <Box mt={2} >

                  <CustomEditor
                    enableVariableListGrouping
                    variables={getVariableAccordingToTemplate(fields.type)}
                    placeholder="Email Template*"
                    value={reventToHTMLVariableFromStringVariable(fields.template)}
                    onChange={(val) => {
                      setFields({ ...fields, template: convertHTMLVariableToStringVariable(val) })
                    }}

                  />

                </Box>
              </>
            }
          </>
        )}
      </CustomDialog>
    </>
  );
};
export default memo(CreateUi);
