import { Box, ButtonBase, IconButton, LinearProgress, linearProgressClasses, MenuItem, Paper, Popover, styled, Typography } from "@mui/material"
import { useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../../store/actions/commonAction";
import { updateLeadStepApi } from "../../../apis/lead.api";
import { callSnackBar } from "../../../store/actions/snackbarAction";
import { SNACK_BAR_VARIETNS } from "../../../utils/constants/constants";
import { Cancel } from "@mui/icons-material";


export const PipelineLinearProgress = styled(LinearProgress)(({ theme ,pipeColor}) => ({
    height: 5,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.primary.light//[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor:pipeColor?? theme.palette.primary.main
    },
}));
export const PipelineButton = styled(ButtonBase)(({ theme, color }) => ({
    border: "2px solid",
    borderColor: color,
    height: "100%", width: "100%",
    borderRadius: "inherit",
    position: "relative",
    "::after": {
        content: "' '",
        position: "absolute",
        top: "0px",
        opacity: 0.1,
        left: "0px",
        height: "100%",
        width: "100%",
        background: color,
    }
}));

const StepSelectionPopOver = ({ width, handleClose, anchorEl, open, popOverId, id, stepObj = {}, pipelineObj = {}, changeInserver = true, onChange = (step, pipeline) => { } }) => {

    const { user } = useSelector((state) => state)
    const dispatch = useDispatch()
    const options = useMemo(() => {
        return user.data?.pipelines?.lead;
    }, [user.data?.pipelines?.lead])

    const onStepChange = (newStepObj, newPipelineObj) => {

        if (changeInserver)
            dispatch(
                callApiAction(
                    async () => await updateLeadStepApi({ id, step: newStepObj?._id }),
                    async (response) => { },
                    (err) => {
                        dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
                    }
                )
            )

        onChange(newStepObj, newPipelineObj)
        handleClose()
    }
    return <Popover
        id={popOverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}

    >
        <Box sx={{ width }} p={2}>
            {
                options?.map((pipeline) => <Box key={pipeline?._id}>
                    <Typography variant="caption" fontWeight={600} color="grey">{pipeline?.title}</Typography>
                    {
                        pipeline?.steps?.map((step) => <MenuItem sx={{ width: "100%", display: "flex" }} key={step?._id} selected={step?._id == stepObj?._id} onClick={() => { onStepChange(step, pipeline) }} >
                            <Box sx={{ height: '10px',minWidth:"10px", width: '10px', borderRadius: "100%", background: step?.color }} >

                            </Box>
                            <Box display="flex"  flex={1}>
                         
                                <Typography display="block" lineHeight="110%" textOverflow="ellipsis" sx={{   width: "100%",whiteSpace:"normal",overflowWrap:"break-word" }} width="100%" variant="subtitle2" ml={2} >
                                    {step.title}
                                </Typography>
                            </Box>
                        </MenuItem>)
                    }
                </Box>

                )
            }
        </Box>

    </Popover>
}

const StepViewComponent = ({ id, forFilter, stepObj = {}, pipelineObj = {}, size, onChange = (step, pipeline) => { }, changeInserver,disabledEdit }) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const parentRef = useRef(null)
    const handleClick = (event) => {
        if(!disabledEdit)
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const popOverId = open ? 'step-popover' : undefined;

    if (size == "large")
        return <Paper elevation="0" ref={parentRef} sx={{ overflow: "hidden", display: "flex" }} height="100%" component={Box} width="100%">
            <PipelineButton color={stepObj?.color} aria-describedby={popOverId} variant="contained" onClick={handleClick}  >
                <Typography sx={{ color: stepObj?.color }} variant="body1" lineHeight="100%" >
                    {stepObj?.title ?? "Select Step"}
                </Typography>

            </PipelineButton>
            {forFilter && stepObj && <IconButton size="small" onClick={() => onChange(null, null)}>
                <Cancel />
            </IconButton>}
            <StepSelectionPopOver
                changeInserver={changeInserver}
                width={parentRef?.current?.clientWidth}
                open={open}
                popOverId={popOverId}
                handleClose={handleClose}
                anchorEl={anchorEl}
                stepObj={stepObj}
                pipelineObj={pipelineObj}
                id={id}
                onChange={onChange}

            />
        </Paper>
    return <Box>
        <Box display="flex" alignItems="center" sx={{ width: "100%" }} mb={1} >
            <Box mr={1} sx={{ height: "10px",minWidth:"10px", width: "10px", borderRadius: "100%", background: stepObj?.color ?? "#000" }} >

            </Box>
            <Typography variant="caption" lineHeight="100%" >
                {stepObj?.title}
            </Typography>
        </Box>
        <PipelineLinearProgress variant="determinate" pipeColor={stepObj?.color} value={(stepObj?.step_no / pipelineObj?.steps?.length) * 100} />
    </Box>
}
export default StepViewComponent