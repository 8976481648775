import {CircularProgress} from "@mui/material"

import { memo } from "react"
import { useSelector } from 'react-redux'
import CustomInput from "../../../../components/inputs/CustomInput"

import CustomDialog from "../../../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../../../components/layouts/OneViewBox"






const AttchOpenSolarProjectUi = ({ title, modalKey, fields, setFields, loading, onSubmit }) => {
    const { user } = useSelector(state => state)


    return <>

        <CustomDialog
            modalKey={modalKey}
            loading={loading}
            err={fields.err}
            onSubmit={onSubmit}
            title={` ${title}`}
            closeText="Close"

            confirmText={`Attach`}
        >


            {loading ? <CenteredBox><CircularProgress /></CenteredBox>
                : <>
                    <CustomInput

                        disabled={loading}
                        value={fields.open_solar_id}
                        onChange={(e) => setFields({ ...fields, err: '', open_solar_id: e.target.value })}

                        label={"Opensolar Project Id*"}

                    />


                </>}

        </CustomDialog>
    </>
}
export default memo(AttchOpenSolarProjectUi)