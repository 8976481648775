import { Box, Button, ButtonBase, Chip, Grid, MenuItem, Typography } from "@mui/material"
import CustomInput from "../../../components/inputs/CustomInput"
import { DesktopDatePicker } from "@mui/x-date-pickers"
import moment from "moment"
import CustomDialog from "../../../components/layouts/common/CustomDialog"
import { MODAL_KEYS } from "../../../utils/constants/modal.constant"
import AsyncDropDown from "../../../components/inputs/AsyncDropDown"
import { getCustomerApi } from "../../../apis/user.api"
import { memo, useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { closeModal, openModal } from "../../../store/actions/modalAction"

const CustomerDetailModal = ({ onSelect }) => {
    const dispatch = useDispatch()
    const modalKey = MODAL_KEYS.CUSTOMER_SELECTION_MODAL
    const [selectedContact, setSelectedContact] = useState(null)
    const onSubmit = (e) => {
        e?.preventDefault()
        onSelect(selectedContact)
        dispatch(closeModal(modalKey))
    }
    return <CustomDialog
        title="Add From Existing Contact"
        id={modalKey}
        onSubmit={onSubmit}
        confirmText="Select"
    >

        <AsyncDropDown
            id={'default_contact'}

            lazyFun={async (params) => { return await getCustomerApi({ ...params, searchable: ['full_name', 'uid'] }) }}

            OptionComponent={({ option, ...rest }) => {
                return <MenuItem {...rest}>{option.full_name}({option.uid})</MenuItem>
            }}
            onChange={async (changedVal) => { setSelectedContact(changedVal) }}
            titleKey={'full_name'}
            valueKey={"_id"}
            InputComponent={(params) => <CustomInput  {...params} label="Select Contact*" />}
        />
    </CustomDialog>
}
const ContactDetailsComponent = ({ title, defaultFields, onChange, disabled }) => {
    /*
    value should be
{
    f_name:
    m_name:
    l_name:
    dob:
    email:
    phone:
customer_id:
customer_uid:
    }

    */

    const [fields, setFields] = useState(defaultFields)
    const dispatch = useDispatch()
    const addFromExistingContactClick = () => {
        dispatch(openModal(
            <CustomerDetailModal
                onSelect={(val) => {
                    setFields({
                        f_name: val?.f_name ?? null,
                        m_name: val?.m_name ?? null,
                        l_name: val?.l_name ?? null,
                        dob: val?.dob ?? null,
                        email: val?.email ?? null,
                        phone: val?.phone ?? null,
                        customer_id: val?._id,
                        customer_uid: val?.uid
                    })
                }}

            />, 'xs', undefined, MODAL_KEYS.CUSTOMER_SELECTION_MODAL
        ))
    }
    const onRemoveCustomer = () => {
        setFields({
            f_name: '',
            m_name: '',
            l_name: '',
            dob: null,
            email: '',
            phone: '',
            customer_id: null,
            customer_uid: null
        })
    }

    const componentLoaded = useRef(false)
    useEffect(() => {
        if (componentLoaded.current)
            onChange(fields)
        else
            componentLoaded.current = true
    }, [fields])
    return <Box>

        <Box display="flex" justifyContent="space-between" mb={2}>
            <Typography>
                {title}:
            </Typography>
            {fields.customer_id ?
                <Chip label={fields.customer_uid} size="small" onDelete={onRemoveCustomer} />
                : <ButtonBase size="small" sx={{ px: 2 }} onClick={addFromExistingContactClick} >
                    <Typography sx={{ textDecoration: "underline" }} variant="caption" textAlign="right" >
                        Add from existing contact
                    </Typography>
                </ButtonBase>}
        </Box>
        <Grid container spacing={2} >
            <Grid item xs={4} >
                <CustomInput

                    margin="none"
                    disabled={disabled}
                    value={fields.f_name}
                    onChange={(e) => setFields({ ...fields, err: '', f_name: e.target.value })}
                    type="text"
                    label={"First Name*"}

                    size="small"

                />
            </Grid>
            <Grid item xs={4} >
                <CustomInput

                    margin="none"
                    disabled={disabled}
                    value={fields.m_name}
                    onChange={(e) => setFields({ ...fields, err: '', m_name: e.target.value })}
                    type="text"
                    label={"Middle Name"}

                    size="small"

                />
            </Grid>
            <Grid item xs={4} >
                <CustomInput

                    margin="none"
                    disabled={disabled}
                    value={fields.l_name}
                    onChange={(e) => setFields({ ...fields, err: '', l_name: e.target.value })}
                    type="text"
                    label={"Last Name"}

                    size="small"

                />
            </Grid>
            <Grid item xs={12} sx={{ padding: 0, my: 0 }} >
                <Typography variant="caption" >
                    Atleast one field from email or phone no is mandatory
                </Typography>
            </Grid>
            <Grid item xs={6} >
                <CustomInput

                    margin="none"
                    disabled={disabled}
                    value={fields.email}
                    onChange={(e) => setFields({ ...fields, err: '', email: e.target.value })}
                    type="email"
                    label={"Email*"}

                    size="small"

                />
            </Grid>
            <Grid item xs={6} >
                <CustomInput

                    margin="none"
                    disabled={disabled}
                    value={fields.phone}
                    onChange={(e) => setFields({ ...fields, err: '', phone: e.target.value })}
                    type="text"
                    label={"Phone*"}

                    size="small"

                />
            </Grid>
            <Grid item xs={6} >
                <DesktopDatePicker
                    inputFormat="DD/MM/yyyy"
                    disabled={disabled}
                    value={fields?.dob ? moment(fields.dob) : null}
                    onChange={(e) => setFields({ ...fields, dob: e?.toISOString() })}
                    type="text"
                    label={"DOB"}


                    renderInput={(params) => <CustomInput  {...params} margin="none" size="small" />}

                />
            </Grid>
        </Grid>
    </Box>
}
export default memo(ContactDetailsComponent)