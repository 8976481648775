import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, MenuItem, Paper, Skeleton, Typography, useTheme } from "@mui/material"
import { getAutoComplete, getLatAndLon } from "../../apis/googlemap.api"
import AsyncDropDown from "./AsyncDropDown"
import CustomInput from "./CustomInput"
import { memo, useCallback, useEffect, useState } from "react"
import { CenteredBox } from "../layouts/OneViewBox"
import { useDispatch } from "react-redux"
import { callApiAction } from "../../store/actions/commonAction"
import { Edit } from "@mui/icons-material"
import CustomDialog from "../layouts/common/CustomDialog"
import { closeModal, openModal } from "../../store/actions/modalAction"
import { MODAL_KEYS } from "../../utils/constants/modal.constant"


const AdreesUpdateDialog = ({ fieldName, value, onChange }) => {
    const dispatch = useDispatch()
    const modalKey = MODAL_KEYS.ADDRESS_UPDATE_MODAL
    const [input, setInput] = useState(value ?? null)
    const onSubmit = (e) => {
        e?.preventDefault()

        onChange(input)
        dispatch(closeModal(modalKey))
    }
    return <>

        <CustomDialog
            id={modalKey}
            title={`Update ${fieldName}`}
            onSubmit={onSubmit}
        >
            <CustomInput

                value={input}
                onChange={(e) => {
                    setInput(e.target.value)
                }}

            />
        </CustomDialog>
    </>



}
const AddressView = memo(({ title, value, onChange }) => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const onEdit = () => {
        dispatch(openModal(<AdreesUpdateDialog fieldName={title} value={value} onChange={onChange} />, 'xs', undefined, MODAL_KEYS.ADDRESS_UPDATE_MODAL))
    }
    return <Box elevation={0} component={Paper} sx={{ border: "1px solid " + theme.palette.primary.main, position: "relative", height: "100%" }} p={1} >



        {/* 
         */}
        <IconButton size="small" sx={{ position: "absolute", top: "0px", right: "0px" }} onClick={onEdit}>
            <Typography variant="caption" >
                <Edit fontSize="inherit" />
            </Typography>
        </IconButton>
        <Typography variant="caption" sx={{ width: "100%", overflow: "hidden", display: "block" }} lineHeight="100%" align="center" fontWeight={600}  >{title}</Typography>


        <Box sx={{ width: "100%", flex: 0 }}  >
            <Typography textOverflow="ellipsis" sx={{ width: "100%", overflow: "hidden", display: "block" }} variant="caption" align="center" >{value ?? "NA"}</Typography>
        </Box>
    </Box>
})

const GoogleAddress = ({ onChange = () => { }, defaultAddress }) => {

    const dispatch = useDispatch()
    const theme = useTheme()
    const [predictedAddress, setPredictedAddress] = useState(null)
    const [address, setAddress] = useState(defaultAddress ?? null)
    const [loading, setLoading] = useState(false)

    const fetchLatAndLong = useCallback((placeId) => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await getLatAndLon({ placeId }),
            (response) => {

                setAddress(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }, [])

    useEffect(() => {
        if (address) {
            onChange(address)
        } else {
            onChange({
                postCode: '',
                country: "",
                state: "",
                locality: "",
                address: "",
                lat: null,
                lon: null
            })
        }
    }, [address])
    useEffect(() => {
        if (predictedAddress) {
            fetchLatAndLong(predictedAddress?.place_id)
        }
    }, [predictedAddress])

    return <Box elevation={0} component={Paper} sx={{ background: theme.palette.secondary.main, border: "1px solid " + theme.palette.primary.main }} p={2}>
        <AsyncDropDown
            filterOptions={(item) => item}
            id={'google-address'}
            lazyFun={async (params) => {
                // console.log(params)
                // if (params.search.length > 3) {

                // } else {

                //     return {
                //         status: 1,
                //         data: {
                //             result: []
                //         }
                //     }
                // }
                return await getAutoComplete({ ...params })

            }}

            OptionComponent={({ option, ...rest }) => {
                return <MenuItem {...rest} sx={{ p: 0, width: "100%", "*": { textOverflow: "ellipsis !important" } }} ><Typography fontSize="inherit" textOverflow="ellipsis" sx={{ width: "100%", overflow: "hidden" }} >
                    {option.description}
                </Typography></MenuItem>
            }}
            onChange={async (changedVal) => {
                setPredictedAddress(changedVal)
            }}
            titleKey={'description'}
            valueKey={"place_id"}
            InputComponent={(params) => <CustomInput placeholder="Select Address" {...params} size="small" />}
        />
        <Box>

            {/* {
                loading && <CenteredBox>
                    <CircularProgress size={20} />
                </CenteredBox>
            } */}
            {

                <Grid container spacing={1} >
                    <Grid item xs={6} >
                        {loading ? <Skeleton height={'50px'} variant="rounded" /> : <AddressView title="Street" onChange={(val) => setAddress({ ...address, address: val })} value={address?.address} />}
                    </Grid>
                    <Grid item xs={6} >
                        {loading ? <Skeleton height={'50px'} variant="rounded" /> : <AddressView title="Locality" onChange={(val) => setAddress({ ...address, locality: val })} value={address?.locality} />}
                    </Grid>

                    <Grid item xs={6} >
                        {loading ? <Skeleton height={'50px'} variant="rounded" /> : <AddressView title="Zip Code" onChange={(val) => setAddress({ ...address, postCode: val })} value={address?.postCode} />}
                    </Grid>
                    <Grid item xs={6} >
                        {loading ? <Skeleton height={'50px'} variant="rounded" /> : <AddressView title="State" onChange={(val) => setAddress({ ...address, state: val })} value={address?.state} />}
                    </Grid>
                    <Grid item xs={4} >
                        {loading ? <Skeleton height={'50px'} variant="rounded" /> : <AddressView title="Country" onChange={(val) => setAddress({ ...address, country: val })} value={address?.country} />}
                    </Grid>
                    <Grid item xs={4} >
                        {loading ? <Skeleton height={'50px'} variant="rounded" /> : <AddressView title="Lat" onChange={(val) => setAddress({ ...address, lat: val })} value={address?.lat} />}
                    </Grid>
                    <Grid item xs={4} >
                        {loading ? <Skeleton height={'50px'} variant="rounded" /> : <AddressView title="Long" value={address?.lon} onChange={(val) => setAddress({ ...address, lon: val })} />}
                    </Grid>
                </Grid>
            }
        </Box>


    </Box>
}
export default memo(GoogleAddress)