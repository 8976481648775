import { Avatar, Box, Button, ButtonGroup, CircularProgress, Grid, Paper, styled, Typography } from "@mui/material"

import { useDispatch, useSelector } from "react-redux"
import { CenteredBox } from "../../../components/layouts/OneViewBox"
import { MilitaryTech, Paid, SolarPower, Source, SwapVert, ThumbDown } from "@mui/icons-material"
import AssignedSalesPerson from "../../lead/components/AssignedSalesPerson"
import BubbleComponent from "../../../components/layouts/BubbleComponent"
import LeadTimeFilters from "../../lead/filters/LeadTimeFilter"
import FilterButton from "../../lead/filters/FilterButton"
import { updateLeaderBoardFilters } from "../../../store/actions/leaderboardActions"
import SubmitButton from "../../../components/button/SubmitButton"
import { openModal } from "../../../store/actions/modalAction"
import ListController, { LeadListModal } from "../../lead/ListController"
import { MODAL_KEYS } from "../../../utils/constants/modal.constant"

const SalesReportBox = ({ lead, amount, size, color, Icon }) => {
    const dispatch = useDispatch()
    const onButtonClick = () => {
        // dispatch(openModal(<LeadListModal modal defaultFilters={{}} />,'lg',undefined,MODAL_KEYS.LEAD))
    }
    return <>

        <CenteredBox flexDirection="column" py={2} onClick={onButtonClick}>
            <Box display="flex" >
                <Typography variant="h4" color={color} lineHeight="100%" >
                    {lead}
                </Typography>
                <Typography variant="h5" >
                    <Icon color={color} fontSize="inherit" />
                </Typography>
            </Box>
            <Box px={2} display="flex" width="100%" justifyContent="space-between" >
                <Typography variant="caption" display="block" >
                    <Paid color={color} fontSize="inherit" />&nbsp;{Number(amount).toFixed(2)}
                </Typography>
                <Typography variant="caption" display="block" >
                    <SolarPower color={color} fontSize="inherit" />&nbsp;{Number(size).toFixed(2)}
                </Typography>
            </Box>
        </CenteredBox>

    </>
}
const SalesItem = ({ title, allocated, rejected, ratio, won, index }) => {


    return <Grid item xs={12} sm={12} md={6} >
        <Box component={Paper} variant="outlined" >

            <Box p={2} borderBottom={1} display="flex" width="100%" borderBottomColor="divider" >
                <AssignedSalesPerson
                    title={String(index + 1)}
                    size="small"
                />
                <Typography ml={2}>
                    {title}
                </Typography>
            </Box>

            <Grid container >
                <Grid item xs={3} sx={{ borderRight: 1, borderColor: "divider" }} >
                    <SalesReportBox
                   
                        Icon={Source}
                        lead={allocated?.total_lead ?? 0}
                        amount={allocated?.total_amount ?? 0}
                        size={allocated?.total_size ?? 0}
                        color="primary"
                    />

                </Grid>
                <Grid item xs={3} sx={{ borderRight: 1, borderColor: "divider" }} >
                    <SalesReportBox
                        Icon={MilitaryTech}
                        lead={won?.total_lead ?? 0}
                        amount={won?.total_amount ?? 0}
                        size={won?.total_size ?? 0}
                        color="success"
                    />

                </Grid>
                <Grid item xs={3} sx={{ borderRight: 1, borderColor: "divider" }} >
                    <SalesReportBox
                        Icon={ThumbDown}
                        lead={rejected?.total_lead ?? 0}
                        amount={rejected?.total_amount ?? 0}
                        size={rejected?.total_size ?? 0}
                        color="error"
                    />

                </Grid>
                <Grid item xs={3} >
                    <CenteredBox p={2}>
                        <BubbleComponent percentage={ratio * 100} height={50} width={50} />
                    </CenteredBox>
                </Grid>
            </Grid>

        </Box>
    </Grid>
}

const SalesReportUI = ({ loading, fetchFun, exportLoading }) => {
    const dispatch = useDispatch()
    const { leaderboard } = useSelector(state => state)
    const { filters, data } = leaderboard
    const setFilters = (newFilters) => { dispatch(updateLeaderBoardFilters({ ...filters, ...newFilters })) }


    return <>

        <Box mb={2} >
            <Paper component={Box} elevation={0} sx={{ width: "100%", p: 2 }} mb={2} >
                <Box p={2} width="100%">
                    <LeadTimeFilters onlyRange filters={filters} setFilters={setFilters} />

                    <Box display="flex" justifyContent="space-between" mt={2}>
                        <ButtonGroup size="small" disableElevation>
                            <Button onClick={() => setFilters({ leaderboardSortType: 'sold' })} variant={filters.leaderboardSortType == 'sold' ? "contained" : "outlined"} ><SwapVert fontSize="inherit" /> Won Lead</Button>
                            <Button onClick={() => setFilters({ leaderboardSortType: 'ratio' })} variant={filters.leaderboardSortType == 'ratio' ? "contained" : "outlined"} ><SwapVert fontSize="inherit" /> Ratio</Button>
                        </ButtonGroup >
                        <FilterButton notApplicableFilters={['sales_person', 'status', 'date']} defaultFilters={filters} setDefaultFilters={setFilters} />
                    </Box>
                </Box>
            </Paper>
            <Paper component={Box} elevation={0} sx={{ width: "100%", p: 2 }} mb={2} >
                <Box mb={2} display="flex">
                    <Box>
                        <SubmitButton disabled={exportLoading} onClick={() => fetchFun(true)} variant="contained" size="small" title={'Export CSV'} loading={exportLoading} />
                    </Box>
                </Box>
                {
                    loading && <CenteredBox sx={{}}>
                        <CircularProgress />
                    </CenteredBox>
                }
                {!loading && <Grid container spacing={2} >
                    {
                        data && data?.slice(0, 10)?.map((item, index) => <SalesItem
                            index={index}
                            title={item?.[0]?.name}
                            type={filters.leaderboardSortType}
                            ratio={item?.[4]}
                            allocated={item?.[1]}
                            rejected={item?.[3]}
                            won={item?.[2]}

                        />)
                    }
                </Grid>}
            </Paper>
        </Box>
    </>

}
export default SalesReportUI