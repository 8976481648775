import {
  CircularProgress,
  Grid,
  Typography,
  styled,
  Paper,
  IconButton,
  CardContent,
  Card,
  Divider,
  Rating,
  Chip,
} from "@mui/material";
import { Box } from "@mui/system";
import { memo } from "react";

import CustomDialog from "../../components/layouts/common/CustomDialog";
import { CenteredBox } from "../../components/layouts/OneViewBox";
import { TitleBox } from "../../components/layouts/common/boxes";
import NoDataComponent from "../../components/layouts/NoDataComponent";
import moment from "moment";
import ImageComponent from "../../components/ImageComponent";
import { constructUrl, moduleAccessCondition, toTitleCase, unEscapeStr } from "../../utils/helpers/helper";
import { Link, useParams } from "react-router-dom";
import {
  AddCircleOutline,
  CallOutlined,
  Edit,
  EditNote,
  MailOutline,
} from "@mui/icons-material";
import CreateOfficesController from "./CreateOfficesController";
import { openModal } from "../../store/actions/modalAction";
import { useDispatch, useSelector } from "react-redux";
import SubmitButton from "../../components/button/SubmitButton";
import CreateRatingsController from "./CreateRatingsController";
import CreateController from "./CreateController";
import MODULES from "../../utils/constants/module.constants";
const CompanyRatingCard = ({ id, onUpdateRatings, rating, totalRating, logo, platform, isEditable }) => {
  return <Grid item xs={12} sm={6} md={6} lg={3} >
    <Card
      sx={{ width: "100%", minHeight: "200px", height: "100%" }}
      variant="outlined"
    >
      <CardContent component="">

        <ImageComponent
          cachePermanent={true}
          src={unEscapeStr(logo)}
          sx={{
            width: "250px",
            height: "80px",
            objectFit: "contain",
          }}

        />
        <Divider sx={{ my: 2 }} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          mb={2}
        >
          <Typography
            sx={{ fontSize: 16 }}
            color="text.primary"
          >
            {platform}
          </Typography>
          {isEditable && <IconButton size="small" onClick={(e) => onUpdateRatings(e, id)}>
            <EditNote

              color="primary"

            />
          </IconButton>}
        </Box>
        <Chip sx={{ fontSize: 14 }}
          label={`${rating} / ${totalRating}`} size="small" />
        <Box sx={{ display: "flex", overflow: "auto" }} mb={3}>
          <Rating
            name="total-ratings"
            value={rating}
            precision={0.1}
            max={totalRating}
            readOnly
          />

        </Box>
      </CardContent>
    </Card>
  </Grid>
}
const CompanyRatingsContainer = ({ companyId, ratings, callBack = () => { }, isEditable }) => {
  const dispatch = useDispatch()
  const onUpdateRatings = (e, id) => {
    dispatch(
      openModal(
        <CreateRatingsController
          id={id}
          callBack={callBack}

        />,
        "sm",
        undefined,
        "ratings"
      )
    );
  };

  const onCreateBtnClick = () => {
    dispatch(
      openModal(<CreateRatingsController
        company_id={companyId}
        callBack={callBack}
      />,
        "sm",
        undefined,
        "ratings")
    );
  }
  return <>
    <Box sx={{ display: "flex", marginY: "10px" }}>
      <Typography sx={{ marginTop: "15px" }} variant="h6">
        Ratings
      </Typography>
      {isEditable && <IconButton
        sx={{ marginTop: "12px" }}
        onClick={onCreateBtnClick}
      >
        <AddCircleOutline color="primary" />
      </IconButton>}
    </Box>

    {ratings && ratings?.length > 0 ? <Grid container spacing={2}>
      {ratings?.map((ele, index) => (
        <CompanyRatingCard key={ele?._id} id={ele?._id} onUpdateRatings={onUpdateRatings} logo={ele?.logo} rating={ele?.rating} totalRating={ele?.total_rating} platform={ele?.platform} isEditable={isEditable} />
      ))}
    </Grid> : <CenteredBox>
      <NoDataComponent iconVariant="body1" variant="body1" message="No Ratings" />
    </CenteredBox>}
  </>
}

const CompanyOfficesCard = ({ id, state, office, onUpdateOffices, isEditable }) => {
  return <Grid item xs={12} sm={6} md={6} lg={3} >
    <Card
      sx={{ width: "100%", minHeight: "200px", height: "100%" }}
      variant="outlined"
    >
      <CardContent component="">

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: " center",
          }}
        >
          <Typography
            sx={{ fontSize: 16 }}
            color="primary"
            gutterBottom
          >
            {state}
          </Typography>
          {isEditable && <IconButton onClick={(e) => onUpdateOffices(e, id)}>
            <EditNote
              color="primary"

            />
          </IconButton>}
        </Box>
        <Divider sx={{ my: 2, width: "100%" }} />
        <Typography
          sx={{ fontSize: 16 }}
          color="text.secondary"
          gutterBottom
        >
          {office}
        </Typography>


      </CardContent>
    </Card>
  </Grid>
}
const CompanyOfficesContainer = ({ companyId, offices, callBack = () => { }, isEditable }) => {

  const dispatch = useDispatch()
  const onUpdateOffices = (e, id) => {

    dispatch(
      openModal(
        <CreateOfficesController
          callBack={callBack}
          id={id}
        />,
        "sm",
        undefined,
        "offices"
      )
    );
  };
  const onCreateBtnClick = () => {
    dispatch(
      openModal(
        <CreateOfficesController
          company_id={companyId}
          callBack={callBack}
        />,
        "sm",
        undefined,
        "offices"
      )
    );
  }

  return <>  <Box sx={{ display: "flex", marginY: "10px" }}>
    <Typography sx={{ marginTop: "18px" }} variant="h6">
      Offices
    </Typography>
    {isEditable && <IconButton
      sx={{ marginTop: "12px" }}
      onClick={onCreateBtnClick}
    >
      <AddCircleOutline color="primary" />
    </IconButton>}
  </Box>
    {offices && offices?.length > 0 ? <Grid container spacing={2}>
      {offices?.map((ele, index) => (
        <CompanyOfficesCard onUpdateOffices={onUpdateOffices} office={ele.office} id={ele?._id} key={ele?._id} state={ele.state} isEditable={isEditable} />
      ))}


    </Grid> : <CenteredBox>
      <NoDataComponent iconVariant="body1" variant="body1" message="No Offices" />
    </CenteredBox>}

  </>
}

const CompanyFields = ({ title, value }) => {
  return <Card
    sx={{
      width: "100%",
      minHeight: "150px",
      marginBottom: "10px",
    }}
    variant="outlined"
  >
    <CardContent component="">
      <Typography
        sx={{ fontSize: 16 }}
        color="text.primary"
        gutterBottom
      >
        {title}
      </Typography>
      <Divider sx={{ my: 2, width: "100%" }} />
      <Typography
        sx={{ fontSize: 14 }}
        color="text.secondary"
        gutterBottom
        dangerouslySetInnerHTML={{
          __html: value,
        }}
      >
        { }
      </Typography>
    </CardContent>
  </Card>
}
const CompanyDetailsUi = ({
  onRefresh,
  id,
  loading,
  state,

  fields,



}) => {

  const dispatch = useDispatch();
  const { user } = useSelector(state => state)
  const isEditable = moduleAccessCondition(user, [MODULES.COMPANY_PROFILE_EDIT, MODULES.COMPANY_MODULE_EDIT])

  const onUpdateDetails = (e, id) => {
    dispatch(
      openModal(
        <CreateController
          id={id}
          callBack={onRefresh}
        // callBack={(response, updatedData) => {
        //   setFields({ ...fields, ...updatedData });
        // }}
        />,
        "sm",
        undefined,
        "company-create"
      )
    );
  };


  return (
    <>

      {!loading && fields && (

        <Box p={3} component={Paper}>
          <Box
            sx={{
              marginTop: "24px",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              marginBottom: "24px",
            }}
          >
            <Box sx={{ display: "flex", flex: 1 }}>
              {fields?.logo && (
                <ImageComponent
                  noClickEffect
                  src={fields?.logo}
                  sx={{
                    width: "100%",
                    height: "60px",
                    objectFit: "contain",
                  }}
                />
              )}
            </Box>
            <CenteredBox sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Typography sx={{ marginTop: "18px" }} variant="h5">
                {toTitleCase(fields?.name)}
              </Typography>
              {
                isEditable && <IconButton size="small" onClick={(e) => onUpdateDetails(e, fields?._id)}>
                  <Edit
                    color="primary"
                    fontSize="inherit"
                    title="Edit"

                  />
                </IconButton>
              }
            </CenteredBox>
            <Typography variant="body1" color="grey">
              {(fields?.abn_no)}
            </Typography>
            {/* </Box> */}
            <Typography variant="h7">
              <a href={`${constructUrl(fields?.company_website)}`} target="_blank">
                <Typography sx={{ textDecoration: "underline" }} color="grey"
                  variant="subtitle1">
                  {fields?.company_website}
                </Typography>
              </a>
            </Typography>


          </Box>

          <Box my={3}>
            <CenteredBox >
              <Box sx={{ display: "flex" }} mr={3}>
                <Typography
                  sx={{
                    // alignItems: "flex-start",
                    display: "flex",
                    // lineHeight: "100%",
                    // justifyContent:"center",
                  }}
                  variant="subtitle1"
                >
                  <MailOutline />

                </Typography>
                <Typography
                  sx={{
                    // alignItems: "flex-start",
                    // justifyContent:"center",
                    display: "flex",
                    // lineHeight: "100%",
                    // overflow: "hidden",
                  }}
                  color="grey"
                  variant="subtitle1"
                >
                  <Link color="inherit" to={`mailto:${fields?.company_email}`}>
                    <Typography color="primary"
                      variant="subtitle1">
                      {fields?.company_email}
                    </Typography>
                  </Link>
                </Typography>
              </Box>

              <Box sx={{ display: "flex" }}>
                <Typography
                  sx={{

                    display: "flex",
                  }}
                  variant="subtitle1"
                >
                  <CallOutlined />
                  {/* &nbsp; Company Phone No. */}
                </Typography>
                <Typography
                  sx={{
                    // alignItems: "center",
                    // justifyContent:"center",
                    display: "flex",
                    // lineHeight: "100%",
                  }}
                  variant="subtitle1"
                >
                  {fields?.company_phone}
                </Typography>
              </Box>
            </CenteredBox>
          </Box>

          <Box my={1}>
            <CompanyFields title={"Description"} value={fields?.description} />
            <CompanyFields title={"Ahead Of Competition."} value={fields?.ahead_of_competition} />
            <CompanyFields title={"CEO's Message"} value={fields?.ceo_message} />
            <CompanyFields title={"Payment Details"} value={fields?.payment_details} />




          </Box>

          <CompanyOfficesContainer companyId={id} isEditable={isEditable} offices={fields.offices_id ?? []} callBack={onRefresh} />


          <CompanyRatingsContainer companyId={id} isEditable={isEditable} ratings={fields.ratings_id ?? []} callBack={onRefresh} />
        </Box>

      )}

      {!state && !loading && (
        <CenteredBox>
          <Typography variant="h3">Loading Data...</Typography>
        </CenteredBox>
      )}
      {loading && (
        <CenteredBox>
          <CircularProgress />
        </CenteredBox>
      )}

    </>
  );
}
export const CompanyDetailModal = ({ modalKey, ...props }) => {
  return <CustomDialog
    id={modalKey}
    loading={props?.loading}
    err={props?.fields.err}
    // onSubmit={onSubmit}
    title={`${props?.title}`}
    closeText="Close"
    confirmText={"Create"}
  >

    <CompanyDetailsUi {...props} />
  </CustomDialog>
}
export default CompanyDetailsUi;
