import { USER_ROLES } from "../constants/constants"


export const getAllowedRolesForExistingRole = (existingRole) => {



    switch (existingRole) {
        case USER_ROLES.director: return Object.values(USER_ROLES)
        case USER_ROLES.admin: return Object.values(USER_ROLES).filter(val => val != USER_ROLES.director)
        case USER_ROLES.partner_admin: return Object.values(USER_ROLES).filter(val => ![USER_ROLES.director, USER_ROLES.admin, USER_ROLES.partner_admin].includes(val))
        case USER_ROLES.sub_admin: return Object.values(USER_ROLES).filter(val => ![USER_ROLES.director, USER_ROLES.admin, USER_ROLES.partner_admin, USER_ROLES.sub_admin].includes(val))
        case USER_ROLES.tl: return [USER_ROLES.sales,USER_ROLES.tl]
        case USER_ROLES.sales: return []
        default: return []
    }
}
