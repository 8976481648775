import { useCallback, useEffect, useState } from "react"
import { getProjectsByIdApi } from "../../../apis/projects.api"
import { CenteredBox } from "../../../components/layouts/OneViewBox"
import { Box, CircularProgress, Grid, Typography } from "@mui/material"
import NoDataComponent from "../../../components/layouts/NoDataComponent"
import { useDispatch } from "react-redux"
import { callApiAction } from "../../../store/actions/commonAction"
import { LocationOn, Map, Pin } from "@mui/icons-material"
import { createAddress } from "../../../utils/helpers/helper"

const ProjectDetailViewForMap = ({ id }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [details, setDetails] = useState({})
    const fetchProjectById = useCallback((id) => {

        setLoading(true)
        dispatch(callApiAction(
            async () => await getProjectsByIdApi({ id }),
            (response) => {
                setDetails(response)
                setLoading(false)

            },
            (err) => {
                setLoading(false)
            }
        ))

    }, [])

    useEffect(() => {
        if (id)
            fetchProjectById(id)
    }, [id])

    if (loading) {
        return <CenteredBox  >
            <CircularProgress />
        </CenteredBox>
    }

    if (!id || id == '') {
        return <CenteredBox>
            <NoDataComponent variant="h6" message="Click on map marker" Icon={LocationOn} />
        </CenteredBox>
    }
if(!details || Object.keys(details).length==0){
    return <></>
}
    const selectedSystem = details?.systems?.find((item)=>item._id==details.selected_system)
    return <Box sx={{ width: "100%", height: "100%", overflowY: "auto" }} >
        <Typography variant="h6" mb={3} >Project Details</Typography>

        <Grid container spacing={2}>
            <Grid item xs={12} md={3} >
                <Typography variant="body1" fontWeight={600} >Name: </Typography>
            </Grid>
            <Grid item xs={12} md={9} >
                <Typography variant="body1"  >{details?.contacts?.[0]?.full_name} </Typography>
            </Grid>

            <Grid item xs={12} md={3} >
                <Typography variant="body1" fontWeight={600} >Email: </Typography>
            </Grid>
            <Grid item xs={12} md={9} >
                <Typography variant="body1" >{details?.contacts?.[0]?.email} </Typography>
            </Grid>

            <Grid item xs={12} md={3} >
                <Typography variant="body1" fontWeight={600} >Phone: </Typography>
            </Grid>
            <Grid item xs={12} md={9} >
                <Typography variant="body1"  >{details?.contacts?.[0]?.phone} </Typography>
            </Grid>

            <Grid item xs={12} md={3} >
                <Typography variant="body1" fontWeight={600} >Address: </Typography>
            </Grid>
            <Grid item xs={12} md={9} >
                <Typography variant="body1"  >{createAddress(details,true)} </Typography>
            </Grid>
{selectedSystem &&<>
    <Grid item xs={12} md={3} >
                <Typography variant="body1" fontWeight={600} >System: </Typography>
            </Grid>
            <Grid item xs={12} md={9} >
                <Typography variant="body1"  >{selectedSystem.name} </Typography>
            </Grid>


            <Grid item xs={12} md={3} >
                <Typography variant="body1" fontWeight={600} >Pannels: </Typography>
            </Grid>
            <Grid item xs={12} md={9} >
                <Typography variant="body1"  >{selectedSystem.modules?.map((item)=>item.code+" ("+item.quantity+")").join(', ')} </Typography>
            </Grid>


            <Grid item xs={12} md={3} >
                <Typography variant="body1" fontWeight={600} >Inverters: </Typography>
            </Grid>
            <Grid item xs={12} md={9} >
                <Typography variant="body1"  >{selectedSystem.inverters?.map((item)=>item.code+" ("+item.quantity+")").join(', ')} </Typography>
            </Grid>
            <Grid item xs={12} md={3} >
                <Typography variant="body1" fontWeight={600} >Battries: </Typography>
            </Grid>
            <Grid item xs={12} md={9} >
                <Typography variant="body1"  >{selectedSystem.battries?.map((item)=>item.code+" ("+item.quantity+")").join(', ')} </Typography>
            </Grid>
</>
}
          



        </Grid>
    </Box>
}
export default ProjectDetailViewForMap