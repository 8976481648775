import { CircularProgress, MenuItem } from "@mui/material"

import { Fragment, memo } from "react"
import { useDispatch, useSelector } from 'react-redux'



import CustomInput from "../../../components/inputs/CustomInput"
import CustomDialog from "../../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../../components/layouts/OneViewBox"
import { getOpenSolarUserApi } from "../../../apis/opensolarIntegration.api"
import AsyncDropDown from "../../../components/inputs/AsyncDropDown"
import { getUserApi } from "../../../apis/user.api"



const MappedUserCreateUi = ({ title, isUpdate, fields, setFields, loading, onSubmit, modalKey }) => {



    return <>

        <CustomDialog
            id={modalKey}
            loading={loading}
            err={fields.err}
            onSubmit={onSubmit}
            title={`${isUpdate ? "Update" : "Create"} ${title}`}
            closeText="Close"
            confirmText={`${isUpdate ? "Update" : "Create"}`}
        >

            {loading ? <CenteredBox><CircularProgress /></CenteredBox> :
                <>

                    <AsyncDropDown
                        id={'crm-user'}

                        lazyFun={getUserApi}
                        label="Crm User"
                        OptionComponent={({ option, ...rest }) => {
                            return <MenuItem {...rest}>{option.name}</MenuItem>
                        }}
                        onChange={async (changedVal) => { setFields({ ...fields, user_id: changedVal ? changedVal._id : null }) }}
                        titleKey={'name'}
                        valueKey={"id"}
                    />
                    <AsyncDropDown
                        id={'opensolar-users'}

                        lazyFun={getOpenSolarUserApi}
                        label="Open Solar User"
                        OptionComponent={({ option, ...rest }) => {
                            return <MenuItem {...rest}>{option.display}</MenuItem>
                        }}
                        onChange={async (changedVal) => { setFields({ ...fields, open_solar_user_id: changedVal ? changedVal.id : null }) }}
                        titleKey={'display'}
                        valueKey={"id"}
                    />




                </>}

        </CustomDialog>
    </>
}
export default memo(MappedUserCreateUi)