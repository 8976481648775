import { Chip, CircularProgress, IconButton, Paper, Typography, styled } from "@mui/material"
import { Box } from "@mui/system"
import { useDispatch } from "react-redux"
import SubmitButton from "../../components/button/SubmitButton"
import { useState } from "react"
import NoDataComponent from "../../components/layouts/NoDataComponent"
import { AddCircle, Delete, Edit } from "@mui/icons-material"
import AddTagController from "./tags/AddTagController"
import { deletePreDefinedTagsCategoryApi, removeTagFromPreDefinedTagsCategoryApi } from "../../apis/predefinedTags.api"
import { closeModal, openModal } from "../../store/actions/modalAction"
import { callApiAction } from "../../store/actions/commonAction"
import MessageDilog from "../../components/MessageDilog"
import CreateController from "./CreateController"
import { CenteredBox } from "../../components/layouts/OneViewBox"


const FilterTitleBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }
}))

const TagItem = ({ category_id, label, onDelete }) => {
    const dispatch = useDispatch()
    const removeFun = async () => {

        dispatch(callApiAction(
            async () => await removeTagFromPreDefinedTagsCategoryApi({ category_id, tag: label }),
            (response) => {
            },
            (err) => {
            }
        ))
        onDelete(label)
        dispatch(closeModal())
    }
    const onRemove = () => {
        dispatch(openModal(<MessageDilog onSubmit={removeFun} title="Alert!" message={`Are you sure to delete "${label}" ?`} />, "sm"))
    }

    return <Chip size="small" sx={{ m: 2 }} label={label} onDelete={onRemove} />
}
const CategoryItem = ({ _id, category_title, tags }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)

    const [params, setParams] = useState({
        category_title,
        _id,
        tags
    })

    const onEdit = () => {
        dispatch(openModal(<CreateController id={params._id} callBack={(response, updatedData) => {
            setParams({ ...params, ...updatedData })
        }} />, "sm"))
    }
    const deleteFun = async () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await deletePreDefinedTagsCategoryApi({ id: params._id }),
            (response) => {
                setParams({})
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
        dispatch(closeModal())
    }
    const onDelete = () => {
        dispatch(openModal(<MessageDilog onSubmit={deleteFun} title="Alert!" message={`Are you sure to delete "${params.category_title}" ?`} />, "sm"))
    }

    const onAddTag = (tag) => {

        const tags = [...(params.tags ?? [])]

        tags.push(tag)
        setParams({ ...params, tags })
    }
    const onAddTagButtonClick = () => {
        dispatch(openModal(<AddTagController category_id={params._id} callBack={onAddTag} />, "sm"))
    }

    const onTagDelete = (tag) => {

        const tags = [...(params.tags ?? [])]

        const tagIndex = tags.findIndex(item => item == tag)
        tags.splice(tagIndex, 1)
        setParams({ ...params, tags })
    }

    if (Object.keys(params).length == 0) {
        return <></>
    }

    return <Paper variant="outlined" component={Box} p={3} mb={2} >
        <Box sx={{ width: "100%", display: "flex" }}>
            <Box display="flex" flex={1} >
                <Typography variant="body1" >
                    {params?.category_title}
                </Typography>
            </Box>
            <Box>
                <IconButton disabled={loading} size="small" onClick={onAddTagButtonClick}>
                    <AddCircle color="info" fontSize="inherit" />
                </IconButton>

                {<>
                    {loading && <CircularProgress color="primary" fontSize="inherit" />}
                    {!loading && <IconButton disabled={loading} size="small" onClick={onDelete}>
                        <Delete color="error" fontSize="inherit" />
                    </IconButton>}
                </>}
                <IconButton disabled={loading} size="small" onClick={onEdit}>
                    <Edit color="info" fontSize="inherit" />
                </IconButton>
            </Box>
        </Box>
        <Paper elevation={0} component={Box} p={2} py={3} sx={{ background: "#f2f2f2" }}>
            <Typography variant="body2">Tags:</Typography>
            {
                params?.tags?.map((tag) => <TagItem key={tag} category_id={_id} label={tag} onDelete={onTagDelete} />)
            }
            {
                (!params?.tags || params?.tags?.length == 0) && <NoDataComponent iconVariant="body1" variant="body1" message="No Tags Found" />
            }
        </Paper>
    </Paper>
}


const ListUi = ({ title, list, loading, onCreateBtnClick, callBack }) => {


    return (
        <>

            <Box mb={3}>
                <Paper elevation={2} sx={{ width: "100%", padding: 4 }} >
                    <Box  >
                        <FilterTitleBox>
                            <Typography variant="h5" mb={2} >{title}</Typography>

                            <Box mb={2}>
                                {<SubmitButton variant="contained" onClick={onCreateBtnClick} title={"Add Category"} />}
                            </Box>

                        </FilterTitleBox>
                    </Box>
                    <Box sx={{ minHeight: "300px" }} p={2}>
                        {loading && (
                            <CenteredBox>
                                <CircularProgress />
                            </CenteredBox>
                        )}
                        {!loading &&
                            list &&
                            list?.length > 0 &&
                            list.map((item, index) => {
                                return (
                                    <CategoryItem key={item?._id} {...item} callBack={callBack} />
                                );
                            })}

                    </Box>

                </Paper>
            </Box>

        </>
    )
}
export default ListUi