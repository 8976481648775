
import { CircularProgress, IconButton, Paper, Typography, styled } from "@mui/material"
import { Box } from "@mui/system"
import { useDispatch, useSelector } from "react-redux"
import SubmitButton from "../../components/button/SubmitButton"
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxInDesktop"

import NoDataComponent from "../../components/layouts/NoDataComponent"
import { CenteredBox } from "../../components/layouts/OneViewBox"
import { memo, useCallback, useState } from "react"
import { Add, Delete } from "@mui/icons-material"
import { callApiAction } from "../../store/actions/commonAction"
import { deleteSupportEmailApi } from "../../apis/user.api"
import CreateController from "./CreateController"
import { openModal } from "../../store/actions/modalAction"



const FilterTitleBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }

}))
const FiltersBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",

    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }
}))

const EmailList = memo(({ emails, onDeleteFun }) => {


    return <Paper component={Box} variant="outlined" >
        {emails?.map((email) => <Box sx={{ borderBottom: 1, borderBottomColor: "divider" }} alignItems="center"  display="flex" justifyContent="space-between" p={1}>
            <Typography variant="body2" >{email}</Typography>
            <IconButton size="small" onClick={() => {
                onDeleteFun(email)
            }} >
                <Delete color="error" />
            </IconButton>
        </Box>)}
    </Paper>

})
const SupportEmailBox = memo(({ emails, user_id }) => {
    const dispatch = useDispatch()
    const [currentEmailList, setEmailList] = useState(emails ?? [])

    const onAddFun = useCallback((email) => {
        const emailsTemp = [...currentEmailList]
        emailsTemp.push(email)
        setEmailList(emailsTemp)

    }, [currentEmailList])

    const onAddButtonClick = () => {
        dispatch(openModal(<CreateController user_id={user_id?._id} callBack={(res) => { onAddFun(res?.emails) }} />, "sm"))
    }


    const onDeleteFun = useCallback((email) => {
        const emailsTemp = [...currentEmailList]
        const index = emailsTemp.findIndex((item) => item == email)
        if (index != -1)
            emailsTemp.splice(index, 1)
        setEmailList(emailsTemp)
        dispatch(
            callApiAction(
                async () => await deleteSupportEmailApi({ user_id:user_id?._id, emails: email }),
                async (response) => {

                },
                (err) => {

                }
            )
        )
    }, [currentEmailList, user_id])

    return <Paper component={Box} variant="outlined" >
        <Box p={2} sx={{ borderBottom: 1, borderBottomColor: "divider" }} display="flex" alignItems="center" justifyContent="space-between">
            <Typography fontWeight="600">{user_id?.name} ({user_id?.email})</Typography>
            <IconButton size="small" onClick={onAddButtonClick} >
                <Add color="info" />
            </IconButton>
        </Box>
        <Box p={2}>
            <EmailList emails={currentEmailList} onDeleteFun={onDeleteFun} />
        </Box>

    </Paper>
})
const ListUi = ({ title, filters, setFilters, list, loading, onCreateBtnClick }) => {

    const user = useSelector(state => state.user)

    return (
        <>

            <Box mb={3}>
                <Paper elevation={2} sx={{ width: "100%", padding: 4 }} >
                    <Box mb={4} >
                        <FilterTitleBox>
                            <Box>
                            <Typography variant="h5" >{title}</Typography>
                            <Typography variant="caption" mb={2} >Currently this feature is only for supplier</Typography>
                            </Box>
                            <Box mb={2}>
                                {<SubmitButton variant="contained" onClick={onCreateBtnClick} title={"Add Support Email"} />}
                            </Box>

                        </FilterTitleBox>


                        <FiltersBox mt={3} >

                            <PaddingBoxInDesktop mb={2} sx={{ display: "flex", justifyContent: "flex-end" }} pl={3} >


                            </PaddingBoxInDesktop>



                        </FiltersBox>

                    </Box>

                    <Box sx={{ minHeight: "300px" }}>
                        {
                            loading && <CenteredBox>
                                <CircularProgress />
                            </CenteredBox>
                        }
                        {
                            !loading && list?.length > 0 && list.map((item) => <SupportEmailBox key={item?._id} user_id={item?.user_id} emails={item?.emails} />)
                        }
                        {
                            !loading && list?.length == 0 && <NoDataComponent variant="body1" message="No Supporting Email Found" />
                        }
                    </Box>
                </Paper>
            </Box>

        </>
    )
}
export default ListUi