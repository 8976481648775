import * as React from 'react';

import MenuItem from '@mui/material/MenuItem';
import { Alert, AlertTitle, CircularProgress, Typography } from '@mui/material';
import { AddCircle, AutoAwesome } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import MessageDilog from '../../../components/MessageDilog';
import { closeModal, openModal } from '../../../store/actions/modalAction';
import { MODAL_KEYS } from '../../../utils/constants/modal.constant';
import { callApiAction } from '../../../store/actions/commonAction';
import { addLeadInOpenSolarApi } from '../../../apis/lead.api';
import { callSnackBar } from '../../../store/actions/snackbarAction';
import { SNACK_BAR_VARIETNS } from '../../../utils/constants/constants';

const LeadCreateInOpenSolarButton = ({ projectId, isUpdate, callBack = () => { } }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = React.useState(false)
    const submitFun = async (noEmail) => {
        setLoading(true);
        dispatch(
            callApiAction(
                async () => await addLeadInOpenSolarApi({ id: projectId, noEmail, isUpdate }),
                (response) => {
                    callBack()
                    dispatch(callSnackBar(`Lead ${isUpdate ? "updated" : "created"} in open solar`, SNACK_BAR_VARIETNS.suceess))
                    setLoading(false);
                },
                (err) => {
                    if (err == 'open_solar_email_exist') {
                        dispatch(openModal(
                            <MessageDilog
                                modalKey={MODAL_KEYS.LEAD_OPEN_SOLAR_MODAL}
                                confirmText='Proceed without email'
                                onSubmit={() => { submitFun(true) }}
                                title='Alert!'
                                message={
                                    <Alert
                                        severity='warning'


                                    >
                                        <AlertTitle>
                                            Email Exist In Open Solar!
                                        </AlertTitle>
                                        <Typography variant='caption'>
                                            The email address is already in use for another project in Open Solar. To create a new project, either use a different email address or create the project without associating an email.
                                        </Typography>
                                    </Alert>
                                }
                            />,
                            "sm",
                            false,
                            MODAL_KEYS.LEAD_OPEN_SOLAR_MODAL
                        ))
                    } else
                        dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
                    setLoading(false);
                }
            )
        );
        dispatch(closeModal(MODAL_KEYS.LEAD_OPEN_SOLAR_MODAL));
    };

    const onCreateButtonClick = () => {
        dispatch(openModal(
            <MessageDilog
                modalKey={MODAL_KEYS.LEAD_OPEN_SOLAR_MODAL}
                onSubmit={() => { submitFun() }}
                title='Alert!'
                message={`Are you sure to ${isUpdate ? "update" : "create"} lead in open solar?`}
            />,
            "sm",
            false,
            MODAL_KEYS.LEAD_OPEN_SOLAR_MODAL
        ))
    }



    return (
        <MenuItem onClick={onCreateButtonClick}>

            {loading ? <CircularProgress size={15} /> : (isUpdate ? <AutoAwesome fontSize='inherit' /> : <AddCircle fontSize='inherit' />)} &nbsp;{isUpdate ? "Update" : "Create"}</MenuItem>
    );
}
export default LeadCreateInOpenSolarButton