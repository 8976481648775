import { memo, useEffect, useState } from "react"
import moment from "moment"
import ListUi from "./ListUi"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { addLeadInOpenSolarApi, deleteLeadApi, getLeadApi } from "../../apis/lead.api"
import { closeModal, openModal } from "../../store/actions/modalAction"
import { useMemo } from "react"

import CreateController from "./create/CreateController"
import { AccountCircle, Add, Delete, Edit, Info, Repeat, Visibility, Warning, WbTwilight } from "@mui/icons-material"

import fileDownload from "js-file-download"
import { MODAL_KEYS } from "../../utils/constants/modal.constant"
import CustomDialog from "../../components/layouts/common/CustomDialog"
import AssignedSalesPerson from "./components/AssignedSalesPerson"
import StepViewComponent from "./components/StepViewComponent"
import { StatusIcon } from "./components/StatusComponent"
import CustomerView from "./components/CustomerView"
import LeadViewButton from "./components/LeadViewButton"
import StatusUpdateController from "./update/status-update/StatusUpdateController"
import { updateCentralLeadFilters } from "../../store/actions/leadFilterAction"


export const LeadListModal = ({ filters = {} }) => {

    return <CustomDialog
        id={MODAL_KEYS.LEAD}
        title="Leads"
    >
        <ListController modal defaultFilters={filters} />
    </CustomDialog>
}
const ListController = ({ defaultFilters = {}, modal }) => {

    const { leadFilters } = useSelector((state) => state)
    console.log("lead filtes", leadFilters)
    const dispatch = useDispatch()
    const title = "Leads"
    const fetchApi = getLeadApi

    const columns = useMemo(() => [
        {
            id: 1, fieldName: 'assigned_sales_person.name', minWidth: 25, label: <AccountCircle />, align: "left", renderValue: (params, setParams) => params.assigned_sales_person?.name ? <AssignedSalesPerson
                editable
                projectId={params?._id}
                salesPersonId={params?.assigned_sales_person?.id}
                title={params.assigned_sales_person?.name}
                onChangeSalesPerson={(res) => setParams({ ...params, ...(res ?? {}) })}
            /> : "NA",
        },
        { id: 2, minWidth: 100, fieldName: 'uid', label: 'UID', align: "left", renderValue: (params, setParams) => <LeadViewButton id={params?._id} title={params?.uid} params={params} setParams={setParams} /> },
        {
            id: 3, minWidth: 150, fieldName: 'contacts.0.full_name', sort: true, label: 'Customer', align: "left", renderValue: (params, setParams) => <CustomerView projectId={params?._id} projectUID={params?.uid} name={params?.contacts?.[0]?.full_name} lead_repeat_paramaters={params?.lead_repeat_paramaters ?? []} email={params?.contacts?.[0]?.email} phone={params?.contacts?.[0]?.phone} />
            //  params?.contacts?.[0]?.full_name ?? "NA" 
        },
        { id: 4, minWidth: 120, fieldName: 'lead_updated_date', label: 'Last Movement', align: "left", sort: true, renderValue: (params, setParams) => params.lead_updated_date && params.lead_updated_date != '' ? moment(params.lead_updated_date).format("DD/MM/YYYY HH:mm") : "NA" },
        { id: 5, minWidth: 120, fieldName: 'createdAt', label: 'Created At', align: "left", sort: true, renderValue: (params, setParams) => params.createdAt && params.createdAt != '' ? moment(params.createdAt).format("DD/MM/YYYY HH:mm") : "NA" },
        {
            id: 6,
            fieldName: 'source',
            label: 'Source',
            minWidth: 100,
            align: "left",
            sort: true,
            renderValue: (params) => params.source ? params.source.name : "NA",
        },
        {
            id: 7,
            fieldName: 'step.title',
            label: 'Step',
            minWidth: 100,
            align: "left",
            renderValue: (params) => <StepViewComponent id={params?._id} stepObj={params.step} pipelineObj={params?.pipeline_id} />

            // params.step ? params.step.title : "NA",
        },
        {
            id: 8,
            fieldName: 'lead_status',
            label: 'Status',
            sort: true,
            align: "left",
            renderValue: (params, setParams) => <StatusUpdateController
                salesPerson={params?.assigned_sales_person?.id}
                ButtonComponent={(props) => <StatusIcon id={params?._id} status={params?.lead_status}  {...props} />}
                id={params?._id}
                projectStatus={params?.status}
                status={params?.lead_status}
                onStatusChangeCallBack={(res) => { setParams({ ...params, ...(res ?? {}) }) }}
            />

            // <StatusIcon id={params?._id} status={params.lead_status} />,
        },
        {
            id: 9, fieldName: 'full_address', minWidth: 400, label: 'Address', align: "left",

        }
    ], [dispatch]);



    const [filters, setFilters] = useState({
        ...leadFilters,
        pageNo: 1,
        pageSize: 10,
        search: '',
        searchable: ['contacts.0.full_name', 'uid'],
        sort: 'updatedAt',
        sortDirection: -1,
        is_favourite: undefined
    })

    useEffect(() => {
        dispatch(updateCentralLeadFilters(filters))
    }, [filters])

    console.log("filterssss", filters)

    // do not change 

    const [loading, setLoading] = useState(false)
    const [exportLoading, setexportLoading] = useState(false)
    const [list, setList] = useState({})









    const fetchList = (isExport) => {

        const filtersToBePassed = {}

        for (let item in { ...filters }) {
            console.log("itemss", item)
            if (filters[item] && typeof filters[item] == "object" && filters[item]?._id) {
                filtersToBePassed[item] = filters[item]?._id
            } else if (filters[item]) {
                filtersToBePassed[item] = filters[item]
            }
        }
        console.log("filtersTobe passedd", filtersToBePassed)
        if (!isExport) {
            setLoading(true)
            dispatch(callApiAction(
                async () => await fetchApi({ ...filtersToBePassed, ...defaultFilters }),
                (response) => {
                    setList(response)
                    setLoading(false)
                },
                (err) => {
                    setLoading(false)
                }
            ))
        } else {
            setexportLoading(true)
            dispatch(callApiAction(
                async () => await fetchApi({ ...filtersToBePassed, ...defaultFilters, csv: true }),
                (response) => {
                    fileDownload(response, "lead.csv")
                    setexportLoading(false)
                },
                (err) => {

                    setexportLoading(false)
                },
                true
            ))
        }
    }

    const onCreateBtnClick = (e, alreadyInOpenSolar) => {
        dispatch(openModal(<CreateController callBack={async () => { fetchList() }} />, "lg", true))
    }


    const onExportBtnClick = () => { fetchList(true) }

    useEffect(() => {
        fetchList()
    }, [filters])



    return (
        <>
            <ListUi
                modal={modal}
                title={title}
                onCreateBtnClick={onCreateBtnClick}
                onExportBtnClick={onExportBtnClick}
                exportLoading={exportLoading}
                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}
                columns={columns}


            />

        </>
    )
}
export default ListController