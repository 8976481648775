import * as React from 'react';

import { styled } from '@mui/material/styles';
import { Button, Chip, CircularProgress } from '@mui/material';


const StyledChip = styled(Chip)(({ theme, bg, fontColor }) => ({


    minWidth: "50px",
    background: "white",
    borderRadius: theme.shape.borderRadius,
    minHeight: "unset",
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    height: "unset",
    border: "1px solid " + (bg ?? theme.palette.primary.main),
    position: "relative",

    "::after": {
        content: "' '",
        backgroundColor: bg ?? theme.palette.primary?.light,
        position: "absolute",
        top: "0px",
        left: "0px",
        right: '0px',
        opacity: 0.2,
        zIndex: 0,
        bottom: "0px",

    },
    "*": {

        minHeight: "unset",
        lineHeight: "100%",
        zIndex: 1,
        padding: theme.spacing(0),
        color: bg
    }

}));
const CustomChip = ({ ...props }) => {
    return <StyledChip {...props} />
}

export default CustomChip