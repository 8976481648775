import moment from "moment";
import { actions } from "../../utils/constants/constants";
import { accessToken, refreshToken } from "../../utils/helpers/helper";


const initialState = {
    filters: {
        leaderboard:true,
        leaderboardSortType:'sold',
        dateTitle:'this_month',
        startDate:moment().startOf('month').valueOf(),
        endDate:moment().endOf('month').valueOf(),
    },
    data: []

}
const leaderBoardReducer = (state = initialState, action) => {

    switch (action.type) {

        case actions.SET_LEADER_BOARD_FILTERS: return { ...state, filters:action.value };
        case actions.SET_LEADER_BOARD_DATA: return { ...state, data:action.value };
        default: return { ...state }
    }

}
export default leaderBoardReducer