
import { useDispatch } from "react-redux"
import { openModal } from "../../../store/actions/modalAction"

import { IconButton, Tooltip } from "@mui/material"
import { AddCircle } from "@mui/icons-material"
import AddStepController from "../steps/AddStepController"

const AddStepButton = ({ setParams, params }) => {
    const dispatch = useDispatch()

    const onAddStep = (tag) => {

        const steps = [...(params.steps ?? [])]
        steps.push(tag)
        setParams({ ...params, steps })
    }
    const onAddStepButtonClick = () => {
        dispatch(openModal(<AddStepController pipeline_id={params._id} callBack={onAddStep} />, "sm"))
    }
    return <>
        <Tooltip arrow  title="Create Step" >
            <IconButton size="small" onClick={onAddStepButtonClick}>
                <AddCircle color="info" fontSize="inherit" />
            </IconButton>
        </Tooltip>
    </>
}
export default AddStepButton