import { Button, ButtonBase, styled, Tooltip, Typography } from "@mui/material"
import { LEAD_STATUS, LEAD_STATUS_COLOR, LEAD_STATUS_ICON } from "../../../utils/constants/lead.constant"
import { findObjectKeyByValue } from "../../../utils/helpers/helper"

export const StatusCustomButton = styled(ButtonBase)(({ theme, color }) => ({

    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    background: color ?? theme.palette.primary.main,
    height: "25px",
    width: "25px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.light.main,
    fontSize: "20px"
}))
const StatusCustomButtonLarge = styled(ButtonBase)(({ theme, color }) => ({

    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    background: color ?? theme.palette.primary.main,

    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.light.main,
    fontSize: "20px"
}))

export const StatusIcon = ({ id, status, size ,...remaining}) => {

    if (size == "large")
        return <StatusCustomButtonLarge color={LEAD_STATUS_COLOR[status]} {...remaining} >
            <Typography variant="h6" lineHeight="100%" >
                {LEAD_STATUS_ICON[status]}
            </Typography>
            <Typography variant="body1" ml={2} >
                {findObjectKeyByValue(status, LEAD_STATUS)}
            </Typography>
        </StatusCustomButtonLarge>
    return <Tooltip arrow title={findObjectKeyByValue(status, LEAD_STATUS)} >
        <StatusCustomButton color={LEAD_STATUS_COLOR[status]} {...remaining}>

            {LEAD_STATUS_ICON[status]}

        </StatusCustomButton>
    </Tooltip>
}
