import { memo, useMemo, useState } from "react"
import useValidate from "../../../../store/hooks/useValidator"

import { useDispatch } from 'react-redux'
import { callApiAction } from "../../../../store/actions/commonAction"
import {  updateLeadTagsApi } from "../../../../apis/lead.api"
import { callSnackBar } from "../../../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../../../utils/constants/constants"
import TagsEditor from "../../../../components/inputs/TagsEditor"
import { TAG_TYPE } from "../../../../utils/constants/tags.constant"

const UpdateLeadTagsController = ({ callBack = () => { }, projectId, tags = [],disabled }) => {
    const validate = useValidate()
    const dispatch = useDispatch()



    const updateApi = updateLeadTagsApi

    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',
        id:projectId,
        tags,
    })



    const validationSchemaForStatusChange = useMemo(() => ([
        {
            required: true,
            value: fields.tags,
            field: 'Tags',
        },
    ]), [fields])





    const updateFun = async (keyWords) => {
        // const validationResponse = validate(validationSchemaForStatusChange)

        // if (validationResponse === true) {

            const passData = { ...fields }

            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await updateApi({ ...passData, tags: keyWords }),
                    async (response) => {
                        setFields({ ...fields, tags: keyWords })
                        await callBack({tags:keyWords})
                        setLoading(false)
                        dispatch(callSnackBar("Tags updated successfully", SNACK_BAR_VARIETNS.suceess))
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        // } else {
        //     console.log(validationResponse )
        //     setFields({ ...fields, 'err': validationResponse })
        // }
    }




    const onSubmit = async (keyWords) => {

        updateFun(keyWords)


    }




    return <TagsEditor onlyView={disabled} type={TAG_TYPE.LEAD} value={fields.tags} onChange={onSubmit} />
}
export default memo(UpdateLeadTagsController)