import { CircularProgress, FormControlLabel, Switch } from "@mui/material";
import { useDispatch } from "react-redux";
import { useState } from "react";

import { updateShowInstallerField } from "../../apis/notesType.api";
import { SNACK_BAR_VARIETNS } from "../../utils/constants/constants";
import { callSnackBar } from "../../store/actions/snackbarAction";
import { callApiAction } from "../../store/actions/commonAction";
import MessageDilog from "../../components/MessageDilog";
import { closeModal, openModal } from "../../store/actions/modalAction";

const UpdateShowToInstallerButton = ({ params, setParams }) => {
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(params.show_to_installer);
  const dispatch = useDispatch();

  const onStatusChange = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await updateShowInstallerField({ id: params._id }),
        (response) => {
          setLoading(false);
          setActive(!active);
          dispatch(
            callSnackBar(
              "Show To Installer status changed.",
              SNACK_BAR_VARIETNS.suceess
            )
          );
        },
        (err) => {
          setLoading(false);
          dispatch(
            callSnackBar(
              "Company Supplier cant be changed.",
              SNACK_BAR_VARIETNS.error
            )
          );
        }
      )
    );
    dispatch(closeModal());
  };

  const onStatus = () => {
    dispatch(
      openModal(
        <MessageDilog
          onSubmit={onStatusChange}
          title="Alert!"
          message={`Are you sure to View To Installer status changed "${params.title}" ?`}
        />,
        "sm"
      )
    );
  };

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <FormControlLabel
          sx={{
            display: "block",
          }}
          control={
            <Switch
              checked={active}
              value
              name="loading"
              color="primary"
              onClick={onStatus}
            />
          }
        />
      )}
    </>
  );
};
export default UpdateShowToInstallerButton;
