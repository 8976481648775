import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { callSnackBar } from "../../store/actions/snackbarAction";
import { useState } from "react";
import { SNACK_BAR_VARIETNS } from "../../utils/constants/constants";
import { CircularProgress, FormControlLabel, Switch } from "@mui/material";
import { updateActiveAgreementApi } from "../../apis/aggrement.api";
import MODULES from "../../utils/constants/module.constants";
import { moduleAccessCondition } from "../../utils/helpers/helper";

const UpdateActiveAgreementButton = ({ params, setParams }) => {
    const { user } = useSelector(state => state)
    const [loading, setLoading] = useState(false)
    const [active, setActive] = useState(params.is_active)
    const dispatch = useDispatch()


    const onStatusChange = () => {
        setLoading(true);
        dispatch(
            callApiAction(
                async () => await updateActiveAgreementApi({ id: params._id }),
                (response) => {

                    setLoading(false);
                    setActive(!active)
                    dispatch(callSnackBar("Agreement status changed.", SNACK_BAR_VARIETNS.suceess))
                },
                (err) => {
                    setLoading(false);
                    dispatch(callSnackBar("Agreement status cant be changed.", SNACK_BAR_VARIETNS.error))
                }
            )
        );
    };
    if (!moduleAccessCondition(user, [MODULES.UPDATE_INSTALLER_AGGREMENT]))
        return <></>
    return <>
        {loading ? <CircularProgress /> :
            <FormControlLabel
                sx={{
                    display: "block",
                }}
                control={
                    <Switch
                        checked={active}
                        value
                        name="loading"
                        color="primary"
                        onClick={(e) => {
                            onStatusChange();
                        }}
                    />
                }
            />}
    </>
}

export default UpdateActiveAgreementButton;