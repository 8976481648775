import { memo, useCallback, useEffect, useState } from "react";
import ListUi from "./ListUi";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";

import {
  Box,
  Chip,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useMemo } from "react";
import {
  CheckCircle,
  Delete,
  Edit,
  Info,
  Paragliding,
} from "@mui/icons-material";


import ProjectViewButton from "../projects/buttons/ProjectViewButtons";
import {
  findObjectKeyByValue,
  moduleAccessCondition,
} from "../../utils/helpers/helper";
import moment from "moment";
import MODULES from "../../utils/constants/module.constants";

import DetailedViewController from "./DetailedViewController";
import { SCHEDULEDCALL_STATUS } from "../../utils/constants/schedulecall.constant";
import { getScheduledCallApi, updateScheduledCallStatus } from "../../apis/scheduledcall.api";
import { CallButton } from "../projects/projectdetails/buttons/ProjectIconButtons";
import LeadViewButton from "../lead/components/LeadViewButton";


const ActionComponent = memo(({ params, fetchList }) => {
  const dispatch = useDispatch();

  const CompleteCall = () => {
    dispatch(
      callApiAction(
        async () =>
          await updateScheduledCallStatus({
            id: params?._id,
            status: SCHEDULEDCALL_STATUS.COMPLETED,
          }),
        (response) => {
          fetchList();
        },
      )
    );
  };

  return (
    <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
      {params.status === SCHEDULEDCALL_STATUS.PENDING && (
        <Tooltip arrow title="Complete call">
          <IconButton size="inherit" disabled={params.status === SCHEDULEDCALL_STATUS.COMPLETED} onClick={CompleteCall}>
            <CheckCircle color="success" />
          </IconButton>
        </Tooltip>
      )}
      <CallButton phone={params.project_id.contact} />
    </Box>
  );
});

const ListController = ({ project_id, modal }) => {
  const dispatch = useDispatch();

  const title = "Scheduled Calls";
  const fetchApi = getScheduledCallApi;
  const deleteApi = null;

  const columns = useMemo(
    () => [

      {
        id: 1,
        fieldName: "project_id",
        label: "Project",
        align: "left",
        sort: true,
        renderValue: (params,setParams) => (
          <LeadViewButton id={params?.project_id?._id} title= {params?.project_id?.uid} params={params} setParams={setParams} >
           
          </LeadViewButton>
        ),
      },
      { id: 2, fieldName: 'scheduled_date', label: 'Scheduled date', align: "left", renderValue: (params) => moment(params.scheduled_date).format("DD/MM/YYYY"), },
      { id: 3, fieldName: 'pre_remarks', label: 'Remarks', align: "left" },
      {
        id: 4,
        fieldName: "status",
        label: "Status",
        align: "left",
        sort: true,
        renderValue: (params) => (
          <Chip
            size="small"
            label={findObjectKeyByValue(params?.status, SCHEDULEDCALL_STATUS)}
            color={
              params.status == SCHEDULEDCALL_STATUS.COMPLETED ? "success" : "default"
            }
          />
        ),
      },
      {
        id: 5,
        fieldName: "",
        label: "Action",

        align: "right",
        renderValue: (params, setParams) => (
          <>
            <ActionComponent
              params={params}
              fetchList={fetchList}
            />
          </>
        ),
      },
    ],
    [dispatch]
  );

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    searchable: ["name"],
    sort: "createdAt",
    category:"Today",
    project_id,    
    subCategory: null,
    status: SCHEDULEDCALL_STATUS.PENDING,
    sortDirection: -1,
  });


  // do not change

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState({});

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchApi({ ...filters }),
        (response) => {
          setList(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };


  useEffect(() => {
    fetchList();
  }, [filters]);

  return (
    <>
      <ListUi
        modal={modal}
        title={title}
        filters={filters}
        setFilters={setFilters}
        loading={loading}
        list={list}
        columns={columns}
      />
    </>
  );
};
export default ListController;
