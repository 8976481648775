import {  Box, CircularProgress} from "@mui/material"
import { memo } from "react"
import { useSelector } from 'react-redux'
import CustomInput from "../../components/inputs/CustomInput"
import CustomDialog from "../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../components/layouts/OneViewBox"
import { DesktopDatePicker } from "@mui/x-date-pickers"
import moment from "moment"





const CreateUi = ({ title, isUpdate, fields, setFields, loading, onSubmit }) => {
    const { user } = useSelector(state => state)


    return <>

        <CustomDialog

            loading={loading}
            err={fields.err}
            onSubmit={onSubmit}
            title={`${isUpdate ? "Update" : "Raise"} ${title}`}
            closeText="Close"
            confirmText={`${isUpdate ? "Update" : "Raise"}`}
        >


            {loading && <CenteredBox><CircularProgress /></CenteredBox>}
            {<>
                <Box mt={2} />
                <CustomInput
                    autoFocus={true}
                    disabled={loading}
                    value={fields.name}
                    onChange={(e) => setFields({ ...fields, err: '', name: e.target.value })}
                    type="text"
                    label={"Holiday Name*"}
                    margin="none"

                />
                <DesktopDatePicker
                    label="Holiday Date*"
                    inputFormat="DD/MM/YYYY"
                    value={moment(fields.date)}
                    renderInput={(props) => <CustomInput {...props} />}
                    onChange={async (changedVal) => { setFields({ ...fields, date: changedVal.toISOString() }) }}
                />

            </>}

        </CustomDialog>
    </>
}
export default memo(CreateUi)