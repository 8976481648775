import { memo, useCallback, useEffect, useState } from "react"
import ListUi from "./ListUi"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"

import { closeModal, openModal } from "../../store/actions/modalAction"

import CreateController from "./CreateController"
import { MODAL_KEYS } from "../../utils/constants/modal.constant"
import CustomDialog from "../../components/layouts/common/CustomDialog"
import { getActivityLogsApi } from "../../apis/lead.api"
 

export const ActivityLogsModal = ({ project_id, defaultLogs,setProjectLogs,modalKey = MODAL_KEYS.ACTIVITY_LOGS_LIST }) => {

    return <CustomDialog
        id={modalKey}
        title={"Activity Logs"}
    >
        <ListController modal defaultLogs={defaultLogs} setProjectLogs={setProjectLogs} project_id={project_id} createModalKey={MODAL_KEYS.ADD_ACTIVITY_LOGS} />
    </CustomDialog>
}


const ListController = ({ project_id,defaultLogs,setProjectLogs, createModalKey = MODAL_KEYS.SOURCES_CREATE }) => {
    const dispatch = useDispatch()

    const fetchApi = getActivityLogsApi


    const [filterfavourite,setFilterFavourite] = useState(undefined)
    const [filters, setFilters] = useState({
        id: project_id,
        is_favourite:filterfavourite
        // is_favourite:filterfavourite,
    })

    // do not change 
    const [loading, setLoading] = useState(false)
    const [list, setList] = useState(defaultLogs??[])

    const handleSwitchToggle = (e) => {
        const isStarred = e.target.checked 
        const filterNote = isStarred ? true : undefined;
        setFilterFavourite(filterNote);
        setFilters((prevFilters) => ({
          ...prevFilters,
          is_favourite: filterNote,
        }));
      };

    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchApi({ ...filters }),
            (response) => {
                console.log("responsessssss",response)
                setList(response.activity_logs)
                setProjectLogs(response?.activity_logs ?? [])
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }
    const onCreateBtnClick = () => {
        dispatch(openModal(<CreateController modalKey={createModalKey} project_id={project_id} callBack={async () => { fetchList() }} />, "sm", undefined, createModalKey))
    }

    useEffect(() => {
        if(!defaultLogs ||defaultLogs.length==0)
        fetchList()
    }, [filters,defaultLogs])

    return (
        <>
            <ListUi
                filterfavourite={filterfavourite}
                handleSwitchToggle={handleSwitchToggle}   
                onCreateBtnClick={onCreateBtnClick}
                onRefresh={fetchList}
                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}
            />

        </>
    )
}
export default ListController