import { CircularProgress, Typography } from "@mui/material";

import { Fragment, memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import CustomInput from "../../components/inputs/CustomInput";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import { CenteredBox } from "../../components/layouts/OneViewBox";
import CustomEditor from "../../components/inputs/CustomEditor";

const CreateUi = ({
  title,
  isUpdate,
  fields,
  setFields,
  loading,
  onSubmit,
}) => {
  return (
    <>
      <CustomDialog
        loading={loading}
        err={fields.err}
        onSubmit={onSubmit}
        title={`${isUpdate ? "Update" : "Create"} ${title}`}
        closeText="Close"
        confirmText={`${isUpdate ? "Update" : "Create"}`}
      >
        {loading ? (
          <CenteredBox>
            <CircularProgress />
          </CenteredBox>
        ) : (
          <>
            <Typography variant="h6" mt={3}>
              Announcement Title:*
            </Typography>

            <CustomInput
              name="announcement title"
              id="announcement_title"
              required
              helperText="Upto 100 Character"
              disabled={loading}
              value={fields.announcement_title}
              onChange={(e) => {
                if (!e.target.value || e.target.value.length <= 100)
                  setFields({
                    ...fields,
                    err: "",
                    announcement_title: e.target.value,
                  });
              }}
              type="text"
              placeholder="Write Announcement Title..."
            />
            <Typography variant="h6" mb={2} mt={2}>
              Announcement Description:*
            </Typography>
            <CustomEditor
            placeholder="Write Announcement Description..."
              value={fields.announcement_description}
              onChange={(val) => {
                setFields({ ...fields, announcement_description: val });
              }}
            />
          </>
        )}
      </CustomDialog>
    </>
  );
};
export default memo(CreateUi);
