import { isVisible } from "@testing-library/user-event/dist/utils";
import { actions } from "../../utils/constants/constants";

const initialState = {
    phone: null,  // Store the Aircall phone instance
    isVisible: false
};

const aircallReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_PHONE_INSTANCE:
            return {
                ...state,
                phone: action.payload,  // Update state with the phone instance
            };
        case actions.TOGGLE_PHONE_VISIBILITY:
            return {
                ...state,
                isVisible: !state.isVisible,
            };
        default:
            return state;
    }
};

export default aircallReducer;