import { Add, AddCircle } from "@mui/icons-material"
import { Box, ButtonBase, Dialog, Grid, IconButton, Tooltip, Typography } from "@mui/material"
import { memo, useState } from "react"
import { CenteredBox } from "../../../components/layouts/OneViewBox"
import CreateDocumentController from "./CreateDocumentController"

const CreateDocumentButton = ({ dispatch, project_id,disabled }) => {
    const [open, setOpen] = useState(false)
    const handleClose = () => {
        setOpen(false)
    }
    const onCreateBtnClick = ()=>{
        setOpen(true)
    }
    
    return <>


        <Tooltip arrow  title="Add Document" >
            <IconButton sx={{ padding: 0 }} onClick={onCreateBtnClick} disabled={disabled}>
                <Typography variant="subtitle1" lineHeight="100%">
                    <AddCircle fontSize="inherit" color="primary" />
                </Typography>
            </IconButton>
        </Tooltip>



        <CreateDocumentController project_id={project_id} dispatch={dispatch} open={open} closeModal={handleClose} />



    </>
}
export default memo(CreateDocumentButton)