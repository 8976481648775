
import { BatteryCharging20, Bento, CalendarViewMonth, Call, Cancel, CheckCircle, CompareOutlined, Email, FiberManualRecord, Grid3x3Outlined, Inventory2, OpenInNew, People } from "@mui/icons-material"
import { Autocomplete, Button, ButtonBase, ButtonGroup, Chip, CircularProgress, Collapse, Divider, Grid, IconButton, Paper, Skeleton, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tabs, TextField, Tooltip, Typography, styled } from "@mui/material"
import { Box } from "@mui/system"
import { Fragment, memo, useEffect, useState } from "react"

import { CenteredBox } from "../../../components/layouts/common/boxes"
import SelectedSystemButton from "./SelectSystemButton"
import { useDispatch } from "react-redux"
import { MODAL_KEYS } from "../../../utils/constants/modal.constant"
import CustomDialog from "../../../components/layouts/common/CustomDialog"
import { getProjectSystems } from "../../../apis/projects.api"
import { callApiAction } from "../../../store/actions/commonAction"
import { groupByArray, titleCase } from "../../../utils/helpers/helper"
import { DEFAULT_PRODUCT_CATEGORY } from "../../../utils/constants/product.constant"



const CustomBox = styled(Box)(({ theme }) => ({
    width: "100%",

    border: "1px solid " + theme.palette.primary.main,
    borderTop: "none"


}))
const CustomOuterHeaderBox = styled(Box)(({ theme, selected }) => ({
    width: "100%",

    background: selected ? theme.palette.success.main : theme.palette.primary.main,
    color: theme.palette.text.invert,
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius


}))

const CustomOuterBox = styled(Box)(({ theme }) => ({
    width: "100%",
    border: "1px solid " + theme.palette.light[200],


}))
const CustomHeaderBox = styled(Box)(({ theme }) => ({
    width: "100%",
    border: "1px solid " + theme.palette.primary.main,
    alignItems: "center",
    borderTopLeftRadius: theme.shape.borderRadius,
    background: theme.palette.secondary.main,
    display: "flex",
    borderTopRightRadius: theme.shape.borderRadius


}))

const ProductRow = ({ code, name, quantity }) => {
    return <Grid container rowSpacing={3} spacing={3}>
        <Grid item md={4} xs={12} >
            <Typography variant="body1" fontWeight="600" lineHeight="100%" color="orange" mb={2}> Name</Typography>
            <Typography variant="body2" lineHeight="100%">  {name}</Typography>
        </Grid>
        <Grid item md={4} xs={12} >
            <Typography variant="body1" fontWeight="600" lineHeight="100%" color="orange" mb={2}> Code</Typography>
            <Typography variant="body2" lineHeight="100%">  {code}</Typography>
        </Grid>
        <Grid item md={4} xs={12} >
            <Typography variant="body1" fontWeight="600" lineHeight="100%" color="orange" mb={2}> Quantity</Typography>
            <Typography variant="body2" lineHeight="100%">  {quantity}</Typography>
        </Grid>
    </Grid>
}
const CollapsableRow = ({ system, id, selected, selectedSystem, callBack }) => {

    const [open, setOpen] = useState(id == 1)
    const products = system.products
    const productObj = products ? groupByArray(products, 'productcategory') : {}
    return <Box mb={2}>

        <CustomOuterHeaderBox selected={selected} p={3} onClick={() => setOpen(!open)} component={ButtonBase} sx={{ width: "100%" }}>

            {system.name}


        </CustomOuterHeaderBox>
        <Collapse in={open}>
            <CustomOuterBox p={3}>
                {!selectedSystem && <Box mb={4} >

                    <SelectedSystemButton id={system._id} selected={selectedSystem} callBack={callBack} />
                </Box>}
                <Box mb={3}>
                    <Grid container rowSpacing={3} spacing={3}>
                        <Grid item md={4} xs={12} >
                            <Typography variant="body1" fontWeight="600" lineHeight="100%" color="orange" mb={2}>Annual Output </Typography>
                            <Typography variant="body2" lineHeight="100%">  {system.annual_output_kwh?.toFixed?.(2)} KWH</Typography>
                        </Grid>
                        <Grid item md={4} xs={12} >
                            <Typography variant="body1" fontWeight="600" lineHeight="100%" color="orange" mb={2}> Amount Without Tax</Typography>
                            <Typography variant="body2" lineHeight="100%">  {system.amount_excluding_tax?.toFixed?.(2)}</Typography>
                        </Grid>
                        <Grid item md={4} xs={12} >
                            <Typography variant="body1" fontWeight="600" lineHeight="100%" color="orange" mb={2}> Amount With Tax</Typography>
                            <Typography variant="body2" lineHeight="100%">  {system.amount_including_tax?.toFixed?.(2)}</Typography>
                        </Grid>

                        <Grid item md={4} xs={12} >
                            <Typography variant="body1" fontWeight="600" lineHeight="100%" color="orange" mb={2}> KW STC</Typography>
                            <Typography variant="body2" lineHeight="100%">  {system.kw_stc ? system.kw_stc : "NA"}</Typography>
                        </Grid>

                        <Grid item md={4} xs={12} >
                            <Typography variant="body1" fontWeight="600" lineHeight="100%" color="orange" mb={2}> Offset</Typography>
                            <Typography variant="body2" lineHeight="100%">  {system.offset ? system.offset : "NA"}%</Typography>
                        </Grid>


                    </Grid>

                </Box>
                <Box mt={3}>

                    <Typography variant="h6" lineHeight="100%">  Components</Typography>
                </Box>

                <CustomHeaderBox p={2} pl={3} mt={2}>
                    <Typography variant="h6" lineHeight="100%" color="orange" >  <CalendarViewMonth fontSize="inherit" /></Typography>
                    <Typography ml={2} variant="body1" lineHeight="100%">  Pannels</Typography>
                </CustomHeaderBox>

                <CustomBox p={3}>
                    {productObj[titleCase(DEFAULT_PRODUCT_CATEGORY.PANELS)]?.length > 0 ? productObj[titleCase(DEFAULT_PRODUCT_CATEGORY.PANELS)]?.map((item) => <ProductRow code={item.code} name={item?.product} quantity={item.quantity} key={item?.code} />)
                        : <CenteredBox p={3}>No Pannel Found</CenteredBox>}

                </CustomBox>


                <CustomHeaderBox p={2} pl={3} mt={3}>
                    <Typography variant="h6" lineHeight="100%" color="orange" >  <Bento fontSize="inherit" /></Typography>
                    <Typography ml={2} variant="body1" lineHeight="100%">  Inverters</Typography>
                </CustomHeaderBox>

                <CustomBox p={3}>
                    {productObj[titleCase(DEFAULT_PRODUCT_CATEGORY.INVERTERS)]?.length > 0 ? productObj[titleCase(DEFAULT_PRODUCT_CATEGORY.INVERTERS)]?.map((item) => <ProductRow code={item.code} name={item?.product} quantity={item.quantity} key={item?.code} />)
                        : <CenteredBox p={3}>No Inverter Found</CenteredBox>}
                </CustomBox>



                <CustomHeaderBox p={2} pl={3} mt={3}>
                    <Typography variant="h6" lineHeight="100%" color="orange" >  <BatteryCharging20 fontSize="inherit" /></Typography>
                    <Typography ml={2} variant="body1" lineHeight="100%">  Batteries</Typography>
                </CustomHeaderBox>

                <CustomBox p={3}>
                    {productObj[titleCase(DEFAULT_PRODUCT_CATEGORY.BATTERIES)]?.length > 0 ? productObj[titleCase(DEFAULT_PRODUCT_CATEGORY.BATTERIES)]?.map((item) => <ProductRow code={item.code} name={item?.product} quantity={item.quantity} key={item?.code} />)
                        : <CenteredBox p={3}>No Battery Found</CenteredBox>}
                </CustomBox>



                <CustomHeaderBox p={2} pl={3} mt={3}>
                    <Typography variant="h6" lineHeight="100%" color="orange" >  <CompareOutlined fontSize="inherit" /></Typography>
                    <Typography ml={2} variant="body1" lineHeight="100%">  Other Component</Typography>
                </CustomHeaderBox>

                <CustomBox p={3}>
                    {productObj[titleCase(DEFAULT_PRODUCT_CATEGORY.OTHER)]?.length > 0 ? productObj[titleCase(DEFAULT_PRODUCT_CATEGORY.OTHER)]?.map((item) => <ProductRow code={item.code} name={item?.product} quantity={item.quantity} key={item?.code} />)
                        : <CenteredBox p={3}>No Other Component Found</CenteredBox>}
                </CustomBox>



                {
                    Object.keys(productObj)
                        .filter((productCategory) => ![titleCase(DEFAULT_PRODUCT_CATEGORY.PANELS), titleCase(DEFAULT_PRODUCT_CATEGORY.INVERTERS), titleCase(DEFAULT_PRODUCT_CATEGORY.BATTERIES), titleCase(DEFAULT_PRODUCT_CATEGORY.OTHER)].includes(productCategory))
                        .map((productCategory) => {

                            return <Fragment key={productCategory} >

                                <CustomHeaderBox p={2} pl={3} mt={3}>
                                    <Typography variant="h6" lineHeight="100%" color="orange" >  <Inventory2 fontSize="inherit" /></Typography>
                                    <Typography ml={2} variant="body1" lineHeight="100%"> {titleCase(productCategory)}</Typography>
                                </CustomHeaderBox>
                                <CustomBox p={3}>
                                    {productObj[productCategory]?.length > 0 ? productObj[productCategory]?.map((item) => <ProductRow code={item.code} name={item?.product} quantity={item.quantity} key={item?.code} />)
                                        : <CenteredBox p={3}>No {productCategory} Found</CenteredBox>}
                                </CustomBox>
                            </Fragment>
                            // return <Grid item xs={6} >
                            //     <Box sx={{ display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
                            //         <Typography variant="body2" fontWeight={600}>{titleCase(productCategory)}: </Typography>
                            //         <Box width="100%" sx={{ rowGap: 2 }} >
                            //             {productObj[productCategory]?.length > 0 ? productObj[productCategory]?.map((item) => <ModuleComponent code={item.code} quantity={item.quantity} key={item?.code} />) : "NA"}
                            //         </Box>
                            //     </Box>
                            // </Grid>
                        })

                }


            </CustomOuterBox>
        </Collapse>

    </Box>
}

const ProjectSystemDetailsUi = ({ project_id, selected_system, defaultSystems, setDefaultSystems, callBack }) => {
    const dispatch = useDispatch()
    const modalKey = MODAL_KEYS.SYSTEMS
    const [loading, setLoading] = useState(false)
    const [systems, setSystems] = useState(defaultSystems ?? [])
    const fetchSystemsProjectById = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await getProjectSystems({ id: project_id }),
            (response) => {
                setSystems([...response])
                setDefaultSystems([...response])
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }
    useEffect(() => {

        if (defaultSystems && defaultSystems?.length == 0)
            fetchSystemsProjectById()

    }, [])


    return <>
        <CustomDialog
            id={modalKey}
            title="Project Systems"
        >
            {
                loading && <CenteredBox><CircularProgress /></CenteredBox>
            }

            {
                !loading && <>


                    {systems && Array.isArray(systems) && systems.length > 0 ?
                        systems.map((system, index) => (
                            <CollapsableRow callBack={callBack} selectedSystem={selected_system?._id} selected={selected_system?._id == system._id} system={system} key={system._id} id={index + 1} />
                        ))

                        :
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Typography variant="h4" color="grey.main">No System Attached</Typography>
                        </Box>}
                </>
            }

        </CustomDialog>


    </>


    return <></>
}
export default (ProjectSystemDetailsUi)