const companyDetails = {
    name: process.env.REACT_APP_COMPANY_NAME,
    phone: process.env.REACT_APP_COMPANY_PHONE,
    email: process.env.REACT_APP_COMPANY_EMAIL,
    website: process.env.REACT_APP_COMPANY_WEBSITE,
}




export const defaultTemplate = (message) => {
    return `
    Hello from ${process.env.REACT_APP_COMPANY_SHORT_NAME}
    `
}
export const companyTemplate = (message) => {
    return `
    Hello from ${process.env.REACT_APP_COMPANY_SHORT_NAME}
    `
}
export const welcomeEmail = () => {
    return `
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:19px;font-family:"Times New Roman",serif;'>Dear _____,</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:16px;font-family:"Times New Roman",serif;'>Thank&nbsp;you for giving us the opportunity to serve you with your solar requirements.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:16px;font-family:"Times New Roman",serif;'>As agreed, here is the solar PV system you chose to proceed with.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:16px;font-family:"Times New Roman",serif;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:19px;font-family:"Times New Roman",serif;'>Solar System Details:<br>&nbsp;</span></p>
<ul style="list-style-type: disc;">
    <li><span style='font-family:"Times New Roman",serif;font-size:12.0pt;'>16 X &nbsp;Sunpro Power 415W Mono Solar Panels</span></li>
    <li><span style='font-family:"Times New Roman",serif;font-size:16px;'>1 &nbsp; X &nbsp;Growatt 5KW Single Phase Inverter</span></li>
    <li><span style='font-family:"Times New Roman",serif;font-size:16px;'>1 &nbsp; X &nbsp;Wifi Monitoring System</span></li>
    <li><span style='font-family:"Times New Roman",serif;font-size:12.0pt;'>1 &nbsp; X &nbsp;Installation as per Solar VIC Standards<br>&nbsp;<br>&nbsp;</span></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:21px;font-family:"Times New Roman",serif;'>Solar System Cost:<br>&nbsp;</span></p>
<ul class="decimal_type" style="list-style-type: disc;">
    <li><span style='font-family:"Times New Roman",serif;font-size:12.0pt;'>Total Purchase Price: $3799</span></li>
    <li><span style='font-family:"Times New Roman",serif;font-size:16px;'>Solar VIC Rebates: $1400</span></li>
    <li><span style='font-family:"Times New Roman",serif;font-size:16px;'>Solar VIC Interest free Loan: $1400 (~$29.17 / month)</span></li>
    <li><span style='font-family:"Times New Roman",serif;font-size:12.0pt;'>Upfront Cost: $999</span></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:21px;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:21px;font-family:"Times New Roman",serif;'>Required documents to start the process:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:21px;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<ul style="list-style-type: disc;">
    <li><span style='font-family:"Times New Roman",serif;font-size:12.0pt;'>Full Meter Picture with Switchboard</span></li>
    <li><span style='font-family:"Times New Roman",serif;font-size:16px;'>Full Electricity Bill with NMI number</span></li>
    <li><span style='font-family:"Times New Roman",serif;font-size:16px;'>Roof Images / Property Pictures</span></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:36.0pt;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:16px;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:21px;line-height:115%;font-family:"Times New Roman",serif;'>Solar System Installation Process:</span></p>
<ol style="list-style-type: lower-roman;">
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;'>Net meter pre-approval with electricity distributor</span></li>
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;'>Solar Vic application for rebates approval</span></li>
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;'>Balance Payment Receipt</span></li>
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;'>Solar System Installation by CEC Accredited Installer</span></li>
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;'>Inspection by Certified Electrician</span></li>
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;'>Grid Connection Documents</span></li>
    <li><span style='line-height:115%;font-family:"Times New Roman",serif;'>Start Savings</span></li>
</ol>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>If you have any other queries regarding your solar, fell free to call ______ or email on _______.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>Thanks and Regards,<br></span></p>
    
    `
}
export const vicApplicationtemplate = () => {
    return `
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:#222222;'>Dear Customer</span><span style='font-size:19px;font-family:"Arial",sans-serif;color:#222222;'>,</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><span style='font-family:"Arial",sans-serif;color:#222222;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:#222222;'>We are glad to welcome you in ${process.env.REACT_APP_COMPANY_SHORT_NAME}; happy solar family.&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:#222222;'>We have submitted your rebates application with solar Victoria.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:#222222;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><strong><span style='font-size:16px;font-family:"Arial",sans-serif;color:#222222;'>Documents required for completing the&nbsp;Application:</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:#222222;'>&nbsp;</span></p>
<ol style="list-style-type: undefined;">
    <li><span style='font-family:"Arial",sans-serif;font-size:12.0pt;color:#222222;'>Home Ownership Proof : (Council Rates Notice)</span></li>
    <li><span style='font-family:"Arial",sans-serif;font-size:12.0pt;color:#222222;'>Income Proof : Latter of Assessment</span></li>
    <li><span style='font-family:"Arial",sans-serif;font-size:12.0pt;color:#222222;'>Identity Proof (100 Pint ID)</span></li>
    <li><span style='font-family:"Arial",sans-serif;font-size:12.0pt;color:#222222;'>Bank Details (If applying for the interest free loan)</span></li>
</ol>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:#222222;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:#222222;'>Use below details to access your quotation on Solar VIC Portal.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:#222222;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:#222222;'>Solar Vic Portal:&nbsp;</span><a href="https://service.vic.gov.au/services/solar-homes"><span style='font-size:16px;font-family:"Arial",sans-serif;'>https://service.vic.gov.au/services/solar-homes</span></a></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:#222222;'>Retailer Name: <strong> ${process.env.REACT_APP_COMPANY_NAME}</strong></span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:#222222;'>Quote no:&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:#222222;'>Total Purchase Amount (before any deductions)<strong>:&nbsp;</strong></span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:#222222;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:#222222;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:#222222;'>Please find the&nbsp;rebate&nbsp;application&nbsp;procedure guide as attachment and feel free to contact should you need further assistance.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><span style='font-size:19px;font-family:"Arial",sans-serif;color:#222222;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:12.65pt;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:#222222;'>If you have any other queries regarding your solar, feel free to call 1300 306 122 or email on&nbsp;</span><a href="mailto: ${process.env.REACT_APP_HELP_EMAIL}" target="_blank"><span style='font-size:16px;font-family:"Arial",sans-serif;color:#1155CC;'>${process.env.REACT_APP_HELP_EMAIL}</span></a><span style='font-size:16px;font-family:"Arial",sans-serif;color:#222222;'>.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:12.65pt;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:#222222;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:#222222;'>Thanks and Regards</span></p>
    
    `
}
export const vicApprovaltemplate = () => {
    return `
    
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:#222222;'>Dear Customer</span><span style='font-size:19px;font-family:"Arial",sans-serif;color:#222222;'>,</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><span style='font-family:"Arial",sans-serif;color:#222222;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:#222222;'>We are glad to inform you that you solar vic rebates approval has been successfully received.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:#222222;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:#222222;'>Below are the approval details.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:#222222;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:#222222;'>Application Number: INS</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:#222222;'>Solar Home Rebates: $1400<br>&nbsp;Solar Home Interest Free Loan: $1400 (~$29.17 / month over 48 months)</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:#222222;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:#222222;'>Kindly understand the below process to proceed with the solar installation at your property.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:#222222;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:#222222;'>Attachment:</span></p>
<ul style="list-style-type: disc;">
    <li><span style='font-family:"Arial",sans-serif;font-size:12.0pt;color:#222222;'>Balance Payment Invoice</span></li>
    <li><span style='font-family:"Arial",sans-serif;font-size:12.0pt;color:#222222;'>Net Meter Application Approval (If already received)</span></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><span style='font-family:"Arial",sans-serif;color:#222222;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:12.65pt;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><strong><span style='font-size:16px;font-family:"Arial",sans-serif;color:#222222;'>Next Step to proceed with solar installation:</span></strong></p>
<ul style="list-style-type: disc;margin-left:62px;">
    <li><span style='font-family:"Arial",sans-serif;font-size:12.0pt;color:#222222;'>Net meter pre-approval with from electricity distributor</span></li>
    <li><span style='font-family:"Arial",sans-serif;font-size:12.0pt;color:#222222;'>Pay the Balance Payment and send us the Receipt</span></li>
    <li><span style='font-family:"Arial",sans-serif;font-size:12.0pt;color:#222222;'>Booking of Solar System Installation by CEC Accredited Installer</span></li>
    <li><span style='font-family:"Arial",sans-serif;font-size:12.0pt;color:#222222;'>Inspection by Certified Electrician</span></li>
    <li><span style='font-family:"Arial",sans-serif;font-size:12.0pt;color:#222222;'>Grid Connection Documents</span></li>
    <li><span style='font-family:"Arial",sans-serif;font-size:12.0pt;color:#222222;'>Start Savings</span></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:12.65pt;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><span style='font-family:"Arial",sans-serif;color:#222222;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:12.65pt;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:#222222;'>If you have any other queries regarding your solar, feel free to call 1300 306 122 or email on&nbsp;</span><a href="mailto:${process.env.REACT_APP_HELP_EMAIL}" target="_blank"><span style='font-size:16px;font-family:"Arial",sans-serif;color:#1155CC;'>${process.env.REACT_APP_HELP_EMAIL}</span></a><span style='font-size:16px;font-family:"Arial",sans-serif;color:#222222;'>.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:12.65pt;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:#222222;'>&nbsp;</span></p>
<p><span style='font-size:16px;line-height:115%;font-family:"Arial",sans-serif;color:#222222;'>Thanks and Regards</span></p>
    `
}
export const installtionBookingTemplate = () => {
    return `
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:#222222;'>Dear Customer</span><span style='font-size:19px;font-family:"Arial",sans-serif;color:#222222;'>,</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><span style='font-family:"Arial",sans-serif;color:#222222;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:#222222;'>We are pleased to inform you that your solar system installation has been booked.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:#222222;'>Below are the booking details:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:#222222;'>&nbsp;</span></p>
<ul style="list-style-type: disc;">
    <li><span style='font-family:"Arial",sans-serif;font-size:12.0pt;color:#222222;'>Date:</span></li>
    <li><span style='font-family:"Arial",sans-serif;'>Name of the Installer:</span></li>
    <li><span style='font-family:"Arial",sans-serif;font-size:12.0pt;color:#222222;'>Approximate Installer&rsquo;s arrival time::</span></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:#222222;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:#222222;'>Attachments:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:#222222;'>&nbsp;</span></p>
<ul style="list-style-type: disc;">
    <li><span style='font-family:"Arial",sans-serif;font-size:12.0pt;color:#222222;'>Solar Panels Layout as per available areal images of your property</span></li>
    <li><span style='font-family:"Arial",sans-serif;font-size:12.0pt;color:#222222;'>Balance Invoice (please ignore if already paid)</span></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:#222222;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:#222222;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><span style='font-family:"Arial",sans-serif;color:#222222;'>Please Note:</span></p>
<ul style="list-style-type: disc;">
    <li><span style='font-family:"Arial",sans-serif;color:#222222;'>If you have any issue with the design share in the attachment, call 1300 306 122 or email on&nbsp;</span><a href="mailto:${process.env.REACT_APP_ADMIN_EMAIL}"><span style=";">${process.env.REACT_APP_ADMIN_EMAIL}</span></a><span style='font-family:"Arial",sans-serif;color:#222222;'>&nbsp;before 72 hours of installation.</span></li>
    <li><span style='font-family:"Arial",sans-serif;color:#222222;'>Final Design will be prepared by installer on the site.</span></li>
    <li><span style='font-family:"Arial",sans-serif;color:#222222;'>Any extra work required on site excluding contract will attract extra charges.</span></li>
    <li><span style='font-family:"Arial",sans-serif;color:#222222;'>Any changes in the number of panels need to be discussed with ${process.env.REACT_APP_COMPANY_NAME} before job starts.</span></li>
</ul>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:.0001pt;margin-left:36.0pt;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><span style='font-family:"Arial",sans-serif;color:#222222;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:12.65pt;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:#222222;'>If you have any other queries regarding your solar, feel free to call 1300 306 122 or email on&nbsp;</span><a href="mailto:${process.env.REACT_APP_HELP_EMAIL}" target="_blank"><span style='font-size:16px;font-family:"Arial",sans-serif;color:#1155CC;'>${process.env.REACT_APP_HELP_EMAIL}</span></a><span style='font-size:16px;font-family:"Arial",sans-serif;color:#222222;'>.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:12.65pt;font-size:15px;font-family:"Calibri",sans-serif;background:white;'><span style='font-size:16px;font-family:"Arial",sans-serif;color:#222222;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:16px;line-height:115%;font-family:"Arial",sans-serif;color:#222222;'>Thanks and Regards</span></p>
    `
}
export const commonMail = (phone, email = companyDetails.email, message) => {

        return `
        
            ${message}
        
    
    
        `
    }
// export const commonMail = (phone, email = companyDetails.email, message) => {

//     return `
    
//         ${message}
    


//     <div style="width:100%;margin-top:30px">


//     <div style="display:flex">

    
//     <div style="margin-right:20px">
//         <img style="height:150px;width:190px;object-fit:contain" src="${process.env.REACT_APP_LOGO}" />
//      </div>
//      <div style="border-left:2px solid pink;padding:20px">
//         <h3>
//         ${companyDetails.name}
//         </h3>
        
//         <span style="display:block;margin-top:10px">
//         ${companyDetails.phone}  | ${phone}
//         </span>
        
//         <a href="mailto:${email}" target="_blank" >
//         ${email}
//         </a>
//         <a href="https://${companyDetails.website}" target="_blank" style="display:block" >
//         ${companyDetails.website}
//         </a>
//         </div>
//         </div>




//     </div>    
//     `
// }
