import { useState } from "react"
import SubmitButton from "../../components/button/SubmitButton"
import { Alert, Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { callApiAction } from "../../store/actions/commonAction"
import { callSnackBar } from "../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../utils/constants/constants"

import { Close, Warning } from "@mui/icons-material"

import { rejectPendingPaymentsApi } from "../../apis/invoice.api"
import { Transition } from "../../components/Modal"

const RejectPaymentButton = ({ invoice_id, verified,payment_id, callBack = () => { } }) => {

    const { user } = useSelector(state => state)
    const [mode, setMode] = useState(0)

    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        invoice_id, payment_id
    })
    const dispatch = useDispatch()

    const onSubmit = (e) => {

        setLoading(true)
        dispatch(
            callApiAction(
                async () => await rejectPendingPaymentsApi({ ...fields }),
                async (response) => {


                    setLoading(false)
                    dispatch(callSnackBar("Payment rejected successfully", SNACK_BAR_VARIETNS.suceess))
                    callBack(fields.remarks)
                    handleClose()
                },
                (err) => {
                    setLoading(false)
                    dispatch(callSnackBar(err ?? "Payment cant rejected", SNACK_BAR_VARIETNS.error))
                }
            )
        )
    }


    const onOpen = () => { setMode(1) }

    const handleClose = () => { setMode(0) }
if(verified){
    return <></>
}

    return <>
        <Dialog
            TransitionComponent={Transition}
            maxWidth="sm"
            fullWidth={true}
            open={mode == 1}

            onClose={handleClose}>
            <DialogTitle variant="h6">
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>

                    <Box component={'div'}>
                        Alert!

                    </Box>
                    <IconButton onClick={handleClose} size="small">
                        <Close />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent >
                <Typography variant="h3" align="center" >
                    <Warning color="error" fontSize="inherit" />
                </Typography>
                <Typography variant="body1" align="center" >
                    Are sure to reject payment?
                </Typography>
            </DialogContent>
            {<DialogActions   >
                <SubmitButton variant='outlined' onClick={handleClose} title={'close'} />
                <SubmitButton onClick={onSubmit} loading={loading} disabled={loading} type="button" color="error" title={'Reject'} />
            </DialogActions>}
        </Dialog>
        <Tooltip arrow  title="Add/Update remarks" >
            <SubmitButton disableElevation title="Reject" size="small" onClick={onOpen} color="error"  >Reject</SubmitButton>
        </Tooltip>

    </>

}
export default RejectPaymentButton