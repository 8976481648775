import { useState } from "react"
import { useDispatch } from "react-redux"
import { closeModal, openModal } from "../../../store/actions/modalAction"
import { callApiAction } from "../../../store/actions/commonAction"

import MessageDilog from "../../../components/MessageDilog"
import { Chip, CircularProgress, IconButton, Tooltip } from "@mui/material"
import { updatePipelineAsDefaultApi } from "../../../apis/pipeline.api"
import { Star, StarBorder } from "@mui/icons-material"

const DefaultMarkButton = ({ title, id, is_default,deleted, callBack = () => { } }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const actionFun = async () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await updatePipelineAsDefaultApi({ id }),
            (response) => {

                setLoading(false)
                callBack(id)
            },
            (err) => {
                setLoading(false)
            }
        ))
        dispatch(closeModal())
    }
    const onBtnClick = () => {
        dispatch(openModal(<MessageDilog onSubmit={actionFun} title="Alert!" message={`Are you sure to mark "${title}" as default ?`} />, "sm"))
    }

    return <>

        {<>
            {loading && <CircularProgress color="primary" fontSize="inherit" />}
            {!loading && <Tooltip arrow  title={"Mark As Default"} >
                <IconButton disabled={is_default || loading || deleted} size="small" onClick={onBtnClick}>
                    {is_default ? <Star fontSize="inherit" color="success" /> : <StarBorder fontSize="inherit" />}
                </IconButton>
            </Tooltip>}
        </>}
    </>
}
export default DefaultMarkButton