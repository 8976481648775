import { AddCircle } from "@mui/icons-material"
import { IconButton, Tooltip } from "@mui/material"
import { useDispatch } from "react-redux"
import { openModal } from "../../../store/actions/modalAction"
import AddPostcodeRangeController from "./AddPostcodeRangeController"
import { MODAL_KEYS } from "../../../utils/constants/modal.constant"

const AddPostCodeRangeButton = ({ data, setData }) => {
    const dispatch = useDispatch()
    const onClick = () => {
        dispatch(openModal(<AddPostcodeRangeController
            modalKey={MODAL_KEYS.ENERGY_PARTNER_POSTCODE_ADD}
            partner_id={data?._id}
            title="Add Postcode Range"
            callBack={(response) => {
                const range = [...(data?.postcode_ranges ?? [])]
                range.push(response)
                setData({ ...data, postcode_ranges: range })
            }}

        />, 'xs', undefined, MODAL_KEYS.ENERGY_PARTNER_POSTCODE_ADD))
    }
    return <Tooltip arrow  title="Add Range" >
        <IconButton sx={{padding:0}} size="small" onClick={onClick}>
            <AddCircle fontSize="inherit" color="primary" />
        </IconButton>
    </Tooltip>
}
export default AddPostCodeRangeButton