import { memo, useCallback, useEffect, useState } from "react"
import ListUi from "./ListUi"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { openModal } from "../../store/actions/modalAction"
import CreateController from "./CreateController"
import { getPipelineApi } from "../../apis/pipeline.api"
import { PIPELINE_TYPE } from "../../utils/constants/pipelines.constant"

const ListController = ({ type = PIPELINE_TYPE.LEAD }) => {
    const dispatch = useDispatch()



    const title = "Pipelines"
    const fetchApi = getPipelineApi

    const [filters, setFilters] = useState({
        type,
        deleted: false

    })







    // do not change 

    const [loading, setLoading] = useState(false)
    const [list, setList] = useState([])









    const fetchList = useCallback(() => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchApi({ ...filters }),
            (response) => {
                setList(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }, [dispatch, setList, setLoading,filters])
 



    useEffect(() => {
        fetchList()
    }, [filters])




    const onCreateBtnClick = () => {
        dispatch(openModal(<CreateController type={type} callBack={async () => { fetchList() }} />, "sm"))
    }
    const onDefaultSelect = useCallback( (id) => {
        const temp =[...list].map((item)=>item._id==id?({...item,is_default:true}):({...item,is_default:false}))

        setList(temp)
    },[list,setList])

    const onStepChange = useCallback( (pipelineId,stepOldId,StepNewId) => {
     
    },[list,setList])
    


    return (
        <>
            <ListUi
                title={title}
                onCreateBtnClick={onCreateBtnClick}
                onDefaultSelect={onDefaultSelect}
                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}
                callBack={fetchList}

            />

        </>
    )
}
export default ListController