import axios from "axios";
import { getHeaders } from "../utils/helpers/helper";
import endpoints from "./endpoints";




export const getAgreementApi = async params => {
  const callResponse = await axios({
    url: endpoints.aggrementBase,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const getAgreementByIdApi = async params => {
  const callResponse = await axios({
    url: endpoints.aggrementById,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


export const addAgreementApi = async data => {
  const callResponse = await axios({
    url: endpoints.aggrementBase,
    method: "POST",
    headers: getHeaders(),
    
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const updateAgreementApi = async data => {
  const callResponse = await axios({
    url: endpoints.aggrementBase,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const updateActiveAgreementApi = async data => {
  const callResponse = await axios({
    url: endpoints.aggrementActive,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const deleteAgreementApi = async data => {
  const callResponse = await axios({
    url: endpoints.aggrementBase,
    method: "DELETE",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};



export default getAgreementApi