import {
  Paper,
  Typography,
  Box,
  Card,
  CardHeader,
  IconButton,
  Collapse,
  Container,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  CardContent,
} from "@mui/material";
import { memo, useState } from "react";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import moment from "moment";
import {
  CalendarMonth,
  Call,
  CreditCard,
  KeyboardArrowDown,
  KeyboardArrowUp,
  MailOutline,
} from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const DetailsUI = memo(({ params }) => {
  const [personal, setPersonal] = useState(true);
  const [SubContractor, setSubContractor] = useState(false);
  const [PublicLiability, setPublicLiability] = useState(false);
  const [Workers, setWorkers] = useState(false);
  const [Accident, setAccident] = useState(false);
  const [Electrical, setElectrical] = useState(false);
  const [License, setLicense] = useState(false);
  const [Accreditation, setAccreditation] = useState(false);
  const [Design, setDesign] = useState(false);
  return (
    <CustomDialog id="list" title="Installer View">
      <Card
        sx={{
          // minWidth: 300,
          border: "1px solid #7F7F7F",
          marginTop: "48px",
          boxShadow: "0px 0px 19px rgba(0, 0, 0, 0.1)",
        }}
      >
        <CardHeader
          sx={{
            borderBottom: personal && "1px solid #7F7F7F",
            height: "7vh",
            fontSize: "10px",
          }}
          title={
            <Typography sx={{ fontSize: "17px" }}>Personal details</Typography>
          }
          onClick={() => setPersonal(!personal)}
          action={
            <IconButton aria-label="expand" size="small">
              {personal ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          }
          // sx={collapsContainer}
        ></CardHeader>
        <Box>
          <Collapse in={personal} timeout="auto">
            <CardContent>
              <Container sx={{ height: 36, lineHeight: 2, height: "100%" }}>
                <TableContainer sx={{ boxShadow: "none" }} component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Typography
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              lineHeight: "100%",
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                            variant="h6"
                          >
                            <AccountCircleIcon />
                            &nbsp;&nbsp;Name
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {params.name ? params.name : "NA"}
                        </TableCell>
                        <TableCell>
                          <Typography
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              lineHeight: "100%",
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                            variant="h6"
                          >
                            <MailOutline />
                            &nbsp;&nbsp;Email
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {params.email ? params.email : "NA"}
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>
                          <Typography
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              lineHeight: "100%",
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                            variant="h6"
                          >
                            <Call />
                            &nbsp;&nbsp;Phone
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {params.phone ? params.phone : "NA"}
                        </TableCell>
                        <TableCell>
                          <Typography
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              lineHeight: "100%",
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                            variant="h6"
                          >
                            <CreditCard />
                            &nbsp;&nbsp;Licence No
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {params.license_number ? params.license_number : "NA"}
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>
                          <Typography
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              lineHeight: "100%",
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                            variant="h6"
                          >
                            <CalendarMonth />
                            &nbsp;&nbsp;Licence Expiry
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {moment(params.license_expiry).format("DD/MM/YYYY")
                            ? moment(params.license_expiry).format("DD/MM/YYYY")
                            : "NA"}
                        </TableCell>
                        {/* <TableCell>
                          <Typography
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              lineHeight: "100%",
                            }}
                            variant="h6"
                          >
                            <WifiCalling3Outlined />
                            &nbsp;&nbsp;Emergency Number
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {state.phone_emergency_country_code}{" "}
                          {state.phone_emergency}
                        </TableCell> */}
                      </TableRow>

                      {/* <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell colSpan={1}>
                          <Typography
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              lineHeight: "100%",
                            }}
                            variant="h6"
                          >
                            <LocalHospitalOutlined />
                            &nbsp;&nbsp;Medical Remarks
                          </Typography>
                        </TableCell>

                        <TableCell colSpan={3}>
                          {state.medical_remarks}
                        </TableCell>
                      </TableRow>

                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell colSpan={1}>
                          <Typography
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              lineHeight: "100%",
                            }}
                            variant="h6"
                          >
                            <PinDrop />
                            &nbsp;&nbsp;Address
                          </Typography>
                        </TableCell>

                        <TableCell colSpan={3}>
                          {state.address_line1},{" "}
                          {state.address_line2
                            ? state.address_line2 + ", "
                            : ""}{" "}
                          {state.zip_code},{state.city}, {state.state},{" "}
                          {state.country}.
                        </TableCell>
                      </TableRow>

                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>
                          <Typography
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              lineHeight: "100%",
                            }}
                            variant="h6"
                          >
                            <CreditCard />
                            &nbsp;&nbsp;PAN No.
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {state.pan_no}
                          {state.pan_url && (
                            <FileDownloadComponent src={state.pan_url} />
                          )}
                        </TableCell>
                        <TableCell>
                          <Typography
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              lineHeight: "100%",
                            }}
                            variant="h6"
                          >
                            <CardMembership />
                            &nbsp;&nbsp;Aadhar Number
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {state.aadhar_no}{" "}
                          {state.addhar_url && (
                            <FileDownloadComponent src={state.addhar_url} />
                          )}
                        </TableCell>
                      </TableRow> */}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Container>
            </CardContent>
          </Collapse>
        </Box>
      </Card>

      <Card
        sx={{
          // minWidth: 300,
          border: "1px solid #7F7F7F",
          marginTop: "16px",
          boxShadow: "0px 0px 19px rgba(0, 0, 0, 0.1)",
        }}
      >
        <CardHeader
          sx={{
            borderBottom: SubContractor && "1px solid #7F7F7F",
            height: "7vh",
            fontSize: "10px",
          }}
          title={
            <Typography sx={{ fontSize: "17px" }}>Subcontractor</Typography>
          }
          onClick={() => setSubContractor(!SubContractor)}
          action={
            <IconButton aria-label="expand" size="small">
              {SubContractor ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          }
          // sx={collapsContainer}
        ></CardHeader>
        <Box>
          <Collapse in={SubContractor} timeout="auto">
            <CardContent>
              <Container sx={{ height: 36, lineHeight: 2, height: "100%" }}>
                <TableContainer sx={{ boxShadow: "none" }} component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Typography
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              lineHeight: "100%",
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                            variant="h6"
                          >
                            {/* <AccountCircleIcon /> */}
                            &nbsp;&nbsp;Full Name
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {params.subcontractor_name
                            ? params.subcontractor_name
                            : "NA"}
                        </TableCell>
                        <TableCell>
                          <Typography
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              lineHeight: "100%",
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                            variant="h6"
                          >
                            {/* <MailOutline /> */}
                            &nbsp;&nbsp;ACN & ABN
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {params.subcontractor_acn
                            ? params.subcontractor_acn
                            : "NA"}
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>
                          <Typography
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              lineHeight: "100%",
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                            variant="h6"
                          >
                            {/* <Call /> */}
                            &nbsp;&nbsp;Contact
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {params.subcontractor_phone
                            ? params.subcontractor_phone
                            : "NA"}
                        </TableCell>
                        <TableCell>
                          <Typography
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              lineHeight: "100%",
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                            variant="h6"
                          >
                            {/* <CreditCard /> */}
                            &nbsp;&nbsp;Land Phone No
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {params.subcontractor_landPhone
                            ? params.subcontractor_landPhone
                            : "NA"}
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>
                          <Typography
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              lineHeight: "100%",
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                            variant="h6"
                          >
                            {/* <CalendarMonth /> */}
                            &nbsp;&nbsp;Email
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {params.subcontractor_email
                            ? params.subcontractor_email
                            : "NA"}
                        </TableCell>
                        <TableCell>
                          <Typography
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              lineHeight: "100%",
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                            variant="h6"
                          >
                            {/* <CalendarMonth /> */}
                            &nbsp;&nbsp;Address
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {params.subcontractor_address
                            ? params.subcontractor_address
                            : "NA"}
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>
                          <Typography
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              lineHeight: "100%",
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                            variant="h6"
                          >
                            {/* <CalendarMonth /> */}
                            &nbsp;&nbsp;Guarantor Name
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {params.guaeantor_name ? params.guaeantor_name : "NA"}
                        </TableCell>
                        <TableCell>
                          <Typography
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              lineHeight: "100%",
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                            variant="h6"
                          >
                            {/* <CalendarMonth /> */}
                            &nbsp;&nbsp;Guarantor Address
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {params.guaeantor_address
                            ? params.guaeantor_address
                            : "NA"}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Container>
            </CardContent>
          </Collapse>
        </Box>
      </Card>

      <Card
        sx={{
          // minWidth: 300,
          border: "1px solid #7F7F7F",
          marginTop: "16px",
          boxShadow: "0px 0px 19px rgba(0, 0, 0, 0.1)",
        }}
      >
        <CardHeader
          sx={{
            borderBottom: PublicLiability && "1px solid #7F7F7F",
            height: "7vh",
            fontSize: "10px",
          }}
          title={
            <Typography sx={{ fontSize: "17px" }}>
              Public Liability Insurance
            </Typography>
          }
          onClick={() => setPublicLiability(!PublicLiability)}
          action={
            <IconButton aria-label="expand" size="small">
              {PublicLiability ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          }
          // sx={collapsContainer}
        ></CardHeader>
        <Box>
          <Collapse in={PublicLiability} timeout="auto">
            <CardContent>
              <Container sx={{ height: 36, lineHeight: 2, height: "100%" }}>
                <TableContainer sx={{ boxShadow: "none" }} component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Typography
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              lineHeight: "100%",
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                            variant="h6"
                          >
                            {/* <AccountCircleIcon /> */}
                            &nbsp;&nbsp;Liability Company
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {params.liability_company
                            ? params.liability_company
                            : "NA"}
                        </TableCell>
                        <TableCell>
                          <Typography
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              lineHeight: "100%",
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                            variant="h6"
                          >
                            {/* <MailOutline /> */}
                            &nbsp;&nbsp;Policy No
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {params.liability_policy_no
                            ? params.liability_policy_no
                            : "NA"}
                        </TableCell>
                      </TableRow>

                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>
                          <Typography
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              lineHeight: "100%",
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                            variant="h6"
                          >
                            {/* <Call /> */}
                            &nbsp;&nbsp;Policy Issue Date
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {moment(params.liability_policy_date).format(
                            "DD/MM/YYYY"
                          )
                            ? moment(params.liability_policy_date).format(
                                "DD/MM/YYYY"
                              )
                            : "NA"}
                        </TableCell>
                        <TableCell>
                          <Typography
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              lineHeight: "100%",
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                            variant="h6"
                          >
                            {/* <CreditCard /> */}
                            &nbsp;&nbsp;Policy Expiry Date
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {" "}
                          {moment(params.liability_policy_expiryDate).format(
                            "DD/MM/YYYY"
                          )
                            ? moment(params.liability_policy_expiryDate).format(
                                "DD/MM/YYYY"
                              )
                            : "NA"}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Container>
            </CardContent>
          </Collapse>
        </Box>
      </Card>

      <Card
        sx={{
          // minWidth: 300,
          border: "1px solid #7F7F7F",
          marginTop: "16px",
          boxShadow: "0px 0px 19px rgba(0, 0, 0, 0.1)",
        }}
      >
        <CardHeader
          sx={{
            borderBottom: Workers && "1px solid #7F7F7F",
            height: "7vh",
            fontSize: "10px",
          }}
          title={
            <Typography sx={{ fontSize: "17px" }}>
              Workers Compensation Insurance
            </Typography>
          }
          onClick={() => setWorkers(!Workers)}
          action={
            <IconButton aria-label="expand" size="small">
              {Workers ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          }
          // sx={collapsContainer}
        ></CardHeader>
        <Box>
          <Collapse in={Workers} timeout="auto">
            <CardContent>
              <Container sx={{ height: 36, lineHeight: 2, height: "100%" }}>
                <TableContainer sx={{ boxShadow: "none" }} component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableBody>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>
                          <Typography
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              lineHeight: "100%",
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                            variant="h6"
                          >
                            {/* <CalendarMonth /> */}
                            &nbsp;&nbsp;Company
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {params.wic_company ? params.wic_company : "NA"}
                        </TableCell>
                        <TableCell>
                          <Typography
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              lineHeight: "100%",
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                            variant="h6"
                          >
                            {/* <CalendarMonth /> */}
                            &nbsp;&nbsp;Policy No
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {params.wic_policy_no ? params.wic_policy_no : "NA"}
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>
                          <Typography
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              lineHeight: "100%",
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                            variant="h6"
                          >
                            {/* <CalendarMonth /> */}
                            &nbsp;&nbsp;Policy Issue Date
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {moment(params.wic_policy_date).format("DD/MM/YYYY")
                            ? moment(params.wic_policy_date).format(
                                "DD/MM/YYYY"
                              )
                            : "NA"}
                        </TableCell>
                        <TableCell>
                          <Typography
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              lineHeight: "100%",
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                            variant="h6"
                          >
                            {/* <CalendarMonth /> */}
                            &nbsp;&nbsp;Policy Expiry Date
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {moment(params.wic_policy_expiryDate).format(
                            "DD/MM/YYYY"
                          )
                            ? moment(params.wic_policy_expiryDate).format(
                                "DD/MM/YYYY"
                              )
                            : "NA"}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Container>
            </CardContent>
          </Collapse>
        </Box>
      </Card>

      <Card
        sx={{
          // minWidth: 300,
          border: "1px solid #7F7F7F",
          marginTop: "16px",
          boxShadow: "0px 0px 19px rgba(0, 0, 0, 0.1)",
        }}
      >
        <CardHeader
          sx={{
            borderBottom: Accident && "1px solid #7F7F7F",
            height: "7vh",
            fontSize: "10px",
          }}
          title={
            <Typography sx={{ fontSize: "17px" }}>
              Personal Accident And Sickness Insurance
            </Typography>
          }
          onClick={() => setAccident(!Accident)}
          action={
            <IconButton aria-label="expand" size="small">
              {Accident ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          }
          // sx={collapsContainer}
        ></CardHeader>
        <Box>
          <Collapse in={Accident} timeout="auto">
            <CardContent>
              <Container sx={{ height: 36, lineHeight: 2, height: "100%" }}>
                <TableContainer sx={{ boxShadow: "none" }} component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableBody>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>
                          <Typography
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              lineHeight: "100%",
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                            variant="h6"
                          >
                            {/* <CalendarMonth /> */}
                            &nbsp;&nbsp;Company
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {params.pasi_company ? params.pasi_company : "NA"}
                        </TableCell>
                        <TableCell>
                          <Typography
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              lineHeight: "100%",
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                            variant="h6"
                          >
                            {/* <CalendarMonth /> */}
                            &nbsp;&nbsp; Policy No
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {params.pasi_policy_no ? params.pasi_policy_no : "NA"}
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>
                          <Typography
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              lineHeight: "100%",
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                            variant="h6"
                          >
                            {/* <CalendarMonth /> */}
                            &nbsp;&nbsp;Policy Issue Date
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {moment(params.pasi_policy_date).format("DD/MM/YYYY")
                            ? moment(params.pasi_policy_date).format(
                                "DD/MM/YYYY"
                              )
                            : "NA"}
                        </TableCell>
                        <TableCell>
                          <Typography
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              lineHeight: "100%",
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                            variant="h6"
                          >
                            {/* <CalendarMonth /> */}
                            &nbsp;&nbsp;Policy Expiry Date
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {moment(params.pasi_policy_expiryDate).format(
                            "DD/MM/YYYY"
                          )
                            ? moment(params.pasi_policy_expiryDate).format(
                                "DD/MM/YYYY"
                              )
                            : "NA"}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Container>
            </CardContent>
          </Collapse>
        </Box>
      </Card>

      <Card
        sx={{
          // minWidth: 300,
          border: "1px solid #7F7F7F",
          marginTop: "16px",
          boxShadow: "0px 0px 19px rgba(0, 0, 0, 0.1)",
        }}
      >
        <CardHeader
          sx={{
            borderBottom: Electrical && "1px solid #7F7F7F",
            height: "7vh",
            fontSize: "10px",
          }}
          title={
            <Typography sx={{ fontSize: "17px" }}>
              Registered Electrical Contractor (REC){" "}
            </Typography>
          }
          onClick={() => setElectrical(!Electrical)}
          action={
            <IconButton aria-label="expand" size="small">
              {Electrical ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          }
          // sx={collapsContainer}
        ></CardHeader>
        <Box>
          <Collapse in={Electrical} timeout="auto">
            <CardContent>
              <Container sx={{ height: 36, lineHeight: 2, height: "100%" }}>
                <TableContainer sx={{ boxShadow: "none" }} component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableBody>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>
                          <Typography
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              lineHeight: "100%",
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                            variant="h6"
                          >
                            {/* <CalendarMonth /> */}
                            &nbsp;&nbsp;License No
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {params.rec_license_no ? params.rec_license_no : "NA"}
                        </TableCell>
                        <TableCell>
                          <Typography
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              lineHeight: "100%",
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                            variant="h6"
                          >
                            {/* <CalendarMonth /> */}
                            &nbsp;&nbsp;License Issue Date
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {moment(params.rec_license_date).format("DD/MM/YYYY")
                            ? moment(params.rec_license_date).format(
                                "DD/MM/YYYY"
                              )
                            : "NA"}
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        {/* <TableCell>{moment(params.wic_policy_date).format("DD/MM/YYYY")}</TableCell> */}
                        <TableCell>
                          <Typography
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              lineHeight: "100%",
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                            variant="h6"
                          >
                            {/* <CalendarMonth /> */}
                            &nbsp;&nbsp;Policy Expiry Date
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {moment(params.rec_license_date).format("DD/MM/YYYY")
                            ? moment(params.rec_license_date).format(
                                "DD/MM/YYYY"
                              )
                            : "NA"}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Container>
            </CardContent>
          </Collapse>
        </Box>
      </Card>

      <Card
        sx={{
          // minWidth: 300,
          border: "1px solid #7F7F7F",
          marginTop: "16px",
          boxShadow: "0px 0px 19px rgba(0, 0, 0, 0.1)",
        }}
      >
        <CardHeader
          sx={{
            borderBottom: License && "1px solid #7F7F7F",
            height: "7vh",
            fontSize: "10px",
          }}
          title={
            <Typography sx={{ fontSize: "17px" }}>
              Electrician's License (A Class)
            </Typography>
          }
          onClick={() => setLicense(!License)}
          action={
            <IconButton aria-label="expand" size="small">
              {License ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          }
          // sx={collapsContainer}
        ></CardHeader>
        <Box>
          <Collapse in={License} timeout="auto">
            <CardContent>
              <Container sx={{ height: 36, lineHeight: 2, height: "100%" }}>
                <TableContainer sx={{ boxShadow: "none" }} component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableBody>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>
                          <Typography
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              lineHeight: "100%",
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                            variant="h6"
                          >
                            {/* <CalendarMonth /> */}
                            &nbsp;&nbsp;License No
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {params.electrician_license_no
                            ? params.electrician_license_no
                            : "NA"}
                        </TableCell>
                        <TableCell>
                          <Typography
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              lineHeight: "100%",
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                            variant="h6"
                          >
                            {/* <CalendarMonth /> */}
                            &nbsp;&nbsp;License Issue Date
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {moment(params.electrician_license_date).format(
                            "DD/MM/YYYY"
                          )
                            ? moment(params.electrician_license_date).format(
                                "DD/MM/YYYY"
                              )
                            : "NA"}
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        {/* <TableCell>{moment(params.wic_policy_date).format("DD/MM/YYYY")}</TableCell> */}
                        <TableCell>
                          <Typography
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              lineHeight: "100%",
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                            variant="h6"
                          >
                            {/* <CalendarMonth /> */}
                            &nbsp;&nbsp;Policy Expiry Date
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {moment(params.electrician_license_expiryDate).format(
                            "DD/MM/YYYY"
                          )
                            ? moment(
                                params.electrician_license_expiryDate
                              ).format("DD/MM/YYYY")
                            : "NA"}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Container>
            </CardContent>
          </Collapse>
        </Box>
      </Card>

      <Card
        sx={{
          // minWidth: 300,
          border: "1px solid #7F7F7F",
          marginTop: "16px",
          boxShadow: "0px 0px 19px rgba(0, 0, 0, 0.1)",
        }}
      >
        <CardHeader
          sx={{
            borderBottom: Accreditation && "1px solid #7F7F7F",
            height: "7vh",
            fontSize: "10px",
          }}
          title={
            <Typography sx={{ fontSize: "17px" }}>
              SAA Accreditation{" "}
            </Typography>
          }
          onClick={() => setAccreditation(!Accreditation)}
          action={
            <IconButton aria-label="expand" size="small">
              {Accreditation ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          }
          // sx={collapsContainer}
        ></CardHeader>
        <Box>
          <Collapse in={Accreditation} timeout="auto">
            <CardContent>
              <Container sx={{ height: 36, lineHeight: 2, height: "100%" }}>
                <TableContainer sx={{ boxShadow: "none" }} component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableBody>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>
                          <Typography
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              lineHeight: "100%",
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                            variant="h6"
                          >
                            {/* <CalendarMonth /> */}
                            &nbsp;&nbsp;License No
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {params.accreditation_license_no
                            ? params.accreditation_license_no
                            : "NA"}
                        </TableCell>
                        <TableCell>
                          <Typography
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              lineHeight: "100%",
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                            variant="h6"
                          >
                            {/* <CalendarMonth /> */}
                            &nbsp;&nbsp;License Issue Date
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {moment(params.accreditation_license_date).format(
                            "DD/MM/YYYY"
                          )
                            ? moment(params.accreditation_license_date).format(
                                "DD/MM/YYYY"
                              )
                            : "NA"}
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        {/* <TableCell>{moment(params.wic_policy_date).format("DD/MM/YYYY")}</TableCell> */}
                        <TableCell>
                          <Typography
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              lineHeight: "100%",
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                            variant="h6"
                          >
                            {/* <CalendarMonth /> */}
                            &nbsp;&nbsp;Policy Expiry Date
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {moment(
                            params.accreditation_license_expiryDate
                          ).format("DD/MM/YYYY")
                            ? moment(
                                params.accreditation_license_expiryDate
                              ).format("DD/MM/YYYY")
                            : "NA"}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Container>
            </CardContent>
          </Collapse>
        </Box>
      </Card>

      <Card
        sx={{
          // minWidth: 300,
          border: "1px solid #7F7F7F",
          marginTop: "16px",
          boxShadow: "0px 0px 19px rgba(0, 0, 0, 0.1)",
        }}
      >
        <CardHeader
          sx={{
            borderBottom: Design && "1px solid #7F7F7F",
            height: "7vh",
            fontSize: "10px",
          }}
          title={
            <Typography sx={{ fontSize: "17px" }}>
              SAA Design License
            </Typography>
          }
          onClick={() => setDesign(!Design)}
          action={
            <IconButton aria-label="expand" size="small">
              {Design ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          }
          // sx={collapsContainer}
        ></CardHeader>
        <Box>
          <Collapse in={Design} timeout="auto">
            <CardContent>
              <Container sx={{ height: 36, lineHeight: 2, height: "100%" }}>
                <TableContainer sx={{ boxShadow: "none" }} component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableBody>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>
                          <Typography
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              lineHeight: "100%",
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                            variant="h6"
                          >
                            {/* <CalendarMonth /> */}
                            &nbsp;&nbsp;License No
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {params.design_license_no
                            ? params.design_license_no
                            : "NA"}
                        </TableCell>
                        <TableCell>
                          <Typography
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              lineHeight: "100%",
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                            variant="h6"
                          >
                            {/* <CalendarMonth /> */}
                            &nbsp;&nbsp;License Issue Date
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {moment(params.design_license_date).format(
                            "DD/MM/YYYY"
                          )
                            ? moment(params.design_license_date).format(
                                "DD/MM/YYYY"
                              )
                            : "NA"}
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        {/* <TableCell>{moment(params.wic_policy_date).format("DD/MM/YYYY")}</TableCell> */}
                        <TableCell>
                          <Typography
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              lineHeight: "100%",
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                            variant="h6"
                          >
                            {/* <CalendarMonth /> */}
                            &nbsp;&nbsp;Policy Expiry Date
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {moment(params.design_license_expiryDate).format(
                            "DD/MM/YYYY"
                          )
                            ? moment(params.design_license_expiryDate).format(
                                "DD/MM/YYYY"
                              )
                            : "NA"}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Container>
            </CardContent>
          </Collapse>
        </Box>
      </Card>
    </CustomDialog>
  );
});

export default DetailsUI;
