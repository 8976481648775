import { memo,  useMemo, useState } from "react"
import useValidate from "../../../../store/hooks/useValidator"

import { useDispatch } from 'react-redux'
import { callApiAction } from "../../../../store/actions/commonAction"
import { closeModal } from "../../../../store/actions/modalAction"


import {  updateLeadSourceApi } from "../../../../apis/lead.api"

import LeadUpdateSourceUi from "./LeadUpdateSourceUi"
import { callSnackBar } from "../../../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../../../utils/constants/constants"
import { MODAL_KEYS } from "../../../../utils/constants/modal.constant"

const UpdateLeadSourceController = ({ callBack = () => { }, id, source = null }) => {
    const validate = useValidate()
    const dispatch = useDispatch()

    const title = "Update Lead Source"
    const modalKey = MODAL_KEYS.LEAD_UPDATE_SOURCE

    const updateApi = updateLeadSourceApi

    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',
        id,
        source,
    })



    const validationSchemaForStatusChange = useMemo(() => ([
        {
            required: true,
            value: fields.source,
            field: 'Source',
        },
    ]), [fields])





    const updateFun = async () => {
        const validationResponse = validate(validationSchemaForStatusChange)

        if (validationResponse === true) {

            const passData = { ...fields }
            passData['source'] = fields['source']?._id

            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await updateApi(passData),
                    async (response) => {

                        await callBack(response)
                        setLoading(false)
                        dispatch(callSnackBar("source updated successfully", SNACK_BAR_VARIETNS.suceess))
                        dispatch(closeModal(modalKey))
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }




    const onSubmit = async (e) => {
        e.preventDefault()
        updateFun()


    }




    return <LeadUpdateSourceUi modalKey={modalKey} title={title} isUpdate={id} loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
}
export default memo(UpdateLeadSourceController)