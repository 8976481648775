import { CircularProgress } from "@mui/material"
import { Box } from "@mui/system"
import { Fragment, memo } from "react"
import { useDispatch, useSelector } from 'react-redux'

import CustomInput from "../../components/inputs/CustomInput"

import CustomDialog from "../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../components/layouts/OneViewBox"
import { closeModal } from "../../store/actions/modalAction"
import { MODAL_KEYS } from "../../utils/constants/modal.constant"
import { DesktopDatePicker } from "@mui/x-date-pickers"
import moment from "moment"



const CreateUi = ({ title, fields, setFields, loading, onSubmit }) => {
    const { user } = useSelector(state => state)
    const dispatch = useDispatch()


    return <>

        <CustomDialog
            onClose={() => { dispatch(closeModal(MODAL_KEYS.SCHEDULE_CALL)) }}
            loading={loading}
            err={fields.err}
            onSubmit={onSubmit}
            title={` ${title}`}
            closeText="Close"
            confirmText={`Schedule`}
        >

            {loading ? <CenteredBox><CircularProgress /></CenteredBox> :
                <>
                    <DesktopDatePicker
                        inputFormat="DD/MM/YYYY"
                        renderInput={(props) => <CustomInput {...props} />}
                        label="Schedule Date*"
                        minDate={new Date()}
                        value={fields.scheduled_date ? moment(fields.scheduled_date) : null}
                        onChange={(changedVal) => { setFields({ ...fields, scheduled_date: changedVal?.toISOString() ?? null }) }}
                    />
                    <CustomInput
                        autoFocus={true}
                        disabled={loading}
                        value={fields.remarks}
                        onChange={(e) => setFields({ ...fields, err: '', remarks: e.target.value })}
                        type="text"
                        label={"Remarks"}
                    />
                </>}

        </CustomDialog>
    </>
}
export default memo(CreateUi)