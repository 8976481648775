import { useCallback, useEffect, useState } from "react"
import { callApiAction } from "../../../store/actions/commonAction"
import { useDispatch } from "react-redux"
import { getProjectsByIdApi } from "../../../apis/projects.api"
import LeadDetailedViewUi, { LeadDetailDialog } from "./LeadDetailedViewUi"
import { getLeadByIdApi } from "../../../apis/lead.api"

const LeadDetailedViewController = ({ project, viewOnly, onDataChange, noDialog, title, children }) => {
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(true)
    const [data, setData] = useState(null)

    const fetchProjectById = useCallback((id) => {

        setLoading(true)
        dispatch(callApiAction(
            async () => await getLeadByIdApi({ id }),
            (response) => {

                setData(response)
                setLoading(false)

            },
            (err) => {
                setLoading(false)
            }
        ))

    }, [])
    useEffect(() => {
        if (project)
            fetchProjectById(project)
    }, [project])
    const callBack = () => {
        fetchProjectById(project)
    }

    useEffect(() => {
        if (data && typeof onDataChange=="function")
            onDataChange(data)
    }, [data])

    if (noDialog)
        return <LeadDetailedViewUi
            children={children}
            title={title}
            viewOnly={viewOnly}
            data={data}
            loading={loading}
            setData={setData}
        />
    return <LeadDetailDialog
        children={children}
        title={title}
        viewOnly={viewOnly}
        data={data}
        loading={loading}
        setData={setData}
        callBack={callBack} />
}
export default LeadDetailedViewController
