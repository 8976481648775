import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { closeModal, openModal } from "../../../store/actions/modalAction"
import { callApiAction } from "../../../store/actions/commonAction"

import MessageDilog from "../../../components/MessageDilog"
import { CircularProgress, IconButton, Tooltip } from "@mui/material"
import { Delete } from "@mui/icons-material"
import { deleteLeadApi } from "../../../apis/lead.api"
import { moduleAccessCondition } from "../../../utils/helpers/helper"
import MODULES from "../../../utils/constants/module.constants"

const DeleteLeadButton = ({ id, allowedDelete, onDelete = () => { }, projectDetails = {} }) => {
    const dispatch = useDispatch()
    const { user } = useSelector(state => state)
    const [loading, setLoading] = useState(false)
    const deleteFun = async () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await deleteLeadApi({ id }),
            (response) => {

                setLoading(false)
                onDelete()
            },
            (err) => {
                setLoading(false)
            }
        ))
        dispatch(closeModal())
    }
    const onDeleteBtnClick = () => {
        dispatch(openModal(<MessageDilog onSubmit={deleteFun} title="Alert!" message={`Are you sure to delete ${projectDetails?.uid}?. on delete lead all documents ,invoices & information related to ${projectDetails?.uid} will be permenently deleted.`} />, "sm"))
    }
    if (!allowedDelete || !moduleAccessCondition(user, [MODULES.DELETE_LEADS]))
        return <></>
    return <>

        {<>
            {loading && <CircularProgress color="primary" fontSize="inherit" />}
            {!loading && <Tooltip arrow title={"Delete"} >
                <IconButton disabled={loading} onClick={onDeleteBtnClick}>
                    <Delete color="error" fontSize="inherit" />
                </IconButton>
            </Tooltip>}
        </>}
    </>
}
export default DeleteLeadButton