import { Box, ButtonBase, Checkbox, Chip, CircularProgress, FormControlLabel, Grid, IconButton, MenuItem, Paper, Radio, Tooltip, Typography } from "@mui/material"

import { memo, useCallback, useState } from "react"




import CustomInput from "../../components/inputs/CustomInput"
import CustomDialog from "../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../components/layouts/OneViewBox"
import AsyncDropDown from "../../components/inputs/AsyncDropDown"
import getSourceApi from "../../apis/sources.api"
import { AddCircle, RadioButtonChecked, RadioButtonUnchecked, Remove } from "@mui/icons-material"
import { useDispatch } from "react-redux"
import { closeModal, openModal } from "../../store/actions/modalAction"
import { LEAD_FORMS_FIELDS } from "../../utils/constants/leaforms.constants"
import CustomEditor from "../../components/inputs/CustomEditor"
const CustomizationModal = ({ defaultFields, setFieldsFun }) => {
    const dispatch = useDispatch()
    const [fields,setFields] = useState(defaultFields)
    const onSubmit = (e)=>{
        e?.preventDefault()
        setFieldsFun(fields)
        dispatch(closeModal())
    }
    return <CustomDialog
        title="Customize Form Ui"
        onSubmit={onSubmit}
    >
        <CustomInput
            value={fields.form_width}
            onChange={(e) => setFields({ ...fields, form_width: e.target.value })}
            type="text"
            label={"Form Width*"}
        />
        <CustomInput
            value={fields.form_title_color}
            onChange={(e) => setFields({ ...fields, form_title_color: e.target.value })}
            type="color"
            label={"Title Color*"}

        />
        <CustomInput
            value={fields.form_description_color}
            onChange={(e) => setFields({ ...fields, form_description_color: e.target.value })}
            type="color"
            label={"Description Color*"}

        />
        <CustomInput
            value={fields.form_border_color}
            onChange={(e) => setFields({ ...fields, form_border_color: e.target.value })}
            type="color"
            label={"Form Border Color*"}

        />
        <CustomInput
            value={fields.form_bg_color}
            onChange={(e) => setFields({ ...fields, form_bg_color: e.target.value })}
            type="color"
            label={"Form Background Color*"}

        />
        <CustomInput
            value={fields.submit_btn_color}
            onChange={(e) => setFields({ ...fields, submit_btn_color: e.target.value })}
            type="color"
            label={"Submit Button Color*"}

        />
        <CustomInput
            value={fields.submit_btn_font_color}
            onChange={(e) => setFields({ ...fields, submit_btn_font_color: e.target.value })}
            type="color"
            label={"Submit Button Font Color*"}

        />
        <CustomEditor 
           placeholder="Success Message"
           value={fields.success_html}
           onChange={(val) => {
             setFields({ ...fields, success_html: val });
           }}
        />
    </CustomDialog>
}
const FieldSelectionItem = ({ title, selected, onSelect, isDefault }) => {

    return <Paper disabled={isDefault} onClick={onSelect} variant="outlined" sx={{ borderColor: selected ? "primary.main" : undefined, p: 2, mb: 2, justifyContent: "flex-start", opacity: isDefault ? 0.5 : 1 }} component={ButtonBase}    >
        {selected ? <RadioButtonChecked color="primaru" /> : <RadioButtonUnchecked />}
        <Typography variant="body2" ml={2} >
            {title}
        </Typography>
    </Paper>
}
const FieldSelectionDropDown = ({ selectedFields, onFieldChange }) => {
    const dispatch = useDispatch()
    const [fields, setFields] = useState(selectedFields)
    const onSelect = useCallback((item) => {
        const existingFields = [...fields]
        const searchIndex = fields.findIndex((searchItem) => searchItem?.field_name == item?.field_name)
        if (searchIndex == -1)
            existingFields.push(item)
        else
            existingFields.splice(searchIndex, 1)

        setFields(existingFields)
    }, [fields])
    const onSubmit = (e) => {
        e?.preventDefault();
        onFieldChange(fields);
        dispatch(closeModal())
    }
    return <CustomDialog
        title="Select Fields"
        onSubmit={onSubmit}
        confirmText="Select"
    >
        <Box display="flex" flexDirection="column" >
            {
                LEAD_FORMS_FIELDS.map((item) => <FieldSelectionItem
                    title={item?.title}
                    isDefault={item?.isDefault}
                    selected={fields.find((searchItem) => searchItem?.field_name == item?.field_name)}
                    onSelect={() => onSelect(item)}
                />)
            }
        </Box>

    </CustomDialog>
}
const FieldItem = memo(({
    loading,
    label,
    title,
    field_name,
    type,
    required,
    isDefault,
    index,
    onChangeItem,
    onRemove
}) => <Box mb={1} >
        <Typography variant="body2" color={'grey'}>
            {title}
        </Typography>
        <Grid container spacing={3} >

            <Grid item xs={5} >
                <CustomInput

                    disabled={loading}
                    value={label}
                    onChange={(e) => onChangeItem(index, 'label', e.target.value)}
                    type="text"
                    label={"Label*"}
                    size="small"
                />
            </Grid>
            <Grid item xs={5} >
                <CenteredBox>
                    <FormControlLabel disabled={isDefault} label="Is Required" control={<Checkbox />} checked={required} onChange={(e) => onChangeItem(index, 'required', e.target.checked)} />
                </CenteredBox>
            </Grid>
            <Grid item xs={2} >
                <CenteredBox>
                    {
                        <IconButton disabled={isDefault} size="small" onClick={(e) => onRemove(index)} >
                            <Remove fontSize="inherit" color={isDefault ? undefined : "error"} />
                        </IconButton>
                    }
                </CenteredBox>
            </Grid>
        </Grid>
    </Box>)

const CreateUi = ({ title, isUpdate, fields, setFields, loading, onSubmit, modalKey }) => {

    const dispatch = useDispatch()
    const onFieldChange = (newFields) => {
        const existingFields = [...fields.fields]
        const fieldsToBeAdded = newFields.map((item) => existingFields.find((searchItem) => searchItem?.field_name == item?.field_name) ?? item)
        setFields({ ...fields, fields: fieldsToBeAdded })
    }
    const onOpenAddFields = () => {
        dispatch(openModal(<FieldSelectionDropDown selectedFields={fields.fields} onFieldChange={onFieldChange} />, 'xs'))
    }
    const onOpenCustomizeFields = () => {
        dispatch(openModal(<CustomizationModal defaultFields={fields} setFieldsFun={setFields} />, 'xs'))
    }
    const onChangeFieldsValue = (index, itemKey, value) => {
        const existingFields = [...fields.fields]
        existingFields[index][itemKey] = value
        setFields({ ...fields, fields: existingFields })
    }
    const onRemoveFieldsValue = (index) => {
        const existingFields = [...fields.fields]
        existingFields.splice(index, 1)
        setFields({ ...fields, fields: existingFields })
    }

    return <>

        <CustomDialog
            id={modalKey}
            loading={loading}
            err={fields.err}
            onSubmit={onSubmit}
            title={`${isUpdate ? "Update" : "Create"} ${title}`}
            closeText="Close"
            confirmText={`${isUpdate ? "Update" : "Create"}`}
        >

            {loading ? <CenteredBox><CircularProgress /></CenteredBox> :
                <>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} >
                            {<CustomInput
                                autoFocus={true}
                                disabled={loading}
                                value={fields.logo}
                                onChange={(e) => setFields({ ...fields, err: '', logo: e.target.value })}
                                type="text"
                                label={"Logo URL*"}

                            />}
                            {fields.logo && fields.logo != '' && <img
                                style={{ width: "100%", objectFit: "contain" }}
                                src={fields.logo}
                            />}

                            {<CustomInput

                                disabled={loading}
                                value={fields.form_title}
                                onChange={(e) => setFields({ ...fields, err: '', form_title: e.target.value })}
                                type="text"
                                label={"Form Title*"}

                            />}
                            {<CustomInput

                                disabled={loading}
                                value={fields.form_description}
                                onChange={(e) => setFields({ ...fields, err: '', form_description: e.target.value })}
                                type="text"
                                label={"Form Description*"}

                            />}

                            {<CustomInput

                                disabled={loading}
                                value={fields.submit_btn_text}
                                onChange={(e) => setFields({ ...fields, err: '', submit_btn_text: e.target.value })}
                                type="text"
                                label={"Submit Button Text*"}

                            />}
                            <AsyncDropDown

                                lazyFun={async (params) => { return await getSourceApi({ ...params }) }}
                                defaultVal={fields.default_source}
                                OptionComponent={({ option, ...rest }) => {
                                    return <MenuItem {...rest}>{option.name}</MenuItem>
                                }}
                                onChange={async (changedVal) => { setFields({ ...fields, default_source: changedVal }) }}
                                titleKey={'name'}
                                valueKey={"_id"}
                                InputComponent={(params) => <CustomInput placeholder="Default Source" {...params} />}

                            />
                            <Box display="flex" justifyContent="flex-end" mt={2}>
                                <ButtonBase onClick={onOpenCustomizeFields} >
                                    <Typography sx={{ textDecoration: "underline" }} color="primary">Customize Form Ui</Typography>
                                </ButtonBase>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6} >

                            <Box display="flex" justifyContent="space-between" alignItems="center" >
                                <Typography mb={1}>Form Fields</Typography>
                                <Tooltip arrow title="Add Fields" >
                                    <IconButton onClick={onOpenAddFields} >
                                        <AddCircle color="primary" />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                            <Paper component={Box} variant="outlined" p={2} >
                                {
                                    fields.fields?.map((item, index) => <FieldItem
                                        key={item?.field_name}
                                        label={item?.label}
                                        loading={loading}
                                        index={index}
                                        field_name={item?.field_name}
                                        type={item?.type}
                                        title={item?.title}
                                        required={item?.required}
                                        isDefault={item?.isDefault}
                                        onChangeItem={onChangeFieldsValue}
                                        onRemove={onRemoveFieldsValue}
                                    />)
                                }
                            </Paper>
                        </Grid>
                    </Grid>


                </>}

        </CustomDialog>
    </>
}
export default memo(CreateUi)