import { memo, useCallback, useEffect, useState } from "react";
import ListUi from "./ListUi";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { useParams } from "react-router-dom";
import { closeModal, openModal } from "../../store/actions/modalAction";
import { findNameByRole, moduleAccessCondition } from "../../utils/helpers/helper";
import {
  Box,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useMemo } from "react";

import {
  AddCircleOutline,
  Delete,
  Download,
  Edit,
  Gavel,
  Info,
  LockOpen,
  Undo,
} from "@mui/icons-material";
import MessageDilog from "../../components/MessageDilog";
import moment from "moment";
import FileDownloadComponent from "../../components/FileDownloadComponent";
import endpoints from "../../apis/endpoints";
import InstallerUpdateController from "./InstallerUpdateController";
import AgreementListController from "../aggrement/ListController";
import getInstallerApi, {
  deleteInstallerApi,
  undoInstallerDeleteApi,
} from "../../apis/installer.api";
import CreateController from "./CreateController";
import DetailsUi from "./DetailViewUi";
import UpdateInstallerFieldController from "./UpdateInstallerFieldController";
import ResetPasswordController from "./ResetPassword.controller";
import MODULES from "../../utils/constants/module.constants";

const ActionComponent = memo(({ params, setParams, deleteApi }) => {
  const dispatch = useDispatch();
  console.log("paramsssssssssssssss", params);
  const { user } = useSelector((state) => state);
  const [loading, setLoading] = useState(false);

  const onEdit = () => {
    dispatch(
      openModal(
        <CreateController
          id={params._id}
          callBack={(response, updatedData) => {
            setParams({ ...params, ...updatedData });
          }}
        />,
        "lg"
      )
    );
  };

  const onResetPassword = () => {
    dispatch(
      openModal(
        <ResetPasswordController
          id={params._id}
          callBack={(response, updatedData) => {
            setParams({ ...params, ...updatedData });
          }}
        />,
        "sm"
      )
    );
  };

  const onView = () => {
    dispatch(
      openModal(
        <AgreementListController
          installer_id={params._id}
          callBack={(response, updatedData) => {
            setParams({ ...params, ...updatedData });
          }}
        />,
        "md",
        undefined,
        "aggrement-list"
      )
    );
  };
  const deleteFun = async () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await deleteInstallerApi({ id: params._id }),
        (response) => {
          setParams({});
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
    dispatch(closeModal());
  };

  const undoDeleteFun = async () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await undoInstallerDeleteApi({ id: params._id }),
        (response) => {
          setParams({ ...params, deleted: false });
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
    dispatch(closeModal());
  };

  const onDelete = () => {
    dispatch(
      openModal(
        <MessageDilog
          onSubmit={deleteFun}
          title="Alert!"
          message={`Are you sure to delete "${params.name || params.title}" ?`}
        />,
        "sm"
      )
    );
  };

  const onUndo = () => {
    dispatch(
      openModal(
        <MessageDilog
          onSubmit={undoDeleteFun}
          title="Alert!"
          message={`Are you sure to undo delete for "${params.name || params.title
            }" ?`}
        />,
        "sm"
      )
    );
  };

  const onDetail = () => {
    dispatch(openModal(<DetailsUi params={params} />, "md", undefined, "list"));
  };

  if (params.deleted)
    return (
      <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
        {" "}
        {moduleAccessCondition(user, [MODULES.DELETE_INSTALLER]) &&  <IconButton onClick={onUndo}>
          <Undo color="error" />
        </IconButton>}
      </Box>
    );
  return (
    <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
      <Tooltip arrow title=" Installer View">
        <IconButton size="inherit" onClick={onDetail}>
          <Info color="info" fontSize="inherit" />
        </IconButton>
      </Tooltip>

      {moduleAccessCondition(user, [MODULES.VIEW_INSTALLER_AGGREMENT]) && <Tooltip title="Agreement">
        <IconButton size="inherit" onClick={onView}>
          <Gavel color="primary" fontSize="inherit" />
        </IconButton>
      </Tooltip>}

      {moduleAccessCondition(user, [MODULES.UPDATE_INSTALLER]) &&
        <IconButton size="inherit" onClick={onEdit}>
          <Edit color="primary" fontSize="inherit" />
        </IconButton>
      }
      {/* )} */}
      {moduleAccessCondition(user, [MODULES.UPDATE_INSTALLER]) &&
        <IconButton disabled={loading} size="inherit" onClick={onResetPassword}>
          <LockOpen color="info" fontSize="inherit" />
        </IconButton>
      }
      {moduleAccessCondition(user, [MODULES.DELETE_INSTALLER]) && <>
        {loading && <CircularProgress color="primary" fontSize="inherit" />}
        {!loading && (
          <IconButton disabled={loading} size="inherit" onClick={onDelete}>
            <Delete color="error" fontSize="inherit" />
          </IconButton>
        )}
      </>}
    </Box>
  );
});

const ListController = () => {
  const dispatch = useDispatch();

  const title = "Installers";
  const fetchApi = getInstallerApi;

  const columns = useMemo(
    () => [
      { id: 1, fieldName: "name", label: "Name", align: "left", sort: true },
      { id: 2, fieldName: "email", label: "Email", align: "left" },

      {
        id: 8,
        fieldName: "phone",
        label: "Phone",
        align: "left",
        // minWidth: 300,
      },
      // { id: 3, fieldName: "accrediation_number", label: "Name", align: "left" },
      // {
      //   id: 4,
      //   fieldName: "license_number",
      //   label: "License Number",
      //   align: "left",
      // },
      // {
      //   id: 5,
      //   fieldName: "license_expiry",
      //   label: "Licencse Expiry",
      //   align: "left",
      //   renderValue: (params) => {
      //     return moment(params.license_expiry).format("DD MMM YYYY");
      //   },
      // },
      // {
      //   id: 6,
      //   fieldName: "license_file",
      //   label: "License File",
      //   align: "left",
      //   renderValue: (params) => {
      //     return <FileDownloadComponent src={params?.license_file} />;
      //   },
      // },
      //   {
      //     id: 7,
      //     fieldName: "aggrement_file",
      //     label: "Agreement File",
      //     align: "left",
      //     renderValue: (params) => {
      //       return <FileDownloadComponent src={params.aggrement_file} />;
      //     },
      //   },

      {
        id: 8,
        fieldName: "",
        label: "Action",

        align: "right",
        renderValue: (params, setParams) => (
          <ActionComponent
            // deleteApi={deleteApi}
            params={params}
            setParams={setParams}
          />
        ),
      },
    ],
    [dispatch]
  );

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    role: "",
    sort: "",
    sortDirection: 1,
  });

  // do not change

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState({});

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchApi({ ...filters }),
        (response) => {
          setList(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };
  const onCreateBtnClick = () => {
    dispatch(
      openModal(
        <CreateController
          //   isInstaller
          callBack={async () => {
            fetchList();
          }}
        />,
        "sm"
      )
    );
  };

  useEffect(() => {
    fetchList();
  }, [filters]);

  return (
    <>
      <ListUi
        title={title}
        onCreateBtnClick={onCreateBtnClick}
        filters={filters}
        setFilters={setFilters}
        loading={loading}
        list={list}
        columns={columns}
      />
    </>
  );
};
export default ListController;
