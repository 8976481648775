import { memo, useState } from "react"
import SubmitButton from "../../../components/button/SubmitButton"
import { Alert, AlertTitle, CircularProgress, IconButton, Tooltip, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"

import { INVOICE_STATUS, INVOICE_TYPE } from "../../../utils/constants/invoice.constant"
import CreateController from "./CreateController"
import { openModal } from "../../../store/actions/modalAction"
import useInovice from "./useInvoice"
import { MODAL_KEYS } from "../../../utils/constants/modal.constant"
import { isAllowedPartialPayment } from "../../../utils/helpers/invoice.helper"
import { moduleAccessCondition } from "../../../utils/helpers/helper"
import MODULES from "../../../utils/constants/module.constants"
import { PROJECT_FLAGS } from "../../../utils/constants/project.constant"
import { getStatusOfProjectFlags } from "../../../utils/helpers/project.helper"

const PayInvoiceButton = ({ invoice, callBack = () => { } }) => {

    const { user } = useSelector(state => state)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const invoiceColor = useInovice(invoice)
    let buttonTitle = 'Pay Invoice'
    if (isAllowedPartialPayment(invoice.type)) {
        buttonTitle = "Add Payment"
    }
    if (invoice?.is_outgoing) {
        buttonTitle = "Initiate Payment"
    }
    if (invoiceColor.isVarificationPending) {
        return <></>
    }

    const onPaid = () => {
        dispatch(openModal(<CreateController type={invoice.type} project={invoice.project} callBack={callBack} id={invoice._id} status={INVOICE_STATUS.PAID} />, 'sm', undefined, MODAL_KEYS.CREATE_INVOICE))
    }
    
    if (
        (invoiceColor.isVarificationPending)
        ||
        (invoice.status == INVOICE_STATUS.PAID)
        ||
        (!moduleAccessCondition(user, [MODULES.PAY_INVOICE]))

    )
        return <></>


 


    return <SubmitButton size="small" loading={loading} onClick={onPaid} sx={{ zIndex: 1 }} disableElevation title={buttonTitle} ></SubmitButton>
}
export default memo(PayInvoiceButton)