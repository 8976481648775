import { memo, useEffect, useMemo, useState } from "react"
import useValidate from "../../store/hooks/useValidator"
import CreateUi from "./CreateUi"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { closeModal } from "../../store/actions/modalAction"
import { useParams } from "react-router-dom"

import { sendEmailApi } from "../../apis/emails.api"
import { callSnackBar } from "../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../utils/constants/constants"
import { commonMail, defaultTemplate, installtionBookingTemplate, vicApplicationtemplate, vicApprovaltemplate, welcomeEmail } from "../../utils/mailtemplates"
import { MODAL_KEYS } from "../../utils/constants/modal.constant"
import { EMAIL_TEMPLATE_TYPE } from "../../utils/constants/emailTemplate.constant"

const CreateController = ({ modalKey = MODAL_KEYS.EMAIL, callBack = () => { }, defaultMessage = '', to, reply, messageId, reply_to, subject, emailTemplateType = EMAIL_TEMPLATE_TYPE.GENERAL, contentId, subjectSuffix }) => {
    const validate = useValidate()
    const { user } = useSelector(state => state)

    const dispatch = useDispatch()
    const params = useParams()

    const title = reply ? reply : "Send Email "
    const createApi = sendEmailApi



    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',
        to,
        subject,
        email_from: emailTemplateType,
        content_id: contentId,
        html: "<p> </p>",
        defaultMessage,
        messageId,
        cc: null,
        bcc: null,
        subjectSuffix,
        reply_to

    })


    const [files, setfiles] = useState([])


    const validationSchemaForCreate = useMemo(() => ([
        {
            required: true,
            isEmail: true,
            value: fields.to,
            field: 'To field ',
        },
        {
            required: true,

            value: fields.subject,
            field: 'Subject ',
        },
        {
            required: true,

            value: fields.html,
            field: 'Body ',
        },

    ]), [fields])





    const createFunction = async () => {
        const validationResponse = validate(validationSchemaForCreate)

        if (validationResponse === true) {
            setLoading(true)
            const formData = new FormData()
            formData.append('subject', reply ? "Re: " : '' + fields.subject)
            formData.append('to', fields.to)

            if (fields.content_id) {
                formData.append('content_id', fields.content_id)
            }
            if (fields.email_from) {
                formData.append('email_from', fields.email_from)
            }
            if (fields.cc && Array.isArray(fields.cc) && fields.cc.length > 0) {
                formData.append('cc', fields.cc.join(','))
            }
            if (fields.bcc && Array.isArray(fields.bcc) && fields.bcc.length > 0) {
                formData.append('bcc', fields.bcc.join(','))
            }

            let thread = ''
            if (reply) {
                thread = `<div style="padding-left:5px;border-left:2px solid gray">
                        ${defaultMessage}
                </div>`
            }

            formData.append('html', commonMail(user.data.phone, user.data.email, fields.html) + " <br/> " + thread)

            for (let file of files) {
                formData.append('file', file)
            }

            if (reply_to) {
                formData.append('reply_to', reply_to)
                formData.append('messageId', messageId)
            }


            dispatch(
                callApiAction(
                    async () => await createApi(formData),
                    async (response) => {
                        await callBack(response)
                        setLoading(false)
                        dispatch(callSnackBar("Mail sent successfully", SNACK_BAR_VARIETNS.suceess))
                        dispatch(closeModal(modalKey))

                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }

    useEffect(() => {
        if (fields.template == 1) {
            setFields({ ...fields, bcc: [process.env.REACT_APP_COMPANY_EMAIL] })
        }
    }, [fields.template])




    const onSubmit = async (e) => {
        e.preventDefault()

        createFunction()

    }





    return <CreateUi emailTemplateType={emailTemplateType} modalKey={modalKey} setfiles={setfiles} files={files} title={title} loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
}
export default memo(CreateController)