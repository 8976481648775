import { Button, ButtonGroup, Chip, CircularProgress, IconButton, Paper, Tooltip, Typography, styled } from "@mui/material"
import { Box } from "@mui/system"
import { useDispatch } from "react-redux"
import SubmitButton from "../../components/button/SubmitButton"
import { memo, useCallback, useState } from "react"
import NoDataComponent from "../../components/layouts/NoDataComponent"
import { AddCircle, Delete, DragIndicator, Edit, FlashAuto, FlashOn } from "@mui/icons-material"
import AddStepController from "./steps/AddStepController"
import update from 'immutability-helper'
import CreateController from "./CreateController"
import { CenteredBox } from "../../components/layouts/OneViewBox"
import DeleteButton from "./buttons/DeleteButton"
import { updateDeleteStatusOfPipelineApi, updateDeleteStatusOfPipelineStatusApi, updatePrioritiesOfPipelineStepsApi } from "../../apis/pipeline.api"
import DefaultMarkButton from "./buttons/DefaultMarkButton"
import AddStepButton from "./buttons/AddStepButton"
import EditButton from "./buttons/EditButton"
import { DndProvider, useDrag, useDragLayer, useDrop } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { callApiAction } from "../../store/actions/commonAction"
import { callSnackBar } from "../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../utils/constants/constants"
import PipelinUserButton from "./buttons/PipelineUserButton"


const FilterTitleBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }
}))

const CustomDragLayer = () => {
    const { item, isDragging, currentOffset } = useDragLayer((monitor) => ({
        item: monitor.getItem(),
        isDragging: monitor.isDragging(),
        currentOffset: monitor.getSourceClientOffset(),
    }));

    if (!isDragging || !currentOffset) {
        return null;
    }

    const { x, y } = currentOffset;
    return (
        <div style={{ pointerEvents: "none", position: "fixed", top: 0, left: 0, width: "100%", height: "100%", zIndex: 11111 }}>
            <Box sx={{}}>
                <Box sx={{ transform: `translate(${x}px, ${y}px)`, width: "500px" }}>
                    <StepItem {...item} />
                </Box>
            </Box>
        </div>
    );
};
const StepItem = (({ pipeline_id, index, label, onDelete, color, id, deleted, onEdit, moveItem, isDraggable, stepNo }) => {


    const [{ isDragging, allowedDrop }, ref, preview] = useDrag({
        type: pipeline_id,
        item: { pipeline_id, label, onDelete, color, id, deleted, onEdit, index, isDraggable },
        end: (item, monitor) => {

            const dropResult = monitor.getDropResult();
            const drop = monitor.didDrop()
            if (drop)
                moveItem(index, dropResult.index, true);


        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const [, drop] = useDrop({
        accept: pipeline_id,
        drop: (item) => ({ pipeline_id, label, onDelete, color, id, deleted, onEdit, index, isDraggable }),
        hover: (draggedItem) => {
            if (draggedItem.index !== index) {

                moveItem(draggedItem.index, index);
                draggedItem.index = index;

            }
        }
    });

    return <>
        <Box ref={!isDraggable ? undefined : (node) => drop(ref((node)))} component={Paper} sx={{ border: 1, borderLeft: 8, borderColor: color, opacity: isDragging ? 0.3 : 1, background: allowedDrop ? "red" : "white" }} display="flex" alignItems="center" p={0} px={2} my={1}>
            <Box display="flex" flex={1} alignItems="center" >
                {isDraggable && <CenteredBox sx={{ cursor: "grab",flex:0 }}>
                    <Typography color={color} lineHeight="100%" >
                        <DragIndicator fontSize="inherit" />
                    </Typography>
                </CenteredBox>}
                <Typography color={color} >
                    {label}
                </Typography>
            </Box>
            <DeleteButton
                title="Step"
                deleted={deleted}
                id={id}
                deletApi={updateDeleteStatusOfPipelineStatusApi}
                onDelete={onDelete}
            />
            <EditButton
                title="Step"
                id={id}
                EditComponent={AddStepController}
                callBack={(response, updatedData) => {
                    onEdit(id, updatedData)
                }}
            />
        </Box>
        <Box ref={preview} ></Box>
    </>
})

const StepsContailer = memo(({ params, setParams }) => {
    const dispatch = useDispatch()
    const onStepDelete = (id, deleteStatus) => {
        const steps = [...(params.steps ?? [])].map((item) => item?._id == id ? ({ ...item, deleted: deleteStatus }) : ({ ...item }))
        setParams({ ...params, steps })
    }
    const onEdit = useCallback((id, editableData = {}) => {
        const steps = [...(params.steps ?? [])].map((item) => item?._id == id ? ({ ...item, ...editableData }) : ({ ...item }))
        setParams({ ...params, steps })
    }, [params, setParams])

    
    const moveItem = (dragIndex, hoverIndex, updateInServer) => {

        const steps = params?.steps

        const dragRecord = steps[dragIndex]
        const updatedSteps = update(steps, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, dragRecord],
            ],
        })
        if (updateInServer)
            dispatch(
                callApiAction(
                    async () => await updatePrioritiesOfPipelineStepsApi({ pipeline_id: params?._id, steps: updatedSteps?.map((item) => item?._id) }),
                    async (response) => {
                    },
                    (err) => {
                        dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
                    }
                )
            )

        setParams(
            {
                ...params, steps: updatedSteps

            }
        )
    }

    return <Paper elevation={0} component={Box} p={2} mt={2} py={3}>
        <Typography variant="body2">Steps:</Typography>
        {
            params?.steps?.map((step, index) => <StepItem
                key={step?._id}
                isDraggable={params?.steps?.findIndex(item => item?.deleted) === -1}
                index={index}
                pipeline_id={params?._id}

                id={step?._id}
                color={step?.color}
                label={step?.title}
                deleted={step?.deleted}
                onDelete={onStepDelete}
                onEdit={onEdit}
                moveItem={moveItem}
            />)
        }
        <CustomDragLayer />
        {
            (!params?.steps || params?.steps?.length == 0) && <NoDataComponent iconVariant="body1" variant="body1" message="No Steps Found" />
        }
    </Paper>
})



const PipelineItems = memo(({ _id, title, deleted, steps, is_default, onDefaultSelect }) => {
    const dispatch = useDispatch()

    const [params, setParams] = useState({
        title,
        _id,
        steps,
        deleted,
    })





    return <Paper variant="outlined" component={Box} p={3} mb={2} >
        <Box sx={{ width: "100%", display: "flex" }}>
            <Box display="flex" flex={1} alignItems="center" >
                <DefaultMarkButton
                    title={params.title}
                    id={params._id}
                    is_default={is_default}
                    deleted={params.deleted}
                    callBack={onDefaultSelect}
                />
                <Typography variant="body1" >
                    {params?.title}
                </Typography>

            </Box>
            <Box>
                <AddStepButton params={params} setParams={setParams} />

                <PipelinUserButton pipeline_id={params?._id} />
                {!is_default && <DeleteButton
                    id={params?._id}
                    deleted={params.deleted}
                    deletApi={updateDeleteStatusOfPipelineApi}
                    title="Pipeline"
                    onDelete={() => setParams({ ...params, deleted: !params?.deleted })}
                />
                }
                <EditButton
                    title="Pipeline"
                    id={params?._id}
                    EditComponent={CreateController}
                    callBack={(response, updatedData) => {
                        setParams({ ...params, ...updatedData })
                    }}
                />

            </Box>
        </Box>
        <StepsContailer params={params} setParams={setParams} />

    </Paper>
})


const ListUi = ({ title, list, filters, setFilters, loading, onCreateBtnClick, callBack, onDefaultSelect }) => {


    return (
        <>

            <Box mb={3}>
                <Paper elevation={2} sx={{ width: "100%", padding: 4 }} >
                    <Box  >
                        <FilterTitleBox>
                            <Typography variant="h5" mb={2} >{title}</Typography>

                            <Box mb={2}>
                                {<SubmitButton variant="contained" onClick={onCreateBtnClick} title={"Add Pipeline"} />}
                            </Box>

                        </FilterTitleBox>
                        <FilterTitleBox>
                            <ButtonGroup disableElevation variant="outlined" >
                                <Button onClick={() => setFilters({ ...filters, deleted: null })} variant={filters.deleted == null ? "contained" : "outlined"} >All</Button>
                                <Button onClick={() => setFilters({ ...filters, deleted: false })} variant={filters.deleted == false ? "contained" : "outlined"} >Active</Button>
                                <Button onClick={() => setFilters({ ...filters, deleted: true })} variant={filters.deleted == true ? "contained" : "outlined"}>Deleted</Button>
                            </ButtonGroup>
                        </FilterTitleBox>
                    </Box>
                    <DndProvider backend={HTML5Backend}>
                        <Box sx={{ minHeight: "300px" }} p={2} mt={2}>
                            {loading && (
                                <CenteredBox>
                                    <CircularProgress />
                                </CenteredBox>
                            )}
                            {!loading &&
                                list &&
                                list?.length > 0 &&
                                list.map((item, index) => {
                                    return (
                                        <PipelineItems key={item?._id} {...item} callBack={callBack} onDefaultSelect={onDefaultSelect} />
                                    );
                                })}
                        </Box>
                    </DndProvider>
                </Paper>
            </Box>

        </>
    )
}
export default ListUi