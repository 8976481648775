    import { redirect } from "react-router-dom"
    import {getAnnouncementApi, readAnnouncementsApi } from "../../apis/announcement.api"
    import { actions } from "../../utils/constants/constants"
    import { callApiAction } from "./commonAction"
    export const fetchAnnouncementAction = () => {
        return async (dispatch, getState) => {
        dispatch(callApiAction(
                async () => await getAnnouncementApi({ pageSize: 3 }),
                (response) => {
                    // console.log("response",response);
                    console.log("responsedd inside action",response)
                    const lastReadId = getState().user.data.last_read_announcement;
                    console.log("last Read Od",lastReadId);
                    const updatedResponse = response.result.map((item) => (
                        {
                            ...item,
                            announcement_state : false,
                        }
                    ))
                    console.log("updated announcement", updatedResponse)
                    // console.log(response);
                    // console.log(lastReadId);
                    console.log("announcement",response);

                    // if (response && Array.isArray(response) && response.length > 0) {
                    //     for (let i = 0; i < response.length; i++) {

                           
                           
                    //     }
                    // }
                    dispatch({ type: actions.SET_ANNOUNCEMENT_DATA, data: updatedResponse })


                },
                (err) => {

                }
            ))
        }
    }
 
    