import { Chip } from "@mui/material";
import moment from "moment";
import { LOG_TYPE, USER_ROLES } from "../constants/constants";

import { localStatecallModal } from "./lead.helper";

export const accessToken = {
  set: (val) => {
    localStorage.setItem("solar_crm_access_token", val);
  },
  get: () => localStorage.getItem("solar_crm_access_token"),
  remove: () => {
    localStorage.removeItem("solar_crm_access_token");
  },
};
export const loggedInUser = {
  set: (val) => {
    localStorage.setItem("solar_crm_user", JSON.stringify(val));
  },
  get: () =>
    localStorage.getItem("solar_crm_user")
      ? JSON.parse(localStorage.getItem("solar_crm_user"))
      : null,
  remove: () => {
    localStorage.removeItem("solar_crm_user");
  },
};
export const spectatorID = {
  set: (val) => {
    localStorage.setItem("solar_crm_spectator_id", val);
  },
  get: () => localStorage.getItem("solar_crm_spectator_id"),
  remove: () => {
    localStorage.removeItem("solar_crm_spectator_id");
  },
};
export const refreshToken = {
  set: (val) => {
    localStorage.setItem("solar_crm_refresh_token", val);
  },
  get: () => localStorage.getItem("solar_crm_refresh_token"),
  remove: () => {
    localStorage.removeItem("solar_crm_refresh_token");
  },
};

export const unEscapeStr = (htmlStr) => {
  if (htmlStr)
    return htmlStr
      .replace(/&amp;/g, "&")
      .replace(/%2f/g, "/")
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">")
      .replace(/&quot;/g, '"')
      .replace(/&#039;/g, "'")
      .replace(/&#x2F;/g, "/");
  else return htmlStr;
};

export const lastReadNotification = {
  set: (val) => {
    localStorage.setItem("last_notification", val);
  },
  get: () =>
    localStorage.getItem("last_notification")
      ? localStorage.getItem("last_notification")
      : null,
  remove: () => {
    localStorage.removeItem("last_notification");
  },
};
export const toTitleCase = (str) => {
  if (str)
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });

  return null;
};

export const chatgroupsLastReadMessages = {
  set: (groupId, messagId, messageTime) => {
    const stringData = localStorage.getItem("solar_crm_last_read_messages");
    const data = stringData ? JSON.parse(stringData) : {};

    data[groupId] = {
      messagId: messagId ?? null,
      messageTime: messageTime ?? null,
    };

    localStorage.setItem("solar_crm_last_read_messages", JSON.stringify(data));
  },
  get: (groupId) => {
    const stringData = localStorage.getItem("solar_crm_last_read_messages");
    const data = stringData ? JSON.parse(stringData) : {};
    return data[groupId] ?? null;
  },
  remove: () => {
    localStorage.removeItem("solar_crm_last_read_messages");
  },
};
export const logOut = () => {
  refreshToken.remove();
  accessToken.remove();
  loggedInUser.remove();
  lastReadNotification.remove();
  spectatorID.remove();
  localStatecallModal().remove();
};

export const getHeaders = () => {
  const token = `Bearer ${accessToken.get()}`;
  const headersObj = { Authorization: token }
  if (spectatorID.get())
    headersObj['spectator_id'] = spectatorID.get() ?? null
  return headersObj
};
export const getFileHeaders = () => {
  const token = `Bearer ${accessToken.get()}`;

  const headersObj = { Authorization: token ,"Content-Type": "multipart/form-data",}
  if (spectatorID.get())
    headersObj['spectator_id'] = spectatorID.get() ?? null
  return headersObj
  
};
export function setZeroPrefix(val) {
  if (parseInt(val, 10) < 10) {
    return `0${val}`;
  }
  return val;
}

export const dateConverter = ({ type = "DD_MM_YYYY", value }) => {
  if (type === "DD_MM_YYYY") {
    const dateObj = new Date(value);
    return `${setZeroPrefix(dateObj.getDate())}/${setZeroPrefix(
      dateObj.getMonth() + 1
    )}/${dateObj.getFullYear()}`;
  }
  return value;
};

export const validateEmail = (email) => {
  var re =
    /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
  return re.test(email);
};
export const validatePhone = (phone) => {
  var re = /^\d{10}$/;
  return phone.match(re);
};
export const allowedDateFilters = [
  {
    id: "today",
    label: "Today",
  },
  {
    id: "yesterday",
    label: "Yesterday",
  },
  {
    id: "last_week",
    label: "Last Week",
  },
  {
    id: "last_month",
    label: "Last Month",
  },
  {
    id: "last_year",
    label: "Last Year",
  },
  {
    id: "this_week",
    label: "This Week",
  },

  {
    id: "this_month",
    label: "This Month",
  },

  {
    id: "this_year",
    label: "This Year",
  },
];
export const getDateFiltersTime = (value) => {
  let date = new Date();
  let startDate = date.getTime();
  let endDate = moment(date.getTime()).endOf("day").valueOf();

  switch (value) {
    case "this_week":
      {
        startDate = moment().startOf("week").valueOf();
      }
      break;
    case "this_month":
      {
        // const temp = new Date(startDate)
        // startDate = new Date(temp.getFullYear(), temp.getMonth()).getTime()
        startDate = moment().startOf("month").valueOf();
      }
      break;
    case "this_year":
      {
        startDate = moment().startOf("year").valueOf();
      }
      break;

    case "last_month":
      {
        startDate = moment().add(-1, "month").startOf("month").valueOf();
        endDate = moment().add(-1, "month").endOf("month").valueOf();
      }
      break;
    case "last_year":
      {
        startDate = moment().add(-1, "year").startOf("year").valueOf();
        endDate = moment().add(-1, "year").endOf("year").valueOf();
      }
      break;
    case "last_week":
      {
        startDate = moment().add(-1, "week").startOf("week").valueOf();
        endDate = moment().add(-1, "week").endOf("week").valueOf();
      }
      break;
    case "today":
      {
        startDate = moment().startOf("day").valueOf();
        endDate = moment().endOf("day").valueOf();
      }
      break;
    case "yesterday":
      {
        startDate = moment().add(-1, "day").startOf("day").valueOf();
        endDate = moment().add(-1, "day").endOf("day").valueOf();
      }
      break;
    case "past":
      {
        endDate = new Date(endDate);

        endDate = new Date(
          endDate.getFullYear(),
          endDate.getMonth(),
          endDate.getDate() + 1
        ).getTime();

        startDate = null;
      }
      break;
    case "future":
      {
        startDate = new Date(startDate);

        startDate = new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate()
        ).getTime();

        endDate = null;
      }
      break;

    default: {
      const noOfDays = parseInt(value);

      // startDate = startDate - noOfDays * 3600 * 1000 * 24
      // const temp = new Date(startDate)
      // startDate = new Date(
      //   temp.getFullYear(),
      //   temp.getMonth(),
      //   temp.getDate()
      // ).getTime()
      startDate = null;
      endDate = null;
    }
  }

  return {
    startDate,
    endDate,
    dateTitle: value
  };
};

export const createBadge = (badgeStatus) => {
  let color = "default";

  return <Chip label={badgeStatus} color={color}></Chip>;
};

export const leaveStatusColored = (status) => {
  let color = "default";
  switch (status) {
    default:
      color = "default";
      break;
  }
  return color;
};
export const titleCase = (s) => {
  if (s)
    return s
      .toLowerCase()
      .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase()) // Initial char (after -/_)
      .replace(/[-_]+(.)/g, (_, c) => " " + c.toUpperCase());

  return null;
};

export const findNameByRole = (role) => {
  for (let val in USER_ROLES) {
    if (USER_ROLES[val] == role) {
      return titleCase(val);
      break;
    }
  }
  return undefined;
};
export const findObjectKeyByValue = (value, object) => {
  for (let val in object) {
    if (object[val] == value) {
      return titleCase(val);
      break;
    }
  }
  return undefined;
};

export const fetchFileName = (str) => {
  if (str) {
    const split = str.split("/");
    return split[split.length - 1];
  }
  return "";
};
export const createAddress = (data, project = false) => {
  if (project) {
    return [
      data?.address,
      data?.locality,
      data?.state,
      data?.country,
      data?.postcode,
    ]
      .filter((item) => item && item != "")
      .join(", ");
  }
  return [
    data?.customer_address,
    data?.customer_locality,
    data?.customer_state,
    data?.customer_country,
    data?.customer_postcode,
  ]
    .filter((item) => item && item != "")
    .join(", ");
};
export function validatePhoneNumber(phoneNumber) {
  // Define a regular expression for a 10-digit phone number starting with 0
  // var pattern = /^0\d{9}$/;

  // // Use the test method to check if the phone number matches the pattern
  // return pattern.test(phoneNumber);
  return true;
}

export const calculateWorkingHours = (data) => {
  let lastLog = null;
  let firstCheckIn = null;
  let lastCheckOut = null;
  let lasttime = 0;
  let milliSeconds = 0;
  data &&
    Array.isArray(data) &&
    data
      .sort((a, b) => moment(a.time).valueOf() - moment(b.time).valueOf())
      .forEach((item) => {
        if ([LOG_TYPE.CHECK_IN, LOG_TYPE.CHECK_OUT].includes(item.logType)) {
          if (!firstCheckIn && item.logType == LOG_TYPE.CHECK_IN) {
            firstCheckIn = item.time;
          }
          if (item.logType == LOG_TYPE.CHECK_OUT) {
            lastCheckOut = item.time;
          }

          if (item.logType == LOG_TYPE.CHECK_OUT) {
            milliSeconds =
              milliSeconds + (moment(item.time).valueOf() - lasttime);
          }

          lastLog = item.logType;
          lasttime = moment(item.time).valueOf();
        }
      });

  // if (lastLog == LOG_TYPE.CHECK_IN) {
  //     const date = moment(lasttime)
  //     const todaysDate = moment()

  //     if (date.get("date") == todaysDate.get('date') && date.get("month") == todaysDate.get("month") && date.get("year") == todaysDate.get("year")) {
  //         milliSeconds = milliSeconds + (moment().valueOf() - lasttime)
  //         lastCheckOut = moment().valueOf()
  //     } else {

  //         milliSeconds = milliSeconds + (moment(lasttime).endOf("date").valueOf() - lasttime)
  //         lastCheckOut = moment(lasttime).endOf("date").valueOf()
  //     }

  // }
  const DEFAULT_LOGOUT_TIME = 14;
  let checkedOut = true;
  if (lastLog == LOG_TYPE.CHECK_IN) {
    const date = moment(lasttime);
    const todaysDate = moment();

    checkedOut = false;

    if (
      date.get("date") == todaysDate.get("date") &&
      date.get("month") == todaysDate.get("month") &&
      date.get("year") == todaysDate.get("year")
    ) {
      milliSeconds = milliSeconds + (moment().valueOf() - lasttime);

      lastCheckOut = null; //moment().toISOString()
    } else {
      milliSeconds =
        milliSeconds +
        (moment(lasttime)
          .set("hour", DEFAULT_LOGOUT_TIME)
          .set("minute", 0)
          .valueOf() -
          lasttime);
      lastCheckOut = moment(lasttime)
        .set("hour", DEFAULT_LOGOUT_TIME)
        .set("minute", 0)
        .toISOString();
    }
  }

  const hours = Math.floor(milliSeconds / (1000 * 60 * 60));
  const minutes = Math.floor(
    (milliSeconds - hours * 60 * 60 * 1000) / (1000 * 60)
  );

  return {
    workingHours: hours,
    minutes,
    firstCheckIn: moment(firstCheckIn).format("DD-MM-YYYY HH:mm:ss"),
    lastCheckOut: moment(lastCheckOut).format("DD-MM-YYYY HH:mm:ss"),
  };
};

export const removeObjectById = (array, id) => {
  const index = array.findIndex((obj) => obj.id === id);
  if (index !== -1) {
    array.splice(index, 1);
    return true;
  }
  return false;
};
export const replaceObjectValueById = (array, id, key, value) => {
  const index = array.findIndex((obj) => obj.id === id);
  if (index !== -1) {
    array[index][key] = value;
    return true;
  }
  return false;
};
export function insertNewString(existingHtml, newString) {
  // Create a temporary DOM element to parse the HTML string
  var tempDiv = document.createElement("div");
  tempDiv.innerHTML =
    existingHtml && existingHtml != "" ? existingHtml : "<p>  </p>";

  // Get the first (and only) child of the temporary element
  var element = tempDiv.firstChild;

  // Append the new string to the text content of the element
  element.innerHTML += " " + newString;

  // Return the updated HTML string
  return tempDiv.innerHTML;
}

export const moduleAccessCondition = (user, modules = [], roles = []) => {
  if (!user?.isLoggedIn) return false;

  let moduleAcces = false;
  let roleAccess = false;
  const userAllowedModules = user.data?.modules ?? [];
  const userRole = user.data?.role;

  for (let userAllowedModule of userAllowedModules) {
    for (let accessModule of modules) {
      if (userAllowedModule == accessModule) {
        moduleAcces = true;
        break;
      }
    }
  }
  roleAccess = roles?.includes(userRole);
  return moduleAcces || roleAccess;
};

export const viewLimitedLengthName = (
  name = "",
  length = 10,
  splitBase = true
) => {
  if (name?.length <= length) return name;
  if (splitBase) {
    if (name?.split(" ")[0]?.length > length)
      return name?.split(" ")[0]?.slice(0, length - 2) + "...";

    return name?.split(" ")[0] + "...";
  }

  return name?.slice(0, length - 2) + "...";
};
export const replaceVariables = (htmlContent) => {
  // Define the pattern to match {{variable_title}}
  const pattern = /\{\{(.*?)\}\}/g;

  // Replace the matched pattern with the desired <span> element
  const replacedContent = htmlContent.replace(
    pattern,
    (match, variableName) => {
      return `<div class="ck-fake-link-selection">|| ${variableName}</div>`;
    }
  );

  return replacedContent;
};
export const lastReadVersion = {
  set: (val) => {
    localStorage.setItem("last_version", val);
  },
  get: () => localStorage.getItem("last_version"),
  remove: () => {
    localStorage.removeItem("last_version");
  },
};
export const isHTML = (input) => {
  if (input && typeof input == "string") {
    // Regular expression to detect HTML tags
    const htmlRegex = /<\/?[a-z][\s\S]*>/i;

    // Test if the input matches the HTML pattern
    return htmlRegex.test(input);
  }
  return false
}

export const removeDuplicates = (arr) => {
  return [...new Set(arr)];
}

export const groupByArray = (data, field = 'status') => {
  return data.reduce((acc, item) => {
    // Check if the status already exists in the accumulator
    if (!acc[item[field]]) {
      acc[item[field]] = [];
    }
    // Add the item to the corresponding status group
    acc[item[field]].push(item);
    return acc;
  }, {}); // Initial value of the accumulator is an empty object
}
export const copyToClipboard = (text) => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(text)
      .then(() => {
        console.log('Text copied to clipboard!');
      })
      .catch(err => {
        console.error('Failed to copy text: ', err);
      });
  } else {
    console.warn('Clipboard API not supported');
  }
}

export const convertHTMLVariableToStringVariable = (htmlString)=> {
  // Create a DOM parser to parse the HTML string
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  // Find all <span> elements with the 'custom-editor-is-variable' class
  const spans = doc.querySelectorAll('input.custom-editor-is-variable');

  spans.forEach(span => {
      // Get the text content of the span
      const variableText = span.value;

      // Create the replacement string
      const replacement = `{{${variableText.trim()}}}`;

      // Replace the span with the replacement text
      const replacementNode = document.createTextNode(replacement);
      span.parentNode.replaceChild(replacementNode, span);
  });

  // Serialize the modified HTML back to a string and return it
  return doc.body.innerHTML;
}

export const  reventToHTMLVariableFromStringVariable =(templateString='')=> {
  // Create a DOM parser to parse the HTML string
  const parser = new DOMParser();
  const doc = parser.parseFromString(templateString, 'text/html');

  // Replace all {{variable}} with <span class='custom-editor-is-variable'>variable</span>
  const elements = doc.body.querySelectorAll('*');

  elements.forEach(element => {
      const textNodes = Array.from(element.childNodes).filter(node => node.nodeType === Node.TEXT_NODE);
      
      textNodes.forEach(textNode => {
          const textContent = textNode.textContent;

          // Replace {{variable}} with <span> elements
          const replacedHTML = textContent.replace(/{{(.*?)}}/g, (match, variable) => {
              return `<input class='custom-editor-is-variable' disabled=''type='button' value='${variable.trim()}'></input>`;
          });

          // Replace the original text node with the new HTML
          const tempDiv = document.createElement('div');
          tempDiv.innerHTML = replacedHTML;
          
          // Insert new HTML elements before the original text node and remove the old text node
          while (tempDiv.firstChild) {
              element.insertBefore(tempDiv.firstChild, textNode);
          }
          element.removeChild(textNode);
      });
  });

  // Serialize the modified HTML back to a string and return it
  return doc.body.innerHTML;
}




export const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
  };
};




export const constructUrl = (domain="", protocol = 'https') => {
  // If the domain already includes http or https, return as is
  if (domain?.startsWith?.('http://') || domain?.startsWith?.('https://')) {
    return domain;
  }

  // Otherwise, prepend the provided or default protocol
  return `${protocol}://${domain}`;
};