import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconButton } from '@mui/material';
import { Edit, Group, SolarPower } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from '../../../store/actions/modalAction';
import LeadUpdateContactDetailsController from '../update/contact-details/LeadUpdateContactDetailsController';
import { MODAL_KEYS } from '../../../utils/constants/modal.constant';
import LeadUpdateSiteDetailsController from '../update/site-details/LeadUpdateSiteDetailsController';
import LeadLastUpdateDateButton from '../update/last-update-date/LeadLastUpdateDateButton';
import LeadUpdateProjectIdButton from '../update/project-id/LeadUpdateProjectIdButton';
import MODULES from '../../../utils/constants/module.constants';
import { moduleAccessCondition } from '../../../utils/helpers/helper';

const EditButton = ({ projectDetails, setProjectDetails,disableEdit }) => {
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {user} =  useSelector(state=>state)
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const allowedEdit = !disableEdit

  const onContactDetailsEdit = () => {
    dispatch(openModal(
      <LeadUpdateContactDetailsController
        callBack={(res) => {
          setProjectDetails({ ...projectDetails, ...(res ?? {}) })
        }}
        contacts={projectDetails?.contacts}
        id={projectDetails?._id}
      />,
      "sm",
      false,
      MODAL_KEYS.LEAD_UPDATE_MODAL
    ))
  }

  const onSiteDetailsEdit = () => {
    dispatch(openModal(
      <LeadUpdateSiteDetailsController
        callBack={(res) => {
          setProjectDetails({ ...projectDetails, ...(res ?? {}) })
        }}
        projectData={{
          "e_distributor": projectDetails?.e_distributor,
          "e_retailer": projectDetails?.e_retailer,
          "address": projectDetails?.address,
          "locality": projectDetails?.locality,
          "state": projectDetails?.state,
          "country": projectDetails?.country,
          "postcode": projectDetails?.postcode,
          "lat": projectDetails?.lat,
          "lon": projectDetails?.lon,
          "property_type": projectDetails?.property_type,
          "roof_type": projectDetails?.roof_type,
          "meter_identifier": projectDetails?.meter_identifier,
          "nmi_no": projectDetails?.nmi_no,
          "no_of_storeys": projectDetails?.no_of_storeys,
          "phase": projectDetails?.phase,
          "power_factor": projectDetails?.power_factor,
          "celluler_coverage": projectDetails?.celluler_coverage,

        }}
        id={projectDetails?._id}
      />,
      "sm",
      false,
      MODAL_KEYS.LEAD_UPDATE_MODAL
    ))
  }

  return (
    <div>
      <IconButton
      disabled={!allowedEdit}
        id="edit-button"
        aria-controls={open ? 'edit-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <Edit color={!allowedEdit ?"grey":'primary'} />
      </IconButton>
      <Menu
        id="edit-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'edit-button',
        }}
      >
        <MenuItem  onClick={onContactDetailsEdit}><Group fontSize='inherit' /> &nbsp;Contact Details</MenuItem>
        <MenuItem onClick={onSiteDetailsEdit}><SolarPower fontSize='inherit' /> &nbsp;Site Details</MenuItem>
        <LeadLastUpdateDateButton id={projectDetails?._id} title='Closing Date' date_field="lead_close_date" date={projectDetails?.lead_close_date} callBack={(res)=>setProjectDetails({...projectDetails,...(res??{})})} />
        <LeadLastUpdateDateButton id={projectDetails?._id} title='Movement Date' date_field="lead_updated_date" date={projectDetails?.lead_updated_date} callBack={(res)=>setProjectDetails({...projectDetails,...(res??{})})} />  
        
        <LeadUpdateProjectIdButton id={projectDetails?._id}  callBack={(res)=>setProjectDetails({...projectDetails,...(res??{})})}></LeadUpdateProjectIdButton>
      </Menu>
    </div>
  );
}
export default EditButton