import { Paper, Tab, Tabs, Typography, styled } from "@mui/material";
import { Box } from "@mui/system";

import DataTable from "../../components/tables/DataTable";

import TimeRangeSelector from "../../components/layouts/common/TimeRangeSelector";
import { CUSTOMER_STATUS } from "../../utils/constants/constants";
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxInDesktop";

import AsynSearchBar from "../../components/inputs/AsynSearchBar";
import SubmitButton from "../../components/button/SubmitButton";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import MODULES from "../../utils/constants/module.constants";
import { moduleAccessCondition } from "../../utils/helpers/helper";
import { useSelector } from "react-redux";

const FilterTitleBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));
const FiltersBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",

  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const ListUi = ({
  installer_id,
  title,
  modalKey,
  filters,
  setFilters,
  list,
  loading,
  onCreateBtnClick,
  columns,
}) => {
  const { user } = useSelector(state => state)
  if (!moduleAccessCondition(user, [MODULES.VIEW_INSTALLER_AGGREMENT]))
    return <></>
  return (
    <>
      <CustomDialog
        loading={loading}
        id={modalKey}
        err={filters.err}
        closeText="Close"

      >
        <Box mb={3}>

          <Box mb={4}>
            <FilterTitleBox>
              <Typography variant="h5" mb={2}>
                {title}
              </Typography>

              <Box mb={2}>
                {moduleAccessCondition(user, [MODULES.CREATE_INSTALLER_AGGREMENT], []) && (
                  <SubmitButton
                    variant="contained"
                    onClick={onCreateBtnClick}
                    title={"Add Agreement"}
                  />
                )}
              </Box>
            </FilterTitleBox>

            <FiltersBox mt={3}>
              <PaddingBoxInDesktop
                mb={2}
                sx={{ display: "flex", flex: 1 }}

              >


                <AsynSearchBar
                  fullWidth
                  title="Search by Title  "
                  size="small"
                  placeholder={"Search Title "}
                  defaultValue={filters.search}
                  onChange={(changedVal) => {
                    setFilters({ ...filters, search: changedVal });
                  }}
                />
              </PaddingBoxInDesktop>
            </FiltersBox>
          </Box>

          <Box sx={{ minHeight: "300px" }}>
            <DataTable
              columns={columns}
              rows={list.result ? list.result : []}
              count={list.total ?? 0}
              filters={filters}
              setFilters={setFilters}
              loading={loading}
            />
          </Box>
          {/* </Paper> */}
        </Box>
      </CustomDialog>
    </>
  );
};
export default ListUi;
