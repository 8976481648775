import { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";

import { closeModal, openModal } from "../../store/actions/modalAction";
import {
  Box,
  CircularProgress,
  IconButton,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { useMemo } from "react";
import getBlogApi from "../../apis/blog.api";
import AllBlogShowUi from "./AllBlogShowUi";

const AllBlogShowController = () => {
  const dispatch = useDispatch();

  const title = "Blogs ";
  const fetchApi = getBlogApi;
  const modalkey = "blog-view-more"
  const deleteApi = null;

//   const columns = useMemo(
//     () => [
//       {
//         id: 1,
//         fieldName: "title",
//         label: "Title",
//         align: "left",
//         sort: true,
//       },
//     //   {
//     //     id: 2,
//     //     fieldName: "announcement_description",
//     //     label: "Description",
//     //     align: "left",
//     //     sort: true,
//     //   },

//       {
//         id: 5,
//         fieldName: "",
//         label: "Action",

//         align: "right",
//         renderValue: (params, setParams) => (
//           <ActionComponent
//             deleteApi={deleteApi}
//             params={params}
//             setParams={setParams}
//           />
//         ),
//       },
//     ],
//     [dispatch]
//   );

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    searchable: ["title"],
    sort: "title",
    sortDirection: 1,
  });

  // do not change

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState({});

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchApi({ ...filters }),
        (response) => {
          setList(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };
 
  useEffect(() => {
    fetchList();
  }, [filters]);

  return (
    <>
      <AllBlogShowUi
        modalKey={modalkey}
        title={title}
        filters={filters}
        setFilters={setFilters}
        loading={loading}
        list={list}
        // columns={columns}
      />
    </>
  );
};
export default AllBlogShowController;
