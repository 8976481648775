import { Autocomplete, Button, ButtonGroup, MenuItem, Paper, Typography, styled } from "@mui/material"
import { Box } from "@mui/system"
import { useSelector } from "react-redux"
import SubmitButton from "../../components/button/SubmitButton"
import { StyledSearchBar } from "../../components/inputs/SearchBar"
import { USER_ROLES } from "../../utils/constants/constants"
import DataTable from "../../components/tables/DataTable"
import { findObjectKeyByValue, moduleAccessCondition, titleCase } from "../../utils/helpers/helper"
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxInDesktop"
import AsyncSearchBar from "../../components/inputs/AsynSearchBar"
import MODULES from "../../utils/constants/module.constants"
import { getAllowedRolesForExistingRole } from "../../utils/helpers/user.helper"
import { useMemo } from "react"
import AsyncDropDown from "../../components/inputs/AsyncDropDown"
import { getCompanyApi } from "../../apis/company.api"



const FilterTitleBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }

}))
const FiltersBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",

    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }
}))


const ListUi = ({ title, filters, setFilters, list, roles, loading, onCreateBtnClick, columns }) => {

    const user = useSelector(state => state.user)
    const allowedRoles = useMemo(() => {
        return getAllowedRolesForExistingRole(user?.data?.role).map((item) => ({ label: findObjectKeyByValue(item, USER_ROLES), _id: item }))
    }, [user?.data?.role])
    return (
        <>

            <Box mb={3}>
                <Paper elevation={2} sx={{ width: "100%", padding: 4 }} >
                    <Box mb={4} >
                        <FilterTitleBox>
                            <Typography variant="h5" mb={2} >{title}</Typography>

                            <Box mb={2}>
                                {moduleAccessCondition(user, [MODULES.CREATE_USERS], []) && <SubmitButton variant="contained" onClick={onCreateBtnClick} title={"Add Member"} />}
                            </Box>

                        </FilterTitleBox>


                        <FiltersBox mt={3} >
                            <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1 }} >
                                <Autocomplete
                                    disableClearable
                                    id="combo-box-demo"
                                    onChange={(e, newVal) => {
                                        setFilters({ ...filters, role: newVal ? newVal._id : null })
                                    }}
                                    options={[{ label: 'All', _id: null }, ...allowedRoles]}
                                    sx={{ width: "100%", display: "flex", "*": { display: "flex", justifyContent: "center" } }}
                                    renderInput={(params) => <StyledSearchBar placeholder="Select Role" {...params} size="small" />}
                                />
                            </PaddingBoxInDesktop>

                            {moduleAccessCondition(user, [MODULES.COMPANY_MODULE_LIST]) && <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1 }} pl={3}>
                                <AsyncDropDown

                                    margin={0}

                                    InputComponent={(props) => <StyledSearchBar placeholder="Select Company"  {...props} margin="none" size="small" />}
                                    lazyFun={async (params) => { return await getCompanyApi({ ...params }) }}

                                    OptionComponent={({ option, ...rest }) => {
                                        return <MenuItem {...rest}>{option.name}</MenuItem>
                                    }}
                                    onChange={async (changedVal) => {

                                        setFilters({ ...filters, ['company']: changedVal ? changedVal._id : null })
                                    }}
                                    titleKey={'name'}
                                    valueKey={"_id"}

                                />
                            </PaddingBoxInDesktop>}
                            <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1 }} pl={3} >


                                <AsyncSearchBar

                                    fullWidth
                                    title="Search Name" size="small" placeholder={"Search Name"}
                                    defaultValue={filters.search}
                                    onChange={(changedVal) => { setFilters({ ...filters, search: changedVal }) }}
                                />
                                {/* <StyledSearchBar fullWidth title="Search Name | Email" size="small" placeholder={"Search Name | Email"} value={filters.search} onChange={(e) => { setFilters({ ...filters, search: e.target.value }) }} /> */}
                            </PaddingBoxInDesktop>

                            <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }} pl={3} >


                                {moduleAccessCondition(user, [MODULES.DELETE_USERS]) && <ButtonGroup disableElevation variant="outlined" >

                                    <Button onClick={() => setFilters({ ...filters, deleted: null })} variant={filters.deleted == null ? "contained" : "outlined"} >Active User</Button>
                                    <Button onClick={() => setFilters({ ...filters, deleted: true })} variant={filters.deleted == true ? "contained" : "outlined"}>Deleted User</Button>
                                </ButtonGroup>}
                            </PaddingBoxInDesktop>

                        </FiltersBox>

                    </Box>

                    <Box sx={{ minHeight: "300px" }}>
                        <DataTable columns={columns} rows={list.result ? list.result : []} count={list.total ?? 0} filters={filters} setFilters={setFilters} loading={loading} />
                    </Box>
                </Paper>
            </Box>

        </>
    )
}
export default ListUi