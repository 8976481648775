import { Button, ButtonGroup, MenuItem, Paper, Switch, Tab, Tabs, Typography, Zoom, styled } from "@mui/material"
import { Box } from "@mui/system"
import { useContext } from "react"
import { useSelector } from "react-redux"


import DataTable from "../../components/tables/DataTable"

import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxInDesktop"
import { BULK_INVOICE_TYPE, INVOICES_NAME, INVOICE_STATUS, INVOICE_TYPE } from "../../utils/constants/invoice.constant"
import AsynSearchBar from "../../components/inputs/AsynSearchBar"
import AsyncDropDown from "../../components/inputs/AsyncDropDown"
import { getInstallerApi, getUserApi } from "../../apis/user.api"
import { StyledSearchBar } from "../../components/inputs/SearchBar"
import InvoiceSummaryController from "./InvoiceSummary"
import SubmitButton from "../../components/button/SubmitButton"
import { BulkInvoiceContext, BulkInvoiceSelectionInitiateButton, endBulkSelection } from "./BulkInvoiceSelectionWrapper"
import { CustomTab, CustomTabs } from "../../components/layouts/common/Tabs"
import { USER_ROLES } from "../../utils/constants/constants"
import { isAllowedPartialPayment, isInvoiceAllowed } from "../../utils/helpers/invoice.helper"
import { FlagFilterButton } from "../projects/FlagFiltersComponent"
import { moduleAccessCondition } from "../../utils/helpers/helper"
import MODULES from "../../utils/constants/module.constants"



const FilterTitleBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }

}))
const FiltersBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",

    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }
}))


const ListUi = ({ title, filters, setFilters, list, loading, columns, exportLoading, onExportBtnClick }) => {

    const user = useSelector(state => state.user)
    const bulkInvoiceContext = useContext(BulkInvoiceContext)
    return (
        <>

            <Box mb={3}>
                <Paper elevation={2} sx={{ width: "100%", padding: 4 }} >
                    <Box mb={4} >
                        <FilterTitleBox>
                            <Typography variant="h5" mb={2} >{title}</Typography>

                        </FilterTitleBox>
                        {<CustomTabs

                            TabScrollButtonProps
                            textColor="default"
                            TabIndicatorProps={{
                                style: {
                                    display: "none"
                                }
                            }}
                            // value={filters.status}

                            variant="scrollable"
                            scrollButtons="auto"
                            value={filters.type}
                            onChange={(e, val) => {
                                setFilters({ ...filters, type: Number(val), assigned_installer: null, assigned_supplier: null })
                                bulkInvoiceContext.dispatch(endBulkSelection())
                            }}
                        >
                            {
                                Object.keys(INVOICES_NAME)
                                ?.filter(invoiceTypeId => isInvoiceAllowed(user?.data?.modules??[], invoiceTypeId))
                                ?.map((invoiceTypeId) => <CustomTab label={INVOICES_NAME[invoiceTypeId]} value={Number(invoiceTypeId)} key={invoiceTypeId} >

                                </CustomTab>)
                            }


                        </CustomTabs>}
                        <>
                            <FiltersBox mt={3} >

                                <PaddingBoxInDesktop sx={{ display: "flex", flex: 1, justifyContent: "flex-start", alignItems: "center" }} >
                                    <Box>
                                        <FlagFilterButton filters={filters} setFilters={setFilters} />
                                    </Box>
                                </PaddingBoxInDesktop>



                                <PaddingBoxInDesktop sx={{ display: "flex", flex: 1, justifyContent: "flex-end", alignItems: "center" }} >
                                    <Box>
                                        <ButtonGroup size="small" disableElevation>
                                            <Button variant={!filters.dueMissed ? "contained" : "outlined"} onClick={() => { setFilters({ ...filters, dueMissed: null }) }} >All</Button>
                                            <Button variant={filters.dueMissed == 'yes' ? "contained" : "outlined"} onClick={() => { setFilters({ ...filters, dueMissed: 'yes' }) }}>Due Date Missed</Button>
                                            <Button variant={filters.dueMissed == 'no' ? "contained" : "outlined"} onClick={() => { setFilters({ ...filters, dueMissed: 'no' }) }}>Due Date Pending</Button>

                                        </ButtonGroup>
                                    </Box>
                                </PaddingBoxInDesktop>
                            </FiltersBox>
                        </>
                        {
                            <FiltersBox mb={3}>
                                <PaddingBoxInDesktop sx={{ display: "flex", flex: 1 }} />
                                {filters.type == INVOICE_TYPE.ELECTRICIAN_INVOICE && <PaddingBoxInDesktop mt={2} sx={{ display: "flex", flex: 1 }} pl={3} >

                                    <AsyncDropDown
                                        id={'installer-invoices'}
                                        // value={filters.installer_id}
                                        lazyFun={async (params) => { return await getInstallerApi({ ...params }) }}

                                        OptionComponent={({ option, ...rest }) => {
                                            return <MenuItem {...rest}>{option.name}</MenuItem>
                                        }}
                                        onChange={async (changedVal) => { setFilters({ ...filters, installer_id: changedVal ? changedVal._id : null }) }}
                                        titleKey={'name'}
                                        valueKey={"_id"}
                                        InputComponent={(params) => <StyledSearchBar placeholder="Select Installer/Electrician" {...params} size="small" margin="none" />}
                                    />
                                </PaddingBoxInDesktop>}


                                {filters.type == INVOICE_TYPE.SUPPLIER_INVOICE && <PaddingBoxInDesktop mt={2} sx={{ display: "flex", flex: 1 }} pl={3} >

                                    <AsyncDropDown
                                        id={'suppliers-invoices'}
                                        // value={filters.supplier_id}
                                        lazyFun={async (params) => { return await getUserApi({ ...params, role: USER_ROLES.supplier }) }}

                                        OptionComponent={({ option, ...rest }) => {
                                            return <MenuItem {...rest}>{option.name}</MenuItem>
                                        }}
                                        onChange={async (changedVal) => { setFilters({ ...filters, supplier_id: changedVal ? changedVal._id : null }) }}
                                        titleKey={'name'}
                                        valueKey={"_id"}
                                        InputComponent={(params) => <StyledSearchBar placeholder="Select Supplier" {...params} size="small" margin="none" />}
                                    />
                                </PaddingBoxInDesktop>}

                            </FiltersBox>
                        }
                        <Box sx={{ mt: 2 }} >
                            <InvoiceSummaryController filters={filters} />
                        </Box>
                        <FiltersBox mt={3} >
                            <PaddingBoxInDesktop sx={{ display: "flex", flex: 1 }} >
                                <ButtonGroup size="small" disableElevation>
                                    <Button variant={filters.status == null && filters.parentStatus != 'pending' ? "contained" : "outlined"} onClick={() => { setFilters({ ...filters, status: null, parentStatus: null }) }} >All</Button>
                                    <Button variant={filters.parentStatus == 'pending' ? "contained" : "outlined"} onClick={() => {
                                        setFilters({ ...filters, status: INVOICE_STATUS.PENDING, parentStatus: "pending" })

                                    }}>Pending</Button>

                                    <Button variant={filters.status == INVOICE_STATUS.PAID ? "contained" : "outlined"} onClick={() => { setFilters({ ...filters, status: INVOICE_STATUS.PAID, parentStatus: null }) }}>


                                        {/* {[INVOICE_TYPE.SOLAR_VICTORIA_INVOICE, INVOICE_TYPE.STC_INVOICE].includes(filters?.type) ? "Received" : 'Paid'} */}
                                        {[INVOICE_TYPE.INCENTIVES_INVOICE].includes(filters?.type) ? "Received" : 'Paid'}
                                    </Button>
                                </ButtonGroup>
                            </PaddingBoxInDesktop>
                            <PaddingBoxInDesktop sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }} >
                                {
                                    isAllowedPartialPayment(filters?.type) &&
                                    <Zoom in={filters.parentStatus == 'pending'} >
                                        {<ButtonGroup disableElevation size="small" >
                                            <Button variant={filters.status == null ? "contained" : "outlined"} onClick={() => { setFilters({ ...filters, status: null }) }} >All</Button>
                                            <Button variant={filters.status == INVOICE_STATUS.PENDING ? "contained" : "outlined"} onClick={() => {
                                                setFilters({ ...filters, status: INVOICE_STATUS.PENDING })

                                            }}>
                                                Full amount Pending
                                            </Button>

                                            <Button variant={filters.status == INVOICE_STATUS.PARTIALLY_PAID ? "contained" : "outlined"} onClick={() => { setFilters({ ...filters, status: INVOICE_STATUS.PARTIALLY_PAID }) }}>Partially Paid</Button>
                                        </ButtonGroup>}
                                    </Zoom>}
                            </PaddingBoxInDesktop>




                            <PaddingBoxInDesktop>
                                <BulkInvoiceSelectionInitiateButton key={filters?.type} allowed={Object.values(BULK_INVOICE_TYPE).includes(filters.type)} type={filters.type} />
                            </PaddingBoxInDesktop>

                        </FiltersBox>

                        <FiltersBox mt={3} >

                            <PaddingBoxInDesktop sx={{ display: "flex", flex: 1 }}  >
                                <Box >

                                {moduleAccessCondition(user, [MODULES.EXPORT_CSV_INVOICE], []) &&<SubmitButton loading={exportLoading} variant="contained" onClick={onExportBtnClick} title={"Export CSV"} />}

                                </Box>
                            </PaddingBoxInDesktop>


                            <PaddingBoxInDesktop sx={{ display: "flex", flex: 1 }} pl={3} >

                                <AsynSearchBar

                                    fullWidth
                                    title="Search Invoice no" size="small" placeholder={"Search Invoice no "}
                                    defaultValue={filters.search}
                                    onChange={(changedVal) => { setFilters({ ...filters, search: changedVal }) }}
                                />
                            </PaddingBoxInDesktop>

                        </FiltersBox>

                    </Box>

                    <Box sx={{ minHeight: "300px", borderStyle: bulkInvoiceContext.allow_selection ? "dotted" : "unset" }}>
                        {bulkInvoiceContext.allow_selection && <Box p={2} >
                            <Typography>Bulk Selection Mode</Typography>
                        </Box>}
                        <DataTable key={(bulkInvoiceContext.allow_selection ? "Y" : "n") + filters.type} columns={columns} rows={list.result ? list.result : []} count={list.total ?? 0} filters={filters} setFilters={setFilters} loading={loading} />
                    </Box>
                </Paper>
            </Box>

        </>
    )
}
export default ListUi