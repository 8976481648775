import { useDispatch, useSelector } from "react-redux"
import ProjectUpdateController from "../../ProjectUpdateController"
import { USER_ROLES } from "../../../../utils/constants/constants"
import { useState } from "react"
import { PROJECT_FLAGS, PROJECT_STATUS } from "../../../../utils/constants/project.constant"
import { Box, Button, MenuItem, Select, Typography } from "@mui/material"
import { openModal } from "../../../../store/actions/modalAction"
import { findObjectKeyByValue, moduleAccessCondition, titleCase } from "../../../../utils/helpers/helper"
import { getPreviousStatus, getStatusOfProjectFlags } from "../../../../utils/helpers/project.helper"
import { MODAL_KEYS } from "../../../../utils/constants/modal.constant"
import { ArrowBack } from "@mui/icons-material"
import MODULES from "../../../../utils/constants/module.constants"

const UpdateStatusButton = ({ projectDetails, callBack = () => { } }) => {
    const { user } = useSelector(state => state)
    const dispatch = useDispatch()
    const role = user?.data?.role
    const { flags, status, ...remainedDetails } = projectDetails
    const [loading, setLoading] = useState(false)


    const handleChange = (value, title, otherProps = {}) => {

        dispatch(openModal(
            <ProjectUpdateController
                title={title}
                status={value}
                id={projectDetails?._id}
                callStart={() => {
                    setLoading(true)
                }}
                callBack={() => {

                    setLoading(false)
                    callBack()
                }}

                {...otherProps}
            />,
            "sm",
            undefined,
            MODAL_KEYS.UPDATE_PROJECT_STATUS
        ))

    }

    /* --------------------Admin Status------------------------- */

    if (moduleAccessCondition(user, [MODULES.UPDATE_LEADS], []) && moduleAccessCondition(user, [], [USER_ROLES.admin]))
        return <Box mt={1} >
            <Select

                fullWidth
                disabled={loading}
                sx={{ minWidth: "100px" }}
                id="Status"
                size="small"
                value={status}
                onChange={(e) => { handleChange(e.target.value, `${findObjectKeyByValue(e.target.value, PROJECT_STATUS)}`) }}
                autoWidth
            >
                {
                    Object.keys(PROJECT_STATUS).map((key) => <MenuItem fullWidth key={PROJECT_STATUS[key]} value={PROJECT_STATUS[key]}>{titleCase(key)}</MenuItem>)
                }
            </Select>

        </Box >
    if (!moduleAccessCondition(user, [MODULES.UPDATE_LEADS, MODULES.PROJECT_ADMIN]))
        return <></>
    return <Box>

        {/* --------------------Initial Status------------------------- */}
        {
            (moduleAccessCondition(user, [MODULES.PROJECT_ADMIN]) && [PROJECT_STATUS.REJECTED].includes(Number(status))) &&
            <Button sx={{ mb: 1 }} disableElevation variant="contained" size="small" color="primary" fullWidth onClick={() => handleChange(PROJECT_STATUS.NEW, "Initialize Project")} >Initialize Project</Button>
        }

        {/* --------------------Complete Pre Approval when grid approval granted------------------------- */}
        {

            [PROJECT_STATUS.UNDER_PRE_APPROVAL].includes(Number(status))
            && (flags?.[PROJECT_FLAGS.GRID_PRE_APPROVAL] == getStatusOfProjectFlags(PROJECT_FLAGS.GRID_PRE_APPROVAL).APPROVED)
            && (flags?.[PROJECT_FLAGS.DEPOSIT] == getStatusOfProjectFlags(PROJECT_FLAGS.DEPOSIT).RECIEVED)
            && (
                [getStatusOfProjectFlags(PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL).APPROVED, getStatusOfProjectFlags(PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL).REJECTED, getStatusOfProjectFlags(PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL).NOT_APPLICABLE].includes(
                    flags?.[PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL]
                )

            )
            && <Button sx={{ mb: 1 }} disableElevation variant="contained" size="small" color="primary" fullWidth onClick={() => handleChange(PROJECT_STATUS.PRE_APPROVAL_COMPLETED, "Complete Pre-approval")} >
                Complete Pre Approval
            </Button>
        }
        {/* --------------------Complete Pre Approval when grid approval not granted------------------------- */}
        {

            [PROJECT_STATUS.UNDER_PRE_APPROVAL].includes(Number(status))
            && (flags?.[PROJECT_FLAGS.GRID_PRE_APPROVAL] == getStatusOfProjectFlags(PROJECT_FLAGS.GRID_PRE_APPROVAL).REJECTED)
            && (flags?.[PROJECT_FLAGS.DEPOSIT] == getStatusOfProjectFlags(PROJECT_FLAGS.DEPOSIT).RECIEVED)
            && (
                [getStatusOfProjectFlags(PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL).APPROVED, getStatusOfProjectFlags(PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL).REJECTED, getStatusOfProjectFlags(PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL).NOT_APPLICABLE].includes(
                    flags?.[PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL]
                )

            )
            && <Button sx={{ mb: 1 }} disableElevation variant="contained" size="small" color="primary" fullWidth onClick={() => handleChange(PROJECT_STATUS.PRE_APPROVAL_COMPLETED, "Complete Pre-approval", { concernLetterRequired: true })} >
                Add Concern Letter
            </Button>
        }
        {/* --------------------Move For Custome Hold------------------------- */}

        {[PROJECT_STATUS.PRE_APPROVAL_COMPLETED].includes(Number(status))

            &&
            <Button sx={{ mb: 1 }} disableElevation variant="contained" size="small" color="primary" fullWidth onClick={() => handleChange(PROJECT_STATUS.CUSTOMER_HOLD, "Mark As Customer Hold")} >
                Customer Hold
            </Button>
        }
        {/* --------------------Move For Installation------------------------- */}

        {moduleAccessCondition(user, [MODULES.PROJECT_ADMIN])
            && [PROJECT_STATUS.PRE_APPROVAL_COMPLETED, PROJECT_STATUS.CUSTOMER_HOLD].includes(Number(status))

            &&
            <Button sx={{ mb: 1 }} disableElevation variant="contained" size="small" color="primary" fullWidth onClick={() => handleChange(PROJECT_STATUS.UNDER_INSTALLATION, "Move For Installation")} >
                Move For Installation
            </Button>
        }
        {/* --------------------Installation Completed------------------------- */}

        {
            [PROJECT_STATUS.UNDER_INSTALLATION].includes(Number(status))

            && (flags?.[PROJECT_FLAGS.PURCHASE_ORDER] == getStatusOfProjectFlags(PROJECT_FLAGS.PURCHASE_ORDER).RECIEVED)
            && (flags?.[PROJECT_FLAGS.INSTALLATION] != getStatusOfProjectFlags(PROJECT_FLAGS.INSTALLATION).PENDING)
            && (flags?.[PROJECT_FLAGS.STC_CLAIMED] != getStatusOfProjectFlags(PROJECT_FLAGS.STC_CLAIMED).PENDING)
            && (
                (
                    flags?.[PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL] != getStatusOfProjectFlags(PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL).APPROVED
                    ||
                    (
                        flags?.[PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL] == getStatusOfProjectFlags(PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL).APPROVED
                        // &&
                        // flags?.[PROJECT_FLAGS.SOLAR_VICTORIA_QR] == getStatusOfProjectFlags(PROJECT_FLAGS.SOLAR_VICTORIA_QR).SCANNED
                    )
                )

            )
            && <Button sx={{ mb: 1 }} disableElevation variant="contained" size="small" color="primary" fullWidth onClick={() => handleChange(PROJECT_STATUS.INSTALLATION_COMPLETED, "Complete Installation")} >
                Complete Installation
            </Button>
        }


        {/* --------------------Move For Post Installation------------------------- */}

        {
            [PROJECT_STATUS.INSTALLATION_COMPLETED].includes(Number(status))

            &&
            <Button sx={{ mb: 1 }} disableElevation variant="contained" size="small" color="primary" fullWidth onClick={() => handleChange(PROJECT_STATUS.UNDER_POST_INSTALLATION, "Start Post Installation")} >
                Start Post Installation
            </Button>
        }

        {/* --------------------Complete Post Installation------------------------- */}

        {

            [PROJECT_STATUS.UNDER_POST_INSTALLATION].includes(Number(status))
            && (flags?.[PROJECT_FLAGS.CES_CERTI] == getStatusOfProjectFlags(PROJECT_FLAGS.CES_CERTI).RECIEVED)
            && (flags?.[PROJECT_FLAGS.STC_CLAIMED] == getStatusOfProjectFlags(PROJECT_FLAGS.STC_CLAIMED).APPROVED)
            && (
                (
                    flags?.[PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL] != getStatusOfProjectFlags(PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL).APPROVED
                    ||
                    (
                        flags?.[PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL] == getStatusOfProjectFlags(PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL).APPROVED
                        &&
                        flags?.[PROJECT_FLAGS.SOLAR_VICTORIA_POST_APPROVAL] == getStatusOfProjectFlags(PROJECT_FLAGS.SOLAR_VICTORIA_POST_APPROVAL).APPROVED
                    )
                )

            )
            &&
            flags?.[PROJECT_FLAGS.GRID_POST_APPROVAL] == getStatusOfProjectFlags(PROJECT_FLAGS.GRID_POST_APPROVAL).COMPLETED &&
            <Button sx={{ mb: 1 }} disableElevation variant="contained" size="small" color="primary" fullWidth onClick={() => handleChange(PROJECT_STATUS.POST_INSTALLATION_COMPLETED, "Complete Post Installation")} >
                Complete Post Installation
            </Button>
        }


        {/* --------------------Complete Project------------------------- */}

        {moduleAccessCondition(user, [MODULES.PROJECT_ADMIN])
            && [PROJECT_STATUS.POST_INSTALLATION_COMPLETED].includes(Number(status))

            &&
            <Button sx={{ mb: 1 }} disableElevation variant="contained" size="small" color="success" fullWidth onClick={() => handleChange(PROJECT_STATUS.COMPLETED, "Close Project")} >
                Close Project
            </Button>
        }

        {/* --------------------Rejection Status------------------------- */}
        {
            (moduleAccessCondition(user, [MODULES.UPDATE_LEADS]) && [PROJECT_STATUS.NEW, PROJECT_STATUS.IN_DISCUSSION, PROJECT_STATUS.UNDER_PRE_APPROVAL, PROJECT_STATUS.PRE_APPROVAL_COMPLETED, PROJECT_STATUS.CUSTOMER_HOLD].includes(Number(status))) &&
            <Button sx={{ mb: 1 }} disableElevation variant="contained" size="small" color="error" fullWidth onClick={() => handleChange(PROJECT_STATUS.REJECTED, "Reject Project")} >Reject Project</Button>

        }
        {/* {
            (moduleAccessCondition(user, [MODULES.PROJECT_ADMIN]) && [PROJECT_STATUS.REJECTED].includes(Number(status))) &&
            <Button sx={{ mb: 1 }} disableElevation variant="contained" size="small" color="error" fullWidth onClick={() => handleChange(PROJECT_STATUS.REJECTED_AND_VERIFIED, "Verify Rejection")} >Verify Rejection</Button>
        } */}


        {/* --------------------Stage Back Option------------------------- */}
        {moduleAccessCondition(user, [MODULES.UPDATE_LEADS, MODULES.LEAD_ADMIN]) && ![PROJECT_STATUS.INITIAL, PROJECT_STATUS.IN_DISCUSSION, PROJECT_STATUS.REJECTED, PROJECT_STATUS.REJECTED_AND_VERIFIED].includes(status) &&
            <Box mt={1}>
                <Button sx={{ mb: 1 }} disableElevation startIcon={<ArrowBack />} variant="contained" size="small" color="error" fullWidth onClick={() => handleChange(getPreviousStatus(status), "Back To Previous Stage")} >Back To Previous</Button>
            </Box>}



    </Box>
}
export default UpdateStatusButton