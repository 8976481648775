import { Box, CircularProgress, MenuItem } from "@mui/material"
import { memo } from "react"
import CustomDialog from "../../../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../../../components/layouts/OneViewBox"
import SiteDetailComponent from "../../create/SiteDetailComponent"




const LeadUpdateSiteDetailsUi = ({ title, fields, setFields, loading, onSubmit, modalKey }) => {
    

    return <>

        <CustomDialog
            id={modalKey}

            loading={loading}
            err={fields.err}

            onSubmit={onSubmit}
            title={` ${title}`}
            closeText="Close"
            confirmText={`Update`}
        >

            {loading ? <CenteredBox><CircularProgress /></CenteredBox> :
                <>
                    <SiteDetailComponent fields={fields} setFields={setFields} loading={loading} />
                </>}

        </CustomDialog>
    </>
}
export default memo(LeadUpdateSiteDetailsUi)