import { memo, useCallback, useEffect, useState } from "react"
import ListUi from "./ListUi"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { openModal } from "../../store/actions/modalAction"

import { Box, IconButton, Tooltip, useTheme } from "@mui/material"
import { useMemo } from "react"
import fileDownload from "js-file-download"
import { getFinanceApi } from "../../apis/finance.api"
import ProjectDetailsController from "../projects/projectdetails/ProjectDetailsController"
import { Edit, Info } from "@mui/icons-material"
import { findObjectKeyByValue, getDateFiltersTime } from "../../utils/helpers/helper"
import moment from "moment"
import { MODAL_KEYS } from "../../utils/constants/modal.constant"

import { CallButton, MailButton } from "../projects/projectdetails/buttons/ProjectIconButtons"
import CustomDialog from "../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../components/layouts/OneViewBox"
import { INVOICE_STATUS } from "../../utils/constants/invoice.constant"
import { calculateProfit } from "../../utils/helpers/project.helper"

const ActionComponent = memo(({ params, setParams }) => {
    const dispatch = useDispatch()

    const onProjectClick = () => {
        dispatch(openModal(<ProjectDetailsController project={params._id} />, "lg", undefined, MODAL_KEYS.PROJECT))
    }



    return <Box sx={{ width: "100%", display: "flex" }}>
        <MailButton email={params?.contacts?.[0]?.email}></MailButton>
        <CallButton phone={params?.contacts?.[0]?.phone}></CallButton>
        {<IconButton disableElevation variant="contained" size="small" onClick={onProjectClick}>
            <Info color="primary" />
        </IconButton>}
        <Box mt={1} />


    </Box>
})
const BackgroundComponent = memo(({ markOnAmountGreaterThanZero=false,amount, paymentStatus = [] }) => {
    const theme = useTheme()
    let bgColor = 'primary'
    let fontWeight = 500
    if (Number(amount) <= 0 && !markOnAmountGreaterThanZero){
        bgColor = theme.palette.error.main
        fontWeight=800
    }
    if(Number(amount) > 0 &&markOnAmountGreaterThanZero ){
        bgColor = theme.palette.warning.main
    }
    return <Box sx={{ width: "100%" }} >
        <CenteredBox sx={{ color: bgColor ?? "primary",fontWeight, width: "100%" }}>
            {amount}
        </CenteredBox>
        <CenteredBox >
            {
                paymentStatus.map((item, index) => <Tooltip arrow  title={INVOICE_STATUS.PAID==item ? "Paid" : "Pending payment"}><Box m={1} sx={{ height: "5px", width: "5px",borderRadius:"100px", background: item == INVOICE_STATUS.PAID ? theme.palette.success.main : theme.palette.warning.main }} /></Tooltip>)
            }
        </CenteredBox>
    </Box>
})
export const FinanceListModal = ({ type, defaultFilters = {} }) => {
    return <CustomDialog
        id={MODAL_KEYS.FINANCE_LIST}
        title="Finance"
    >
        <ListController type={type} defaultFilters={defaultFilters} />
    </CustomDialog>
}


const ListController = ({ type, defaultFilters = {} }) => {
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state)

    const title = "Finance"
    const fetchApi = getFinanceApi

    const columns = useMemo(() => {

        return [
            { id: 1, sort: true, fieldName: 'installtion_date', label: 'Installation Date', align: "left", renderValue: (params) => params.installtion_date ? moment(params.installtion_date).format("DD/MM/YYYY") : "NA" },
            { id: 13, minWidth: "100px", fieldName: 'uid', label: 'Project Id', align: "left", renderValue: (params) => params.uid ?? "NA" },
            { id: 15, fieldName: 'selected_system', label: "System Size(kw)", align: "left", renderValue: (params) => <BackgroundComponent paymentStatus={params?.selected_system_invoice_status} amount={params?.selected_system} /> },
            { id: 14, minWidth: "150px", fieldName: 'contacts.name', label: 'Customer', align: "left", renderValue: (params) => params.contacts?.[0]?.full_name ?? "NA" },
            
            { id: 16, fieldName: 'profit', label: "Gross Profit", align: "left", renderValue: (params) =><BackgroundComponent  amount={calculateProfit(params)}/> },
            { id: 2, fieldName: 'customer', label: "Customer Price", align: "left", renderValue: (params) => <BackgroundComponent paymentStatus={params?.customer_invoice_status} amount={params?.customer} /> },
{ id: 4, fieldName: 'incentive_invoice', label: "Incentives Price", align: "left", renderValue: (params) => <BackgroundComponent paymentStatus={params?.incentives_status} amount={params?.incentive_amount} /> },
            // { id: 3, fieldName: 'stc', label: "Stc Price", align: "left", renderValue: (params) => <BackgroundComponent paymentStatus={params?.stc_invoice_status} amount={params?.stc} /> },
            // { id: 4, fieldName: 'solar_victoria', label: "Solar victoria Price", align: "left", renderValue: (params) => <BackgroundComponent paymentStatus={params?.solar_victoria_invoice_status} amount={params?.solar_victoria} /> },
            { id: 7, fieldName: 'suppliers', label: "Suppliers", align: "left", renderValue: (params) => <BackgroundComponent paymentStatus={params?.suppliers_invoice_status} amount={params?.suppliers} /> },
            { id: 8, fieldName: 'electricians', label: "Electricians", align: "left", renderValue: (params) => <BackgroundComponent paymentStatus={params?.electricians_invoice_status} amount={params?.electricians} /> },
           
            { id: 9, fieldName: 'app_and_delivery', label: "App  & Delivery Fess", align: "left", renderValue: (params) => <BackgroundComponent paymentStatus={params?.app_and_delivery_invoice_status} amount={params?.app_and_delivery} /> },
            { id: 5, fieldName: 'general_charge', label: "General Charge", align: "left", renderValue: (params) => <BackgroundComponent markOnAmountGreaterThanZero paymentStatus={params?.general_charge_invoice_status} amount={params?.general_charge} /> },
            { id: 6, fieldName: 'extraa_charge', label: "Extraa Charge", align: "left", renderValue: (params) => <BackgroundComponent markOnAmountGreaterThanZero paymentStatus={params?.extraa_charge_invoice_status} amount={params?.extraa_charge} /> },            
            { id: 10, fieldName: 'loss', label: "Loss Invoices", align: "left" , renderValue: (params) => <BackgroundComponent markOnAmountGreaterThanZero paymentStatus={params?.loss_invoice_status} amount={params?.loss} />},
            { id: 11, fieldName: 'refund', label: "Refund", align: "left", renderValue: (params) => <BackgroundComponent markOnAmountGreaterThanZero paymentStatus={params?.refund_invoice_status} amount={params?.refund} /> },
            
            
            
            { id: 12, fieldName: 'Action', label: 'Action', align: "left", renderValue: (params, setParams) => <ActionComponent params={params} setParams={setParams} /> },
        ]

    }, [dispatch]);


    const [filters, setFilters] = useState({
        pageNo: 1,
        type,
        pageSize: 10,
        search: '',
        searchable: ['uid'],
        source: '',
        status: null,
        startDate: null, endDate: null,
        sort: 'installtion_date',
        rangeSelector: "",
        sortDirection: -1,
        ...getDateFiltersTime('this_month'),
        ...defaultFilters

    })





    // do not change 

    const [loading, setLoading] = useState(false)
    const [exportLoading, setexportLoading] = useState(false)
    const [list, setList] = useState({})









    const fetchList = (isExport) => {
        if (!isExport) {
            setLoading(true)
            dispatch(callApiAction(
                async () => await fetchApi({ ...filters }),
                (response) => {
                    setList(response)
                    setLoading(false)
                },
                (err) => {
                    setLoading(false)
                }
            ))
        } else {
            setexportLoading(true)
            dispatch(callApiAction(
                async () => await fetchApi({ ...filters, csv: true }),
                (response) => {
                    fileDownload(response, "finance.csv")
                    setexportLoading(false)
                },
                (err) => {
                     
                    setexportLoading(false)
                },
                true
            ))
        }
    }



    const onExportBtnClick = () => { fetchList(true) }

    useEffect(() => {
        fetchList()
    }, [filters])





    return (
        <>
            <ListUi
                title={title}
                onExportBtnClick={onExportBtnClick}
                exportLoading={exportLoading}
                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}
                columns={columns}


            />

        </>
    )
}
export default ListController