import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Box, ButtonGroup, MenuItem, styled } from '@mui/material';
import { LEAD_STATUS, LEAD_STATUS_COLOR, LEAD_STATUS_ICON } from '../../../../utils/constants/lead.constant';
import { titleCase } from '../../../../utils/helpers/helper';
import CustomInput from '../../../../components/inputs/CustomInput';
import AsyncDropDown from '../../../../components/inputs/AsyncDropDown';
import { getRejectionReasonApi } from '../../../../apis/rejectionreasons.api';
import { StyledSearchBar } from '../../../../components/inputs/SearchBar';
import SubmitButton from '../../../../components/button/SubmitButton';

const StatusButton = styled((props) => <Button {...props} size='small' />)(({ theme, active, status }) => ({
    padding: 0,

    background: active ? LEAD_STATUS_COLOR[status] + "!important" : undefined,
    borderColor: active ? LEAD_STATUS_COLOR[status] + "!important" : undefined,
    color: active ? "#fff" : undefined,
    flexDirection: "column"
}))
const StatusUpdateUI = ({ ButtonComponent, loading, fields, setFields, validateFun, handleClick, handleClose, anchorEl, currentStatus, onSubmit }) => {


    const open = Boolean(anchorEl);
    const id = open ? 'status-popover' : undefined;

    return (
        <Box width="100%" >
            <ButtonComponent aria-describedby={id} variant="contained" onClick={handleClick} />

            <Popover
                // disablePortal
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box sx={{ minHeight: "150px", width: "400px", maxWidth: "100%" }} p={2} >
                    <Box>
                        <ButtonGroup disableElevation fullWidth>
                            {
                                Object.keys(LEAD_STATUS).filter((item) => item != 'UNHANDLED').map((item) => <StatusButton
                                    key={item}
                                    status={LEAD_STATUS[item]}
                                    active={fields.status == LEAD_STATUS[item]}
                                    onClick={() => setFields({ ...fields, status: LEAD_STATUS[item], rejection_reason: null, remarks: "" })}
                                >
                                    {LEAD_STATUS_ICON[LEAD_STATUS[item]]}

                                    <Typography variant='caption' >
                                        {titleCase(item)}
                                    </Typography>
                                </StatusButton>)
                            }
                        </ButtonGroup>
                    </Box>
                    <Box>
                        {fields.status != LEAD_STATUS.REJECTED && <CustomInput
                            multiline
                            sx={{ width: "100%" }}
                            rows={2}
                            disabled={loading}
                            value={fields.remarks}
                            onChange={(e) => setFields({ ...fields, err: '', remarks: e.target.value })}
                            type="text"
                            label={"Remarks" + (currentStatus == LEAD_STATUS.WON ? "*" : "")}

                        />}
                        {
                            fields.status == LEAD_STATUS.REJECTED && <AsyncDropDown
                                id={'rejected-reason-'}

                                lazyFun={async (params) => { return await getRejectionReasonApi({ ...params, searchable: ['title'] }) }}
                                // label="Rejection Reason*"
                                OptionComponent={({ option, ...rest }) => {
                                    return <MenuItem {...rest}>{option.title}</MenuItem>
                                }}
                                onChange={async (changedVal) => { setFields({ ...fields, rejection_reason: changedVal ? changedVal.title : null }) }}
                                titleKey={'title'}
                                valueKey={"_id"}
                                margin="none"
                                InputComponent={(props) => <StyledSearchBar placeholder="Rejection Reason*"  {...props} size="small" />}
                            />
                        }

                        <SubmitButton disabled={currentStatus == fields.status || loading || validateFun() !== true} title="Save" size="small" onClick={onSubmit} />



                    </Box>
                </Box>
            </Popover >
        </Box >
    );
}
export default StatusUpdateUI