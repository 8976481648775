import { CircularProgress } from "@mui/material";

import { memo } from "react";
import { useSelector } from "react-redux";

import CustomInput from "../../components/inputs/CustomInput";

import CustomDialog from "../../components/layouts/common/CustomDialog";
import { CenteredBox } from "../../components/layouts/OneViewBox";

const ResetPasswordUi = ({
  title,
  isInstaller,
  isUpdate,
  fields,
  setFields,
  loading,
  onSubmit,
}) => {
  const { user } = useSelector((state) => state);

  return (
    <>
      <CustomDialog
        loading={loading}
        err={fields.err}
        onSubmit={onSubmit}
        title={`${title}`}
        closeText="Close"
        confirmText={`Reset`}
      >
        {loading ? (
          <CenteredBox>
            <CircularProgress />
          </CenteredBox>
        ) : (
          <>
            <CustomInput
              autoFocus={true}
              disabled={loading}
              value={fields.password}
              onChange={(e) =>
                setFields({ ...fields, err: "", password: e.target.value })
              }
              type="password"
              label={"Password*"}
            />
          </>
        )}
      </CustomDialog>
    </>
  );
};
export default memo(ResetPasswordUi);
