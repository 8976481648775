import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Collapse,
  Paper,
  styled,
  SwipeableDrawer,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { memo } from "react";
import AppModeLabel from "../../texts/AppModeLabel";
import PopUpModal from "../../Modal";
import Logo from "./Logo";
import Header from "./Header";
import Navbar from "./Navbar";
import CheckInCheckoutButton from "../../../pages/attendences/CheckInCheckoutButton";
import VersionDetailsController from "../../../pages/versions/VersionDetailsController";
import { openModal } from "../../../store/actions/modalAction";
import { useEffect, useMemo, useState } from "react";

import { lastReadVersion, spectatorID } from "../../../utils/helpers/helper";

const SpectateModeContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.warning.light,
  padding: theme.spacing(1),
  display: "flex",
  justifyContent: "space-between",
  paddingLeft: theme.spacing(3)
}))
const containerStyle = (theme) => ({
  // height: "100%",
  width: "100%",
  display: "flex",
  flex: 1,
  background: theme.palette.light.main,
  overflowY: "auto"

});
const navBarOuterStyle = (theme) => ({ height: "100%", position: "relative" });
const collapsableContainerStyle = (theme) => ({
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
});
const navbarInnerStyle = (theme) => ({
  width: "260px",
  background: "white",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  overflowY: "auto",
  borderRadius: "0px",
  scrollBarWidth: "0px",
  " -ms-overflow-style": "none",
  "::-webkit-scrollbar": {
    display: "none",
  },
});

const logoContainer = (theme) => ({
  width: "100%",
  position: "sticky",
  top: "0px",
  backgroundColor: theme.palette.light.main,
  zIndex: "1",
});
const rightContainerStyle = (theme) => ({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  overflowY: "scroll",
});
const workAreaStyle = (theme, overflow = "unset") => ({
  display: "flex",
  width: "100%",
  flexDirection: "column",
  overflow,
  flex: 1,

  background: theme.palette.grey.main,
  borderTopLeftRadius: theme.shape.borderRadius * 1,
});

const AppContainer = ({ overflow, ...props }) => {
  const { user } = useSelector((state) => state);

  const [navBar, setNavbar] = useState(true);
  const dispatch = useDispatch();

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  useEffect(() => {
    const lastVersion = lastReadVersion.get();
    const splittedVersion = lastVersion?.split?.("-") ?? [];

    if (
      user.data.web_version &&
      (!lastVersion ||
        splittedVersion[0] != user.data.web_version?.main ||
        splittedVersion[1] != user.data.web_version?.sub)
    ) {
      dispatch(
        openModal(
          // title: "New Update",
          <VersionDetailsController />,
          "md",
          undefined,
          "version-details"
        )
      );
    }
  }, []);
const onCloseSpectateMode = ()=>{
  spectatorID.remove()
  window.location.reload()
}
  return (
    <>
      {process.env.REACT_APP_APP_MODE != "production" && <AppModeLabel />}      
      <PopUpModal />
      <Box sx={{
        height: "100%",
        width: "100%", display: "flex", flexDirection: "column", overflow: "hidden"
      }}>
        {user?.data?.isSpectating &&
          <SpectateModeContainer >
            <Typography variant="caption" lineHeight="100%" >
              Spectate Mode:  close spectate mode to get logged in as director role
            </Typography>
            <Button onClick={onCloseSpectateMode} size="small" sx={{ py: 0 }} variant="contained" color="light" >Close Spectating</Button>
          </SpectateModeContainer>
        }
        <Box sx={containerStyle}>

          {!isSmallScreen && (
            <Box elevation={2} sx={navBarOuterStyle}>
              <Collapse
                orientation="horizontal"
                in={navBar}
                sx={collapsableContainerStyle}
              >
                <Paper elevation={0} sx={navbarInnerStyle}>
                  <Box pt={1} pb={3} sx={logoContainer}>
                    <Logo />
                  </Box>
                  <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                    <Navbar />
                  </Box>
                  <Box
                    component={Paper}
                    p={3}
                    sx={{
                      position: "sticky",
                      bottom: 0,
                      background: "light.main",
                    }}
                  >
                    <CheckInCheckoutButton
                      userId={user.data.userId}
                      callBack={() => { }}
                    />
                  </Box>
                </Paper>
              </Collapse>
            </Box>
          )}
          {isSmallScreen && (
            <SwipeableDrawer
              anchor={"left"}
              open={navBar}
              onClose={() => setNavbar(false)}
              onOpen={() => setNavbar(true)}
            >
              <Paper elevation={0} sx={navbarInnerStyle}>
                <Box pt={1} sx={logoContainer}>
                  <Logo mb={4} />
                </Box>
                <Navbar />
              </Paper>
            </SwipeableDrawer>
          )}
          <Box sx={rightContainerStyle}>

            <Header open={navBar} setOpen={setNavbar} />
            <Box p={3} sx={(theme) => workAreaStyle(theme, overflow)}>
              <Outlet />
              {props.children}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default memo(AppContainer);
