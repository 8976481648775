import { Navigate } from "react-router-dom";
import { moduleAccessCondition } from "./helper";
import MODULES from "../constants/module.constants";
import { Dashboard } from "@mui/icons-material";
import { USER_ROLES } from "../constants/constants";

export const defaultRedirect = (user) => {
    if (!user.isLoggedIn) {
        return "/sign-in";
    }
    if ((user.isLoggedIn && user?.data?.role != USER_ROLES.director)) {
        return '/dashboard'
    }

    if ((user.isLoggedIn && user?.data?.modules?.includes(MODULES.VIEW_PROJECTS))) {
        return '/projects'
    }
    if ((user.isLoggedIn && user?.data?.modules?.includes(MODULES.VIEW_COMPLAINS))) {
        return '/complains'
    }
    if ((user.isLoggedIn && user?.data?.modules?.includes(MODULES.COMPANY_MODULE_LIST))) {
        return '/company'
    }
    return "/not-allowed";
};
export const routeObjGenerator = (userObj, redirectDefault) => {


    return (path, title, Icon, element, moduleAccessRequired = [], roleAccessRequired = [], children, hideInPannel, customCondition, redirectComponent = <Navigate replace to={redirectDefault} />) => {

        let pathIsAllowed = true
        if (moduleAccessRequired?.length > 0 || roleAccessRequired.length > 0)
            pathIsAllowed = moduleAccessCondition(userObj, moduleAccessRequired, roleAccessRequired)
        if (typeof customCondition != "undefined") {

            pathIsAllowed = customCondition
        }

        return {
            path,
            title,
            icon: Icon ? <Icon /> : <Dashboard />,

            hideInPannel: hideInPannel || !moduleAccessCondition(userObj, moduleAccessRequired, roleAccessRequired),

            element: pathIsAllowed ? (
                element
            ) : (
                redirectComponent
            ),
            children
        }
    }
}