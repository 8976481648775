import {  CircularProgress,  Grid, Typography,  styled, Paper } from "@mui/material"
import { Box } from "@mui/system"
import {  memo } from "react"


import CustomDialog from "../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../components/layouts/OneViewBox"
import { TitleBox } from "../../components/layouts/common/boxes"
import NoDataComponent from "../../components/layouts/NoDataComponent"
import moment from "moment"
const NotesComponentBox = styled(Paper)(({ theme }) => ({
    background: theme.palette.secondary.main,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "*": {
        lineHeight: "100%"
    }
}))
const NotesComponent = ({ by, date, remarks }) => {
    return <>
        <NotesComponentBox >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="caption" fontWeight={600} >{by}</Typography>
                <Typography variant="caption" fontWeight={600} >{moment(date).format("DD/MM/yyyy")}</Typography>
            </Box>
            <Box mt={1}>
                <Typography variant="caption" >{remarks}</Typography>
            </Box>

        </NotesComponentBox>

    </>
}

const DetailedViewUi = ({ title, modalKey, fields, setFields, loading }) => {

    return <>

        <CustomDialog
            id={modalKey}
            loading={loading}
            err={fields.err}
            dialogProps={{ sx: { height: "10000px", overflow: "hidden" } }}
            title={fields.complain_id ? "Complain Id:" + fields.complain_id : title}
        >

            {loading ? <CenteredBox><CircularProgress /></CenteredBox> :
                <>
                    <Grid spacing={2} container sx={{ height: "100%", overflow: "hidden" }}>
                        <Grid item xs={12} sm={8} sx={{ height: "100%", overflow: "hidden" }}>
                            <TitleBox title={"Complain"}   >

                                <Box dangerouslySetInnerHTML={{
                                    __html: fields?.remarks ?? "",
                                }} >

                                </Box>

                            </TitleBox>
                        </Grid>
                        
                    </Grid>

                </>}

        </CustomDialog>
    </>
}
export default memo(DetailedViewUi)