
import { Autocomplete, Paper, Typography, styled } from "@mui/material"
import { Box } from "@mui/system"
import { useSelector } from "react-redux"


import PaddingBoxInDesktop from "../../../components/layouts/PaddingBoxInDesktop"

import { findObjectKeyByValue, titleCase } from "../../../utils/helpers/helper"
import SalesPersonFilterDropDown from "../../../components/filterdropdowns/SalesPersonFilter"
import TeamFilterDropDown from "../../../components/filterdropdowns/TeamFilterDropDown"
import FilterButton from "../filters/FilterButton"
import { LEAD_STATUS } from "../../../utils/constants/lead.constant"
import { StyledSearchBar } from "../../../components/inputs/SearchBar"
import LeadTimeFilters from "../filters/LeadTimeFilter"
import CustomMonthPicker from "../../../components/layouts/common/CustomMonthPicker"
import Calendar from "../../../components/layouts/Calendar"
import LeadCalendarInnerBox from "./LeadCalendarInnerBox"
import moment from "moment"



const FilterTitleBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }

}))
const FiltersBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",

    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }
}))


const LeadCalendarUi = ({ title, filters, setFilters, list, modal, loading }) => {

    const user = useSelector(state => state.user)

    return (
        <>
            {!modal && <Box mb={2}>
                <Paper elevation={0} sx={{ width: "100%", p: 2 }}  >
                    <Box p={2}>
                        {<FilterTitleBox >
                            <Typography variant="h5" mb={2} >{title}</Typography>

                        </FilterTitleBox>}


                        <FiltersBox mt={1} >

                            <PaddingBoxInDesktop sx={{ display: "flex" }}>
                                <LeadTimeFilters noRange filters={filters} setFilters={setFilters} forPannel />
                            </PaddingBoxInDesktop>

                            {<PaddingBoxInDesktop sx={{ display: "flex", flex: 1, flexDirection: "column" }} >
                                <FiltersBox sx={{ width: "100%" }} >
                                    <PaddingBoxInDesktop sx={{ display: "flex", flex: 1 }} pl={3}>
                                        <TeamFilterDropDown
                                            key={filters?.refreshKey}
                                            value={filters?.team}
                                            returnFullObj
                                            id={'teams-leads'}
                                            filters={filters}
                                            setFilters={setFilters}
                                        />
                                    </PaddingBoxInDesktop>
                                    {<PaddingBoxInDesktop sx={{ display: "flex", flex: 1 }} pl={3}>
                                        <SalesPersonFilterDropDown
                                            value={filters?.assigned_sales_person}
                                            returnFullObj
                                            id={'sales-leads'}
                                            filters={filters}
                                            setFilters={setFilters}
                                            key={filters.team?._id + filters?.refreshKey}
                                        />
                                    </PaddingBoxInDesktop>}

                                </FiltersBox>
                                <FiltersBox sx={{ width: "100%" }} mt={2} >
                                    <PaddingBoxInDesktop sx={{ display: "flex", flex: 1 }} pl={3} >
                                        <Autocomplete
                                            multiple
                                            key={filters?.refreshKey}
                                            size="small"
                                            value={filters?.lead_status?.map?.(item => ({ _id: item, label: findObjectKeyByValue(item, LEAD_STATUS) }))}

                                            onChange={(e, newVal) => {
                                                console.log(newVal)
                                                setFilters({ ...filters, lead_status: newVal ? newVal?.map((item) => item?._id) : [] })
                                            }}
                                            options={[ ...Object.keys(LEAD_STATUS).map((key) => ({ label: titleCase(key), _id: LEAD_STATUS[key] }))]}

                                            sx={{ width: "100%", display: "flex", "*": { display: "flex", justifyContent: "center" } }}
                                            renderInput={(params) => <StyledSearchBar placeholder="Select Status" {...params} size="small" />}
                                        />
                                    </PaddingBoxInDesktop>
                                </FiltersBox>
                            </PaddingBoxInDesktop>}



                        </FiltersBox>

                        <Box display="flex" justifyContent="flex-end" mt={1}>
                            <FilterButton notApplicableFilters={['date']} defaultFilters={filters} setDefaultFilters={setFilters} />
                        </Box>
                    </Box>
                </Paper>
            </Box>}
            <Box mb={3}>
                <Paper elevation={0} sx={{ width: "100%", padding: modal ? 0 : 2 }} >
                    <Box mb={2} >
                        <CustomMonthPicker date={filters.calendarDate} setDate={(dateObj) => setFilters({ ...filters, calendarDate: dateObj })} />
                    </Box>
                    <Calendar
                        loading={loading}
                        data={list}
                        Component={LeadCalendarInnerBox}
                        calendarTimeObj={filters.calendarDate.toDate()}
                    />
                </Paper >
            </Box >

        </>
    )
}
export default LeadCalendarUi