import axios from "axios";
import { getHeaders } from "../utils/helpers/helper";
import endpoints from "./endpoints";

export const getInstallerApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.installerFetch,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const getInstallerByIdApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.installerFetchByid,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const addInstallerApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.installerBase,
    method: "POST",
    headers: getHeaders(),

    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const updatPasswordApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.installerPassword,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const updateInstallerField = async (data) => {
  const callResponse = await axios({
    url: endpoints.installerUpdate,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const deleteInstallerApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.installerDelete,
    method: "DELETE",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const undoInstallerDeleteApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.installerDeleteUndo,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const updateInstallerFieldApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.installerupdateField,
    method: "put",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

// export const updateCompanyInstaller = async (data) => {
//   const callResponse = await axios({
//     url: endpoints.isCompanyInstaller,
//     method: "PATCH",
//     headers: getHeaders(),
//     data,
//   })
//     .then((response) => response.data)
//     .catch((err) => err.response.data);

//   return callResponse;
// };

export default getInstallerApi;
