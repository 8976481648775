import {
  Alert,
  AlertTitle,
  Button,
  ButtonGroup,
  CircularProgress,
  Paper,
  Typography,
  styled,
} from "@mui/material";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { MODAL_KEYS } from "../../../utils/constants/modal.constant";
import { WbTwilight } from "@mui/icons-material";
import { openModal } from "../../../store/actions/modalAction";
import { CenteredBox } from "../../../components/layouts/OneViewBox";
import MappedUserListController from "./MappedUserListController";
import SignInAircallController from "./SignInAircallController";

const FilterTitleBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const OpenAircallSignInButton = ({ callBack, title, ...props }) => {
  const dispatch = useDispatch();
  const onClick = () => {
    dispatch(
      openModal(
        <SignInAircallController callBack={callBack} />,
        "sm",
        undefined,
        MODAL_KEYS.AIRCALL_INTEGRATION_SIGNIN
      )
    );
  };

  return (
    <Button
      variant='contained'
      disableElevation
      onClick={onClick}
      startIcon={<WbTwilight />}
      {...props}
    >
      {title ?? "Integrate AirCall"}
    </Button>
  );
};
const MainUi = ({
  title,
  filters,
  setFilters,
  list,
  modal,
  loading,
  integrationDetails,
  callBack,
}) => {
  const user = useSelector((state) => state.user);

  return (
    <>
      <Box mb={3}>
        <Paper elevation={0} sx={{ width: "100%", padding: 4 }}>
          <Box mb={4}>
            {loading && (
              <CenteredBox>
                <CircularProgress />
              </CenteredBox>
            )}
            {!loading && integrationDetails && (
              <>
                <Paper variant='outlined' component={Box} p={2}>
                  <Alert severity='success'>
                    <AlertTitle>Integration Completed</AlertTitle>
                    Your Company is integrated with Aircall
                  </Alert>

                  <Typography variant='caption'>
                    if above integration is not valid then{" "}
                    <OpenAircallSignInButton
                      title='Reintegrate'
                      callBack={callBack}
                      size='small'
                      variant='text'
                    />
                  </Typography>
                </Paper>

                <MappedUserListController />
              </>
            )}
            {!loading && !integrationDetails && (
              <CenteredBox>
                <OpenAircallSignInButton callBack={callBack} />
              </CenteredBox>
            )}
          </Box>
        </Paper>
      </Box>
    </>
  );
};
export default MainUi;
