import { memo, useMemo, useState } from "react"
import useValidate from "../../../../store/hooks/useValidator"

import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../../../store/actions/commonAction"
import { updateLeadStatusApi } from "../../../../apis/lead.api"
import { callSnackBar } from "../../../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../../../utils/constants/constants"
import { LEAD_STATUS } from "../../../../utils/constants/lead.constant"
import StatusUpdateUI from "./StatusUpdateUi"
import { moduleAccessCondition } from "../../../../utils/helpers/helper"
import MODULES from "../../../../utils/constants/module.constants"
import { PROJECT_STATUS } from "../../../../utils/constants/project.constant"

const UpdateLeadStatusController = ({ ButtonComponent, onStatusChangeCallBack = () => { }, id, status, projectStatus, salesPerson }) => {
    const validate = useValidate()
    const dispatch = useDispatch()


    const { user } = useSelector(state => state)
    const updateApi = updateLeadStatusApi

    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',
        id,
        status,
        remarks: "",
        rejection_reason: ""
    })
    const [anchorEl, setAnchorEl] = useState(null);

    const isLeadOwner = moduleAccessCondition(user, [MODULES.LEAD_ADMIN]) || user.data?._id == salesPerson

    const allowedToEdit = isLeadOwner && moduleAccessCondition(user, [MODULES.UPDATE_LEADS]) && projectStatus == PROJECT_STATUS.NEW


    const handleClick = (event) => {

        if (allowedToEdit)
            setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    const validationSchemaForStatusChange = useMemo(() => ([
        {

            value: fields.remarks,
            field: 'Remarks',
            custom: () => {
                if (status == LEAD_STATUS.WON && fields.status != LEAD_STATUS.WON && ((!fields.remarks || fields.remarks?.trim?.() == '') && (!fields.rejection_reason || fields.rejection_reason?.trim?.() == ''))) {
                    return "Please Select Remarks"
                }
                return true
            }
        },
        {

            value: fields.rejection_reason,
            field: 'Rejection Reason',
            custom: () => {
                if (fields.status == LEAD_STATUS.REJECTED && (!fields.rejection_reason || fields.rejection_reason?.trim?.() == '')) {
                    return "Please Select Reason For Lead Reject"
                }
                return true
            }
        },
    ]), [fields, status])




    const validateFun = () => {
        return validate(validationSchemaForStatusChange)
    }
    const updateFun = async () => {
        const validationResponse = validate(validationSchemaForStatusChange)

        if (validationResponse === true) {

            const passData = { ...fields }

            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await updateApi(passData),
                    async (response) => {
                        handleClose()
                        await onStatusChangeCallBack(response)
                        setLoading(false)
                        dispatch(callSnackBar("status updated successfully", SNACK_BAR_VARIETNS.suceess))

                    },
                    (err) => {
                        setLoading(false)
                        dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            dispatch(callSnackBar(validationResponse, SNACK_BAR_VARIETNS.error))
        }
    }




    const onSubmit = async (e) => {
        e.preventDefault()
        updateFun()


    }




    return <StatusUpdateUI
        currentStatus={status}
        handleClick={handleClick}
        handleClose={handleClose}
        anchorEl={anchorEl}
        loading={loading}
        ButtonComponent={ButtonComponent}
        fields={fields}
        setFields={setFields}
        onSubmit={onSubmit}
        validateFun={validateFun}

    />
}
export default memo(UpdateLeadStatusController)