import { memo, useMemo, useState } from "react"
import useValidate from "../../../../store/hooks/useValidator"

import { useDispatch } from 'react-redux'
import { callApiAction } from "../../../../store/actions/commonAction"
import { closeModal } from "../../../../store/actions/modalAction"
import { updateLeadSiteDetailsApi } from "../../../../apis/lead.api"
import LeadUpdateContactDetailsUi from "./LeadUpdateSiteDetailsUi"
import { callSnackBar } from "../../../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../../../utils/constants/constants"
import { MODAL_KEYS } from "../../../../utils/constants/modal.constant"
import { validateMeterNo, validateNMI } from "../../../../utils/helpers/lead.helper"

const LeadUpdateSiteDetailsController = ({ callBack = () => { }, id, projectData = {} }) => {
    const validate = useValidate()
    const dispatch = useDispatch()

    const title = "Update Site Details"
    const modalKey = MODAL_KEYS.LEAD_UPDATE_MODAL

    const updateApi = updateLeadSiteDetailsApi

    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',
        id,

        ...projectData,
      

    })



    const updateValidationSchema = useMemo(() => ([

        {
            value: fields.nmi_no,
            field: "NMI No",
            custom: () => {
                if (fields.nmi_no && fields.nmi_no != '')
                    return validateNMI(fields.nmi_no)

                return true
            }
        },
        {
            value: fields.meter_identifier,
            field: "Meter No",
            custom: () => {
                if (fields.meter_identifier && fields.meter_identifier != '')
                    return validateMeterNo(fields.meter_identifier)

                return true
            }
        }
    ]), [fields])





    const updateFun = async () => {
        const validationResponse = validate(updateValidationSchema)

        if (validationResponse === true) {

            const dataToBeSend = {

            }
            const dataCopy = JSON.parse(JSON.stringify(fields))

            for (let item in dataCopy) {
                if (typeof dataCopy[item] === "object" && !Array.isArray(dataCopy[item])) {
                    dataToBeSend[item] = dataCopy[item]?._id
                } else
                    dataToBeSend[item] = dataCopy[item]
            }

            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await updateApi(dataToBeSend),
                    async (response) => {

                        await callBack(response)
                        setLoading(false)
                        dispatch(callSnackBar("data updated successfully", SNACK_BAR_VARIETNS.suceess))
                        dispatch(closeModal(modalKey))
                    },
                    (err, code, responseData) => {

                        setLoading(false)
                        setFields({ ...fields, err })
                        dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }




    const onSubmit = async (e) => {
        e.preventDefault()
        updateFun()


    }




    return <LeadUpdateContactDetailsUi modalKey={modalKey} title={title} isUpdate={id} loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
}
export default memo(LeadUpdateSiteDetailsController)