import axios from "axios";
import { getHeaders } from "../utils/helpers/helper";
import endpoints from "./endpoints";




export const getCompanyApi = async params => {
  const callResponse = await axios({
    url: endpoints.companyBase,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};



export const getCompanyByIdApi = async params => {
  const callResponse = await axios({
    url: endpoints.companyById,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


export const addCompanyApi = async data => {
  const callResponse = await axios({
    url: endpoints.companyBase,
    method: "POST",
    headers: getHeaders(),
    
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const updateCompanyDeleteStatus = async data => {
  const callResponse = await axios({
    url: endpoints.companyBase,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
export const updateCompanyData = async data => {
  const callResponse = await axios({
    url: endpoints.companyBase,
    method: "PUT",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};



