import { memo, useEffect, useMemo, useState } from "react"
import useValidate from "../../store/hooks/useValidator"
import CreateUi from "./CreateUi"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { closeModal, openModal } from "../../store/actions/modalAction"
import { useParams } from "react-router-dom"

import { addProjectsApi } from "../../apis/projects.api"
import { Button, CircularProgress, IconButton, Tooltip } from "@mui/material"
import { Refresh } from "@mui/icons-material"
import { callSnackBar } from "../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../utils/constants/constants"
import SubmitButton from "../../components/button/SubmitButton"
import MessageDilog from "../../components/MessageDilog"

const CreateController = ({ callBack, id, create }) => {
    const validate = useValidate()
    const dispatch = useDispatch()
    const params = useParams()

    const title = "Projects from Open Solar "
    const createApi = addProjectsApi

    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',
        id: id ?? null,
        from_lead: create
    })



    const validationSchemaForCreate = useMemo(() => ([

        {
            required: true,
            value: fields.id,
            field: 'Open solar id ',
        }
    ]), [fields])





    const createFunction = async (e) => {
        e?.preventDefault()
        const validationResponse = validate(validationSchemaForCreate)
        if (create) {
            dispatch(closeModal())
        }
        if (validationResponse === true) {


            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await createApi(fields),
                    async (response) => {

                        await callBack(response)
                        setLoading(false)

                        if (create) {
                            dispatch(callSnackBar("Project created sucessfully.", SNACK_BAR_VARIETNS.suceess))
                        } else {
                            dispatch(closeModal())
                            dispatch(callSnackBar("Project refreshed sucessfully.", SNACK_BAR_VARIETNS.suceess))
                        }

                    },
                    (err) => {
                        setLoading(false)
                        dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            dispatch(callSnackBar(validationResponse, SNACK_BAR_VARIETNS.error))
            setFields({ ...fields, 'err': validationResponse })
        }
    }

    const onCreateBtnClick = () => {
        dispatch(openModal(<MessageDilog title="Alert!" message="Are you Sure To Create Project?" onSubmit={createFunction} />, "sm"))
    }

    const onSubmit = async (e) => {
        e.preventDefault()


        createFunction()

    }

    if (create) {
        if (loading)
            return <CircularProgress />
        return (
            <>

                <Tooltip arrow  title="Create Project">
                    {<SubmitButton disabled={loading} loading={loading} size="small" title={"Create"} onClick={onCreateBtnClick}></SubmitButton>}
                </Tooltip>
            </>

        )
    }

    if (id) {
        if (loading)
            return <CircularProgress />
        return (
            <Tooltip arrow  title="Refresh Project">

                {loading ? <CircularProgress size="inherit" /> : <IconButton onClick={createFunction}><Refresh color="primary" /></IconButton>}
            </Tooltip>
        )


    }

    return <CreateUi title={title} isUpdate={id} loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
}
export default memo(CreateController)