import { Button, ButtonGroup, CircularProgress, Collapse, IconButton, Paper, Tooltip, Typography, styled } from "@mui/material";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";

import SubmitButton from "../../components/button/SubmitButton";
import { memo, useCallback, useState } from "react";
import { callApiAction } from "../../store/actions/commonAction";
import { updateActivityActiveStatusApi, updateActivityPriorityApi } from "../../apis/activity.api";
import { AddCircle, Delete, DragIndicator, Edit, FlashOn } from "@mui/icons-material";
import CreateController from "./CreateController";
import update from 'immutability-helper'
import { MODAL_KEYS } from "../../utils/constants/modal.constant";
import { closeModal, openModal } from "../../store/actions/modalAction";
import { CenteredBox } from "../../components/layouts/OneViewBox";
import { DndProvider, useDrag, useDragLayer, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { PreviewIcon } from "../../components/inputs/IconSelectionDropDown";
import MessageDilog from "../../components/MessageDilog";

const FilterTitleBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const ActionComponent = memo(({ noCreate, params, callBack }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(0);

  const onDelete = (e) => {
    e?.preventDefault?.();
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await updateActivityActiveStatusApi({ id: params.id, active: !params?.active }),
        (response) => {
          setLoading(false);
          callBack()
        },
        (err) => {
          setLoading(false);
        }
      )
    );
    dispatch(closeModal());
  };

  const onDeleteBtnClick = () => {
    dispatch(
      openModal(
        <MessageDilog onSubmit={onDelete} title="Alert!" message={`Are you sure to ${params?.active ? "delete" : "activate"}?`} />,
        "xs",
        undefined
      )
    );
  };

  const onCreateBtnClick = () => {
    dispatch(
      openModal(
        <CreateController title="Outcome" parent={params?.id} icon={params.icon} callBack={(res, editableData) => { callBack() }} />,
        "xs",
        undefined,
        MODAL_KEYS.ACTIVITY_CREATE
      )
    );
  };

  const onEditBtnClick = () => {
    dispatch(openModal(<CreateController id={params?.id} callBack={(res, editableData) => { callBack() }} />, "xs", undefined, MODAL_KEYS.ACTIVITY_CREATE));
  };

  if (loading) return <CircularProgress />;
  return (
    <Box sx={{ width: "100%", display: "flex" }}>
      {!noCreate && params.active && (
        <Tooltip arrow  title="Add Outcome" >
          <IconButton disableElevation variant="contained" size="small" onClick={onCreateBtnClick}>
            <AddCircle color="info" fontSize="inherit" />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip arrow  title={params.active ? "Remove Activity" : "Activate Activity"}  >
        <IconButton disableElevation variant="contained" size="small" onClick={onDeleteBtnClick}>
          {params.active ? <Delete fontSize="inherit" color="error" /> : <FlashOn fontSize="inherit" color="success" />}
        </IconButton>
      </Tooltip>
     {  params.active&& <IconButton disableElevation variant="contained" size="small" onClick={onEditBtnClick}>
        <Edit fontSize="inherit" color="info" />
      </IconButton>}
    </Box>
  );
});

const ActivityItem = memo(({ title, icon, id, active, color, outcomes, editable, callBack }) => {
  return (
    <Paper component={Box} p={2} elevation={0} sx={{ width: "100%", borderBottom: 1, borderColor: "divider" }}>
      <Box display="flex" alignItems="center">
        <Box display="flex" flex={1} alignItems="center">
          <Typography lineHeight="100%" sx={{ color: color }} variant="body1">
            <PreviewIcon value={icon} color={color} />
          </Typography>
          <Typography ml={2} variant="body1">
            {title}
          </Typography>
        </Box>
        {editable && (
          <Box>
            <ActionComponent noCreate params={{ title, icon, id, active, color, outcomes, editable, active }} callBack={callBack} />
          </Box>
        )}
      </Box>
    </Paper>
  );
});

const ParentItemType = "Parent-list";

const ActivityParentItem = (({ title, index, icon, id, active, color, outcomes, editable, moveItem, callBack }) => {
  const dispatch = useDispatch();
  const [{ isDragging }, ref, preview] = useDrag({
    type: ParentItemType,
    item: { title, index, icon, id, active, color, outcomes, editable },
    end: (item, monitor) => {

      const dropResult = monitor.getDropResult();
      if (item.index !== index) {
        moveItem(index, item.index);
        dispatch(
          callApiAction(
            async () => await updateActivityPriorityApi({ id: item.id, priority: item.index }),
            (response) => { },
            (err) => { }
          )
        );
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ParentItemType,
    drop: (item) => ({ ...item }),
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {


        moveItem(draggedItem.index, index);
        draggedItem.index = index;

      }
    },
  });

  return (
    <>
      <Paper mb={2} component={Box} p={2} variant="outlined" sx={{ width: "100%", userSelect: "none", opacity: isDragging ? 0.4 : 1, position: "relative" }}>
        <Box display="flex" alignItems="center" ref={drop}>
          <Box display="flex" flex={1} alignItems="center">
            <Box sx={{ cursor: "grab" }} p={2} ref={(node) => ref((node))}>
              <DragIndicator />
            </Box>
            <Typography lineHeight="100%" sx={{ color: color }} variant="body1">
              <PreviewIcon value={icon} color={color} />
            </Typography>
            <Typography ml={2} variant="body1">
              {title}
            </Typography>
          </Box>
          {editable && (
            <Box>
              <ActionComponent params={{ title, icon, id, active, color, outcomes, editable, active }} callBack={callBack} />
            </Box>
          )}
        </Box>
        <Collapse in={outcomes && outcomes?.length > 0}>
          <Box sx={{}} p={3}>
            <Box>
              <Typography variant="body2">Outcomes:</Typography>
            </Box>
            <Paper variant="outlined">
              {outcomes.map((item, index) => {
                return (
                  <ActivityItem
                    key={item?._id}
                    index={index}
                    title={item.title}
                    outcomes={item.outcomes}
                    icon={item.icon}
                    color={item.color}
                    id={item._id}
                    active={item.active}
                    editable={item.editable}
                    callBack={callBack}
                  />
                );
              })}
            </Paper>
          </Box>
        </Collapse>
      </Paper>
      {/* <Box ref={preview}></Box> */}
    </>
  );
});

const CustomDragLayer = () => {
  const { item, isDragging, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    isDragging: monitor.isDragging(),
    currentOffset: monitor.getSourceClientOffset(),
  }));

  if (!isDragging || !currentOffset) {
    return null;
  }

  const { x, y } = currentOffset;
  return (
    <div style={{ pointerEvents: "none", position: "fixed", top: 0, left: 0, width: "100%", height: "100%", zIndex: 11111 }}>
      <Box sx={{}}>
        <Box sx={{ transform: `translate(${x}px, ${y}px)`, width: "500px" }}>
          <ActivityParentItem {...item} />
        </Box>
      </Box>
    </div>
  );
};

const ListUi = ({ title, modal, filters, setFilters, list, setList, onCreateBtnClick, loading, callBack }) => {
  const moveItem = useCallback(
    (dragIndex, hoverIndex) => {


      const dragRecord = list[dragIndex]
      setList(
        update(list, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRecord],
          ],
        })
      )
    },
    [list]
  );

  return (
    <>
      <Box mb={3}>
        <Paper elevation={modal ? 0 : 2} sx={{ width: "100%", padding: 4 }}>
          <FilterTitleBox display="flex" mb={3}>
            <Typography variant="h5" mb={2}>
              {title}
            </Typography>
            <Box>
              <SubmitButton onClick={onCreateBtnClick} title="Add Activity"></SubmitButton>
            </Box>
          </FilterTitleBox>
          <FilterTitleBox display="flex" mb={3}>
            <ButtonGroup variant="outlined" >
              <Button onClick={() => setFilters({ ...filters, active: null })} variant={filters.active == null ? "contained" : "outlined"} >All</Button>
              <Button onClick={() => setFilters({ ...filters, active: true })} variant={filters.active == true ? "contained" : "outlined"} >Active</Button>
              <Button onClick={() => setFilters({ ...filters, active: false })} variant={filters.active == false ? "contained" : "outlined"}>Deleted</Button>
            </ButtonGroup>
          </FilterTitleBox>
          <DndProvider backend={HTML5Backend}>
            <Box sx={{ minHeight: "300px" }} p={2}>
              {loading && (
                <CenteredBox>
                  <CircularProgress />
                </CenteredBox>
              )}
              {!loading &&
                list &&
                list?.length > 0 &&
                list.map((item, index) => {
                  return (
                    <ActivityParentItem
                      callBack={callBack}
                      moveItem={moveItem}
                      index={index}
                      key={item?._id}
                      title={item.title}
                      outcomes={item.outcomes}
                      icon={item.icon}
                      color={item.color}
                      id={item._id}
                      active={item.active}
                      editable={item.editable}
                    />
                  );
                })}
              <CustomDragLayer />
            </Box>
          </DndProvider>
        </Paper>
      </Box>
    </>
  );
};

export default ListUi;
