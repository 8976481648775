import { Box, Button, ButtonBase, CircularProgress, Divider, FormControl, FormControlLabel, FormLabel, MenuItem, Radio, RadioGroup, TextField, Typography } from "@mui/material"

import { useDispatch, useSelector } from 'react-redux'
import CustomInput from "../../components/inputs/CustomInput"
import CustomDialog from "../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../components/layouts/OneViewBox"

import getSourceApi from "../../apis/sources.api"
import AsyncDropDown from "../../components/inputs/AsyncDropDown"
import { DesktopDatePicker } from "@mui/x-date-pickers"
import moment from "moment"
import { closeModal, openModal } from "../../store/actions/modalAction"

import { MODAL_KEYS } from "../../utils/constants/modal.constant"
import ListController from "../predefined-expense/ListController"
import { memo } from "react"
import CreatableAsyncDropDown from "../../components/inputs/CreatableAsyncDropDown"
import { getSuggestionForExpenseTitleApi } from "../../apis/finance.api"
import IconSelectionComponent from "../../components/inputs/IconSelectionDropDown"




const CreateUi = ({ title, isUpdate, modalKey, fields, setFields, loading, onSubmit, callBack }) => {

    const dispatch = useDispatch()
    const onPreDefinedExpenseModalClick = () => {
        dispatch(closeModal(modalKey))
        dispatch(openModal(<ListController callBack={callBack} />, 'sm', undefined, MODAL_KEYS.PRE_DEFINED_EXPENSES_LIST))
    }
    return <>

        <CustomDialog
            id={modalKey}
            loading={loading}
            err={fields.err}
            onSubmit={onSubmit}
            title={`${isUpdate ? "Update" : "Add"} ${title}`}
            closeText="Close"
            confirmText={`${isUpdate ? "Update" : "Add"}`}
        >

            {loading ? <CenteredBox><CircularProgress /></CenteredBox> :
                <>

                    {!fields.parent && <>
                        <Typography variant="subtitle2" >Select Icon:</Typography>
                        <IconSelectionComponent value={fields.icon} onChange={(val) => {
                            setFields({ ...fields, err: '', icon: val })
                        }} />
                        <Box mb={1} />
                    </>}
                    <CustomInput

                        disabled={loading}
                        value={fields.title}
                        onChange={(e) => setFields({ ...fields, err: '', title: e.target.value })}
                        type="Text"
                        label={"Title*"}

                    />

                    <TextField
                        margin="dense"

                        disabled={loading}
                        value={fields.color}
                        onChange={(e) => setFields({ ...fields, err: '', color: e.target.value })}
                        fullWidth
                        label={"Color*"}
                        type="color"
                    />

                </>}

        </CustomDialog >
    </>
}
export default memo(CreateUi)