import axios from "axios";
import { getHeaders } from "../utils/helpers/helper";
import endpoints from "./endpoints";




export const getEnergyPartnerApi = async params => {
  const callResponse = await axios({
    url: endpoints.energyPartnerBase,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


export const getEnergyPartnerByIdApi = async params => {
  const callResponse = await axios({
    url: endpoints.energyPartnerById,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


export const addEnergyPartnerApi = async data => {
  const callResponse = await axios({
    url: endpoints.energyPartnerBase,
    method: "POST",
    headers: getHeaders(),
    
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const updateEnergyPartnerField = async data => {
  const callResponse = await axios({
    url: endpoints.energyPartnerBase,
    method: "PUT",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


export const deleteEnergyPartnerApi = async data => {
  const callResponse = await axios({
    url: endpoints.energyPartnerBase,
    method: "DELETE",
    headers: getHeaders(),
    
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const addEnergyPartnerPostCodeRangeApi = async data => {
  const callResponse = await axios({
    url: endpoints.energyPartnerPostCode,
    method: "POST",
    headers: getHeaders(),
    
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
export const deleteEnergyPartnerPostCodeRangeApi = async data => {
  const callResponse = await axios({
    url: endpoints.energyPartnerPostCode,
    method: "DELETE",
    headers: getHeaders(),
    
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const getPartnerFromPinCodesApi = async params => {
  const callResponse = await axios({
    url: endpoints.energyPartnerSearchByPostCode,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
