import { Paper, Typography, styled } from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import SubmitButton from "../../components/button/SubmitButton";
import DataTable from "../../components/tables/DataTable";
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxInDesktop";
import AsynSearchBar from "../../components/inputs/AsynSearchBar";
import CustomDialog from "../../components/layouts/common/CustomDialog";

const FilterTitleBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));
const FiltersBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",

  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const ListUi = ({
  title,
  filters,
  setFilters,
  modal,
  list,
  loading,
  onCreateBtnClick,
  modalKey,
  columns,
}) => {
  const user = useSelector((state) => state.user);

  // if (modal)
  //   return (
  //     <CustomDialog id={modalKey} title={title}>
  //       <Box mb={4}>
  //         <FilterTitleBox>
  //           <Box></Box>

  //           <Box mb={2}>
  //             <SubmitButton
  //               variant="contained"
  //               onClick={onCreateBtnClick}
  //               title={"Add " + title}
  //             />
  //           </Box>
  //         </FilterTitleBox>

  //         <FiltersBox mt={3}>
  //           <PaddingBoxInDesktop
  //             mb={2}
  //             sx={{ display: "flex", justifyContent: "flex-end" }}
  //             pl={3}
  //           >
  //             <AsynSearchBar
  //               fullWidth
  //               title="Search By Title "
  //               size="small"
  //               placeholder={"Search By  Name "}
  //               defaultValue={filters.search}
  //               onChange={(changedVal) => {
  //                 setFilters({ ...filters, search: changedVal });
  //               }}
  //             />
  //           </PaddingBoxInDesktop>
  //         </FiltersBox>
  //       </Box>
  //       <Box sx={{ minHeight: "300px" }}>
  //         <DataTable
  //           columns={columns}
  //           rows={list.result ? list.result : []}
  //           count={list.total ?? 0}
  //           filters={filters}
  //           setFilters={setFilters}
  //           loading={loading}
  //         />
  //       </Box>
  //     </CustomDialog>
  //   );
  return (
    <>
      <Box mb={3}>
        <Paper elevation={2} sx={{ width: "100%", padding: 4 }}>
          <Box mb={4}>
            <FilterTitleBox>
              <Typography variant="h5" mb={2}>
                {title}
              </Typography>

              <Box mb={2}>
                <SubmitButton
                  variant="contained"
                  onClick={onCreateBtnClick}
                  title={"Add " + title}
                />
              </Box>
            </FilterTitleBox>

            <FiltersBox mt={3}>
              <PaddingBoxInDesktop
                mb={2}
                sx={{ display: "flex", justifyContent: "flex-end" }}
                pl={3}
              >
                <AsynSearchBar
                  fullWidth
                  title="Search By Title "
                  size="small"
                  placeholder={"Search By  Name "}
                  defaultValue={filters.search}
                  onChange={(changedVal) => {
                    setFilters({ ...filters, search: changedVal });
                  }}
                />
              </PaddingBoxInDesktop>
            </FiltersBox>
          </Box>
          <Box sx={{ minHeight: "300px" }}>
            <DataTable
              columns={columns}
              rows={list.result ? list.result : []}
              count={list.total ?? 0}
              filters={filters}
              setFilters={setFilters}
              loading={loading}
            />
          </Box>
        </Paper>
      </Box>
    </>
  );
};
export default ListUi;
