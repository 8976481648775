import { memo, useEffect, useMemo, useState } from "react"
import useValidate from "../../../store/hooks/useValidator"
import CreateUi from "./SignInOpenSolarUI"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../../store/actions/commonAction"
import { closeModal } from "../../../store/actions/modalAction"
import { useParams } from "react-router-dom"

import { addSourceApi, getSourceByIdApi, updateSourceField } from "../../../apis/sources.api"
import { MODAL_KEYS } from "../../../utils/constants/modal.constant"
import { signInOpenSolarUser } from "../../../apis/opensolarIntegration.api"

const SignInOpenSolarController = ({parent,callBack }) => {
    
    const validate = useValidate()
    const dispatch = useDispatch()
    const params = useParams()

    const modalKey=MODAL_KEYS.OPEN_SOLAR_INTEGRATION_SIGNIN 
    const title = "Integrate Open Solar"
    const signInApi = signInOpenSolarUser
   

    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',
        
        email: '',
        password:"",          

    })
  
    const validationSchemaForCreate = useMemo(() => ([

        {
            required: true,
            value: fields.email,
            isEmail:true,
            field: 'Name',
        },
        {
            required: true,
            value: fields.password,
            field: 'Password',
        }
    ]), [fields])

   



    const createFunction = async () => {
        const validationResponse = validate(validationSchemaForCreate)

        if (validationResponse === true) {


            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await signInApi(fields),
                    async (response) => {

                        await callBack(response)
                        setLoading(false)
                        dispatch(closeModal(modalKey))
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }



    const onSubmit = async (e) => {
        e.preventDefault()
      
            createFunction()

    }

    


    return <CreateUi title={title} modalKey={modalKey} loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
}
export default memo(SignInOpenSolarController)