import { memo, useCallback, useEffect, useState } from "react";
import ListUi from "./ListUi";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";

import { closeModal, openModal } from "../../store/actions/modalAction";
import {
  Box,
  CircularProgress,
  IconButton,
  Switch,
  Typography,
} from "@mui/material";
import { useMemo } from "react";
import CreateController from "./CreateController";
import { Delete, Edit, Undo } from "@mui/icons-material";

import getSourceApi, { updateSourceField } from "../../apis/sources.api";
import getTeamApi from "../../apis/team.api";
import { moduleAccessCondition } from "../../utils/helpers/helper";
import MODULES from "../../utils/constants/module.constants";
import getSupplierApi, {
  deleteSupplierApi,
  undoSupplierDeleteApi,
} from "../../apis/supplier.api";
import MessageDilog from "../../components/MessageDilog";
import UpdateCompanySupplierButton from "./Components/UpdateInCompanySupplierButton";

const ActionComponent = memo(({ params, setParams, deleteApi }) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state);
  const [loading, setLoading] = useState(false);

  const onEdit = () => {
    dispatch(
      openModal(
        <CreateController
          id={params._id}
          callBack={(response, updatedData) => {
            setParams({ ...params, ...updatedData });
          }}
        />,
        "sm"
      )
    );
  };

  const deleteFun = async () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await deleteApi({ id: params._id }),
        (response) => {
          setParams({});
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
    dispatch(closeModal());
  };

  const undoDeleteFun = async () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await undoSupplierDeleteApi({ id: params._id }),
        (response) => {
          setParams({ ...params, deleted: false });
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
    dispatch(closeModal());
  };

  const onDelete = () => {
    dispatch(
      openModal(
        <MessageDilog
          onSubmit={deleteFun}
          title="Alert!"
          message={`Are you sure to delete "${params.name || params.title}" ?`}
        />,
        "sm"
      )
    );
  };

  const onUndo = () => {
    dispatch(
      openModal(
        <MessageDilog
          onSubmit={undoDeleteFun}
          title="Alert!"
          message={`Are you sure to undo delete for "${params.name || params.title
            }" ?`}
        />,
        "sm"
      )
    );
  };

  if(params.company_id!=user.data?.company_id?._id)
    return <></>
  if (params.deleted)
    return (
      <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
        {" "}
        {moduleAccessCondition(user, [MODULES.DELETE_SUPPLIER], []) && <IconButton onClick={onUndo}>
          <Undo color="error" />
        </IconButton>}
      </Box>
    );
  return (
    <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
      {moduleAccessCondition(user, [MODULES.UPDATE_SUPPLIER], []) && (
        <IconButton size="inherit" onClick={onEdit}>
          <Edit color="info" fontSize="inherit" />
        </IconButton>
      )}
      {moduleAccessCondition(user, [MODULES.DELETE_SUPPLIER], []) && <>
        {loading && <CircularProgress color="primary" fontSize="inherit" />}
        {!loading && (
          <IconButton disabled={loading} size="inherit" onClick={onDelete}>
            <Delete color="error" fontSize="inherit" />
          </IconButton>
        )}
      </>}
    </Box>
  );
});

const ListController = () => {
  const dispatch = useDispatch();

  const title = "Suppliers ";
  const fetchApi = getSupplierApi;
  const deleteApi = deleteSupplierApi;

  const columns = useMemo(
    () => [
      { id: 1, fieldName: "name", label: "Name", align: "left", sort: true },
      { id: 2, fieldName: "email", label: "Email", align: "left", sort: true },
      { id: 3, fieldName: "phone", label: "Phone", align: "left", sort: true },
      
      {
        id: 4,
        fieldName: "warehouse_name",
        label: "Warehouse Name",
        align: "left",
        sort: true,
      },
      { id: 5, fieldName: "email", label: "Support Emails", align: "left",renderValue:(params)=>params?.support_email },
      {
        id: 6,
        fieldName: "abn_no",
        label: "ABN No",
        align: "left",

      },


      {
        id: 6,
        fieldName: "",
        label: "Action",

        align: "right",
        renderValue: (params, setParams) => (
          <ActionComponent
            deleteApi={deleteApi}
            params={params}
            setParams={setParams}
          />
        ),
      },
    ],
    [dispatch]
  );

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    searchable: ["name"],
    sort: "name",
    sortDirection: 1,
    isCompanySupplier: false
  });

  // do not change

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState({});

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchApi({ ...filters }),
        (response) => {
          setList(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };
  const onCreateBtnClick = () => {
    dispatch(
      openModal(
        <CreateController
          isCompanySupplier={filters.isCompanySupplier}
          callBack={async () => {
            fetchList();
          }}
        />,
        "sm"
      )
    );
  };

  useEffect(() => {
    fetchList();
  }, [filters]);

  return (
    <>
      <ListUi
        title={title}
        onCreateBtnClick={onCreateBtnClick}
        filters={filters}
        setFilters={setFilters}
        loading={loading}
        list={list}
        columns={columns}
      />
    </>
  );
};
export default ListController;
