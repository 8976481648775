import { actions } from "../../utils/constants/constants";

export const setPhoneInstance = (phone) => {
    return (dispatch, getState) => {
        dispatch({
            type: actions.SET_PHONE_INSTANCE,
            payload: phone,
        });
    };
};

export const togglePhoneVisibility = () => {
    return (dispatch, getState) => {
        dispatch({
            type: actions.TOGGLE_PHONE_VISIBILITY,
        });
    };
};

// Thunk action to dial a call
export const dialCallAction = (phoneNumber) => {
    return (dispatch, getState) => {
        const { phone, isVisible } = getState().aircall; // Get the phone instance from the Redux state

        if (phone) {
            let payload = { phone_number: phoneNumber };
            phone.send("dial_number", payload, (success, data) => {
                if (success) {
                    if (!isVisible) {
                        dispatch(togglePhoneVisibility());
                    }
                } else {
                    console.error('Failed to dial call:', data);
                }
            });
        } else {
            console.error('Phone instance is not available');
        }
    };
};