import { memo, useCallback, useEffect, useState } from "react"
import ListUi from "./ListUi"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"

import { closeModal, openModal } from "../../store/actions/modalAction"
import { Box, CircularProgress, IconButton, Switch, Typography } from "@mui/material"
import { useMemo } from "react"
import CreateController from "./CreateController"
import { Delete, Edit, More, MoreHoriz, Undo } from "@mui/icons-material"
import { MODAL_KEYS } from "../../utils/constants/modal.constant"

import { moduleAccessCondition } from "../../utils/helpers/helper"
import MODULES from "../../utils/constants/module.constants"
import MessageDilog from "../../components/MessageDilog"
import { deleteProductApi, deleteProductCategoryApi, getProductApi, getProductCategoryApi } from "../../apis/product.api"
import { callSnackBar } from "../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../utils/constants/constants"
import ImageComponent from "../../components/ImageComponent"
import fileDownload from "js-file-download"

const ActionComponent = memo(({ params, setParams, deleteApi, createModalKey }) => {
    const dispatch = useDispatch()
    const deleted = params.deleted
    const [loading, setLoading] = useState(false)

    const { user } = useSelector(state => state)

    const onEdit = () => {
        dispatch(openModal(<CreateController id={params._id} callBack={(response, updatedData) => {
            setParams({ ...params, ...updatedData })
        }} />, "sm", undefined, createModalKey))
    }


    const deleteFun = async () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await deleteApi({ id: params._id, deleted: !deleted }),
            (response) => {
                setParams({})
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
        dispatch(closeModal())
    }


    const onDelete = () => {
        dispatch(openModal(<MessageDilog onSubmit={deleteFun} title="Alert!" message={`Are you sure to delete "${params.name || params.title}" ?`} />, "sm"))
    }

    const onUndo = () => {
        dispatch(openModal(<MessageDilog onSubmit={deleteFun} title="Alert!" message={`Are you sure to undo delete for "${params.name || params.title}" ?`} />, "sm"))
    }
    if (deleted && moduleAccessCondition(user, [MODULES.DELETE_PRODUCT], []))
        return <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}> <IconButton onClick={onUndo}>
            <Undo color="error" />
        </IconButton>
        </Box>
    return <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
        {moduleAccessCondition(user, [MODULES.UPDATE_PRODUCT], []) && !params.identifier && <IconButton size="inherit" onClick={onEdit}>
            <Edit color="info" fontSize="inherit" />
        </IconButton>
        }


        {moduleAccessCondition(user, [MODULES.DELETE_PRODUCT], []) && !params.identifier && <>

            {loading && <CircularProgress color="primary" fontSize="inherit" />}
            {!loading && <IconButton disabled={loading} size="inherit" onClick={onDelete}>
                <Delete color="error" fontSize="inherit" />
            </IconButton>}
        </>}
    </Box>
})


const ListController = ({ }) => {
    const dispatch = useDispatch()

    const createModalKey = MODAL_KEYS.PRODUCT_MODULE_CREATE

    const title = "Product SKUs "
    const fetchCategoryApi = getProductCategoryApi
    const fetchApi = getProductApi
    const deleteApi = deleteProductApi


    const columns = useMemo(() => [
        { id: 3, fieldName: 'logo', label: 'Logo', align: "left", renderValue: (params) => <ImageComponent key={params.logo} isStatic noClickEffect={true} sx={{ width: "100%" }} src={params.logo} alt={params?.title} /> },
        { id: 1, fieldName: 'title', label: 'SKU Title/Manufacturer name', align: "left", sort: true },
        { id: 2, fieldName: 'item_code', label: 'Item Code', align: "left" },


        {
            id: 5,
            fieldName: '',
            label: 'Action',

            align: "right",
            renderValue: (params, setParams) => <ActionComponent createModalKey={createModalKey} deleteApi={deleteApi} params={params} setParams={setParams} />,
        },

    ], [dispatch]);


    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 10,
        search: '',

        searchable: ['title', 'item_code'],
        sort: 'createdAt',
        sortDirection: -1

    })





    // do not change 

    const [loading, setLoading] = useState(false)
    const [exportLoading, setExportLoading] = useState(false)
    const [productCategories, setProductCategories] = useState([])
    const [list, setList] = useState({})









    const fetchProductCategory = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchCategoryApi({ all: true, deleted: false }),
            (response) => {
                setProductCategories(response)
                setFilters({ ...filters, productCategory: response?.[0]?._id })
            },
            (err) => {
                dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))

            }
        ))
    }

    const fetchList = (csv) => {
        if (!csv) {
            setLoading(true)
            dispatch(callApiAction(
                async () => await fetchApi({ ...filters }),
                (response) => {
                    setList(response)
                    setLoading(false)
                },
                (err) => {
                    setLoading(false)
                }
            ))
        } else {
            setExportLoading(true)
            dispatch(callApiAction(
                async () => await fetchApi({ ...filters, csv: true }),
                (response) => {
                    fileDownload(response, "skus.csv")
                    setExportLoading(false)
                },
                (err) => {

                    setExportLoading(false)
                },
                true
            ))
        }
    }
    const fetchCsv = () => {
        fetchList(true)
    }
    const onCreateBtnClick = () => {
        dispatch(openModal(<CreateController modalKey={createModalKey} product_category={filters.productCategory} callBack={async () => { fetchList() }} />, "sm", undefined, createModalKey))
    }


    useEffect(() => {
        fetchProductCategory()
    }, [])

    useEffect(() => {
        if (filters?.productCategory)
            fetchList()
    }, [filters])





    return (
        <>
            <ListUi
                productCategories={productCategories}
                title={title}
                onCreateBtnClick={onCreateBtnClick}
                exportLoading={exportLoading}
                filters={filters}
                setFilters={setFilters}
                fetchCsv={fetchCsv}
                
                loading={loading}
                list={list}
                columns={columns}
                callBack={fetchList}


            />

        </>
    )
}
export default ListController