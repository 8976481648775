import { Box, CircularProgress, MenuItem, Typography } from "@mui/material";

import { Fragment, memo, useState } from "react";

import CustomInput from "../../components/inputs/CustomInput";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import { CenteredBox } from "../../components/layouts/OneViewBox";
import getProjectsApi from "../../apis/projects.api";
import AsyncDropDown from "../../components/inputs/AsyncDropDown";
import { StyledSearchBar } from "../../components/inputs/SearchBar";
import ProjectDetailsController from "../projects/projectdetails/ProjectDetailsController";
import getComplainCategoryApi from "../../apis/complaincategory.api";
import SubmitButton from "../../components/button/SubmitButton";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const CreateUi = ({
  project_id,
  title,
  isUpdate,
  fields,
  setFields,
  loading,
  onSubmit,
  modalKey,
}) => {
  const [editor, setEditor] = useState(null);
  return (
    <>
      <CustomDialog
        id={modalKey}
        loading={loading}
        dialogProps={{ sx: { height: "1000px", overflow: "hidden" } }}
        // onSubmit={onSubmit}
        title={`${isUpdate ? "Update" : "Create"} ${title}`}
        closeText="Close"
        confirmText={`${isUpdate ? "Update" : "Create"}`}
      >
        {!fields.project_id && (
          <AsyncDropDown
            lazyFun={async (params) => {
              return await getProjectsApi({
                ...params,
                searchable: ["uid","contacts.full_name"],
              });
            }}
            id={"projects-create"}
            OptionComponent={({ option, ...rest }) => {
              return (
                <MenuItem {...rest}>{option?.contacts?.[0]?.full_name}({option.uid})</MenuItem>
              );
            }}
            onChange={(changedVal) => {
              
              setFields({
                ...fields,
                project_id: changedVal ? changedVal._id : null,
                uid: changedVal
                  ? changedVal?.uid
                  : null,
              });
            }}
            titleKey={"uid"}
            valueKey={"_id"}
            InputComponent={(params) => (
              <StyledSearchBar
                placeholder="Search And Select Project"
                {...params}
              />
            )}
          />
        )}
        {fields.project_id && (
          <ProjectDetailsController
            viewOnly
            noDialog
            project={fields?.project_id}
          >
            {loading ? (
              <CenteredBox>
                <CircularProgress />
              </CenteredBox>
            ) : (
              <>
                {fields.err && (
                  <Typography color="error">{fields.err}</Typography>
                )}
                {!project_id && (
                  <AsyncDropDown
                    id={"projects-create2"}
                    lazyFun={async (params) => {
                      return await getProjectsApi({
                        ...params,
                        searchable: ["uid"],
                      });
                    }}
                    value={
                      fields?.project_id
                        ? {
                            uid: fields.uid,
                            _id: fields.project_id,
                          }
                        : null
                    }
                    OptionComponent={({ option, ...rest }) => {
                      return (
                        <MenuItem {...rest}>
                          {option.uid}
                        </MenuItem>
                      );
                    }}
                    onChange={async (changedVal) => {
                      setFields({
                        ...fields,
                        project_id: changedVal ? changedVal._id : null,
                        uid: changedVal
                          ? changedVal?.uid
                          : null,
                      });
                    }}
                    titleKey={"uid"}
                    valueKey={"_id"}
                    InputComponent={(params) => (
                      <CustomInput
                        {...params}
                        label="Search And Select Project"
                      />
                    )}
                  />
                )}

                <AsyncDropDown
                  id={"complain-category"}
                  value={fields?.category}
                  lazyFun={async (params) => {
                    return await getComplainCategoryApi({
                      ...params,
                      searchable: ["title"],
                    });
                  }}
                  OptionComponent={({ option, ...rest }) => {
                    return <MenuItem {...rest}>{option.title}</MenuItem>;
                  }}
                  onChange={async (changedVal) => {
                    setFields({
                      ...fields,
                      category: changedVal ? changedVal : null,
                      sub_category: null,
                    });
                  }}
                  titleKey={"title"}
                  valueKey={"_id"}
                  InputComponent={(params) => (
                    <CustomInput {...params} label="Select Complain Category" />
                  )}
                />
                {fields.category && (
                  <AsyncDropDown
                    id={"complain-sub-category"}
                    key={fields?.category?._id}
                    value={fields?.sub_category}
                    lazyFun={async (params) => {
                      return await getComplainCategoryApi({
                        ...params,
                        searchable: ["title"],
                        parent: fields.category?._id,
                      });
                    }}
                    OptionComponent={({ option, ...rest }) => {
                      return <MenuItem {...rest}>{option.title}</MenuItem>;
                    }}
                    onChange={async (changedVal) => {
                      setFields({
                        ...fields,
                        sub_category: changedVal ? changedVal : null,
                      });
                    }}
                    titleKey={"title"}
                    valueKey={"_id"}
                    InputComponent={(params) => (
                      <CustomInput
                        {...params}
                        label="Select Complain Sub Category"
                      />
                    )}
                  />
                )}
                {/* {
                  <CustomInput
                    multiline
                    rows={4}
                    autoFocus={true}
                    disabled={loading}
                    value={fields.remarks}
                    onChange={(e) =>
                      setFields({ ...fields, err: "", remarks: e.target.value })
                    }
                    type="text"
                    label={"Complain Statement*"}
                  />
                } */}
                {
                  <Box
                    mt={2}
                    mb={2}
                    sx={{
                      zIndex: -1, // Optional: ensure it stays above other content
                    }}
                  >
                    <CKEditor
                      config={{
                        placeholder: "Complain Statement...",
                        style: { height: "500px", minHeight: "500px" },
                        toolbar: {
                          items: [
                            "p",
                            "heading",
                            "italic",
                            "bold",
                            "blockquote",
                            "link",
                            "table",
                            "undo",
                            "redo",
                            "numberedList",
                            "bulletedList",
                          ],
                        },
                      }}
                      editor={ClassicEditor}
                      data={fields.remarks}
                      onReady={(editor) => {
                        setEditor(editor);
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setFields({ ...fields, remarks: data });
                      }}
                      onBlur={(event, editor) => {}}
                      onFocus={(event, editor) => {}}
                    />
                  </Box>
                }
              </>
            )}

            <SubmitButton
              sx={{
                position: "sticky",
                bottom: 0, // Adjust the top value as needed
                zIndex: 1, // Optional: ensure it stays above other content
              }}
              mt={5}
              title={(fields.id ? "Update" : "Create") + " Complain"}
              onClick={onSubmit}
              loading={loading}
            ></SubmitButton>
          </ProjectDetailsController>
        )}
      </CustomDialog>
    </>
  );
};
export default memo(CreateUi);
