import axios from "axios";
import { getHeaders } from "../utils/helpers/helper";
import endpoints from "./endpoints";


export const getOfficesApi = async params => {
  const callResponse = await axios({
    url: endpoints.OfficesBase,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


export const getOfficesByIdApi = async params => {
  const callResponse = await axios({
    url: endpoints.OfficesById,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


export const addOfficesApi = async data => {
  const callResponse = await axios({
    url: endpoints.OfficesBase,
    method: "POST",
    headers: getHeaders(),
    
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const updateOfficesField = async data => {
  const callResponse = await axios({
    url: endpoints.OfficesBase,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const deleteOfficesField = async data => {
  const callResponse = await axios({
    url: endpoints.OfficesBase,
    method: "DELETE",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


export default getOfficesApi;