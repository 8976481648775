import { USER_ROLES } from "../constants/constants"
import { INVOICES_NAME, INVOICE_STATUS, INVOICE_TYPE } from "../constants/invoice.constant"
import MODULES from "../constants/module.constants"
import { PROJECT_STATUS } from "../constants/project.constant"

export const allowedInvoiceBasedonProjectStatus = (status, role) => {

  if (role == USER_ROLES.admin)
    return Object.keys(INVOICES_NAME).map((item) => ({
      label: INVOICES_NAME[item],
      _id: item
    })).sort((a, b) => a.label.localeCompare(b.label))

  const invoices = [
    {
      label: 'General',
      _id: INVOICE_TYPE.GENERAL
    },
    {
      label: 'Extraa Charge',
      _id: INVOICE_TYPE.EXTRAA_CHARGE
    },
    // {
    //   label: 'Refund',
    //   _id: INVOICE_TYPE.REFUND_INVOICE
    // },

    // {
    //   label: 'Loss',
    //   _id: INVOICE_TYPE.LOSS_INVOICE
    // }
  ]
  // if (
  //   [
  //     PROJECT_STATUS.INSTALLATION_COMPLETED,
  //     PROJECT_STATUS.CES_RECIEVED,
  //     PROJECT_STATUS.PENDING_POST_INSTALLATION_VERIFICATION,
  //     PROJECT_STATUS.COMPLETED,
  //     PROJECT_STATUS.COMPLETED_AND_VERIFIED
  //   ].includes(Number(status))
  // ) {
  //   invoices.push({
  //     label: 'Purchase Invoice',
  //     _id: INVOICE_TYPE.SUPPLIER_INVOICE
  //   })
  //   invoices.push({
  //     label: 'Application & Delivery Fees',
  //     _id: INVOICE_TYPE.APPLICATION_AND_DELIVERY_FEES_INVOICE
  //   })

  // }



  // if (
  //   [

  //     PROJECT_STATUS.CES_RECIEVED,
  //     PROJECT_STATUS.PENDING_POST_INSTALLATION_VERIFICATION,
  //     PROJECT_STATUS.COMPLETED,
  //     PROJECT_STATUS.COMPLETED_AND_VERIFIED
  //   ].includes(Number(status))
  // ) {
  //   invoices.push({
  //     label: 'Solar Victoria Invoice',
  //     _id: INVOICE_TYPE.SOLAR_VICTORIA_INVOICE
  //   })


  // }
  return invoices
}
const mapInvoiceIdsWithInvoiceModulesId = (invoiceId) => {

  switch (Number(invoiceId)) {
    case INVOICE_TYPE.DEPOSIT: return MODULES.DEPOSIT_INVOICE;
    case INVOICE_TYPE.CUSTOMER_INVOICE: return MODULES.CUSTOMER_INVOICE;
    case INVOICE_TYPE.EXTRAA_CHARGE: return MODULES.EXTRAA_CHARGE_INVOICE;

    case INVOICE_TYPE.GENERAL: return MODULES.GENERAL_INVOICE;
    case INVOICE_TYPE.ELECTRICIAN_INVOICE: return MODULES.ELEC_INVOICE;
    case INVOICE_TYPE.INCENTIVES_INVOICE: return MODULES.INCENTIVES_INVOICE;
    // case INVOICE_TYPE.STC_INVOICE: return MODULES.STC_INVOICE;
    // case INVOICE_TYPE.SOLAR_VICTORIA_INVOICE: return MODULES.SV_INVOICE;
    case INVOICE_TYPE.SUPPLIER_INVOICE: return MODULES.SUPPLIER_INVOICE;
    case INVOICE_TYPE.APPLICATION_AND_DELIVERY_FEES_INVOICE: return MODULES.ADF_INVOICE;
    case INVOICE_TYPE.LOSS_INVOICE: return MODULES.LOSS_INVOICE;
    case INVOICE_TYPE.REFUND_INVOICE: return MODULES.REFUND_INVOICE;

  }
}

export const isInvoiceAllowed = (userModules = [], type) => {

  const module = mapInvoiceIdsWithInvoiceModulesId(type)
  return userModules.includes(module)

}
export const isAllowedPartialPayment = (type) => {
  return type == INVOICE_TYPE.CUSTOMER_INVOICE
}
export const isAllowedToEdit = (type) => {

  return [INVOICE_TYPE.ELECTRICIAN_INVOICE, INVOICE_TYPE.SUPPLIER_INVOICE, INVOICE_TYPE.APPLICATION_AND_DELIVERY_FEES_INVOICE].includes(type)
}

export const getInvoiceColor = (status) => {
  if (status == INVOICE_STATUS.PAID)
    return "success"
  if (status == INVOICE_STATUS.PARTIALLY_PAID)
    return "warning"
  if (status == INVOICE_STATUS.PENDING)
    return "error"
}