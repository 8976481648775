import { CalendarMonth } from "@mui/icons-material";
import {
  List,
  ListSubheader,
  Paper,
  Box,
  Typography,
  useTheme,
  ListItemButton,
  Badge,
  TablePagination,
  CircularProgress,
} from "@mui/material";
import moment from "moment";
import { CenteredBox } from "../../components/layouts/common/boxes";
import SubmitButton from "../../components/button/SubmitButton";
import { openModal } from "../../store/actions/modalAction";
// import ListController from "../blogs/ListController";
import AnnouncementUI from "../announcements/AnnouncementViewDetailsUi";
import AnnouncementsFormUI from "../announcements/AnnouncementsFormUI";
import { useDispatch } from "react-redux";
import ListUi from "../announcements/ListUi";
import AnnouncementViewDetailsController from "../announcements/AnnouncementViewDetails.controller";
import { Link } from "react-router-dom";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import AnnouncementModal from "./AnnouncementModal";
import BlogModal from "./BlogModel";

const AllBlogShowUi = ({
  modalKey,
  loading,
  title,
  filters,
  setFilters,
  list,
}) => {
  const handleChangePage = (e, newVal) => {
        
    setFilters({ ...filters, pageNo: newVal+1 })
}
const handleChangeRowsPerPage = (e) => {
    setFilters({ ...filters, pageNo: 1, pageSize: e.target.value })
}

  const theme = useTheme();
  const dispatch = useDispatch();
  const handleClick = (blog) => {
    dispatch(openModal(<BlogModal blog={blog} />, "sm", undefined, "blog"));
  };

  return (
    <>
      <CustomDialog
        id={modalKey}
        loading={loading}
        title={title}
        closeText="Close"
      >
        {loading ? (
          <CenteredBox>
            {" "}
            <CircularProgress />
          </CenteredBox>
        ) : (
          <>
            {list?.result?.map((blog) => (
              <Box
                sx={{
                  display: "flex",
                  bgcolor: "#F5F5F5",
                  borderRadius: "10px",
                  borderColor: "lightgrey",
                  margin: 3,
                  alignItems: "center",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <Link
                    sx={{ textDecoration: "none" }}
                    onClick={() => handleClick(blog)}
                    key={blog._id}
                  >
                    <ListItemButton disableTouchRipple sx={{}} key={blog._id}>
                      <Box
                        p={1}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {/* <Badge
                    color="error"
                    overlap="circular"
                    badgeContent=""
                    variant="dot"
                    // invisible={
                    //   storedIds.includes(blog._id) ? true : false
                    // }
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  > */}
                        <Box
                          sx={{
                            backgroundColor: "#ccc",
                            width: 45,
                            height: 45,
                            borderRadius: "50%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            p: 1.5,
                            // mr: 2,
                          }}
                        >
                          <Typography variant="body2" sx={{}}  color="#002050">
                            {" "}
                            {moment(blog.date).format("DD")}
                          </Typography>

                          <Typography
                            variant="caption"
                            lineHeight={"100%"}
                            color="#002050"
                          >
                            {" "}
                            {moment(blog.date).format("MMM")}
                          </Typography>
                        </Box>
                        {/* </Badge> */}
                      </Box>

                      <Box
                        sx={{
                          width: "70%",
                          ml: 2,

                          flexDirection: "row",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                           color="#002050"
                          sx={{ flexGrow: 1, width: "100%" }}
                        >
                          {blog.title.toUpperCase()}
                        </Typography>
                        <Typography
                          variant="caption"
                           color="#002050"
                          sx={{ justifySelf: "flex-end" }}
                        >
                          {"" + moment(blog.date).fromNow(true) + " Ago"}
                        </Typography>
                      </Box>
                      <Box variant="p" sx={{ justifySelf: "flex-end" }}></Box>
                    </ListItemButton>
                  </Link>
                </Box>
              </Box>
            ))}
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <TablePagination
                rowsPerPageOptions={[5, 25, 100]}
                count={list?.total}
                rowsPerPage={filters?.pageSize}
                page={filters?.pageNo - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          </>
        )}
      </CustomDialog>
    </>
  );
};
export default AllBlogShowUi;
