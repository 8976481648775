import React, { useState, useEffect } from 'react'
import { Button, TextField, Box, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import { closeModal } from "../../store/actions/modalAction"
import { callApiAction } from "../../store/actions/commonAction"
import { emailListeningApi } from '../../apis/emailIntegration.api'
import CustomDialog from '../../components/layouts/common/CustomDialog'
import { callSnackBar } from '../../store/actions/snackbarAction'
import { SNACK_BAR_VARIETNS } from '../../utils/constants/constants'
import CustomInput from '../../components/inputs/CustomInput'

const CreateController = ({ callBack, fields }) => {
    const dispatch = useDispatch()
    const [formValues, setFormValues] = useState({})
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({})

    useEffect(() => {
        // Initialize formValues based on fields prop
        const initialValues = fields.reduce((acc, field) => {
            acc[field.name] = ''; // Set initial values to empty strings
            return acc;
        }, {});
        setFormValues(initialValues);
    }, [fields]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value
        });
    }

    const handleSubmit = (e) => {
        e?.preventDefault()
        setLoading(true); // Start loading indicator

        // Clean formValues to ensure only valid fields are included
        const cleanedValues = Object.keys(formValues).reduce((acc, key) => {
            if (['email', 'password', 'port', 'host'].includes(key)) {
                if (key === 'email') {
                    acc['user'] = formValues[key]; // Rename 'email' to 'user'
                } else if (key === 'port') {
                    acc[key] = Number(formValues[key]); // Convert 'port' to a number
                } else {
                    acc[key] = formValues[key]; // Assign other values as is
                }
            }
            return acc;
        }, {});


        dispatch(
            callApiAction(
                async () => await emailListeningApi(cleanedValues), // Call the API
                async (response) => {
                    // On success
                    await callBack(response); // Execute callback with response
                    setLoading(false); // Stop loading indicator
                    dispatch(closeModal()); // Close the modal
                },
                (err) => {
                    // On error
                    setLoading(false); // Stop loading indicator
                    setErrors({ apiError: err || 'An error occurred' }); // Set error message

                    dispatch(callSnackBar(err,SNACK_BAR_VARIETNS.error))
                }
            )
        );
    }

    return (
        <CustomDialog
        
        title={"Add Email Integration"}
        onSubmit={handleSubmit}
        loading={loading}
confirmText='Start Listen'
        >
        
            
            {fields.map((field) => (
                <TextField
                    key={field.name}
                    name={field.name}
                    label={field.label +"*"}
                    type={field.type || 'text'}
                    value={formValues[field.name] || ''}
                    fullWidth
                    margin="dense"
                    onChange={handleChange}
                    error={!!errors[field.name]} // Display error state if exists
                    helperText={errors[field.name] || ''}
                />
            ))}
            {errors.apiError && (
                <Typography color="error" variant="body2">{errors.apiError}</Typography> // Display API error if exists
            )}
            
        
        </CustomDialog>
    )
}

export default CreateController
