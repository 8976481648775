export const THIRD_PART_APPS_USED = {
  none: "none",


  google_map: "google_map",
  // facebook_lead: "facebook_lead",
  // uniden_portal: "uniden_portal",
};
export const USER_ROLES = {
  director: 9,
  partner_admin: 7,
  admin: 1,
  sub_admin: 2,
  sales: 3,
  tl: 8,
};
export const NOTIFICATION_TYPE = {
  general: "general",
  project: "project",
  lead: "lead",
};
export const SNACK_BAR_VARIETNS = {
  error: "error",
  suceess: "success",
  info: "info",
  warning: "warning",
};

export const CUSTOMER_STATUS = {
  PENDING: 1,
  COMPLETED: 2,
  REJECTED: -1,
};

export const AUS_STATES = ["WA", "VIC", "NSW", "QLD", "TAS", "SA"];
export const CALENDAR_ITEMS_TYPES = {
  HOLIDAYS: 1,
  LOGS: 2,
  TASK: 3,
  PROJECT: 4,
  LEAD: 5,
  LEAD: 5,
};

export const APP_TYPES = {
  WEB: 1,
  APP: 2,
};

export const actions = {
  SIGN_IN: "SIGN_IN",
  SET_USER: "SET_USER",
  SIGN_OUT: "SIGN_OUT",
  OPEN_MODAL: "OPEN_MODAL",
  CLOSE_MODAL: "CLOSE_MODAL",

  SET_LIFE_STOCK_DATA: "SET_LIFE_STOCK_DATA",
  START_LIFE_STOCK_LOADING: "START_LIFE_STOCK_LOADING",

  SET_LEAVE_BALANCE_COUNT_DATA: "SET_LEAVE_BALANCE_COUNT_DATA",
  START_LEAVE_BALANCE_COUNT_LOADING: "START_LEAVE_BALANCE_COUNT_LOADING",

  SET_HOLIDAY_COUNT_DATA: "SET_HOLIDAY_COUNT_DATA",
  START_HOLIDAY_COUNT_LOADING: "START_HOLIDAY_COUNT_LOADING",

  SET_NOTIFICATION_DATA: "SET_NOTIFICATION_DATA",
  NOTIFICATION_READ: "NOTIFICATION_READ",

  CALL_SNACKBAR_DATA: "CALL_SNACKBAR_DATA",

  SET_DEVICES_LIST: "SET_DEVICES_LIST",
  UPDATE_DEVICES_LIST: "UPDATE_DEVICES_LIST",
  CHANGE_DATE_RANGE: "CHANGE_DATE_RANGE",

  SOCKET_CONNECT: "socket_connect",
  SOCKET_CONNECT_WTH_GROUPS: "socket_connect_with_groups",
  CREATE_CHAT_GROUP: "create_chat_group",
  CHANGE_ACTIVE_GROUP: "change_active_group",
  ADD_MESSAGE: "ADD_MESSAGE",
  MESSAGE_ARRIVED: "MESSAGE_ARRIVED",

  MESSAGE_DELETED: "MESSAGE_DELETED",
  GROUP_MEMBER_REMOVED: "GROUP_MEMBER_REMOVED",
  ADDED_TO_GROUP: "ADDED_TO_GROUP",
  ADD_GROUP_CHAT_DATA: "ADD_GROUP_CHAT_DATA",
  MARK_AS_READ_TO_ALL: "MARK_AS_READ_TO_ALL",
  GROUP_CHAT_LOADING: "GROUP_CHAT_LOADING",
  MARK_NEW_MESSAGE_AS_READ: "MARK_NEW_MESSAGE_AS_READ",
  MARK_NEW_MESSAGE_AS_UNREAD: "MARK_NEW_MESSAGE_AS_UNREAD",
  SOCKET_DISCONNECT: "socket_disconnect",
  SOCKET_RESET: "socket_reset",

  SET_VERSION_DATA: "SET_VERSION_DATA",
  SET_VERSION_DATA_ONDELETE: "SET_VERSION_DATA_ONDELETE",
  START_VERSION_LOADING: "START_VERSION_LOADING",

  ADD_TAG_DATA: "ADD_TAG_DATA",
  

  SET_ANNOUNCEMENT_DATA: "SET_ANNOUNCEMENT_DATA",
  SET_USER_LAST_READ_ANNOUNCEMENT: "SET_USER_LAST_READ_ANNOUNCEMENT",
  SET_BLOG_DATA: "SET_BLOG_DATA",
  LEAD_FILTER_CHANGE: "LEAD_FILTER_CHANGE",
  SET_PHONE_INSTANCE: 'SET_PHONE_INSTANCE',
  DIAL_CALL: 'DIAL_CALL',
  TOGGLE_PHONE_VISIBILITY: 'TOGGLE_PHONE_VISIBILITY',
  

  SET_LEADER_BOARD_FILTERS: "SET_LEADER_BOARD_FILTERS",
  SET_LEADER_BOARD_DATA: "SET_LEADER_BOARD_DATA",
};

export const DATE_TIME_FILTERS = {
  this_week: "This Week",
  this_month: "This Month",
  this_year: "This Year",
  last_month: "Last Month",
  last_year: "Last Year",
  last_week: "Last Week",
  today: "Today",
  yesterday: "Yesterday",
};

export const EMAIL_BOX_TYPE = {
  INBOX: "INBOX",
  STARRED: "[Gmail]/Starred",
  ALL_MAIL: "[Gmail]/All Mail",
  SENT: "[Gmail]/Sent Mail",
  BIN: "[Gmail]/Bin",
  // IMPORTANT:"[Gmail]/Important",
  SPAM: "[Gmail]/Spam",
};

export const LOG_TYPE = {
  CHECK_IN: 1,
  CHECK_OUT: 2
}

export const TEMPLATE_CATEGORY = {
  EMAIL: 1,
  WHATSUP: 2,
  MESSAGE: 3,
};

export const MODULES_TYPES = {
  PANNELS: 1,
  INVERTERS: 2,
  BATTERIES: 3,
  OTHERS: 4,
};
