import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../../../store/actions/commonAction";
import {
  addProjectNotesApi,
  getProjectNotesApi,
  updateProjectsNoteFavorite,
} from "../../../../apis/projects.api";
import { callSnackBar } from "../../../../store/actions/snackbarAction";
import {
  SNACK_BAR_VARIETNS,
  USER_ROLES,
} from "../../../../utils/constants/constants";
import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Rating,
  Select,
  styled,
  Switch,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import CustomInput from "../../../../components/inputs/CustomInput";
import moment from "moment";
import { CenteredBox } from "../../../../components/layouts/OneViewBox";
import NoDataComponent from "../../../../components/layouts/NoDataComponent";
import SubmitButton from "../../../../components/button/SubmitButton";
import { findObjectKeyByValue, moduleAccessCondition } from "../../../../utils/helpers/helper";
import { NOTES_TYPE } from "../../../../utils/constants/project.constant";
import CustomDialog from "../../../../components/layouts/common/CustomDialog";
import { MODAL_KEYS } from "../../../../utils/constants/modal.constant";
import getNotesTypeApi from "../../../../apis/notesType.api";
import AsyncDropDown from "../../../../components/inputs/AsyncDropDown";
import {
  AccountCircle,
  CalendarMonth,
  Note,
  Notes,
  StarBorderOutlined,
  StarOutlined,
} from "@mui/icons-material";
import { PreviewIcon } from "../../../../components/inputs/IconSelectionDropDown";
import { StyledSearchBar } from "../../../../components/inputs/SearchBar";
import CreateProjectNotesController from "./CreateNoteController";
import MODULES from "../../../../utils/constants/module.constants";

const Container = styled(Box)(({ theme }) => ({
  border: "1px solid ",
  borderLeft: "5px solid",
  borderColor: theme.palette.primary.main,
  borderLeftColor: theme.palette.primary.main,
  alignItems: "center",
  width: "100%",
  borderTopLeftRadius: theme.shape.borderRadius,
  // background: theme.palette.secondary.main,

  borderRadius: theme.shape.borderRadius,
}));

const NotesCard = ({ item, handleChange }) => {
  const theme = useTheme()
  return <Grid key={item?._id} item xs={6}>
    <Container m={1} p={2}>
      <Box
        component={Paper}
        elevation={0}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: theme.palette.secondary.main,
        }}
      >
        <Box
          display="flex"
          m={1}
          alignItems="center"
        >
          <Typography
            color="grey"
            variant="caption"
            lineHeight="100%"
          >
            {item.type.title}
          </Typography>
        </Box>

        <Box
          display="flex"
          mb={1}
          alignItems="center"
        >
          <Typography
            color="grey"
            ml={1}
            variant="caption"
            lineHeight="100%"
          >
            <IconButton
              size="small"
              onClick={() => handleChange(item._id)}
            >
              {item.mark == true ? (
                <StarOutlined
                  fontSize="inherit"
                  sx={{
                    color: theme.palette.primary.main,

                  }}
                />
              ) : (
                <StarBorderOutlined fontSize="inherit" />
              )}
            </IconButton>
          </Typography>
        </Box>
      </Box>
      <Box display="flex" >
        <Box
          display="flex"
          m={1}
          gap="2px"
          alignItems="flex-start"
          flex={1}
        >
          <Typography
            color="grey"
            variant="caption"
            lineHeight="100%"
          >
            <AccountCircle fontSize="inherit" />
          </Typography>
          <Typography
            color="grey"
            variant="caption"
            lineHeight="100%"
          >
            {item.user_name}
          </Typography>
        </Box>
        <Box
          display="flex"
          m={1}
          // justifyContent="center"
          gap="2px"
          alignItems="flex-start"
          flex={1}
        >
          <Typography
            color="grey"
            variant="caption"
            lineHeight="100%"
          >
            <CalendarMonth fontSize="inherit" />
          </Typography>
          <Typography
            color="grey"
            variant="caption"
            lineHeight="100%"
          >
            {moment(item.date).format("DD/MM/YYYY HH:mm")}
          </Typography>
        </Box>
      </Box>

      <Box
        display="flex"
        m={1}
        alignItems="flex-start"
        flex={1}
      >
        <Typography
          color="grey"
          variant="body2"
          lineHeight="100%"
        >
          {item.note}
        </Typography>
      </Box>


    </Container>
  </Grid>
}

const ProjectsNotesController = ({
  project_id,
  
  defaultNotes = [],
  setDefaultNotes = () => { },
}) => {
  const theme = useTheme();

  const { user } = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [notes, setNotes] = useState(defaultNotes);


  const [type, setType] = useState(null);
  const [notFilter, setNoteFilter] = useState(undefined);
  const [mark, setMark] = useState(false);

  const handleChange = (id) => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await updateProjectsNoteFavorite({ id }),
        (response) => {
          setLoading(false);
          // setMark(!mark);
          setMark((prevMark) => !prevMark);
          setNotes((prevNotes) =>
            prevNotes.map((note) =>
              note._id === id ? { ...note, mark: !note.mark } : note
            )
          );
          setDefaultNotes(defaultNotes.map((note) =>
            note._id === id ? { ...note, mark: !note.mark } : note
          ))
          dispatch(
            callSnackBar("Favorite Notes changed.", SNACK_BAR_VARIETNS.suceess)
          );
        },
        (err) => {
          setLoading(false);
          dispatch(
            callSnackBar(
              "Favorite Notes cant be changed.",
              SNACK_BAR_VARIETNS.error
            )
          );
        }
      )
    );
  };


  const fetchNotesByProjectId = useCallback(
    (id, mark, type) => {
      setLoading(true);
      dispatch(
        callApiAction(
          async () => await getProjectNotesApi({ id, mark, type }),
          (response) => {
            setNotes(response?.notes ?? []);
            setDefaultNotes(response?.notes ?? []);
            setLoading(false);
          },
          (err) => {
            setLoading(false);
          }
        )
      );
    },
    [setDefaultNotes, setNotes, setLoading, dispatch]
  );

  useEffect(() => {
    if (project_id && defaultNotes.length == 0)
      fetchNotesByProjectId(project_id, notFilter, type);
  }, []);
  if (!moduleAccessCondition(user, [MODULES.NOTES_VIEW]))
    return <></>
  return (
    <>
      <CustomDialog id={MODAL_KEYS.NOTES} title="Project Notes">
        <Box sx={{ width: "100%" }}>
          <Grid container spacing={2} flexDirection="row-reverse">
            <Grid item xs={12} md={9}>
              <Box
                variant="outlined"
                component={Paper}
                sx={{ width: "100%", minHeight: "100%" }}
                p={3}
              >
                <Tooltip title="Only Favorite Notes">
                  <IconButton>
                    <StarOutlined
                      fontSize="inherit"
                      sx={{
                        color: theme.palette.primary.main,
                      }}
                    />
                  </IconButton>
                </Tooltip>
                <Switch
                  checked={notFilter}
                  onChange={(e) => {
                    const isStarred = e.target.checked
                    const filterNote = isStarred ? true : undefined;
                    setNoteFilter(filterNote);

                  }}
                  color="primary"
                />


                <AsyncDropDown
                  id={"notes-type"}
                  key={loading}
                  // defaultVal={type}
                  InputComponent={(params) => (
                    <CustomInput
                      placeholder="Select Note Type"
                      {...params}
                      margin="none"
                      size="small"
                    />
                  )}
                  lazyFun={async (params) => {
                    return await getNotesTypeApi({ ...params });
                  }}
                  // label="Select Notes Type*"
                  OptionComponent={({ option, ...rest }) => {
                    return <MenuItem {...rest}>{option.title}</MenuItem>;
                  }}
                  onChange={async (changedVal) => {
                    const type = changedVal;
                    setType(type);
                  }}
                  titleKey={"title"}
                  valueKey={"_id"}
                  margin="none"

                />
                {notes && notes.length > 0 && (
                  <>
                    <Grid container sx={{ height: "100%", marginTop: "2px" }} spacing={2}>
                      {notes
                        ?.filter(item => notFilter ? item.mark : true)
                        ?.filter(item => type ? item.type?._id == type?._id : true)
                        .map?.((item) => {
                          return (
                            <NotesCard key={item?._id} item={item} handleChange={handleChange} />
                          );
                        })}
                      {
                        notes?.length == 0 && (
                          <Grid item sx={{ height: "100%" }} xs={12}>
                            <CenteredBox sx={{ height: "100%", width: "100%" }}>
                              <NoDataComponent
                                message="No Notes found"
                                variant="body1"
                                iconVariant="h4"
                              />
                            </CenteredBox>
                          </Grid>
                        )
                      }
                    </Grid>
                  </>
                )}
                {(!notes || notes?.length == 0) && (
                  <CenteredBox sx={{ width: "100%", minHeight: "100%" }}>
                    <NoDataComponent
                      variant="h6"
                      message="No Notes Availabled"
                    ></NoDataComponent>
                  </CenteredBox>
                )}
              </Box>
            </Grid>

            {moduleAccessCondition(user, [MODULES.NOTES_CREATE]) && <Grid item xs={12} md={3}>
              <CreateProjectNotesController

                project_id={project_id}
                onNewNoteAdd={(newNote) => {
                  setNotes([...notes, newNote])
                  setDefaultNotes([...defaultNotes, newNote])
                }}

              />
            </Grid>}
          </Grid>
        </Box>
      </CustomDialog>
    </>
  );
  // if (sent) {
  //     return <Chip color="success" size="small" label="Already Sent"></Chip>
  // }
  // return <SubmitButton title="Send STC To Bridge Select" loading={loading} onClick={onClick} />
};
export default ProjectsNotesController;
