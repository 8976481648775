import { Box, Button, ButtonGroup, FormControlLabel, Grid, Paper, Radio, RadioGroup, Typography } from "@mui/material"
import TimeRangeSelector from "../../../components/layouts/common/TimeRangeSelector"
import IconTitle from "../../../components/texts/IconTitle"
import { CalendarMonth } from "@mui/icons-material"
import { allowedDateFilters } from "../../../utils/helpers/helper"

const LeadTimeFilters = ({noRange,onlyRange, filters, setFilters, forPannel = false }) => {


    return <>
        {!forPannel && !onlyRange && <IconTitle Icon={CalendarMonth} title="Dates" />}
        <Paper component={Box} variant="outlined" p={2} display="flex" flexDirection={ "column"} justifyContent="space-between">
           
           {noRange && <IconTitle Icon={CalendarMonth} title="Date Type" />} 
           
           {!onlyRange &&<RadioGroup
                row
                key={filters.dateField}
                value={filters.dateField}
                onChange={(e) => setFilters({ ...filters, dateField: e.target.value })}
                aria-labelledby="date-time-filters"
                name="row-radio-buttons-group"
            >
                <FormControlLabel value="createdAt" control={<Radio size="small" />} label="Created At" />
                <FormControlLabel value="allocation_date" control={<Radio size="small" />} label="Allocation" />
                <FormControlLabel value="lead_updated_date" control={<Radio size="small" />} label="Last Movement" />                
                <FormControlLabel value="lead_close_date" control={<Radio size="small" />} label="Close" />
            </RadioGroup>}
            {!noRange &&<Box sx={{ minWidth: forPannel ? "300px" : "unset" }}>
                <TimeRangeSelector controlled value={filters.dateTitle ? { _id: filters.dateTitle, label: allowedDateFilters.find((item) => item?.id == filters.dateTitle)?.label ?? "" } : null} placeHolder="Date Range" onChange={(newRange) => { setFilters({ ...filters, ...newRange }) }} sx={{minWidth:"0px"}} />
            </Box>}
        </Paper>
    </>
}
export default LeadTimeFilters