import { memo, useCallback, useEffect, useState } from "react";
import ListUi from "./ListUi";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";

import { closeModal, openModal } from "../../store/actions/modalAction";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  IconButton,
  Rating,
  Switch,
  Typography,
} from "@mui/material";
import { useMemo } from "react";
import {
  Delete,
  Edit,
  ExpandCircleDownOutlined,
  Info,
  More,
  MoreHoriz,
  ViewModuleRounded,
} from "@mui/icons-material";
import { MODAL_KEYS } from "../../utils/constants/modal.constant";
import MessageDilog from "../../components/MessageDilog";
import getRatingsApi, { deleteRatingsField } from "../../apis/ratings.api";
import CreateRatingsController from "../company/CreateRatingsController";
import ImageComponent from "../../components/ImageComponent";
import { Link } from "react-router-dom";
import { constructUrl } from "../../utils/helpers/helper";

const DeleteButton = memo(({ params, setParams }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const onDelete = (e) => {
    e?.preventDefault();
    dispatch(closeModal());
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await deleteRatingsField({ id: params?._id }),
        (response) => {
          setParams({});
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  const onClick = () => {
    dispatch(
      openModal(
        <MessageDilog
          title="Alert!"
          message="Are you sure to delete?"
          onSubmit={onDelete}
        />,
        "xs"
      )
    );
  };

  if (loading) return <CircularProgress />;
  return (
    <IconButton onClick={onClick}>
      <Delete color="error" />
    </IconButton>
  );
});
const ActionComponent = memo(({ params, setParams }) => {
  const dispatch = useDispatch();

  const onEdit = () => {
    dispatch(
      openModal(
        <ListController
          parent={params._id}
          callBack={(response, updatedData) => {
            setParams({ ...params, ...updatedData });
          }}
        />,
        "sm",
        undefined,
        MODAL_KEYS.COMPLAIN_CATEGORY_LIST
      )
    );
  };

  return (
    <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
      {/* {!params.parent && (
        <IconButton size="inherit" onClick={onEdit}>
          <MoreHoriz color="info" fontSize="inherit" />
        </IconButton>
      )} */}
      <DeleteButton params={params} setParams={setParams} />
    </Box>
  );
});

const ListController = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  console.log("userss", user);
  const title = "Rating ";
  const fetchApi = getRatingsApi;
  const deleteApi = deleteRatingsField;

  const columns = useMemo(
    () => [

      {
        id: 1,
        fieldName: "logo",
        label: "Logo",
        align: "left",
        renderValue: (params) =>
          params.logo ? (
            <Box sx={{ display: "flex" }}>
              <Box>
                <ImageComponent
                  cachePermanent={true}
                  // src={params.logo}
                  sx={{ height: "40px", width: "87px", objectFit: "contain" }}
                />
              </Box>
            </Box>
          ) : (
            "NA"
          ),
      },
      {
        id: 2,
        fieldName: "platform",
        label: "Platform",
        align: "left",
        sort: true
      },
      // {
      //   id: 3,
      //   fieldName: "total_rating",
      //   label: "Total Rating",
      //   align: "left",
      //   renderValue: (params) => (<>
      //     <Box sx={{ display: "flex", gap: "2px" }}>
      //       <Rating
      //         name="total-ratings"
      //         value={params.total_rating}
      //         precision={0.1}
      //         // max={ele.total_rating}
      //         readOnly
      //       />
      //       <Chip sx={{ fontSize: 14 }}
      //         label={params.total_rating} size="small" />
      //     </Box>
      //   </>
      //   )
      // },
      {
        id: 4,
        fieldName: "rating",
        label: "Rating",
        align: "left",
        sort: true,
        renderValue: (params) => (<>
          <Box sx={{ display: "flex", gap: "2px" }}>
            <Rating
              name="ratings"
              value={params.rating}
              precision={0.1}
              max={params.total_rating}
              readOnly
            />
            <Chip
              label={`${params.rating} / ${params.total_rating}`} size="small" />
          </Box>
        </>
        )
      },
      {
        id: 5,
        fieldName: "platform_url",
        label: "Platform",
        align: "left",
        renderValue: (params) => (params.platform_url?<Button size="small" LinkComponent={Link} to={constructUrl(params.platform_url)} target="_blank" >

          <Typography sx={{textDecoration:"underline",textUnderlineOffset:"2px"}} variant="body2" >
          View
          </Typography>
        </Button>:"")
        // sort: true,
      },
      {
        id: 6,
        fieldName: "",
        label: "Action",

        align: "right",
        renderValue: (params, setParams) => (
          <ActionComponent
            deleteApi={deleteApi}
            params={params}
            setParams={setParams}
          />
        ),
      },
    ],
    [dispatch]
  );

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    searchable: [""],
    sort: "createdAt",
    sortDirection: 1,
    id: user.data.company_id._id
  });

  // do not change

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState({});

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchApi({ ...filters }),
        (response) => {
          setList(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };
  const onCreateBtnClick = () => {
    dispatch(
      openModal(
        <CreateRatingsController
          callBack={fetchList}
          company_id={user.data.company_id._id}
        />,
        "sm",
        undefined,
        "ratings"
      )
    );
  };

  useEffect(() => {
    fetchList();
  }, [filters]);

  return (
    <>
      <ListUi
        title={title}
        onCreateBtnClick={onCreateBtnClick}
        filters={filters}
        setFilters={setFilters}
        loading={loading}
        list={list}
        columns={columns}
      />
    </>
  );
};
export default ListController;
