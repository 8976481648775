import { useEffect, useState } from "react"
import CustomDialog from "../../../../components/layouts/common/CustomDialog"
import { MODAL_KEYS } from "../../../../utils/constants/modal.constant"
import { getPoDownloadsApi } from "../../../../apis/projects.api"
import { useDispatch } from "react-redux"
import { closeModal } from "../../../../store/actions/modalAction"
import { callSnackBar } from "../../../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../../../utils/constants/constants"
import { callApiAction } from "../../../../store/actions/commonAction"
import fileDownload from "js-file-download";
import CustomInput from "../../../../components/inputs/CustomInput"
import { Box, Checkbox, FormControlLabel, Paper, Typography } from "@mui/material"
import { getSupportEmailsByUserApi } from "../../../../apis/user.api"

const PoDownloadModal = ({ orderId, supplier, supplierId }) => {
    const dispatch = useDispatch()
    const modalKey = MODAL_KEYS.PO_DOWNLOAD
    const [loading, setLoading] = useState(false)
    const [fields, setFields] = useState({
        id: orderId,
        remarks: "",
        email: false,

    })
    const [supportingEmailList, setSupportingEmailList] = useState([])
    const onSubmit = (e) => {
        e?.preventDefault()
        setLoading(true)
        dispatch(callApiAction(
            async () => await getPoDownloadsApi({ ...fields, emailList: supportingEmailList.filter((item) => item.send)?.map(item=>item.email)?.join(', ') }),
            (response) => {
                fileDownload(response, supplier + ".pdf")
                dispatch(closeModal(modalKey))
                setLoading(false)
            },
            (err) => {
                dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
                setLoading(false)
            },
            true
        ))
    }

    const fetchSuppliersSupportEmails = (id) => {
        setLoading(true)
        dispatch(
            callApiAction(
                async () => await getSupportEmailsByUserApi({ user_id: supplierId }),
                async (response) => {

                    setSupportingEmailList(response ? response?.map((item) => ({ email: item, send: true })) : [])

                    setLoading(false)

                },
                (err) => {
                    setFields({ ...fields, err })
                    setLoading(false)

                }
            )
        )
    }

    useEffect(() => {
        fetchSuppliersSupportEmails()
    }, [])
    return <CustomDialog
        id={modalKey}
        title="Download PO"
        loading={loading}
        onSubmit={onSubmit}
    >
        <CustomInput
            multiline
            rows={4}
            autoFocus={true}
            disabled={loading}
            value={fields.remarks}
            onChange={(e) => setFields({ ...fields, err: '', remarks: e.target.value })}
            type="text"
            label={"Site Notes*"}

        />
        <FormControlLabel
            label="Send Email To Supplier"
            control={
                <Checkbox
                    checked={fields.email}
                    onChange={(e) => setFields({ ...fields, err: '', email: e.target.checked ?? null })}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
            }
        />
        {
            fields.email && <Paper variant="outlined" component={Box} p={2} >
                <Typography>Email List</Typography>
                {
                    supportingEmailList.map((item, index) => <Box key={item?.email}>
                        <FormControlLabel control={<Checkbox checked={item?.send}
                            onChange={(e) => {
                                const list = [...supportingEmailList]
                                list[index].send = e.target.checked
                                setSupportingEmailList(list)
                            }}
                        />} label={item?.email} />
                    </Box>)
                }
            </Paper>
        }

    </CustomDialog>
}
export default PoDownloadModal