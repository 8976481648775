import { Autocomplete, ButtonBase, CircularProgress, Collapse, IconButton, InputAdornment, MenuItem, Typography, styled } from "@mui/material"
import { Box } from "@mui/system"
import { Fragment, memo, useEffect, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'



import CustomInput from "../../components/inputs/CustomInput"

import CustomDialog from "../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../components/layouts/OneViewBox"
import FileInput from "../../components/inputs/FileInput"

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Delete, Description, PictureAsPdf, UploadFile } from "@mui/icons-material"
import { MuiChipsInput } from "mui-chips-input"
import { convertHTMLVariableToStringVariable, reventToHTMLVariableFromStringVariable, validateEmail } from "../../utils/helpers/helper"
import AsyncDropDown from "../../components/inputs/AsyncDropDown"
import { getTemplateApi } from "../../apis/template.api"
import CustomEditor from "../../components/inputs/CustomEditor"
const FileViewrBox = styled(Box)(({ theme }) => ({
    width: "100%",
    border: "1px solid " + theme.palette.primary.main,
    position: "relative",
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    cusrsor: "pointer",
    alignItems: "center",

    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(1),

    marginBottom: theme.spacing(1),
    "*": {
        zIndex: 1,
        wordBreak: "break-word",
    },
    "::after": {
        zIndex: 0,
        content: "' '",
        position: "absolute",
        top: "0px",

        left: "0px",
        height: "100%",
        width: "100%",
        borderRadius: theme.shape.borderRadius,
        background: theme.palette.primary.main,
        opacity: "0.2",
    }
}))
const FileContainer = styled(ButtonBase)(({ theme }) => ({
    // width: "100%",
    border: "2px dashed " + theme.palette.primary.main,
    position: "relative",
    // minHeight: "50px",
    display: "flex",
    justifyContent: "flex-start",
    cusrsor: "pointer",

    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(2),
    paading: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "input": {
        cursor: "pointer",
        opacity: "0",
        zIndex: 11,
        position: "absolute",
        top: "0px",
        left: "0px",
        height: "100%",
        width: "100%",
    },
    "::after": {
        content: "' '",
        position: "absolute",
        top: "0px",
        left: "0px",
        height: "100%",
        width: "100%",
        borderRadius: theme.shape.borderRadius,
        background: theme.palette.primary.main,
        opacity: "0.2",
    }
}))

const CCANDBCCBOX = ({ fields, setFields, loading }) => {
    const [show, setShow] = useState(false)
    const toogleShow = () => {
        if (show) {
            setFields({ ...fields, err: '', cc: [], bcc: [] })
            setShow(false)
        } else {
            setShow(true)
        }
    }
    return <>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }} >
            <Typography color='info.main' component={ButtonBase} onClick={toogleShow} >{show ? "Hide" : "Show"} CC && BCC</Typography>
        </Box>
        {<Collapse in={show} >
            <MuiChipsInput disabled={loading} fullWidth margin="dense" value={fields.cc ?? []} type="email"
                label={"CC*"} onChange={(newData) => {
                    if (newData && newData.length > 0 && newData.reduce((val, item) => validateEmail(item) ? val : false, true) == true) {
                        setFields({ ...fields, err: '', cc: newData })
                    } else {
                        setFields({ ...fields, err: '', cc: [] })
                    }


                }} />
            <MuiChipsInput disabled={loading} fullWidth margin="dense" label={"BCC*"} value={fields.bcc ?? []} onChange={(newData) => {
                if (newData && newData.length > 0 && newData.reduce((val, item) => validateEmail(item) ? val : false, true) == true) {
                    setFields({ ...fields, err: '', bcc: newData })
                } else {
                    setFields({ ...fields, err: '', bcc: [] })
                }


            }} />
        </Collapse>
        }    </>
}
const CreateUi = ({ title, fields, setFields, loading, onSubmit, setfiles, files, emailTemplateType, modalKey }) => {
    const { user } = useSelector(state => state)
    
   
    return <>

        <CustomDialog
            id={modalKey}
            loading={loading}
            err={fields.err}
            onSubmit={onSubmit}
            title={`${title}`}
            closeText="Close"
            confirmText={`Send`}
        >

            {loading ? <CenteredBox><CircularProgress /></CenteredBox> :
                <>
                    <CustomInput
                        autoFocus={true}
                        disabled={loading}
                        value={fields.to}
                        onChange={(e) => setFields({ ...fields, err: '', to: e.target.value })}
                        type="email"
                        label={"To*"}

                    />
                    <CCANDBCCBOX fields={fields} loading={loading} setFields={setFields} />
                    


                    <AsyncDropDown
                        id={"mail-templates"}
                        value={fields?.selectedTemplate}
                        lazyFun={async (params) => {
                            return await getTemplateApi({
                                ...params,
                                type:emailTemplateType
                            });
                        }}
                        OptionComponent={({ option, ...rest }) => {
                            return <MenuItem {...rest}>{option.title}</MenuItem>;
                        }}
                        onChange={async (changedVal) => {
                            setFields({
                                ...fields,
                                selectedTemplate: changedVal,
                                html: changedVal ? changedVal?.template : null,
                                subject: changedVal ? changedVal?.subject : null,
                                sub_category: null,
                            });
                        }}
                        titleKey={"title"}
                        valueKey={"_id"}
                        InputComponent={(params) => (
                            <CustomInput {...params} label="Select Template" />
                        )}
                    />
                    <CustomInput
                        key={fields.selectedTemplate?._id}
                        disabled={loading}
                        value={fields.subject}
                        onChange={(e) => setFields({ ...fields, err: '', subject: e.target.value })}
                        type="text"
                        label={"subject*"}
InputProps={{
    endAdornment:
        <InputAdornment position="end">
          {fields.subjectSuffix}
        </InputAdornment>
      
}}
                    />
                    <Box mt={2} >
                      
                        <CustomEditor
                            key={fields.selectedTemplate?._id}
                            placeholder="Write Email Body"
                            value={reventToHTMLVariableFromStringVariable(fields.html)}
                            onChange={(val) => setFields({ ...fields, html: convertHTMLVariableToStringVariable(val) })}

                        >


                        </CustomEditor>


                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>


                        <FileContainer mt={2} mb={2}>

                            <input type="file" name="file" multiple accept=".pdf,.png,.jpg,.jpeg" onChange={(e) => {

                                if (e.target.files?.length > 0) {
                                    setfiles([...files, ...e.target.files])
                                }
                            }}></input>

                            {/* {
                                loading && <CenteredBox p={3}>

                                    <LinearProgress sx={{ zIndex: 1111, width: "100%" }} variant="determinate" value={percentage} />
                                </CenteredBox>
                            } */}
                            {!loading && <><Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
                                <Typography variant="h4" align="center" lineHeight="100%"><UploadFile fontSize="inherit" lineHeight="100%"></UploadFile></Typography>
                            </Box>
                                {/* <Box sx={{ display: "flex", justifyContent: "flex-start", flexDirection: "column", flex: 1 }} ml={3}>
                                    <Typography align="left" variant="h6" fontWeight="bold">{"Attch Files"}</Typography>
                                    <Typography align="left" variant="body2">{"only image files & pdf are allowed"}</Typography>
                                </Box> */}
                            </>}


                        </FileContainer>
                        {
                            Array.from(files).map((file, index) => <FileViewrBox p={2} key={file.name}>
                                <Description />&nbsp;&nbsp;
                                <Box display="flex" flex={1}>
                                    <Typography>
                                        {file.name}
                                    </Typography>
                                </Box>

                                <IconButton size="small"

                                    onClick={() => {
                                        const filesArr = [...files]
                                        filesArr?.splice(index, 1)
                                        setfiles(filesArr)
                                    }}
                                >
                                    <Delete color="error"></Delete>
                                </IconButton>
                            </FileViewrBox>)
                        }
                    </Box>
                </>}

        </CustomDialog>
    </>
}
export default memo(CreateUi)