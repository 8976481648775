import axios from "axios";
import { getHeaders } from "../utils/helpers/helper";
import endpoints from "./endpoints";

export const getAnnouncementApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.announcementFetch,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const getAnnouncementByIdApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.announcementFetchByid,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const addAnnouncementApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.announcementBase,
    method: "POST",
    headers: getHeaders(),

    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const deleteAnnouncementApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.AnnouncementDelete,
    method: "DELETE",
    headers: getHeaders(),

    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export default getAnnouncementApi;
