import axios from "axios";
import { getHeaders } from "../utils/helpers/helper";
import endpoints from "./endpoints";

export const getCompanyCertificateApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.CompanyCertificateBase,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const addCompanyCertificateApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.CompanyCertificateBase,
    method: "POST",
    headers: getHeaders(),

    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const deleteCompanyCertificateApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.CompanyCertificateBase,
    method: "DELETE",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const updateCompanyCertificateApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.CompanyCertificateBase,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

// export const deleteOfficesField = async data => {
//   const callResponse = await axios({
//     url: endpoints.OfficesBase,
//     method: "DELETE",
//     headers: getHeaders(),
//     data,
//   })
//     .then(response => response.data)
//     .catch(err => err.response.data);

//   return callResponse;
// };

export const getCompanyCertificateByIdApi = async (params) => {
    const callResponse = await axios({
      url: endpoints.CompanyCertificateById,
      method: "get",
      headers: getHeaders(),
      params,
    })
      .then((response) => response.data)
      .catch((err) => err.response.data);

    return callResponse;
  };

export default getCompanyCertificateApi;
