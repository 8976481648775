import {  Paper, Tab, Tabs,  Typography, styled } from "@mui/material"
import { Box } from "@mui/system"

import DataTable from "../../components/tables/DataTable"

import TimeRangeSelector from "../../components/layouts/common/TimeRangeSelector"
import { CUSTOMER_STATUS } from "../../utils/constants/constants"
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxInDesktop"

import AsynSearchBar from "../../components/inputs/AsynSearchBar"
import SubmitButton from "../../components/button/SubmitButton"



const FilterTitleBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }

}))
const FiltersBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",

    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }
}))


const ListUi = ({ title, filters, setFilters, list, roles, loading, exportLoading,onExportBtnClick, columns }) => {

    

    return (
        <>

            <Box mb={3}>
                <Paper elevation={2} sx={{ width: "100%", padding: 4 }} >
                    <Box mb={4} >
                        <FilterTitleBox>
                            <Typography variant="h5" mb={2} >{title}</Typography>



                        </FilterTitleBox>


                        <FiltersBox mt={3} >
                            <Box sx={{ width: "100%", maxWidth: "400px" }}>
                                <TimeRangeSelector onChange={(newRange) => { setFilters({ ...filters, ...newRange }) }} />

                            </Box>
                            <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1 }} pl={3} >
                                {/* <StyledSearchBar fullWidth title="Search by Name | Email | Phone " size="small" placeholder={"Search Name | Email | Phone"} value={filters.search} onChange={(e) => { setFilters({ ...filters, search: e.target.value }) }} /> */}

                                
                                <AsynSearchBar
                                    fullWidth
                                    title="Search by Name | Email | Phone "
                                    size="small"
                                    placeholder={"Search Name | Email | Phone"}
                                    defaultValue={filters.search}
                                    onChange={(changedVal) => { setFilters({ ...filters, search: changedVal }) }}
                                />
                            </PaddingBoxInDesktop>
                        </FiltersBox>

                    </Box>
                    <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1 }}  >
                                <Box mb={2} >
                                    {<SubmitButton loading={exportLoading} variant="contained" onClick={onExportBtnClick} title={"Export CSV"} />}
                                </Box>
                            </PaddingBoxInDesktop>
                    {/* <Box mb={3}>
                        <Tabs variant="scrollable"
                        sx={{borderBottom:1,borderColor:"divider"}}
                            scrollButtons="auto" value={filters.status} onChange={(e, val) => { setFilters({ ...filters, status: val }) }}>
                           
                           <Tab label={'All'} value={null}  />
                            {
                                Object.keys(CUSTOMER_STATUS).map((item) => {
                                    return <Tab label={item} value={CUSTOMER_STATUS[item]} key={item} />
                                })
                            }

                        </Tabs>
                    </Box> */}
                    <Box sx={{ minHeight: "300px" }}>
                        <DataTable columns={columns} rows={list.result ? list.result : []} count={list.total ?? 0} filters={filters} setFilters={setFilters} loading={loading} />
                    </Box>
                </Paper>
            </Box>

        </>
    )
}
export default ListUi