import { memo, useEffect, useMemo, useState } from "react"
import useValidate from "../../../store/hooks/useValidator"
import AddPostCodeRangeUi from "./AddPostCodeRangeUi"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../../store/actions/commonAction"
import { closeModal } from "../../../store/actions/modalAction"
import { useParams } from "react-router-dom"
import {  addEnergyPartnerPostCodeRangeApi} from "../../../apis/energy-partners.api"

const AddPostcodeRangeController = ({ modalKey, title, partner_id, callBack }) => {
    const validate = useValidate()
    const dispatch = useDispatch()
    const params = useParams()


    const createApi = addEnergyPartnerPostCodeRangeApi
  

    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',
        
        start:'',
        end: '',
        partner_id

    })
    

    const validationSchemaForCreate = useMemo(() => ([

        {
            required: true,
            value: fields.start,
            field: 'Post Code Range Start From',
        },
        
        {
            required: true,
            value: fields.end,
            field: 'Post Code Range End At',
        }
    ]), [fields])




    const createFunction = async () => {
        const validationResponse = validate(validationSchemaForCreate)

        if (validationResponse === true) {
            const creatableData = {}
            for (let field in fields) {
                if (fields[field] && fields[field] != '')
                    creatableData[field] = fields[field]
            }

            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await createApi(creatableData),
                    async (response) => {

                        await callBack(response)
                        setLoading(false)
                        dispatch(closeModal(modalKey))
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }


  



    const onSubmit = async (e) => {
        e.preventDefault()
       
            createFunction()

    }

  

    return <AddPostCodeRangeUi title={title} modalKey={modalKey}  loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
}
export default memo(AddPostcodeRangeController)