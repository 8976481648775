import { memo, useEffect, useMemo, useState } from "react"
import useValidate from "../../store/hooks/useValidator"
import CreateUi from "./CreateUI"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { closeModal } from "../../store/actions/modalAction"
import { useParams } from "react-router-dom"

import { callSnackBar } from "../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../utils/constants/constants"
import { MODAL_KEYS } from "../../utils/constants/modal.constant"
import { createscheduledcall } from "../../apis/scheduledcall.api"


const CreateController = ({ projectId }) => {
    const validate = useValidate()
    const dispatch = useDispatch()
    const params = useParams()

    const title = "Schedule Call"
    const createApi = createscheduledcall

    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',
        scheduled_date: '',
        remarks: '',
    })



    const validationSchemaForCreate = useMemo(() => ([

        {
            required: true,
            value: fields.scheduled_date,
            field: 'Schedule Date',

        }

    ]), [fields])





    const createFunction = async (e) => {
        e?.preventDefault()
        const validationResponse = validate(validationSchemaForCreate)

        if (validationResponse === true) {
            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await createApi({ ...fields, project_id: projectId }),
                    async (response) => {

                        setLoading(false)

                        dispatch(closeModal(MODAL_KEYS.SCHEDULE_CALL))
                        dispatch(callSnackBar("Call schedule successfully", SNACK_BAR_VARIETNS.suceess))


                    },
                    (err) => {
                        setLoading(false)
                        dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            dispatch(callSnackBar(validationResponse, SNACK_BAR_VARIETNS.error))
            setFields({ ...fields, 'err': validationResponse })
        }
    }



    // if (loading)
    //     return <CircularProgress />
    // return (
    //     <>

    //         <Tooltip arrow title="Create Project">
    //             {<SubmitButton disabled={loading} loading={loading} size="small" title={"Create"} onClick={onSubmit}></SubmitButton>}
    //         </Tooltip>
    //     </>

    // )


    return <CreateUi title={title} loading={loading} fields={fields} onSubmit={createFunction} setFields={setFields} />
}
export default memo(CreateController)