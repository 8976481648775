import { useDispatch, useSelector } from "react-redux"
import { USER_ROLES } from "../../../../utils/constants/constants"
import { IconButton, MenuItem, Tooltip } from "@mui/material"
import LeadLastUpdateDateController from "./LeadLastUpdateDateController"
import { openModal } from "../../../../store/actions/modalAction"
import { MODAL_KEYS } from "../../../../utils/constants/modal.constant"
import { CalendarMonth } from "@mui/icons-material"


const LeadLastUpdateDateButton = ({ id,title="Last Movement Date",date_field, date, callBack = () => { } }) => {

    const { user } = useSelector(state => state)
    const dispatch = useDispatch()


    const onOpenModalClick = () => {
        dispatch(openModal(<LeadLastUpdateDateController title={title} date_field={date_field} date={date} id={id} callBack={callBack} />, "xs",undefined,MODAL_KEYS.LEAD_UPDATE_MODAL))
    }


    if (user.data.role == USER_ROLES.admin)
        return <MenuItem onClick={onOpenModalClick}><CalendarMonth fontSize='inherit' /> &nbsp;{title}</MenuItem>
    return <></>



}
export default LeadLastUpdateDateButton