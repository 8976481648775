import { memo,  useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from '../../store/actions/commonAction'
import { openModal } from '../../store/actions/modalAction'


import Calendar from '../../components/layouts/Calendar'
import CalendarBox from './CalendarInnerBox'
import moment from 'moment'

import CustomMonthPicker from '../../components/layouts/common/CustomMonthPicker'
import { Box, MenuItem, Paper } from '@mui/material'
import SubmitButton from '../../components/button/SubmitButton'
import { CenteredBox } from '../../components/layouts/common/boxes'
import { CALENDAR_ITEMS_TYPES, SNACK_BAR_VARIETNS, USER_ROLES } from '../../utils/constants/constants'
import CreateController from './CreateController'
import { getTasksApi } from '../../apis/task.api'
import { callSnackBar } from '../../store/actions/snackbarAction'
import AsyncDropDown from '../../components/inputs/AsyncDropDown'
import { StyledSearchBar } from '../../components/inputs/SearchBar'
import { getUserApi } from '../../apis/user.api'
import { getAttendecesOfmonthApi } from '../../apis/attendence.api'

const TaskCalendarController = ({ Component }) => {
  const { holiday, user } = useSelector((state) => state)

  const [calendarTimeObj, setCalendarTimeObj] = useState(moment())

  const weeklyOf = []
  const userId = user.data._id

  const [list, setList] = useState([])
  const [listLoading, setListLoading] = useState(false)

  const [taskList, setTaskList] = useState(null)
  const [logsList, setLogsList] = useState(null)

  const [taskLoading, setTaskLoading] = useState(null)
  const [logsLoading, setLogsLoading] = useState(null)

  const holidayList = user.data.holiday

  const dispatch = useDispatch()

  const [filters, setFilters] = useState({
    userId: userId,

  })
  const fetchTaskList = () => {
    setTaskLoading(true)
    dispatch(
      callApiAction(
        async () =>
          await getTasksApi({
            ...filters,
            date: calendarTimeObj.valueOf(),
          }),
        (response) => {

          setTaskList(response)
          setTaskLoading(false)
        },
        (err) => {
          setTaskList(null)
          setTaskLoading(false)
          dispatch(callSnackBar("server Error", SNACK_BAR_VARIETNS.error))
        },
      ),
    )

  }
  const fetchAttendenceList = () => {
    setLogsLoading(true)
    dispatch(
      callApiAction(
        async () =>
          await getAttendecesOfmonthApi({
            userId: filters.userId,
            date: calendarTimeObj.toISOString(),
          }),
        (response) => {
          setLogsLoading(false)
          setLogsList(response)


        },
        (err) => {
          setLogsLoading(false)
          dispatch(callSnackBar("server Error", SNACK_BAR_VARIETNS.error))
        },
      ),
    )

  }
  const fetchList = () => {
    setListLoading(true)


    fetchTaskList()

    fetchAttendenceList()


  }

  const createCalendarList = () => {

    const finalList = []

    if (Array.isArray(taskList)) {
      taskList.forEach((item) => {
        finalList.push({
          title: item.name,
          start: new Date(item.start_date),
          end:  new Date(item.start_date),
          relatedId: item._id,
          type: CALENDAR_ITEMS_TYPES.TASK,
          status: item.status,
          priority: item.priority

        })
      })
    }

    if (Array.isArray(logsList)) {
      logsList.forEach((item) => {
        finalList.push({
          logType: item.logType,
          start: new Date(item.time),
          end: new Date(item.time),
          type: CALENDAR_ITEMS_TYPES.LOGS,
          time: moment(item.time).valueOf()

        })
      })
    }
    setListLoading(false)
    setList(finalList)
  }


  useEffect(() => {
    if (taskList && holidayList && !taskLoading && logsList && !logsLoading) //
      createCalendarList()
  }, [holidayList, logsList, taskList])
  useEffect(() => {
    fetchList()
  }, [calendarTimeObj.valueOf(), filters.userId])

  const onTaskCreateBtnClick = () => {
    dispatch(openModal(<CreateController callBack={fetchTaskList} />), "md")
  }


  return (
    <>

      <Paper component={Box} p={3} mb={3} >
        <Box sx={{ display: "flex", justifyContent: "space-between" }} mb={3}>
          <Box sx={{ display: "flex", flex: 1, maxWidth: "300px" }}>

            {(user.data.role == USER_ROLES.admin || user.data.role == USER_ROLES.sub_admin || user.data.role == USER_ROLES.tl || user.data.role == USER_ROLES.partner_admin) && <AsyncDropDown
              margin={0}
              id={'tasks-employees'}
              InputComponent={(props) => <StyledSearchBar placeholder="Select Person"  {...props} margin="none" size="small" />}
              lazyFun={async (params) => { return await getUserApi({ ...params }) }}

              OptionComponent={({ option, ...rest }) => {
                return <MenuItem {...rest}>{option.name}</MenuItem>
              }}
              onChange={async (changedVal) => {

                setFilters({ ...filters, userId: changedVal ? changedVal._id : null })
              }}
              titleKey={'name'}
              valueKey={"_id"}
            />}
          </Box>
          <Box sx={{ display: "flex" }}>
            <Box >
              <SubmitButton onClick={onTaskCreateBtnClick} disableElevation sx={{ height: "100%" }} title="Create Task" />
            </Box>
            <Box ml={2} >
              {/* <SubmitButton disableElevation sx={{ height: "100%" }} title="Check In" /> */}
             
            </Box>
          </Box>
        </Box>
        <Box mb={2} sx={{ display: "flex" }} >
          <Box sx={{ display: "flex", flex: 1 }} >
            <CustomMonthPicker date={calendarTimeObj} setDate={setCalendarTimeObj} />
          </Box>

        </Box>
        <CenteredBox>
          <Calendar
            loading={listLoading}
            data={list}
            Component={CalendarBox}
            calendarTimeObj={calendarTimeObj.toDate()}
          />
        </CenteredBox>
      </Paper>
    </>
  )
  return <></>
}
export default memo(TaskCalendarController)
