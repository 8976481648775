import {
  AccountBalance,
  AccountBalanceWallet,
  Analytics,
  Announcement,
  AutoMode,
  Business,
  Category,
  Chat,
  ChatBubble,
  CheckCircle,
  CloudSync,
  CreditScore,
  CurrencyExchange,
  Dashboard,
  Diversity1,
  ElectricMeter,
  Email,
  Engineering,
  Error,
  Feedback,
  Group,
  Groups2,
  Groups2Outlined,
  IntegrationInstructions,
  Inventory,
  Key,
  Leaderboard,
  LibraryBooks,
  ListAlt,
  Loyalty,
  Message,
  Money,
  MoneyOff,
  NextWeek,
  Payment,
  PaymentOutlined,
  Pending,
  PendingActions,
  People,
  PlaylistAddCheck,
  QuestionAnswer,
  Report,
  RuleFolderOutlined,
  Sell,
  Settings,
  Source,
  SupportAgentOutlined,
  Workspaces,
  Description,
  ProductionQuantityLimits,
  AllInclusive,
  Paid,
  Timer,
  WorkHistoryOutlined,
  LocalActivity,
  ManageAccounts,
  FiberManualRecord,
  WbTwilight,
  TagSharp,
  LocalOffer,
  LocalShipping,
  AccountTree,
  CrisisAlert,
  MapsHomeWork,
  EmailOutlined,
  Storefront,
  Notes,
  TableRows,
  CalendarMonth,
  Campaign,
  Widgets,
  Call,
  Feed,
  WorkspacePremium,
  Apartment,
  Star,
  Stars,
  BarChart,
  PieChart,
  TableChart,
  Inventory2,
} from "@mui/icons-material";
import AircallIcon from './assets/icons/AircallIcon.svg'
import { Navigate } from "react-router-dom";
import AppContainer from "./components/layouts/common/AppContainer";

import SignInController from "./pages/signin/SignInController";
import UserListController from "./pages/user/ListController";
import SupportEmailListController from "./pages/support-email/ListController";
import PendingPaymentListController from "./pages/pendingpayments/ListController";
import PendingVerificationListController from "./pages/pendingverification/ListController";
import TermsListController from "./pages/terms-and-condition/ListController";
import ExpenseListController from "./pages/expenses/ListController";
import InvoiceListController from "./pages/invoice/ListController";
// import UnhandledLeadListController from "./pages/lead/unhandled/ListController";
import LeadListController from "./pages/lead/ListController";
// import CustomerListController from "./pages/customer/ListController";
import StockListController from "./pages/projects/stock/ListController";
import NotesTypeListController from "./pages/notes-type/ListController";
import InstallerListController from "./pages/installer/ListController";
import SupplierListController from "./pages/supplier/ListController";
import TeamListController from "./pages/team/ListController";
import ProjectListController from "./pages/projects/ListController";

import SourcesListController from "./pages/sources/ListController";
import RejectionReasonListController from "./pages/rejection-reasons/ListController"
import LoanBenefitsListController from "./pages/loanbenefits/ListController";
import FBIntegrationListController from "./pages/facebookintegration/ListController";
import OpenSolarIntegration from "./pages/integrations/opensolar/MainController";
import AircallIntegration from "./pages/integrations/aircall/MainController";

import SourceCapabilityListController from "./pages/source-capability/ListController"
import SalesPersonCapabilityListController from "./pages/salesperson-capability/ListController"

import EmailIntegrationListController from "./pages/email-integration/ListController";

import ScheduleCallListController from "./pages/scheduled-call/ListController";



import { USER_ROLES } from "./utils/constants/constants";
import PagenotFound, {
  PageNotAllowed,
} from "./components/layouts/PagenotFound";
import ListController from "./pages/secrets/ListController";
import ArtTrackIcon from "@mui/icons-material/ArtTrack";
import DashboardController from "./pages/dashboard/DashboardController";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import SolarPowerIcon from "@mui/icons-material/SolarPower";
import ProjectDetailsController from "./pages/projects/projectdetails/ProjectDetailsController";
import HolidayController from "./pages/holidays/HolidayController";
import ProductCategoryController from "./pages/product-category/ListController";
import ProductController from "./pages/product/ListController";
import EnergyPartnersController from "./pages/energy-partners/ListController";
import BulkInvoiceListController from "./pages/invoice/BulkInvoiceListController";

import InvoiceOverAllSummary from "./pages/invoice/InvoiceOverAllSummary";
import ChatMainController from "./pages/chatapp/ChatMainController";
import ComplainListController from "./pages/complain/ListController";
import ComplainCategoryListController from "./pages/complain-category/ListController";
import BulkInvoiceSelectionWrapper from "./pages/invoice/BulkInvoiceSelectionWrapper";
import { moduleAccessCondition } from "./utils/helpers/helper";
import TaskCalendarController from "./pages/task/TaskCalendarController";
import CompanyListController from "./pages/company/ListController";
import MODULES from "./utils/constants/module.constants";
import modulesConfig from "./config/modules.config";
import VersionsController from "./pages/versions/VersionController";
import TemplateListController from "./pages/template/ListController";
import ModuleListController from "./pages/module/ListController";
import WhiteListedController from "./pages/whiteListedIp/ListController";
import ActivityListedController from "./pages/activity/ListController";
import ALeadFormsListedController from "./pages/lead-forms/ListController";
import PredefinedTagsCategoryController from "./pages/predefinedtags-category/ListController";
import PipeLineController from "./pages/pipelines/ListController";
import {
  defaultRedirect,
  routeObjGenerator,
} from "./utils/helpers/route.helper";
import AppSubContainer from "./components/layouts/common/AppSubContainer";
import { TAG_TYPE } from "./utils/constants/tags.constant";
import AnnouncementsFormController from "./pages/announcements/ListController";
import BlogFormController from "./pages/blog/ListController";
// import CompanyProfileController from "./pages/company-profile/CompanyProfileController";
import { PIPELINE_TYPE } from "./utils/constants/pipelines.constant";
import { ENERGY_PARTNER_TYPE } from "./utils/constants/energypartner.constant";
import CustomerListController from "./pages/customer/ListController";
import { TableRow } from "@mui/material";
import LeadCalendarController from "./pages/lead/calendar/LeadCalendarController";
import LeadPipelineViewController from "./pages/lead/pipeline-view/LeadPipelineViewController";
import { SvgIcon } from '@mui/material';
import UnAssignedListController from "./pages/unassign-lead/ListController";
import CompanyCertificateListController from "./pages/company-certificate/ListController"
import OfficesListController from "./pages/offices/ListController";
import RatingListController from "./pages/ratings/ListController"; import SalesReportController from "./pages/lead-reports/sales-report/SalesReportController";
import LeadAnalyticsBySourceController from "./pages/lead-reports/source/LeadAnalyticsBySourceController";
import LeadAnalyticsByRejectionReasonController from "./pages/lead-reports/rejection-reasons/LeadAnalyticsByRejectionReasonController";
import LeadAnalyticsByStateController from "./pages/lead-reports/state/LeadAnalyticsByStateController";
import FinanceCountsController from "./pages/finance/FinanceCountsController";
import InstallationCalendar from "./pages/projects/installationcalendar/InstallationCalendar";
import CompanyDetailsController from "./pages/company/CompanyDetailsController";

function AircallMUIIcon(props) {
  return (
    <SvgIcon {...props}>
      <image href={AircallIcon} width="90%" height="90%" />
    </SvgIcon>
  );
}


const defineRoutes = (user) => {
  const redirectDefault = defaultRedirect(user);
  const generateRouterObjFun = routeObjGenerator(user, redirectDefault);
  return [
    generateRouterObjFun("sign-in", "Sign In", undefined, <SignInController />, undefined, undefined, undefined, true, !user.isLoggedIn),
    generateRouterObjFun("", "", undefined, <Navigate replace to={redirectDefault} />, undefined, undefined, undefined, true, user.isLoggedIn, <Navigate replace to="/sign-in" />),
    generateRouterObjFun("dashboard", "Dashboard", Dashboard, <AppContainer><DashboardController /></AppContainer>, [], Object.values(USER_ROLES).filter((item) => item != USER_ROLES.director)),
    generateRouterObjFun("company", "Companies", Business, <AppContainer><CompanyListController /></AppContainer>, [MODULES.COMPANY_MODULE_LIST], []),
    generateRouterObjFun("company-profile", "", undefined, <AppContainer ><CompanyDetailsController /></AppContainer>, [MODULES.COMPANY_PROFILE_VIEW, MODULES.COMPANY_MODULE_LIST], [], undefined, true),

    generateRouterObjFun("reports", "Reports", BarChart, <AppContainer></AppContainer>, [MODULES.LEAD_REPORTS, MODULES.FINANCE_REPORTS], [], [
      generateRouterObjFun("lead", "Lead", FiberManualRecord, <AppSubContainer />, [MODULES.LEAD_REPORTS], [], [
        generateRouterObjFun("sales", "Sales Report", Analytics, <SalesReportController />, [MODULES.LEAD_REPORTS], []),
        generateRouterObjFun("source", "Source Report", PieChart, <LeadAnalyticsBySourceController />, [MODULES.LEAD_REPORTS], []),
        generateRouterObjFun("state", "State Report", PieChart, <LeadAnalyticsByStateController />, [MODULES.LEAD_REPORTS], []),
        generateRouterObjFun("rejection-reason", "Rejection Reason", PieChart, <LeadAnalyticsByRejectionReasonController />, [MODULES.LEAD_REPORTS], []),
      ]),
      generateRouterObjFun("finance", "Finance", FiberManualRecord, <AppSubContainer />, [MODULES.FINANCE_REPORTS], [], [
        generateRouterObjFun("finance", "Finance Report", TableChart, <FinanceCountsController />, [MODULES.FINANCE_REPORTS], []),
        generateRouterObjFun("invoice-summary", "Invoices Summary", TableChart, <InvoiceOverAllSummary />, [MODULES.FINANCE_REPORTS], []),
      ]),

    ]),
    // generateRouterObjFun("dashboard/:tab", "Dashboard", Dashboard, <AppContainer><DashboardController /></AppContainer>, [MODULES.DASHBOARD_MAIN], [], undefined, true),
    generateRouterObjFun("task", "Task Calendar", PlaylistAddCheck, <AppContainer><TaskCalendarController /></AppContainer>, [MODULES.TASK_MANAGEMENT_MAIN]),


    generateRouterObjFun("chats", "Messages", QuestionAnswer, <AppContainer overflow='hidden'><ChatMainController /></AppContainer>, [MODULES.CHATS_SECTION_MAIN], [], undefined, true),
    generateRouterObjFun("unassign-lead", "Unassigned Leads", PendingActions, <AppContainer><UnAssignedListController /></AppContainer>, [MODULES.LEAD_ADMIN], []),
    generateRouterObjFun("lead", "Leads", ArtTrackIcon, <AppContainer />, [MODULES.LEAD_ADMIN, MODULES.VIEW_LEADS], [], [
      generateRouterObjFun("", "Table", TableRows, <LeadListController />, [MODULES.LEAD_ADMIN, MODULES.VIEW_LEADS], []),

      generateRouterObjFun("calendar", "Calendar", CalendarMonth, <LeadCalendarController />, [MODULES.LEAD_ADMIN, MODULES.VIEW_LEADS], []),
      generateRouterObjFun("pipeline", "Pipeline", AccountTree, <LeadPipelineViewController />, [MODULES.LEAD_ADMIN, MODULES.VIEW_LEADS], []),
      // generateRouterObjFun("calls", "Lead Calls", PermPhoneMsgIcon, <LeadCallListController />),
      // generateRouterObjFun("unhandled-lead", "Unhandled Lead", Error, <UnhandledLeadListController />, [], [USER_ROLES.admin, USER_ROLES.partner_admin]),
    ]),
    generateRouterObjFun("scheduled-call", "Scheduled Call", Call, <AppContainer>  <ScheduleCallListController /> </AppContainer>, [MODULES.SCHEDULED_CALL]),

    // generateRouterObjFun("projects", "Projects", SolarPowerIcon, <AppContainer><ProjectListController /></AppContainer>, [MODULES.VIEW_PROJECTS]),
    generateRouterObjFun("projects", "Projects", SolarPowerIcon, <AppContainer />, [MODULES.VIEW_PROJECTS], [], [
      generateRouterObjFun("", "Table", TableRows, <ProjectListController />, [MODULES.VIEW_PROJECTS], []),

      generateRouterObjFun("calendar", "Calendar", CalendarMonth, <InstallationCalendar />, [MODULES.VIEW_PROJECTS], []),

    ]),
    generateRouterObjFun("project-detail/:id", "Project Detail", SolarPowerIcon, <AppContainer><ProjectDetailsController /></AppContainer>, [MODULES.VIEW_PROJECTS], [], undefined, true),
    generateRouterObjFun("finance", "Finance", Payment, <AppContainer />, [MODULES.BULK_INVOICE,MODULES.VIEW_EXPENSE, ...modulesConfig.find(item => item?.identifier == "INVOICE")?.children?.find(item => item.identifier == "LIST")?.children?.map(item => item.value) ?? []], [], [
      generateRouterObjFun("invoices", "Invoices", PaymentOutlined, <BulkInvoiceSelectionWrapper><InvoiceListController /></BulkInvoiceSelectionWrapper>, modulesConfig.find(item => item?.identifier == "INVOICE")?.children?.find(item => item.identifier == "LIST")?.children?.filter(item => ![MODULES.CREATE_INVOICE, MODULES.CREATE_ELECTRICIAN_INVOICE].includes(item?.value))?.map(item => item.value) ?? []),
      generateRouterObjFun("expenses", "Expenses", Money, <ExpenseListController />, [MODULES.VIEW_EXPENSE]),      
      generateRouterObjFun("bulk", "Bulk Invoices", Workspaces, <BulkInvoiceSelectionWrapper><BulkInvoiceListController /></BulkInvoiceSelectionWrapper>, [MODULES.BULK_INVOICE]),

    ]),
    generateRouterObjFun("payment", "Payments", Paid, <AppContainer />, [MODULES.PAYMENTS_VERIFICATION], [], [
      generateRouterObjFun("payment-verification", "Payment Verification", CreditScore, <PendingVerificationListController />, [MODULES.PAYMENTS_VERIFICATION]),
      generateRouterObjFun("pending-payments", "Pending Payments", WorkHistoryOutlined, <PendingPaymentListController />, [MODULES.PAYMENTS_VERIFICATION]),
    ]),
    generateRouterObjFun("complains", "Complains", Report, <AppContainer><ComplainListController /></AppContainer>, [MODULES.VIEW_COMPLAINS]),

    generateRouterObjFun("users", "Users", Groups2Outlined, <AppContainer />, [MODULES.VIEW_USERS, MODULES.VIEW_INSTALLER, MODULES.VIEW_TEAMS, MODULES.VIEW_SUPPLIER], [], [
      generateRouterObjFun("all-users", "Users", People, <UserListController />, [MODULES.VIEW_USERS]),
      generateRouterObjFun("team", "Sales Teams", Diversity1, <TeamListController />, [MODULES.VIEW_TEAMS]),
      generateRouterObjFun("installer", "Installers", Engineering, <InstallerListController />, [MODULES.VIEW_INSTALLER]),
      generateRouterObjFun("supplier", "Suppliers", LocalShipping, <SupplierListController />, [MODULES.VIEW_SUPPLIER]),

    ]),



    generateRouterObjFun("admin-workspace", "Admin Workspace", ArtTrackIcon, <AppContainer />, [], [USER_ROLES.admin], [
      generateRouterObjFun("lead-forms", "Lead Forms", Feed, <ALeadFormsListedController />, [MODULES.LEAD_FORMS]),
      generateRouterObjFun("announcement", "Announcement", Campaign, <AnnouncementsFormController />, [MODULES.ANNOUNCEMENT_MODULE]),
      generateRouterObjFun("blog", "Blog", Widgets, <BlogFormController />, [MODULES.BLOG_MODULE]),
      generateRouterObjFun("template", "Email Template", Description, <TemplateListController />, [MODULES.EMAIL_TEMPLATES]),
      generateRouterObjFun("holiday", "Holidays", RuleFolderOutlined, <HolidayController />, [MODULES.HOLIDAY_MODULE]),
      // generateRouterObjFun("stock", "Stock", Inventory, <StockListController />, [MODULES.STOCK_MODULE]),
      generateRouterObjFun("customer", "Customers", CloudSync, <CustomerListController />, [MODULES.CUSTOMER_MODULE]),

    ]),
    generateRouterObjFun("product", "Product",Inventory2, <AppContainer />, [MODULES.VIEW_PRODUCT_CATEGORY,MODULES.VIEW_PRODUCT], [], [
      generateRouterObjFun("product-category", "Product Category", FiberManualRecord, <ProductCategoryController />, [MODULES.VIEW_PRODUCT_CATEGORY]),
      generateRouterObjFun("product", "Product SKUs", FiberManualRecord, <ProductController />, [MODULES.VIEW_PRODUCT]),

    ]),
    generateRouterObjFun("setting", "Settings", Settings, <AppContainer />, [MODULES.VIEW_LEAD_SOURCE,MODULES.PREDEFINED_TAGS,MODULES.PIPELINE_MODULE,MODULES.REJECTION_REASON_MODULE,MODULES.INTEGRATION_MODULE,MODULES.NOTES_TYPE_VIEW, MODULES.COMPANY_PROFILE_EDIT], [USER_ROLES.director], [

      generateRouterObjFun("lead", "Lead", FiberManualRecord, <AppSubContainer />, [MODULES.VIEW_LEAD_SOURCE,MODULES.PREDEFINED_TAGS,MODULES.PIPELINE_MODULE,MODULES.REJECTION_REASON_MODULE], [USER_ROLES.director], [
        generateRouterObjFun("source", "Lead Sources", NextWeek, <SourcesListController />, [MODULES.VIEW_LEAD_SOURCE]),

        generateRouterObjFun("activities", "Activities", LocalActivity, <ActivityListedController />, [MODULES.ACTIVITY_MODULE]),
        generateRouterObjFun("predfined-tags", "Predefined Tags", LocalOffer, <PredefinedTagsCategoryController type={TAG_TYPE.LEAD} />, [MODULES.PREDEFINED_TAGS]),
        generateRouterObjFun("pipelines", "Sales Pipelines & steps", AccountTree, <PipeLineController type={PIPELINE_TYPE.LEAD} />, [MODULES.PIPELINE_MODULE]),
        // generateRouterObjFun("loan-benfits", "Loan Benefits", Loyalty, <LoanBenefitsListController />, [MODULES.LOAN_BENEFITS_MODULES]),

        generateRouterObjFun("rejection-reasons", "Rejection Reason", CrisisAlert, <RejectionReasonListController />, [MODULES.REJECTION_REASON_MODULE]),

      ]),
      generateRouterObjFun("integration", "Integration", FiberManualRecord, <AppSubContainer />, [MODULES.INTEGRATION_MODULE], undefined, [
        generateRouterObjFun("fb-integration", "Fb Integration", IntegrationInstructions, <FBIntegrationListController />, [MODULES.INTEGRATION_MODULE]),
        generateRouterObjFun("open-solar", "Open Solar", WbTwilight, <OpenSolarIntegration />, [MODULES.INTEGRATION_MODULE]),
        generateRouterObjFun("aircall", "Aircall", AircallMUIIcon, <AircallIntegration />, [MODULES.INTEGRATION_MODULE]),
      ]),

      generateRouterObjFun("complains", "Complains", FiberManualRecord, <AppSubContainer />, [MODULES.COMPLAIN_CATEGORIES_MODULE], undefined, [
        generateRouterObjFun("categories", "Complain Categories", Category, <ComplainCategoryListController />, [MODULES.COMPLAIN_CATEGORIES_MODULE]),
        // generateRouterObjFun("predfined-tags", "Predefined Tags", LocalOffer, <PredefinedTagsCategoryController type={TAG_TYPE.COMPLAIN} />, [MODULES.ADMIN_PANNEL]),
      ]),
      generateRouterObjFun("energy-partners", "Energy Partners", FiberManualRecord, <AppSubContainer />, [MODULES.ADMIN_PANNEL], [USER_ROLES.director], [
        generateRouterObjFun("distributor", "Distributors", MapsHomeWork, <EnergyPartnersController title="Distributors" type={ENERGY_PARTNER_TYPE.DISTRIBUTOR} />, [], [USER_ROLES.admin, USER_ROLES.director], undefined),
        generateRouterObjFun("retailers", "Retailers", Storefront, <EnergyPartnersController title="Retailers" type={ENERGY_PARTNER_TYPE.RETAILER} />, [], [USER_ROLES.admin, USER_ROLES.director]),


      ]),
      // generateRouterObjFun("general", "General", FiberManualRecord, <AppSubContainer />, [MODULES.ADMIN_PANNEL], undefined, [
      //   // generateRouterObjFun("", "Holidays", RuleFolderOutlined, <HolidayController />, [MODULES.HOLIDAY_MODULE], undefined, undefined, true),


      // ]),
      
      generateRouterObjFun("note-type", "Note Type", FiberManualRecord, <NotesTypeListController />, [MODULES.NOTES_TYPE_VIEW]),
      generateRouterObjFun("company", "Company", FiberManualRecord, <AppSubContainer />, [MODULES.COMPANY_PROFILE_EDIT], undefined, [
        generateRouterObjFun("company-certificate", "Company Certificate", WorkspacePremium, <CompanyCertificateListController />, [MODULES.COMPANY_PROFILE_EDIT]),
        generateRouterObjFun("offices", "Offices", Apartment, <OfficesListController />, [MODULES.COMPANY_PROFILE_EDIT]),
        generateRouterObjFun("ratings", "Ratings", Stars, <RatingListController />, [MODULES.COMPANY_PROFILE_EDIT]),
      ]),
      generateRouterObjFun("version", "Versions", CloudSync, <VersionsController />, [], [USER_ROLES.director]),
      generateRouterObjFun("secrets", "Secrets Management", Key, <ListController />, [], [USER_ROLES.director]),
      // 



    ]
    ),

    // email integration 
    generateRouterObjFun("email-integration", "Email Integration", Email, <AppContainer>  <EmailIntegrationListController /> </AppContainer>, [MODULES.VIEW_USERS]),



    generateRouterObjFun(
      "*",
      "",
      undefined,
      <Navigate replace to={"/sign-in"} />,
      undefined,
      undefined,
      undefined,
      true,
      !user.isLoggedIn,
      <PagenotFound />
    ),
  ];
};
export default defineRoutes;

/* 

================================================================
Demo Object
================================================================
        {
            path: "sign-in",
            element: !user.isLoggedIn ? <SignInController /> : <Navigate replace to="/leaves" />,
            hideInPannel:true, //for showing it onSide pannel or not
            title: "Leave Management", // it will use as name in side navbar
            component:<></>


             children: [
                {
                    path: "",
                    title: "My Leaves",// it will use as name in side navbar
                    element: <MyLeaveController />,
                    hideInPannel:true,
                }
            ],
        },




================================================================
================================================================


*/