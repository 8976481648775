import { Box, Icon, Typography } from "@mui/material"

const IconTitle = ({Icon,title})=>{

    return <Box display="flex" alignItems="center" >
    <Typography variant='body1' lineHeight="100%" >
        <Icon fontSize='inherit' />
    </Typography>
    &nbsp;
    <Typography variant='body1' >
        {title}
    </Typography>
</Box>
}
export default IconTitle