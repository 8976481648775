import { CircularProgress } from "@mui/material"

import { Fragment, memo } from "react"
import CustomInput from "../../components/inputs/CustomInput"
import CustomDialog from "../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../components/layouts/OneViewBox"



const CreateUi = ({ title, isUpdate, fields, setFields, loading, onSubmit, modalKey }) => {



    return <>

        <CustomDialog
            id={modalKey}
            loading={loading}
            err={fields.err}
            onSubmit={onSubmit}
            title={`${isUpdate ? "Update" : "Create"} ${title}`}
            closeText="Close"
            confirmText={`${isUpdate ? "Update" : "Create"}`}
        >

            {loading ? <CenteredBox><CircularProgress /></CenteredBox> :
                <>
                    {<CustomInput
                        autoFocus={true}
                        disabled={loading}
                        value={fields.name}
                        onChange={(e) => setFields({ ...fields, err: '', name: e.target.value })}
                        type="text"
                        label={"Name*"}

                    />}

                    <CustomInput
                        disabled={loading}
                        value={fields.website}
                        onChange={(e) => setFields({ ...fields, err: '', website: e.target.value })}
                        type="text"
                        label={"Website"}

                    />
                    <CustomInput
                        disabled={loading}
                        value={fields.phone}
                        onChange={(e) => setFields({ ...fields, err: '', phone: e.target.value })}
                        type="text"
                        label={"Phone No."}

                    />
                    <CustomInput
                        disabled={loading}
                        value={fields.email}
                        onChange={(e) => setFields({ ...fields, err: '', email: e.target.value })}
                        type="text"
                        label={"Email"}

                    />
{/* 
                    <EnergyPartnerDropDown type={ENERGY_PARTNER_TYPE.DISTRIBUTOR} postcode="2800" value={fields.destributor} onChange={(item) => {
                        console.log(item)
                        setFields({ ...fields, destributor: item })
                    }} /> */}



                </>}

        </CustomDialog>
    </>
}
export default memo(CreateUi)