import { Autocomplete, Button, ButtonGroup, MenuItem, Paper, Typography, styled, CircularProgress, Alert, AlertTitle } from "@mui/material"
import { Box } from "@mui/system"
import { useSelector } from "react-redux"
import SubmitButton from "../../components/button/SubmitButton"
import { StyledSearchBar } from "../../components/inputs/SearchBar"
import { USER_ROLES } from "../../utils/constants/constants"
import DataTable from "../../components/tables/DataTable"
import { findObjectKeyByValue, moduleAccessCondition, titleCase } from "../../utils/helpers/helper"
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxInDesktop"
import AsyncSearchBar from "../../components/inputs/AsynSearchBar"
import MODULES from "../../utils/constants/module.constants"
import { getAllowedRolesForExistingRole } from "../../utils/helpers/user.helper"
import { useMemo } from "react"
import { CenteredBox } from "../../components/layouts/OneViewBox"






const ListUi = ({ title, filters, setFilters, list, roles, loading, onCreateBtnClick, deleteApi }) => {

    const user = useSelector(state => state.user)
    const allowedRoles = useMemo(() => {
        return getAllowedRolesForExistingRole(user?.data?.role).map((item) => ({ label: findObjectKeyByValue(item, USER_ROLES), _id: item }))
    }, [user?.data?.role])
    return (
        <>
          <Paper
            elevation={2}
            sx={{
              width: "100%",
              padding: 4,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {list.length === 0 ? (
              <Box
                mb={2}
                width={1 / 3}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                {moduleAccessCondition(user, [MODULES.CREATE_USERS], []) && (
                  <SubmitButton
                    variant="contained"
                    onClick={onCreateBtnClick}
                    title={"Add Email Integration"}
                  />
                )}
              </Box>
            ) : (
              <Box mb={4} width="100%">
                {loading && (
                  <CenteredBox>
                    <CircularProgress />
                  </CenteredBox>
                )}
                {!loading && (
                  <Paper variant="outlined" component={Box} p={2}>
                    <Alert severity="success">
                      <AlertTitle>Integration Completed</AlertTitle>
                      Your "{user.data.email}" is integrated with email listening
                    </Alert>
                    <Box mt={2} display="flex" justifyContent="center">
                <Button
                  variant="contained"
                  color="error"
                  onClick={deleteApi}
                  mt = {2}
                  mb = {2}
                >
                  Delete Integration
                </Button>
              </Box>
                  </Paper>
                )}
              </Box>
            )}
          </Paper>
        </>
      );
}
export default ListUi