import { Autocomplete, CircularProgress } from "@mui/material";

import { Fragment, memo, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import CustomInput from "../../components/inputs/CustomInput";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import { CenteredBox } from "../../components/layouts/OneViewBox";
import {
  findNameByRole,
  findObjectKeyByValue,
  titleCase,
} from "../../utils/helpers/helper";
import { getAllowedRolesForExistingRole } from "../../utils/helpers/user.helper";
import { USER_ROLES } from "../../utils/constants/constants";
import FileInput from "../../components/inputs/FileInput";

const CreateUi = ({
  title,
  isUpdate,
  fields,
  setFields,
  loading,
  onSubmit,
  modalKey,
}) => {
  return (
    <>
      <CustomDialog
        id={modalKey}
        loading={loading}
        err={fields.err}
        onSubmit={onSubmit}
        title={`${isUpdate ? "Update" : "Create"} ${title}`}
        closeText="Close"
        confirmText={`${isUpdate ? "Update" : "Create"}`}
      >
        {loading ? (
          <CenteredBox>
            <CircularProgress />
          </CenteredBox>
        ) : (
          <>
            {
              <CustomInput
                autoFocus={true}
                disabled={loading}
                value={fields.title}
                onChange={(e) =>
                  setFields({ ...fields, err: "", title: e.target.value })
                }
                type="text"
                label={"Title*"}
              />
            }
             <FileInput
                  onlyImage={true}
                  defaults={fields.logo ? [fields.logo] : []}
                  title="Logo"
                  subTitle="Only .png with aspect ratio of 13/6"
                  accept=".png"
                  onChange={(newUrl) => {
                    setFields({ ...fields, logo: newUrl });
                  }}
                />
            {/* {
              <Autocomplete
                multiple
                disableClearable
                options={[
                  ...Object.keys(USER_ROLES).filter((item) => item !== "director").map((item) => {
                    return {
                      label: titleCase(item),
                      _id: USER_ROLES[item],
                    };
                  }),
                ]}
                value={findNameByRole(fields.allowed_roles)}
                onChange={(e, newVal) => {
                  setFields({
                    ...fields,
                    allowed_roles: newVal
                      ? newVal.map((allowed_roles) => allowed_roles._id)
                      : [],
                  });
                }}
                sx={{
                  width: "100%",
                  display: "flex",
                  "*": { display: "flex", justifyContent: "center" },
                }}
                renderInput={(params) => (
                  <CustomInput
                    placeholder="Select Role*"
                    {...params}
                    label="Select Role*"
                  />
                )}
              />
            } */}
          </>
        )}
      </CustomDialog>
    </>
  );
};
export default memo(CreateUi);
