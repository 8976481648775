import { Box, CircularProgress, MenuItem } from "@mui/material"
import { memo } from "react"
import { useSelector } from 'react-redux'
import CustomDialog from "../../../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../../../components/layouts/OneViewBox"
import ContactDetailsComponent from "../../create/ContactDetailsComponent"
import { TitleBox } from "../../../../components/layouts/common/boxes"




const LeadUpdateContactDetailsUi = ({ title, fields, setFields, loading, onSubmit, modalKey }) => {
    const { user } = useSelector(state => state)

    return <>

        <CustomDialog
            id={modalKey}

            loading={loading}
            err={fields.err}

            onSubmit={onSubmit}
            title={` ${title}`}
            closeText="Close"
            confirmText={`Update`}
        >

            {loading ? <CenteredBox><CircularProgress /></CenteredBox> :
                <>
                <TitleBox>
                    <ContactDetailsComponent
                        disabled={loading}
                        defaultFields={fields.customerDetails}
                        onChange={(valObj) => {
                            setFields({ ...fields, err: "", customerDetails: valObj })
                        }}
                        title={"Customer Details"}
                    />
                   <Box mt={3} />
                    
                    <ContactDetailsComponent
                        disabled={loading}
                        defaultFields={fields.partnerDetails}
                        onChange={(valObj) => {
                            setFields({ ...fields, err: "", partnerDetails: valObj })
                        }}
                        title={"Partner Details(Not Required)"}
                    />
                     </TitleBox>
                </>}

        </CustomDialog>
    </>
}
export default memo(LeadUpdateContactDetailsUi)