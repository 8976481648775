import { memo, useCallback, useEffect, useState } from "react"
import ListUi from "./ListUi"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"

import { closeModal, openModal } from "../../store/actions/modalAction"
import { Box, CircularProgress, IconButton, Switch, Typography } from "@mui/material"
import { useMemo } from "react"
import CreateController from "./CreateController"
import { Delete, Edit, More, MoreHoriz, Undo } from "@mui/icons-material"
import { MODAL_KEYS } from "../../utils/constants/modal.constant"

import { moduleAccessCondition } from "../../utils/helpers/helper"
import MODULES from "../../utils/constants/module.constants"
import MessageDilog from "../../components/MessageDilog"
import { deleteProductCategoryApi, getProductCategoryApi } from "../../apis/product.api"

const ActionComponent = memo(({ params, setParams, deleteApi, createModalKey }) => {
    const dispatch = useDispatch()
    const deleted = params.deleted
    const [loading, setLoading] = useState(false)

    const { user } = useSelector(state => state)

    const onEdit = () => {
        dispatch(openModal(<CreateController id={params._id} callBack={(response, updatedData) => {
            setParams({ ...params, ...updatedData })
        }} />, "sm", undefined, createModalKey))
    }

   
    const deleteFun = async () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await deleteApi({ id: params._id, deleted: !deleted }),
            (response) => {
                setParams({})
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
        dispatch(closeModal())
    }


    const onDelete = () => {
        dispatch(openModal(<MessageDilog onSubmit={deleteFun} title="Alert!" message={`Are you sure to delete "${params.name || params.title}" ?`} />, "sm"))
    }

    const onUndo = () => {
        dispatch(openModal(<MessageDilog onSubmit={deleteFun} title="Alert!" message={`Are you sure to undo delete for "${params.name || params.title}" ?`} />, "sm"))
    }
    if (deleted && moduleAccessCondition(user, [MODULES.DELETE_PRODUCT_CATEGORY], []))
        return <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}> <IconButton onClick={onUndo}>
            <Undo color="error" />
        </IconButton>
        </Box>
    return <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
        {moduleAccessCondition(user, [MODULES.UPDATE_PRODUCT_CATEGORY], []) && !params.identifier && <IconButton size="inherit" onClick={onEdit}>
            <Edit color="info" fontSize="inherit" />
        </IconButton>
        }
      

        {moduleAccessCondition(user, [MODULES.DELETE_PRODUCT_CATEGORY], [])  && !params.identifier && <>

            {loading && <CircularProgress color="primary" fontSize="inherit" />}
            {!loading && <IconButton disabled={loading} size="inherit" onClick={onDelete}>
                <Delete color="error" fontSize="inherit" />
            </IconButton>}
        </>}
    </Box>
})


const ListController = ({  }) => {
    const dispatch = useDispatch()

    const createModalKey = MODAL_KEYS.PRODUCT_CATEGORY

    const title = "Product Category "

    const fetchApi = getProductCategoryApi
    const deleteApi = deleteProductCategoryApi
    

    const columns = useMemo(() => [

        { id: 1, fieldName: 'title', label: 'Category Title', align: "left", sort: true },
        
        {
            id: 5,
            fieldName: '',
            label: 'Action',

            align: "right",
            renderValue: (params, setParams) => <ActionComponent createModalKey={createModalKey} deleteApi={deleteApi} params={params} setParams={setParams} />,
        },

    ], [dispatch]);


    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 10,
        search: '',
        
        searchable: ['title'],
        sort: 'createdAt',
        sortDirection: -1

    })





    // do not change 

    const [loading, setLoading] = useState(false)
    const [list, setList] = useState({})









    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchApi({ ...filters }),
            (response) => {
                setList(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }
    const onCreateBtnClick = () => {
        dispatch(openModal(<CreateController modalKey={createModalKey} callBack={async () => { fetchList() }} />, "sm", undefined, createModalKey))
    }



    useEffect(() => {
        fetchList()
    }, [filters])



    return (
        <>
            <ListUi
                
                title={title}
                onCreateBtnClick={onCreateBtnClick}

                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}
                columns={columns}


            />

        </>
    )
}
export default ListController