import { memo,  useEffect, useState } from "react"
import ListUi from "./ListUi"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"

import { closeModal, openModal } from "../../store/actions/modalAction"
import { Box, CircularProgress, IconButton } from "@mui/material"
import { useMemo } from "react"
import CreateController from "./CreateController"
import { Delete, Edit, Info } from "@mui/icons-material"

import { MODAL_KEYS } from "../../utils/constants/modal.constant"
import MessageDilog from "../../components/MessageDilog"
import { deleteEnergyPartnerApi, getEnergyPartnerApi } from "../../apis/energy-partners.api"
import DetailedViewController from "./DetailedViewController"


const ActionComponent = memo(({ params, setParams, deleteApi, createModalKey,title }) => {
    const dispatch = useDispatch()
    
    const [loading, setLoading] = useState(false)
    

    const onEdit = () => {
        dispatch(openModal(<CreateController modalKey={createModalKey} id={params._id} callBack={(response, updatedData) => {
            setParams({ ...params, ...updatedData })
        }} />, "sm", undefined, createModalKey))
    }

    const onMoreClick = () => {
        dispatch(openModal(<DetailedViewController  title={title} id={params?._id} callBack={(response, updatedData) => {

        }} />, "sm", undefined, MODAL_KEYS.ENERGY_PARTNER_VIEW))
    }
    const deleteFun = async () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await deleteApi({ id: params._id }),
            (response) => {
                setParams({})
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
        dispatch(closeModal())
    }


    const onDelete = () => {
        dispatch(openModal(<MessageDilog onSubmit={deleteFun} title="Alert!" message={`Are you sure to delete "${params.name || params.title}" ?`} />, "sm"))
    }

  

    return <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
          <IconButton size="inherit" onClick={onMoreClick}>
            <Info color="info" fontSize="inherit" />
        </IconButton>
        {<IconButton size="inherit" onClick={onEdit}>
            <Edit color="info" fontSize="inherit" />
        </IconButton>
        }
      

        {<>

            {loading && <CircularProgress color="primary" fontSize="inherit" />}
            {!loading && <IconButton disabled={loading} size="inherit" onClick={onDelete}>
                <Delete color="error" fontSize="inherit" />
            </IconButton>}
        </>}
    </Box>
})


const ListController = ({ createModalKey = MODAL_KEYS.ENERGY_PARTNER_CREATE, type, title }) => {
    const dispatch = useDispatch()




    const fetchApi = getEnergyPartnerApi
    const deleteApi = deleteEnergyPartnerApi

    const columns = useMemo(() => [

        { id: 1, fieldName: 'name', label: 'Name', align: "left", sort: true },
        { id: 2, fieldName: 'phone', label: 'Phone', align: "left" },
        { id: 3, fieldName: 'email', label: 'Email', align: "left" },



        {
            id: 5,
            fieldName: '',
            label: 'Action',

            align: "right",
            renderValue: (params, setParams) => <ActionComponent createModalKey={createModalKey} deleteApi={deleteApi} title={title} params={params} setParams={setParams} />,
        },

    ], [dispatch,title]);


    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 10,
        search: '',
        
        searchable: ['name'],
        type,
        sort: '',
        sortDirection: 1

    })





    // do not change 

    const [loading, setLoading] = useState(false)
    const [list, setList] = useState({})









    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchApi({ ...filters ,type}),
            (response) => {
                setList(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }
    const onCreateBtnClick = () => {
        dispatch(openModal(<CreateController title={title} type={type} modalKey={createModalKey} callBack={async () => { fetchList() }} />, "sm", undefined, createModalKey))
    }



    useEffect(() => {
        fetchList()
    }, [filters,type])



    return (
        <>
            <ListUi

                title={title}
                onCreateBtnClick={onCreateBtnClick}

                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}
                columns={columns}


            />

        </>
    )
}
export default ListController