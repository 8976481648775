import React, { useRef, useState, useEffect, useCallback, memo, useMemo } from 'react';
import { Box, IconButton, styled, Menu, MenuItem, Popover, Button, ButtonBase, useTheme, useMediaQuery, Tooltip, Paper, Typography } from '@mui/material';
import {
    Code,
    FormatBold,
    FormatItalic,
    FormatListBulleted,
    FormatListNumbered,
    FormatQuote,
    FormatUnderlined,
    Redo,
    Title,
    Undo,
    Link,
    FormatAlignCenter,
    FormatAlignLeft,
    FormatAlignRight,
    ArrowDropDown,
    MoreVert,
    CodeOff,
    PlaylistAddCircle
} from '@mui/icons-material';
import { debounce, groupByArray } from '../../utils/helpers/helper';

const EditorContainer = styled(Box)(({ theme }) => ({
    border: "1px solid " + theme.palette.divider,
    width: "100%",
    borderRadius: theme.shape.borderRadius
}));

const EditorButtonContainer = styled(Box)(({ theme }) => ({
    borderBottom: "1px solid " + theme.palette.divider,
    width: "100%",
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    flexWrap: "wrap",
}));
const EditorCustomButton = styled(ButtonBase)(({ theme, active }) => ({
    padding: theme.spacing(2),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.dark[500],
    background: active ? theme.palette.grey.main : "transparent",
    [theme.breakpoints.down('md')]: {
        padding: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
    }
}))
const EditorButton = ({ title, ...props }) => (
    // <IconButton sx={{ padding: 1 }} size="small" color={active ? 'info' : "primary"} {...props} />
    <Tooltip arrow title={title} >
        <EditorCustomButton {...props} />
    </Tooltip>
);

const CustomEditor = ({ placeholder, title, onlyText, value, enableVariableListGrouping = false, variables = [], onChange }) => {
    const editorRef = useRef(null);
    const textAreaRef = useRef(null);
    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [formatState, setFormatState] = useState({
        bold: false,
        italic: false,
        underline: false,
        unorderedList: false,
        orderedList: false,
        blockquote: false,
        heading1: false,
        heading2: false,
        heading3: false,
        paragraph: false,
        alignLeft: false,
        alignCenter: false,
        alignRight: false,
    });
    const [history, setHistory] = useState([]);
    const [historyIndex, setHistoryIndex] = useState(-1);
    const [isHtmlMode, setIsHtmlMode] = useState(false);
    const [headingAnchorEl, setHeadingAnchorEl] = useState(null);
    const [mobileMenuAnchorEl, setMobileMenuAnchorEl] = useState(null);

    const [variablePopver, setVariablePopover] = React.useState(null);

    const onVariblePopOverOpenBtnClick = (event) => {
        event.preventDefault()
        setVariablePopover(event.currentTarget);

    };

    const onVariblePopOverClose = () => {
        setVariablePopover(null);

    };

    const isvariablePopver = Boolean(variablePopver);



    const updateHistory = useCallback(() => {
        const content = editorRef.current.innerHTML;

        if (historyIndex === -1 || history[historyIndex] !== content) {
            const newHistory = history.slice(0, historyIndex + 1);
            newHistory.push(content);
            setHistory(newHistory);
            setHistoryIndex(newHistory.length - 1);
            if (onChange) onChange(content);
        }
    }, [editorRef, historyIndex, history, onChange])
    const saveHistory = useCallback(debounce(updateHistory, 300), [updateHistory]
    )
    const undo = () => {
        if (historyIndex > 0) {
            const newHistoryIndex = historyIndex - 1;
            const newContent = history[newHistoryIndex];
            editorRef.current.innerHTML = newContent;
            setHistoryIndex(newHistoryIndex);
            if (onChange) onChange(newContent);
        }
    };

    const redo = () => {
        if (historyIndex < history.length - 1) {
            const newHistoryIndex = historyIndex + 1;
            const newContent = history[newHistoryIndex];
            editorRef.current.innerHTML = newContent;
            setHistoryIndex(newHistoryIndex);
            if (onChange) onChange(newContent);
        }
    };

    const formatText = (command) => {
        const selection = window.getSelection();
        if (!selection.rangeCount || !selection.anchorNode || !editorRef.current.contains(selection.anchorNode)) return;

        const range = selection.getRangeAt(0);
        const applyFormat = (tagName, alignCommand) => {
            const selection = window.getSelection();
            const range = selection.getRangeAt(0);

            if (!selection.rangeCount || !selection.anchorNode || !editorRef.current.contains(selection.anchorNode)) return;

            const parentElement = range.commonAncestorContainer.nodeType === Node.ELEMENT_NODE
                ? range.commonAncestorContainer
                : range.commonAncestorContainer.parentElement;

            const isAlreadyFormatted = parentElement.tagName.toLowerCase() === tagName;

            // Function to remove alignment styles from parent if needed
            const removeAlignmentStyles = (element) => {
                if (element.tagName.toLowerCase() === 'div') {
                    element.style.textAlign = '';
                }
            };

            if (isAlreadyFormatted) {
                const fragment = document.createDocumentFragment();
                while (parentElement.firstChild) {
                    fragment.appendChild(parentElement.firstChild);
                }
                parentElement.parentNode.replaceChild(fragment, parentElement);
                removeAlignmentStyles(parentElement);
            } else {
                const formatElement = document.createElement(tagName);
                formatElement.appendChild(range.cloneContents());

                if (tagName === 'ul' || tagName === 'ol') {
                    const childNodes = formatElement.childNodes;
                    for (let i = childNodes.length - 1; i >= 0; i--) {
                        const listItem = document.createElement('li');
                        listItem.appendChild(childNodes[i]);
                        formatElement.insertBefore(listItem, childNodes[i + 1]);
                    }
                }

                // Add custom class name
                formatElement.classList.add(`custom-editor-${tagName}`);

                range.deleteContents();
                range.insertNode(formatElement);

                if (tagName === 'div') {
                    formatElement.style.textAlign = alignCommand.replace('align-', '');
                }

                range.selectNodeContents(formatElement);
                selection.removeAllRanges();
                selection.addRange(range);
            }

            saveHistory();
            updateFormatState();
        };

        // const applyFormat = (tagName) => {
        //     const selection = window.getSelection();
        //     const range = selection.getRangeAt(0);

        //     if (!selection.rangeCount || !selection.anchorNode || !editorRef.current.contains(selection.anchorNode)) return;

        //     const parentElement = range.commonAncestorContainer.nodeType === Node.ELEMENT_NODE
        //         ? range.commonAncestorContainer
        //         : range.commonAncestorContainer.parentElement;

        //     const isAlreadyFormatted = parentElement.tagName.toLowerCase() === tagName;

        //     // Function to remove alignment styles from parent if needed
        //     const removeAlignmentStyles = (element) => {
        //         if (element.tagName.toLowerCase() === 'div') {
        //             element.style.textAlign = '';
        //         }
        //     };

        //     if (isAlreadyFormatted) {
        //         const fragment = document.createDocumentFragment();
        //         while (parentElement.firstChild) {
        //             fragment.appendChild(parentElement.firstChild);
        //         }
        //         parentElement.parentNode.replaceChild(fragment, parentElement);
        //         removeAlignmentStyles(parentElement);
        //     } else {
        //         const formatElement = document.createElement(tagName);
        //         formatElement.appendChild(range.cloneContents());

        //         if (tagName === 'ul' || tagName === 'ol') {
        //             const childNodes = formatElement.childNodes;
        //             for (let i = childNodes.length - 1; i >= 0; i--) {
        //                 const listItem = document.createElement('li');
        //                 listItem.appendChild(childNodes[i]);
        //                 formatElement.insertBefore(listItem, childNodes[i + 1]);
        //             }
        //         }

        //         // Add custom class name
        //         formatElement.classList.add(`custom-editor-${tagName}`);

        //         range.deleteContents();
        //         range.insertNode(formatElement);

        //         if (tagName === 'div') {
        //             const alignCommand = `align-${command}`;
        //             formatElement.style.textAlign = alignCommand.replace('align-', '');
        //         }

        //         range.selectNodeContents(formatElement);
        //         selection.removeAllRanges();
        //         selection.addRange(range);
        //     }

        //     saveHistory();
        //     updateFormatState();
        // };



        switch (command) {
            case 'bold':
                applyFormat('b');
                break;
            case 'italic':
                applyFormat('i');
                break;
            case 'underline':
                applyFormat('u');
                break;
            case 'unordered-list':
                const ul = document.createElement('ul');
                ul.appendChild(range.cloneContents());

                const ulChildNodes = ul.childNodes;
                for (let i = ulChildNodes.length - 1; i >= 0; i--) {
                    const listItem = document.createElement('li');
                    listItem.appendChild(ulChildNodes[i]);
                    ul.insertBefore(listItem, ulChildNodes[i + 1]);
                }

                ul.classList.add('custom-editor-ul');

                range.deleteContents();
                range.insertNode(ul);
                saveHistory();
                break;
            case 'ordered-list':
                const ol = document.createElement('ol');
                ol.appendChild(range.cloneContents());

                const olChildNodes = ol.childNodes;
                for (let i = olChildNodes.length - 1; i >= 0; i--) {
                    const listItem = document.createElement('li');
                    listItem.appendChild(olChildNodes[i]);
                    ol.insertBefore(listItem, olChildNodes[i + 1]);
                }

                ol.classList.add('custom-editor-ol');

                range.deleteContents();
                range.insertNode(ol);
                saveHistory();
                break;
            case 'blockquote':
                const blockquote = document.createElement('blockquote');
                blockquote.appendChild(range.cloneContents());
                blockquote.classList.add('custom-editor-blockquote');
                range.deleteContents();
                range.insertNode(blockquote);
                saveHistory();
                break;
            case 'heading1':
                const h1 = document.createElement('h1');
                h1.appendChild(range.cloneContents());
                h1.classList.add('custom-editor-h1');
                range.deleteContents();
                range.insertNode(h1);
                saveHistory();
                break;
            case 'heading2':
                const h2 = document.createElement('h2');
                h2.appendChild(range.cloneContents());
                h2.classList.add('custom-editor-h2');
                range.deleteContents();
                range.insertNode(h2);
                saveHistory();
                break;
            case 'heading3':
                const h3 = document.createElement('h3');
                h3.appendChild(range.cloneContents());
                h3.classList.add('custom-editor-h3');
                range.deleteContents();
                range.insertNode(h3);
                saveHistory();
                break;
            case 'paragraph':
                const p = document.createElement('p');
                p.appendChild(range.cloneContents());
                p.classList.add('custom-editor-p');
                range.deleteContents();
                range.insertNode(p);
                saveHistory();
                break;
            case 'align-left':
                applyFormat('div', 'align-left');
                break;
            case 'align-center':
                applyFormat('div', 'align-center');
                break;
            case 'align-right':
                applyFormat('div', 'align-right');
                break;
            default:
                return;
        }
    };

    const addLink = () => {
        const url = prompt('Enter the URL');
        if (url) {
            const selection = window.getSelection();
            if (selection.rangeCount === 0 || !editorRef.current.contains(selection.anchorNode)) return;

            const range = selection.getRangeAt(0);
            const link = document.createElement('a');
            link.href = url;
            link.className = 'custom-editor-link';
            range.surroundContents(link);
            saveHistory();
        }
    };

    const updateFormatState = () => {
        const selection = window.getSelection();
        if (!selection.rangeCount || !selection.anchorNode || !editorRef.current.contains(selection.anchorNode)) return;

        const anchorNode = selection.anchorNode;
        const parentNode = anchorNode.parentNode;

        setFormatState({
            bold: parentNode.closest ? parentNode.closest('b') !== null : false,
            italic: parentNode.closest ? parentNode.closest('i') !== null : false,
            underline: parentNode.closest ? parentNode.closest('u') !== null : false,
            unorderedList: parentNode.closest ? parentNode.closest('ul') !== null : false,
            orderedList: parentNode.closest ? parentNode.closest('ol') !== null : false,
            blockquote: parentNode.closest ? parentNode.closest('blockquote') !== null : false,
            heading1: parentNode.closest ? parentNode.closest('h1') !== null : false,
            heading2: parentNode.closest ? parentNode.closest('h2') !== null : false,
            heading3: parentNode.closest ? parentNode.closest('h3') !== null : false,
            paragraph: parentNode.closest ? parentNode.closest('p') !== null : false,
            alignLeft: parentNode.closest ? parentNode.closest('div')?.style.textAlign === 'left' : false,
            alignCenter: parentNode.closest ? parentNode.closest('div')?.style.textAlign === 'center' : false,
            alignRight: parentNode.closest ? parentNode.closest('div')?.style.textAlign === 'right' : false,
        });
    };
    useEffect(() => {
        const handlePaste = (event) => {
            event.preventDefault();
            const text = event.clipboardData.getData('text/plain');
            document.execCommand('insertText', false, text);
        };

        editorRef.current?.addEventListener('paste', handlePaste);
        return () => {
            editorRef.current?.removeEventListener('paste', handlePaste);
        };
    }, []);
    useEffect(() => {
        const saveCursorPosition = () => {
            const selection = window.getSelection();
            if (!selection.rangeCount || !editorRef.current.contains(selection.anchorNode)) return null;
            return selection.getRangeAt(0).cloneRange();
        };

        const restoreCursorPosition = (range) => {

            if (range) {
                const selection = window.getSelection();
                selection.removeAllRanges();
                selection.addRange(range);
            }
        };

        if (value !== editorRef.current.innerHTML) {
            const savedRange = saveCursorPosition();
            editorRef.current.innerHTML = value;
            restoreCursorPosition(savedRange);
        }
    }, []);


    useEffect(() => {
        document?.addEventListener?.('selectionchange', updateFormatState);
        return () => {
            document?.removeEventListener?.('selectionchange', updateFormatState);
        };
    }, []);

    const wrapInParagraph = () => {
        const content = editorRef.current.innerHTML;
        if (!content.startsWith('<p>')) {

            editorRef.current.innerHTML = `<p>${content}</p>`;

        }
    };

    const handleInput = () => {
        saveHistory();
        wrapInParagraph();

    };

    const toggleHtmlMode = () => {
        if (isHtmlMode) {
            editorRef.current.innerHTML = textAreaRef.current.value;
        } else {
            textAreaRef.current.value = editorRef.current.innerHTML;
        }
        setIsHtmlMode(!isHtmlMode);

    };

    const handleMobileMenuOpen = (event) => {
        setMobileMenuAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMenuAnchorEl(null);
    };

    const handleHeadingClick = (event) => {
        setHeadingAnchorEl(event.currentTarget);
    };

    const handleHeadingClose = () => {
        setHeadingAnchorEl(null);
    };

    const handleHeadingSelect = (type) => {
        switch (type) {
            case 'heading1':
                formatText('heading1');
                break;
            case 'heading2':
                formatText('heading2');
                break;
            case 'heading3':
                formatText('heading3');
                break;
            case 'paragraph':
                formatText('paragraph');
                break;
            default:
                break;
        }
        handleHeadingClose();
    };

    const handleKeyDown = (e) => {
        if (e?.key === "Backspace") {
            const selection = window.getSelection();
            if (selection.rangeCount === 0) return;

            const range = selection.getRangeAt(0);
            if (range.startContainer === editorRef.current) return;

            const startContainer = range.startContainer;
            const parentNode = startContainer.parentNode;

            if (parentNode.classList.contains('custom-editor-is-variable')) {
                e.preventDefault();
                parentNode.remove();
                handleInput()
                // saveHistory();
            }
        }
    };
    const insertVariable = (variableId) => {
        const editor = editorRef.current;

        // Ensure the editor is focused
        editor.focus();

        // Get the current selection
        const selection = window.getSelection();
        if (selection.rangeCount === 0) return;

        const range = selection.getRangeAt(0);

        // Check if the selection is within the editor's bounds
        if (!editor.contains(range.commonAncestorContainer)) {
            console.warn('Selection is outside of the editor.');
            return;
        }

        // Create the variable text node
        const inputElement = document.createElement('input');
        inputElement.className = 'custom-editor-is-variable';
        inputElement.disabled = true;
        inputElement.type = 'button';
        inputElement.value = variableId
        // const variableText = document.createTextNode(`${variableId}`);
        // inputElement.appendChild(variableText);

        // Insert the variable text at the cursor position
        range.deleteContents();
        range.insertNode(inputElement);

        // Move the cursor after the inserted text
        range.setStartAfter(inputElement);
        selection.removeAllRanges();
        selection.addRange(range);

        // Focus back on the editor
        editor.focus();
        onVariblePopOverClose();
    }

    useEffect(() => {
        let debounceTimeout;

        const observer = new MutationObserver(() => {
            if (debounceTimeout) {
                clearTimeout(debounceTimeout);
            }
            debounceTimeout = setTimeout(() => {
                saveHistory();
                wrapInParagraph();
            }, 300); // Adjust the debounce delay as needed
        });

        observer.observe(editorRef.current, {
            childList: true,
            subtree: true,
            characterData: true
        });

        return () => {
            clearTimeout(debounceTimeout);
            observer.disconnect();
        };
    }, [saveHistory]);

    const variableList = useMemo(() => {
        if (enableVariableListGrouping && variables && variables.length > 0)
            return groupByArray(variables, 'group')
        return variables
    }, [enableVariableListGrouping, variables])

    return (
        <Paper mt={2} component={Box} variant="outlined" p={2}>
            {title && <Typography>
                {title}
            </Typography>}
            {((!enableVariableListGrouping && variableList.length > 0) || (enableVariableListGrouping && Object.keys(variableList).length > 0)) && <Box display="flex" justifyContent="flex-end" >
                <Tooltip title={"Add Variables"} >
                    <IconButton
                        id="variable-button"
                        aria-controls={isvariablePopver ? 'variable-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={isvariablePopver ? 'true' : undefined}
                        onClick={onVariblePopOverOpenBtnClick}
                    >
                        <PlaylistAddCircle />
                    </IconButton>
                </Tooltip>
                <Menu
                    id="variable-menu"
                    anchorEl={variablePopver}
                    open={isvariablePopver}
                    onClose={onVariblePopOverClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    {
                        !enableVariableListGrouping && variableList.map((item) => <MenuItem key={item.id} onClick={() => { insertVariable(item.id) }}>{item.label}</MenuItem>)
                    }
                    {
                        enableVariableListGrouping && Object.keys(variableList).map((itemKey) => <Box key={itemKey}>
                            <MenuItem disabled>{itemKey}</MenuItem>
                            {variableList[itemKey].map((item) => <MenuItem key={item.id} onClick={() => { insertVariable(item.id) }}>{item.label}</MenuItem>)}
                        </Box>)
                    }

                </Menu>
            </Box>}
            <EditorContainer onKeyDown={handleKeyDown}>
                {!isMobile && <EditorButtonContainer>
                    {!isHtmlMode && <>
                        <Button onClick={handleHeadingClick} endIcon={<ArrowDropDown />} variant="outlined">Add Headings</Button>
                        <EditorButton title="Bold" active={formatState.bold} onClick={() => formatText('bold')}><FormatBold /></EditorButton>
                        <EditorButton title="Italic" active={formatState.italic} onClick={() => formatText('italic')}><FormatItalic /></EditorButton>
                        <EditorButton title="Underline" active={formatState.underline} onClick={() => formatText('underline')}><FormatUnderlined /></EditorButton>
                        <EditorButton title="Unordered List" active={formatState.unorderedList} onClick={() => formatText('unordered-list')}><FormatListBulleted /></EditorButton>
                        <EditorButton title="Ordered List" active={formatState.orderedList} onClick={() => formatText('ordered-list')}><FormatListNumbered /></EditorButton>
                        <EditorButton title="Blockquote" active={formatState.blockquote} onClick={() => formatText('blockquote')}><FormatQuote /></EditorButton>

                        <EditorButton title="Add Link" onClick={addLink}><Link /></EditorButton>
                        <EditorButton title="Left Align" active={formatState.alignLeft} onClick={() => formatText('align-left')}><FormatAlignLeft /></EditorButton>
                        <EditorButton title="Center Align" active={formatState.alignCenter} onClick={() => formatText('align-center')}><FormatAlignCenter /></EditorButton>
                        <EditorButton title="Right Align" active={formatState.alignRight} onClick={() => formatText('align-right')}><FormatAlignRight /></EditorButton>
                        <EditorButton title="Undo" onClick={undo}><Undo /></EditorButton>
                        <EditorButton title="Redo" onClick={redo}><Redo /></EditorButton>
                    </>}
                    {!onlyText && <EditorButton title="Edit as HTML" onClick={toggleHtmlMode}>{!isHtmlMode ? <Code /> : <CodeOff />}</EditorButton>}

                </EditorButtonContainer>}
                {isMobile && <EditorButtonContainer sx={{ justifyContent: "flex-end" }}>
                    <EditorButton onClick={handleMobileMenuOpen}><MoreVert /></EditorButton>
                </EditorButtonContainer>}
                <Menu
                    sx={{ width: '200px' }}
                    anchorEl={headingAnchorEl}
                    open={Boolean(headingAnchorEl)}
                    onClose={handleHeadingClose}
                >
                    <MenuItem onClick={() => handleHeadingSelect('heading1')} selected={formatState.heading1}>Heading 1 (H1)</MenuItem>
                    <MenuItem onClick={() => handleHeadingSelect('heading2')} selected={formatState.heading2}>Heading 2 (H2)</MenuItem>
                    <MenuItem onClick={() => handleHeadingSelect('heading3')} selected={formatState.heading3}>Heading 3 (H3)</MenuItem>
                    <MenuItem onClick={() => handleHeadingSelect('paragraph')} selected={formatState.paragraph}>Paragraph (P)</MenuItem>
                </Menu>

                <Popover
                    anchorEl={mobileMenuAnchorEl}
                    open={Boolean(mobileMenuAnchorEl)}
                    onClose={handleMobileMenuClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <Box sx={{ p: 2 }}>
                        <EditorButton onClick={handleHeadingClick}><Title /></EditorButton>
                        <EditorButton onClick={() => formatText('bold')}><FormatBold /></EditorButton>
                        <EditorButton onClick={() => formatText('italic')}><FormatItalic /></EditorButton>
                        <EditorButton onClick={() => formatText('underline')}><FormatUnderlined /></EditorButton>
                        <EditorButton onClick={() => formatText('unordered-list')}><FormatListBulleted /></EditorButton>
                        <EditorButton onClick={() => formatText('ordered-list')}><FormatListNumbered /></EditorButton>
                        <EditorButton onClick={() => formatText('blockquote')}><FormatQuote /></EditorButton>

                        <EditorButton onClick={addLink}><Link /></EditorButton>
                        <EditorButton onClick={undo}><Undo /></EditorButton>
                        <EditorButton onClick={redo}><Redo /></EditorButton>
                        {!onlyText && <EditorButton onClick={toggleHtmlMode}>{!isHtmlMode ? <Code /> : <CodeOff />}</EditorButton>}
                        <EditorButton onClick={() => formatText('align-left')}><FormatAlignLeft /></EditorButton>
                        <EditorButton onClick={() => formatText('align-center')}><FormatAlignCenter /></EditorButton>
                        <EditorButton onClick={() => formatText('align-right')}><FormatAlignRight /></EditorButton>
                    </Box>
                </Popover>

                {/* Textarea for HTML Mode */}
                <textarea
                    ref={textAreaRef}
                    style={{ display: isHtmlMode ? "block" : "none", height: "100px", width: "100%", resize: "none" }}
                    className="editor-textarea"
                />

                {/* ContentEditable Div for Rich Text Mode */}
                <Box
                    placeholder={placeholder}
                    p={2}
                    sx={{ display: !isHtmlMode ? "block" : "none", minHeight: "100px", maxHeight: "400px", width: "100%", overflowY: "auto" }}
                    className="editor-container"
                    contentEditable={true}
                    ref={editorRef}
                    suppressContentEditableWarning={true}
                    onInput={handleInput}
                ></Box>
            </EditorContainer>
        </Paper>
    );
};

export default memo(CustomEditor);
