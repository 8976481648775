import { memo, useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'


import getProjectsApi from "../../../apis/projects.api"
import ReadyToInstallerProjectsUi from "./ReadyToInstallerProjectsUi"
import { callApiAction } from "../../../store/actions/commonAction"
import { PROJECT_FLAGS, PROJECT_STATUS } from "../../../utils/constants/project.constant"
import { getStatusOfProjectFlags } from "../../../utils/helpers/project.helper"





const ReadyToInstallerProjectsController = ({ callBack = () => { }, defaultFilters = {} }) => {
    const dispatch = useDispatch()

    const { user } = useSelector(state => state)

    const title = "Ready To Install Projects "
    const fetchApi = getProjectsApi

    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 25,
        searchable: ['uid'],
        status: [PROJECT_STATUS.CUSTOMER_HOLD, PROJECT_STATUS.UNDER_INSTALLATION],
        ["flag-" + PROJECT_FLAGS.INSTALLATION]: getStatusOfProjectFlags(PROJECT_FLAGS.INSTALLATION).PENDING,
        search: '',
        sort: 'updatedAt',
        all: true,
        ...defaultFilters


    })


    const [loading, setLoading] = useState(false)
    const [list, setList] = useState([])



    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchApi({ ...filters }),
            (response) => {
                setList(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))

    }


    useEffect(() => {
        fetchList()
    }, [filters, defaultFilters])

    const onSchduleInstallation = useCallback((id) => {
        const old = [...list]
        const indexOfItem = old.findIndex(item => item?._id === id)
        if (indexOfItem !== -1)
            old.splice(indexOfItem, 1)

        setList(old)
        callBack()
    }, [list, setList])
    return (
        <>
            <ReadyToInstallerProjectsUi
                title={title}

                filters={filters}
                setFilters={setFilters}
                loading={loading}
                onSchduleInstallation={onSchduleInstallation}
                list={list}
            />

        </>
    )
}
export default ReadyToInstallerProjectsController