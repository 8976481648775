import { memo, useEffect, useMemo, useState } from "react"
import useValidate from "../../store/hooks/useValidator"
import CreateUi from "./CreateUi"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { addUserApi, getUserByIdApi, updateInstallerUserField, updateUserField } from "../../apis/user.api"
import { closeModal } from "../../store/actions/modalAction"
import { useParams } from "react-router-dom"
import { USER_ROLES } from "../../utils/constants/constants"
import { addCompanyApi, getCompanyByIdApi, updateCompanyData } from "../../apis/company.api"
import CreateOfficesUi from "./CreateOfficesUi"
import { addOfficesApi, getOfficesByIdApi, updateOfficesField } from "../../apis/offices.api"
import { addRatingsApi, getRatingsByIdApi, updateRatingsField } from "../../apis/ratings.api"
import CreateRatingsUi from "./CreateRatingsUi"

const CreateRatingsController = ({callBack = () => {},company_id,id }) => {

    const validate = useValidate()
    const dispatch = useDispatch()
    // const params = useParams()

    const title = "Platform Rating"
    const modalKey="ratings"
    const createApi = addRatingsApi
    const updateApi = updateRatingsField
    const getByIdApi = getRatingsByIdApi

    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',
        company_id:company_id,
        id,
        total_rating:null,
        rating:null,
        platform:"",
        logo:'',
        platform_url:""
    })
    const [originalDocument, setOriginalDocument] = useState({


    })


    const validationSchemaForCreate = useMemo(() => ([

        {
            required: true,
            value: fields.platform_url,
            field: 'Platform Url',
        },

        {
            required: true,
            value: fields.total_rating,
            field: 'Platfrom Total Rating',
            
        },
        {
            required: true,
            value: fields.rating,
            field: 'Company Rating',
        },

        {
            required: true,
            value: fields.platform,
            field: ' Platform',
            
        },
        {
            required: true,
            value: fields.logo,
            field: 'Platform Logo',
        },

    ]), [fields])

    const validationSchemaForUpdate = useMemo(() => ([

       
      
        {
            required: true,
            value: fields.platform_url,
            field: 'Platform Url',
        },

        {
            required: true,
            value: fields.total_rating,
            field: 'Platfrom Total Rating',
            
        },
        {
            required: true,
            value: fields.rating,
            field: 'Company Rating',
        },

        {
            required: true,
            value: fields.platform,
            field: ' Platform',
            
        },
        {
            required: true,
            value: fields.logo,
            field: 'Platform Logo',
        },

    ]), [fields])



    const createFunction = async () => {
        const validationResponse = validate(validationSchemaForCreate)

        if (validationResponse === true) {


            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await createApi({...fields,company_id}),
                    async (response) => {

                        await callBack(response)
                        setLoading(false)
                        dispatch(closeModal(modalKey))
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }


    const updateFunction = async () => {
        const validationResponse = validate(validationSchemaForUpdate)
        let updatedData = { id }

        for (let field in fields) {

            if (originalDocument[field] && fields[field] && fields[field] != originalDocument[field]) {
                updatedData[field] = fields[field]
            }
            if (!originalDocument[field]) {
                updatedData[field] = fields[field]
            }
        }

        if (validationResponse === true) {


            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await updateApi(updatedData),
                    async (response) => {

                        await callBack(response, updatedData)
                        setLoading(false)
                        dispatch(closeModal(modalKey))
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }



    const onSubmit = async (e) => {
        e.preventDefault()
        if (id)
            updateFunction()
        else
            createFunction()

    }

    const fetchById = (id) => {
        setLoading(true)
        dispatch(
            callApiAction(
                async () => await getByIdApi({ id }),
                async (response) => {
                    setFields({ ...fields, ...response })
                    setLoading(false)

                },
                (err) => {
                    setFields({ ...fields, err })
                    setLoading(false)

                }
            )
        )
    }

    useEffect(() => {
        if (id)
            fetchById(id)

    }, [id])


    return <CreateRatingsUi isUpdate={id} callBack={callBack} modalKey={modalKey} title={title} loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
}
export default memo(CreateRatingsController)