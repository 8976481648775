import { Box, Grid, Paper, Typography } from "@mui/material";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import ImageComponent from "../../components/ImageComponent";
import moment from "moment";
import { AccessTime, Campaign } from "@mui/icons-material";

const BlogModal = ({ blog }) => {
  return (
    <>
      <CustomDialog id="blog" title="Blog">
        <Box
          p={4}
          sx={{
            border: "2px solid",
            // boxShadow: `0px 4px 6px rgba(0, 0, 0, 0.1)`,
            // bgcolor:"#F5F5F5",
            borderColor: "lightgrey",
            borderRadius: "10px",
          }}
        >
          <Grid container spacing={2} rowSpacing={3}>
            <Grid xs={6} item>
              {blog.thumbnail && (
                <Box
                  sx={{
                    position: "relative",
                    height: "150px",
                  }}
                >
                  <ImageComponent
                    cachePermanent={true}
                    src={blog.thumbnail}
                    sx={{
                      layout: "fill",
                      objectFit: "cover",
                      borderRadius: "5px",
                      width: "530px",
                    }} //height: "40px", width: "87px"
                  />
                </Box>
              )}
              <Typography variant="h5"> {blog?.title ?? "Title"}</Typography>
              <Typography variant="body2" color="textSecondary">
                <Grid container spacing={1} alignItems="center">
                  <Grid item mt={1}>
                    <Campaign sx={{ fontSize: 18 }} />
                  </Grid>
                  <Grid item>
                    <Typography variant="caption" color="textSecondary">
                      Created By:- {blog?.created_by?.name ?? "NA"}
                    </Typography>
                  </Grid>
                </Grid>
              </Typography>
              <Typography variant="body2">
                <Grid container spacing={1} alignItems="center">
                  <Grid item mt={1}>
                    <AccessTime sx={{ fontSize: 18 }} />{" "}
                    {/* Adjust icon size as needed */}
                  </Grid>
                  <Grid item>
                    <Typography variant="caption">
                      {moment(blog?.date).format("Do MMM YYYY") ?? "Date"}
                    </Typography>
                  </Grid>
                </Grid>
              </Typography>
            </Grid>

            <Grid xs={12} item>
              <Box
                sx={{
                  bgcolor: "#F5F5F5",
                  borderColor: "lightgrey",
                  borderRadius: "10px",
                }}
              >
                <Typography
                  p={3}
                  variant="body1"
                  // color="grey.main"
                  sx={{ overflowWrap: "break-word" }}
                  dangerouslySetInnerHTML={{
                    __html: blog?.description,
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* <Typography>
          <Box
            dangerouslySetInnerHTML={{
              __html: blog?.description,
            }}
          ></Box>
        </Typography> */}
      </CustomDialog>
    </>
  );
};
export default BlogModal;
