
import { Autocomplete, ButtonBase, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Skeleton, Typography, styled } from "@mui/material"
import { Box } from "@mui/system"
import { useSelector } from "react-redux"


import PaddingBoxInDesktop from "../../../components/layouts/PaddingBoxInDesktop"

import { findObjectKeyByValue, titleCase } from "../../../utils/helpers/helper"
import SalesPersonFilterDropDown from "../../../components/filterdropdowns/SalesPersonFilter"
import TeamFilterDropDown from "../../../components/filterdropdowns/TeamFilterDropDown"
import FilterButton from "../filters/FilterButton"
import { LEAD_STATUS } from "../../../utils/constants/lead.constant"
import { StyledSearchBar } from "../../../components/inputs/SearchBar"
import LeadTimeFilters from "../filters/LeadTimeFilter"
import { useMemo } from "react"
import { CenteredBox } from "../../../components/layouts/OneViewBox"
import NoDataComponent from "../../../components/layouts/NoDataComponent"
import { AccountTree, Paid, SolarPower } from "@mui/icons-material"
import ListController from "../ListController"



const FilterTitleBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }

}))
const FiltersBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",

    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }
}))
const StepCountBox = styled(Paper)(({ theme, color, index, active }) => ({
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2),
    transition: "all 0.2s linear",
    border: "1px solid " + color,
    minWidth: "250px",
    marginLeft: index == 0 ? 0 : theme.spacing(2),
    borderLeft: active ? ('10px solid ' + color) : undefined,
    transform: active ? "scale(1.01)" : undefined,
    ":hover": {
        borderLeft: '10px solid ' + color
    },
    "::after": {
        content: "' '",
        position: "absolute",
        top: "0px",
        left: '0px',
        height: '100%',
        width: '100%',
        background: active ? color : undefined,
        opacity: 0.2
    }

}))
const StepCountItem = ({ title, color, onClick, lead, amount, size, index, active }) => {
    return <StepCountBox onClick={onClick} variant="outlined" color={color} active={active} index={index} component={ButtonBase} >

        <Box>
            <Typography variant="h5" color={color} >
                {lead}
            </Typography>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="flex-start" flex={1} ml={2}>
            <Typography mb={1} color={color} variant="body2">
                {title}
            </Typography>
            <Box width="100%" >
                <Grid container>
                    <Grid item xs={6} >
                        <Typography mb={1} color={color} variant="subtitle2" textAlign="left" lineHeight="00%" >
                            <Paid fontSize="inherit" />    {Number(amount??0).toFixed(2)}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} >
                        <Typography mb={1} color={color} variant="subtitle2" textAlign="left" lineHeight="00%" >
                            <SolarPower fontSize="inherit" />     {Number(size??0).toFixed(1)}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>



        </Box>
    </StepCountBox>
}
const LeadPipelineViewUi = ({ title, filters, setFilters, list, modal, loading, selecteStep, setSelectedStep }) => {

    const user = useSelector(state => state.user)
    const allowedPipelines = useMemo(() => {
        return user.data?.pipelines?.lead;
    }, [user.data?.pipelines?.lead])
    return (
        <>
            {!modal && <Box mb={2}>
                <Paper elevation={0} sx={{ width: "100%", p: 2 }}  >
                    <Box p={2}>
                        {<FilterTitleBox >
                            <Typography variant="h5" mb={2} >{title}</Typography>

                        </FilterTitleBox>}


                        <FiltersBox mt={1} >

                            <PaddingBoxInDesktop sx={{ display: "flex" }}>
                                <LeadTimeFilters filters={filters} setFilters={setFilters} forPannel />
                            </PaddingBoxInDesktop>

                            {<PaddingBoxInDesktop sx={{ display: "flex", flex: 1, flexDirection: "column" }} >
                                <FiltersBox sx={{ width: "100%" }} >
                                    <PaddingBoxInDesktop sx={{ display: "flex", flex: 1 }} pl={3}>
                                        <TeamFilterDropDown
                                            key={filters?.refreshKey}
                                            value={filters?.team}
                                            returnFullObj
                                            id={'teams-leads'}
                                            filters={filters}
                                            setFilters={setFilters}
                                        />
                                    </PaddingBoxInDesktop>
                                    {<PaddingBoxInDesktop sx={{ display: "flex", flex: 1 }} pl={3}>
                                        <SalesPersonFilterDropDown
                                            value={filters?.assigned_sales_person}
                                            returnFullObj
                                            id={'sales-leads'}
                                            filters={filters}
                                            setFilters={setFilters}
                                            key={filters.team?._id + filters?.refreshKey}
                                        />
                                    </PaddingBoxInDesktop>}

                                </FiltersBox>
                                <FiltersBox sx={{ width: "100%" }} mt={2} >
                                    <PaddingBoxInDesktop sx={{ display: "flex", flex: 1 }} pl={3} >
                                        <Autocomplete
                                            multiple
                                            key={filters?.refreshKey}
                                            size="small"
                                            value={filters?.lead_status?.map?.(item => ({ _id: item, label: findObjectKeyByValue(item, LEAD_STATUS) }))}

                                            onChange={(e, newVal) => {
                                                console.log(newVal)
                                                setFilters({ ...filters, lead_status: newVal ? newVal?.map((item) => item?._id) : [] })
                                            }}
                                            options={[ ...Object.keys(LEAD_STATUS).map((key) => ({ label: titleCase(key), _id: LEAD_STATUS[key] }))]}

                                            sx={{ width: "100%", display: "flex", "*": { display: "flex", justifyContent: "center" } }}
                                            renderInput={(params) => <StyledSearchBar placeholder="Select Status" {...params} size="small" />}
                                        />
                                    </PaddingBoxInDesktop>
                                </FiltersBox>
                            </PaddingBoxInDesktop>}
                        </FiltersBox>

                        <Box display="flex" justifyContent="flex-end" mt={1}>
                            <FilterButton notApplicableFilters={['step']} defaultFilters={filters} setDefaultFilters={setFilters} />
                        </Box>
                    </Box>
                </Paper>
            </Box>}
            <Box mb={3}>
                <Paper elevation={0} sx={{ width: "100%", padding: modal ? 0 : 2 }} >
                    <FormControl fullWidth margin="dense">
                        <InputLabel id="pipeline">Select Pipeline</InputLabel>
                        <Select
                            label="Select Pipeline"
                            labelId="pipeline"
                            id="outcomee-select" value={filters.pipeline_id} fullWidth onChange={(e) => setFilters({ ...filters, pipeline_id: e.target.value, step: null })}>
                            {
                                allowedPipelines?.map((item) => {
                                    return <MenuItem key={item?.title} value={item?._id} >
                                        {item?.title}
                                    </MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>

                    <Box mt={2}>
                        {filters?.pipeline_id ? <Box >
                            {
                                !loading && list?.length == 0 && <CenteredBox>
                                    <NoDataComponent message="No Steps Found" />
                                </CenteredBox>
                            }
                            {
                                loading && <Grid container spacing={2} >
                                    {
                                        [0, 1, 2, 3, 4]?.map(item => <Grid item xs={6} md={3} key={item?._id}>
                                            <Skeleton variant="rounded" height="100px" width="100%" />
                                        </Grid>)
                                    }
                                </Grid>
                            }
                            {
                                !loading && list?.length > 0 && <Box sx={{ width: "100%", display: "flex", overflowX: "auto" }} py={3} >
                                    {
                                        list?.map((item, index) =>
                                            <StepCountItem
                                                active={selecteStep?._id == item?._id}
                                                onClick={() => setSelectedStep(item)}
                                                index={index}
                                                color={item?.color}
                                                title={item?.title}
                                                lead={item?.counts?.total_lead ?? 0}
                                                amount={item?.counts?.total_amount ?? 0}
                                                size={item?.counts?.total_size ?? 0}
                                            />
                                        )
                                    }
                                </Box>
                            }
                        </Box>

                            : <CenteredBox>
                                <NoDataComponent Icon={AccountTree} iconVariant="h4" variant="h6" message="Select Pipeline" />

                            </CenteredBox>
                        }
                    </Box>
                </Paper >
            </Box >
            {selecteStep && <Box mb={3}>
                <Paper elevation={0} sx={{ width: "100%", padding: modal ? 0 : 2 }} >


                    <ListController key={selecteStep?._id} defaultFilters={{ ...filters, step: selecteStep?._id }} modal={true} />
                </Paper >
            </Box >}

        </>
    )
}
export default LeadPipelineViewUi