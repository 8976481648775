
import { Button, ButtonGroup, Paper, Typography, styled } from "@mui/material"
import { Box } from "@mui/system"
import { useSelector } from "react-redux"
import SubmitButton from "../../components/button/SubmitButton"
import DataTable from "../../components/tables/DataTable"

import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxInDesktop"
import AsynSearchBar from "../../components/inputs/AsynSearchBar"
import { moduleAccessCondition } from "../../utils/helpers/helper"
import MODULES from "../../utils/constants/module.constants"
import { CustomTab, CustomTabs } from "../../components/layouts/common/Tabs"
import { UploadCsvButton } from "./UploadCsvController"



const FilterTitleBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }

}))
const FiltersBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",

    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }
}))


const ListUi = ({ title, filters, setFilters, list, loading, onCreateBtnClick, columns, productCategories, fetchCsv, exportLoading,callBack}) => {

    const user = useSelector(state => state.user)

    return (
        <>

            <Box mb={3}>
                <Paper elevation={0} sx={{ width: "100%", padding: 4 }} >
                    <Box mb={2} >
                        <FilterTitleBox>
                            {<Typography variant="h5" mb={2} >{title}</Typography>}
                            {<Box />}
                            <Box mb={2}>
                                {productCategories && Array.isArray(productCategories) && moduleAccessCondition(user, [MODULES.CREATE_PRODUCT], []) && <SubmitButton variant="contained" onClick={onCreateBtnClick} title={"Add"} />}
                            </Box>

                        </FilterTitleBox>

                        <CustomTabs onChange={(e, val) => { setFilters({ ...filters, productCategory: val }) }} value={filters.productCategory}>
                            {
                                productCategories && Array.isArray(productCategories) && productCategories?.map?.((item) => <CustomTab value={item?._id} label={item?.title}></CustomTab>)
                            }


                        </CustomTabs>
                        <FiltersBox mt={3} >
                            <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-start" }}  >
                                <ButtonGroup disableElevation size="small" variant="outlined" >
                                    <Button onClick={() => setFilters({ ...filters, deleted: null })} variant={filters.deleted == null ? "contained" : "outlined"} >Active</Button>
                                    <Button onClick={() => setFilters({ ...filters, deleted: true })} variant={filters.deleted == true ? "contained" : "outlined"}>Deleted</Button>
                                </ButtonGroup>
                            </PaddingBoxInDesktop>
                            <PaddingBoxInDesktop mb={2} sx={{ display: "flex", justifyContent: "flex-end" }} pl={3} >

                                <AsynSearchBar
                                    fullWidth
                                    title="Search Title/Item Code" size="small" placeholder={"Search Title/Item Code"}
                                    defaultValue={filters.search}
                                    onChange={(changedVal) => { setFilters({ ...filters, search: changedVal }) }}
                                />

                            </PaddingBoxInDesktop>



                        </FiltersBox>
                        <Box display="flex">
                            {productCategories && Array.isArray(productCategories) && moduleAccessCondition(user, [MODULES.CREATE_PRODUCT], []) && <Box mr={2}>
                             {  <UploadCsvButton product_category={filters.productCategory} callBack={callBack} />}
                            </Box>}
                            <Box>
                                <SubmitButton size="small" loading={exportLoading} title="Export CSV" onClick={fetchCsv} />
                            </Box>
                        </Box>
                    </Box>

                    <Box sx={{ minHeight: "300px" }}>
                        <DataTable columns={columns} rows={list.result ? list.result : []} count={list.total ?? 0} filters={filters} setFilters={setFilters} loading={loading} />
                    </Box>
                </Paper>
            </Box>

        </>
    )
}
export default ListUi