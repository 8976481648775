import { useCallback, useEffect, useReducer, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { callApiAction } from "../../../store/actions/commonAction"
import { getProjectDocumentsApi } from "../../../apis/projects.api"
import { CenteredBox, TitleBox } from "../../../components/layouts/common/boxes"
import { Box, CircularProgress, Grid, IconButton, Tooltip, Typography } from "@mui/material"
import ProjectDocumentRow from "./ProjectDocumentRow"
import CreateDocumentButton from "./CreateDocumentButton"
import CreateJobPackButton from "../jobpack/CreateJobPackButton"
import { PROJECT_DOCUMENT_ACTION } from "../../../utils/constants/project.constant"
import { AddCircle } from "@mui/icons-material"
import { moduleAccessCondition } from "../../../utils/helpers/helper"
import MODULES from "../../../utils/constants/module.constants"


const projectDocumentReducer = (state, action) => {
    switch (action.type) {

        case PROJECT_DOCUMENT_ACTION.ADD_ITEM: {
            const arr = [...state]
            arr.unshift(action.data)
            return arr
        }

        case PROJECT_DOCUMENT_ACTION.SET_ITEM: {
            const arr = [...state]
            const { id, files } = action
            const index = arr.findIndex((val) => val._id == id)
            const oldData = arr[index]
            arr[index] = { ...oldData, files }
            return arr
        }
        case PROJECT_DOCUMENT_ACTION.SET_DATA: return action.data
        default: return [...state]
    }
}

const DocumetTitleComponent = ({ project_id, dispatch,disabled }) => {

    const { user } = useSelector(state => state)

    return <Box display="flex" justifyContent="space-between" alignItems={"center"}>
        <Typography lineHeight="100%">
            Documents
        </Typography>


        {moduleAccessCondition(user, [MODULES.CREATE_PROJECT_DOCUMENTS]) && !disabled
            && <CreateDocumentButton disabled={disabled} dispatch={dispatch} project_id={project_id} />}
    </Box>
}

const ProjectDocumentMain = ({ project_id, defaultList, name, setDefaultList,titleBoxProps={} ,disabledEdit}) => {

    const dispatch = useDispatch()
    const { user } = useSelector(state => state)

    const [loading, setLoading] = useState(false)
    const [data, dispatchdocument] = useReducer(projectDocumentReducer, defaultList ?? [])

    const fetchDocuments = useCallback(() => {

        setLoading(true)
        dispatch(callApiAction(
            async () => await getProjectDocumentsApi({ project_id }),
            (response) => {
                dispatchdocument({ type: PROJECT_DOCUMENT_ACTION.SET_DATA, data: response })

                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))

    }, [])

    useEffect(() => {
        if (data.length == 0)
            fetchDocuments()
    }, [])
    useEffect(() => {

        if (data.length > 0) {
            
            setDefaultList(data)
        }
    }, [data, defaultList])
    if (!moduleAccessCondition(user, [MODULES.VIEW_PROJECT_DOCUMENTS]))
        return <></>
    return <>
        <TitleBox
            title={<DocumetTitleComponent disabled={disabledEdit} documents={data} name={name} dispatch={dispatchdocument} project_id={project_id} />}
            {...titleBoxProps}
            >
            {
                loading && data.length == 0 ? <CenteredBox sx={{ height: "100%", width: "100%" }}>
                    <CircularProgress />
                </CenteredBox> : <>
                    {
                        data?.sort((a, b) => a?.name?.localeCompare(b?.name)).map((item) => <ProjectDocumentRow disabled={disabledEdit} key={item?._id} files={item?.files} updatedAt={item.updatedAt} id={item?._id} name={item.name} type={item.type} dispatch={dispatchdocument} />)
                    }


                </>
            }
        </TitleBox>



    </>


}
export default ProjectDocumentMain