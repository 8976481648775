import { memo, useEffect, useMemo, useState } from "react"
import useValidate from "../../store/hooks/useValidator"
import CreateUi from "./CreateUi"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { closeModal } from "../../store/actions/modalAction"
import { useParams } from "react-router-dom"

import { addSourceApi, getSourceByIdApi, updateSourceField } from "../../apis/sources.api"
import { MODAL_KEYS } from "../../utils/constants/modal.constant"
import { createActivityLogsApi } from "../../apis/lead.api"

const CreateController = ({ modalKey = MODAL_KEYS.ADD_ACTIVITY_LOGS, project_id, callBack }) => {
    const validate = useValidate()
    const dispatch = useDispatch()

    const title = "Activity Log "
    const createApi = createActivityLogsApi


    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',
        project_id,
        activity_id: null,
        parent_activity: null,
        remarks: '',

    })


    const validationSchemaForCreate = useMemo(() => ([

        {
            required: true,
            value: fields.activity_id,
            field: 'Activity Outcome ',
        }
    ]), [fields])




    const createFunction = async () => {
        const validationResponse = validate(validationSchemaForCreate)

        if (validationResponse === true) {

            const creatableData = {}
            for (let item in fields) {
                if (typeof fields[item] == "object")
                    creatableData[item] = fields[item]?._id
                else
                creatableData[item] = fields[item]
            }
            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await createApi(creatableData),
                    async (response) => {

                        await callBack(response)
                        setLoading(false)
                        dispatch(closeModal(modalKey))
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }





    const onSubmit = async (e) => {
        e.preventDefault()

        createFunction()

    }



    return <CreateUi title={title} modalKey={modalKey} loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
}
export default memo(CreateController)