import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid,
  MenuItem,
} from "@mui/material";
import { memo } from "react";
import { useSelector } from "react-redux";

import CustomInput from "../../components/inputs/CustomInput";
import { USER_ROLES } from "../../utils/constants/constants";
import { findNameByRole, titleCase } from "../../utils/helpers/helper";

import CustomDialog from "../../components/layouts/common/CustomDialog";
import { CenteredBox } from "../../components/layouts/OneViewBox";

import InstallerFields from "../installer/InstallerFields";
import getTeamApi from "../../apis/team.api";
import FileInput from "../../components/inputs/FileInput";
import CustomEditor from "../../components/inputs/CustomEditor";

const CreateUi = ({
  modalKey,
  title,
  isInstaller,
  isUpdate,
  fields,
  setFields,
  loading,
  onSubmit,
  placeholder,
}) => {
  const { user } = useSelector((state) => state);

  let allowedRoles = [
    ...Object.keys(USER_ROLES).map((key) => ({
      label: titleCase(key),
      _id: USER_ROLES[key],
    })),
  ];

  if (user.data.role === USER_ROLES.partner_admin) {
    allowedRoles = [];
    Object.keys(USER_ROLES).forEach((key) => {
      if (USER_ROLES[key] != USER_ROLES.admin)
        allowedRoles.push({ label: titleCase(key), _id: USER_ROLES[key] });
    });
  }

  return (
    <>
      <CustomDialog
        loading={loading}
        id={modalKey}
        err={fields.err}
        onSubmit={onSubmit}
        title={`${isUpdate ? "Update" : "Create"} ${title}`}
        closeText="Close"
        confirmText={`${isUpdate ? "Update" : "Create"}`}
      >
        {loading ? (
          <CenteredBox>
            <CircularProgress />
          </CenteredBox>
        ) : (
          <>
            <Grid container spacing={2} columnSpacing={5}>
              <Grid item xs={12} md={isUpdate ? 12 : 6}>
              <FileInput
                  onlyImage={true}
                  defaults={fields.logo ? [fields.logo] : []}
                  title="Logo"
                  subTitle="Only .png with aspect ratio of 13/6"
                  accept=".png"
                  onChange={(newUrl) => {
                    setFields({ ...fields, logo: newUrl });
                  }}
                />
                <CustomInput
                  autoFocus={true}
                  disabled={loading}
                  value={fields.name}
                  onChange={(e) =>
                    setFields({ ...fields, err: "", name: e.target.value })
                  }
                  type="text"
                  label={"Company Name*"}
                />
                <CustomInput
                  disabled={loading}
                  value={fields.short_name}
                  onChange={(e) =>
                    setFields({
                      ...fields,
                      err: "",
                      short_name: e.target.value,
                    })
                  }
                  type="text"
                  label={"Company Short Name*"}
                />

                {
                  <CustomInput
                    disabled={loading}
                    value={fields.company_phone}
                    onChange={(e) =>
                      setFields({
                        ...fields,
                        err: "",
                        company_phone: e.target.value,
                      })
                    }
                    type="tel"
                    label={"Company Phone No*"}
                  />
                }
                <CustomInput
                  disabled={loading}
                  value={fields.company_email}
                  onChange={(e) =>
                    setFields({
                      ...fields,
                      err: "",
                      company_email: e.target.value,
                    })
                  }
                  type="email"
                  label={"Company Email*"}
                />
                <CustomInput
                  disabled={loading}
                  value={fields.abn_no}
                  onChange={(e) =>
                    setFields({
                      ...fields,
                      err: "",
                      abn_no: e.target.value,
                    })
                  }
                  type="text"
                  label={"Abn Number*"}
                />
                <CustomInput
                  disabled={loading}
                  value={fields.company_website}
                  onChange={(e) =>
                    setFields({
                      ...fields,
                      err: "",
                      company_website: e.target.value,
                    })
                  }
                  type="text"
                  label={"Company Website*"}
                />
                <CustomEditor
                  title={"Company Description:"}
                  onlyText
                  placeholder="Write Company Description..."
                  value={fields.description}
                  onChange={(val) => {
                    setFields({ ...fields, description: val });
                  }}
                />
              
                <Box mt={2}>
                  <CustomEditor
                    title={"Ahead of Competition:"}
                    onlyText
                    placeholder="Write Company Ahead of Competition..."
                    value={fields.ahead_of_competition}
                    onChange={(val) => {
                      setFields({ ...fields, ahead_of_competition: val });
                    }}
                  />
                </Box>
                {/* <Box mt={2}>
                  <CustomEditor
                    placeholder="Write Company Professional..."
                    value={fields.professional}
                    onChange={(val) => {
                      setFields({ ...fields, professional: val });
                    }}
                  />
                </Box> */}
                {/* <Box mt={2}>
                  <CustomEditor
                    placeholder="Write Company Convenience..."
                    value={fields.convenience}
                    onChange={(val) => {
                      setFields({ ...fields, convenience: val });
                    }}
                  />
                </Box> */}

                {/* <Box mt={2}>
                  <CustomEditor
                    placeholder="Write Company Unique..."
                    value={fields.unique}
                    onChange={(val) => {
                      setFields({ ...fields, unique: val });
                    }}
                  />
                </Box> */}
                <Box mt={2}>
                  <CustomEditor
                  title={"CEO's Message:"}
                    placeholder="Write Company CEO's Message..."
                    value={fields.ceo_message}
                    onChange={(val) => {
                      setFields({ ...fields, ceo_message: val });
                    }}
                  />
                </Box>
                <Box mt={2}>
                  <CustomEditor
        onlyText
                  title="Payment Details"
                    placeholder="Write Payment Details..."
                    value={fields.payment_details}
                    onChange={(val) => {
                      setFields({ ...fields, payment_details: val });
                    }}
                  />
                </Box>
              </Grid>
              {!isUpdate && (
                <Grid item xs={12} md={6}>
                  <CustomInput
                    autoFocus={true}
                    disabled={loading}
                    value={fields.admin_name}
                    onChange={(e) =>
                      setFields({
                        ...fields,
                        err: "",
                        admin_name: e.target.value,
                      })
                    }
                    type="text"
                    label={"Admin Name*"}
                  />
                  <CustomInput
                    autoFocus={true}
                    disabled={loading}
                    value={fields.admin_email}
                    onChange={(e) =>
                      setFields({
                        ...fields,
                        err: "",
                        admin_email: e.target.value,
                      })
                    }
                    type="text"
                    label={"Admin Email*"}
                  />
                  <CustomInput
                    autoFocus={true}
                    disabled={loading}
                    value={fields.admin_password}
                    onChange={(e) =>
                      setFields({
                        ...fields,
                        err: "",
                        admin_password: e.target.value,
                      })
                    }
                    type="text"
                    label={"Admin Password*"}
                  />
                  <CustomInput
                    autoFocus={true}
                    disabled={loading}
                    value={fields.admin_phone}
                    onChange={(e) =>
                      setFields({
                        ...fields,
                        err: "",
                        admin_phone: e.target.value,
                      })
                    }
                    type="text"
                    label={"Admin Phone*"}
                  />
                </Grid>
              )}
            </Grid>
          </>
        )}
      </CustomDialog>
    </>
  );
};
export default memo(CreateUi);
