
import { getSalesReportApi } from "../../apis/lead.api"
import { actions } from "../../utils/constants/constants"
import { callApiAction } from "./commonAction"


export const fetchLeaderBoardData = (data={}, onSuccess, onError) => {
    return async (dispatch, getState) => {
        const filters = {...getState().leaderboard.filters,...(data??{})}
        const filtersToBePassed = {}

        for (let item in { ...filters }) {
            if (filters[item] && typeof filters[item] == "object" && filters[item]?._id) {
                filtersToBePassed[item] = filters[item]?._id
            } else if (filters[item]) {
                filtersToBePassed[item] = filters[item]
            }
        }
        dispatch(callApiAction(
            async () => await getSalesReportApi(filtersToBePassed),
            (response) => {
                if (data.csv != true)
                    dispatch({ type: actions.SET_LEADER_BOARD_DATA, value: response })
                onSuccess(response)
            },
            (err) => {
                onError(err)
            },
            data.csv == true
        ))
    }


}



export const updateLeaderBoardFilter = (filter, value) => {

    return async (dispatch, getState) => {
        const filters = { ...(getState()?.leaderboard?.filters ?? {}) }
        filters[filter] = value
        dispatch({ type: actions.SET_LEADER_BOARD_FILTERS, value: filters })
    }
}

export const updateLeaderBoardFilters = (filters) => {

    return ({ type: actions.SET_LEADER_BOARD_FILTERS, value: filters })
}