import { ButtonBase, Chip, CircularProgress, Collapse, Divider, Grid, IconButton, Paper, Box, Tooltip, Typography, styled, Alert } from "@mui/material"
import { useState } from "react"
import CustomDialog from "../../../components/layouts/common/CustomDialog"
import useInovice from "../../projects/invoice/useInvoice"
import { useDispatch, useSelector } from "react-redux"
import { AssignmentReturned, Cancel, CheckCircle, Download, DownloadDoneRounded, KeyboardArrowDown, KeyboardArrowUp, MoneyOff, Share } from "@mui/icons-material"
import DeleteInvoiceButton from "../../projects/invoice/DeleteInvoiceButton"
import AddRemarksToInvoiceButton from "../../projects/invoice/AddRemarksToInvoiceButton"
import FileDownloadComponent from "../../../components/FileDownloadComponent"
import { SNACK_BAR_VARIETNS } from "../../../utils/constants/constants"
import { INVOICE_TYPE, PAYMENT_OPTION } from "../../../utils/constants/invoice.constant"
import { findObjectKeyByValue, moduleAccessCondition, titleCase } from "../../../utils/helpers/helper"
import moment from "moment"
import CreateSystemInvoiceButton from "../../projects/invoice/CreateSystemInvoiceButton"
import NoDataComponent from "../../../components/layouts/NoDataComponent"
import { CenteredBox } from "../../../components/layouts/OneViewBox"
import PayInvoiceButton from "../../projects/invoice/PayInvoiceButton"
import endpoints from "../../../apis/endpoints"
import UpdateInvoiceButton from "../update/UpdateInvoiceButton"
import { isAllowedToEdit } from "../../../utils/helpers/invoice.helper"
import MODULES from "../../../utils/constants/module.constants"
import { openModal } from "../../../store/actions/modalAction"
import MessageDilog from "../../../components/MessageDilog"
import DownloadInvoiceModal from "./DownloadInvoiceModal"
import { MODAL_KEYS } from "../../../utils/constants/modal.constant"

const Container = styled(Box)(({ theme, invoiceColor }) => ({
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    border: "1px solid " + invoiceColor?.color,
    alignItems: "center",
    position: "relative",
    overflow: "hidden",
    display: "flex",
    borderRadius: theme.shape.borderRadius * 4,
    "::after": {
        content: "' '",
        background: invoiceColor?.color,
        opacity: "0.1",
        position: "absolute",
        height: "100%",
        width: "100%",
        top: "0px",
        left: "0px",
        zIndex: 0
    }


}))
const PaymentDetailsContainer = styled(Paper)(({ theme, paid }) => ({
    width: "100%",
    zIndex: 111,

    background: '#f2f2f2' + "!important",
    borderRadius: theme.shape.borderRadius * 2,



}))
const SendOrDownloadInvoiceButton = ({ disabled, id, email, invoiceName }) => {
    const { user } = useSelector(state => state)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    const onSendClick = () => {
        dispatch(openModal(<DownloadInvoiceModal invoiceId={id} email={email} invoiceName={invoiceName} />, 'sm', undefined, MODAL_KEYS.INVOICE_DOWNLOAD))
    }
    
    if (loading) {
        return <CircularProgress />
    }
    if (disabled)
        return <></>
    if (email)
        return <Tooltip arrow  title="Share to Customer">
            <IconButton onClick={onSendClick}>
                <Share />
            </IconButton>
        </Tooltip>

    return <Tooltip arrow  title="Download Invoice">
        <IconButton onClick={onSendClick}>
            <Download />
        </IconButton>
    </Tooltip>
}
const InvoiceDetailUi = ({ modalKey, title, data, setData, loading, callBack }) => {

    const { user } = useSelector(state => state)

    const invoiceColor = useInovice(data ?? {})

    return <CustomDialog
        title={title}
        id={modalKey}

    >
        {loading && <CenteredBox><CircularProgress /></CenteredBox>}
        {
            data && data?._id &&
            <>
            {
                data?.disabled && <Alert severity="error">
                    This invoice is disabled
                    <Box>
                    <Typography variant="caption" >This invoice will not count for any further calculations, it is here just to show past actions</Typography>
                    </Box>
                </Alert>
            }
                {data?.installer_id && <Typography textTransform="capitalize" mb={3}>Installer: {data?.installer_id?.name}</Typography>}
                {data?.supplier_id && <Typography textTransform="capitalize" mb={3} >Supplier: {data?.supplier_id?.name}</Typography>}
                <Container id="invoice-detail" p={3} pb={1} invoiceColor={invoiceColor}>
                    <Box sx={{ width: "100%" }} >
                        <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", zIndex: 1 }} mb={2}>
                            <Box sx={{ display: "flex" }}>
                                <Typography variant="body1" > {data.invoice_no}</Typography>
                                <Typography variant="body1" > ({moment(data.creation_date).format("DD MMM YYYY")})</Typography>
                            </Box>
                            <Box display="flex" flexDirection="column" alignItems="flex-end" >
                            <Chip size="small" color="info" label={findObjectKeyByValue(data.type, INVOICE_TYPE)} />
                                <Typography variant="caption">{(data.type==INVOICE_TYPE.INCENTIVES_INVOICE?`${data.incentive_type}`:"")}</Typography>
                            </Box>
                            


                        </Box>
                        <Box sx={{ width: "100%" }}>
                            <Typography variant="caption" >Due Date</Typography>
                            <Typography variant="caption" > ({moment(data.creation_date).format("DD MMM YYYY")})</Typography>
                        </Box>
                        {data.remarks && <Box mt={3} elevation={0} component={Paper} p={2} sx={{ width: "100%", display: "flex", background: "white", position: "relative", zIndex: 1 }} mb={2}>
                            <Typography variant="caption" align="center" >Remarks:</Typography>
                            <Typography variant="caption" align="center" >{data.remarks}</Typography>

                        </Box>}

                        <Box mt={3} elevation={0} component={Paper} p={2} sx={{ width: "100%", display: "flex", background: "white", position: "relative", zIndex: 1 }} mb={2}>

                            <Grid container>
                                <Grid item xs={4}>
                                    <Box sx={{ width: "100%", background: "", zIndex: 1 }} >
                                        <Typography variant="h6" align="center" > {data.amount}</Typography>
                                        <Typography variant="subtitle2" align="center" >Total Amount</Typography>
                                    </Box>

                                </Grid>
                                <Grid item xs={4}>
                                    <Box sx={{ width: "100%", background: "", zIndex: 1, borderLeft: 1 }} >
                                        <Typography variant="h6" align="center" > {data.paid_amount}</Typography>
                                        <Typography variant="subtitle2" align="center" >Paid Amount</Typography>
                                    </Box>

                                </Grid>
                                <Grid item xs={4}>
                                    <Box sx={{ width: "100%", background: "", zIndex: 1, borderLeft: 1 }} >
                                        <Typography variant="h6" align="center" > {data.amount - data.paid_amount}</Typography>
                                        <Typography variant="subtitle2" align="center" >Remained Amount</Typography>
                                    </Box>

                                </Grid>

                            </Grid>


                        </Box>
                        <Box >
                            <Typography variant="body1" fontWeight="bold" mt={1}>Payment Details</Typography>
                            {
                                data.payments && Array.isArray(data.payments) && data.payments.map((item) => {
                                    return <Box elevation={0} component={Paper} sx={{ borderBottom: 1, borderBottomColor: "divider", zIndex: 11, position: "relative" }} key={item._id} mb={2} p={2} >
                                        <Grid container spacing={3} >
                                            <Grid item xs={6} md={4} >
                                                <Typography variant="caption" display="block" fontWeight="600" align="center" > {item.amount}</Typography>
                                                <Typography variant="caption" sx={{ display: "block" }} align="center" > Amount Paid</Typography>
                                            </Grid>
                                            <Grid item xs={6} md={4} >
                                                <Typography variant="caption" display="block" fontWeight="600" align="center" > {titleCase(findObjectKeyByValue(item.payment_by, PAYMENT_OPTION))}</Typography>
                                                <Typography variant="caption" sx={{ display: "block" }} align="center" >Payment Type</Typography>
                                            </Grid>
                                            <Grid item xs={6} md={4} >
                                                <Typography variant="caption" display="block" fontWeight="600" align="center" > {item.verified ? <CheckCircle fontSize="inherit" color="success" /> : <Cancel fontSize="inherit" color="error" />}</Typography>
                                                <Typography variant="caption" sx={{ display: "block" }} align="center" >Verification Status</Typography>
                                            </Grid>

                                            <Grid item xs={6}  md={4}  >
                                                <Typography variant="caption" display="block" fontWeight="600" align="center" >{moment(item.payment_date).format("DD MMM YYYY")}</Typography>
                                                <Typography variant="caption" sx={{ display: "block" }} align="center" > Transaction Date</Typography>
                                            </Grid>

                                            <Grid item xs={6}  md={4}  >
                                                <Typography variant="caption" display="block" fontWeight="600" align="center" > {item.transaction_id}</Typography>
                                                <Typography variant="caption" sx={{ display: "block" }} align="center" >Transaction Id</Typography>
                                            </Grid>
                                           {item?.transaction_proof && item?.transaction_proof!='' && <Grid item xs={6}  md={4}  >
                                                <Typography variant="caption" display="block" fontWeight="600" align="center" ><FileDownloadComponent src={item?.transaction_proof}  /></Typography>
                                                <Typography variant="caption" sx={{ display: "block" }} align="center" >Transaction Proof</Typography>
                                            </Grid>}
                                        </Grid>

                                        {item.is_in_bulk &&
                                            <>
                                                <Divider />
                                                <Typography variant="caption" sx={{ display: "block" }} align="center" >In Bulk</Typography>
                                            </>
                                        }
                                    </Box>
                                })
                            }
                            {
                                data.payments && data.payments.length == 0 && <CenteredBox elevation={0} mb={3} sx={{ position: "relative", zIndex: 1 }} component={Paper} >
                                    <NoDataComponent iconVariant="h3" variant="body1" Icon={MoneyOff} message="No Transactions Available" />
                                </CenteredBox>
                            }
                        </Box>
                    </Box>
                    <Box sx={{ width: "100%", position: "relative", zIndex: 11 }}>
                        {!invoiceColor.isVarificationPending && !data.disabled && <PayInvoiceButton invoice={data} callBack={callBack} />}

                        <Box sx={{ display: "flex", width: "100%", zIndex: 1, justifyContent: "space-between" }} >
                            {<Box>
                                {
                                    (data.type == INVOICE_TYPE.DEPOSIT || data.type == INVOICE_TYPE.CUSTOMER_INVOICE || data.type == INVOICE_TYPE.EXTRAA_CHARGE || data.type == INVOICE_TYPE.GENERAL || data.type == INVOICE_TYPE.CUSTOMER_INVOICE) &&
                                    // <FileDownloadComponent direct heades src={endpoints.invoiceDownload + data._id} />
                                    <SendOrDownloadInvoiceButton id={data._id} invoiceName={data?.invoice_no} />
                                }
                                {
                                    (data.type == INVOICE_TYPE.DEPOSIT || data.type == INVOICE_TYPE.CUSTOMER_INVOICE) && <>

                                        <SendOrDownloadInvoiceButton disabled={data.disabled} id={data._id} email={true} />


                                        {data.type == INVOICE_TYPE.CUSTOMER_INVOICE && moduleAccessCondition(user, [MODULES.CREATE_INVOICE]) && <CreateSystemInvoiceButton
                                            recreate
                                            customer_name={data.customer_name}
                                            project_id={data.project}


                                        />


                                        }
                                    </>
                                }
                                {!invoiceColor.isVarificationPending && !data.disabled && isAllowedToEdit(data.type) && <UpdateInvoiceButton params={data} setParams={setData} />}
                                {!invoiceColor.isVarificationPending && !data.disabled && <DeleteInvoiceButton icon id={data._id} status={data.status} callBack={() => { callBack(data?.project, null, null) }} />}
                                <AddRemarksToInvoiceButton id={data._id} callBack={(remarks) => { setData({ ...data, remarks }) }} />
                                {
                                    data?.custom_invoice && data?.custom_invoice != '' && <Tooltip arrow  title="Downlaod Receipt" ><FileDownloadComponent heades src={data.custom_invoice} fileNameDefault={"Deposit receipt" + data.invoice_no + "." + (data.custom_invoice?.split('.')[data.custom_invoice?.split('.')?.length - 1] ?? "pdf")} text={<AssignmentReturned color="gray" />} /></Tooltip>
                                }
                            </Box>}

                        </Box>
                    </Box>

                </Container>
            </>

        }
    </CustomDialog>
}
export default InvoiceDetailUi