
import { CircularProgress, Grid, InputAdornment, Paper, Popover, Popper, Typography, styled } from "@mui/material"
import { Box } from "@mui/system"

import AsynSearchBar from "../../../components/inputs/AsynSearchBar"
import { useEffect, useRef } from "react"
import { CenteredBox } from "../../../components/layouts/OneViewBox"
import NoDataComponent from "../../../components/layouts/NoDataComponent"
import { Comment } from "@mui/icons-material"
import { createAddress } from "../../../utils/helpers/helper"
import LeadCard from "../components/LeadCard"
import { useDispatch } from "react-redux"
import { callSnackBar } from "../../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../../utils/constants/constants"




const OuterBox = styled(Box)(({ theme }) => ({
    width: "100%",
    padding: theme.spacing(2),
    background: theme.palette.secondary.main,
    border: "1px solid " + theme.palette.primary.main

}))
const FiltersBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",

    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }
}))


const ListUi = ({ filters, setFilters, list, loading }) => {

    const ref = useRef()

    useEffect(() => {

        window.addEventListener("keydown", (event) => {
            if (event.ctrlKey && event.key === 's') {
                event.preventDefault();
                if (ref) {

                    ref.current?.querySelector('input')?.focus?.()
                }

            }

            if (event.key === 'Escape') {
                event.preventDefault();
                if (ref) {
                    setFilters({ ...filters, search: "" })

                }

            }
        })
        return () => window.removeEventListener("keydown", () => { })
    }, [filters.search])
    const dispatch = useDispatch()

    return (
        <>


            <Box sx={{ width: "100%" }} >

                <AsynSearchBar
                    aria-describedby={'lead-dropdown'}
                    ref={ref}
                    fullWidth
                    title="Search Lead "
                    size="small"
                    InputProps={{
                        endAdornment: <InputAdornment sx={{}} position="end">
                            <Typography variant="caption" fontWeight={600} >
                                [CTRL+S]
                            </Typography>
                        </InputAdornment>
                    }}
                    placeholder={"Search Lead "}
                    value={filters.search}
                    onChange={(changedVal) => { setFilters({ ...filters, search: changedVal }) }}
                />

                <Popover
                    onClose={() => setFilters({ ...filters, search: "" })}
                    key={filters.search?.length}
                    id={'lead-dropdown'}
                    open={filters.search?.length > 0}
                    // sx={{ zIndex: 111111 }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    anchorEl={ref.current}
                    disableAutoFocus={true}
                    disableEnforceFocus={true}
                >
                    <Box component={Paper} sx={{ width: "400px", maxWidth: "100%" }} p={3} >
                        {
                            loading && <CenteredBox><CircularProgress size={20} /></CenteredBox>
                        }
                        {
                            !loading && list && list.length > 0 ? list.map((item) => {
                                return <Box mb={2} key={item?._id} >

                                    <LeadCard
                                        size="large"
                                        favourite={item?.is_favourite}
                                        uid={item?.uid}
                                        projectId={item?._id}
                                        address={item?.full_address}
                                        status={item?.lead_status}
                                        step={item?.step}
                                        name={item?.contacts?.[0]?.full_name}
                                        customerId={item?.contacts?.[0]?.customer_uid}
                                        email={item?.contacts?.[0]?.email}
                                        phone={item?.contacts?.[0]?.phone}
                                        createdAt={item?.createdAt}
                                        updatedAt={item?.lead_updated_date}
                                        assignedSalesPerson={item?.assigned_sales_person}
                                    />
                                </Box>
                            }) : <CenteredBox>
                                <NoDataComponent iconVariant="subtitle1" variant="subtitle2" Icon={() => <Comment />} message="No Lead" />
                            </CenteredBox>
                        }
                    </Box>

                </Popover>
            </Box>


        </>
    )
}
export default ListUi