import { useState } from "react"
import SubmitButton from "../../../components/button/SubmitButton"
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, Typography, Zoom } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { callApiAction } from "../../../store/actions/commonAction"
import { callSnackBar } from "../../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS, USER_ROLES } from "../../../utils/constants/constants"
import { deleteInvoicesApi } from "../../../apis/invoice.api"
import { Delete, Warning } from "@mui/icons-material"
import { INVOICE_STATUS } from "../../../utils/constants/invoice.constant"
import { CenteredBox } from "../../../components/layouts/common/boxes"
import { moduleAccessCondition } from "../../../utils/helpers/helper"
import MODULES from "../../../utils/constants/module.constants"

const DeleteInvoiceButton = ({ id, icon, status, callBack = () => { } }) => {

    const { user } = useSelector(state => state)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const onSubmit = () => {

        setLoading(true)
        dispatch(
            callApiAction(
                async () => await deleteInvoicesApi({ id }),
                async (response) => {


                    setLoading(false)
                    dispatch(callSnackBar("Invoice deleted successfully", SNACK_BAR_VARIETNS.suceess))
                    setOpen(false)
                    callBack()
                },
                (err) => {
                    setLoading(false)
                    dispatch(callSnackBar("Invoice Cant be deleted", SNACK_BAR_VARIETNS.error))
                }
            )
        )
    }

    const onClick = () => {
        setOpen(true)
    }

    if (!moduleAccessCondition(user, [MODULES.DELETE_INVOICE]))
        return <></>




    return <>
        <Dialog

            fullWidth={true}
            TransitionComponent={Zoom}
            maxWidth={'sm'}

            componentsProps={{ closeAfterTransition: true }}
            open={open}
            onClose={() => { setOpen(false) }}
        >
            <DialogTitle>
                Alert!
            </DialogTitle>
            <DialogContent  >
                <CenteredBox sx={{ flexDirection: "column" }}>
                    {loading && <CircularProgress />}
                    <Typography variant="h3"><Warning color="error" fontSize="inherit" /></Typography>
                    <Typography variant="body1" >
                        Are you sure to delete this invoice!!!
                    </Typography>
                </CenteredBox>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={() => setOpen(false)}>
                    No I dont
                </Button>
                <Button variant="contained" color="error" onClick={onSubmit}>
                    Delete it
                </Button>
            </DialogActions>
        </Dialog>
        {
            icon && <>
                {loading && <CircularProgress />}
                {!loading &&
                    <Tooltip arrow  title="Delete Invoice" >
                        <IconButton onClick={onClick} >
                            <Delete color="error" />
                        </IconButton>
                    </Tooltip>
                }
            </>

        }
        {!icon && <SubmitButton title="Delete Invoice" loading={loading} onClick={onClick} />}
    </>
    // if (icon) {

    //     if (loading)
    //         return <CircularProgress />

    //     return <Tooltip arrow  title="Delete Invoice" >
    //         <IconButton onClick={onClick} >
    //             <Delete color="error" />
    //         </IconButton>
    //     </Tooltip>
    // }
    // return <SubmitButton title="Delete Invoice" loading={loading} onClick={onClick} />
}
export default DeleteInvoiceButton