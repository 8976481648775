import { Box, Checkbox, CircularProgress, FormControlLabel, Grid, MenuItem, Select, Typography } from "@mui/material"

import { memo } from "react"
import { useSelector } from 'react-redux'
import CustomInput from "../../../components/inputs/CustomInput"

import CustomDialog from "../../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../../components/layouts/OneViewBox"
import GoogleAddress from "../../../components/inputs/GoogleAddress"
import { TitleBox } from "../../../components/layouts/common/boxes"
import ContactDetailsComponent from "./ContactDetailsComponent"
import getSourceApi from "../../../apis/sources.api"
import { USER_ROLES } from "../../../utils/constants/constants"
import AsyncDropDown from "../../../components/inputs/AsyncDropDown"
import { getUserApi } from "../../../apis/user.api"
import LeadStepDropDown from "../components/LeadStepsDropDown"
import TagsEditor from "../../../components/inputs/TagsEditor"
import { TAG_TYPE } from "../../../utils/constants/tags.constant"
import { CheckBox } from "@mui/icons-material"
import moment from "moment"
import { DesktopDatePicker, DesktopDateTimePicker } from "@mui/x-date-pickers"
import SiteDetailComponent from "./SiteDetailComponent"





const CreateUi = ({ title, isUpdate, fields, setFields, loading, onSubmit }) => {
    const { user } = useSelector(state => state)


    return <>

        <CustomDialog

            loading={loading}
            err={fields.err}
            onSubmit={onSubmit}
            title={`${isUpdate ? "Update" : "Raise"} ${title}`}
            closeText="Close"
            dialogProps={{ sx: { height: "1500px" } }}
            confirmText={`${isUpdate ? "Update" : "Raise"}`}
        >


            {loading ? <CenteredBox><CircularProgress /></CenteredBox>
                : <>

                    <Grid container spacing={2} sx={{ height: "100%" }} >
                        <Grid item sm={6} md={5} sx={{ height: "100%", overflow: "hidden" }} >
                            <TitleBox title="Contacts Details" >
                                <ContactDetailsComponent
                                    disabled={loading}
                                    defaultFields={fields.customerDetails}
                                    onChange={(valObj) => {                                        
                                        setFields({ ...fields, err: "", customerDetails: valObj })
                                    }}
                                    title={"Customer Details"}
                                />                                
                                <Box mt={2} />
                                <ContactDetailsComponent
                                    disabled={loading}
                                    defaultFields={fields.partnerDetails}
                                    onChange={(valObj) => {
                                        setFields({ ...fields, err: "", partnerDetails: valObj })
                                    }}
                                    title={"Partner Details(Not Required)"}
                                />
                            </TitleBox>
                        </Grid>
                        <Grid item sm={6} md={4} sx={{ height: "100%", overflow: "hidden" }}>
                         <SiteDetailComponent fields={fields} setFields={setFields} loading={loading} />
                        </Grid>
                        <Grid item sm={6} md={3} sx={{ height: "100%", overflow: "hidden" }}>
                            {/* <TitleBox title="Other Details" > */}
                            <Box pt={2} >
                                <Grid container spacing={2}>

                                    {<Grid item xs={12} >

                                        <AsyncDropDown
                                            id={'sources-leads'}
                                            key={loading}
                                            defaultVal={fields.source}
                                            lazyFun={async (params) => { return await getSourceApi({ ...params }) }}
                                            label="Lead Source*"
                                            OptionComponent={({ option, ...rest }) => {
                                                return <MenuItem {...rest}>{option.name}</MenuItem>
                                            }}
                                            onChange={async (changedVal) => { setFields({ ...fields, source: changedVal }) }}
                                            titleKey={'name'}
                                            valueKey={"_id"}
                                            margin="none"
                                            InputComponent={(params) => <CustomInput  {...params} size="small" margin="none" />}
                                        />
                                    </Grid>}


                                    {user.data.role != USER_ROLES.sales && <Grid item xs={12} >
                                        <AsyncDropDown
                                            id={'sales-leads'}
                                            defaultVal={fields.assigned_sales_person}
                                            lazyFun={async (params) => { return await getUserApi({ ...params, role: USER_ROLES.sales }) }}
                                            label="Assigned To*"
                                            OptionComponent={({ option, ...rest }) => {
                                                return <MenuItem {...rest}>{option.name}</MenuItem>
                                            }}
                                            onChange={async (changedVal) => { setFields({ ...fields, assigned_sales_person: changedVal }) }}
                                            titleKey={'name'}
                                            valueKey={"_id"}
                                            InputComponent={(params) => <CustomInput  {...params} size="small" margin="none" />}
                                        />
                                    </Grid>}
                                    <Grid item xs={12} >

                                        <LeadStepDropDown
                                            value={fields.step}
                                            onChange={(e, newVal) => {
                                                setFields({ ...fields, step: newVal })
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} >

                                        <FormControlLabel
                                            label="Schedule call"
                                            control={
                                                <Checkbox
                                                    checked={fields.schedule_call}

                                                    onChange={(e) => {
                                                        setFields({ ...fields, schedule_call: e.target.checked, scheduled_call_date: e.target.checked == true ? moment().add(2, "hours").toISOString() : null })
                                                    }}
                                                />
                                            }
                                        />
                                        {fields.schedule_call && <DesktopDateTimePicker
                                            inputFormat="DD/MM/yyyy HH:mm"
                                            disabled={loading}
                                            value={fields?.scheduled_call_date ? moment(fields.scheduled_call_date) : null}
                                            onChange={(e) => setFields({ ...fields, scheduled_call_date: e?.toISOString() })}
                                            type="text"
                                            label={"Call Date & Time"}


                                            renderInput={(params) => <CustomInput  {...params} margin="none" size="small" />}

                                        />}
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Box mt={4} />
                                        <TagsEditor
                                            isInputBox={true}
                                            value={fields.tags}
                                            onChange={(tags) => { setFields({ ...fields, tags }) }}
                                            type={TAG_TYPE.LEAD}

                                        />
                                    </Grid>




                                </Grid>
                            </Box>
                            {/* </TitleBox> */}
                        </Grid>
                    </Grid>
                </>}

        </CustomDialog>
    </>
}
export default memo(CreateUi)