import { memo, useEffect, useMemo, useState } from "react";
import useValidate from "../../store/hooks/useValidator";
import CreateUi from "./CreateUi";
import { useDispatch } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { closeModal } from "../../store/actions/modalAction";
import { useParams } from "react-router-dom";
import {
  addSupplierApi,
  getSupplierByIdApi,
  updateSupplierField,
} from "../../apis/supplier.api";
import { Password, Warehouse } from "@mui/icons-material";

const CreateController = ({ callBack, id, isCompanySupplier }) => {
  const validate = useValidate();
  const dispatch = useDispatch();
  const params = useParams();

  const title = "Suppliers ";
  const createApi = addSupplierApi;
  const updateApi = updateSupplierField;
  const getByIdApi = getSupplierByIdApi;

  const [loading, setLoading] = useState(false);

  const [fields, setFields] = useState({
    err: "",
    id,
    name: "",
    email: "",
    abn_no: "",
    abn_no: "",
    warehouse_name: "",
    is_company_supplier: isCompanySupplier
  });
  const [originalDocument, setOriginalDocument] = useState({});

  const validationSchemaForCreate = useMemo(
    () => [
      {
        required: true,
        value: fields.name,
        field: "Name",
      },

      {
        required: true,
        value: fields.email,
        field: "Email",
        isEmail: true,
      },
      {
        required: true,
        value: fields.phone,
        field: "Phone no ",
      },
      {
        required: true,
        value: fields.abn_no,
        field: "ABN No",
      },
      {
        required: true,
        value: fields.warehouse_name,
        field: "Warehouse Name",
      },
    ],
    [fields]
  );

  const validationSchemaForUpdate = useMemo(
    () => [
      {
        required: true,
        value: fields.name,
        field: "Name",
      },

      {
        required: true,
        value: fields.email,
        field: "Email",
        isEmail: true,
      },
      {
        required: true,
        value: fields.phone,
        field: "Phone no ",
      },
      {
        required: true,
        value: fields.abn_no,
        field: "ABN No",
      },
      {
        required: true,
        value: fields.warehouse_name,
        field: "Warehouse Name",
      },
    ],
    [fields]
  );

  const createFunction = async () => {
    const validationResponse = validate(validationSchemaForCreate);

    if (validationResponse === true) {
      setLoading(true);
      dispatch(
        callApiAction(
          async () => await createApi(fields),
          async (response) => {
            await callBack(response);
            setLoading(false);
            dispatch(closeModal());
          },
          (err) => {
            setLoading(false);
            setFields({ ...fields, err });
          }
        )
      );
    } else {
      setFields({ ...fields, err: validationResponse });
    }
  };

  const updateFunction = async () => {
    const validationResponse = validate(validationSchemaForUpdate);
    let updatedData = { id };

    for (let field in fields) {
      if (
        originalDocument[field] &&
        fields[field] &&
        fields[field] != originalDocument[field]
      ) {
        updatedData[field] = fields[field];
      }
      if (!originalDocument[field]) {
        updatedData[field] = fields[field];
      }
    }

    if (validationResponse === true) {
      setLoading(true);
      dispatch(
        callApiAction(
          async () => await updateApi(updatedData),
          async (response) => {
            await callBack(response, updatedData);
            setLoading(false);
            dispatch(closeModal());
          },
          (err) => {
            setLoading(false);
            setFields({ ...fields, err });
          }
        )
      );
    } else {
      setFields({ ...fields, err: validationResponse });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (id) updateFunction();
    else createFunction();
  };

  const fetchById = (id) => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await getByIdApi({ id }),
        async (response) => {
          setFields({ ...fields, ...response });
          setOriginalDocument(response);
          setLoading(false);
        },
        (err) => {
          setFields({ ...fields, err });
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    if (id) fetchById(id);
  }, [id]);

  return (
    <CreateUi
      title={title}
      isUpdate={id}
      loading={loading}
      fields={fields}
      onSubmit={onSubmit}
      setFields={setFields}
    />
  );
};
export default memo(CreateController);
