import { useCallback, useEffect, useState } from "react"
import { callApiAction } from "../../store/actions/commonAction"
import { useDispatch } from "react-redux"
import LeadFormDetailedViewUi from "./LeadFormDetailedViewUi"
import { getLeadFormByIdApi } from "../../apis/leadform.api"
import { MODAL_KEYS } from "../../utils/constants/modal.constant"

const LeadFromDetailedViewController = ({ id }) => {
    const dispatch = useDispatch()
    const title = "Lead Form Preview"
    const modalKey = MODAL_KEYS.LEAD_FORMS_DETAIL
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState(null)

    const fetchFun = useCallback((id) => {

        setLoading(true)
        dispatch(callApiAction(
            async () => await getLeadFormByIdApi({ id }),
            (response) => {

                setData(response)
                setLoading(false)

            },
            (err) => {
                setLoading(false)
            }
        ))

    }, [])
    useEffect(() => {
        if (id)
            fetchFun(id)
    }, [id])



    return <LeadFormDetailedViewUi

        title={title}
        modalKey={modalKey}
        data={data}
        loading={loading}
        setData={setData}

    />
}
export default LeadFromDetailedViewController
