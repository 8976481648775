import { Box, Button, ButtonBase, ButtonGroup, Checkbox, FormControlLabel, Grid, Paper, Radio, RadioGroup, styled, Tooltip, Typography } from "@mui/material"
import TimeRangeSelector from "../../../components/layouts/common/TimeRangeSelector"
import IconTitle from "../../../components/texts/IconTitle"
import { CalendarMonth, CheckBox, MilitaryTech } from "@mui/icons-material"
import { allowedDateFilters, titleCase } from "../../../utils/helpers/helper"
import { StatusCustomButton, StatusIcon } from "../components/StatusComponent"
import { LEAD_STATUS, LEAD_STATUS_COLOR, LEAD_STATUS_ICON } from "../../../utils/constants/lead.constant"
import { memo } from "react"

// const StatusCustomButton = styled(ButtonBase)(({ theme, color }) => ({

//     borderRadius: theme.shape.borderRadius,
//     padding: theme.spacing(1),
//     background: color ?? theme.palette.primary.main,
//     height: "25px",
//     width: "25px",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     color: theme.palette.light.main,
//     fontSize: "20px"
// }))
const LeadStatusFilter = ({ filters, setFilters, forPannel = false }) => {

    const onStatusClick = (status) => {

        const statusInFilter = filters.lead_status
        if (statusInFilter && Array.isArray(statusInFilter)) {
            const findIndex = statusInFilter.findIndex((item) => status == item)
            if (findIndex == -1)
                statusInFilter.push(status)
            else
                statusInFilter.splice(findIndex, 1)

            setFilters({ ...filters, lead_status: statusInFilter })
        } else {
            setFilters({ ...filters, lead_status: [status] })
        }
    }
    const checkisActive = (status) => {
        const statusInFilter = filters.lead_status

        return statusInFilter && Array.isArray(statusInFilter) && statusInFilter.find((item) => item == status) !== undefined
    }
    return <>
        {!forPannel && <IconTitle Icon={MilitaryTech} title="Status" />}
        <Paper component={Box} variant="outlined" p={2} display="flex" justifyContent="space-between">
            {
                Object.keys(LEAD_STATUS).map((item) => <Tooltip key={item} title={titleCase(item)}>

                    <StatusCustomButton key={item} color={checkisActive(LEAD_STATUS[item]) ? LEAD_STATUS_COLOR[LEAD_STATUS[item]] : "grey"} onClick={() => onStatusClick(LEAD_STATUS[item])} >
                        {LEAD_STATUS_ICON[LEAD_STATUS[item]]}
                    </StatusCustomButton>
                </Tooltip>)
            }
        </Paper>
    </>
}
export default memo(LeadStatusFilter)