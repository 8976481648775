import { Box } from "@mui/material"

const BubbleComponent = ({ percentage, height, width }) => {

    const borderWidth = width * 0.033
    const innerWidth = width -( 0.94)
    const innerHeight = height -( 0.94)
    
    return <Box className={`${percentage > 20 ? 'green' : 'red'}`}>
        <Box className={`progress ${percentage > 20 ? 'green' : 'red'}`} height={height} width={width} sx={{ borderWidth: borderWidth + ' !important', boxShadow: "none !important" }}>
            <Box className="inner" height={innerHeight} width={innerWidth}>
                <Box className="percent" sx={{ lineHeight: innerHeight + "px", fontSize: width * 0.282 }} ><span>{percentage}</span>%</Box>
                <Box className="water" key={percentage} sx={{ top: (100 - percentage) + "% !important" }}></Box>
                <Box className="glare"></Box>
            </Box>
        </Box>
    </Box>
}
export default BubbleComponent