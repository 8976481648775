import { Box, ButtonBase, Typography, styled, useTheme } from "@mui/material"
import { CALENDAR_ITEMS_TYPES, LOG_TYPE } from "../../utils/constants/constants"
import { TASK_PRIORITY, TASK_STATUS } from "../../utils/constants/task.constants"
import { useDispatch } from "react-redux"
import { openModal } from "../../store/actions/modalAction"
import TaskDetailsController from "./detailedView/TaskDetails.controller"
import { calculateWorkingHours, findObjectKeyByValue } from "../../utils/helpers/helper"
import { CenteredBox } from "../../components/layouts/OneViewBox"
import { memo } from "react"
import { East, PunchClock, West } from "@mui/icons-material"
import moment from "moment"



const TaskItem = styled(ButtonBase)(({ theme, color }) => (
    {
        width: "100%",
        borderRadius: 0,
        marginBottom: 0,
        color,
        position: "relative",
        "::before": {
            borderRadius: 0,
            content: "' '",
            position: "absolute",
            opacity: "0.2",
            top: "0px",
            left: "0px",
            zIndex: 0,
            background: color,
            height: "100%",
            width: "100%"
        }
    }
))
const LogItem = memo(({ time, type, Icon, text }) => {

    return <Box sx={{ display: "flex", alignItems: "center" }} pl={1} >
        <Typography variant="caption" lineHeight="100%" color="info" >

            {Icon && text && <Icon fontSize="inherit" />}
            {type && (type == LOG_TYPE.CHECK_IN ? <East fontSize="inherit" /> : <West fontSize="inherit" />)}
        </Typography>
        <Typography ml={1} variant="caption" lineHeight="100%" color='gray' fontWeight="600" >
            {time && moment(time).format("HH:mm")}
            {text}
        </Typography>

    </Box>
})
const TaskPriority = styled(Box)(({ theme, color }) => (
    {
        width: "10px",
        backgroundColor: color,
        height: "100%",
        zIndex: 1,
        position: "absolute"


    }
))

const useTaskColor = () => {
    const theme = useTheme()
    return (status) => {


        if (status == TASK_STATUS.COMPLETED) {
            return theme.palette.success.main
        }
        if (status == TASK_STATUS.IN_PROCESS)
            return theme.palette.warning.main
        return theme.palette.primary.main
    }
}
const CalendarInnerBox = ({ data }) => {
    const getTaskColor = useTaskColor()
    const dispatch = useDispatch()
    const onTaskClick = (id) => {
        dispatch(openModal(<TaskDetailsController id={id} />, "sm"))
    }
    const theme = useTheme()

    const { workingHours, minutes } = calculateWorkingHours(data.filter(item => item.type == CALENDAR_ITEMS_TYPES.LOGS) ?? [])
    return <>

        <Box sx={{ display: "flex", height: "100%", flexDirection: "column", justifyContent: "space-between", width: "100%" }} >

            <Box sx={{ width: "100%" }} pt={3}>
                {
                    data && data.filter(item => item.type == CALENDAR_ITEMS_TYPES.LOGS).map((item) => <LogItem type={item.logType} time={item.time} />)

                }
                <Box mt={1} />
                {
                    data && !(workingHours == 0 && minutes == 0) && <LogItem
                        Icon={PunchClock}
                        text={`${workingHours}H  ${minutes} M`}
                    />
                }
            </Box>
            <Box>
                <Box sx={{ width: "100%" }} >
                    {
                        data && data.filter(item => item.type == CALENDAR_ITEMS_TYPES.TASK).map((item) => <TaskItem pl={1} sx={{ justifyContent: "flex-start" }} key={item.title} onClick={() => { onTaskClick(item.relatedId) }} color={getTaskColor(item.status)} >
                            <TaskPriority color={item.priority == TASK_PRIORITY.HIGH ? theme.palette.error.main : item.priority == TASK_PRIORITY.MEDIUM ? theme.palette.warning.main : theme.palette.success.main}  >
                                <CenteredBox>
                                    <Typography color="white" sx={{ fontSize: "8px" }} >
                                        {
                                            findObjectKeyByValue(item.priority, TASK_PRIORITY)?.slice(0, 1) ?? ""
                                        }
                                    </Typography>
                                </CenteredBox>
                            </TaskPriority>
                            <Box sx={{ display: "flex", flex: 1, marginLeft: '12px' }} ml={2}>
                                <Typography variant="caption" sx={{ fontSize: "9px" }} align="left" >{item?.title}</Typography>
                            </Box>
                        </TaskItem>)
                    }
                </Box>

            </Box>
        </Box>

    </>
}
export default memo(CalendarInnerBox)