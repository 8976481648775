import { actions } from "../../utils/constants/constants";
const initialState = {    
    data: [

    ],
}
const blogReducer = (state = initialState, action) => {
    // console.log("states",action);
    switch (action.type) {
        case actions.SET_BLOG_DATA: return { ...state, data: action.data};
        // case actions.ANNOUNCEMENT_READ: return { ...state, new: 0 };d
        default: return { ...state }
    }

}

export default blogReducer





