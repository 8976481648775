import { memo, useEffect, useMemo, useState } from "react";
import useValidate from "../../store/hooks/useValidator";
import CreateUi from "./CreateUi";
import { useDispatch } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import {
  addUserApi,
  getUserByIdApi,
  updateInstallerUserField,
  updateUserField,
} from "../../apis/user.api";
import { closeModal } from "../../store/actions/modalAction";
import { useParams } from "react-router-dom";
import { USER_ROLES } from "../../utils/constants/constants";
import {
  addCompanyApi,
  getCompanyByIdApi,
  updateCompanyData,
} from "../../apis/company.api";

const CreateController = ({ callBack = () => {}, id }) => {
  const validate = useValidate();
  const dispatch = useDispatch();
  const params = useParams();

  const title = "Company";
  const modalKey = "company-create";
  const createApi = addCompanyApi;
  const updateApi = updateCompanyData;
  const getByIdApi = getCompanyByIdApi;

  const [loading, setLoading] = useState(false);

  const [fields, setFields] = useState({
    err: "",
    id,
    name: "",
    short_name: "",
    logo: null,
    abn_no:"",
    description: "",
    company_phone: "",
    company_email: "",
    company_website: "",
    admin_name: "",
    admin_email: "",
    admin_password: "",
    admin_phone: "",
    ahead_of_competition: "",
    professional: "",
    positioning: "",
    convenience: "",
    unique: "",
    ceo_message: "",
    payment_details: "",
  });
  const [originalDocument, setOriginalDocument] = useState({});

  const validationSchemaForCreate = useMemo(
    () => [
      {
        required: true,
        value: fields.name,
        field: "Company Name",
      },

      {
        required: true,
        value: fields.short_name,
        field: "Company Short Name",
      },
      {
        required: true,
        value: fields.description,
        field: "Company Description",
      },

      {
        required: true,
        value: fields.logo,
        field: "Logo",
      },
      {
        required: true,
        value: fields.company_phone,
        field: "Company Phone",
      },
      {
        required: true,
        value: fields.company_email,
        isEmail: true,
        field: "Company Email",
      },
      {
        required: true,
        value: fields.company_website,
        field: "Company Website",
      },
      // {
      //   required: true,
      //   value: fields.ahead_of_competition,
      //   field: "Ahead of Competition",
      // },
      // {
      //   required: true,
      //   value: fields.professional,
      //   field: "Professional",
      // },
      // {
      //   required: true,
      //   value: fields.convenience,
      //   field: "Convenience",
      // },
      // {
      //   required: true,
      //   value: fields.unique,
      //   field: "Unique",
      // },
      // {
      //   required: true,
      //   value: fields.ceo_message,
      //   field: "CEO's message",
      // },
      {
        required: true,
        value: fields.admin_name,
        field: "Admin Name",
      },
      {
        required: true,
        value: fields.admin_email,
        field: "Admin Email",
      },
      {
        required: true,
        value: fields.admin_password,
        field: "Admin Password",
      },
      {
        required: true,
        value: fields.admin_phone,
        field: "Admin Phone",
      },
      {
        required: true,
        value: fields.abn_no,
        field: "Abn Number",
      },
    ],
    [fields]
  );

  const validationSchemaForUpdate = useMemo(
    () => [
      {
        required: true,
        value: fields.name,
        field: "Company Name",
      },

      {
        required: true,
        value: fields.short_name,
        field: "Company Short Name",
      },
      {
        required: true,
        value: fields.description,
        field: "Company Description",
      },
      {
        required: true,
        value: fields.logo,
        field: "Logo",
      },
      {
        required: true,
        value: fields.company_phone,
        field: "Company Phone",
      },
      {
        required: true,
        value: fields.company_email,
        isEmail: true,
        field: "Company Email",
      },
      {
        required: true,
        value: fields.company_website,
        field: "Company Website",
      },
      {
        required: true,
        value: fields.abn_no,
        field: "Abn Number",
      },
      // {
      //   required: true,
      //   value: fields.ahead_of_competition,
      //   field: "Ahead of Competition",
      // },
      // {
      //   required: true,
      //   value: fields.professional,
      //   field: "Professional",
      // },

      // {
      //   required: true,
      //   value: fields.convenience,
      //   field: "Convenience",
      // },
      // {
      //   required: true,
      //   value: fields.unique,
      //   field: "Unique",
      // },
      // {
      //   required: true,
      //   value: fields.ceo_message,
      //   field: "CEO's message",
      // },
      // {
      //   required: true,
      //   value: fields.payment_details,
      //   field: "Payment Details",
      // },
    ],
    [fields]
  );

  const createFunction = async () => {
    const validationResponse = validate(validationSchemaForCreate);

    if (validationResponse === true) {
      setLoading(true);
      dispatch(
        callApiAction(
          async () => await createApi(fields),
          async (response) => {
            await callBack(response);
            setLoading(false);
            dispatch(closeModal(modalKey));
          },
          (err) => {
            setLoading(false);
            setFields({ ...fields, err });
          }
        )
      );
    } else {
      setFields({ ...fields, err: validationResponse });
    }
  };

  const updateFunction = async () => {
    const validationResponse = validate(validationSchemaForUpdate);
    let updatedData = { id };

    for (let field in fields) {
      if (
        originalDocument[field] &&
        fields[field] &&
        fields[field] != originalDocument[field]
      ) {
        updatedData[field] = fields[field];
      }
      if (!originalDocument[field] && fields[field]!='') {
        updatedData[field] = fields[field];
      }
    }

    if (validationResponse === true) {
      setLoading(true);
      dispatch(
        callApiAction(
          async () => await updateApi(updatedData),
          async (response) => {
            await callBack(response, updatedData);
            setLoading(false);
            dispatch(closeModal(modalKey));
          },
          (err) => {
            setLoading(false);
            setFields({ ...fields, err });
          }
        )
      );
    } else {
      setFields({ ...fields, err: validationResponse });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (id) updateFunction();
    else createFunction();
  };

  const fetchById = (id) => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await getByIdApi({ id }),
        async (response) => {
          // if (response) {
          //   const fieldsToBeUpdate = { ...response };
          //   // fieldsToBeUpdate['name'] = response.name
          //   // fieldsToBeUpdate['description'] = response.description
          //   // fieldsToBeUpdate['short_name'] = response.short_name
          //   // fieldsToBeUpdate['logo'] = response.logo

          //   setFields({ ...fields, ...fieldsToBeUpdate });
          //   setOriginalDocument(fieldsToBeUpdate);
          // }

          setFields({ ...fields, ...response });
          setLoading(false);
        },
        (err) => {
          setFields({ ...fields, err });
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    if (id) fetchById(id);
  }, [id]);

  return (
    <CreateUi
      modalKey={modalKey}
      title={title}
      isUpdate={id}
      loading={loading}
      fields={fields}
      onSubmit={onSubmit}
      setFields={setFields}
    />
  );
};
export default memo(CreateController);
