import axios from "axios";
import { getFileHeaders, getHeaders } from "../utils/helpers/helper";
import endpoints from "./endpoints";




export const getProductApi = async params => {
  const callResponse = await axios({
    url: endpoints.productBase,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const downloadProductDemoCSV = async params => {
  const callResponse = await axios({
    url: endpoints.productCSVBase,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const getProductByIdApi = async params => {
  const callResponse = await axios({
    url: endpoints.productById,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


export const addProductApi = async data => {
  const callResponse = await axios({
    url: endpoints.productBase,
    method: "POST",
    headers: getHeaders(),

    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const uploadProductCSVApi = async data => {
  const callResponse = await axios({
    url: endpoints.productCSVBase,
    method: "POST",
    headers: getFileHeaders(),

    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const updateProductField = async data => {
  const callResponse = await axios({
    url: endpoints.productBase,
    method: "PUT",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


export const deleteProductApi = async data => {
  const callResponse = await axios({
    url: endpoints.productBase,
    method: "DELETE",
    headers: getHeaders(),

    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};




export const getProductCategoryApi = async params => {
  const callResponse = await axios({
    url: endpoints.productCategoryBase,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


export const getProductCategoryByIdApi = async params => {
  const callResponse = await axios({
    url: endpoints.productCategoryById,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


export const addProductCategoryApi = async data => {
  const callResponse = await axios({
    url: endpoints.productCategoryBase,
    method: "POST",
    headers: getHeaders(),

    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const updateProductCategoryField = async data => {
  const callResponse = await axios({
    url: endpoints.productCategoryBase,
    method: "PUT",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


export const deleteProductCategoryApi = async data => {
  const callResponse = await axios({
    url: endpoints.productCategoryBase,
    method: "DELETE",
    headers: getHeaders(),

    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
