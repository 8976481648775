import { memo, useEffect, useMemo, useState } from "react";
import useValidate from "../../store/hooks/useValidator";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { closeModal, openModal } from "../../store/actions/modalAction";
import { useParams } from "react-router-dom";

import { MODAL_KEYS } from "../../utils/constants/modal.constant";
import {
    addComplainNoteApi
} from "../../apis/complain.api";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import CustomInput from "../../components/inputs/CustomInput";
import { moduleAccessCondition } from "../../utils/helpers/helper";
import MODULES from "../../utils/constants/module.constants";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { AddCircle } from "@mui/icons-material";

export const ComplainAddNotesButton = ({ callBack = () => { }, complain_id }) => {
    const { user } = useSelector(state => state)
    const dispatch = useDispatch()
    const onClick = () => {
        dispatch(openModal(<ComplainAddNotes callBack={callBack} complain_id={complain_id} />,'sm',undefined,MODAL_KEYS.COMPLAINS_NOTES_ADD))
    }

    if (!moduleAccessCondition(user, [MODULES.CREATE_COMPLAINS]))
        return <></>
    return <Tooltip arrow  title="Add Note" >
        <IconButton sx={{ padding: 0 }} onClick={onClick}>
            <Typography variant="subtitle1" lineHeight="100%">
                <AddCircle fontSize="inherit" color="primary" />
            </Typography>
        </IconButton>
    </Tooltip>
}

const ComplainAddNotes = ({ callBack = () => { }, complain_id }) => {
    const validate = useValidate();
    const dispatch = useDispatch();
    const modalKey = MODAL_KEYS.COMPLAINS_NOTES_ADD;
    const title = "Add Note";
    const createApi = addComplainNoteApi;

    const [loading, setLoading] = useState(false);

    const [fields, setFields] = useState({
        err: "",
        complain_id,
        remarks: ""
    });



    const validationSchemaForCreate = useMemo(
        () => [

            {
                required: true,
                value: fields?.remarks,
                field: "Note",
            }
        ],
        [fields]
    );


    const createFunction = async () => {
        const validationResponse = validate(validationSchemaForCreate);

        if (validationResponse === true) {
            let passData = fields;


            setLoading(true);
            dispatch(
                callApiAction(
                    async () => await createApi(passData),
                    async (response) => {
                        await callBack(response);
                        setLoading(false);
                        dispatch(closeModal(modalKey));
                    },
                    (err) => {
                        setLoading(false);
                        setFields({ ...fields, err });
                    }
                )
            );
        } else {
            setFields({ ...fields, err: validationResponse });
        }
    };



    const onSubmit = async (e) => {
        e?.preventDefault()
        createFunction();
    };



    return (
        <CustomDialog
            loading={loading}
            id={modalKey}
            title={title}
            onSubmit={onSubmit}
        >
            <CustomInput
                loading={loading}
                value={fields.remarks}
                label="Note*"
                miltiline
                rows={4}
                onChange={(e) => setFields({ ...fields, remarks: e.target.value })}
            />

        </CustomDialog>
    );
};
export default memo(ComplainAddNotes);
