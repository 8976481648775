import { useEffect, useState } from "react"
import DetailedViewUi from "./DetailedView"
import { MODAL_KEYS } from "../../utils/constants/modal.constant"
import { useDispatch } from "react-redux"
import { callApiAction } from "../../store/actions/commonAction"
import { getEnergyPartnerByIdApi } from "../../apis/energy-partners.api"

const DetailedViewController = ({ id, title }) => {

    const modalKey = MODAL_KEYS.ENERGY_PARTNER_VIEW
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState(null)
    const fetchById = (id) => {
        setLoading(true)
        dispatch(
            callApiAction(
                async () => await getEnergyPartnerByIdApi({ id }),
                async (response) => {
                    setData(response)
                    setLoading(false)
                },
                (err) => {
                    setData(null)
                    setLoading(false)
                }
            )
        )
    }

    useEffect(() => {
        if (id)
            fetchById(id)

    }, [id])
    return <DetailedViewUi
        id={id}
        modalKey={modalKey}
        title={title}
        data={data}
        setData={setData}
        loading={loading}
    />
}
export default DetailedViewController