import { Button, ButtonGroup, Paper, Typography, styled } from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import SubmitButton from "../../components/button/SubmitButton";
import DataTable from "../../components/tables/DataTable";
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxInDesktop";
import AsynSearchBar from "../../components/inputs/AsynSearchBar";
import MODULES from "../../utils/constants/module.constants";
import { moduleAccessCondition } from "../../utils/helpers/helper";

const FilterTitleBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));
const FiltersBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",

  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const ListUi = ({
  title,
  filters,
  setFilters,
  list,
  roles,
  loading,
  onCreateBtnClick,
  columns,
}) => {
  const user = useSelector((state) => state.user);

  return (
    <>
      <Box >
        <Paper elevation={2} sx={{ width: "100%", padding: 4 }}>
          <Box mb={4}>
            <FilterTitleBox>
              <Typography variant="h5" mb={2}>
                {title}
              </Typography>

              <Box mb={2}>
                {moduleAccessCondition(user, [MODULES.CREATE_INSTALLER], []) && (
                  <SubmitButton
                    variant="contained"
                    onClick={onCreateBtnClick}
                    title={"Add Installer"}
                  />
                )}
              </Box>
            </FilterTitleBox>

            <FiltersBox mt={3}>
            
            

              <PaddingBoxInDesktop
                mb={2}
                sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
                pl={3}
              >
                { (
                  <ButtonGroup disableElevation variant="outlined">
                    <Button
                      onClick={() => setFilters({ ...filters, deleted: null })}
                      variant={
                        filters.deleted == null ? "contained" : "outlined"
                      }
                    >
                      Active Installer
                    </Button>
                    <Button
                      onClick={() => setFilters({ ...filters, deleted: true })}
                      variant={
                        filters.deleted == true ? "contained" : "outlined"
                      }
                    >
                      Deleted Installer
                    </Button>
                  </ButtonGroup>
                )}
              </PaddingBoxInDesktop>
            </FiltersBox>
            <FiltersBox mt={3}>
            
              <PaddingBoxInDesktop
                mb={2}
               
              >
                <AsynSearchBar
                  fullWidth
                  title="Search Name "
                  size="small"
                  placeholder={"Search Name "}
                  defaultValue={filters.search}
                  onChange={(changedVal) => {
                    setFilters({ ...filters, search: changedVal });
                  }}
                />
                
              </PaddingBoxInDesktop>

</FiltersBox>
          </Box>

          <Box sx={{ minHeight: "300px" }}>
            <DataTable
              columns={columns}
              rows={list.result ? list.result : []}
              count={list.total ?? 0}
              filters={filters}
              setFilters={setFilters}
              loading={loading}
            />
          </Box>
        </Paper>
      </Box>
    </>
  );
};
export default ListUi;
