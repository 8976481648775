
import { Button, ButtonGroup, Paper, Typography, styled } from "@mui/material"
import { Box } from "@mui/system"
import { useSelector } from "react-redux"
import SubmitButton from "../../../components/button/SubmitButton"
import DataTable from "../../../components/tables/DataTable"

import PaddingBoxInDesktop from "../../../components/layouts/PaddingBoxInDesktop"
import AsynSearchBar from "../../../components/inputs/AsynSearchBar"



const FilterTitleBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }

}))
const FiltersBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",

    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }
}))


const MappedUserListUI = ({ title, filters, setFilters, list, modal, loading, onCreateBtnClick, columns }) => {

    const user = useSelector(state => state.user)

    return (
        <>
            <Paper elevation={0} variant="outlined" sx={{ width: "100%", mt: 3,p:3 }} >
                <Box mb={4} >
                    <FilterTitleBox>
                        { <Typography variant="h5"  >{title}</Typography>}
                        
                        <Box >
                            <SubmitButton variant="contained" onClick={onCreateBtnClick} title={"Map Users"} />
                        </Box>

                    </FilterTitleBox>


                    <FiltersBox mt={3} >

                        <PaddingBoxInDesktop  sx={{ display: "flex", justifyContent: "flex-end" }} pl={3} >

                            <AsynSearchBar
                                fullWidth
                                title="Search By  Name " size="small" placeholder={"Search By Name "}
                                defaultValue={filters.search}
                                onChange={(changedVal) => { setFilters({ ...filters, search: changedVal }) }}
                            />
                        </PaddingBoxInDesktop>



                    </FiltersBox>

                </Box>

                <Box sx={{ minHeight: "300px" }}>
                    <DataTable columns={columns} rows={list.result ? list.result : []} count={list.total ?? 0} filters={filters} setFilters={setFilters} loading={loading} />
                </Box>
            </Paper>

        </>
    )
}
export default MappedUserListUI