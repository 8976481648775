import { memo, useCallback, useEffect, useState } from "react"
import ListUi from "./ListUi"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"

import { closeModal, openModal } from "../../store/actions/modalAction"
import { Box, CircularProgress, IconButton, Switch, Typography } from "@mui/material"
import { useMemo } from "react"
import CreateController from "./CreateController"
import { Delete, Edit, Info, More, MoreHoriz, Preview, Undo } from "@mui/icons-material"

import { MODAL_KEYS } from "../../utils/constants/modal.constant"
import CustomDialog from "../../components/layouts/common/CustomDialog"
import MessageDilog from "../../components/MessageDilog"
import { deleteLeadFormApi, getLeadFormApi } from "../../apis/leadform.api"
import LeadFromDetailedViewController from "./LeadFromDetailedViewController"

const ListModal = ({ modalKey = MODAL_KEYS.LEAD_FORMS_LIST, title, parent }) => {
    return <CustomDialog
        id={modalKey}
        title={title}
    >
        <ListController modal parent={parent} createModalKey={MODAL_KEYS.LEAD_FROMS_CREATE} />
    </CustomDialog>
}

const ActionComponent = memo(({ params, setParams, deleteApi }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)

    const { user } = useSelector(state => state)

    const onEdit = () => {
        dispatch(openModal(<CreateController id={params._id} callBack={(response, updatedData) => {
            setParams({ ...params, ...updatedData })
        }} />, "lg", undefined, MODAL_KEYS.SOURCES_CREATE))
    }
    const onPreviewClick = () => {
        dispatch(openModal(<LeadFromDetailedViewController id={params._id} />, "lg", undefined, MODAL_KEYS.LEAD_FORMS_DETAIL))
    }

    const deleteFun = async () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await deleteApi({ id: params._id }),
            (response) => {
                setParams({})
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
        dispatch(closeModal())
    }


    const onDelete = () => {
        dispatch(openModal(<MessageDilog onSubmit={deleteFun} title="Alert!" message={`Are you sure to delete "${params.name || params.title}" ?`} />, "sm"))
    }

    return <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
        <IconButton size="inherit" onClick={onPreviewClick}>
            <Preview color="primary" fontSize="inherit" />
        </IconButton>
        {<IconButton size="inherit" onClick={onEdit}>
            <Edit color="info" fontSize="inherit" />
        </IconButton>
        }


        {<>
            {loading && <CircularProgress color="primary" fontSize="inherit" />}
            {!loading && <IconButton disabled={loading} size="inherit" onClick={onDelete}>
                <Delete color="error" fontSize="inherit" />
            </IconButton>}
        </>}
    </Box>
})


const ListController = ({ createModalKey = MODAL_KEYS.LEAD_FROMS_CREATE, parent, modal }) => {
    const dispatch = useDispatch()


    const title = "Lead Forms "

    const fetchApi = getLeadFormApi
    const deleteApi = deleteLeadFormApi


    const columns = useMemo(() => [

        { id: 1, fieldName: 'form_title', label: 'Form Title', align: "left", sort: true },


        {
            id: 5,
            fieldName: '',
            label: 'Action',

            align: "right",
            renderValue: (params, setParams) => <ActionComponent createModalKey={createModalKey} deleteApi={deleteApi} params={params} setParams={setParams} />,
        },

    ], [dispatch]);


    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 10,
        search: '',
        parent,
        searchable: ['form_title'],
        sort: 'createdAt',
        sortDirection: -1

    })





    // do not change 

    const [loading, setLoading] = useState(false)
    const [list, setList] = useState({})









    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchApi({ ...filters }),
            (response) => {
                setList(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }
    const onCreateBtnClick = () => {
        dispatch(openModal(<CreateController modalKey={createModalKey} parent={parent} callBack={async () => { fetchList() }} />, "lg", undefined, createModalKey))
    }



    useEffect(() => {
        fetchList()
    }, [filters])



    return (
        <>
            <ListUi
                modal={modal}
                title={title}
                onCreateBtnClick={onCreateBtnClick}

                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}
                columns={columns}


            />

        </>
    )
}
export default ListController