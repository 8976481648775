
export const localStatecallModal = () => {
    return {
      set: (val) => localStorage.setItem('call_modal_state', JSON.stringify(val)),
      get: () => localStorage.getItem('call_modal_state') ? JSON.parse(localStorage.getItem('call_modal_state')) : null,
      remove: () => localStorage.removeItem('call_modal_state')
    }
  }

export const  validateNMI =(input)=> {
    const regex = /^\d{10,11}$/; // Regular expression to match numbers with length between 10 and 11
    return regex.test(input);    // Returns true if input matches the regex, false otherwise
  }
  export const  validateMeterNo =(input)=> {
    const regex = /^[a-zA-Z0-9]+$/; // Regular expression to match only letters and numbers
  return regex.test(input);    
  }