import { CircularProgress, MenuItem } from "@mui/material"
import { memo, useMemo } from "react"
import { useSelector } from 'react-redux'

import { USER_ROLES } from "../../../../utils/constants/constants"
import AsyncDropDown from "../../../../components/inputs/AsyncDropDown"

import CustomDialog from "../../../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../../../components/layouts/OneViewBox"

import { getUserApi } from "../../../../apis/user.api"




const LeadReassignUi = ({ title, fields, setFields, loading, onSubmit, modalKey }) => {
    const { user } = useSelector(state => state)
    const defaultOptions = useMemo(() => {
        if (user.data.role == USER_ROLES.sales)
            return [{ name: "Assign to me.", _id: user?.data?._id }]
        else return []

    }, [user.data?.role])
    return <>

        <CustomDialog
            id={modalKey}

            loading={loading}
            err={fields.err}

            onSubmit={onSubmit}
            title={` ${title}`}
            closeText="Close"
            confirmText={`Update`}
        >

            {loading ? <CenteredBox><CircularProgress /></CenteredBox> :
                <>
                    <AsyncDropDown
                        id={'reassign-sales-leads-create'}
                        defaultVal={fields.assigned_sales_person}
                        defaultOptions={defaultOptions}
                        lazyFun={async (params) => { return await getUserApi({ ...params, role: USER_ROLES.sales }) }}
                        label="Assigned To*"
                        OptionComponent={({ option, ...rest }) => {
                            return <MenuItem {...rest}>{option.name}</MenuItem>
                        }}
                        onChange={async (changedVal) => { setFields({ ...fields, assigned_sales_person: changedVal ? changedVal : null }) }}
                        titleKey={'name'}
                        valueKey={"_id"}
                    />

                </>}

        </CustomDialog>
    </>
}
export default memo(LeadReassignUi)