import { memo, useEffect, useMemo, useState } from "react"
import useValidate from "../../store/hooks/useValidator"
import CreateUi from "./CreateUi"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { closeModal } from "../../store/actions/modalAction"


import { MODAL_KEYS } from "../../utils/constants/modal.constant"
import { createActivityApi, getActivityByIdApi, updateActivityApi } from "../../apis/activity.api"


const CreateController = ({ title = " Activity",callBack = () => { }, id ,parent,icon}) => {

    const validate = useValidate()
    const dispatch = useDispatch()

    const modalKey = MODAL_KEYS.ACTIVITY_CREATE
    
    const createApi = createActivityApi
    const updateApi = updateActivityApi
    const getByIdApi = getActivityByIdApi

    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',
        
        amount: 0,
        title: "",
        color: "#000",
        icon: icon??1,
        parent


    })
    const [originalDocument, setOriginalDocument] = useState({


    })

    const validationSchemaForCreate = useMemo(() => ([
        {
            required: true,
            value: fields.title,
            field: 'Title',
        },
        {
            required: true,
            value: fields.color,
            field: 'Color',
        },
        {
            required: true,
            value: fields.icon,
            field: 'Icon',
        }

    ]), [fields])


    const validationSchemaForUpdate = useMemo(() => ([

        {
            required: true,
            value: fields.title,
            field: 'Title',
        },
        {
            required: true,
            value: fields.color,
            field: 'Color',
        },
        {
            required: true,
            value: fields.icon,
            field: 'Icon',
        }

    ]), [fields])


    const createFunction = async () => {

        const validationResponse = validate(validationSchemaForCreate)

        let dataToBePassed = { ...fields }

        
        if (validationResponse === true) {
            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await createApi({ ...dataToBePassed }),
                    async (response) => {
                        await callBack(fields)
                        setLoading(false)
                        dispatch(closeModal(modalKey))
                    },
                    (err) => {

                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }

    const updateFunction = async () => {
        const validationResponse = validate(validationSchemaForUpdate)
        let updatedData = { id }

        for (let field in originalDocument) {

            if (originalDocument[field] && fields[field] && fields[field] != originalDocument[field]) {
                updatedData[field] = fields[field]
            }
        }

        if (validationResponse === true) {


            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await updateApi(updatedData),
                    async (response) => {

                        await callBack(response, updatedData)
                        setLoading(false)
                        dispatch(closeModal(modalKey))
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }


    const onSubmit = async (e) => {
        e?.preventDefault()
        if (id)
            updateFunction()
        else
            createFunction()

    }

    const fetchById = (id) => {
        setLoading(true)
        dispatch(
            callApiAction(
                async () => await getByIdApi({ id }),
                async (response) => {


                    setFields({ ...fields, ...response })
                    setOriginalDocument(response)
                    setLoading(false)

                },
                (err) => {
                    setFields({ ...fields, err })
                    setLoading(false)

                }
            )
        )
    }

    useEffect(() => {
        if (id)
            fetchById(id)

    }, [id])

    return <CreateUi title={title} callBack={callBack} isUpdate={id} modalKey={modalKey} loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
}
export default memo(CreateController)