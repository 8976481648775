export const MODAL_KEYS = {
    PROJECT: "PROJECT",
    MODULE_ACCESS: "MODULE_ACCESS",
    EMAIL:"EMAIL",
    SOURCES_CREATE:"SOURCES_CREATE",
    SOURCES_LIST:"SOURCES_LIST",
    PROJECT_FLAG_FILTERS:"PROJECT_FLAG_FILTERS",
    PROJECT_STOCK_FILTERS:"PROJECT_STOCK_FILTERS",
    CHAT_GROUP_DETAILS: "CHAT_GROUP_DETAILS",
    OPEN_SOLAR_DOCS: "OPEN_SOLAR_DOCS",
    SYSTEMS: "SYSTEMS",
    PROJECT_LOGS: "PROJECT_LOGS",
    LEAD: "LEAD",
    LEAD_DETAILS: "LEAD_DETAILS",
    CREATE_ORDER: "CREATE_ORDER",
    RECIEVE_ORDER: "RECIEVE_ORDER",
    LOAN_AND_BENEFITS: "LOAN_AND_BENEFITS",
    REJECTION_REASON: "REJECTION_REASON",
    SALES_PERSON_CAPABILITY: "SALES_PERSON_CAPABILITY",
    SOURCE_CAPABILITY: "SOURCE_CAPABILITY",
    SCHEDULE_CALL: "SCHEDULE_CALL",

    COMPLAIN_CATEGORY_CREATE: "COMPLAIN_CATEGORY_CREATE",
    COMPLAIN_CATEGORY_LIST: "COMPLAIN_CATEGORY_LIST",
    COMPLAINS: "COMPLAINS",
    COMPLAINS_NOTES_ADD: "COMPLAINS_NOTES_ADD",
    COMPLAINS_LIST: "COMPLAINS_LIST",
    CREATE_INVOICE: "CREATE_INVOICE",
    PAY_PENDING_INVOICE: "PAY_PENDING_INVOICE",
    INVOICE_DETAILS: "INVOICE_DETAILS",
    NOTES: "NOTES",
    ORDERS:"ORDERS",
    UPDATE_PROJECT_FLAGS:"UPDATE_PROJECT_FLAGS",
    UPDATE_PROJECT_STATUS:"UPDATE_PROJECT_STATUS",
    
    EXPENSE_CREATE:"EXPENSE_CREATE",
    EXPENSES_LIST:"EXPENSE_LIST",

    ACTIVITY_CREATE:"ACTIVITY_CREATE",
    ACTIVITY_LIST:"ACTIVITY_LIST",

    PRE_DEFINED_EXPENSE_CREATE:"PRE_DEFINED_EXPENSE_CREATE",
    PRE_DEFINED_EXPENSES_LIST:"PRE_DEFINED_EXPENSES_LIST",

    FINANCE_LIST:"FINANCE_LIST",
    PO_DOWNLOAD:"PO_DOWNLOAD",
    FB_INTEGRATION:"FB_INTEGRATION",
    INVOICE_DOWNLOAD:"INVOICE_DOWNLOAD",



    OPEN_SOLAR_INTEGRATION_SIGNIN:"OPEN_SOLAR_INTEGRATION_SIGNIN",
    OPEN_SOLAR_INTEGRATION_USER:"OPEN_SOLAR_INTEGRATION_USER",

    PIPELINE_USER_MODAL:"PIPELINE_USER_MODAL",
    TAGS_MODAL:"TAGS_MODAL",

    ENERGY_PARTNER_VIEW:"ENERGY_PARTNER_VIEW",
    ENERGY_PARTNER_CREATE:"ENERGY_PARTNER_CREATE",
    ENERGY_PARTNER_POSTCODE_ADD:"ENERGY_PARTNER_POSTCODE_ADD",
    ENERGY_PARTNER_SELECT:"ENERGY_PARTNER_SELECT",


    CUSTOMER_SELECTION_MODAL:"CUSTOMER_SELECTION_MODAL",
    ADDRESS_UPDATE_MODAL:"ADDRESS_UPDATE_MODAL",
    ADD_ACTIVITY_LOGS:"ADD_ACTIVITY_LOGS",
    ACTIVITY_LOGS_LIST:"ACTIVITY_LOGS_LIST",
    LEAD_UPDATE_SALES_PERSON:"LEAD_UPDATE_SALES_PERSON",
    LEAD_UPDATE_SOURCE:"LEAD_UPDATE_SOURCE",
    LEAD_UPDATE_MODAL:"LEAD_UPDATE_MODAL",
    LEAD_OPEN_SOLAR_MODAL:"LEAD_OPEN_SOLAR_MODAL",
    DUPLICATE_LEAD:"DUPLICATE_LEAD",
    LEAD_CALENDAR_VIEWORE:"LEAD_CALENDAR_VIEWORE",

    LEAD_FROMS_CREATE:"LEAD_FROMS_CREATE",
    LEAD_FORMS_LIST:"LEAD_FORMS_LIST",
    LEAD_FORMS_DETAIL:"LEAD_FORMS_DETAIL",


    PRODUCT_CATEGORY:"PRODUCT_CATEGORY",
    PRODUCT_MODULE_CREATE:"PRODUCT_MODULE_CREATE",

    UPLOAD_CSV:"UPLOAD_CSV:"
}