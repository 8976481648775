import { memo, useCallback, useEffect, useState } from "react"
import ListUi from "./ListUi"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"

import { closeModal, openModal } from "../../store/actions/modalAction"
import { Box, CircularProgress, IconButton, Switch, Typography } from "@mui/material"
import { useMemo } from "react"
import CreateController from "./CreateController"
import { Delete, Edit } from "@mui/icons-material"
import { MODAL_KEYS } from "../../utils/constants/modal.constant"
import MessageDilog from "../../components/MessageDilog"
import { deleteRejectionReasonApi, getRejectionReasonApi } from "../../apis/rejectionreasons.api"

const DeleteButton = memo(({ params, setParams }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const onDelete = (e) => {
        e?.preventDefault()
        dispatch(closeModal())
        setLoading(true)
        dispatch(callApiAction(
            async () => await deleteRejectionReasonApi({ id: params?._id }),
            (response) => {
                setParams({})
                setLoading(false)
            },
            (err) => {                
                setLoading(false)
            }
        ))

    }

    const onClick = () => {
        dispatch(openModal(<MessageDilog title="Alert!" message="Are you sure to delete" onSubmit={onDelete} />, "sm"))
    }

    if (loading)
        return <CircularProgress />
    return <IconButton onClick={onClick}>
        <Delete color="error" />
    </IconButton>
})
const ActionComponent = memo(({ params, setParams }) => {

    return <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
        
        <DeleteButton params={params} setParams={setParams} />
    </Box>
})



const ListController = () => {
    const dispatch = useDispatch()



    const title = "Rejection Reasons "
    const fetchApi = getRejectionReasonApi
    const deleteApi = null


    const columns = useMemo(() => [

        { id: 1, fieldName: 'title', label: 'Title', align: "left", sort: true },       
        {
            id: 5,
            fieldName: '',
            label: 'Action',

            align: "right",
            renderValue: (params, setParams) => <ActionComponent deleteApi={deleteApi} params={params} setParams={setParams} />,
        },

    ], [dispatch]);


    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 10,
        search: '',
        searchable: ['title'],
        sort: '',
        sortDirection: 1

    })





    // do not change 

    const [loading, setLoading] = useState(false)
    const [list, setList] = useState({})









    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchApi({ ...filters }),
            (response) => {
                setList(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }
    const onCreateBtnClick = () => {
        dispatch(openModal(<CreateController callBack={async () => { fetchList() }} />, "sm",undefined,MODAL_KEYS.REJECTION_REASON))
    }



    useEffect(() => {
        fetchList()
    }, [filters])



    return (
        <>
            <ListUi
                title={title}
                onCreateBtnClick={onCreateBtnClick}

                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}
                columns={columns}


            />

        </>
    )
}
export default ListController