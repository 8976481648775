import { useDispatch, useSelector } from "react-redux"
import { SNACK_BAR_VARIETNS, USER_ROLES } from "../../../../utils/constants/constants"
import { CircularProgress,  MenuItem } from "@mui/material"

import { closeModal, openModal } from "../../../../store/actions/modalAction"
import { MODAL_KEYS } from "../../../../utils/constants/modal.constant"
import MessageDilog from "../../../../components/MessageDilog"
import { useState } from "react"
import { callSnackBar } from "../../../../store/actions/snackbarAction"
import { updateLeadUIDApi } from "../../../../apis/lead.api"
import { callApiAction } from "../../../../store/actions/commonAction"
import { Grid3x3 } from "@mui/icons-material"


const LeadUpdateProjectIdButton = ({ id, callBack = () => { } }) => {
    const [loading, setLoading] = useState(false)
    const { user } = useSelector(state => state)
    const dispatch = useDispatch()
    const submitFun = (e) => {
        e?.preventDefault()
        setLoading(true)
        dispatch(
            callApiAction(
                async () => await updateLeadUIDApi({ id }),
                async (response) => {

                    await callBack(response)
                    setLoading(false)
                    dispatch(closeModal(MODAL_KEYS.LEAD_UPDATE_MODAL))
                    dispatch(callSnackBar("UId updated successfully", SNACK_BAR_VARIETNS.suceess))

                },
                (err) => {
                    setLoading(false)
                    dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
                }
            )
        )
    }

    const onOpenModalClick = () => {
        dispatch(openModal(<MessageDilog modalKey={ MODAL_KEYS.LEAD_UPDATE_MODAL} onSubmit={submitFun} title="Alert" message="Are you sure to update UID of project?" />, "xs", undefined, MODAL_KEYS.LEAD_UPDATE_MODAL))
    }


    if (user.data.role == USER_ROLES.admin)
        return <MenuItem disabled={loading} onClick={onOpenModalClick}><Grid3x3 fontSize='inherit' />&nbsp;{loading && <CircularProgress size={10} sx={{mr:2}} />}UID</MenuItem>
    return <></>



}
export default LeadUpdateProjectIdButton