import { Alert, AlertTitle, Box, Typography } from "@mui/material"
import CustomDialog from "../../../components/layouts/common/CustomDialog"
import { MODAL_KEYS } from "../../../utils/constants/modal.constant"
import LeadCard from "../components/LeadCard"

const DuplicateLeadComponent = ({ leads,onReassignLead }) => {
    return <CustomDialog
        id={MODAL_KEYS.DUPLICATE_LEAD}
        title="Duplicate Leads"
    >
        <Alert severity="warning" mb={2} >
            <AlertTitle lineHeight="100%">Duplication Alert</AlertTitle>
            <Typography lineHeight="100%" variant="caption">{leads?.length} duplicate lead found</Typography>
            <Box>
                <Typography lineHeight="100%" variant="caption">
                    If you want to create a new lead with the same contact, close this popup and <Typography fontWeight={600} display="inline" fontSize="inherit">attach the existing contact</Typography> . Alternatively, you can <Typography fontWeight={600} display="inline" fontSize="inherit"> reassign the lead </Typography> by double tap on sales person icon.
                </Typography>
            </Box>
        </Alert>
        <Box mt={2} px={3}>
            {
                leads?.map(item => <LeadCard
                    key={item?.uid}
                    favourite={item?.is_favourite}
                    size="large"
                    uid={item?.uid}
                    projectId={item?._id}
                    address={item?.full_address}
                    status={item?.lead_status}
                    step={item?.step}
                    name={item?.contacts?.[0]?.full_name}
                    customerId={item?.contacts?.[0]?.customer_uid}
                    email={item?.contacts?.[0]?.email}
                    phone={item?.contacts?.[0]?.phone}
                    createdAt={item?.createdAt}
                    updatedAt={item?.lead_updated_date}
                    assignedSalesPerson={item?.assigned_sales_person}
                    onSalesPersonChange={onReassignLead}

                />)
            }
        </Box>
    </CustomDialog>
}
export default DuplicateLeadComponent