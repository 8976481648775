import { CalendarMonth } from "@mui/icons-material";
import {
  List,
  ListSubheader,
  Paper,
  Box,
  Typography,
  useTheme,
  ButtonBase,
} from "@mui/material";
import SubmitButton from "../../components/button/SubmitButton";
import { useDispatch } from "react-redux";
import { CenteredBox } from "../../components/layouts/OneViewBox";

const DashBox = ({ showTodayDate, showDate, title,onViewMoreClick, ...props }) => {
  return (
    <Paper
      elevation={0}
      component={Box}
      height={"100%"}
      sx={(theme) => ({
        border: 1,
        borderColor: "divider",
        overflow: "hidden",
      })}
    >
      <Box 
        height={"100%"}
        display="flex"
        overflow="hidden"
        flexDirection="column"
        flex={1}
      >
      <List
        sx={{
          width: "100%",
          maxWidth: "100%",
          bgcolor: "background.paper",
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader
        
            sx={{
              borderBottom: "1.5px solid lightgrey",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p:2
            }}
          >
            <Typography
              variant="h6"
              fontWeight={"bold"}
              sx={{ color: "text.primary" }}
            >              
              {title}
            </Typography>
          
          </ListSubheader>
        }
      ></List>
      <Box sx={{display:"flex",flex:1,flexDirection:"column"}}>{props.children}</Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }} m={3}>
        
        {
          onViewMoreClick &&  <CenteredBox>
            <ButtonBase onClick={onViewMoreClick} >
              <Typography sx={{textDecoration:"underline"}}>
                View More
              </Typography>
            </ButtonBase>
          </CenteredBox>
        }
   
      </Box>
      </Box>
    </Paper>
  );
};
export default DashBox;
