import { Grid, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import { memo } from "react";
import moment from "moment";
import { AccessTime, Campaign } from "@mui/icons-material";

const DetailComponent = ({ value }) => {
  return (
    <Paper variant="outlined" component={Box} p={2}>
      <Box sx={{ width: "100%" }} my={2}>
        <Grid container spacing={1}>
          <Grid item xs={7}>
            <Typography variant="body1" fontWeight={400} ml={2}>
              {value ?? "NA"}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

const DetailsUI = memo(({ params }) => {
  return (
    <CustomDialog id="description-list" title="Announcements">
      {/* <Box sx={{ width: "100%" }}>
        <DetailComponent
          title="Announcement Description"
          value={
            <Box
              dangerouslySetInnerHTML={{
                __html: params?.announcement_description,
              }}
            ></Box>
          }
        />
      </Box> */}
      <>
        {
          <Box
            p={4}
            sx={{
              border: "2px solid",
              borderColor: "lightgrey",
              borderRadius: "10px",
            }}
          >
            <Grid container spacing={2} rowSpacing={3}>
              <Grid xs={6} item>
                <Typography variant="h5">
                  {params.announcement_title}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  <Grid container spacing={1} alignItems="center">
                    <Grid item mt={1}>
                      <Campaign sx={{ fontSize: 18 }} />
                    </Grid>
                    <Grid item>
                      <Typography variant="caption" color="textSecondary">
                        Announced By:- {params?.announced_by?.name ?? "NA"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  <Grid container spacing={1} alignItems="center">
                    <Grid item mt={1}>
                      <AccessTime sx={{ fontSize: 18 }} />
                    </Grid>
                    <Grid item>
                      <Typography variant="caption" color="textSecondary">
                        {moment(params.announcement_date).format("DD-MMM-YYYY")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
              <Grid xs={12} item>
                <Box
                  sx={{
                    bgcolor: "#F5F5F5",
                    borderColor: "lightgrey",
                    borderRadius: "10px",
                  }}
                >
                  <Typography
                    p={3}
                    variant="body1"
                    color="textSecondary"
                    sx={{ overflowWrap: "break-word" }}
                    dangerouslySetInnerHTML={{
                      __html: params.announcement_description,
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        }
      </>
    </CustomDialog>
  );
});

export default DetailsUI;
