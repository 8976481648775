import { memo, useCallback, useEffect, useState } from "react"
import ListUi from "./ListUi"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
// import { deleteUserApi, getUserApi, updateUserField } from "../../apis/user.api"
import { openModal } from "../../store/actions/modalAction"
import {  titleCase } from "../../utils/helpers/helper"
import { Box, Button,  IconButton, Tooltip, Typography } from "@mui/material"
import { useMemo } from "react"
import CreateController from "./CreateController"
import { Info, WbTwilight } from "@mui/icons-material"

import getProjectsApi from "../../apis/projects.api"
import { Link, useNavigate } from "react-router-dom"
import fileDownload from "js-file-download"
import moment from "moment"
import ProjectDetailsController from "./projectdetails/ProjectDetailsController"

import { MODAL_KEYS } from "../../utils/constants/modal.constant"
import ProjectStatus from "./projectdetails/ProjectStatus"
import { CallButton, MailButton, OpenInOpenSolarButton } from "./projectdetails/buttons/ProjectIconButtons"
import { PROJECT_PARENT_STATUS } from "../../utils/constants/project.constant"
import { EMAIL_TEMPLATE_TYPE } from "../../utils/constants/emailTemplate.constant"


const ActionComponent = memo(({ params, setParams }) => {
    const dispatch = useDispatch()

    const navigate = useNavigate()



    const onClick = () => {
        dispatch(openModal(<ProjectDetailsController project={params._id} />, 'xl', undefined, MODAL_KEYS.PROJECT))
    }


    return <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
        <Tooltip arrow  title="View more">
            <IconButton size="inherit" onClick={onClick}>
                <Info color="info" fontSize="inherit" />
            </IconButton>
        </Tooltip>

        <MailButton email={params?.contacts?.[0]?.email} subjectSuffix={params?.uid} projectId={params?._id} emailTemplateType={EMAIL_TEMPLATE_TYPE.PROJECT} />
        <CallButton phone={params?.contacts?.[0]?.phone} />

        
        <OpenInOpenSolarButton open_solar_project_id={params.third_party?.open_solar_project_id} />



    </Box>
})

const ProjectNo = memo(({ params }) => {
    const dispatch = useDispatch()
    const onClick = () => {
        dispatch(openModal(<ProjectDetailsController project={params._id} />, 'xl', undefined, MODAL_KEYS.PROJECT))
    }
    return <Button size="small" onClick={onClick} >
        <Typography variant="body1" color="info.main" >
            {params.uid}
        </Typography>
    </Button>
})






const ListController = ({ type }) => {
    const dispatch = useDispatch()

    const { user } = useSelector(state => state)

    const title = "Projects "
    const fetchApi = getProjectsApi

    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 10,
        searchable: ['uid', 'contacts.full_name', 'contacts.email', 'contacts.phone'],
        search: '',
        sort: 'updatedAt',
        status: null,
        sortDirection: -1,
        type,
        assigned_sales_person: null,

        assigned_installer: null,
        allInstalled: null,
        parentStatus: null,

    })
    const columns = useMemo(() => {
        const columnsFields = [

        ]
        columnsFields.push({
            id: -1, fieldName: 'createdAt', label: 'Creation Date', align: "left",
            renderValue: (params, setParams) => params.createdAt ? moment(params.createdAt).format("DD/MM/YYYY") : "NA"
        })
        if(!filters.parentStatus ||filters.parentStatus == PROJECT_PARENT_STATUS.LEADS || filters.parentStatus == PROJECT_PARENT_STATUS.PRE_APPROVALS ){
            columnsFields.push({
                id: 0, fieldName: 'approval_date', label: 'Approval Date', align: "left",
                renderValue: (params, setParams) => params.approval_date ? moment(params.approval_date).format("DD/MM/YYYY") : "NA"
            })
        }
      
        if(!filters.parentStatus ||filters.parentStatus == PROJECT_PARENT_STATUS.LEADS || filters.parentStatus == PROJECT_PARENT_STATUS.INSTALLATION ){
            columnsFields.push({
                id: 51, fieldName: 'installation_date', label: 'Installation Date', align: "left",
                renderValue: (params, setParams) => params.installtion_date ? moment(params.installtion_date).format("DD/MM/YYYY") : "NA"
            })
        }
      
       
        columnsFields.push({
            id: 1, fieldName: 'uid', label: 'Project Id', align: "left",
            renderValue: (params, setParams) => params?.contacts?.[0]?.full_name ?
                <ProjectNo params={params} /> : "NA"
        })
        columnsFields.push({
            id: 3, fieldName: 'name', minWidth: '200px', label: 'Customer Name', align: "left",
            renderValue: (params, setParams) =>  params?.contacts?.[0]?.full_name && titleCase( params?.contacts?.[0]?.full_name),
        })

        if(filters.parentStatus == PROJECT_PARENT_STATUS.LEADS || filters.parentStatus == PROJECT_PARENT_STATUS.PRE_APPROVALS ){
            columnsFields.push({
                id: 50, fieldName: 'documents', label: 'Documents', align: "left",
                renderValue: (params, setParams) => params.documents?.sort?.((a,b)=>a?.name?.localeCompare(b?.name))?.map?.(item=><Typography variant="caption" display="block" >- {item?.name}</Typography>)??"NA"
            })
        }
        
        if (filters.parentStatus == PROJECT_PARENT_STATUS.LEADS || filters.parentStatus == PROJECT_PARENT_STATUS.COMPLETED || filters.parentStatus == PROJECT_PARENT_STATUS.REJECTED) {
            columnsFields.push(
                {
                    id: 4, fieldName: 'assigned_sales_person', minWidth: '90px', label: 'Sales Person', align: "left",
                    renderValue: (params, setParams) => (params.assigned_sales_person && (params.assigned_sales_person.name)) ?? "NA",
                }
            )
        }
        if (filters.parentStatus && filters.parentStatus != PROJECT_PARENT_STATUS.LEADS) {
            columnsFields.push(
                {
                    id: 4, fieldName: 'selected_system.kw_stc', minWidth: '90px', label: 'System', align: "left",
                    renderValue: (params, setParams) => (params.selected_system && (params.selected_system.kw_stc + " KW")) ?? "NA",
                }
            )
        }

       










        columnsFields.push(
            {
                id: 13, fieldName: 'status', label: 'Status', align: "left", sort: true,
                renderValue: (params, setParams) => <ProjectStatus status={params?.status} chip />
            }
        )
        columnsFields.push(
            {
                id: 16, fieldName: 'Action', label: 'Action', align: "left",
                renderValue: (params, setParams) => <ActionComponent params={params} setParams={setParams} />
            }
        )
        return columnsFields
    }, [dispatch, filters.parentStatus]);








    // do not change 

    const [loading, setLoading] = useState(false)
    const [list, setList] = useState({})

    const [exportLoading, setexportLoading] = useState(false)



    const fetchList = (isExport) => {



        if (!isExport) {
            setLoading(true)
            dispatch(callApiAction(
                async () => await fetchApi({ ...filters }),
                (response) => {
                    setList(response)
                    setLoading(false)
                },
                (err) => {
                    setLoading(false)
                }
            ))
        } else {
            setexportLoading(true)
            dispatch(callApiAction(
                async () => await fetchApi({ ...filters, csv: true }),
                (response) => {
                    fileDownload(response, "projects.csv")
                    setexportLoading(false)
                },
                (err) => {
                     
                    setexportLoading(false)
                },
                true
            ))
        }
    }

    const onExportBtnClick = () => { fetchList(true) }

    useEffect(() => {
        fetchList()
    }, [filters])


    const onCreateBtnClick = () => {
        dispatch(openModal(<CreateController callBack={() => {
            fetchList()
        }} />, 'sm', false))
    }
    return (
        <>
            <ListUi
                title={title}
                onCreateBtnClick={onCreateBtnClick}

                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}
                columns={columns}

                onExportBtnClick={onExportBtnClick}
                exportLoading={exportLoading}
            />

        </>
    )
}
export default ListController