import { Box, Grid, IconButton, Popover, Step, StepContent, StepLabel, Stepper, Typography, styled } from "@mui/material"
import { TitleBox } from "../../../components/layouts/common/boxes"
import { CenteredBox } from "../../../components/layouts/OneViewBox"
import { Cancel, CheckCircle, Close, Done, Error, MoreVert, PriorityHigh, Warning } from "@mui/icons-material"
import { PROJECT_FLAGS } from "../../../utils/constants/project.constant"
import { getStatusOfProjectFlags } from "../../../utils/helpers/project.helper"
import moment from "moment"
import { useState } from "react"

const StepButton = styled(CenteredBox)(({ theme }) => ({
    height: "25px",
    width: "25px",
    borderRadius: "100%",
    background: "white"
}))
const StepIndicator = styled(Box)(({ theme }) => ({
    width: "3px", background: theme.palette.primary.main, position: "absolute", height: "100%", left: "12.5px", zIndex: 0
}))
const PopOverButton = ({ defaultId, children }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? defaultId : undefined;

    return (
        <Box>
            <IconButton sx={{ padding: 0 }} aria-describedby={id} onClick={handleClick}>
                <MoreVert fontSize="small" color="primary" />
            </IconButton>
            <Popover
                elevation={1}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box sx={{ minWidth: "200px" }} >
                    {children}
                </Box>
            </Popover>
        </Box>
    );
}
const StepButtonComponent = ({ active, rejected, title, description, noLine }) => {
    return <Box sx={{ display: "flex", alignItems: "flex-start", flex: 1, position: "relative" }} mb={2} >
        {!noLine && <StepIndicator />}
        <StepButton active={active} rejected={rejected} zIndex={1} >
            <Typography variant="h5" align="center" lineHeight="100%" sx={{ verticalAlign: "center" }}>
                {rejected && <Cancel fontSize="inherit" color="error" />}
                {active && <CheckCircle fontSize="inherit" color="primary" />}
                {!active && !rejected && <Error fontSize="inherit" sx={{ color: "primary.light" }} />}
            </Typography>
        </StepButton>
        <Box ml={1} sx={{ display: "flex", flex: 1, flexDirection: "column" }}  >
            <Typography variant="caption" fontFamily={"Public Sans"} fontWeight={800} >{title}</Typography>
            <Typography variant="caption" color="grey" fontWeight={500} >{description}</Typography>
        </Box>
    </Box>
}



const InstallationTrakker = ({ data }) => {

    const flags = data?.flags
    return <TitleBox title={"Installation"} titleProps={{ p: 1 }} childrenProps={{ p: 2 }} >
        <Box sx={{ display: "flex", justifyContent: "space-between", minHeight: "100%" }} flexDirection="column">
            <StepButtonComponent
                active={flags?.[PROJECT_FLAGS.PURCHASE_ORDER] >= getStatusOfProjectFlags(PROJECT_FLAGS.PURCHASE_ORDER).CREATED}
                title={"Order Created"}
                description={data?.assigned_suppliers?.length > 0 ? <Box>
                    <Typography lineHeight="100%" variant="caption" fontWeight={600} >Suppliers:</Typography>
                    <Typography lineHeight="100%" variant="caption" display="block" fontWeight={400}>{data?.assigned_suppliers?.map((item) => item?.name)?.join(" | ")}</Typography>
                </Box> : undefined}
            />
            <StepButtonComponent
                active={flags?.[PROJECT_FLAGS.PURCHASE_ORDER] >= getStatusOfProjectFlags(PROJECT_FLAGS.PURCHASE_ORDER).RECIEVED}
                title={"Order Recieved"}
            />
            <StepButtonComponent

                active={flags?.[PROJECT_FLAGS.INSTALLATION] >= getStatusOfProjectFlags(PROJECT_FLAGS.INSTALLATION).SCHEDULED}

                title={"Installation Scheduled"}
                description={flags?.[PROJECT_FLAGS.INSTALLATION] >= getStatusOfProjectFlags(PROJECT_FLAGS.INSTALLATION).SCHEDULED ? <Box>
                    <Typography lineHeight="100%" variant="caption" display="block" fontWeight={400}>{moment(data?.installtion_date).format("DD/MM/YYYY")}</Typography>
                    <Typography lineHeight="100%" variant="caption" fontWeight={600} >Installer:</Typography>
                    <Typography lineHeight="100%" variant="caption" display="block" fontWeight={400}>{data?.assigned_installer?.name}</Typography>
                </Box> : undefined}
            />
            <StepButtonComponent
                active={flags?.[PROJECT_FLAGS.INSTALLATION] >= getStatusOfProjectFlags(PROJECT_FLAGS.INSTALLATION).COMPLETED}

                noLine title={"Installation Completed"}
            />


        </Box>
    </TitleBox>
}

const STCTrakker = ({ data }) => {
    const flags = data?.flags
    return <TitleBox title="STC" titleProps={{ p: 1 }} childrenProps={{ p: 2 }} >
        <Box sx={{ display: "flex", justifyContent: "space-between", minHeight: "100%" }} flexDirection="column">
            <StepButtonComponent

                active={flags?.[PROJECT_FLAGS.STC_CLAIMED] >= getStatusOfProjectFlags(PROJECT_FLAGS.STC_CLAIMED).APPLIED}

                title={"STC Applied"}
            />
            <StepButtonComponent
                active={flags?.[PROJECT_FLAGS.STC_CLAIMED] >= getStatusOfProjectFlags(PROJECT_FLAGS.STC_CLAIMED).CLAIMED}


                title={"STC Claimed"}

                description={data?.stc_details?.claimed_date ? <Box>
                    <Typography lineHeight="100%" variant="caption" display="block" fontWeight={400}>{moment(data?.stc_details?.claimed_date).format("DD/MM/YYYY")}</Typography>
                    <Typography lineHeight="100%" variant="caption" fontWeight={600} >Ref No:</Typography>
                    <Typography lineHeight="100%" variant="caption" display="block" fontWeight={400}>{data?.stc_details?.ref_no}</Typography>
                </Box> : undefined}
            />
            <StepButtonComponent
                active={flags?.[PROJECT_FLAGS.STC_CLAIMED] >= getStatusOfProjectFlags(PROJECT_FLAGS.STC_CLAIMED).APPROVED}
                noLine title={"Payment Verified"} />


        </Box>
    </TitleBox>
}


const SolarVictoriaTrakker = ({ data }) => {

    const flags = data?.flags

    return <TitleBox title="Solar Victoria" titleProps={{ p: 1 }} childrenProps={{ p: 2 }} >
        <Box sx={{ display: "flex", justifyContent: "space-between", minHeight: "100%" }} flexDirection="column">
            <StepButtonComponent

                active={flags?.[PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL] >= getStatusOfProjectFlags(PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL).APPLIED}
                rejected={flags?.[PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL] == getStatusOfProjectFlags(PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL).NOT_APPLICABLE}

                title={"Pre Applied"}
                description={data?.victoria_details?.applied_date ? <Box>
                    <Typography lineHeight="100%" variant="caption" display="block" fontWeight={400}>{moment(data?.victoria_details?.applied_date).format("DD/MM/YYYY")}</Typography>
                    <Typography lineHeight="100%" variant="caption" fontWeight={600} >INS No:</Typography>
                    <Typography lineHeight="100%" variant="caption" display="block" fontWeight={400}>{data?.victoria_details?.ref_no}</Typography>
                    
                </Box> : undefined}
            />
            <StepButtonComponent
                active={flags?.[PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL] == getStatusOfProjectFlags(PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL).APPROVED}
                rejected={flags?.[PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL] == getStatusOfProjectFlags(PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL).REJECTED}

                title={"Pre Approved"}

                description={data?.victoria_details?.approval_date ? <Box>
                    <Typography lineHeight="100%" variant="caption" display="block" fontWeight={400}>{moment(data?.victoria_details?.approval_date).format("DD/MM/YYYY")}</Typography>

                </Box> : undefined}
            />
            <StepButtonComponent
                active={flags?.[PROJECT_FLAGS.SOLAR_VICTORIA_QR] == getStatusOfProjectFlags(PROJECT_FLAGS.SOLAR_VICTORIA_QR).SCANNED}

                title={"QR Scan"} />
            <StepButtonComponent
                active={flags?.[PROJECT_FLAGS.SOLAR_VICTORIA_POST_APPROVAL] >= getStatusOfProjectFlags(PROJECT_FLAGS.SOLAR_VICTORIA_POST_APPROVAL).CLAIMED}
                description={data?.victoria_details?.claimed_date ? <Box>
                    <Typography lineHeight="100%" variant="caption" display="block" fontWeight={400}>{moment(data?.victoria_details?.claimed_date).format("DD/MM/YYYY")}</Typography>
                    <Typography lineHeight="100%" variant="caption" fontWeight={600} >INS No:</Typography>
                    <Typography lineHeight="100%" variant="caption" display="block" fontWeight={400}>{data?.victoria_details?.claimed_ref_no}</Typography>
                </Box> : undefined}

                title={"Claimed"} />
            <StepButtonComponent
                active={flags?.[PROJECT_FLAGS.SOLAR_VICTORIA_POST_APPROVAL] >= getStatusOfProjectFlags(PROJECT_FLAGS.SOLAR_VICTORIA_POST_APPROVAL).APPROVED}
                noLine title={"Payment Verified"}
            />


        </Box>
    </TitleBox>
}

const GridApplicationTrakker = ({ data }) => {

    const flags = data?.flags
    return <TitleBox title="Grid Connection" titleProps={{ p: 1 }} childrenProps={{ p: 2 }} >
        <Box sx={{ display: "flex", justifyContent: "space-between", minHeight: "100%" }} flexDirection="column">
            <StepButtonComponent
                active={flags?.[PROJECT_FLAGS.GRID_PRE_APPROVAL] >= getStatusOfProjectFlags(PROJECT_FLAGS.GRID_PRE_APPROVAL).APPLIED}
                title={"Pre Approval Applied"}
                description={
                    data?.pre_approval_details?.applied_date ? <Box>
                        <Typography lineHeight="100%" variant="caption" display="block" fontWeight={400}>{moment(data?.pre_approval_details?.applied_date).format("DD/MM/YYYY")}</Typography>
                        <Typography lineHeight="100%" variant="caption" fontWeight={600} >Ref No:</Typography>
                        <Typography lineHeight="100%" variant="caption" display="block" fontWeight={400}>{data?.pre_approval_details?.applied_ref_no}</Typography>
                        
                    </Box> : undefined}

            />
            <StepButtonComponent
                active={flags?.[PROJECT_FLAGS.GRID_PRE_APPROVAL] == getStatusOfProjectFlags(PROJECT_FLAGS.GRID_PRE_APPROVAL).APPROVED}
                rejected={flags?.[PROJECT_FLAGS.GRID_PRE_APPROVAL] == getStatusOfProjectFlags(PROJECT_FLAGS.GRID_PRE_APPROVAL).REJECTED}

                title={"Pre Approval Approved"}

                description={
                    data?.pre_approval_details?.approval_date ? <Box>
                        
                        <Typography lineHeight="100%" variant="caption" display="block" fontWeight={400}>{moment(data?.pre_approval_details?.approval_date).format("DD/MM/YYYY")}</Typography>
                        <Typography lineHeight="100%" variant="caption" fontWeight={600} >Ref No:</Typography>
                        <Typography lineHeight="100%" variant="caption" display="block" fontWeight={400}>{data?.pre_approval_details?.approval_ref_no}</Typography>
                        <Typography lineHeight="100%" variant="caption" fontWeight={600} >Export Limit:</Typography>
                        <Typography lineHeight="100%" variant="caption" display="block" fontWeight={400}>{data?.pre_approval_details?.approved_export_limit}</Typography>
                    </Box> : undefined}

            />
            <StepButtonComponent
                active={flags?.[PROJECT_FLAGS.GRID_POST_APPROVAL] >= getStatusOfProjectFlags(PROJECT_FLAGS.GRID_POST_APPROVAL).APPLIED}

                title={"Post Approval Applied"}
                description={
                    data?.inspection_field?.application_date ? <Box>
                        <Typography lineHeight="100%" variant="caption" display="block" fontWeight={400}>{moment(data?.pre_approval_details?.application_date).format("DD/MM/YYYY")}</Typography>
                        <Typography lineHeight="100%" variant="caption" fontWeight={600} >CR No:</Typography>
                        <Typography lineHeight="100%" variant="caption" display="block" fontWeight={400}>{data?.inspection_field?.cr_no}</Typography>
                    </Box> : undefined}

            />
            <StepButtonComponent

                active={flags?.[PROJECT_FLAGS.GRID_POST_APPROVAL] == getStatusOfProjectFlags(PROJECT_FLAGS.GRID_POST_APPROVAL).COMPLETED}

                description={
                    data?.inspection_field?.complition_date ? <Box>
                        <Typography lineHeight="100%" variant="caption" display="block" fontWeight={400}>{moment(data?.pre_approval_details?.complition_date).format("DD/MM/YYYY")}</Typography>
                    </Box> : undefined}

                noLine title={"Post Approval Approved"} />


        </Box>
    </TitleBox>
}

const ProjectFlagTrakker = ({ overflow, data }) => {

    return <Grid container sx={{ height: "100%", overflow: overflow }} spacing={2}>

        <Grid sx={{ height: "100%", overflow: overflow }} item xs={12} md={3} >
            <GridApplicationTrakker data={data} />
        </Grid>
        <Grid sx={{ height: "100%", overflow: overflow }} item xs={12} md={3} >
            <SolarVictoriaTrakker data={data} />
        </Grid>
        <Grid sx={{ height: "100%", overflow: overflow }} item xs={12} md={3} >
            <InstallationTrakker data={data} />
        </Grid>
        <Grid sx={{ height: "100%", overflow: overflow }} item xs={12} md={3} >
            <Box sx={{ display: "flex", height: "100%", overflow, flexDirection: "column" }} >
                <Box sx={{ display: "flex", flex: 1.5, overflow: overflow }} >
                    <STCTrakker data={data} />
                </Box>

                <Box sx={{ display: "flex", flex: 1 }} mt={2} >
                    <TitleBox title="Other Details" titleProps={{ p: 1 }} >
                        <Typography lineHeight="100%" variant="subtitle2" fontWeight={600} >Sales Person:</Typography>
                        <Typography lineHeight="100%" variant="caption" display="block" fontWeight={400}>{data?.assigned_sales_person?.name}</Typography>


                        <Typography mt={1} lineHeight="120%" variant="subtitle2" fontWeight={600} >Incentives:</Typography>

                        {data?.selected_system?.incentives?.map((item) => <Typography lineHeight="120%" variant="caption" display="block" fontWeight={400} key={item?.title}>- {item?.title} ({item?.value}) </Typography>)}

                    </TitleBox>
                </Box>
            </Box>
        </Grid>
    </Grid>
}
export default ProjectFlagTrakker