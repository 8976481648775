import axios from "axios";
import { getHeaders } from "../utils/helpers/helper";
import endpoints from "./endpoints";




export const getPipelineApi = async params => {
  const callResponse = await axios({
    url: endpoints.pipelinBase,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


export const getPipelineByIdApi = async params => {
  const callResponse = await axios({
    url: endpoints.pipelinById,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


export const addPipelineApi = async data => {
  const callResponse = await axios({
    url: endpoints.pipelinBase,
    method: "POST",
    headers: getHeaders(),
    
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const updatPipelineApi = async data => {
  const callResponse = await axios({
    url: endpoints.pipelinBase,
    method: "PUT",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


export const updateDeleteStatusOfPipelineApi = async data => {
  const callResponse = await axios({
    url: endpoints.pipelinBase,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


export const updatePipelineAsDefaultApi = async data => {
  const callResponse = await axios({
    url: endpoints.pipelinDefault,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};




//steps



export const addPipelineStepApi = async data => {
  const callResponse = await axios({
    url: endpoints.pipelinStepBase,
    method: "POST",
    headers: getHeaders(),
    
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const updatePipelineStepApi = async data => {
  const callResponse = await axios({
    url: endpoints.pipelinStepBase,
    method: "PUT",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
export const getPipelineStepByIdApi = async params => {
  const callResponse = await axios({
    url: endpoints.pipelinStepBase,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const updateDeleteStatusOfPipelineStatusApi = async data => {
  const callResponse = await axios({
    url: endpoints.pipelinStepBase,
    method: "DELETE",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


export const updatePrioritiesOfPipelineStepsApi = async data => {
  const callResponse = await axios({
    url: endpoints.pipelinStepBase,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const getPipelineUsersApi = async params => {
  const callResponse = await axios({
    url: endpoints.pipelinUserBase,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


export const addOrRemovePipelineUsers = async data => {
  const callResponse = await axios({
    url: endpoints.pipelinUserBase,
    method: "POST",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
