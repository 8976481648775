
import CustomDialog from "../../components/layouts/common/CustomDialog"
import { Box, CircularProgress, Grid, IconButton, Paper, styled, Tooltip, Typography, useTheme } from "@mui/material"
import { CenteredBox } from "../../components/layouts/OneViewBox"
import { CopyAll } from "@mui/icons-material"
import { copyToClipboard } from "../../utils/helpers/helper"


const FlexedBox = styled(Box)(({ theme }) => ({
    display: "flex",
    flex: 1,

    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
        overflow: "auto",
    }
}))

const SkeletonLoader = () => {

    return <CenteredBox>

        <CircularProgress />
    </CenteredBox>
}





const LeadFormDetailedViewUi = ({ loading, data, setData, title, modalKey }) => {
    const theme = useTheme()
    const iframString = data ? `
    
    <iframe width={${data?.form_width}} height="100%" src={${data?.embed_url}} title={${data?.form_title}} frameborder="0" allow=" clipboard-write;  picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    `: ''

    return (
        <CustomDialog id={modalKey} title={title} dialogProps={{ sx: { height: "5000px" } }}>
            {loading && <SkeletonLoader />}
            {
                !loading && data && <Grid container sx={{ height: "100%" }}>
                    <Grid item xs={8} sx={{ height: "100%" }} >
                        <CenteredBox sx={{ width: "100%", height: "100%" }} p={2}>
                            <Box component={Paper} variant="outlined" p={3} sx={{ height: "100%", maxWidth: "100%", overflow: "auto" }} >
                                <iframe width={data?.form_width} height="100%" src={data?.embed_url} title={data?.form_title} frameborder="0" allow=" clipboard-write;  picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </Box>
                        </CenteredBox>
                    </Grid>
                    <Grid item xs={4} >
                        <Box display="flex" justifyContent="space-between">
                            <Typography>
                                Embed Code to your HTML
                            </Typography>
                            <Tooltip title="Copy Code" >
                                <IconButton size="small" onClick={()=>copyToClipboard(iframString)}>
                                    <CopyAll color="primary" fontSize="inherit"  />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Box sx={{ background: theme.palette.primary.main }} component={Paper} p={3} >
                            <code>
                                <Typography variant="caption" color={theme.palette.primary.light}>
                                    {iframString}
                                </Typography>


                            </code>
                        </Box>
                        <Box display="flex" justifyContent="space-between" mt={3}>
                            <Typography>
                                Form Link
                            </Typography>
                            <Tooltip title="Copy" >
                                <IconButton size="small" onClick={() => copyToClipboard(data.embed_url)} >
                                    <CopyAll color="primary" fontSize="inherit" />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Box sx={{ background: theme.palette.primary.main }} component={Paper} p={3} >
                            <code>
                                <Typography variant="caption" color={theme.palette.primary.light} >
                                    {data.embed_url}
                                </Typography>


                            </code>
                        </Box>
                    </Grid>
                </Grid>
            }
        </CustomDialog>
    )
}
export default LeadFormDetailedViewUi