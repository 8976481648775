
import { CalendarMonth, Call, Cancel, CheckCircle, ElectricBolt, Email, FmdGood, Map, MonetizationOn, OpenInNew, People, Phone, Pin, SolarPower } from "@mui/icons-material"
import { Autocomplete, Button, ButtonBase, ButtonGroup, Chip, CircularProgress, Divider, Grid, IconButton, Paper, Skeleton, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tabs, TextField, Tooltip, Typography, styled, useTheme } from "@mui/material"
import { Box } from "@mui/system"
import { createAddress, findObjectKeyByValue, groupByArray, projectStatusColor, titleCase, viewLimitedLengthName } from "../../../utils/helpers/helper"
import { CenteredBox } from "../../../components/layouts/OneViewBox"
import { PHASE_TYPE, PROPERTY_TYPE, ROOF_PITCH_TYPE, ROOF_TYPES, STOCK_TYPE, STOREY_TYPE, SWITCH_BOARD } from "../../../utils/constants/project.constant"
import CustomDialog from "../../../components/layouts/common/CustomDialog"
import { useDispatch } from "react-redux"
import { openModal } from "../../../store/actions/modalAction"
import moment from "moment"
import { DEFAULT_PRODUCT_CATEGORY } from "../../../utils/constants/product.constant"


const CustomBox = styled(Box)(({ theme }) => ({
    width: "100%", height: "100%",
    background: theme.palette.secondary.main,
    display: "flex",
    overflow: "hidden",
    border: "1px solid  " + theme.palette.primary.main,


}))
const SiteBox = styled(Box)(({ theme, size }) => ({
    width: "100%",
    height: "100%",
    flexDirection: size != "small" ? "column" : 'row-reverse',
    justifyContent: size != "small" ? "center" : "space-evenly",
    alignItems: "center",
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.secondary.main,
    display: "flex",
    overflow: "hidden",
    position: "relative",
    color: theme.palette.primary.main,
    border: "1px solid  " + theme.palette.primary.main,

    paddingLeft: 1,

    "::after": {
        content: "' '",
        position: "absolute",
        top: "0px",
        left: "0px",
        height: "100%",
        width: '10px',
        zIndex: 1,
        background: theme.palette.primary.main,
        // background: theme.palette.primary.light,
    }

}))
const CustomHeaderBox = styled(Box)(({ theme }) => ({
    width: "100%",
    background: theme.palette.primary.main,
    color: theme.palette.primary.light,

}))

const PartnerDetails = ({ contact }) => {

    return <CustomDialog
        id={"partner"}
        title={"Partner Details"}
    >
        <CustomBox component={Paper} flexDirection="column" elevation={0} p={2}>


            <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                <Typography variant="body2" ><People  ></People> </Typography>
                <Typography ml={2} variant="body2"><Chip size="small" label={"#" + contact?.customer_uid + "-" + contact.full_name} />{ }</Typography>
            </Box>

            <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                <Typography variant="body2"><Email /> </Typography>
                <Typography ml={2} variant="body2">{contact.email}</Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                <Typography variant="body2"><Phone ></Phone> </Typography>
                <Typography ml={2} variant="body2">{contact.phone}</Typography>
            </Box>


        </CustomBox>
    </CustomDialog>
}

const ProjectContactDetailsUi = ({ data, showDates }) => {
    const dispatch = useDispatch()
    const contact = data?.contacts[0]
    const onPartnerDetailViewClick = () => {
        dispatch(openModal(<PartnerDetails contact={data?.contacts?.[1]} />, 'xs', undefined, 'partner'))
    }
    return <CustomBox component={Paper} flexDirection="column" elevation={0} p={2}>
        {showDates && <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} mb={2}>

            <Tooltip title="created Date" arrow>
                <Box>
                    <Typography variant="caption" lineHeight="100%" ><CalendarMonth fontSize="inherit" /> {moment(data?.createdAt).format("DD/MM/YYYY HH:mm")}</Typography>
                </Box>
            </Tooltip>
            <Tooltip title="Last Movement Date" arrow >
                <Box>
                    <Typography variant="caption" lineHeight="100%" ><CalendarMonth fontSize="inherit" /> {moment(data?.lead_updated_date).format("DD/MM/YYYY HH:mm")}</Typography>
                </Box>
            </Tooltip>

            {data?.lead_close_date && <Tooltip title="Close Date" arrow >
                <Box>
                    <Typography variant="caption" lineHeight="100%" ><CalendarMonth fontSize="inherit" /> {moment(data?.lead_close_date).format("DD/MM/YYYY HH:mm")}</Typography>
                </Box>

            </Tooltip>}

        </Box>}

        <Box sx={{ display: "flex", alignItems: "flex-start" }}>
            <Typography variant="body2" ><People  ></People> </Typography>
            <Typography ml={2} variant="body2"><Chip size="small" label={"#" + contact?.customer_uid + "-" + contact.full_name} />{ }</Typography>
        </Box>

        <Box sx={{ display: "flex", alignItems: "flex-start" }}>
            <Typography variant="body2"><Email /> </Typography>
            <Typography ml={2} variant="body2">{contact.email}</Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "flex-start" }}>
            <Typography variant="body2"><Phone ></Phone> </Typography>
            <Typography ml={2} variant="body2">{contact.phone}</Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "flex-start" }}>
            <Typography variant="body2"><FmdGood /></Typography>
            <Typography ml={2} variant="body2">{data?.full_address}</Typography>
        </Box>

        {
            data?.contacts?.length > 1 &&
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <ButtonBase onClick={onPartnerDetailViewClick}>
                    <Typography variant="caption" lineHeight="100%" sx={{ textDecoration: "underline", display: "block" }} align="right" >View Partner Details</Typography>
                </ButtonBase>
            </Box>
        }

    </CustomBox>
}
export default ProjectContactDetailsUi


const ModuleComponent = ({ code, quantity }) => {
    const theme = useTheme()
    return <Box sx={{ display: "flex", borderRadius: 1, overflow: "hidden", alignItems: "stretch", minHeight: "100%", width: "100%" }}>
        <Box px={1} sx={{ minWidth: "30px", background: theme.palette.primary.main, minHeight: "100%", flex: "none" }} py={1}>
            <CenteredBox>
                <Typography color="primary.light" lineHeight="100%" fontSize="10px" >
                    {quantity}
                </Typography>

            </CenteredBox>
        </Box>
        <Box sx={{ display: "flex", background: theme.palette.primary.light, flex: 1 }} px={1} py={1} >
            <Typography lineHeight="100%" fontSize="10px" >
                {code}
            </Typography>
        </Box>


    </Box>
}
export const ProjectSelectedSystemDetailsUi = ({ data }) => {
    const selectedSystem = data?.selected_system
    const products = data?.selected_system?.products
    const productObj = products ? groupByArray(products, 'productcategory') : {}

    return <CustomBox sx={{ background: "unset", maxHeight: "200px", overflow: "auto" }} component={Paper} flexDirection="column" elevation={0}>

        {!selectedSystem ? <CenteredBox><Typography variant="h6" >
            No System Selected
        </Typography></CenteredBox> : <>

            <CustomHeaderBox display="flex" justifyContent="space-around" alignItems="center" p={2} >
                <Typography display="flex" justifyContent="space-around" alignItems="center" variant="body2" lineHeight="100%" >
                    <SolarPower fontSize="inherit" /> &nbsp;{selectedSystem?.kw_stc} KW
                </Typography>
                <Typography display="flex" justifyContent="space-around" alignItems="center" variant="body2" lineHeight="100%" >
                    <ElectricBolt fontSize="inherit" />&nbsp;{selectedSystem?.annual_output_kwh?.toFixed?.(2) ?? "0"} KWH
                </Typography>
                <Typography display="flex" justifyContent="space-around" alignItems="center" variant="body2" lineHeight="100%" >
                    <MonetizationOn fontSize="inherit" />&nbsp;{selectedSystem?.amount_including_tax?.toFixed?.(2) ?? "0"}
                </Typography>
            </CustomHeaderBox>


            <Box p={2}>
                <Grid container spacing={2} >

                    <Grid item xs={6} >
                        <Box sx={{ display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
                            <Typography variant="body2" fontWeight={600}>Panels: </Typography>
                            <Box width="100%" >
                                {productObj[titleCase(DEFAULT_PRODUCT_CATEGORY.PANELS)]?.length > 0 ? productObj[titleCase(DEFAULT_PRODUCT_CATEGORY.PANELS)]?.map((item) => <ModuleComponent code={item.code} quantity={item.quantity} key={item?.code} />) : "NA"}
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={6} >
                        <Box sx={{ display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
                            <Typography variant="body2" fontWeight={600}>Inverters: </Typography>
                            <Box width="100%" sx={{ rowGap: 1,display:"grid" }} >
                                {productObj[titleCase(DEFAULT_PRODUCT_CATEGORY.INVERTERS)]?.length > 0 ? productObj[titleCase(DEFAULT_PRODUCT_CATEGORY.INVERTERS)]?.map((item) => <ModuleComponent code={item.code} quantity={item.quantity} key={item?.code} />) : "NA"}
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={6} >
                        <Box sx={{ display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
                            <Typography variant="body2" fontWeight={600}>Batteries: </Typography>
                            <Box width="100%" sx={{ rowGap: 2 }} >
                                {productObj[titleCase(DEFAULT_PRODUCT_CATEGORY.BATTERIES)]?.length > 0 ? productObj[titleCase(DEFAULT_PRODUCT_CATEGORY.BATTERIES)]?.map((item) => <ModuleComponent code={item.code} quantity={item.quantity} key={item?.code} />) : "NA"}
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={6} >
                        <Box sx={{ display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
                            <Typography variant="body2" fontWeight={600}>Others: </Typography>
                            <Box width="100%" sx={{ rowGap: 2 }} >
                                {productObj[titleCase(DEFAULT_PRODUCT_CATEGORY.OTHER)]?.length > 0 ? productObj[titleCase(DEFAULT_PRODUCT_CATEGORY.OTHER)]?.map((item) => <ModuleComponent code={item.code} quantity={item.quantity} key={item?.code} />) : "NA"}
                            </Box>
                        </Box>
                    </Grid>

                    {
                        Object.keys(productObj)
                            .filter((productCategory) => ![titleCase(DEFAULT_PRODUCT_CATEGORY.PANELS), titleCase(DEFAULT_PRODUCT_CATEGORY.INVERTERS), titleCase(DEFAULT_PRODUCT_CATEGORY.BATTERIES), titleCase(DEFAULT_PRODUCT_CATEGORY.OTHER)].includes(productCategory))
                            .map((productCategory) => {
                                return <Grid item xs={6} key={productCategory}>
                                    <Box sx={{ display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
                                        <Typography variant="body2" fontWeight={600}>{titleCase(productCategory)}: </Typography>
                                        <Box width="100%" sx={{ rowGap: 2 }} >
                                            {productObj[productCategory]?.length > 0 ? productObj[productCategory]?.map((item) => <ModuleComponent code={item.code} quantity={item.quantity} key={item?.code} />) : "NA"}
                                        </Box>
                                    </Box>
                                </Grid>
                            })
                        
                    }
                </Grid>


            </Box>

        </>}
    </CustomBox>
}
const SiteComponent = ({ title, value, size }) => {
    return <SiteBox size={size}>
        <Tooltip arrow title={value}>
            <Typography variant={size == "small" ? "caption" : "caption"} >{value ? viewLimitedLengthName(value, 20, false) : "NA"}</Typography>
        </Tooltip>
        <Typography variant="caption" fontWeight={600} color="dark.main" >{title}</Typography>
    </SiteBox>
}
export const ProjectSiteDetailsUi = ({ data }) => {
    const selectedSystem = data?.selected_system
    // if(!selectedSystem){}
    return <>

        <Grid container spacing={1} >
            <Grid item xs={2} >

                <SiteComponent title="Property Type" value={findObjectKeyByValue(data?.property_type, PROPERTY_TYPE) ?? "NA"} />
            </Grid>
            <Grid item xs={2} >

                <SiteComponent title="Storey" value={findObjectKeyByValue(data?.no_of_storeys, STOREY_TYPE) ?? "NA"} />
            </Grid>
            <Grid item xs={2} >

                <SiteComponent title="Roof Type" value={ROOF_TYPES[data?.roof_type] ?? "NA"} />
            </Grid>
            <Grid item xs={2} >

                <SiteComponent title="Roof Pitch" value={findObjectKeyByValue(data?.roof_pitch, ROOF_PITCH_TYPE) ?? "NA"} />
            </Grid>
            <Grid item xs={2} >
                <SiteComponent title="Phase" value={findObjectKeyByValue(data?.phase, PHASE_TYPE) ?? "NA"} />
            </Grid>
            <Grid item xs={2} >
                <SiteComponent title="Switch Board" value={findObjectKeyByValue(data?.switch_board, SWITCH_BOARD) ?? "NA"} />
            </Grid>


            {/* <Grid item xs={2.4} >
                <SiteComponent title="Output (KWH)" value={(selectedSystem?.annual_output_kwh?.toFixed?.(2) ?? "0")} />
            </Grid>
            <Grid item xs={2.4} >
                <SiteComponent title="Price" value={(selectedSystem?.amount_including_tax?.toFixed?.(2) ?? "0")} />
            </Grid> */}
            <Grid item xs={3} >
                <SiteComponent title="NMI No" value={(data?.nmi_no ?? "NA")} />
            </Grid>
            <Grid item xs={3} >
                <SiteComponent title="Meter No" value={(data?.meter_identifier ?? "NA")} />
            </Grid>
            <Grid item xs={3} >
                <SiteComponent title="Energy Distributor" value={(data?.e_distributor?.name ?? "NA")} />
            </Grid>
            <Grid item xs={3} >
                <SiteComponent title="Energy Retailer" value={(data?.e_retailer?.name ?? "NA")} />
            </Grid>
        </Grid>
    </>
}