import { useState } from "react"
import SubmitButton from "../../../components/button/SubmitButton"
import { Alert, Chip, CircularProgress, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { callApiAction } from "../../../store/actions/commonAction"
import { selectProjectSystem, uploadJobToStcApi } from "../../../apis/projects.api"
import { callSnackBar } from "../../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../../utils/constants/constants"
import { moduleAccessCondition } from "../../../utils/helpers/helper"
import MODULES from "../../../utils/constants/module.constants"

const SelectedSystemButton = ({selected, id,callBack=()=>{} }) => {
    const {user} = useSelector(state=>state)
    const [selectedSystem, setselectedSystem] = useState(selected)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    const onClick = () => {

        setLoading(true)
        dispatch(
            callApiAction(
                async () => await selectProjectSystem({  id }),
                async (response) => {

                    setselectedSystem(id)
                    setLoading(false)
                    callBack()
                    dispatch(callSnackBar("System selected successfully", SNACK_BAR_VARIETNS.suceess))
                },
                (err) => {
                    setLoading(false)
                    dispatch(callSnackBar("System cant be selected", SNACK_BAR_VARIETNS.error))
                }
            )
        )
    }

    if (selectedSystem) {
        return <></>
    }
    if(!moduleAccessCondition(user,[MODULES.UPDATE_LEADS]))
        return <Alert severity="warning"  >
           <Typography fontSize="inherit" >Not allowed to sold manually</Typography> 
            <Typography variant="caption" >Ask "Sold Manually" permission to admin for performing this task</Typography>
        </Alert>
    return <SubmitButton color="success" title="Manually Sold" loading={loading} onClick={onClick} />
}
export default SelectedSystemButton