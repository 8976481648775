import { memo, useEffect, useMemo, useState } from "react"
import useValidate from "../../store/hooks/useValidator"
import CreateUi from "./CreateUi"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { addUserApi, getUserByIdApi, updateInstallerUserField, updateUserField } from "../../apis/user.api"
import { closeModal } from "../../store/actions/modalAction"
import { useParams } from "react-router-dom"
import { USER_ROLES } from "../../utils/constants/constants"
import { addCompanyApi, getCompanyByIdApi, updateCompanyData } from "../../apis/company.api"
import CreateOfficesUi from "./CreateOfficesUi"
import { addOfficesApi, getOfficesByIdApi, updateOfficesField } from "../../apis/offices.api"

const CreateOfficesController = ({callBack = () => {},company_id,id }) => {
    const validate = useValidate()
    const dispatch = useDispatch()
    // const params = useParams()

    const title = "Company Offices"
    const modalKey="offices"
    const createApi = addOfficesApi
    const updateApi = updateOfficesField
    const getByIdApi = getOfficesByIdApi

    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',
        company_id:company_id,
        id,
        office:'',
        state:''

    })
    const [originalDocument, setOriginalDocument] = useState({


    })


    const validationSchemaForCreate = useMemo(() => ([

        // {
        //     required: true,
        //     value: fields.company_id,
        //     field: 'Company Name',
        // },

        {
            required: true,
            value: fields.office,
            field: 'Company Address',
            
        },
        {
            required: true,
            value: fields.state,
            field: 'Company State',
        },

    ]), [fields])

    const validationSchemaForUpdate = useMemo(() => ([

        // {
        //     required: true,
        //     value: fields.company_id,
        //     field: 'Company Name',
        // },

        {
            required: true,
            value: fields.office,
            field: 'Company Address',
            
        },
        {
            required: true,
            value: fields.state,
            field: 'Company State',
        },
    ]), [fields])



    const createFunction = async () => {
        const validationResponse = validate(validationSchemaForCreate)

        if (validationResponse === true) {


            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await createApi({...fields,company_id}),
                    async (response) => {

                        await callBack(response)
                        setLoading(false)
                        dispatch(closeModal(modalKey))
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }


    const updateFunction = async () => {
        const validationResponse = validate(validationSchemaForUpdate)
        let updatedData = { id }
        for (let field in fields) {

            if (originalDocument[field] && fields[field] && fields[field] != originalDocument[field]) {
                updatedData[field] = fields[field]
            }
            if (!originalDocument[field]) {
                updatedData[field] = fields[field]
            }
        }

        if (validationResponse === true) {


            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await updateApi(updatedData),
                    async (response) => {
                        await callBack(response, updatedData)
                        setLoading(false)
                        dispatch(closeModal(modalKey))
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }



    const onSubmit = async (e) => {
        e.preventDefault()
        if (id)
            updateFunction()
        else
            createFunction()

    }



    const fetchById = (id) => {
        setLoading(true)
        dispatch(
            callApiAction(
                async () => await getByIdApi({ id }),
                async (response) => {
                    setFields({ ...fields, ...response })
                    
                    // if (response) {
                    //     const fieldsToBeUpdate = {  ...response }
                    //     // fieldsToBeUpdate['name'] = response.name
                    //     // fieldsToBeUpdate['description'] = response.description
                    //     // fieldsToBeUpdate['short_name'] = response.short_name
                    //     // fieldsToBeUpdate['logo'] = response.logo

                    //     setFields({...fields,...fieldsToBeUpdate})
                    //     setOriginalDocument(fieldsToBeUpdate)
                    // } 
                   
                    setLoading(false)

                },
                (err) => {
                    setFields({ ...fields, err })
                    setLoading(false)

                }
            )
        )
    }

    useEffect(() => {
        if (id)
            fetchById(id)

    }, [id])


    return <CreateOfficesUi  isUpdate={id} callBack={callBack} modalKey={modalKey} title={title} loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
}
export default memo(CreateOfficesController)