import React, { memo, useEffect, useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import { Button, ButtonBase, CircularProgress, FormControlLabel, Grid, IconButton, Input, Paper, Radio, RadioGroup, Typography, useTheme } from '@mui/material';
import { Edit, Label, Opacity, RadioButtonChecked, RadioButtonUnchecked, Sell } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, openModal } from '../../store/actions/modalAction';
import CustomDialog from '../layouts/common/CustomDialog';
import { MODAL_KEYS } from '../../utils/constants/modal.constant';
import { fetchPreDefinedTags } from '../../store/actions/preDefinedTagAction';
import { TitleBox } from '../layouts/common/boxes';
import { CenteredBox } from '../layouts/OneViewBox';
import { removeDuplicates } from '../../utils/helpers/helper';

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
    '.MuiAutocomplete-inputRoot': {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: "flex-start",
        minHeight: "100px"
    },
    '& .MuiChip-root': {
        backgroundColor: theme.palette.action.disabledBackground,
        opacity: 1,
        border: `1px solid ${theme.palette.action.disabled}`,
    },
}));
const TagItem = memo(({ title, selected, selectedTags, setSelectedTags }) => {




    const onBtnClick = () => {

        const oldTags = [...selectedTags]

        if (selected) {
            const searchIndex = oldTags.findIndex((item) => item == title)
            if (searchIndex !== -1) {
                oldTags.splice(searchIndex, 1)
            }
        } else {
            oldTags.push(title)
        }
        setSelectedTags(oldTags)
    }


    return <Grid item xs={6} sm={4} >
        <ButtonBase sx={{ width: "100%", height: "100%" }} onClick={onBtnClick}>
            <Paper display={"flex"} alignItems="center" sx={{ width: "100%", borderColor: selected ? "primary.main" : "divider", height: "100%" }} variant="outlined" component={Box} p={1} >
                <Typography mr={2} lineHeight="100%" >
                    {selected ? <RadioButtonChecked fontSize="inherit" color="primary" /> : <RadioButtonUnchecked fontSize="inherit" color="primary" />}
                </Typography>
                <Typography variant="caption" textAlign="left" >
                    {title}
                </Typography>
            </Paper>
        </ButtonBase>
    </Grid>


})

const CategoryItem = memo(({ category_title, tags, selectedTags, setSelectedTags }) => {
    const theme = useTheme()
    return <Box mb={2}>
        <TitleBox title={category_title} childrenProps={{ p: 3 }} titleProps={{ p: 1, sx: { fontSize: theme.typography.subtitle2, background: "#f2f2f2" } }} >
            <Grid container spacing={1} >
                {
                    tags.map((item) => <TagItem key={item} title={item} setSelectedTags={setSelectedTags} selectedTags={selectedTags} selected={selectedTags?.find((searchItem) => searchItem == item) ? true : false} />)
                }
            </Grid>

        </TitleBox>
    </Box>
})
const PreDefinedTagsModal = ({ tags, onSubmit, type }) => {
    const { preDefinedTags } = useSelector(state => state)
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true)
    const [selectedTags, setSelectedTags] = useState(tags ?? [])
    const onSubmitBtnClick = (e) => {
        e.preventDefault?.()
        onSubmit(selectedTags)
        dispatch(closeModal(MODAL_KEYS.TAGS_MODAL))
    }

    useEffect(() => {

        if (!preDefinedTags?.[type]) {
            dispatch(fetchPreDefinedTags({ type }, () => {
                setLoading(false)
            }, () => {
                setLoading(false)
            }))
        } else {
            setLoading(false)
        }
    }, [setLoading, dispatch])
    return <CustomDialog
        loading={loading}
        title="Predefined Tags"
        id={MODAL_KEYS.TAGS_MODAL}
        onSubmit={onSubmitBtnClick}
    >
        {
            loading && <CenteredBox>
                <CircularProgress />
            </CenteredBox>
        }
        {
            !loading && preDefinedTags && preDefinedTags?.[type] && Array.isArray(preDefinedTags?.[type]) && preDefinedTags?.[type]?.map((item) => <CategoryItem {...item} key={item?._id} selectedTags={selectedTags} setSelectedTags={setSelectedTags} />)
        }
    </CustomDialog>
}

const TagsEditor = ({ value = [], onChange = () => { }, type, onlyView = false, isInputBox, forFilter }) => {
    const dispatch = useDispatch()
    const ref = useRef()
    const [keywords, setKeywords] = useState(value ?? []);
    const [tagsFilterType, setTagsFilterType] = useState('in')
    const [editMode, setEditMode] = useState(isInputBox ?? false)
    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && event.target.value.trim()) {
            setKeywords((prevKeywords) => removeDuplicates([...prevKeywords, event.target.value.trim()]));
            event.target.value = '';
            event.preventDefault();
        } else if (event.key === 'Backspace' && event.target.value === '') {
            setKeywords((prevKeywords) => prevKeywords.slice(0, -1));
        }
    };
    const onAddFromPreDefinedTagsClick = () => {
        dispatch(openModal(<PreDefinedTagsModal type={type} tags={keywords} onSubmit={(selectedTags) => setKeywords(removeDuplicates(selectedTags))} />, 'sm', undefined, MODAL_KEYS.TAGS_MODAL))
    }
    const onceLoaded = useRef(null)
    useEffect(() => {
        if (onceLoaded.current && isInputBox) {
            onChange(keywords,tagsFilterType)
        }else{
            onceLoaded.current = true
        }
    }, [keywords,tagsFilterType])
    useEffect(() => {
        if (!isInputBox && editMode && ref.current) {
            ref.current.focus()
        }
    }, [editMode])

    const onSubmit = () => {
        if (!isInputBox) {
            onChange(keywords,tagsFilterType)
            setEditMode(false)
        }
    }
    return (
        <Box
            sx={{ width: "100%", minHeight: "100%" }}
        >
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                <Box display="flex" alignItems="center" >
                    <Typography variant='body1' lineHeight="100%" >
                        <Sell fontSize='inherit' />
                    </Typography>
                    &nbsp;
                    <Typography variant='body1' >
                        Tags
                    </Typography>
                </Box>
                {
                    editMode ? <ButtonBase onClick={onAddFromPreDefinedTagsClick}>
                        <Typography fontSize="inherit" sx={{ textDecoration: "underline" }} >
                            Add From Predefined Tags
                        </Typography>

                    </ButtonBase> : (onlyView ? <></> : <IconButton size='small' onClick={() => setEditMode(true)} >
                        <Edit fontSize='inherit' />
                    </IconButton>)
                }

            </Box>
            {forFilter &&
                <RadioGroup
                    row

                    value={tagsFilterType}
                    onChange={(e) => setTagsFilterType(e.target.value)}
                    aria-labelledby="tag-filter-type"
                    name="tag-filter-type"
                >
                    <FormControlLabel value="in" control={<Radio size="small" />} label="In" />
                    <FormControlLabel value="not-in" control={<Radio size="small" />} label="Not In" />
                </RadioGroup>
            }
            <StyledAutocomplete

                disabled={!editMode}
                multiple
                freeSolo

                multiline
                disableClearable
                value={keywords}
                onChange={(event, newValue) => setKeywords(newValue)}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                            key={index}
                            //   variant="outlined"
                            label={option}
                            size='small'
                            {...getTagProps({ index })}
                            onDelete={editMode ? getTagProps({ index }).onDelete : undefined}
                            sx={{ margin: '2px', opacity: 1 }}
                        />
                    ))
                }
                options={[]}

                renderInput={(params) => (
                    <TextField

                        {...params}

                        variant="outlined"
                        onKeyDown={handleKeyDown}
                        placeholder="Enter Tags"
                        ref={ref}

                        size='small'


                    />
                )}
            />
            {
                !isInputBox && editMode && <Box display="flex" >
                    <Button sx={{ mt: 2 }} onClick={() => { setEditMode(false) }} variant='text' size='small' fullWidth disableElevation>
                        Cancel
                    </Button>
                    <Button ml={2} sx={{ mt: 2 }} onClick={onSubmit} variant='contained' size='small' fullWidth disableElevation>
                        Update
                    </Button>
                </Box>
            }
        </Box>
    );
};

export default TagsEditor;
