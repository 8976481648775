// src/components/AircallController.js

import { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import AircallPhone from "aircall-everywhere";
import { getProjectsByContactApi } from "../../../apis/projects.api";
import { createActivityLogsApi } from "../../../apis/lead.api";
import {
  createUnknownCallLog,
} from "../../../apis/aircallIntegration.api";
import AircallWidgetUI from "./AircallWidgetUI"; // Import the UI component
import { getActivityByTitle } from "../../../apis/activity.api";
import { CALL_EVENTS } from "../../../utils/constants/call.constant";
import LeadCard from "../../lead/components/LeadCard";
import { Box } from "@mui/system";
import { setPhoneInstance, togglePhoneVisibility } from "../../../store/actions/aircallAction";
import { IconButton, Tooltip } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

const AircallController = () => {
  const isVisible = useSelector((state) => state.aircall.isVisible);
  const [phone, setPhone] = useState(null);
  const [project, setProject] = useState(null);
  const dispatch = useDispatch();
  const [data, setData] = useState({
    project_id: "",
    from: "",
    to: "",
    call_dir: "",
    remarks: "",
  });

  const dataRef = useRef(data);
  const isVisibleref = useRef(isVisible);

  useEffect(() => {
    dataRef.current = data;
  }, [data]);

  useEffect(() => {
    isVisibleref.current = isVisible;
  }, [isVisible]);

  useEffect(() => {
    const timer = setTimeout(() => {
      const aircallPhone = new AircallPhone({
        onLogin: (settings) => {
          setPhone(aircallPhone);
          dispatch(setPhoneInstance(aircallPhone));
        },
        onLogout: () => {
          cleanEvents();
        },
        domToLoadPhone: "#aircall-phone-container",
        integrationToLoad: "zendesk",
        size: "small",
      });
    }, 100);
    return () => {
      clearTimeout(timer);
      cleanEvents();
    };
  }, []);



  useEffect(() => {
    if (phone) {
      phone.on("incoming_call", handleIncomingCall);
      phone.on("call_end_ringtone", handleCallEndRingtone);
      phone.on("call_ended", handleCallEnded);
      phone.on("outgoing_call", handleOutgoingCall);
      phone.on("outgoing_answered", handleOutgoingAnswered);

      const callInfo = {
        from: "+919870070505",
        to: "+911234567890",
        duration: "00:00",
      };

      // handleIncomingCall(callInfo);
    }
  }, [phone]);

  const cleanEvents = () => {
    if (phone) {
      phone.removeListener("incoming_call");
      phone.removeListener("call_end_ringtone");
      phone.removeListener("call_ended");
      phone.removeListener("outgoing_call");
      phone.removeListener("outgoing_answered");
    }
  };

  const handleIncomingCall = async (callInfos) => {
    console.log(isVisibleref.current);
    if (!isVisibleref.current) {
      dispatch(togglePhoneVisibility());
    }
    const project = await getProjectsByContactApi({
      phone: callInfos.from.substring(3, callInfos.from.length),
    });
    setProject(project.data.length > 0 ? project.data[0] : null);
    setData((prevData) => ({
      ...prevData,
      call_dir: "inbound",
      project_id: project.data.length > 0 ? project.data[0]._id : null,
      remarks:
        project.length > 1
          ? "Multiple projects found and selected first one"
          : "",
      from: callInfos.from,
      to: callInfos.to,
    }));
  };

  const handleCallEndRingtone = async (callInfos) => {
    // console.log(callInfos);
    const currentData = dataRef.current;
    if (callInfos.answer_status === "answered") {
      const activity = await (callInfos.answer_status === "answered"
        ? getActivityByTitle({ title: CALL_EVENTS.INCOMING_CALL_ANSWERED })
        : getActivityByTitle({ title: CALL_EVENTS.INCOMING_CALL_DECLINED }));
      if (currentData.project_id) {
        const request = {
          project_id: currentData.project_id,
          activity_id: activity.data._id,
          remarks: currentData.remarks,
          aircall_call_id: callInfos.call_id
        };
        createActivityLogsApi(request);
      } else {
        await createUnknownCallLog({
          mobile_no: currentData.from.substring(3, currentData.from.length),
          activity_id: activity.data._id,
          aircall_call_id: callInfos.call_id
        });
      }
    }
    if (callInfos.answer_status != "answered") {
      setProject(null);
    }
  };

  const handleCallEnded = async (callInfos) => {
    const currentData = dataRef.current;

    let activity = await (currentData.call_dir === "inbound"
      ? getActivityByTitle({ title: CALL_EVENTS.INCOMING_CALL_ENDED })
      : getActivityByTitle({ title: CALL_EVENTS.OUTGOING_CALL_ENDED }));

    if (currentData.project_id) {
      const request = {
        project_id: currentData.project_id,
        activity_id: activity.data._id,
        remarks: callInfos.duration + currentData.remarks,
        aircall_call_id: callInfos.call_id
      };
      createActivityLogsApi(request);
    } else {
      await createUnknownCallLog({
        mobile_no: currentData.call_dir === "inbound" ? currentData.from.substring(3, currentData.from.length) : currentData.to.substring(3, currentData.to.length),
        activity_id: activity.data._id,
        remarks: callInfos.duration,
        aircall_call_id: callInfos.call_id
      });
    }
    // setProject(null);
  };

  const handleOutgoingCall = async (callInfos) => {
    console.log("Outgoing Call", callInfos);
    const project = await getProjectsByContactApi({
      phone: callInfos.to.substring(3, callInfos.to.length),
    });
    //set project for lead card
    setProject(project.data.length > 0 ? project.data[0] : null);

    setData({
      call_dir: "outbound",
      project_id: project.data.length > 0 ? project.data[0]._id : null,
      remarks:
        project.length > 1
          ? "Multiple projects found and selected first one"
          : "",
      from: callInfos.from,
      to: callInfos.to,
    });

    let activity = await getActivityByTitle({
      title: CALL_EVENTS.OUTGOING_CALL_CREATED,
    });

    if (project.data.length === 0) {
      await createUnknownCallLog({
        mobile_no: callInfos.to.substring(3, callInfos.to.length),
        activity_id: activity.data._id,
        aircall_call_id: callInfos.call_id
      });
    } else {
      createActivityLogsApi({
        project_id: project.data[0]._id,
        activity_id: activity.data._id,
        remarks:
          project.length > 1
            ? "Multiple projects found and selected first one"
            : "",
        aircall_call_id: callInfos.call_id,
      });
    }
  };

  const handleOutgoingAnswered = async (callInfos) => {
    console.log("Outgoing Answered", callInfos);
    const currentData = dataRef.current;
    console.log("Outgoing Answered", callInfos);
    var activity = await getActivityByTitle({
      title: CALL_EVENTS.OUTGOING_CALL_ANSWERED,
    });
    if (currentData.project_id) {
      createActivityLogsApi({
        project_id: currentData.project_id,
        activity_id: activity.data._id,
        remarks: currentData.remarks,
        aircall_call_id: callInfos.call_id
      });
    } else {
      await createUnknownCallLog({
        mobile_no: currentData.to.substring(3, currentData.to.length),
        activity_id: activity.data._id,
        aircall_call_id: callInfos.call_id
      });
    }
  };

  return <>
    {project &&

      <Box
        sx={{
          position: 'fixed',
          bottom: 60,
          left: 10,
          padding: '8px',
          zIndex: 99999,
          backgroundColor: 'background.paper',
          borderRadius: '8px',
          boxShadow: 5,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <LeadCard
          favourite={project?.is_favourite}
          uid={project?.uid}
          projectId={project?._id}
          address={project?.full_address}
          status={project?.lead_status}
          step={project?.step}
          name={project?.contacts?.[0]?.full_name}
          customerId={project?.contacts?.[0]?.customer_uid}
          email={project?.contacts?.[0]?.email}
          phone={project?.contacts?.[0]?.phone}
          createdAt={project?.createdAt}
          updatedAt={project?.lead_updated_date}
          assignedSalesPerson={project?.assigned_sales_person}
        />
        <Box
          sx={{
            position: 'absolute',
            top: -8,
            right: -8,
            backgroundColor: 'background.paper',
            borderRadius: '50%',
            boxShadow: 1,
          }}
        >
          <Tooltip title="Close">
            <IconButton
              sx={{
                p: 0,
                color: 'text.secondary',
              }}
              onClick={() => {
                setProject(null)
              }}
            >
              <CancelIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    }
    <AircallWidgetUI />
  </>
};

export default AircallController;
