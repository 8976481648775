import { memo, useEffect, useMemo, useState } from "react"
import useValidate from "../../../store/hooks/useValidator"
import CreateUi from "./MappedUserCreateUi"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../../store/actions/commonAction"
import { closeModal } from "../../../store/actions/modalAction"
import { useParams } from "react-router-dom"

import { addSourceApi, getSourceByIdApi, updateSourceField } from "../../../apis/sources.api"
import { MODAL_KEYS } from "../../../utils/constants/modal.constant"
import { addOpenSolarIntegratedUserApi } from "../../../apis/opensolarIntegration.api"

const MappedUserCreateController = ({modalKey=MODAL_KEYS.OPEN_SOLAR_INTEGRATION_USER ,callBack}) => {
    const validate = useValidate()
    const dispatch = useDispatch()
    const params = useParams()

    const title = "Mapped User "
    const createApi = addOpenSolarIntegratedUserApi
  

    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',
        
        user_id:null,
        open_solar_user_id:null,         

    })
   


    const validationSchemaForCreate = useMemo(() => ([

        {
            required: true,
            value: fields.user_id,
            field: 'Crm User',
        },
        {
            required: true,
            value: fields.open_solar_user_id,
            field: 'Open Solar User',
        }
    ]), [fields])

  


    const createFunction = async () => {
        const validationResponse = validate(validationSchemaForCreate)

        if (validationResponse === true) {


            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await createApi(fields),
                    async (response) => {

                        await callBack(response)
                        setLoading(false)
                        dispatch(closeModal(modalKey))
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }




    const onSubmit = async (e) => {
        e.preventDefault()
       
            createFunction()

    }

   


    return <CreateUi title={title} modalKey={modalKey}  loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
}
export default memo(MappedUserCreateController)