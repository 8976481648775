import { Grid, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import { memo } from "react";
import { CenteredBox } from "../../components/layouts/OneViewBox";
import { TitleBox } from "../../components/layouts/common/boxes";

const DetailComponent = ({ value }) => {
  return (
    <CenteredBox variant="outlined" component={Box} p={2}>
      <Box sx={{ width: "100%" }} my={2}>
      {value ?? "NA"}
      </Box>
    </CenteredBox>
  );
};

const DetailsUI = memo(({ params }) => {
  return (
    <CustomDialog id="template-list" title="Template View">
      <TitleBox title={params.subject} >
     
      <Box sx={{ width: "100%" }}>
        <DetailComponent
          title="Template"
          value={
            <Box
              dangerouslySetInnerHTML={{
                __html: params?.template,
              }}
            ></Box>
          }
        />
      </Box>
      </TitleBox>
    </CustomDialog>
  );
});

export default DetailsUI;
