

const MODULES = {



  //company profile
  COMPANY_PROFILE_VIEW: 1.1,
  COMPANY_PROFILE_EDIT: 1.2,

  //comapny module
  COMPANY_MODULE_LIST: 1.3,
  COMPANY_MODULE_CREATE: 1.4,
  COMPANY_MODULE_EDIT: 1.5,
  COMPANY_MODULE_DELETE: 1.7,


  //reports
  LEAD_REPORTS: 2.1,
  FINANCE_REPORTS: 2.2,




  //Task Management
  TASK_MANAGEMENT_MAIN: 3,

  //Chats
  CHATS_SECTION_MAIN: 3.1,
  CHATS_ADMIN: 3.2,

  //Users
  VIEW_USERS: 4.1,
  CREATE_USERS: 4.2,
  UPDATE_USERS: 4.3,
  DELETE_USERS: 4.4,
  UPDATE_MODULE_ACCESS: 4.5,

  VIEW_INSTALLER: 4.11,
  CREATE_INSTALLER: 4.12,
  UPDATE_INSTALLER: 4.13,
  DELETE_INSTALLER: 4.14,
  VIEW_INSTALLER_AGGREMENT: 4.15,
  CREATE_INSTALLER_AGGREMENT: 4.16,
  UPDATE_INSTALLER_AGGREMENT: 4.17,
  DELETE_INSTALLER_AGGREMENT: 4.18,

  VIEW_SUPPLIER: 4.21,
  CREATE_SUPPLIER: 4.22,
  UPDATE_SUPPLIER: 4.23,
  DELETE_SUPPLIER: 4.24,

  //TEAMS
  VIEW_TEAMS: 4.31,
  CREATE_TEAMS: 4.32,
  UPDATE_TEAMS: 4.33,


  LEAD_ADMIN: 5.1,
  VIEW_LEADS: 5.2,
  CREATE_LEADS: 5.3,
  UPDATE_LEADS: 5.4,
  DELETE_LEADS: 5.5,
  LEAD_DATA_EXPORT: 5.6,


  SCHEDULED_CALL: 6.1,

  NOTES_CREATE: 7.1,
  NOTES_VIEW: 7.2,

  NOTES_TYPE_VIEW: 7.4,
  NOTES_TYPE_CREATE: 7.5,
  NOTES_TYPE_DELETE: 7.6,

  VIEW_PROJECT_DOCUMENTS: 7.11,
  CREATE_PROJECT_DOCUMENTS: 7.12,
  UPLOAD_PROJECT_DOCUMENTS: 7.13,

  ACTIVITY_LOGS_VIEW: 7.21,
  ACTIVITY_LOGS_CREATE: 7.22,

  HISTORY_LOGS: 7.31,




  PROJECT_ADMIN: 8.1,
  VIEW_PROJECTS: 8.2,
  UPDATE_PROJECTS: 8.4,  
  PROJECT_DATA_EXPORT: 8.6,

  PROJECT_PREINSTALLATION_ACTIONS: 8.7,
  PROJECT_INSTALLATION_ACTIONS: 8.8,
  PROJECT_POSTINSTALLATION_ACTIONS: 8.9,

  INSTALLER_APPROVALS: 8.11,
  



  VIEW_COMPLAINS: 9.1,
  CREATE_COMPLAINS: 9.2,
  EDIT_COMPLAINS: 9.3,
  DELETE_COMPLAINS: 9.4,



  CREATE_INVOICE: 12.1,
  EDIT_INVOICE: 12.2,
  DELETE_INVOICE: 12.3,

  PAY_INVOICE: 12.4,
  EXPORT_CSV_INVOICE: 12.5,

  BULK_INVOICE: 12.6,

  DEPOSIT_INVOICE: 12.11,
  CUSTOMER_INVOICE: 12.12,
  EXTRAA_CHARGE_INVOICE: 12.13,
  GENERAL_INVOICE: 12.14,
  ELEC_INVOICE: 12.15,
  
  INCENTIVES_INVOICE:12.16,
  SUPPLIER_INVOICE: 12.18,
  ADF_INVOICE: 12.19,
  LOSS_INVOICE: 12.21,
  REFUND_INVOICE: 12.22,


  PAYMENTS_VERIFICATION: 13.1,

  VIEW_EXPENSE: 14.1,
  CREATE_EXPENSE: 14.2,
  EDIT_EXPENSE: 14.3,
  DELETE_EXPENSE: 14.4,



  //Admin WorkSpace

  LEAD_FORMS: 15.1,
  ANNOUNCEMENT_MODULE: 15.6,
  BLOG_MODULE: 15.11,
  EMAIL_TEMPLATES: 15.21,
  HOLIDAY_MODULE: 15.31,
  LEAD_FORMS: 15.41,
  CUSTOMER_MODULE: 15.51,


  //settings 
  //Lead Settings

  VIEW_LEAD_SOURCE: 16.1,
  CREATE_LEAD_SOURCE: 16.2,
  UPDATE_LEAD_SOURCE: 16.3,
  DELETE_LEAD_SOURCE: 16.4,

  ACTIVITY_MODULE: 16.11,
  PIPELINE_MODULE: 16.21,
  PREDEFINED_TAGS: 16.31,
  REJECTION_REASON_MODULE: 16.41,
  
  COMPLAIN_CATEGORIES_MODULE: 16.51,

  INTEGRATION_MODULE: 16.61,



  

  VIEW_PRODUCT: 17.1,
  CREATE_PRODUCT: 17.2,
  UPDATE_PRODUCT: 17.3,
  DELETE_PRODUCT: 17.4,

  VIEW_PRODUCT_CATEGORY: 17.11,
  CREATE_PRODUCT_CATEGORY: 17.12,
  UPDATE_PRODUCT_CATEGORY: 17.13,
  DELETE_PRODUCT_CATEGORY: 17.14,

  VIEW_STOCK: 17.21,
  EDIT_STOCK: 17.22,
  STOCK_LOGS: 17.23
}




const OLD_MODULES = {
  //dashboard
  DASHBOARD_MAIN: 1,
  INSTALLATION_CALENDAR: 1.1,
  STATUS_CROSS_SOURCE: 1.2,
  STATUS_CROSS_SALES_PERSONS: 1.3,
  SOURCE_CROSS_SALES_PERSONS: 1.4,

  //reports
  LEAD_REPORTS: 1.1,
  FINANCE_REPORTS: 1.2,
  LEAD_REPORTS: 1.3,

  //Chats
  CHATS_SECTION_MAIN: 2.1,
  CREATE_CHATS_GROUPS: 2.2,
  VIEW_CHATS_GROUP_DETAIL: 2.3,
  ADD_AND_REMOVE_CHATS_MEMBER: 2.4,
  DELETE_CHAT_MESSAGE: 2.5,

  //Task Management
  TASK_MANAGEMENT_MAIN: 3,

  //Users
  VIEW_USERS: 4.1,
  CREATE_USERS: 4.2,
  UPDATE_USERS: 4.3,
  DELETE_USERS: 4.4,
  CHANGE_PASSWORD: 4.5,
  UNDO_DELETED_USERS: 4.6,
  VIEW_INSTALLER: 4.7,
  UPDATE_MODULE_ACCESS: 4.8,
  SUPPLIER_MODULE: 4.9,
  INSTALLER_MODULE: 4.11,
  AGGREMENT_MODULE: 4.12,

  //TEAMS
  VIEW_TEAMS: 5.1,
  CREATE_TEAMS: 5.2,
  UPDATE_TEAMS: 5.3,

  //CUSTOMER
  CUSTOMER_MODULE: 6.1,

  //STOCK
  STOCK_MODULE: 7.1,

  //Announcement Module
  ANNOUNCEMENT_MODULE: 7.2,

  //SETTINGS

  VIEW_LEAD_SOURCE: 8.1,
  CREATE_LEAD_SOURCE: 8.2,
  UPDATE_LEAD_SOURCE: 8.3,
  DELETE_LEAD_SOURCE: 8.35,

  //Holiday
  HOLIDAY_MODULE: 8.4,

  //Secrets
  SECRETS_MODULE: 8.5,

  //Loan benefits
  LOAN_BENEFITS_MODULES: 8.6,

  //Complain Categrories
  COMPLAIN_CATEGORIES_MODULE: 8.7,

  //Versions
  VERSION_MODULE: 8.8,

  //Templates
  TEMPLATE_MODULE: 8.9,

  //Terms & Condition Module
  TERMS_AND_CONDITION_MODULE: 8.11,

  //Modules
  MODULESS: 8.12,

  //White Listed IP
  WHITE_LISTED_IP: 8.13,

  //Activity Module
  ACTIVITY_MODULE: 8.14,

  //Integration Module
  INTEGRATION_MODULE: 8.15,

  //Blog module
  BLOG_MODULE: 8.17,

  //Notes_type Module
  NOTES_TYPE_MODULE: 8.18,


  //Company_certificate Module
  COMPANY_CERTIFICATE: 8.19,

  //Office Module
  OFFICE_MODULE: 8.21,

  //Rating Module
  RATING_MODULE: 8.22,
  //Payment Verification
  PAYMENT_VERIFICATION_MODULE: 9,

  //Final Calculation Module
  FINANCE: 10,

  //Complains
  VIEW_COMPLAINS: 11.1,
  CREATE_COMPLAINS: 11.2,
  EDIT_COMPLAINS: 11.3,
  STATUS_MOVEMENT_COMPLAINS: 11.4,

  // Invoices
  SUMMARY_INVOICES: 12.1,
  EXPORT_CSV_INVOICE: 12.2,
  BULK_INVOICE: 12.3,
  CREATE_INVOICE: 12.4,
  PAY_INVOICE: 12.5,
  DELETE_INVOICE: 12.6,
  SHARE_INVOICE: 12.7,
  REFRESH_INVOICE: 12.8,
  EDIT_INVOICE: 12.9,
  DEPOSIT_INVOICE: 12.11,
  CUSTOMER_INVOICE: 12.12,
  EXTRAA_CHARGE_INVOICE: 12.13,
  GENERAL_INVOICE: 12.14,
  ELEC_INVOICE: 12.15,
  STC_INVOICE: 12.16,
  SV_INVOICE: 12.17,
  SUPPLIER_INVOICE: 12.18,
  ADF_INVOICE: 12.19,
  LOSS_INVOICE: 12.21,
  REFUND_INVOICE: 12.22,

  //Projects

  VIEW_PROJECTS: 13.1,
  CREATE_AND_REFRESH_PROJECTS: 13.2,
  EXPORT_CSV_PROJECTS: 13.3,
  REASSIGN_INSTALLER: 13.4,
  PROJECT_ORDERS: 13.5,
  PROJECT_LOGS: 13.6,
  VIEW_PROJECT_DOCUMENTS: 13.7,
  CREATE_PROJECT_DOCUMENTS: 13.8,
  UPLOAD_PROJECT_DOCUMENTS: 13.9,
  SELECT_SYSTEM: 13.11,
  STAGE_MOVEMENTS_PROJECTS: 13.12,
  ADMIN_APPROVALS_PROJECTS: 13.13,
  ALL_STAGE_MOVEMENTS_PROJECTS: 13.14,

  ALL_ACTION: 13.15,
  DEPOSIT_RECIEVE_BUTTON: 13.16,
  PRE_APPROVAL_APPLY: 13.17,
  APPROVE_REJECT_PRE_APPROVAL: 13.18,
  APPLY_SV: 13.19,
  APPROVE_REJECT_SV: 13.21,
  SEND_TO_STC_BUTTON: 13.22,
  CREATE_JOB_PACK: 13.23,
  SCHEDULE_INSTALLATION: 13.24,
  APPLY_STC: 13.25,
  SV_QR_SCANNED: 13.26,
  ADD_CES: 13.27,
  CREATE_CUSTOMER_INVOICE: 13.28,
  CREATE_ELECTRICIAN_INVOICE: 13.29,
  CLAIM_STC: 13.3,
  CLAIM_SV: 13.31,
  APPLY_POST_APPROVAL: 13.32,
  COMPLETE_POST_APPROVAL: 13.33,

  // //Leads
  // CENTERAL_SEARCH_LEADS: 14.1,
  // EXPORT_CSV_LEADS: 14.2,
  // VIEW_LEADS: 14.3,
  // EDIT_LEADS: 14.4,
  // EDIT_STATUS_LEADS: 14.5,
  // CREATE_LEADS: 14.6,
  // DELETE_LEADS: 14.7,
  // REASSIGN_SALEPERSON: 14.8,
  // LEAD_LOG_HISTORY: 14.9,
  // LEAD_CALL_HISTORY: 14.11,
  // VIEW_LEAD_CALLS: 14.12,

  //Filters
  SOURCE_FILTER: 15.1,
  SALES_PERSON_FILTER: 15.2,
  // INSTALLER_FILTER: 15.3,
  // SUPPLIER_FILTER: 15.4,
  TEAM_FILTER: 15.5,
  PROJECT_ACTION_FILTER: 15.6,

  //GENRAL FEATURES
  SEND_EMAIL: 16,

  //comapny

  // COMPANY_MODULE_LIST: 17.1,
  // COMPANY_MODULE_CREATE: 17.2,
  // COMPANY_MODULE_EDIT: 17.3,
  // COMPANY_PROFILE_EDIT: 17.4,
  // COMPANY_MODULE_DELETE: 17.5,

  // Email Integration
  EMAIL_INTEGRATION: 17.6
};
export default MODULES;
