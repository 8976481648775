import { combineReducers } from "@reduxjs/toolkit"
import modalReducer from "./modalReducer";
import userReducer from "./userReducer";
import notificationReducer from "./notificationReducer";
import snackBarReducer from "./snackbarReducer";
import chatReducer from "./chatReducer";
import dateRangeSelectionReducer from "./dateRangeSelectionReducer";
import fetchVersionReducer from "./fetchVersionReducer";
import announcementReducer from "./announcementReducer";
import blogReducer from "./blogReducer";
import preDefinedTagsReducer from "./predefinedTagsReducer";
import leadFiltersReducer from "./leadfiltersReducer";
import aircallReducer from "./aircallReducer";
import leaderBoardReducer from "./leaderboardReducer";


const rootReducer = combineReducers({
    user: userReducer,
    modal: modalReducer,
    notifications: notificationReducer,
    chat: chatReducer,
    snackBar: snackBarReducer,
    dateRange: dateRangeSelectionReducer,
    version: fetchVersionReducer,
    announcements: announcementReducer,
    blog: blogReducer,
    preDefinedTags: preDefinedTagsReducer,
    leadFilters: leadFiltersReducer,
    aircall: aircallReducer,
    leadFilters: leadFiltersReducer,
    leaderboard: leaderBoardReducer,
})
export default rootReducer;