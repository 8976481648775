import { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from '../../../store/actions/commonAction'
import { openModal } from '../../../store/actions/modalAction'


import Calendar from '../../../components/layouts/Calendar'
import CalendarBox from './CalendarInnerBox'
import moment from 'moment'

import CustomMonthPicker from '../../../components/layouts/common/CustomMonthPicker'
import { Box, Grid, MenuItem, Paper, Typography } from '@mui/material'
import SubmitButton from '../../../components/button/SubmitButton'
import { CenteredBox } from '../../../components/layouts/common/boxes'
import { CALENDAR_ITEMS_TYPES, SNACK_BAR_VARIETNS, USER_ROLES } from '../../../utils/constants/constants'
// import CreateController from './CreateController'
import { getTasksApi } from '../../../apis/task.api'
import { callSnackBar } from '../../../store/actions/snackbarAction'
import AsyncDropDown from '../../../components/inputs/AsyncDropDown'
import { StyledSearchBar } from '../../../components/inputs/SearchBar'
import { getInstallerApi, getUserApi } from '../../../apis/user.api'
import { getAttendecesOfmonthApi } from '../../../apis/attendence.api'
import getProjectsApi from '../../../apis/projects.api'
import { useProjectStatusColor } from '../../../utils/helpers/project.helper'
import { PROJECT_STATUS } from '../../../utils/constants/project.constant'
import { findObjectKeyByValue, titleCase } from '../../../utils/helpers/helper'
import ReadyToInstallerProjectsController from './ReadyToInstallerProjectsController'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

const InstallationCalendar = ({  }) => {
  const { holiday, user } = useSelector((state) => state)
  const projectStatusColor = useProjectStatusColor()
  const [calendarTimeObj, setCalendarTimeObj] = useState(moment())

  const weeklyOf = []
  const userId = user.data._id

  const [list, setList] = useState([])
  const [listLoading, setListLoading] = useState(false)

  const [projectList, setProjectList] = useState(null)
  const [projectLoading, setProjectsLoading] = useState(null)


  const holidayList = user.data.holiday

  const dispatch = useDispatch()

  const [filters, setFilters] = useState({
    userId: userId,
    all: true,
    calendar: true
  })
  const fetchProjects = () => {
    setProjectsLoading(true)
    dispatch(
      callApiAction(
        async () =>
          await getProjectsApi({
            ...filters,
            installationStartDate: calendarTimeObj?.startOf("month").valueOf(),
            installationEndDate: calendarTimeObj?.endOf("month").valueOf(),
          }),
        (response) => {

          setProjectList(response)
          setProjectsLoading(false)
        },
        (err) => {
          setProjectList(null)
          setProjectsLoading(false)
          dispatch(callSnackBar("server Error", SNACK_BAR_VARIETNS.error))
        },
      ),
    )

  }

  const fetchList = () => {
    setListLoading(true)

    if (!listLoading)
      fetchProjects()




  }

  const createCalendarList = () => {

    const finalList = []

    

    if (Array.isArray(projectList)) {
      projectList.forEach((item) => {
        finalList.push({
          ...item,
          title: item.contacts?.[0]?.full_name ?? item?.uid,
          start: new Date(item.installtion_date),
          end: new Date(item.installtion_date),
          relatedId: item._id,
          type: CALENDAR_ITEMS_TYPES.PROJECT,
          status: item.status,
          flags: item.flags,


        })
      })
    }


    setListLoading(false)
    setList(finalList)
  }


  useEffect(() => {
    if (projectList && holidayList && !projectLoading) //
      createCalendarList()
  }, [holidayList, projectList])

  useEffect(() => {
    fetchList()
  }, [calendarTimeObj.valueOf(), filters.assigned_installer, filters.filterKey])




  return (
    <>

      <Paper component={Box} p={3} mb={3} >


        <CenteredBox sx={{ alignItems: "flex-start" }}>
          <DndProvider backend={HTML5Backend}>


            <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }} mr={3} >
              <ReadyToInstallerProjectsController callBack={fetchList} defaultFilters={filters} />
            </Box>



            <Box sx={{ display: "flex", flex: 3, flexDirection: "column" }} >




              {(user.data.role == USER_ROLES.admin || user.data.role == USER_ROLES.sub_admin || user.data.role == USER_ROLES.tl || user.data.role == USER_ROLES.partner_admin) && <Box sx={{ display: "flex", flex: 1 }} mb={1}>
                <AsyncDropDown
                  id={'installer-installation-calendar'}
                  margin={0}
                  InputComponent={(props) => <StyledSearchBar placeholder="Select Installer"  {...props} margin="none" size="small" />}
                  lazyFun={async (params) => { return await getInstallerApi({ ...params }) }}

                  OptionComponent={({ option, ...rest }) => {
                    return <MenuItem {...rest}>{option.name}</MenuItem>
                  }}
                  onChange={async (changedVal) => {

                    setFilters({ ...filters, assigned_installer: changedVal ? changedVal._id : null })
                  }}
                  titleKey={'name'}
                  valueKey={"_id"}
                />
              </Box>
              }








              <Box mb={2} >
                <CustomMonthPicker date={calendarTimeObj} setDate={setCalendarTimeObj} />
              </Box>
              <Calendar
                loading={listLoading}
                data={list}
                Component={CalendarBox}
                calendarTimeObj={calendarTimeObj.toDate()}
              />
            </Box>
          </DndProvider>
        </CenteredBox>

        <Box mt={3} p={2} >
          <Grid container spacing={2} >
            {
              Object.keys(PROJECT_STATUS).filter(item => PROJECT_STATUS[item] > PROJECT_STATUS.CUSTOMER_HOLD).map((item) => <Grid sx={3} md={2} key={item}>

                <Box mb={1} flex={1} sx={{ minWidth: "150px" }} display="flex" >

                  <Box mr={1} sx={{ width: "20px", height: "20px", backgroundColor: projectStatusColor(PROJECT_STATUS[item]).bgColor }} >

                  </Box>
                  <Typography variant="caption" >{titleCase(item)}</Typography>
                </Box>
              </Grid>)
            }
          </Grid>
        </Box>
      </Paper>
    </>
  )
  return <></>
}
export default memo(InstallationCalendar)
