import { EMAIL_TEMPLATE_TYPE } from "../constants/emailTemplate.constant"
import { titleCase } from "./helper"

export const getVariableAccordingToTemplate = (templateType) => {

    switch (templateType) {
        case EMAIL_TEMPLATE_TYPE.PROJECT:
        case EMAIL_TEMPLATE_TYPE.LEAD:
        
            return [
                {
                    label: "Project UID",
                    group: 'Customer Details',
                    id: 'uid',
                },
                {
                    label: titleCase('customer_id'),
                    group: 'Customer Details',
                    id: 'customer_id',
                },
                {
                    label: titleCase('c_first_name'),
                    group: 'Customer Details',
                    id: 'c_first_name',
                },
                {
                    label: titleCase('c_middle_name'),
                    group: 'Customer Details',
                    id: 'c_middle_name',
                },
                {
                    label: titleCase('c_last_name'),
                    group: 'Customer Details',
                    id: 'c_last_name',
                },
                {
                    label: titleCase('c_full_name'),
                    group: 'Customer Details',
                    id: 'c_full_name',
                },
                {
                    label: titleCase('c_phone'),
                    group: 'Customer Details',
                    id: 'c_phone',
                },
                {
                    label: titleCase('c_email'),
                    group: 'Customer Details',
                    id: 'c_email',
                },
                {
                    label: titleCase('c_address'),
                    group: 'Site Details',
                    id: 'c_address',
                },
                {
                    label: titleCase('state'),
                    group: 'Site Details',
                    id: 'state',
                },
                {
                    label: titleCase('country'),
                    group: 'Site Details',
                    id: 'country',
                },
                {
                    label: titleCase('postcode'),
                    group: 'Site Details',
                    id: 'postcode',
                },

                {
                    label: titleCase('property_type'),
                    group: 'Site Details',
                    id: 'property_type',
                },
                {
                    label: titleCase('no_of_storeys'),
                    group: 'Site Details',
                    id: 'no_of_storeys',
                },
                {
                    label: titleCase('phase'),
                    group: 'Site Details',
                    id: 'phase',
                },
                {
                    label: titleCase('roof_pitch'),
                    group: 'Site Details',
                    id: 'roof_pitch',
                },
                {
                    label: titleCase('e_distributor'),
                    group: 'Site Details',
                    id: 'e_distributor',
                },
                {
                    label: titleCase('e_retailer'),
                    group: 'Site Details',
                    id: 'e_retailer',
                },
                {
                    label: titleCase('roof_type'),
                    group: 'Site Details',
                    id: 'roof_type',
                },



                {
                    label: titleCase('pannels_summary'),
                    group: 'System Details',
                    id: 'pannels_summary',
                },
                {
                    label: titleCase('inverters_summary'),
                    group: 'System Details',
                    id: 'inverters_summary',
                },
                {
                    label: titleCase('battery_summary'),
                    group: 'System Details',
                    id: 'battery_summary',
                },
                {
                    label: titleCase('others_summary'),
                    group: 'System Details',
                    id: 'others_summary',
                },
                {
                    label: titleCase('total_system_size'),
                    group: 'System Details',
                    id: 'total_system_size',
                },
                {
                    label: titleCase('total_output'),
                    group: 'System Details',
                    id: 'total_output',
                },
                {
                    label: titleCase('sales_person'),
                    id: 'sales_person',
                    group: 'Other Details',
                },
            ]
        default: return []
    }
}