import { memo, useCallback, useEffect, useState } from "react"
import ListUi from "./ListUi"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"

import { closeModal, openModal } from "../../store/actions/modalAction"
import { Box, CircularProgress, IconButton, Switch, Typography } from "@mui/material"
import { useMemo } from "react"
import CreateController from "./CreateController"
import { Delete, Edit, More, MoreHoriz, Undo } from "@mui/icons-material"

import getSourceApi, { deleteSourceApi, updateSourceField } from "../../apis/sources.api"
import { MODAL_KEYS } from "../../utils/constants/modal.constant"
import CustomDialog from "../../components/layouts/common/CustomDialog"
import { moduleAccessCondition } from "../../utils/helpers/helper"
import MODULES from "../../utils/constants/module.constants"
import MessageDilog from "../../components/MessageDilog"

const ListModal = ({ modalKey = MODAL_KEYS.SOURCES_LIST, title, parent }) => {
    return <CustomDialog
        id={modalKey}
        title={title}
    >
        <ListController modal parent={parent} createModalKey={`create-${modalKey}`} />
    </CustomDialog>
}

const ActionComponent = memo(({ params, setParams, deleteApi, createModalKey }) => {
    const dispatch = useDispatch()
    const deleted = params.deleted
    const [loading, setLoading] = useState(false)

    const { user } = useSelector(state => state)

    const onEdit = () => {
        dispatch(openModal(<CreateController id={params._id} callBack={(response, updatedData) => {
            setParams({ ...params, ...updatedData })
        }} />, "sm", undefined, MODAL_KEYS.SOURCES_CREATE))
    }

    const onMoreClick = () => {
        dispatch(openModal(<ListModal parent={params._id} title={`Sub Sources of "${params.name}"`} modalKey={params._id} callBack={(response, updatedData) => {

        }} />, "md", undefined, params._id))
    }
    const deleteFun = async () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await deleteApi({ id: params._id, deleted: !deleted }),
            (response) => {
                setParams({})
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
        dispatch(closeModal())
    }


    const onDelete = () => {
        dispatch(openModal(<MessageDilog onSubmit={deleteFun} title="Alert!" message={`Are you sure to delete "${params.name || params.title}" ?`} />, "sm"))
    }

    const onUndo = () => {
        dispatch(openModal(<MessageDilog onSubmit={deleteFun} title="Alert!" message={`Are you sure to undo delete for "${params.name || params.title}" ?`} />, "sm"))
    }
    if (deleted && moduleAccessCondition(user, [MODULES.DELETE_LEAD_SOURCE], []))
        return <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}> <IconButton onClick={onUndo}>
            <Undo color="error" />
        </IconButton>
        </Box>
    return <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
        {moduleAccessCondition(user, [MODULES.UPDATE_LEAD_SOURCE], []) && !params.is_default && <IconButton size="inherit" onClick={onEdit}>
            <Edit color="info" fontSize="inherit" />
        </IconButton>
        }
        <IconButton size="inherit" onClick={onMoreClick}>
            <MoreHoriz color="info" fontSize="inherit" />
        </IconButton>

        {moduleAccessCondition(user, [MODULES.DELETE_LEAD_SOURCE], [])  && !params.is_default && <>

            {loading && <CircularProgress color="primary" fontSize="inherit" />}
            {!loading && <IconButton disabled={loading} size="inherit" onClick={onDelete}>
                <Delete color="error" fontSize="inherit" />
            </IconButton>}
        </>}
    </Box>
})


const ListController = ({ createModalKey = MODAL_KEYS.SOURCES_CREATE, parent, modal }) => {
    const dispatch = useDispatch()


    const title = "Sources "

    const fetchApi = getSourceApi
    const deleteApi = deleteSourceApi
    const updateApi = updateSourceField

    const columns = useMemo(() => [

        { id: 1, fieldName: 'name', label: 'Source Name', align: "left", sort: true },
        { id: 2, fieldName: 'identifier', label: 'Identifier', align: "left" },



        {
            id: 5,
            fieldName: '',
            label: 'Action',

            align: "right",
            renderValue: (params, setParams) => <ActionComponent createModalKey={createModalKey} deleteApi={deleteApi} params={params} setParams={setParams} />,
        },

    ], [dispatch]);


    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 10,
        search: '',
        parent,
        searchable: ['name'],
        sort: '',
        sortDirection: 1

    })





    // do not change 

    const [loading, setLoading] = useState(false)
    const [list, setList] = useState({})









    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchApi({ ...filters }),
            (response) => {
                setList(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }
    const onCreateBtnClick = () => {
        dispatch(openModal(<CreateController modalKey={createModalKey} parent={parent} callBack={async () => { fetchList() }} />, "sm", undefined, createModalKey))
    }



    useEffect(() => {
        fetchList()
    }, [filters])



    return (
        <>
            <ListUi
                modal={modal}
                title={title}
                onCreateBtnClick={onCreateBtnClick}

                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}
                columns={columns}


            />

        </>
    )
}
export default ListController