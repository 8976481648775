import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";

import {
  Autocomplete,
  ButtonBase,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Switch,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  AccountCircle,
  Block,
  FilterList,
  Groups,
  Lock,
  MyLocation,
  Refresh,
  SolarPower,
  Source,
  Stairs,
  Star,
} from "@mui/icons-material";
import TagsEditor from "../../../components/inputs/TagsEditor";
import { TAG_TYPE } from "../../../utils/constants/tags.constant";
import LeadTimeFilters from "./LeadTimeFilter";
import LeadStatusFilter from "./LeadStatusFilter";
import SourceFilterDropDown from "../../../components/filterdropdowns/SourceFilterDropDown";
import IconTitle from "../../../components/texts/IconTitle";
import TeamFilterDropDown from "../../../components/filterdropdowns/TeamFilterDropDown";
import SalesPersonFilterDropDown from "../../../components/filterdropdowns/SalesPersonFilter";
import { useTheme } from "@emotion/react";
import CustomInput from "../../../components/inputs/CustomInput";
import StepViewComponent from "../components/StepViewComponent";
import SelectDropDown from "../../../components/inputs/SelectDropDown";
import {
  PHASE_TYPE,
  PROPERTY_TYPE,
  ROOF_PITCH_TYPE,
  ROOF_TYPES,
  STOREY_TYPE,
  SWITCH_BOARD,
} from "../../../utils/constants/project.constant";
import { titleCase } from "../../../utils/helpers/helper";

import EnergyPartnerDropDown from "../../energy-partners/dropdown/EnergyPartnerDropDown";
import { ENERGY_PARTNER_TYPE } from "../../../utils/constants/energypartner.constant";
import { AUS_STATES } from "../../../utils/constants/constants";
import { initialLeadFilters } from "../../../store/reducers/leadfiltersReducer";
import { CenteredBox } from "../../../components/layouts/OneViewBox";

const NotApplicableComponent = () => {
  return (
    <Tooltip title="This filter is not allowed for this module">
      <Box
        sx={{
          position: "absolute",
          zIndex: 1,
          top: "0px",
          left: "0px",
          height: "100%",
          width: "100%",
          background: "rgba(255,255,255,.2)",
          backdropFilter: "blur(2px)",
          cursor: "not-allowed",
        }}
      >
        <CenteredBox
          sx={{
            position: "absolute",
            zIndex: 1,
            bottom: "5px",
            right: "5px",
            background: "#fff",
            height: "30px",
            width: "30px",
            borderRadius: "100%",
          }}
        >
          <Block color="warning" />
        </CenteredBox>
      </Box>
    </Tooltip>
  );
};
const FilterButton = ({
  defaultFilters,
  setDefaultFilters,
  notApplicableFilters = [],
}) => {
  const [open, setOpen] = React.useState(false);

  const [filters, setFilters] = React.useState({
    ...defaultFilters,
    refreshKey: Math.random(),
  });
  const toggleDrawer = (newOpen) => () => {
    if (newOpen) {
      setFilters({ ...filters, ...defaultFilters, refreshKey: Math.random() });
    }
    setOpen(newOpen);
  };

  const onApplyClick = () => {
    setDefaultFilters({ ...filters, refreshKey: Math.random() });
    toggleDrawer(false)();
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <ButtonBase onClick={toggleDrawer(true)}>
        <Typography
          variant="caption"
          color="primary"
          sx={{ textDecoration: "underline" }}
        >
          Advance Filters
        </Typography>
      </ButtonBase>
      <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
        <Box
          key={filters.refreshKey}
          sx={{
            width: isMobile ? "300px" : "600px",
            height: "100%",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
          }}
          role="presentation"
        >
          <Box
            component={Paper}
            p={2}
            sx={{ background: "#f2f2f2" }}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography lineHeight="100%">
              <FilterList fontSize="inherit" lineHeight="100%" />
              &nbsp;Lead Filters
            </Typography>
            <Tooltip title="Reset To Default">
              <IconButton
                size="small"
                onClick={() =>
                  setFilters({
                    ...initialLeadFilters,
                    refreshKey: Math.random(),
                  })
                }
              >
                <Refresh />
              </IconButton>
            </Tooltip>
          </Box>
          <Box
            sx={{
              display: "flex",
              flex: 1,
              overflowY: "auto",
              flexDirection: "column",
            }}
          >
            <Box p={2}>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <Box position="relative">
                    {notApplicableFilters.includes("date") && (
                      <NotApplicableComponent />
                    )}
                    <LeadTimeFilters
                      filters={filters}
                      setFilters={setFilters}
                    />
                  </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Box
                    sx={{
                      height: "100%",
                      overflow: "hidden",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box position="relative">
                      {notApplicableFilters.includes("status") && (
                        <NotApplicableComponent />
                      )}
                      <LeadStatusFilter
                        filters={filters}
                        setFilters={setFilters}
                      />
                    </Box>
                    <Box
                      mt={3}
                      sx={{
                        flex: 1,
                        overflow: "hidden",
                        display: "flex",
                        flexDirection: "column",
                        position: "relative",
                      }}
                    >
                      {notApplicableFilters.includes("step") && (
                        <NotApplicableComponent />
                      )}
                      <IconTitle Icon={Stairs} title="Steps" />
                      <Box
                        sx={{
                          minHeight: "30px",
                          overflow: "hidden",
                          display: "flex",
                        }}
                      >
                        <StepViewComponent
                          forFilter
                          changeInserver={false}
                          size="large"
                          stepObj={filters.step}
                          onChange={(step) => {
                            setFilters({ ...filters, step });
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Divider component={Box} sx={{ mt: 2, mb: 2, borderWidth: 2 }} />
            <Box p={2}>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <Box position="relative">
                    {notApplicableFilters.includes("team") && (
                      <NotApplicableComponent />
                    )}

                    <TeamFilterDropDown
                      titleComponent={<IconTitle Icon={Groups} title="Team" />}
                      returnFullObj
                      defaultVal={filters.team}
                      id={"team-list-lead-analytics"}
                      filters={filters}
                      setFilters={setFilters}
                    />
                  </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Box position="relative">
                    {notApplicableFilters.includes("sales_person") && (
                      <NotApplicableComponent />
                    )}

                    <SalesPersonFilterDropDown
                      titleComponent={
                        <IconTitle Icon={AccountCircle} title="Sales Person" />
                      }
                      returnFullObj
                      defaultVal={filters.assigned_sales_person}
                      id={"sales-leads"}
                      filters={filters}
                      setFilters={setFilters}
                      key={filters.team}
                    />
                  </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Box position="relative">
                    {notApplicableFilters.includes("source") && (
                      <NotApplicableComponent />
                    )}

                    <SourceFilterDropDown
                      titleComponent={
                        <IconTitle Icon={MyLocation} title="Source" />
                      }
                      defaultVal={filters.source ?? null}
                      returnFullObj
                      id={"sources-leads"}
                      filters={filters}
                      setFilters={setFilters}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Divider component={Box} sx={{ mt: 2, mb: 2, borderWidth: 2 }} />
            <Box p={2}>
              <Tooltip title="Only Favorite Notes">
                <IconButton>
                  <Star
                    fontSize="inherit"
                    sx={{
                      color: theme.palette.primary.main,
                    }}
                  />
                </IconButton>
              </Tooltip>
              <Switch
                checked={filters.is_favourite}
                onChange={(e)=>{
                    const isStarred = e.target.checked 
                    const filterLead = isStarred ? true : undefined;
                    setFilters({ ...filters, is_favourite:filterLead })
                }}
                color="primary"
              />
            </Box>
            <Box p={2}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box position="relative" width="100%">
                    {notApplicableFilters.includes("tags") && (
                      <NotApplicableComponent />
                    )}

                    <TagsEditor
                      forFilter
                      isInputBox={true}
                      value={filters.tags}
                      onChange={(tags, filterType) =>
                        setFilters({
                          ...filters,
                          tags,
                          tagFilterType: filterType,
                        })
                      }
                      type={TAG_TYPE.LEAD}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Divider component={Box} sx={{ mt: 2, mb: 2, borderWidth: 2 }} />
            <Box p={2}>
              <IconTitle Icon={SolarPower} title="Site Details" />
              <Grid container spacing={2} mt={2}>
                <Grid item xs={12} md={6}>
                  <Box position="relative">
                    {notApplicableFilters.includes("property_type") && (
                      <NotApplicableComponent />
                    )}

                    <SelectDropDown
                      disableClearable={false}
                      // inputComponent={(params) => <StyledSearchBar  {...params} size="small" />}
                      title="Property Type"
                      value={filters.property_type}
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          err: "",
                          property_type: e.target.value,
                        })
                      }
                      data={Object.keys(PROPERTY_TYPE).map((item) => ({
                        value: PROPERTY_TYPE[item],
                        label: titleCase(item),
                      }))}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box position="relative">
                    {notApplicableFilters.includes("roof_type") && (
                      <NotApplicableComponent />
                    )}

                    <SelectDropDown
                      disableClearable={false}
                      // inputComponent={(params) => <StyledSearchBar  {...params} size="small" />}
                      title="Roof Type"
                      value={filters.roof_type}
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          err: "",
                          roof_type: e.target.value,
                        })
                      }
                      data={Object.keys(ROOF_TYPES).map((item) => ({
                        value: item,
                        label: ROOF_TYPES[item],
                      }))}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Box position="relative">
                    {notApplicableFilters.includes("no_of_storeys") && (
                      <NotApplicableComponent />
                    )}

                    <SelectDropDown
                      disableClearable={false}
                      // inputComponent={(params) => <StyledSearchBar  {...params} size="small" />}
                      title="No of Storey"
                      value={filters.no_of_storeys}
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          err: "",
                          no_of_storeys: e.target.value,
                        })
                      }
                      data={Object.keys(STOREY_TYPE).map((item) => ({
                        value: STOREY_TYPE[item],
                        label: titleCase(item),
                      }))}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box position="relative">
                    {notApplicableFilters.includes("phase") && (
                      <NotApplicableComponent />
                    )}

                    <SelectDropDown
                      disableClearable={false}
                      // inputComponent={(params) => <StyledSearchBar  {...params} size="small" />}
                      title="Phase"
                      value={filters.phase}
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          err: "",
                          phase: e.target.value,
                        })
                      }
                      data={Object.keys(PHASE_TYPE).map((item) => ({
                        value: PHASE_TYPE[item],
                        label: titleCase(item),
                      }))}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Box position="relative">
                    {notApplicableFilters.includes("roof_pitch") && (
                      <NotApplicableComponent />
                    )}

                    <SelectDropDown
                      disableClearable={false}
                      // inputComponent={(params) => <StyledSearchBar  {...params} size="small" />}
                      title="Roof Pitch"
                      value={filters.roof_pitch}
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          err: "",
                          roof_pitch: e.target.value,
                        })
                      }
                      data={Object.keys(ROOF_PITCH_TYPE).map((item) => ({
                        value: ROOF_PITCH_TYPE[item],
                        label: titleCase(item),
                      }))}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box position="relative">
                    {notApplicableFilters.includes("switch_board") && (
                      <NotApplicableComponent />
                    )}

                    <SelectDropDown
                      disableClearable={false}
                      // inputComponent={(params) => <StyledSearchBar  {...params} size="small" />}
                      title="Switch Board"
                      value={filters.switch_board}
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          err: "",
                          switch_board: e.target.value,
                        })
                      }
                      data={Object.keys(SWITCH_BOARD).map((item) => ({
                        value: SWITCH_BOARD[item],
                        label: titleCase(item),
                      }))}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Box position="relative">
                    {notApplicableFilters.includes("state") && (
                      <NotApplicableComponent />
                    )}

                    <Autocomplete
                      disablePortal
                      size="small"
                      id="state-filter"
                      label="States"
                      value={filters.state}
                      options={AUS_STATES}
                      onChange={(e, newVal) => {
                        setFilters({ ...filters, state: newVal });
                      }}
                      fullWidth
                      renderInput={(params) => (
                        <CustomInput
                          {...params}
                          label="States"
                          size="small"
                          margin="none"
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}></Grid>
                <Grid item xs={12} md={6}>
                  <Box position="relative">
                    {notApplicableFilters.includes("e_distributor") && (
                      <NotApplicableComponent />
                    )}

                    <EnergyPartnerDropDown
                      value={filters.e_distributor}
                      onChange={(val) =>
                        setFilters({
                          ...filters,
                          err: "",
                          e_distributor: val ?? null,
                        })
                      }
                      type={ENERGY_PARTNER_TYPE.DISTRIBUTOR}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box position="relative">
                    {notApplicableFilters.includes("e_retailer") && (
                      <NotApplicableComponent />
                    )}

                    <EnergyPartnerDropDown
                      value={filters.e_retailer}
                      onChange={(val) =>
                        setFilters({
                          ...filters,
                          err: "",
                          e_retailer: val ?? null,
                        })
                      }
                      type={ENERGY_PARTNER_TYPE.RETAILER}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box
            component={Paper}
            sx={{
              height: "50px",
              width: "100%",
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            }}
            p={2}
          >
            <Button
              disabled={
                JSON.stringify(filters) == JSON.stringify(defaultFilters)
              }
              fullWidth
              variant="contained"
              onClick={onApplyClick}
            >
              Apply
            </Button>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};
export default FilterButton;
