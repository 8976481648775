import { useRef, useState } from "react"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchLeaderBoardData } from "../../../store/actions/leaderboardActions"
import SalesReportUI from "./SalesReportUI"
import fileDownload from "js-file-download"

const SalesReportController = () => {
    const dispatch = useDispatch()
    const { leaderboard } = useSelector(state => state)
    const { filters, data } = leaderboard
    const [loading, setLoading] = useState(false)
    const [exportLoading, setExportLoading] = useState(false)
    const loaded = useRef(false)


    const fetchFun = (csv) => {
        if (csv)
            setExportLoading(true)
        else
            setLoading(true)
        dispatch(fetchLeaderBoardData({ csv }, (response) => {
            setLoading(false)

            setExportLoading(false)
            if (csv) {
                fileDownload(response, 'sales-report.csv')
            }
        }, () => {
            setLoading(false)
            setExportLoading(false)
        }))
    }
    useEffect(() => {
        if (loaded.current) {
            fetchFun()
        } else {
            if (data && Array.isArray(data) && data.length == 0) {
                fetchFun()
            }
            loaded.current = true
        }




    }, [
        filters
    ])


    return <SalesReportUI
        exportLoading={exportLoading}
        fetchFun={fetchFun}
        loading={loading}
    />
}
export default SalesReportController