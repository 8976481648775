import { CircularProgress } from "@mui/material"

import { Fragment, memo } from "react"

import CustomInput from "../../../components/inputs/CustomInput"
import CustomDialog from "../../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../../components/layouts/OneViewBox"



const CreateUi = ({ title, fields, setFields, loading, onSubmit, modalKey }) => {



    return <>

        <CustomDialog
            id={modalKey}
            loading={loading}
            err={fields.err}
            onSubmit={onSubmit}
            title={` ${title}`}
            closeText="Close"
            confirmText={`Submit`}
        >

            {loading ? <CenteredBox><CircularProgress /></CenteredBox> :
                <>
                    {<CustomInput
                        autoFocus={true}
                        disabled={loading}
                        value={fields.client_id}
                        onChange={(e) => setFields({ ...fields, err: '', client_id: e.target.value })}
                        type="text"
                        label={"Client ID*"}

                    />}

                    {<CustomInput
                        disabled={loading}
                        value={fields.client_secret}
                        onChange={(e) => setFields({ ...fields, err: '', client_secret: e.target.value })}
                        type="text"
                        label={"Client Secret*"}

                    />}
                    {<CustomInput
                        disabled={loading}
                        value={fields.webhook_url}
                        onChange={(e) => setFields({ ...fields, err: '', webhook_url: e.target.value })}
                        type="text"
                        label={"Webhook Url*"}
                    />}
                </>}

        </CustomDialog>
    </>
}
export default memo(CreateUi)