import { OpenInNew } from "@mui/icons-material"
import { Autocomplete, Button, ButtonGroup, CircularProgress, Grid, IconButton, MenuItem, Paper, Skeleton, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tabs, TextField, Tooltip, Typography, styled } from "@mui/material"
import { Box } from "@mui/system"
import { Fragment, memo, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"



import SubmitButton from "../../components/button/SubmitButton"

import DataTable from "../../components/tables/DataTable"

import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxInDesktop"
import AsynSearchBar from "../../components/inputs/AsynSearchBar"
import getComplainCategoryApi from "../../apis/complaincategory.api"
import { StyledSearchBar } from "../../components/inputs/SearchBar"
import AsyncDropDown from "../../components/inputs/AsyncDropDown"
import ComplainCounts from "./ScheduledCallCounts"
import TimeRangeSelector from "../../components/layouts/common/TimeRangeSelector"
import { moduleAccessCondition } from "../../utils/helpers/helper"
import MODULES from "../../utils/constants/module.constants"
import SelectDropDown from "../../components/inputs/SelectDropDown"
import { SCHEDULEDCALL_STATUS } from "../../utils/constants/schedulecall.constant"
import { getUserApi } from "../../apis/user.api"
import { USER_ROLES } from "../../utils/constants/constants"
import SalesPersonFilterDropDown from "../../components/filterdropdowns/SalesPersonFilter"



const FilterTitleBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }

}))
const FiltersBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",

    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }
}))


const ListUi = ({ title, filters, setFilters, list, modal, loading, onCreateBtnClick, columns }) => {

    const user = useSelector(state => state.user)

    return (
        <>

            <Box mb={modal ? 0 : 3}>
                <Paper elevation={modal ? 0 : 2} sx={{ width: "100%", padding: modal ? 0 : 4 }} >
                    <Box mb={2} >
                        <FilterTitleBox>
                            <Box>
                                {!modal && <Typography variant="h5"  >{title}</Typography>}
                            </Box>
                        </FilterTitleBox>
                        {/* <PaddingBoxInDesktop display={"flex"} flex={1} pl={1} mt={2} >
                            <SelectDropDown
                                margin="none"
                                title="Call Status"
                                value={filters.status}
                                onChange={(e) => { setFilters({ ...filters, status: e.target.value }) }}
                                data={[
                                    { label: "Pending", value: SCHEDULEDCALL_STATUS.PENDING },
                                    { label: "Completed", value: SCHEDULEDCALL_STATUS.COMPLETED },
                                ]}
                            />
                        </PaddingBoxInDesktop> */}
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={filters.status} onChange={(e,val) => { setFilters({ ...filters, status: val }) }}>
                                <Tab value={SCHEDULEDCALL_STATUS.PENDING} label="Pending" >Pending</Tab>
                                <Tab value={SCHEDULEDCALL_STATUS.COMPLETED} label="Completed" >Completed</Tab>
                            </Tabs>
                        </Box>
                        <FiltersBox mt={3} >
                            <PaddingBoxInDesktop display={"flex"} flex={1} pl={1} >
                                {/* <AsyncDropDown
                                    id={'sales-user-list'}

                                    lazyFun={async (params) => { return await getUserApi({ ...params, role: USER_ROLES.sales }) }}

                                    OptionComponent={({ option, ...rest }) => {
                                        return <MenuItem {...rest}>{option.name}</MenuItem>
                                    }}
                                    size="small"
                                    onChange={async (changedVal) => { setFilters({ ...filters, subCategory: changedVal ? changedVal._id : null }) }}
                                    titleKey={'name'}
                                    valueKey={"_id"}
                                    InputComponent={(params) => <StyledSearchBar placeholder="Select sales person" {...params} size="small" />}
                                /> */}
                                <SalesPersonFilterDropDown

                                    value={filters?.subCategory}

                                    id={'sales-leads'}
                                    filters={filters}
                                    setFilters={setFilters}
                                    margin="dense"
                                />
                            </PaddingBoxInDesktop>

                        </FiltersBox>
                        <FiltersBox mt={3} >
                            <ComplainCounts filters={filters} setFilters={setFilters} />
                        </FiltersBox>
                        <FiltersBox mt={3} >

                            <PaddingBoxInDesktop mb={2} sx={{ display: "flex", justifyContent: "flex-end" }} pl={3} >

                                {/* <AsynSearchBar
                                    fullWidth
                                    title="Search By Source Name " size="small" placeholder={"Search By Source Name "}
                                    defaultValue={filters.search}
                                    onChange={(changedVal) => { setFilters({ ...filters, search: changedVal }) }}
                                /> */}
                                {/* <StyledSearchBar  title="Search By Key Name " size="small" placeholder={"Search By key Name "} value={filters.search} onChange={(e) => { setFilters({ ...filters, search: e.target.value }) }} /> */}
                            </PaddingBoxInDesktop>



                        </FiltersBox>

                    </Box>

                    <Box sx={{ minHeight: modal ? "0px" : "300px" }}>
                        <DataTable columns={columns} rows={list.result ? list.result : []} count={list.total ?? 0} filters={filters} setFilters={setFilters} loading={loading} />
                    </Box>
                </Paper>
            </Box>

        </>
    )
}
export default ListUi