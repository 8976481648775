import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";

import { Fragment, memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import CustomInput from "../../components/inputs/CustomInput";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import { CenteredBox } from "../../components/layouts/OneViewBox";
import { AUS_STATES } from "../../utils/constants/constants";
import FileInput from "../../components/inputs/FileInput";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { TroubleshootOutlined } from "@mui/icons-material";

const CreateUi = ({
  title,
  isUpdate,
  fields,
  setFields,
  loading,
  onSubmit,
}) => {
  return (
    <>
      <CustomDialog
        loading={loading}
        err={fields.err}
        onSubmit={onSubmit}
        title={`${isUpdate ? "Update" : "Create"} ${title}`}
        closeText="Close"
        confirmText={`${isUpdate ? "Update" : "Create"}`}
      >
        {loading ? (
          <CenteredBox>
            <CircularProgress />
          </CenteredBox>
        ) : (
          <>
            {!isUpdate && (
              <>
                <CustomInput
                  autoFocus={true}
                  disabled={loading}
                  value={fields.name}
                  onChange={(e) =>
                    setFields({ ...fields, err: "", name: e.target.value })
                  }
                  type="text"
                  label={"Name*"}
                />
                {
                  <CustomInput
                    disabled={loading}
                    value={fields.email}
                    onChange={(e) =>
                      setFields({ ...fields, err: "", email: e.target.value })
                    }
                    type="text"
                    label={"Email*"}
                  />
                }
                {
                  <CustomInput
                    disabled={loading}
                    value={fields.phone}
                    onChange={(e) =>
                      setFields({ ...fields, err: "", phone: e.target.value })
                    }
                    type="text"
                    label={"Phone*"}
                  />
                }
              </>
            )}
            {!isUpdate && ( 
              <CustomInput
                disabled={loading}
                value={fields.password}
                onChange={(e) =>
                  setFields({ ...fields, err: "", password: e.target.value })
                }
                type="password"
                label={"Password*"}
              />
            )}
            {isUpdate && (
              <Box sx={{ width: "100%" }}>
                <Grid
                  item
                  md={12}
                  xs={12}
                  gap={2}
                  display={"flex"}
                  flexDirection={"row"}
                  width={"100%"}
                >
                  <Grid item md={8} xs={8}>
                    <Box width={"27.1vw"}>
                      <CustomInput
                        disabled={loading}
                        value={fields.name}
                        onChange={(e) =>
                          setFields({
                            ...fields,
                            err: "",
                            name: e.target.value,
                          })
                        }
                        type="text"
                        label={"Name"}
                      />
                    </Box>
                  </Grid>
                  <Grid item md={8} xs={8}>
                    <Box width={"27.1vw"}>
                      <CustomInput
                        disabled={loading}
                        value={fields.email}
                        onChange={(e) =>
                          setFields({
                            ...fields,
                            err: "",
                            email: e.target.value,
                          })
                        }
                        type="text"
                        label={"Email"}
                      />
                    </Box>
                  </Grid>
                  <Grid item md={8} xs={8}>
                    <Box width={"27.1vw"}>
                      <CustomInput
                        disabled={loading}
                        value={fields.phone}
                        onChange={(e) =>
                          setFields({
                            ...fields,
                            err: "",
                            phone: e.target.value,
                          })
                        }
                        type="number"
                        label={"Phone"}
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Grid
                  container
                  md={12}
                  xs={12}
                  display={"flex"}
                  flexDirection={"row"}
                >
                  <Grid item md={6} xs={6}>
                    <Typography
                      sx={{ display: "flex", justifyContent: "flex-start" }}
                      fontWeight="bold"
                      variant="h6"
                    >
                      License Details :
                    </Typography>
                    <Grid
                      item
                      md={6}
                      xs={6}
                      gap={2}
                      display={"flex"}
                      flexDirection={"row"}
                    >
                      <Grid item md={12} xs={12}>
                        <Box width={"19.7vw"}>
                          <CustomInput
                            disabled={loading}
                            value={fields.license_number}
                            onChange={(e) =>
                              setFields({
                                ...fields,
                                err: "",
                                license_number: e.target.value,
                              })
                            }
                            type="text"
                            label={"License No"}
                          />
                        </Box>
                      </Grid>
                      <Grid item md={3} xs={3}>
                        <Box width={"19.7vw"}>
                          <DesktopDatePicker
                            label="License Expiry"
                            inputFormat="DD/MM/yyyy"
                            renderInput={(props) => <CustomInput {...props} />}
                            value={
                              fields.license_expiry
                                ? moment(fields.license_expiry)
                                : null
                            }
                            onChange={(e) =>
                              setFields({
                                ...fields,
                                err: "",
                                license_expiry: e.valueOf(),
                              })
                            }
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={6}
                      gap={2}
                      display={"flex"}
                      flexDirection={"row"}
                    >
                      <Grid width={"100%"} item md={6} xs={6}>
                        <Box width={"40vw"}>
                          <FileInput
                            defaults={
                              fields.license_file ? [fields.license_file] : []
                            }
                            title="License Proof"
                            subTitle="Only .pdf  less than 2mb are valid."
                            accept=".pdf"
                            onChange={(newUrl) => {
                              setFields({ ...fields, license_file: newUrl });
                            }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item md={6} xs={6}>
                    <Typography
                      sx={{ display: "flex", justifyContent: "flex-start" }}
                      fontWeight="bold"
                      variant="h6"
                    >
                      SubContractor :
                    </Typography>
                    <Grid
                      item
                      md={6}
                      xs={6}
                      gap={2}
                      display={"flex"}
                      flexDirection={"row"}
                      width={"100%"}
                    >
                      <Grid item md={8} xs={8}>
                        <Box width={"13vw"}>
                          <CustomInput
                            disabled={loading}
                            value={fields.subcontractor_name}
                            onChange={(e) =>
                              setFields({
                                ...fields,
                                err: "",
                                subcontractor_name: e.target.value,
                              })
                            }
                            type="text"
                            label={"Full Name"}
                          />
                        </Box>
                      </Grid>
                      <Grid item md={8} xs={8}>
                        <Box width={"13vw"}>
                          <CustomInput
                            disabled={loading}
                            value={fields.subcontractor_acn}
                            onChange={(e) =>
                              setFields({
                                ...fields,
                                err: "",
                                subcontractor_acn: e.target.value,
                              })
                            }
                            type="number"
                            label={"ACN & ABN"}
                          />
                        </Box>
                      </Grid>
                      <Grid item md={8} xs={8}>
                        <Box width={"13vw"}>
                          <CustomInput
                            disabled={loading}
                            value={fields.subcontractor_phone}
                            onChange={(e) =>
                              setFields({
                                ...fields,
                                err: "",
                                subcontractor_phone: e.target.value,
                              })
                            }
                            type="number"
                            label={"Contact"}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={6}
                      gap={2}
                      display={"flex"}
                      flexDirection={"row"}
                    >
                      <Grid item md={8} xs={8}>
                        <Box width={"13vw"}>
                          <CustomInput
                            disabled={loading}
                            value={fields.subcontractor_landPhone}
                            onChange={(e) =>
                              setFields({
                                ...fields,
                                err: "",
                                subcontractor_landPhone: e.target.value,
                              })
                            }
                            type="number"
                            label={"Land Phone No."}
                          />
                        </Box>
                      </Grid>
                      <Grid item md={8} xs={8}>
                        <Box width={"13vw"}>
                          <CustomInput
                            disabled={loading}
                            value={fields.subcontractor_email}
                            onChange={(e) =>
                              setFields({
                                ...fields,
                                err: "",
                                subcontractor_email: e.target.value,
                              })
                            }
                            type="text"
                            label={"Email"}
                          />
                        </Box>
                      </Grid>
                      <Grid item md={8} xs={8}>
                        <Box width={"13vw"}>
                          <CustomInput
                            disabled={loading}
                            value={fields.subcontractor_address}
                            onChange={(e) =>
                              setFields({
                                ...fields,
                                err: "",
                                subcontractor_address: e.target.value,
                              })
                            }
                            type="text"
                            label={"Address"}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  mt={3}
                  container
                  md={12}
                  xs={12}
                  display={"flex"}
                  flexDirection={"row"}
                >
                  <Grid item md={6} xs={6}>
                    <Typography
                      sx={{ display: "flex", justifyContent: "flex-start" }}
                      fontWeight="bold"
                      variant="h6"
                    >
                      Guarantor :
                    </Typography>
                    <Grid
                      item
                      md={6}
                      xs={6}
                      display={"flex"}
                      flexDirection={"column"}
                      width={"100%"}
                    >
                      <Grid item md={6} xs={6}>
                        <Box width={"39.8vw"}>
                          <CustomInput
                            disabled={loading}
                            value={fields.guaeantor_name}
                            onChange={(e) =>
                              setFields({
                                ...fields,
                                err: "",
                                guaeantor_name: e.target.value,
                              })
                            }
                            type="text"
                            label={"Guarantor Name"}
                          />
                        </Box>
                      </Grid>
                      <Grid item md={6} xs={6}>
                        <Box width={"39.8vw"}>
                          <CustomInput
                            disabled={loading}
                            value={fields.guaeantor_address}
                            onChange={(e) =>
                              setFields({
                                ...fields,
                                err: "",
                                guaeantor_address: e.target.value,
                              })
                            }
                            type="text"
                            label={"Address"}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item md={6} xs={6}>
                    <Typography
                      sx={{ display: "flex", justifyContent: "flex-start" }}
                      fontWeight="bold"
                      variant="h6"
                    >
                      Public Liability Insurance :
                    </Typography>
                    <Grid
                      item
                      md={6}
                      xs={6}
                      gap={2}
                      display={"flex"}
                      flexDirection={"row"}
                      width={"100%"}
                    >
                      <Grid item md={12} xs={12}>
                        <Box width={"19.6vw"}>
                          <CustomInput
                            disabled={loading}
                            value={fields.liability_company}
                            onChange={(e) =>
                              setFields({
                                ...fields,
                                err: "",
                                liability_company: e.target.value,
                              })
                            }
                            type="text"
                            label={"Company"}
                          />
                        </Box>
                      </Grid>
                      <Grid item md={8} xs={8}>
                        <Box width={"19.6vw"}>
                          <CustomInput
                            disabled={loading}
                            value={fields.liability_policy_no}
                            onChange={(e) =>
                              setFields({
                                ...fields,
                                err: "",
                                liability_policy_no: e.target.value,
                              })
                            }
                            type="text"
                            label={"Policy No."}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={6}
                      gap={2}
                      display={"flex"}
                      flexDirection={"row"}
                    >
                      <Grid item md={12} xs={12}>
                        <Box sx={{ display: "flex", width: "19.6vw" }}>
                          <DesktopDatePicker
                            autoFocus={true}
                            label={"Policy Issue Date"}
                            renderInput={(props) => {
                              return (
                                <CustomInput
                                  {...props}
                                  sx={{ height: "100%" }}
                                />
                              );
                            }}
                            inputFormat="DD-MM-yyyy"
                            value={moment(fields.liability_policy_date)}
                            onChange={(val) => {
                              if (val != null) {
                                const isoString = val.toISOString();
                                setFields({
                                  ...fields,
                                  err: "",
                                  liability_policy_date: isoString,
                                });
                              }
                            }}
                            type="date"
                          />
                        </Box>
                      </Grid>
                      <Grid item md={8} xs={8}>
                        <Box sx={{ display: "flex", width: "19.6vw" }}>
                          <DesktopDatePicker
                            autoFocus={true}
                            label={"Policy Expiry Date"}
                            renderInput={(props) => {
                              return (
                                <CustomInput
                                  {...props}
                                  sx={{ height: "100%" }}
                                />
                              );
                            }}
                            inputFormat="DD-MM-yyyy"
                            value={moment(fields.liability_policy_expiryDate)}
                            onChange={(val) => {
                              if (val != null) {
                                const isoString = val.toISOString();
                                setFields({
                                  ...fields,
                                  err: "",
                                  liability_policy_expiryDate: isoString,
                                });
                              }
                            }}
                            type="date"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  mt={3}
                  md={12}
                  xs={12}
                  display={"flex"}
                  flexDirection={"row"}
                >
                  <Grid item md={6} xs={6}>
                    <Typography
                      sx={{ display: "flex", justifyContent: "flex-start" }}
                      fontWeight="bold"
                      variant="h6"
                    >
                      Workers Compensation Insurance :
                    </Typography>
                    <Grid
                      item
                      md={6}
                      xs={6}
                      gap={2}
                      display={"flex"}
                      flexDirection={"row"}
                      width={"100%"}
                    >
                      <Grid item md={12} xs={12}>
                        <Box width={"19.6vw"}>
                          <CustomInput
                            disabled={loading}
                            value={fields.wic_company}
                            onChange={(e) =>
                              setFields({
                                ...fields,
                                err: "",
                                wic_company: e.target.value,
                              })
                            }
                            type="text"
                            label={"Company"}
                          />
                        </Box>
                      </Grid>
                      <Grid item md={8} xs={8}>
                        <Box width={"19.6vw"}>
                          <CustomInput
                            disabled={loading}
                            value={fields.wic_policy_no}
                            onChange={(e) =>
                              setFields({
                                ...fields,
                                err: "",
                                wic_policy_no: e.target.value,
                              })
                            }
                            type="text"
                            label={"Policy No."}
                          />
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      md={6}
                      xs={6}
                      gap={2}
                      display={"flex"}
                      flexDirection={"row"}
                    >
                      <Grid item md={12} xs={12}>
                        <Box sx={{ display: "flex", width: "19.6vw" }}>
                          <DesktopDatePicker
                            autoFocus={true}
                            label={"Policy Issue Date"}
                            renderInput={(props) => {
                              return (
                                <CustomInput
                                  {...props}
                                  sx={{ height: "100%" }}
                                />
                              );
                            }}
                            inputFormat="DD-MM-yyyy"
                            value={moment(fields.wic_policy_date)}
                            onChange={(val) => {
                              if (val != null) {
                                const isoString = val.toISOString();
                                setFields({
                                  ...fields,
                                  err: "",
                                  wic_policy_date: isoString,
                                });
                              }
                            }}
                            type="date"
                          />
                        </Box>
                      </Grid>
                      <Grid item md={8} xs={8}>
                        <Box sx={{ display: "flex", width: "19.6vw" }}>
                          <DesktopDatePicker
                            autoFocus={true}
                            label={"Policy Expiry Date"}
                            renderInput={(props) => {
                              return (
                                <CustomInput
                                  {...props}
                                  sx={{ height: "100%" }}
                                />
                              );
                            }}
                            inputFormat="DD-MM-yyyy"
                            value={moment(fields.wic_policy_expiryDate)}
                            onChange={(val) => {
                              if (val != null) {
                                const isoString = val.toISOString();
                                setFields({
                                  ...fields,
                                  err: "",
                                  wic_policy_expiryDate: isoString,
                                });
                              }
                            }}
                            type="date"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item md={6} xs={6}>
                    <Typography
                      sx={{ display: "flex", justifyContent: "flex-start" }}
                      fontWeight="bold"
                      variant="h6"
                    >
                      Personal Accident And Sickness Insurance :
                    </Typography>
                    <Grid
                      item
                      md={6}
                      xs={6}
                      gap={2}
                      display={"flex"}
                      flexDirection={"row"}
                      width={"100%"}
                    >
                      <Grid item md={12} xs={12}>
                        <Box width={"19.6vw"}>
                          <CustomInput
                            disabled={loading}
                            value={fields.pasi_company}
                            onChange={(e) =>
                              setFields({
                                ...fields,
                                err: "",
                                pasi_company: e.target.value,
                              })
                            }
                            type="text"
                            label={"Company"}
                          />
                        </Box>
                      </Grid>
                      <Grid item md={8} xs={8}>
                        <Box width={"19.6vw"}>
                          <CustomInput
                            disabled={loading}
                            value={fields.pasi_policy_no}
                            onChange={(e) =>
                              setFields({
                                ...fields,
                                err: "",
                                pasi_policy_no: e.target.value,
                              })
                            }
                            type="text"
                            label={"Policy No."}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={6}
                      gap={2}
                      display={"flex"}
                      flexDirection={"row"}
                    >
                      <Grid item md={12} xs={12}>
                        <Box sx={{ display: "flex", width: "19.6vw" }}>
                          <DesktopDatePicker
                            autoFocus={true}
                            label={"Policy Issue Date"}
                            renderInput={(props) => {
                              return (
                                <CustomInput
                                  {...props}
                                  sx={{ height: "100%" }}
                                />
                              );
                            }}
                            inputFormat="DD-MM-yyyy"
                            value={moment(fields.pasi_policy_date)}
                            onChange={(val) => {
                              if (val != null) {
                                const isoString = val.toISOString();
                                setFields({
                                  ...fields,
                                  err: "",
                                  pasi_policy_date: isoString,
                                });
                              }
                            }}
                            type="date"
                          />
                        </Box>
                      </Grid>
                      <Grid item md={8} xs={8}>
                        <Box sx={{ display: "flex", width: "19.6vw" }}>
                          <DesktopDatePicker
                            autoFocus={true}
                            label={"Policy Expiry Date"}
                            renderInput={(props) => {
                              return (
                                <CustomInput
                                  {...props}
                                  sx={{ height: "100%" }}
                                />
                              );
                            }}
                            inputFormat="DD-MM-yyyy"
                            value={moment(fields.pasi_policy_expiryDate)}
                            onChange={(val) => {
                              if (val != null) {
                                const isoString = val.toISOString();
                                setFields({
                                  ...fields,
                                  err: "",
                                  pasi_policy_expiryDate: isoString,
                                });
                              }
                            }}
                            type="date"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  mt={3}
                  md={12}
                  xs={12}
                  display={"flex"}
                  flexDirection={"row"}
                >
                  <Grid item md={6} xs={6}>
                    <Typography
                      sx={{ display: "flex", justifyContent: "flex-start" }}
                      fontWeight="bold"
                      variant="h6"
                    >
                      Registered Electrical Contractor (REC) :
                    </Typography>
                    <Grid
                      item
                      md={6}
                      xs={6}
                      gap={2}
                      display={"flex"}
                      flexDirection={"row"}
                    >
                      <Grid item md={11} xs={11}>
                        <Box width={"39.8vw"}>
                          <CustomInput
                            disabled={loading}
                            value={fields.rec_license_no}
                            onChange={(e) =>
                              setFields({
                                ...fields,
                                err: "",
                                rec_license_no: e.target.value,
                              })
                            }
                            type="text"
                            label={"License No."}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={6}
                      gap={2}
                      display={"flex"}
                      flexDirection={"row"}
                    >
                      <Grid item md={11} xs={11}>
                        <Box width={"19.6vw"}>
                          <DesktopDatePicker
                            autoFocus={true}
                            label={"License Issue Date"}
                            renderInput={(props) => {
                              return (
                                <CustomInput
                                  {...props}
                                  sx={{ height: "100%" }}
                                />
                              );
                            }}
                            inputFormat="DD-MM-yyyy"
                            value={moment(fields.rec_license_date)}
                            onChange={(val) => {
                              if (val != null) {
                                const isoString = val.toISOString();
                                setFields({
                                  ...fields,
                                  err: "",
                                  rec_license_date: isoString,
                                });
                              }
                            }}
                            type="date"
                          />
                        </Box>
                      </Grid>
                      <Grid width={"100%"} item md={6} xs={6}>
                        <Box width={"20vw"}>
                          <DesktopDatePicker
                            autoFocus={true}
                            label={"License Expiry Date"}
                            renderInput={(props) => {
                              return (
                                <CustomInput
                                  {...props}
                                  sx={{ height: "100%" }}
                                />
                              );
                            }}
                            inputFormat="DD-MM-yyyy"
                            value={moment(fields.rec_license_expiry_date)}
                            onChange={(val) => {
                              if (val != null) {
                                const isoString = val.toISOString();
                                setFields({
                                  ...fields,
                                  err: "",
                                  rec_license_expiry_date: isoString,
                                });
                              }
                            }}
                            type="date"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item md={6} xs={6}>
                    <Typography
                      sx={{ display: "flex", justifyContent: "flex-start" }}
                      fontWeight="bold"
                      variant="h6"
                    >
                      Electrician's License (A Class) :
                    </Typography>
                    <Grid
                      item
                      md={6}
                      xs={6}
                      gap={2}
                      display={"flex"}
                      flexDirection={"row"}
                    >
                      <Grid item md={11} xs={11}>
                        <Box width={"39.8vw"}>
                          <CustomInput
                            disabled={loading}
                            value={fields.electrician_license_no}
                            onChange={(e) =>
                              setFields({
                                ...fields,
                                err: "",
                                electrician_license_no: e.target.value,
                              })
                            }
                            type="text"
                            label={"License No."}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={6}
                      gap={2}
                      display={"flex"}
                      flexDirection={"row"}
                    >
                      <Grid item md={12} xs={12}>
                        <Box width={"20vw"}>
                          <DesktopDatePicker
                            autoFocus={true}
                            label={"License Issue Date"}
                            renderInput={(props) => {
                              return (
                                <CustomInput
                                  {...props}
                                  sx={{ height: "100%" }}
                                />
                              );
                            }}
                            inputFormat="DD-MM-yyyy"
                            value={moment(fields.electrician_license_date)}
                            onChange={(val) => {
                              if (val != null) {
                                const isoString = val.toISOString();
                                setFields({
                                  ...fields,
                                  err: "",
                                  electrician_license_date: isoString,
                                });
                              }
                            }}
                            type="date"
                          />
                        </Box>
                      </Grid>
                      <Grid width={"100%"} item md={6} xs={6}>
                        <Box width={"19.2vw"}>
                          <DesktopDatePicker
                            autoFocus={true}
                            label={"License Expiry Date"}
                            renderInput={(props) => {
                              return (
                                <CustomInput
                                  {...props}
                                  sx={{ height: "100%" }}
                                />
                              );
                            }}
                            inputFormat="DD-MM-yyyy"
                            value={moment(
                              fields.electrician_license_expiryDate
                            )}
                            onChange={(val) => {
                              if (val != null) {
                                const isoString = val.toISOString();
                                setFields({
                                  ...fields,
                                  err: "",
                                  electrician_license_expiryDate: isoString,
                                });
                              }
                            }}
                            type="date"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  mt={3}
                  md={12}
                  xs={12}
                  display={"flex"}
                  flexDirection={"row"}
                >
                  <Grid item md={6} xs={6}>
                    <Typography
                      sx={{ display: "flex", justifyContent: "flex-start" }}
                      fontWeight="bold"
                      variant="h6"
                    >
                      SAA ACCREDITATION :
                    </Typography>
                    <Grid
                      item
                      md={6}
                      xs={6}
                      gap={2}
                      display={"flex"}
                      flexDirection={"row"}
                    >
                      <Grid item md={11} xs={11}>
                        <Box width={"39.8vw"}>
                          <CustomInput
                            disabled={loading}
                            value={fields.accreditation_license_no}
                            onChange={(e) =>
                              setFields({
                                ...fields,
                                err: "",
                                accreditation_license_no: e.target.value,
                              })
                            }
                            type="text"
                            label={"License No."}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={6}
                      gap={2}
                      display={"flex"}
                      flexDirection={"row"}
                    >
                      <Grid item md={11} xs={11}>
                        <Box width={"19.2vw"}>
                          <DesktopDatePicker
                            autoFocus={true}
                            label={"License Issue Date"}
                            renderInput={(props) => {
                              return (
                                <CustomInput
                                  {...props}
                                  sx={{ height: "100%" }}
                                />
                              );
                            }}
                            inputFormat="DD-MM-yyyy"
                            value={moment(fields.accreditation_license_date)}
                            onChange={(val) => {
                              if (val != null) {
                                const isoString = val.toISOString();
                                setFields({
                                  ...fields,
                                  err: "",
                                  accreditation_license_date: isoString,
                                });
                              }
                            }}
                            type="date"
                          />
                        </Box>
                      </Grid>
                      <Grid width={"100%"} item md={6} xs={6}>
                        <Box width={"20vw"}>
                          <DesktopDatePicker
                            autoFocus={true}
                            label={"License Expiry Date"}
                            renderInput={(props) => {
                              return (
                                <CustomInput
                                  {...props}
                                  sx={{ height: "100%" }}
                                />
                              );
                            }}
                            inputFormat="DD-MM-yyyy"
                            value={moment(
                              fields.accreditation_license_expiryDate
                            )}
                            onChange={(val) => {
                              if (val != null) {
                                const isoString = val.toISOString();
                                setFields({
                                  ...fields,
                                  err: "",
                                  accreditation_license_expiryDate: isoString,
                                });
                              }
                            }}
                            type="date"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item md={6} xs={6}>
                    <Typography
                      sx={{ display: "flex", justifyContent: "flex-start" }}
                      fontWeight="bold"
                      variant="h6"
                    >
                      SAA Design License :
                    </Typography>
                    <Grid
                      item
                      md={6}
                      xs={6}
                      gap={2}
                      display={"flex"}
                      flexDirection={"row"}
                    >
                      <Grid item md={11} xs={11}>
                        <Box width={"39.8vw"}>
                          <CustomInput
                            disabled={loading}
                            value={fields.design_license_no}
                            onChange={(e) =>
                              setFields({
                                ...fields,
                                err: "",
                                design_license_no: e.target.value,
                              })
                            }
                            type="text"
                            label={"License No."}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={6}
                      gap={2}
                      display={"flex"}
                      flexDirection={"row"}
                    >
                      <Grid item md={12} xs={12}>
                        <Box width={"19.6vw"}>
                          <DesktopDatePicker
                            autoFocus={true}
                            label={"License Issue Date"}
                            renderInput={(props) => {
                              return (
                                <CustomInput
                                  {...props}
                                  sx={{ height: "100%" }}
                                />
                              );
                            }}
                            inputFormat="DD-MM-yyyy"
                            value={moment(fields.design_license_date)}
                            onChange={(val) => {
                              if (val != null) {
                                const isoString = val.toISOString();
                                setFields({
                                  ...fields,
                                  err: "",
                                  design_license_date: isoString,
                                });
                              }
                            }}
                            type="date"
                          />
                        </Box>
                      </Grid>
                      <Grid width={"100%"} item md={6} xs={6}>
                        <Box width={"19.6vw"}>
                          <DesktopDatePicker
                            autoFocus={true}
                            label={"License Expiry Date"}
                            renderInput={(props) => {
                              return (
                                <CustomInput
                                  {...props}
                                  sx={{ height: "100%" }}
                                />
                              );
                            }}
                            inputFormat="DD-MM-yyyy"
                            value={moment(fields.design_license_expiryDate)}
                            onChange={(val) => {
                              if (val != null) {
                                const isoString = val.toISOString();
                                setFields({
                                  ...fields,
                                  err: "",
                                  design_license_expiryDate: isoString,
                                });
                              }
                            }}
                            type="date"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            )}
          </>
        )}
      </CustomDialog>
    </>
  );
};
export default memo(CreateUi);
