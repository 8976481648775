import axios from "axios";
import { getHeaders } from "../utils/helpers/helper";
import endpoints from "./endpoints";




export const getPreDefinedTagsCategoryApi = async params => {
  const callResponse = await axios({
    url: endpoints.preDefinedTagsCategoryBase,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


export const getPreDefinedTagsCategoryByIdApi = async params => {
  const callResponse = await axios({
    url: endpoints.preDefinedTagsCategoryBaseById,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


export const addPreDefinedTagsCategoryApi = async data => {
  const callResponse = await axios({
    url: endpoints.preDefinedTagsCategoryBase,
    method: "POST",
    headers: getHeaders(),
    
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const updatePreDefinedTagsCategoryApi = async data => {
  const callResponse = await axios({
    url: endpoints.preDefinedTagsCategoryBase,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const addTagFromPreDefinedTagsCategoryApi = async data => {
  const callResponse = await axios({
    url: endpoints.preDefinedTagsAdd,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const removeTagFromPreDefinedTagsCategoryApi = async data => {
  const callResponse = await axios({
    url: endpoints.preDefinedTagsRemove,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const deletePreDefinedTagsCategoryApi = async data => {
  const callResponse = await axios({
    url: endpoints.preDefinedTagsCategoryBase,
    method: "DELETE",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};