import { redirect } from "react-router-dom"
import { actions } from "../../utils/constants/constants"
import { callApiAction } from "./commonAction"
import getBlogApi from "../../apis/blog.api"
export const fetchBlogAction = () => {
    return async (dispatch, getState) => {
    dispatch(callApiAction(
            async () => await getBlogApi({ pageSize: 3 }),
            (response) => {
                // console.log("response",response);
                console.log("responsedd inside action",response)
                const lastReadId = getState().user.data.last_read_blog;
                // console.log("last Read Od",lastReadId);
                const updatedResponse = response.result.map((item) => (
                    {
                        ...item,
                        blog_state : false,
                    }
                ))
               
                dispatch({ type: actions.SET_BLOG_DATA, data: updatedResponse })


            },
            (err) => {

            }
        ))
    }
}

