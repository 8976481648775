import { memo, useCallback, useEffect, useState } from "react"
import ListUi from "./ListPipelineUsersUi"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../../store/actions/commonAction"

import { getPipelineUsersApi } from "../../../apis/pipeline.api"
import { MODAL_KEYS } from "../../../utils/constants/modal.constant"

const ListPipelineUsersController = ({ pipeline_id }) => {
    const dispatch = useDispatch()

    const modalKey = MODAL_KEYS.PIPELINE_USER_MODAL

    const title = "Pipeline Users"
    const fetchApi = getPipelineUsersApi

    const [filters, setFilters] = useState({
        pipeline_id,
        pageNo: 1,
        pageSize: 24,
        searchable: ['name'],
        search: '',

    })







    // do not change 

    const [loading, setLoading] = useState(false)
    const [list, setList] = useState({})









    const fetchList = useCallback(() => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchApi({ ...filters }),
            (response) => {
                setList(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }, [dispatch, setList, setLoading, filters])




    useEffect(() => {
        fetchList()
    }, [filters])








    return (
        <>
            <ListUi
                title={title}
                modalKey={modalKey}
                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}
                callBack={fetchList}

            />

        </>
    )
}
export default ListPipelineUsersController