import { Box, CircularProgress, Grid, Paper, Skeleton, Typography } from "@mui/material"
import { USER_ROLES } from "../../../utils/constants/constants"

import TimeRangeSelector from "../../../components/layouts/common/TimeRangeSelector"
import { useSelector } from "react-redux"
import SalesPersonFilterDropDown from "../../../components/filterdropdowns/SalesPersonFilter"
import TeamFilterDropDown from "../../../components/filterdropdowns/TeamFilterDropDown"
import LeadTimeFilters from "../../lead/filters/LeadTimeFilter"
import FilterButton from "../../lead/filters/FilterButton"
import { Paid, SolarPower } from "@mui/icons-material"
import BubbleComponent from "../../../components/layouts/BubbleComponent"
import DashBox from "../Dashbox"

const ReportComponent = ({ title, lead, amount, color, size }) => {

    return <Paper sx={{ height: "100%", borderColor: color }} variant="outlined">
        <Box p={2} >
            <Typography mb={2} textTransform={"capitalize"} align="center" variant="h5" fontWeight={"bold"}>{lead ?? 0}</Typography>
            <Typography textTransform={"capitalize"} align="center" lineHeight="100%" mb={2} variant="caption" display="flex" alignItems="center" justifyContent="center">

                <SolarPower fontSize="inherit" />&nbsp;{size?.toFixed?.(2) ?? 0}kw
            </Typography>
            <Typography textTransform={"capitalize"} align="center" lineHeight="100%" mb={2} variant="caption" display="flex" alignItems="center" justifyContent="center">
                <Paid fontSize="inherit" />&nbsp;{amount?.toFixed?.(2) ?? 0}$

            </Typography>
            <Typography mb={2} textTransform={"capitalize"} align="center" variant="subtitle2" fontWeight={"bold"}>{title}</Typography>

        </Box>
    </Paper>
}

const LeadIndividualSalesReportUi = ({ loading, list, filters, setFilters }) => {

    const { user } = useSelector(state => state)
    const percentage = !loading && list.won ? ((list.won?.total_lead / (list.allocated?.total_lead != 0 ? list.allocated?.total_lead : 1)) * 100).toFixed(0) : 0

    return (
        <>
            <DashBox title={"Sales Report"}>
                <Box p={2} >
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <LeadTimeFilters onlyRange filters={filters} setFilters={setFilters} />
                        </Grid>
                        {<Grid item xs={6} >



                            <TeamFilterDropDown
                                id={'teams-lead-analytics-status'}
                                filters={filters}
                                setFilters={setFilters}
                            />


                        </Grid>}
                        <Grid item xs={6} >

                            <SalesPersonFilterDropDown
                                id={'sales-lead-analytics-status'}
                                filters={filters}
                                setFilters={setFilters}
                                key={filters.team}
                            />
                        </Grid>

                    </Grid>
                    <Box display="flex" justifyContent="flex-end" width="100%" mt={1}>
                        <FilterButton notApplicableFilters={['date', 'status']} defaultFilters={filters} setDefaultFilters={setFilters} />
                    </Box>

                    <Grid container spacing={2}>

                        {
                            loading && [0, 1].map((item) => {
                                return <Grid key={item} item xs={6} md={6}>
                                    <Skeleton width={"100%"} height="150px" />
                                </Grid>
                            })
                        }
                        {
                            !loading && list && <>

                                <Grid item xs={4} >
                                    <ReportComponent
                                        title={"Allocated"}
                                        amount={list.allocated?.total_amount}
                                        size={list.allocated?.total_size}
                                        lead={list.allocated?.total_lead}
                                    />

                                </Grid>
                                <Grid item xs={4} >
                                    <ReportComponent
                                        title={"Won"}
                                        color="success.main"
                                        amount={list.won?.total_amount}
                                        size={list.won?.total_size}
                                        lead={list.won?.total_lead}
                                    />
                                </Grid>
                                <Grid item xs={4} >
                                    <ReportComponent
                                        title={"Rejected"}
                                        color="error.main"
                                        amount={list.rejected?.total_amount}
                                        size={list.rejected?.total_size}
                                        lead={list.rejected?.total_lead}
                                    />
                                </Grid>
                                {

                                }
                            </>
                        }
                    </Grid>
                    {(user.data.role == USER_ROLES.admin || user.data.role == USER_ROLES.sub_admin || user.data.role == USER_ROLES.partner_admin || user.data.role == USER_ROLES.sales || user.data.role == USER_ROLES.tl) && <Box component={Box} mt={2}>
                        <Box p={3}>
                            <Box sx={{ display: "flex", alignItems: 'center', flexDirection: "column", flexWrap: "wrap" }} mb={4}>
                                <Box sx={{ display: "flex", flex: 1 }}>
                                    <Typography variant="h6" fontWeight={"bold"} >Conversion Ratio</Typography>
                                </Box>


                                {
                                    loading && !percentage && <CircularProgress sx={{ height: "150px", width: "150px" }} />
                                }
                                {
                                    !loading && percentage >= 0 &&
                                    <BubbleComponent percentage={percentage} height={150} width={150} />
                                    // <Box className={`${percentage > 70 ? 'green' : 'red'}`}>
                                    //     <Box className={`progress ${percentage > 70 ? 'green' : 'red'}`} >
                                    //         <Box className="inner">
                                    //             <Box className="percent"><span>{percentage}</span>%</Box>
                                    //             <Box className="water" key={percentage} sx={{ top: (100 - percentage) + "% !important" }}></Box>
                                    //             <Box className="glare"></Box>
                                    //         </Box>
                                    //     </Box>
                                    // </Box>
                                }
                            </Box>
                        </Box>
                    </Box>
                    }
                </Box>
            </DashBox>




        </>
    )
}
export default LeadIndividualSalesReportUi