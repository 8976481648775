export const PROJECT_PARENT_STATUS = {

    LEADS: 1,
    REJECTED: -1,
    PRE_APPROVALS: 2,
    INSTALLATION: 3,
    POST_INSTALLATION: 4,
    COMPLETED: 5


}

export const PROJECT_STATUS = {
    REJECTED: -1,
    NEW: 1,
    UNDER_PRE_APPROVAL: 2,
    PRE_APPROVAL_COMPLETED: 3,
    CUSTOMER_HOLD: 4,
    UNDER_INSTALLATION: 5,
    INSTALLATION_COMPLETED: 6,
    UNDER_POST_INSTALLATION: 7,
    POST_INSTALLATION_COMPLETED: 8,
    COMPLETED: 9


}
// before add and update project flags make changes in project.helper/getFlagsUnderProjectStatus
// before add and update project flags make changes in project.helper/getStatusOfProjectFlags
export const PROJECT_FLAGS = {
    DEPOSIT: 'deposit',
    GRID_PRE_APPROVAL: 'grid_pre_approval',
    SOLAR_VICTORIA_PRE_APPROVAL: 'solar_victoria_pre_approval',
    SOLAR_VICTORIA_QR: 'solar_victoria_qr',
    PURCHASE_ORDER: 'purchase_order',
    INSTALLATION: 'installation',
    STC_CLAIMED: 'stc_claimed',
    SOLAR_VICTORIA_POST_APPROVAL: 'solar_vcitoria_post_approval',
    GRID_POST_APPROVAL: 'grid_post_approval',
    CES_CERTI: 'ces_certi'
}


export const PROJECT_ACTION_TYPES = {
    STATUS_CHANGE: 1,
    FLAG_CHANGED: 2,
    OTHER: 3,
    LEAD: 4
}
export const STOCK_TYPE = {
    PANNELS: "modules",
    BATTERIES: "battries",
    INVERTERS: "inverters",
    OTHERS: "others"
}

export const ROOF_TYPES = {
    "-1": "None",
    6: "Composition / Asphalt Shingle",
    7: "Flat Concrete",
    8: "Flat Foam",
    9: "Membrane EPDM",
    10: "Membrane PVC",
    11: "Membrane TPO",
    12: "Metal Decramastic",
    13: "Metal Shingle",
    14: "Metal Standing Seam",
    15: "Metal Stone Coated",
    16: "Metal Tin",
    17: "Tar and Gravel / Bitumen",
    18: "Thatched",
    19: "Tile Clay",
    20: "Tile Concrete",
    21: "Tile Slate",
    22: "Wood/Shake Shingle",
    23: "Other",
    24: "Kliplock",




}


export const NOTES_TYPE = {
    GENERAL: 1,
    PAYMENTS_NOTES: 2,
    INSTALLATION_NOTES: 3,


}
export const PROJECT_DOCUMENT_ACTION = {
    ADD_ITEM: 'ADD_ITEM',
    SET_DATA: 'SET_DATA',
    SET_ITEM: 'SET_ITEM'
}
export const FILE_TYPES = {
    IMAGES: 1,
    PDFS: 2
}

export const DOCUMENT_TYPES = {
    CONCERN_LETTER: 'Concern Letter',
    PRE_APPROVAL_LETTER: "Pre Approval Letter",
    CES_CERTI: "CES Certi",
    ELECTRICITY_BILL: "Electricity Bill",
    METER_PHOTO: "Meter Photo",
    ROOF_PHOTO: "Roof Photo",
    OPEN_SOLAR_FILE: "Open Solar File"
}

export const PROPERTY_TYPE = {
    RESIDENTIAL: 1,
    COMMERCIAL: 2
}

export const STOREY_TYPE = {
    NONE: -1,
    SINGLE: 1,
    DOUBLE: 2,
    THREE: 3,
    MORE_THAN_3: 4

}
export const PHASE_TYPE = {
    NONE: -1,
    SINGLE: 1,
    DOUBLE: 2,
    THREE: 3,


}

export const ROOF_PITCH_TYPE = {
    TILT_0: 1,
    UNDER_5:3,
    BETWEEN_6_TO_15:4,
    UNDER_30: 2,
    BETWEEN_30_TO_40:4,
    // OVER_30: 3,
    // OVER_40: 4,
    // TILT_REQD: 5,
    NONE: 6,
}

export const SWITCH_BOARD = {
    UPGRADE: 1,
    MODIFICATION: 2,
    NEED_TO_CHECK: 3,
    NONE: 4

}
