import { memo, useEffect, useState } from "react"

import LeadCalendarUi from "./LeadCalendarUi"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../../store/actions/commonAction"
import { getLeadApi } from "../../../apis/lead.api"
import { MODAL_KEYS } from "../../../utils/constants/modal.constant"
import CustomDialog from "../../../components/layouts/common/CustomDialog"

import { updateCentralLeadFilters } from "../../../store/actions/leadFilterAction"
import moment from "moment"
import { CALENDAR_ITEMS_TYPES } from "../../../utils/constants/constants"

const LeadCalendarController = ({ }) => {

    const { leadFilters, user } = useSelector((state) => state)
    const dispatch = useDispatch()
    const title = "Lead Calendar"
    const fetchApi = getLeadApi




    const [filters, setFilters] = useState({
        ...leadFilters,
        calendarDate: moment()

    })

    useEffect(() => {
        dispatch(updateCentralLeadFilters(filters))
    }, [filters])



    // do not change 

    const [loading, setLoading] = useState(false)
    const [list, setList] = useState([])









    const fetchList = () => {

        const filtersToBePassed = {}

        for (let item in { ...filters }) {
            if (filters[item] && typeof filters[item] == "object" && filters[item]?._id) {
                filtersToBePassed[item] = filters[item]?._id
            } else if (filters[item]) {
                filtersToBePassed[item] = filters[item]
            }
        }

        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchApi({ ...filtersToBePassed, calendar: true, startDate: moment(filters.calendarDate).startOf('month').valueOf(), endDate: moment(filters.calendarDate).endOf('month').valueOf() }),
            (response) => {
                const finalList = []
               

                if (Array.isArray(response)) {
                    response.forEach((item) => {
                        finalList.push({
                            ...item,
                            title: item.contacts?.[0]?.full_name ?? item?.uid,
                            start: new Date(item[filters?.dateField ?? "createdAt"]),
                            end: new Date(item[filters?.dateField ?? "createdAt"]),
                            relatedId: item._id,
                            type: CALENDAR_ITEMS_TYPES.LEAD,
                            status: item.status,
                            flags: item.flags,


                        })
                    })
                }
                setList(finalList)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }



    useEffect(() => {
        fetchList()
    }, [filters])



    return (
        <>
            <LeadCalendarUi

                title={title}
                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}



            />

        </>
    )
}
export default LeadCalendarController