
import { useDispatch } from "react-redux"
import { openModal } from "../../../store/actions/modalAction"

import { IconButton, Tooltip } from "@mui/material"
import {  PeopleAlt } from "@mui/icons-material"

import { MODAL_KEYS } from "../../../utils/constants/modal.constant"
import ListPipelineUsersController from "../users/ListPipelineUsersController"

const PipelinUserButton = ({ pipeline_id }) => {
    const dispatch = useDispatch()

    const onAddStepButtonClick = () => {
        dispatch(openModal(<ListPipelineUsersController pipeline_id={pipeline_id}  />, "md",undefined,MODAL_KEYS.PIPELINE_USER_MODAL))
    }
    return <>
        <Tooltip arrow  title="Allocate User" >
            <IconButton size="small" onClick={onAddStepButtonClick}>
                <PeopleAlt color="warning" fontSize="inherit" />
            </IconButton>
        </Tooltip>
    </>
}
export default PipelinUserButton