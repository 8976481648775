import { Box, IconButton, Tooltip } from "@mui/material"
import CreateController from "../../CreateController"
import { AddIcCallRounded, Call, Email, FmdGood, Star, StarBorder, WbTwilight } from "@mui/icons-material"
import ProjectInstallerReassignButton from "../ProjectInstallerReassignButton"
import EmailCreateController from "../../../email/CreateController"
import { useDispatch, useSelector } from "react-redux"
import { openModal } from "../../../../store/actions/modalAction"
import { MODAL_KEYS } from "../../../../utils/constants/modal.constant"
import { moduleAccessCondition } from "../../../../utils/helpers/helper"
import MODULES from "../../../../utils/constants/module.constants"
import { dialCallAction } from "../../../../store/actions/aircallAction"
import { EMAIL_TEMPLATE_TYPE } from "../../../../utils/constants/emailTemplate.constant"
import CreateUI from "../../../scheduled-call/CreateController"
import { updateFavouriteLeadApi } from "../../../../apis/lead.api"
import { callApiAction } from "../../../../store/actions/commonAction"
import { callSnackBar } from "../../../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../../../utils/constants/constants"
import { useState } from "react"
import OpenSolarButton from "../../../lead/open-solar/OpenSolarButton"

export const AddressButton = ({ lat, lon, iconProps = {}, btnProps = {} }) => {
    if (isNaN(lat) || isNaN(lon))
        return <></>
    return <IconButton href={`https://www.google.com/maps?q=${lat},${lon}`} target="_blank" {...btnProps} >

        <FmdGood color="primary" {...iconProps} />

    </IconButton>
}
export const MailButton = ({ email, color = "primary", iconProps = {}, btnProps = {}, emailTemplateType, projectId, subjectSuffix }) => {
    const dispatch = useDispatch()
    return <Tooltip arrow title="Send Email">
        <IconButton onClick={() => { dispatch(openModal(<EmailCreateController emailTemplateType={emailTemplateType} to={email} subjectSuffix={subjectSuffix} contentId={projectId} />, "lg", undefined, MODAL_KEYS.EMAIL)) }}
            {...btnProps}
        >

            <Email color={color} {...iconProps} />

        </IconButton>
    </Tooltip>
}
export const CallButton = ({ phone, iconProps = {}, btnProps = {} }) => {

    const dispatch = useDispatch()
    const phoneins = useSelector((state) => state.aircall.phone);

    const handleDial = () => {
        dispatch(dialCallAction(("+61" + phone).replace(/\s/g, "")))
    }

    return <Tooltip arrow title="Dial Call">

        <IconButton disabled={!phone} onClick={handleDial} {...btnProps}>

            <Call color={!phone ? "grey" : "primary"} {...iconProps} />

        </IconButton>
    </Tooltip>
}
export const ScheduleCallButton = ({ projectId }) => {
    const dispatch = useDispatch()

    const onClick = () => {
        dispatch(openModal(<CreateUI projectId={projectId} />, "md", undefined, MODAL_KEYS.SCHEDULE_CALL))
    }

    return <Tooltip arrow title="Schedule Call" ><IconButton onClick={onClick} ><AddIcCallRounded color="warning" /></IconButton></Tooltip>
}


export const FavouriteButton = ({ disabled, favourite, project_id, callBack = () => { }, iconProps = {}, btnProps = {} }) => {

    const [leadFavourite, setLeadFavourite] = useState(favourite);
    const dispatch = useDispatch()
    const handleFavourite = (id) => {
        // setLoading(true);
        dispatch(
            callApiAction(
                async () => await updateFavouriteLeadApi({ id }),
                (response) => {
                    // setLoading(false);
                    setLeadFavourite(!leadFavourite);
                    callBack()
                    dispatch(
                        callSnackBar("Favourite changed.", SNACK_BAR_VARIETNS.suceess)
                    );
                },
                (err) => {
                    // setLoading(false);
                    dispatch(
                        callSnackBar("Favourite cant be changed.", SNACK_BAR_VARIETNS.error)
                    );
                }
            )
        );
    };
    return <Tooltip arrow title="Favourite Lead">

        <IconButton disabled={disabled} onClick={() => handleFavourite(project_id)} >
            {favourite == true ?
                <Star color={disabled?"grey":"primary"} /> :
                <StarBorder color={disabled?"grey":"primary"} />
            }
        </IconButton>
    </Tooltip>
}
export const OpenInOpenSolarButton = ({ open_solar_project_id }) => {

    return <Tooltip arrow title="Open in Open solar">
        <IconButton variant="contained" disableElevation size="inherit"
            onClick={() => { window.open(`https://app.opensolar.com/#/projects/${open_solar_project_id}/info`, "_blank", `resizable=yes, scrollbars=yes, titlebar=yes, width=${window.innerWidth}, height=${window.innerHeight}`) }}
        >
            <WbTwilight color="info" fontSize="inherit" />
        </IconButton>
    </Tooltip>
}
const ProjectIconButtons = ({ params, setParams }) => {
    const { user } = useSelector(state => state)

    return <Box sx={{ display: "flex", justifyContent: "space-around", flexWrap: "wrap" }} >

        <MailButton email={params.contacts?.[0]?.email} subjectSuffix={params?.uid} projectId={params?._id} emailTemplateType={EMAIL_TEMPLATE_TYPE.PROJECT} />
        <CallButton phone={params.contacts?.[0]?.phone} />
        <AddressButton lat={params.lat} lon={params?.lon} />
        


        

        <ProjectInstallerReassignButton params={params} setParams={setParams} />
    </Box >
}
export default ProjectIconButtons