// src/components/AircallWidgetUI.js

import React from "react";
import { IconButton, Box } from "@mui/material";
import { CloseRounded, InstallMobileRounded } from "@mui/icons-material";
import { SvgIcon } from '@mui/material';
import AircallIcon from '../../../assets/icons/AircallIcon.svg';
import { Typography } from "@mui/material";
import { togglePhoneVisibility } from "../../../store/actions/aircallAction";
import { useDispatch,useSelector } from "react-redux";
import { createPortal } from "react-dom";

function AircallMUIIcon(props) {
  return (
    <SvgIcon {...props}>
      <image href={AircallIcon} width="100%" height="100%" />
    </SvgIcon>
  );
}
const AirCallWidgetPortal  = ({open,onToggle})=>{

  return  createPortal(  <Box
    sx={{
      position: "fixed",
      top: 20,
      right: open ? 20 : -400,
      zIndex: 2147483647,
      width: 376,
      backgroundColor: "white",
      boxShadow: 3,
      transition: "right 0.3s ease-in-out",
    }}
  >
    {/* Top bar with Aircall text and close button */}
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "8px 16px",
        backgroundColor: "#06ab78", // Aircall green color
        color: "white",
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
        zIndex: 1, // Ensure the top bar is above the content
        position: "relative",
      }}
    >
      <AircallMUIIcon />
      <IconButton onClick={onToggle} size="small" sx={{ color: "white" }}>
        <CloseRounded />
      </IconButton>
    </Box>

    {/* Main content box */}
    <Box
          id="aircall-phone-container"
          sx={{
            height: 600,
            backgroundColor: "white",
            position: "relative",
            borderBottomLeftRadius: "4px",
            borderBottomRightRadius: "4px",
            top: 0, // Adjust the content to stay below the top bar
          }}
        >
          {/* Add your content here */}
        </Box>
  </Box>,document.body)

}
const AircallWidgetUI = () => {
  const isVisible = useSelector((state) => state.aircall.isVisible);
  const dispatch = useDispatch();


  const toggleVisibility = () => {
    dispatch(togglePhoneVisibility());
  };

  return (
    <>
      <IconButton onClick={toggleVisibility}>
        <AircallMUIIcon />
      </IconButton>
<AirCallWidgetPortal open={isVisible} onToggle={toggleVisibility}  />
    
    </>
  );
};

export default AircallWidgetUI;
