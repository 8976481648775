import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Typography,
} from "@mui/material";
import { memo } from "react";
import { useSelector } from "react-redux";

import CustomInput from "../../components/inputs/CustomInput";
import { USER_ROLES } from "../../utils/constants/constants";
import {
  findNameByRole,
  titleCase,
  toTitleCase,
} from "../../utils/helpers/helper";

import CustomDialog from "../../components/layouts/common/CustomDialog";
import { CenteredBox } from "../../components/layouts/OneViewBox";

import InstallerFields from "../installer/InstallerFields";
import getTeamApi from "../../apis/team.api";
import FileInput from "../../components/inputs/FileInput";
import CustomEditor from "../../components/inputs/CustomEditor";
import ImageComponent from "../../components/ImageComponent";
import { Link } from "react-router-dom";
import SubmitButton from "../../components/button/SubmitButton";
import { AddCircleOutline } from "@mui/icons-material";
import { getCompanyApi } from "../../apis/company.api";
import AsyncDropDown from "../../components/inputs/AsyncDropDown";
import { StyledSearchBar } from "../../components/inputs/SearchBar";

const CreateOfficesUi = ({
  isUpdate,
  callBack,
  modalKey,
  params,
  title,
  isInstaller,
  fields,
  setFields,
  loading,
  onSubmit,
  placeholder,
}) => {
  const { user } = useSelector((state) => state);

  let allowedRoles = [
    ...Object.keys(USER_ROLES).map((key) => ({
      label: titleCase(key),
      _id: USER_ROLES[key],
    })),
  ];

  if (user.data.role === USER_ROLES.partner_admin) {
    allowedRoles = [];
    Object.keys(USER_ROLES).forEach((key) => {
      if (USER_ROLES[key] != USER_ROLES.admin)
        allowedRoles.push({ label: titleCase(key), _id: USER_ROLES[key] });
    });
  }

  return (
    <>
      <CustomDialog
        id={modalKey}
        loading={loading}
        err={fields.err}
        onSubmit={onSubmit}
        // title={`${title}`}
        // closeText="Close"
        // confirmText={"Create"}
        title={`${isUpdate ? "Update" : "Add"} ${title}`}
        closeText="Close"
        confirmText={`${isUpdate ? "Update" : "Add"}`}
      >
        {loading ? (
          <CenteredBox>
            <CircularProgress />
          </CenteredBox>
        ) : (
          <>
            <Grid container spacing={2} columnSpacing={5}>
              <Grid item xs={12}>
                {/* <CustomInput
                    autoFocus={true}
                    disabled={loading}
                    value={fields.name}
                    onChange={(e) =>
                      setFields({ ...fields, err: "", company_id: e.target.value })
                    }
                    type="text"
                    label={"Company Name*"}
                  /> */}
                {/* <AsyncDropDown
                  margin={0}
                  InputComponent={(props) => (
                    <StyledSearchBar
                      placeholder="Select Company Name"
                      {...props}
                      margin="none"
                      size="small"
                    />
                  )}
                  lazyFun={async (params) => {
                    return await getCompanyApi({
                      ...params,
                      // role: USER_ROLES.sales,
                      // team: filters.team,
                    });
                  }}
                  OptionComponent={({ option, ...rest }) => {
                    return <MenuItem {...rest}>{option.name}</MenuItem>;
                  }}
                  onChange={async (changedVal) => {
                    setFields({
                      ...fields,
                      // [filtersKey]: changedVal ? changedVal._id : null,
                    });
                  }}
                  titleKey={"name"}
                  valueKey={"_id"}
                  // {...props}
                /> */}
                {
                  <CustomInput
                    disabled={loading}
                    value={fields.state}
                    onChange={(e) =>
                      setFields({
                        ...fields,
                        err: "",
                        state: e.target.value,
                      })
                    }
                    type="tel"
                    label={"Company State/Title*"}
                  />
                }

                <CustomInput
                  disabled={loading}
                  value={fields.office}
                  onChange={(e) =>
                    setFields({
                      ...fields,
                      err: "",
                      office: e.target.value,
                    })
                  }
                  type="text"
                  label={"Company Address*"}
                />
              </Grid>
            </Grid>

            {/* </Box> */}
          </>
        )}
      </CustomDialog>
    </>
  );
};
export default memo(CreateOfficesUi);
