import { Box, Button, ButtonBase, IconButton, Typography, styled, useTheme } from "@mui/material"
import { CALENDAR_ITEMS_TYPES, LOG_TYPE } from "../../../utils/constants/constants"
import { useDispatch } from "react-redux"

import { memo, useContext } from "react"
import LeadCard from "../components/LeadCard"
import moment from "moment"
import CustomDialog from "../../../components/layouts/common/CustomDialog"
import { MODAL_KEYS } from "../../../utils/constants/modal.constant"
import { openModal } from "../../../store/actions/modalAction"
import { CalendarContext } from "../../../components/layouts/Calendar"
import { CenteredBox } from "../../../components/layouts/OneViewBox"
import { Block, EmojiEvents, Pending, PendingActions } from "@mui/icons-material"
import { groupByArray } from "../../../utils/helpers/helper"
import { LEAD_STATUS } from "../../../utils/constants/lead.constant"



export const CalendarItem = styled(ButtonBase)(({ theme }) => (
    {
        width: "100%",
        boxShadow: 0,
        overflow: "hidden",
        borderRadius: 0,
        justifyContent: "flex-start",
        marginTop: 0,
        margin: 0,
        marginBottom: 1,
        padding: 0,
        paddingLeft: theme.spacing(1),
        borderRadius: "2px",
        background: "#fff",
        position: "relative",
        "*": {
            fontSize: "8px !important"
        }

    }
))
const ReportItem = styled(CenteredBox)(({ theme, color }) => (
    {
        // border:"1px solid",
        // borderColor:color

    }
))

const DetailedModal = ({ title, data }) => {
    return <CustomDialog
        title={title}

        id={MODAL_KEYS.LEAD_CALENDAR_VIEWORE}


    >
        {
            data?.sort((a, b) => moment(b?.lead_updated_date).valueOf() - moment(a?.lead_updated_date).valueOf())?.map((item) => <LeadCard
                size="small"
                projectId={item?._id}
                key={item?.uid}
                status={item?.lead_status}
                uid={item?.uid}
                name={item?.contacts?.[0]?.full_name}
            />)

        }


    </CustomDialog>
}
const LeadCalendarInnerBox = ({ data, weeklyOf, weekDay, date }) => {
    const theme = useTheme()
    const { dateObj } = useContext(CalendarContext)
    const dispatch = useDispatch()
    const onViewmOreClick = () => {
        dispatch(openModal(<DetailedModal data={data} title={moment(dateObj).set('date', date).format("DD MMM,YYYY")} />, 'xs', undefined, MODAL_KEYS.LEAD_CALENDAR_VIEWORE))
    }

    const groupedData = groupByArray(data ?? [], 'lead_status') ?? {}

    const MAX_ELE_ALLOWED = 5
    return <>

        <Box sx={{ display: "flex", height: "100%", flexDirection: "column", justifyContent: "flex-end", width: "100%" }} >


            {
                data?.sort((a, b) => moment(b?.lead_updated_date).valueOf() - moment(a?.lead_updated_date).valueOf())?.slice(0, MAX_ELE_ALLOWED)?.map((item) => <LeadCard
                    size="small"
                    projectId={item?._id}
                    key={item?.uid}
                    status={item?.lead_status}
                    uid={item?.uid}
                    name={item?.contacts?.[0]?.full_name}
                />)

            }
            {
                data?.length > MAX_ELE_ALLOWED && <ButtonBase onClick={onViewmOreClick}>
                    <Typography color="primary" variant="caption" sx={{ textDecoration: "underline" }} >
                        +{data?.length - MAX_ELE_ALLOWED} more
                    </Typography>
                </ButtonBase>
            }

            {
                <Box display="flex" borderTop={1} borderColor="divider"  >

                    <ReportItem display="flex" flex={1} p={1} color={theme.palette.warning.main} >
                        <Typography variant="caption" lineHeight="100%" >
                            <Typography display="inline" lineHeight="100%"><PendingActions fontSize="inherit" /></Typography>  {(groupedData[LEAD_STATUS.UNHANDLED]?.length ?? 0) + (groupedData[LEAD_STATUS.WIP]?.length ?? 0) + (groupedData[LEAD_STATUS.HOLD]?.length ?? 0)}
                        </Typography>
                    </ReportItem>
                    <ReportItem display="flex" flex={1} p={1} color={theme.palette.success.main} >
                        <Typography variant="caption" lineHeight="100%" >
                            <Typography display="inline" lineHeight="100%"><EmojiEvents fontSize="inherit" /></Typography>  {(groupedData[LEAD_STATUS.WON]?.length ?? 0)}
                        </Typography>
                    </ReportItem>
                    <ReportItem display="flex" flex={1} p={1} color={theme.palette.error.main} >
                        <Typography variant="caption" lineHeight="100%" >
                            <Typography display="inline" lineHeight="100%"><Block fontSize="inherit" /></Typography>   {(groupedData[LEAD_STATUS.REJECTED]?.length ?? 0) + (groupedData[LEAD_STATUS.JUNK]?.length ?? 0)}
                        </Typography>
                    </ReportItem>
                </Box>
            }


        </Box>

    </>
}
export default memo(LeadCalendarInnerBox)