import { memo, useCallback, useEffect, useState } from "react";
import ListUi from "./ListUi";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { closeModal, openModal } from "../../store/actions/modalAction";
import {
  findNameByRole,
  moduleAccessCondition,
  spectatorID,
} from "../../utils/helpers/helper";
import {
  Box,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useMemo } from "react";
import CreateController from "./CreateController";
import { Delete, Edit, Info, Preview, Undo } from "@mui/icons-material";
import MessageDilog from "../../components/MessageDilog";
import MODULES from "../../utils/constants/module.constants";
import {
  getCompanyApi,
  updateCompanyDeleteStatus,
} from "../../apis/company.api";
import ImageComponent from "../../components/ImageComponent";
import CreateOfficesController from "./CreateOfficesController";
import CompanyDetailsController from "./CompanyDetailsController";
// import ImageComponent from "../../components/inputs/ImageComponent"
// import ImageComponent from "../../components/inputs/ImageComponent"

const ActionComponent = ({
  params,
  setParams,
  deleteApi,
  fetchList,
  deleted,
}) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state);

  const onEdit = () => {
    dispatch(
      openModal(
        <CreateController
          id={params._id}
          callBack={(response, updatedData) => {
            setParams({ ...params, ...updatedData });
          }}
        />,
        "sm",
        undefined,
        "company-create"
      )
    );
  };

  const onView = () => {
    dispatch(
      openModal(
        <CompanyDetailsController companyId={params._id} modal />,
        "lg",
        undefined,
        "company-details"
      )
    );
  };
  const onSpectatingStart = () => {
    spectatorID.set(params?._id);
    window.location.reload();
  };
  const deleteFun = async () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await deleteApi({ id: params._id, deleted: !deleted }),
        (response) => {
          setParams({});
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
    dispatch(closeModal());
  };

  const onDelete = () => {
    dispatch(
      openModal(
        <MessageDilog
          onSubmit={deleteFun}
          title="Alert!"
          message={`Are you sure to delete "${params.name || params.title}" ?`}
        />,
        "sm"
      )
    );
  };

  const onUndo = () => {
    dispatch(
      openModal(
        <MessageDilog
          onSubmit={deleteFun}
          title="Alert!"
          message={`Are you sure to undo delete for "${
            params.name || params.title
          }" ?`}
        />,
        "sm"
      )
    );
  };
  if (
    deleted &&
    moduleAccessCondition(user, [MODULES.COMPANY_MODULE_DELETE], [])
  )
    return (
      <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
        {" "}
        <IconButton onClick={onUndo}>
          <Undo color="error" />
        </IconButton>
      </Box>
    );
  return (
    <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
      {
        <IconButton disabled={loading} size="inherit" onClick={onView}>
          <Info color="info" fontSize="inherit" />
        </IconButton>
      }
      <Tooltip title="Sign In as Company Admin">
        <IconButton onClick={onSpectatingStart}>
          <Preview color="warning" />
        </IconButton>
      </Tooltip>
      {moduleAccessCondition(user, [MODULES.COMPANY_MODULE_EDIT], []) && (
        <IconButton disabled={loading} size="inherit" onClick={onEdit}>
          <Edit color="info" fontSize="inherit" />
        </IconButton>
      )}

      {moduleAccessCondition(user, [MODULES.COMPANY_MODULE_DELETE], []) && (
        <>
          {loading && <CircularProgress color="primary" fontSize="inherit" />}
          {!loading && (
            <IconButton disabled={loading} size="inherit" onClick={onDelete}>
              <Delete color="error" fontSize="inherit" />
            </IconButton>
          )}
        </>
      )}
    </Box>
  );
};

const ListController = ({ callBack = () => {} }) => {
  const dispatch = useDispatch();

  const title = "Companies";
  const fetchApi = getCompanyApi;
  const deleteApi = updateCompanyDeleteStatus;
  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    sort: "name",
    sortDirection: 1,
    deleted: null,
  });

  const columns = useMemo(() => {
    const arr = [
      {
        id: 4,
        fieldName: "logo",
        label: "Logo",
        sort: true,
        align: "left",
        renderValue: (params) =>
          params.logo ? (
            <Box sx={{ display: "flex" }}>
              <Box>
                <ImageComponent
                  cachePermanent={true}
                  src={params.logo}
                  sx={{ height: "40px", width: "87px", objectFit: "contain" }}
                />
              </Box>
            </Box>
          ) : (
            "NA"
          ),
      },

      { id: 1, fieldName: "name", label: "Name", align: "left", sort: true },
      {
        id: 2,
        fieldName: "short_name",
        label: "Short Name",
        align: "left",
        sort: true,
      },
    ];

    arr.push({
      id: 6,
      fieldName: "deletedaction",
      label: "Action",

      align: "right",
      renderValue: (params, setParams) => (
        <ActionComponent
          key={Math.random()}
          deleted={params.deleted}
          deleteApi={deleteApi}
          fetchList={fetchList}
          params={{ ...params, deleted: true }}
          setParams={setParams}
        />
      ),
    });

    return arr;
  }, [dispatch, filters]);

  // do not change

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState({});

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchApi({ ...filters }),
        (response) => {
          setList(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };
  const onCreateBtnClick = () => {
    dispatch(
      openModal(
        <CreateController
          callBack={async () => {
            fetchList();
          }}
        />,
        "lg",
        undefined,
        "company-create"
      )
    );
  };

  useEffect(() => {
    fetchList();
  }, [filters]);

  return (
    <>
      <ListUi
        title={title}
        onCreateBtnClick={onCreateBtnClick}
        filters={filters}
        setFilters={setFilters}
        loading={loading}
        list={list}
        columns={columns}
      />
    </>
  );
};
export default ListController;
