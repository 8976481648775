import { memo, useEffect, useMemo, useState } from "react"
import useValidate from "../../../store/hooks/useValidator"
import AddTagUi from "./AddTagUi"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../../store/actions/commonAction"
import { closeModal } from "../../../store/actions/modalAction"
import { addTagFromPreDefinedTagsCategoryApi } from "../../../apis/predefinedTags.api"

const AddTagController = ({ callBack, category_id }) => {
    const validate = useValidate()
    const dispatch = useDispatch()    

    const title = "Tag"
    const createApi = addTagFromPreDefinedTagsCategoryApi

    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',
        category_id,
        tag: '',
    })
 


    const validationSchemaForCreate = useMemo(() => ([

        {
            required: true,
            value: fields.tag,
            field: 'Tag',
        }

    ]), [fields])

   


    const createFunction = async () => {
        const validationResponse = validate(validationSchemaForCreate)

        if (validationResponse === true) {


            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await createApi(fields),
                    async (response) => {

                        await callBack(fields.tag)
                        setLoading(false)
                        dispatch(closeModal())
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }



    const onSubmit = async (e) => {
        e.preventDefault()
      
            createFunction()

    }

  


    return <AddTagUi title={title}  loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
}
export default memo(AddTagController)