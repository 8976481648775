import { USER_ROLES } from "../utils/constants/constants";
import MODULES from "../utils/constants/module.constants";

const modulesConfig = [
  {
    title: "Companies",
    identifier: "COMPANY",
    children: [
      {
        title: "View",
        recommended: [USER_ROLES.director],
        not_allowed: Object.values(USER_ROLES).filter((item) => item != USER_ROLES.director),
        dependent_on: [],
        value: MODULES.COMPANY_MODULE_LIST,
      },
      {
        title: "Create",
        recommended: [USER_ROLES.director],
        not_allowed: Object.values(USER_ROLES).filter((item) => item != USER_ROLES.director),
        dependent_on: [],
        value: MODULES.COMPANY_MODULE_CREATE,
      },
      {
        title: "Update",
        recommended: [USER_ROLES.director],
        not_allowed: Object.values(USER_ROLES).filter((item) => item != USER_ROLES.director),
        dependent_on: [],
        value: MODULES.COMPANY_MODULE_EDIT,
      },
      {
        title: "Delete",
        recommended: [USER_ROLES.director],
        not_allowed: Object.values(USER_ROLES).filter((item) => item != USER_ROLES.director),
        dependent_on: [],
        value: MODULES.COMPANY_MODULE_DELETE,
      }
    ]

  },
  {
    title: "Company Profile",
    identifier: "COMPANY_PROFILE",
    children: [
      {
        title: "View",
        recommended: "*",
        not_allowed: [USER_ROLES.director],
        dependent_on: [],
        value: MODULES.COMPANY_PROFILE_VIEW,
      },
      {
        title: "Edit",
        recommended: [USER_ROLES.admin],
        not_allowed: [USER_ROLES.director],
        dependent_on: [],
        value: MODULES.COMPANY_PROFILE_EDIT,
      }
    ]

  },
  {
    title: "Reports",
    identifier: "REPORTS",
    children: [
      {
        title: "LEAD",
        recommended: [USER_ROLES.admin],
        not_allowed: [USER_ROLES.director],
        dependent_on: [],
        value: MODULES.LEAD_REPORTS,
      },
      {
        title: "Finance",
        recommended: [USER_ROLES.admin],
        not_allowed: [USER_ROLES.director],
        dependent_on: [],
        value: MODULES.FINANCE_REPORTS,
      }
    ]

  },
  {
    title: "Task Calendar",
    recommended: "*",
    not_allowed: [USER_ROLES.director],
    dependent_on: [],
    value: MODULES.TASK_MANAGEMENT_MAIN,

  },

  {
    title: "Chats",
    identifier: "REPORTS",
    children: [
      {
        title: "View",
        recommended: "*",
        not_allowed: [USER_ROLES.director],
        dependent_on: [],
        value: MODULES.CHATS_SECTION_MAIN,
      },
      {
        title: "Chats Admin",
        recommended: [USER_ROLES.admin],
        not_allowed: [USER_ROLES.director],
        dependent_on: [MODULES.CHATS_SECTION_MAIN],
        value: MODULES.CHATS_ADMIN,
      }
    ]

  },
  {
    title: "Teams",

    children: [
      {
        title: "View",
        recommended: [
          USER_ROLES.admin,
          USER_ROLES.partner_admin
        ],
        not_allowed: [USER_ROLES.director, USER_ROLES.sales, USER_ROLES.director],
        dependent_on: [],
        value: MODULES.VIEW_TEAMS,
      },
      {
        title: "Create",
        recommended: [
          USER_ROLES.admin,
          USER_ROLES.partner_admin
        ],
        not_allowed: [USER_ROLES.director, USER_ROLES.sales, USER_ROLES.director],
        dependent_on: [MODULES.VIEW_TEAMS],
        value: MODULES.CREATE_TEAMS,
      },
      {
        title: "Update",
        recommended: [
          USER_ROLES.admin,
          USER_ROLES.partner_admin,

        ],
        not_allowed: [USER_ROLES.director, USER_ROLES.sales, USER_ROLES.director],
        dependent_on: [MODULES.VIEW_TEAMS],
        value: MODULES.UPDATE_TEAMS,
      },

    ],
  },
  {
    title: "Users",

    children: [
      {
        title: "View",
        recommended: [
          USER_ROLES.admin,
          USER_ROLES.partner_admin,
          USER_ROLES.director,
        ],
        not_allowed: [USER_ROLES.sales],
        dependent_on: [],
        value: MODULES.VIEW_USERS,
      },
      {
        title: "Create",
        recommended: [
          USER_ROLES.admin,
          USER_ROLES.partner_admin,
          USER_ROLES.director,
        ],
        not_allowed: [USER_ROLES.sales],
        dependent_on: [MODULES.VIEW_USERS],
        value: MODULES.CREATE_USERS,
      },
      {
        title: "Update",
        recommended: [
          USER_ROLES.admin,
          USER_ROLES.partner_admin,
          USER_ROLES.director,
        ],
        not_allowed: [USER_ROLES.sales],
        dependent_on: [MODULES.VIEW_USERS],
        value: MODULES.UPDATE_USERS,
      },
      {
        title: "Delete",
        recommended: [USER_ROLES.admin, USER_ROLES.director],
        not_allowed: [USER_ROLES.sales],
        dependent_on: [MODULES.VIEW_USERS],
        value: MODULES.DELETE_USERS,
      },

      {
        title: "Edit Access",
        recommended: [
          USER_ROLES.admin,
          USER_ROLES.director,
          USER_ROLES.director,
        ],
        not_allowed: [USER_ROLES.sales, USER_ROLES.tl],
        dependent_on: [MODULES.VIEW_USERS],
        value: MODULES.UPDATE_MODULE_ACCESS,
      }

    ],
  },


  {
    title: "Installer",

    children: [
      {
        title: "View",
        recommended: [
          USER_ROLES.admin,
          USER_ROLES.partner_admin,
          USER_ROLES.director,
        ],
        not_allowed: [USER_ROLES.sales, USER_ROLES.tl],
        dependent_on: [],
        value: MODULES.VIEW_INSTALLER,
      },
      {
        title: "Create",
        recommended: [
          USER_ROLES.admin,
          USER_ROLES.partner_admin,
          USER_ROLES.director,
        ],
        not_allowed: [USER_ROLES.sales, USER_ROLES.tl],
        dependent_on: [MODULES.VIEW_INSTALLER],
        value: MODULES.CREATE_INSTALLER,
      },
      {
        title: "Update ",
        recommended: [
          USER_ROLES.admin,
          USER_ROLES.partner_admin,
          USER_ROLES.director,
        ],
        not_allowed: [USER_ROLES.sales, USER_ROLES.tl],
        dependent_on: [MODULES.VIEW_INSTALLER],
        value: MODULES.UPDATE_INSTALLER,
      },
      {
        title: "Delete ",
        recommended: [USER_ROLES.admin, USER_ROLES.director],
        not_allowed: [USER_ROLES.sales, USER_ROLES.tl],
        dependent_on: [MODULES.VIEW_INSTALLER],
        value: MODULES.DELETE_INSTALLER,
      },

      {
        title: "Agreement",
        children: [
          {
            title: "View",
            recommended: [
              USER_ROLES.admin,
              USER_ROLES.partner_admin,

            ],
            not_allowed: [USER_ROLES.sales, USER_ROLES.director, USER_ROLES.tl],
            dependent_on: [MODULES.VIEW_INSTALLER],
            value: MODULES.VIEW_INSTALLER_AGGREMENT,
          },
          {
            title: "Create",
            recommended: [
              USER_ROLES.admin,
              USER_ROLES.partner_admin,
            ],
            not_allowed: [USER_ROLES.sales, USER_ROLES.director, USER_ROLES.tl],
            dependent_on: [MODULES.VIEW_INSTALLER_AGGREMENT],
            value: MODULES.CREATE_INSTALLER_AGGREMENT,
          },
          {
            title: "Update ",
            recommended: [
              USER_ROLES.admin,
              USER_ROLES.partner_admin,

            ],
            not_allowed: [USER_ROLES.sales, USER_ROLES.director, USER_ROLES.tl],
            dependent_on: [MODULES.VIEW_INSTALLER_AGGREMENT],
            value: MODULES.UPDATE_INSTALLER_AGGREMENT,
          },
          {
            title: "Delete ",
            recommended: [USER_ROLES.admin],
            not_allowed: [USER_ROLES.sales, USER_ROLES.director, USER_ROLES.tl],
            dependent_on: [MODULES.VIEW_INSTALLER_AGGREMENT],
            value: MODULES.DELETE_INSTALLER_AGGREMENT,
          },
        ]

      }

    ],
  },
  {
    title: "Supplier",

    children: [
      {
        title: "View",
        recommended: [
          USER_ROLES.admin,
          USER_ROLES.partner_admin,
          USER_ROLES.director,
        ],
        not_allowed: [USER_ROLES.sales],
        dependent_on: [],
        value: MODULES.VIEW_SUPPLIER,
      },
      {
        title: "Create",
        recommended: [
          USER_ROLES.admin,
          USER_ROLES.partner_admin,
          USER_ROLES.director,
        ],
        not_allowed: [USER_ROLES.sales],
        dependent_on: [MODULES.VIEW_SUPPLIER],
        value: MODULES.CREATE_SUPPLIER,
      },
      {
        title: "Update ",
        recommended: [
          USER_ROLES.admin,
          USER_ROLES.partner_admin,
          USER_ROLES.director,
        ],
        not_allowed: [USER_ROLES.sales],
        dependent_on: [MODULES.VIEW_SUPPLIER],
        value: MODULES.UPDATE_SUPPLIER,
      },
      {
        title: "Delete",
        recommended: [USER_ROLES.admin, USER_ROLES.director],
        not_allowed: [USER_ROLES.sales],
        dependent_on: [MODULES.VIEW_SUPPLIER],
        value: MODULES.DELETE_SUPPLIER,
      }



    ],
  },



  {
    title: "Leads",

    children: [
      {
        title: "Admin Rights",
        recommended: [
          USER_ROLES.partner_admin,
          USER_ROLES.admin
        ],
        not_allowed: [USER_ROLES.director],
        dependent_on: [MODULES.VIEW_LEADS, MODULES.CREATE_LEADS, MODULES.UPDATE_LEADS],
        value: MODULES.LEAD_ADMIN,
      },
      {
        title: "View",
        recommended: [
          USER_ROLES.sales,
          USER_ROLES.tl,
          USER_ROLES.partner_admin,
          USER_ROLES.admin
        ],
        not_allowed: [USER_ROLES.director],
        dependent_on: [],
        value: MODULES.VIEW_LEADS,
      },
      {
        title: "Create",
        recommended: [
          USER_ROLES.sales,
          USER_ROLES.tl,
          USER_ROLES.partner_admin,
          USER_ROLES.admin
        ],
        not_allowed: [USER_ROLES.director],
        dependent_on: [],
        value: MODULES.CREATE_LEADS,
      },
      {
        title: "Update",
        recommended: [
          USER_ROLES.sales,
          USER_ROLES.tl,
          USER_ROLES.partner_admin,
          USER_ROLES.admin
        ],
        not_allowed: [USER_ROLES.director],
        dependent_on: [],
        value: MODULES.UPDATE_LEADS,
      },
      {
        title: "Delete",
        recommended: [
          USER_ROLES.admin
        ],
        not_allowed: [USER_ROLES.director],
        dependent_on: [],
        value: MODULES.DELETE_LEADS,
      },
      {
        title: "Features",

        children: [
          {
            title: "Export Data",
            recommended: [

              USER_ROLES.partner_admin,
              USER_ROLES.admin
            ],
            not_allowed: [USER_ROLES.director],
            dependent_on: [],
            value: MODULES.LEAD_DATA_EXPORT,
          },
          {
            title: "Call Schedule",
            recommended: "*",
            not_allowed: [USER_ROLES.director],
            dependent_on: [],
            value: MODULES.SCHEDULED_CALL,
          },
          {
            title: "Manual Sold",
            recommended: [
              USER_ROLES.admin,
              USER_ROLES.partner_admin,
              USER_ROLES.sub_admin,
            ],
            not_allowed: [USER_ROLES.director],
            dependent_on: [],
            value: MODULES.UPDATE_LEADS,
          },
          {
            title: "Notes",

            children: [
              {
                title: "View",
                recommended: "*",
                not_allowed: [USER_ROLES.director],
                dependent_on: [],
                value: MODULES.NOTES_VIEW,
              },
              {
                title: "Create",
                recommended: "*",
                not_allowed: [USER_ROLES.director],
                dependent_on: [],
                value: MODULES.NOTES_CREATE,
              }


            ]
          },
          {
            title: "Documents",

            children: [
              {
                title: "View",
                recommended: "*",
                not_allowed: [USER_ROLES.director],
                dependent_on: [],
                value: MODULES.VIEW_PROJECT_DOCUMENTS,
              },
              {
                title: "Create",
                recommended: "*",
                not_allowed: [USER_ROLES.director],
                dependent_on: [],
                value: MODULES.CREATE_PROJECT_DOCUMENTS,
              },
              {
                title: "Upload",
                recommended: "*",
                not_allowed: [USER_ROLES.director],
                dependent_on: [],
                value: MODULES.UPLOAD_PROJECT_DOCUMENTS,
              }


            ]
          },
          {
            title: "Activity Logs",

            children: [
              {
                title: "View",
                recommended: "*",
                not_allowed: [USER_ROLES.director],
                dependent_on: [],
                value: MODULES.ACTIVITY_LOGS_VIEW,
              },
              {
                title: "Create",
                recommended: "*",
                not_allowed: [USER_ROLES.director],
                dependent_on: [],
                value: MODULES.ACTIVITY_LOGS_CREATE,
              }


            ]
          },
          {
            title: "History Logs",

            children: [
              {
                title: "View",
                recommended: "*",
                not_allowed: [USER_ROLES.director],
                dependent_on: [],
                value: MODULES.HISTORY_LOGS,
              }



            ]
          },
        ]
      }
    ]
  },

  {
    title: "Projects",

    children: [
      {
        title: "Admin",
        recommended: [USER_ROLES.admin, USER_ROLES.partner_admin],
        not_allowed: [USER_ROLES.director],
        dependent_on: [],
        value: MODULES.PROJECT_ADMIN,
      },
      {
        title: "View",
        recommended: "*",
        not_allowed: [USER_ROLES.director],
        dependent_on: [],
        value: MODULES.VIEW_PROJECTS,
      },
      {
        title: "Edit",
        recommended: "*",
        not_allowed: [USER_ROLES.director],
        dependent_on: [],
        value: MODULES.UPDATE_PROJECTS,
      },
      {
        title: "Delete",
        recommended: [USER_ROLES.admin],
        not_allowed: [USER_ROLES.director],
        dependent_on: [],
        value: MODULES.DELETE_LEADS,
      },
     {
      title:"Process",
      children:[
        {
          title: "Pre Installation",
          recommended: "*",
          not_allowed: [USER_ROLES.director],
          dependent_on: [],
          value: MODULES.PROJECT_PREINSTALLATION_ACTIONS,
        },
        {
          title: "Installation",
          recommended: "*",
          not_allowed: [USER_ROLES.director],
          dependent_on: [],
          value: MODULES.PROJECT_INSTALLATION_ACTIONS,
        },
        {
          title: "Post Installation",
          recommended: "*",
          not_allowed: [USER_ROLES.director],
          dependent_on: [],
          value: MODULES.PROJECT_POSTINSTALLATION_ACTIONS,
        },
      ]
     },
      {
        title: "Features",
        children: [
          {
            title: "Export CSV",
            recommended: [USER_ROLES.admin, USER_ROLES.partner_admin],
            not_allowed: [USER_ROLES.director],
            dependent_on: [],
            value: MODULES.PROJECT_DATA_EXPORT,
          },
          {
            title: "Installer Approval",
            recommended: [USER_ROLES.admin, USER_ROLES.partner_admin,USER_ROLES.sub_admin],
            not_allowed: [USER_ROLES.director],
            dependent_on: [],
            value: MODULES.INSTALLER_APPROVALS,
          },
          {
            title: "Notes",

            children: [
              {
                title: "View",
                recommended: "*",
                not_allowed: [USER_ROLES.director],
                dependent_on: [],
                value: MODULES.NOTES_VIEW,
              },
              {
                title: "Create",
                recommended: "*",
                not_allowed: [USER_ROLES.director],
                dependent_on: [],
                value: MODULES.NOTES_CREATE,
              }


            ]
          },
          {
            title: "Documents",

            children: [
              {
                title: "View",
                recommended: "*",
                not_allowed: [USER_ROLES.director],
                dependent_on: [],
                value: MODULES.VIEW_PROJECT_DOCUMENTS,
              },
              {
                title: "Create",
                recommended: "*",
                not_allowed: [USER_ROLES.director],
                dependent_on: [],
                value: MODULES.CREATE_PROJECT_DOCUMENTS,
              },
              {
                title: "Upload",
                recommended: "*",
                not_allowed: [USER_ROLES.director],
                dependent_on: [],
                value: MODULES.UPLOAD_PROJECT_DOCUMENTS,
              }


            ]
          },
          {
            title: "Activity Logs",

            children: [
              {
                title: "View",
                recommended: "*",
                not_allowed: [USER_ROLES.director],
                dependent_on: [],
                value: MODULES.ACTIVITY_LOGS_VIEW,
              },
              {
                title: "Create",
                recommended: "*",
                not_allowed: [USER_ROLES.director],
                dependent_on: [],
                value: MODULES.ACTIVITY_LOGS_CREATE,
              }


            ]
          },
          {
            title: "History Logs",

            children: [
              {
                title: "View",
                recommended: "*",
                not_allowed: [USER_ROLES.director],
                dependent_on: [],
                value: MODULES.HISTORY_LOGS,
              }



            ]
          }
        ],
      },
      
    ],
  },
  {
    title: "Complains",
    children: [
      {
        title: "View",
        recommended: "*",
        not_allowed: [USER_ROLES.director],
        dependent_on: [],
        value: MODULES.VIEW_COMPLAINS,
      },
      {
        title: "Create",
        recommended: "*",
        not_allowed: [USER_ROLES.director],
        dependent_on: [],
        value: MODULES.CREATE_COMPLAINS,
      },
      {
        title: "Update",
        recommended: "*",
        not_allowed: [USER_ROLES.director],
        dependent_on: [],
        value: MODULES.EDIT_COMPLAINS,
      },
      // {
      //   title: "Delete",
      //   recommended: [
      //     USER_ROLES.admin
      //   ],
      //   not_allowed: [USER_ROLES.director],
      //   dependent_on: [],
      //   value: MODULES.DELETE_COMPLAINS,
      // }

    ]
  },


  {
    title: "Admin Workspace",
    children: [
      {
        title: "Lead Forms",
        recommended: [USER_ROLES.admin, USER_ROLES.partner_admin],
        not_allowed: [USER_ROLES.director],
        dependent_on: [],
        value: MODULES.LEAD_FORMS,
      },
      {
        title: "Announcement",
        recommended: [USER_ROLES.admin, USER_ROLES.partner_admin],
        not_allowed: [USER_ROLES.director],
        dependent_on: [],
        value: MODULES.ANNOUNCEMENT_MODULE,
      },
      {
        title: "Blog",
        recommended: [USER_ROLES.admin, USER_ROLES.partner_admin],
        not_allowed: [USER_ROLES.director],
        dependent_on: [],
        value: MODULES.BLOG_MODULE,
      },
      {
        title: "Email Template",
        recommended: [USER_ROLES.admin, USER_ROLES.partner_admin],
        not_allowed: [USER_ROLES.director],
        dependent_on: [],
        value: MODULES.EMAIL_TEMPLATES,
      },
      {
        title: "Holidays",
        recommended: [USER_ROLES.admin, USER_ROLES.partner_admin],
        not_allowed: [USER_ROLES.director],
        dependent_on: [],
        value: MODULES.HOLIDAY_MODULE,
      },
      {
        title: "Customer",
        recommended: [USER_ROLES.admin, USER_ROLES.partner_admin],
        not_allowed: [USER_ROLES.director],
        dependent_on: [],
        value: MODULES.CUSTOMER_MODULE,
      },


    ]
  },
  {
    title: "Payment Verification",
    recommended:[USER_ROLES.admin],
    not_allowed: [USER_ROLES.director],
    dependent_on: [],
    value: MODULES.PAYMENTS_VERIFICATION,
  },
  {
    title: "Invoices",
    identifier: "INVOICE",
    children: [
      {
        title: "Create",
        recommended: "*",
        not_allowed: [USER_ROLES.director],
        dependent_on: [],
        value: MODULES.CREATE_INVOICE,
      },
      {
        title: "Update",
        recommended: [
          USER_ROLES.admin,
          USER_ROLES.partner_admin,
          USER_ROLES.sub_admin,
        ],
        not_allowed: [USER_ROLES.director],
        dependent_on: [],
        value: MODULES.EDIT_INVOICE,
      },
      {
        title: "Delete",
        recommended: [USER_ROLES.admin],
        not_allowed: [USER_ROLES.director],
        dependent_on: [],
        value: MODULES.DELETE_INVOICE,
      },

      {
        title: "Features",
        children: [
          {
            title: "Pay Invoice",
            recommended: "*",
            not_allowed: [USER_ROLES.director],
            dependent_on: [],
            value: MODULES.PAY_INVOICE,
          },
          {
            title: "Export CSV",
            recommended: [USER_ROLES.admin, USER_ROLES.partner_admin],
            not_allowed: [USER_ROLES.director, USER_ROLES.sales, USER_ROLES.tl],
            dependent_on: [],
            value: MODULES.EXPORT_CSV_INVOICE,
          },
        ]
      },


      {
        title: "Bulk Invoices",
        recommended: [
          USER_ROLES.admin,
          USER_ROLES.partner_admin,
          USER_ROLES.sub_admin,
        ],
        not_allowed: [USER_ROLES.director, USER_ROLES.sales, USER_ROLES.tl],
        dependent_on: [],
        value: MODULES.BULK_INVOICE,
      },



      {
        title: "Invoice Types",
        identifier: "LIST",
        children: [
          {
            title: "Deposit",
            recommended: "*",
            not_allowed: [USER_ROLES.director],
            dependent_on: [],
            value: MODULES.DEPOSIT_INVOICE,
          },
          {
            title: "Customer",
            recommended: "*",
            not_allowed: [USER_ROLES.director],
            dependent_on: [],
            value: MODULES.CUSTOMER_INVOICE,
          },
          {
            title: "Incentives",
            recommended: "*",
            not_allowed: [USER_ROLES.director],
            dependent_on: [],
            value: MODULES.INCENTIVES_INVOICE,
          },

          {
            title: "Extraa Charge",
            recommended: "*",
            not_allowed: [USER_ROLES.director],
            dependent_on: [],
            value: MODULES.EXTRAA_CHARGE_INVOICE,
          },
          {
            title: "General",
            recommended: "*",
            not_allowed: [USER_ROLES.director],
            dependent_on: [],
            value: MODULES.GENERAL_INVOICE,
          },

          {
            title: "Electrician",
            recommended: [
              USER_ROLES.admin,
              USER_ROLES.partner_admin,
              USER_ROLES.sub_admin,
            ],
            not_allowed: [USER_ROLES.director],
            dependent_on: [],
            value: MODULES.ELEC_INVOICE,
          },


          {
            title: "Supplier",
            recommended: [USER_ROLES.admin, USER_ROLES.partner_admin],
            not_allowed: [USER_ROLES.director],
            dependent_on: [],
            value: MODULES.SUPPLIER_INVOICE,
          },
          {
            title: "ADF",
            recommended: [USER_ROLES.admin, USER_ROLES.partner_admin],
            not_allowed: [USER_ROLES.director],
            dependent_on: [],
            value: MODULES.ADF_INVOICE,
          },
          {
            title: "Loss",
            recommended: "*",
            not_allowed: [USER_ROLES.director],
            dependent_on: [],
            value: MODULES.LOSS_INVOICE,
          },
          {
            title: "Refund",
            recommended: "*",
            not_allowed: [USER_ROLES.director],
            dependent_on: [],
            value: MODULES.REFUND_INVOICE,
          },
        ],
      },
    ],
  },
  {
    title: "Settings",
    children: [
      {
        title: "Lead Source",


        children: [
          {
            title: "View",
            recommended: [USER_ROLES.admin, USER_ROLES.partner_admin],
            not_allowed: [USER_ROLES.director],
            dependent_on: [],
            value: MODULES.VIEW_LEAD_SOURCE,
          },
          {
            title: "Create",
            recommended: [USER_ROLES.admin, USER_ROLES.partner_admin],
            not_allowed: [USER_ROLES.director],
            dependent_on: [],
            value: MODULES.CREATE_LEAD_SOURCE,
          },
          {
            title: "Update",
            recommended: [USER_ROLES.admin, USER_ROLES.partner_admin],
            not_allowed: [USER_ROLES.director],
            dependent_on: [],
            value: MODULES.UPDATE_LEAD_SOURCE,
          },
          {
            title: "Delete",
            recommended: [USER_ROLES.admin, USER_ROLES.partner_admin],
            not_allowed: [USER_ROLES.director],
            dependent_on: [],
            value: MODULES.DELETE_LEAD_SOURCE,
          }


        ]

      },
      {
        title: "Note Type",

        children: [
          {
            title: "View",
            recommended: [USER_ROLES.admin],
            not_allowed: [USER_ROLES.director],
            dependent_on: [],
            value: MODULES.NOTES_TYPE_VIEW,
          },
          {
            title: "Create",
            recommended: [USER_ROLES.admin],
            not_allowed: [USER_ROLES.director],
            dependent_on: [],
            value: MODULES.NOTES_TYPE_CREATE,
          },

          {
            title: "Delete",
            recommended: [USER_ROLES.admin],
            not_allowed: [USER_ROLES.director],
            dependent_on: [],
            value: MODULES.NOTES_TYPE_DELETE,
          }


        ]

      },
      {
        title: "Activities",
        recommended: [USER_ROLES.admin, USER_ROLES.partner_admin],
        not_allowed: [USER_ROLES.director],
        dependent_on: [],
        value: MODULES.ACTIVITY_MODULE,
      },
      {
        title: "Predefined Tags",
        recommended: [USER_ROLES.admin, USER_ROLES.partner_admin],
        not_allowed: [USER_ROLES.director],
        dependent_on: [],
        value: MODULES.PREDEFINED_TAGS,
      },
      {
        title: "Sales Pipeline",
        recommended: [USER_ROLES.admin, USER_ROLES.partner_admin],
        not_allowed: [USER_ROLES.director],
        dependent_on: [],
        value: MODULES.PIPELINE_MODULE,
      },
      {
        title: "Rejection Reasons",
        recommended: [USER_ROLES.admin, USER_ROLES.partner_admin],
        not_allowed: [USER_ROLES.director],
        dependent_on: [],
        value: MODULES.REJECTION_REASON_MODULE,
      },
      {
        title: "Integration",
        recommended: [USER_ROLES.admin],
        not_allowed: [USER_ROLES.director],
        dependent_on: [],
        value: MODULES.INTEGRATION_MODULE,
      },
      {
        title: "Complain Categories",
        recommended: [USER_ROLES.admin],
        not_allowed: [USER_ROLES.director],
        dependent_on: [],
        value: MODULES.COMPLAIN_CATEGORIES_MODULE,
      },


    ]
  },
  {
    title: "Product",
    identifier: "PRODUCT",

    children: [
      {
        title: "Product Category",


        children: [
          {
            title: "VIEW",
            recommended: [USER_ROLES.director],
            not_allowed: Object.values(USER_ROLES).filter(item => item != USER_ROLES.director),
            dependent_on: [],
            value: MODULES.VIEW_PRODUCT_CATEGORY,
          },
          {
            title: "CREATE",
            recommended: [USER_ROLES.director],
            not_allowed: Object.values(USER_ROLES).filter(item => item != USER_ROLES.director),
            dependent_on: [],
            value: MODULES.CREATE_PRODUCT_CATEGORY,
          }, {
            title: "UPDATE",
            recommended: [USER_ROLES.director],
            not_allowed: Object.values(USER_ROLES).filter(item => item != USER_ROLES.director),
            dependent_on: [],
            value: MODULES.UPDATE_PRODUCT_CATEGORY,
          },
          {
            title: "DELETE",
            recommended: [USER_ROLES.director],
            not_allowed: Object.values(USER_ROLES).filter(item => item != USER_ROLES.director),
            dependent_on: [],
            value: MODULES.DELETE_PRODUCT_CATEGORY,
          }
        ]
      },
      {
        title: "Product SKUs",
        children: [
          {
            title: "VIEW",
            recommended: [USER_ROLES.director],
            not_allowed: Object.values(USER_ROLES).filter(item => item != USER_ROLES.director),
            dependent_on: [],
            value: MODULES.VIEW_PRODUCT,
          },
          {
            title: "CREATE",
            recommended: [USER_ROLES.director],
            not_allowed: Object.values(USER_ROLES).filter(item => item != USER_ROLES.director),
            dependent_on: [],
            value: MODULES.CREATE_PRODUCT,
          }, {
            title: "UPDATE",
            recommended: [USER_ROLES.director],
            not_allowed: Object.values(USER_ROLES).filter(item => item != USER_ROLES.director),
            dependent_on: [],
            value: MODULES.UPDATE_PRODUCT,
          },
          {
            title: "DELETE",
            recommended: [USER_ROLES.director],
            not_allowed: Object.values(USER_ROLES).filter(item => item != USER_ROLES.director),
            dependent_on: [],
            value: MODULES.DELETE_PRODUCT,
          }
        ]
      }
    ]
  }

];
export default modulesConfig;
