import {
  Alert,
  AlertTitle,
  Box,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import LeadIndividualSalesReportController from "./lead-analytics/LeadIndividualSalesReportController";

import { useState } from "react";
import { useSelector } from "react-redux";

import LeadAnalyticsByStatusAndSalesPersonController from "./lead-analytics/LeadAnalyticsByStatusAndSalesPersonController";
import { moduleAccessCondition } from "../../utils/helpers/helper";
import { USER_ROLES } from "../../utils/constants/constants";
import LeadAnalyticsBySourceAndSalesPersonController from "./lead-analytics/LeadAnalyticsBySourceAndSalesPersonController";
import InstallationCalendar from "../projects/installationcalendar/InstallationCalendar";
import LeadAnalyticsBySourceAndSalesPersonUI from "./lead-analytics/LeadAnalyticsBySourceAndSalesPersonUI";
import LeadAnalyticsByStatusAndSourceUI from "./lead-analytics/LeadAnalyticsByStatusAndSourceUI";
import LeadAnalyticsByStatusAndSourceController from "./lead-analytics/LeadAnalyticsByStatusAndSourceController";
import MODULES from "../../utils/constants/module.constants";
import FinanceCountsController from "../finance/FinanceCountsController";
import { useNavigate, useParams } from "react-router-dom";
import DashboardAnnouncement from "./DashboardAnnouncement";
import DashboardBlog from "./DashboardBlog";
import SalesLeaderBoardController from "../lead-reports/leader-board/SalesLeaderBoardController";
import SalesReportController from "../lead-reports/sales-report/SalesReportController";
import LeadAnalyticsBySourceController from "../lead-reports/source/LeadAnalyticsBySourceController";
import LeadAnalyticsByStateController from "../lead-reports/state/LeadAnalyticsByStateController";
import LeadAnalyticsByRejectionReasonController from "../lead-reports/rejection-reasons/LeadAnalyticsByRejectionReasonController";
import { Close } from "@mui/icons-material";
// import ListController from "./lead-finder/ListController"

const MainDashboard = () => {
  const user = useSelector((state) => state.user);
  const [open,setOpen] = useState(true)
  console.log("USERRRRR", user);
  return (
    <>
      <Grid container spacing={2}>
      {user?.data?.company_id?.ceo_message && (
          <Grid item xs={12} md={12}>
            {/* <Alert severity="success">
              <AlertTitle>CEO's Message</AlertTitle>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
                dangerouslySetInnerHTML={{
                  __html: user.data.company_id.ceo_message,
                }}
              ></Typography>
            </Alert> */}
            <Collapse in={open}>
              <Alert
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <Close fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                <AlertTitle>CEO's Message</AlertTitle>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                  dangerouslySetInnerHTML={{
                    __html: user.data.company_id.ceo_message,
                  }}
                ></Typography>
              </Alert>
            </Collapse>
          </Grid>
        )}
        <Grid item xs={12} md={4}>
          <LeadIndividualSalesReportController />
        </Grid>

        <Grid item md={3.5} xs={12}>
          <Box>
            <DashboardAnnouncement />
            
          </Box>
          <Box mt={2} >
          <DashboardBlog />
          </Box>
        </Grid>
        <Grid item md={4.5} xs={12}>
          <Box>
          <SalesLeaderBoardController/>
          </Box>
        </Grid>
      </Grid>

      <Box mt={3} sx={{ display: "flex" }}></Box>
    </>
  );
};
const DashboardUi = () => {
  const { user } = useSelector((state) => state);
  const params = useParams();
  const navigate = useNavigate();
  const [tab, setTab] = useState(params.tab ?? "main");
  return (
    <>
      <Box mb={3}>
     
         <MainDashboard />
        {/* {tab == "main" &&}
        
        
        {tab == "intsallation-calendar" && <InstallationCalendar />}
        {tab == "finance" && <FinanceCountsController />}
        {tab == "sources-cross-sp" && (
          <LeadAnalyticsBySourceAndSalesPersonController />
        )}
        {tab == "status-cross-sp" && (
          <LeadAnalyticsByStatusAndSalesPersonController />
        )}
        {tab == "sources-cross-status" && (
          <LeadAnalyticsByStatusAndSourceController />
        )} */}
      </Box>
    </>
  );
};
export default DashboardUi;
