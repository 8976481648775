const { SolarPower } = require("@mui/icons-material")
const { MenuItem } = require("@mui/material")

const OpenInOpenSolarButton = ({ openSolarProjectId }) => {
    const onClick = () => { window.open(`https://app.opensolar.com/#/projects/${openSolarProjectId}/info`, "_blank", `resizable=yes, scrollbars=yes, titlebar=yes, width=${window.innerWidth}, height=${window.innerHeight}`) }
    return   <MenuItem onClick={onClick}>

    {<SolarPower fontSize='inherit' />}
    &nbsp;View Lead
</MenuItem>
}

export default OpenInOpenSolarButton