import { memo, useEffect, useState } from "react"

import LeadPipelineViewUi from "./LeadPipelineViewUi"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../../store/actions/commonAction"
import {  getLeadPiplineViewApi } from "../../../apis/lead.api"
import { updateCentralLeadFilters } from "../../../store/actions/leadFilterAction"


const LeadPipelineViewController = ({ }) => {

    const { leadFilters, user } = useSelector((state) => state)
    const dispatch = useDispatch()
    const title = "Pipeline view"
    const fetchApi = getLeadPiplineViewApi




    const [filters, setFilters] = useState({
        ...leadFilters,
        pipeline_id: null
    })
const [selecteStep,setSelectedStep] = useState(null)

    useEffect(() => {
        dispatch(updateCentralLeadFilters({ ...filters }))

        return () => {
            dispatch(updateCentralLeadFilters({ ...filters, pipeline_id: null, step: null }))
        }
    }, [filters])



    // do not change 

    const [loading, setLoading] = useState(false)
    const [list, setList] = useState([])









    const fetchList = () => {

        const filtersToBePassed = {}

        for (let item in { ...filters }) {
            if (filters[item] && typeof filters[item] == "object" && filters[item]?._id) {
                filtersToBePassed[item] = filters[item]?._id
            } else if (filters[item]) {
                filtersToBePassed[item] = filters[item]
            }
        }

        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchApi({ ...filtersToBePassed, step: null }),
            (response) => {
                setList(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }



    useEffect(() => {
        if (filters.pipeline_id)
            fetchList()
    }, [filters])



    return (
        <>
            <LeadPipelineViewUi

                title={title}
                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}

                setSelectedStep={setSelectedStep}
                selecteStep={selecteStep}

            />

        </>
    )
}
export default LeadPipelineViewController