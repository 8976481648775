import { memo, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";

import { useParams } from "react-router-dom";
import { USER_ROLES } from "../../utils/constants/constants";
import {

  getCompanyByIdApi,

} from "../../apis/company.api";
import CompanyDetailsUi, { CompanyDetailModal } from "./CompanyDetailsUi";


const CompanyDetailsController = ({ callBack = () => { }, companyId, modal }) => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state)
  const params = useParams()
  const id = companyId ?? user?.data?.company_id?._id
  const modalKey = "company-details";
  const title = "Company Details";

  const getByIdApi = getCompanyByIdApi;


  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [state, setState] = useState([]);
  const [fields, setFields] = useState({
    err: "",
    id,
  });


  const fetchById = (id) => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await getByIdApi({ id }),
        async (response) => {
          setFields({ ...fields, ...response });
          setLoading(false);
        },
        (err) => {
          setFields({ ...fields, err });
          setLoading(false);
        }
      )
    );
  };


  useEffect(() => {

    fetchById(id);

  }, [id]);

  if (modal) {
    return <CompanyDetailModal
      id={id}
      callBack={callBack}
      onRefresh={async () => { fetchById(id) }}
      modalKey={modalKey}
      title={title}
      list={list}
      setList={setList}
      state={state}
      setState={setState}
      loading={loading}
      fields={fields}
      
      
      setFields={setFields}
    />
  }
  return (
    <CompanyDetailsUi
      id={id}
      callBack={callBack}
      onRefresh={async () => { fetchById(id) }}
      modalKey={modalKey}
      title={title}
      list={list}
      setList={setList}
      state={state}
      setState={setState}
      loading={loading}
      fields={fields}      
      
      setFields={setFields}
    />
  );
};
export default memo(CompanyDetailsController);
