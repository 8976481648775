import { memo, useCallback, useEffect, useState } from "react";
// import ListUi from "./ListUi";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";

import { closeModal, openModal } from "../../store/actions/modalAction";
import {
  Box,
  CircularProgress,
  IconButton,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { useMemo } from "react";
// import CreateController from "./CreateController";
import { Delete, Edit, Info } from "@mui/icons-material";

// import getSourceApi, { updateSourceField } from "../../apis/sources.api";
// import getTeamApi from "../../apis/team.api";
import { moduleAccessCondition } from "../../utils/helpers/helper";
import MODULES from "../../utils/constants/module.constants";
import getAnnouncementApi from "../../apis/announcement.api";
// import DetailsUI from "./DetailsUI";
import AllAnnouncementShowUi from "./AllAnnouncementShowUi";

const AllAnnouncementShowController = () => {
  const dispatch = useDispatch();

  const title = "Announcements ";
  const fetchApi = getAnnouncementApi;
  const modalKey = "view-more";
  const deleteApi = null;

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 5,
    search: "",
    searchable: ["announcement_title"],
    sort: "announcement_title",
    sortDirection: 1,
  });

  // do not change

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState({});

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchApi({ ...filters }),
        (response) => {
          setList(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    fetchList();
  }, [filters]);

  return (
    <>
      <AllAnnouncementShowUi
        modalKey={modalKey}
        title={title}
        filters={filters}
        setFilters={setFilters}
        loading={loading}
        list={list}
      />
    </>
  );
};
export default AllAnnouncementShowController;
