import { Avatar, Box, Button, ButtonGroup, CircularProgress, Grid, Paper, Typography } from "@mui/material"
import DashBox from "../../dashboard/Dashbox"
import { useDispatch, useSelector } from "react-redux"
import { CenteredBox } from "../../../components/layouts/OneViewBox"
import { MilitaryTech, SwapVert } from "@mui/icons-material"
import AssignedSalesPerson from "../../lead/components/AssignedSalesPerson"
import BubbleComponent from "../../../components/layouts/BubbleComponent"
import LeadTimeFilters from "../../lead/filters/LeadTimeFilter"
import FilterButton from "../../lead/filters/FilterButton"
import { updateLeaderBoardFilters } from "../../../store/actions/leaderboardActions"

const SalesItem = ({ title, type, ratio, won, index }) => {
    return <Grid item xs={6} >
        <Box component={Paper} variant="outlined" >

            <Box p={2} borderBottom={1} display="flex" width="100%" borderBottomColor="divider" >
                <AssignedSalesPerson
                    title={String(index + 1)}
                    size="small"
                />
                <Typography ml={2}>
                    {title}
                </Typography>
            </Box>

            <Grid container >

                <Grid item xs={6} sx={{ borderRight: 1, borderColor: "divider" }} >
                    <CenteredBox py={2}>
                        <Typography variant="h4" color="success.main" lineHeight="100%" >
                            {won}

                        </Typography>
                        <Typography color="success" variant="h5" >
                            <MilitaryTech color="success" fontSize="inherit" />
                        </Typography>
                    </CenteredBox>
                </Grid>
                <Grid item xs={6} >
                    <CenteredBox p={2}>
                        <BubbleComponent percentage={ratio * 100} height={50} width={50} />
                    </CenteredBox>
                </Grid>
            </Grid>

        </Box>
    </Grid>
}

const SaleLeaderBoardUi = ({ loading }) => {
    const dispatch = useDispatch()
    const { leaderboard } = useSelector(state => state)
    const { filters, data } = leaderboard
    const setFilters = (newFilters) => { dispatch(updateLeaderBoardFilters({ ...filters, ...newFilters })) }
    return <DashBox title="Sales Leaderboard">
        <Box p={2}>
            <LeadTimeFilters onlyRange filters={filters} setFilters={setFilters} />
            <Box display="flex" justifyContent="space-between" width="100%" my={2}>
                <ButtonGroup size="small" disableElevation>
                    <Button onClick={() => setFilters({ leaderboardSortType: 'sold' })} variant={filters.leaderboardSortType == 'sold' ? "contained" : "outlined"} ><SwapVert fontSize="inherit" /> Won Lead</Button>
                    <Button onClick={() => setFilters({ leaderboardSortType: 'ratio' })} variant={filters.leaderboardSortType == 'ratio' ? "contained" : "outlined"} ><SwapVert fontSize="inherit" /> Ratio</Button>
                </ButtonGroup >
                <FilterButton notApplicableFilters={['sales_person', 'status', 'date']} defaultFilters={filters} setDefaultFilters={setFilters} />
            </Box>
            {
                loading && <CenteredBox sx={{}}>
                    <CircularProgress />
                </CenteredBox>
            }
            {!loading && <Grid container spacing={2} >
                {
                    data && data?.slice(0, 10)?.map((item, index) => <SalesItem
                        index={index}
                        title={item?.[0]?.name}
                        type={filters.leaderboardSortType}
                        ratio={item?.[4]}
                        won={item?.[2]?.total_lead}

                    />)
                }
            </Grid>}

        </Box>
    </DashBox>
}
export default SaleLeaderBoardUi