import { Autocomplete, CircularProgress, MenuItem } from "@mui/material"
import {  memo, useMemo } from "react"
import {  useSelector } from 'react-redux'

import CustomInput from "../../components/inputs/CustomInput"
import {  USER_ROLES } from "../../utils/constants/constants"
import { findNameByRole, findObjectKeyByValue, titleCase } from "../../utils/helpers/helper"
import AsyncDropDown from "../../components/inputs/AsyncDropDown"

import CustomDialog from "../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../components/layouts/OneViewBox"

import InstallerFields from "../installer/InstallerFields"
import getTeamApi from "../../apis/team.api"
import { getAllowedRolesForExistingRole } from "../../utils/helpers/user.helper"
import { getCompanyApi } from "../../apis/company.api"



const CreateUi = ({ title, isInstaller, isUpdate, fields, setFields, loading, onSubmit }) => {
    const { user } = useSelector(state => state)

    // let allowedRoles = [...Object.keys(USER_ROLES).map((key) => ({ label: titleCase(key), _id: USER_ROLES[key] }))]
    
const allowedRoles = useMemo(()=>{
return getAllowedRolesForExistingRole(user.data?.role).map((item)=>({label:findObjectKeyByValue(item,USER_ROLES),_id:item}))
},[user.data.role]) 
    


    return <>

        <CustomDialog

            loading={loading}
            err={fields.err}
            onSubmit={onSubmit}
            title={`${isUpdate ? "Update" : "Create"} ${title}`}
            closeText="Close"
            confirmText={`${isUpdate ? "Update" : "Create"}`}
        >

            {loading ? <CenteredBox><CircularProgress /></CenteredBox> :
                <>
                    <CustomInput
                        autoFocus={true}
                        disabled={loading}
                        value={fields.name}
                        onChange={(e) => setFields({ ...fields, err: '', name: e.target.value })}
                        type="text"
                        label={"Name*"}

                    />

                    { <CustomInput
                        disabled={loading}
                        value={fields.email}
                        onChange={(e) => setFields({ ...fields, err: '', email: e.target.value })}
                        type="text"
                        label={"Email*"}

                    />}
                    {
                        <CustomInput
                            disabled={loading}
                            value={fields.phone}
                            onChange={(e) => setFields({ ...fields, err: '', phone: e.target.value })}
                            type="tel"
                            label={"Phone No*"}

                        />
                    }
                    {!isUpdate && <CustomInput
                        disabled={loading}
                        value={fields.password}
                        onChange={(e) => setFields({ ...fields, err: '', password: e.target.value })}

                        type="password"
                        label={"Password*"}

                    />}

                 
                    {!isInstaller && <Autocomplete
                        // disablePortal
                        disableClearable
                        options={allowedRoles}
                        value={findNameByRole(fields.role)}
                        // id="user-role-id"
                        // name="user-role-id"
                        onChange={(e, newVal) => {
                            setFields({ ...fields, role: newVal ? newVal._id : null })
                        }}

                        sx={{ width: "100%", display: "flex", "*": { display: "flex", justifyContent: "center" } }}
                        renderInput={(params) => <CustomInput placeholder="Select Role*" {...params} label="Select Role*" />}
                    />}
  {user.data.role==USER_ROLES.director && fields.role!=USER_ROLES.director &&
                        <AsyncDropDown

                        id={'company-user'}
                        defaultVal={fields.company_id}
                        lazyFun={getCompanyApi}
                        label="Select Company*"
                        OptionComponent={({ option, ...rest }) => {
                            return <MenuItem {...rest}>{option.name}</MenuItem>
                        }}
                        onChange={async (changedVal) => { setFields({ ...fields, company_id: changedVal ? changedVal._id : null,team:null }) }}
                        titleKey={'name'}
                        valueKey={"_id"}
                    />
                    }

                    { [USER_ROLES.sales,USER_ROLES.tl].includes(fields.role) &&  <AsyncDropDown

                        id={'teams-users'}
                        key={fields.company_id}
                        defaultVal={fields.team}
                        lazyFun={async(para)=>await getTeamApi({...para,company_id:fields.company_id??undefined})}
                        label="Select Team*"
                        OptionComponent={({ option, ...rest }) => {
                            return <MenuItem {...rest}>{option.name}</MenuItem>
                        }}
                        onChange={async (changedVal) => { setFields({ ...fields, team: changedVal ? changedVal._id : null }) }}
                        titleKey={'name'}
                        valueKey={"_id"}
                    />}
                  
                    
                    {/* {
                        (isInstaller || fields.role == USER_ROLES.installer) && !isUpdate &&

                        <InstallerFields loading={loading} fields={fields} setFields={setFields} />
                    } */}
                </>}

        </CustomDialog>
     

    </>
}
export default memo(CreateUi)