import { useState } from "react"
import UploadCsvUI from "./UploadCsvUI"
import { useDispatch } from "react-redux"
import { MODAL_KEYS } from "../../utils/constants/modal.constant"
import { uploadProductCSVApi } from "../../apis/product.api"
import { Button } from "@mui/material"
import { closeModal, openModal } from "../../store/actions/modalAction"
import { callApiAction } from "../../store/actions/commonAction"
import { callSnackBar } from "../../store/actions/snackbarAction"

export const UploadCsvButton = ({ ...props }) => {
    const dispatch = useDispatch()
    const onClick = () => {
        dispatch(openModal(<UploadCsvController {...props} />, 'sm', undefined, MODAL_KEYS.UPLOAD_CSV))
    }
    return <Button variant="contained" size="small" onClick={onClick} >
        Import CSV
    </Button>
}
const UploadCsvController = ({ product_category, callBack }) => {


    const dispatch = useDispatch()
    const modalKey = MODAL_KEYS.UPLOAD_CSV

    const [fields, setFields] = useState({
        err: null,
        product_category,
        file: null
    })
    const [loading, setLoading] = useState(null)

    const validateFile = (file) => {
        if (!file) {
            setFields({ ...fields, err: "select File" })
            return false
        }
        const allowedExtensions = /(\.xlsx|\.xls|\.csv)$/i;
        if (!allowedExtensions.exec(file.name)) {
            setFields({ ...fields, err: "Please upload a file with .xlsx, .xls, or .csv extension." });
          return false;
        }
        

        return true
    }
    const onSubmit = async (e) => {
        e?.preventDefault()
        if (validateFile(fields.file)) {
            const formData = new FormData()
            formData.append('file',fields.file)
            formData.append('product_category',product_category)
            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await uploadProductCSVApi(formData),
                    async (response) => {

                        await callBack(response)
                        setLoading(false)
                        dispatch(closeModal(modalKey))
                        dispatch(callSnackBar("Data uploaded successfully"))
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        }

    }


    return <UploadCsvUI
        onSubmit={onSubmit}
        modalKey={modalKey}
        fields={fields}
        setFields={setFields}
        loading={loading}

    />
}
export default UploadCsvController