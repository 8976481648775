
import { Button, ButtonGroup, Paper, MenuItem, FormControlLabel, Switch, Tab, Tabs, Tooltip, Typography, styled, Autocomplete } from "@mui/material"
import { Box } from "@mui/system"
import { useSelector } from "react-redux"



import SubmitButton from "../../components/button/SubmitButton"
import { StyledSearchBar } from "../../components/inputs/SearchBar"
import { AUS_STATES, USER_ROLES } from "../../utils/constants/constants"
import DataTable from "../../components/tables/DataTable"
import { allowedProjectStatusDropDownList, fetchAllowedSubTab } from "../../utils/helpers/project.helper"
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxInDesktop"
import TimeRangeSelector from "../../components/layouts/common/TimeRangeSelector"
import AsynSearchBar from "../../components/inputs/AsynSearchBar"
import AsyncDropDown from "../../components/inputs/AsyncDropDown"
import { getInstallerApi, getUserApi } from "../../apis/user.api"
import MapOpenButton from "./map/MapOpenButton"
import { DOCUMENT_TYPES, PROJECT_PARENT_STATUS, PROJECT_STATUS } from "../../utils/constants/project.constant"
import ProjectCounts from "./ProjectCounts"
import { FlagFilterButton } from "./FlagFiltersComponent"
import { getProjectDocumentsTypeApi } from "../../apis/projects.api"
import { moduleAccessCondition, titleCase } from "../../utils/helpers/helper"
import MODULES from "../../utils/constants/module.constants"
import SalesPersonFilterDropDown from "../../components/filterdropdowns/SalesPersonFilter"
import { ProductFilterButton } from "./ProductFiltersComponent"



const FilterTitleBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }

}))
const FiltersBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",

    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }
}))


const ListUi = ({ title, filters, setFilters, list, roles, loading, columns, exportLoading, onExportBtnClick, onCreateBtnClick }) => {

    const user = useSelector(state => state.user)



    return (
        <>

            <Box mb={3}>
                <Paper elevation={2} sx={{ width: "100%", padding: 4 }} >
                    <Box mb={2} >
                        <FilterTitleBox>
                            <Typography variant="h5" mb={2} >{title}</Typography>

                           

                        </FilterTitleBox>


                        <FiltersBox>
                            <PaddingBoxInDesktop sx={{ display: "flex", flex: 1 }} >


                                <TimeRangeSelector placeHolder="Creation Date" onChange={(newRange) => { setFilters({ ...filters, ...newRange }) }} />

                            </PaddingBoxInDesktop>
                            <PaddingBoxInDesktop sx={{ display: "flex", flex: 1 }} pl={2} >
                                <TimeRangeSelector placeHolder="Approval Date" onChange={(newRange) => { setFilters({ ...filters, approvalStartDate: newRange.startDate, approvalEndDate: newRange.endDate }) }} />
                            </PaddingBoxInDesktop>
                            {<PaddingBoxInDesktop sx={{ flex: 1 }} pl={2} >


                                <TimeRangeSelector placeHolder="Installation Date" onChange={(newRange) => { setFilters({ ...filters, installationStartDate: newRange.startDate, installationEndDate: newRange.endDate }) }} />

                            </PaddingBoxInDesktop>}
                            {<PaddingBoxInDesktop sx={{ flex: 1 }} pl={2} >


                                <Autocomplete
                                    disablePortal
                                    size="small"
                                    id="combo-box-demo"
                                    options={AUS_STATES}
                                    onChange={(e, newVal) => {
                                        setFilters({ ...filters, state: newVal })
                                    }}
                                    fullWidth
                                    renderInput={(params) => <StyledSearchBar {...params} placeholder="States" />}
                                />



                            </PaddingBoxInDesktop>}

                        </FiltersBox>
                        <FiltersBox mt={2}>
                            {user.data.role != USER_ROLES.sales && user.data.role != USER_ROLES.installer && <PaddingBoxInDesktop sx={{ display: "flex", flex: 1 }} >
                                <AsyncDropDown
                                    id={'installer-projects'}
                                    margin={0}
                                    InputComponent={(props) => <StyledSearchBar placeholder="Select Installer"  {...props} margin="none" size="small" />}
                                    lazyFun={async (params) => { return await getInstallerApi({ ...params}) }}

                                    OptionComponent={({ option, ...rest }) => {
                                        return <MenuItem {...rest}>{option.name}</MenuItem>
                                    }}
                                    onChange={async (changedVal) => {

                                        setFilters({ ...filters, assigned_installer: changedVal ? changedVal._id : null })
                                    }}
                                    titleKey={'name'}
                                    valueKey={"_id"}
                                />
                            </PaddingBoxInDesktop>}
                            {<PaddingBoxInDesktop sx={{ display: "flex", flex: 1 }} pl={2} >
                               

                                <SalesPersonFilterDropDown
                                    id={'sales-projects'}
                                    filters={filters}
                                    setFilters={setFilters}
                                    filtersKey="assigned_sales_person"
                                />
                            </PaddingBoxInDesktop>}

                            {(filters.parentStatus == PROJECT_PARENT_STATUS.PRE_APPROVALS || filters.documents?.length > 0) && user.data.role != USER_ROLES.installer && <PaddingBoxInDesktop sx={{ display: "flex", flex: 1 }} pl={2} >
                                <AsyncDropDown
                                    id={'socuments-projects'}
                                    margin={0}
                                    defaultOptions={Object.values(DOCUMENT_TYPES).sort((a, b) => a.localeCompare(b)).map((item) => ({ name: item, default: true }))}

                                    multiple
                                    InputComponent={(props) => <StyledSearchBar placeholder="Select For Not Available Documents"  {...props} margin="none" size="small" />}
                                    lazyFun={async (params) => { return await getProjectDocumentsTypeApi({ ...params, searchable: ["name"] }) }}

                                    OptionComponent={({ option, ...rest }) => {
                                        return <MenuItem {...rest}>{option.name}</MenuItem>
                                    }}

                                    onChange={async (changedVal) => {

                                        setFilters({ ...filters, documents: changedVal ? changedVal?.map((item) => titleCase(item?.name)) : [] })
                                    }}
                                    groupBy={(option) => option.default ? "Default" : "All"}
                                    getOptionLabel={(option) => option.name}
                                    titleKey={'name'}
                                    valueKey={"_id"}
                                />
                            </PaddingBoxInDesktop>}
                        </FiltersBox>

                    </Box>
                    <Box display="flex">
                        <FlagFilterButton filters={filters} setFilters={setFilters} />
                        <Box ml={1}>
                        <ProductFilterButton filters={filters} setFilters={setFilters}  />
                        </Box>
                    </Box>
                    <Box width={"100%"} mt={2} mb={3} >
                        <ProjectCounts filters={filters} setFilters={setFilters} />
                    </Box>
                    <FiltersBox mt={2} mb={2} >
                        <PaddingBoxInDesktop sx={{ display: "flex", flex: 1 }}>
                            <Box  >
                                {moduleAccessCondition(user, [MODULES.PROJECT_DATA_EXPORT]) && <SubmitButton loading={exportLoading} variant="contained" onClick={onExportBtnClick} title={"Export CSV"} />}
                            </Box>
                            {filters.parentStatus == PROJECT_PARENT_STATUS.INSTALLATION && <Box ml={2}>
                                <MapOpenButton />
                            </Box>}
                        </PaddingBoxInDesktop>

                        <PaddingBoxInDesktop sx={{ display: "flex", justifyContent: "flex-end", flex: 1 }} pl={2} >
                            {/* <StyledSearchBar fullWidth title="Search By Key Name " size="small" placeholder={"Search By Customer | Project id "} value={filters.search} onChange={(e) => { setFilters({ ...filters, search: e.target.value }) }} /> */}

                            <AsynSearchBar
                                fullWidth
                                title="Search By Key Name " size="small" placeholder={"Search By Customer | Project id "}
                                defaultValue={filters.search}
                                onChange={(changedVal) => { setFilters({ ...filters, search: changedVal }) }}
                            />
                        </PaddingBoxInDesktop>



                    </FiltersBox>
                    <Box sx={{ minHeight: "300px" }} pt={0}>
                        <DataTable key={columns.length} columns={columns} rows={list.result ? list.result : []} count={list.total ?? 0} filters={filters} setFilters={setFilters} loading={loading} />
                    </Box>
                </Paper >
            </Box >

        </>
    )
}
export default ListUi