import {  CircularProgress} from "@mui/material"
import { memo } from "react"
import { useSelector } from 'react-redux'

import CustomInput from "../../../components/inputs/CustomInput"

import CustomDialog from "../../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../../components/layouts/OneViewBox"




const AddTagUi = ({ title,  isUpdate, fields, setFields, loading, onSubmit }) => {

    return <>

        <CustomDialog

            loading={loading}
            err={fields.err}
            onSubmit={onSubmit}
            title={`${isUpdate ? "Update" : "Create"} ${title}`}
            closeText="Close"
            confirmText={`${isUpdate ? "Update" : "Create"}`}
        >

            {loading ? <CenteredBox><CircularProgress /></CenteredBox> :
                <>
                    <CustomInput
                        autoFocus={true}
                        disabled={loading}
                        value={fields.tag}
                        onChange={(e) => setFields({ ...fields, err: '', tag: e.target.value })}
                        type="text"
                        label={"Tag*"}

                    />
                </>}

        </CustomDialog>


    </>
}
export default memo(AddTagUi)