export const INVOICE_TYPE = {
    DEPOSIT: 1,
    GENERAL: 3,
    EXTRAA_CHARGE: 4,
    CUSTOMER_INVOICE: 5,
    ELECTRICIAN_INVOICE: 6,
    // STC_INVOICE: 7,
    BULK_INVOICE: 8,
    INCENTIVES_INVOICE:9,
    // SOLAR_VICTORIA_INVOICE: 9,
    SUPPLIER_INVOICE: 10,
    APPLICATION_AND_DELIVERY_FEES_INVOICE: 11,
    REFUND_INVOICE: 12,
    LOSS_INVOICE: 13
}

export const INVOICES_NAME = {

    [INVOICE_TYPE.DEPOSIT]: "Deposit",
    [INVOICE_TYPE.CUSTOMER_INVOICE]: "Customer",
    [INVOICE_TYPE.ELECTRICIAN_INVOICE]: "Electrician",
    [INVOICE_TYPE.GENERAL]: "General",
    [INVOICE_TYPE.SUPPLIER_INVOICE]: "Supplier",
    // [INVOICE_TYPE.STC_INVOICE]: "STC",
    // [INVOICE_TYPE.SOLAR_VICTORIA_INVOICE]: "Solar Victoria",
    [INVOICE_TYPE.INCENTIVES_INVOICE]: "Incentives",
    [INVOICE_TYPE.APPLICATION_AND_DELIVERY_FEES_INVOICE]: "ADF",
    [INVOICE_TYPE.EXTRAA_CHARGE]: "Extraa Charge",
    [INVOICE_TYPE.REFUND_INVOICE]: "Refund",
    [INVOICE_TYPE.LOSS_INVOICE]: "Loss",

}
export const BULK_INVOICE_TYPE = {

    ELECTRICIAN_INVOICE: INVOICE_TYPE.ELECTRICIAN_INVOICE,
    // STC_INVOICE: INVOICE_TYPE.STC_INVOICE,
    INCENTIVES_INVOICE: INVOICE_TYPE.INCENTIVES_INVOICE,
    APPLICATION_AND_DELIVERY_FEES_INVOICE: INVOICE_TYPE.APPLICATION_AND_DELIVERY_FEES_INVOICE,
    SUPPLIER_INVOICE: INVOICE_TYPE.SUPPLIER_INVOICE,

}

export const INVOICE_TYPE_LIST = [

    {
        name: "Deposit",
        value: INVOICE_TYPE.DEPOSIT,
        debit: false
    },
    {
        name: "Customer Invoice",
        value: INVOICE_TYPE.CUSTOMER,
        debit: false
    },

    {
        name: "General Charges",
        value: INVOICE_TYPE.GENERAL,
        debit: false
    },

    {
        name: "Extraa Charges",
        value: INVOICE_TYPE.EXTRAA_CHARGE,
        debit: false
    },
    {
        name: "Incentive Invoices",
        value: INVOICE_TYPE.INCENTIVES_INVOICE,
        debit: false
    },
    // {
    //     name: "STC Invoices",
    //     value: INVOICE_TYPE.STC_INVOICE,
    //     debit: false
    // },
    // {
    //     name: "Solar Victoria Invoices",
    //     value: INVOICE_TYPE.SOLAR_VICTORIA_INVOICE,
    //     debit: false
    // },
    {
        name: "Suppier Invoices",
        value: INVOICE_TYPE.SUPPLIER_INVOICE,
        debit: true
    },

    {
        name: "Electrician Invoices",
        value: INVOICE_TYPE.ELECTRICIAN_INVOICE,
        debit: true
    },
    {
        name: "Application & delivery Invoices",
        value: INVOICE_TYPE.APPLICATION_AND_DELIVERY_FEES_INVOICE,
        debit: true
    },
    {
        name: "Refund Invoices",
        value: INVOICE_TYPE.REFUND_INVOICE,
        debit: true
    },
    {
        name: "Loss Invoices",
        value: INVOICE_TYPE.LOSS_INVOICE,
        debit: true
    }
]
export const INVOICE_TYPE_DROP_DOWN = {
    // DEPOSIT: 1,
    GENERAL: 3,
    EXTRAA_CHARGE: 4,


}
export const INVOICE_STATUS = {
    PAID: 1,
    PENDING: 2,
    PARTIALLY_PAID: 3
}
export const PAYMENT_OPTION = {
    CARD: 1,
    BANK: 2,
    LOSS: 3
}
