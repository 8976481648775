import { CircularProgress } from "@mui/material"

import { Fragment, memo } from "react"

import CustomInput from "../../../components/inputs/CustomInput"
import CustomDialog from "../../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../../components/layouts/OneViewBox"



const CreateUi = ({ title,  fields, setFields, loading, onSubmit, modalKey }) => {



    return <>

        <CustomDialog
            id={modalKey}
            loading={loading}
            err={fields.err}
            onSubmit={onSubmit}
            title={` ${title}`}
            closeText="Close"
            confirmText={`Submit`}
        >

            {loading ? <CenteredBox><CircularProgress /></CenteredBox> :
                <>
                    {<CustomInput
                        autoFocus={true}
                        disabled={loading}
                        value={fields.email}
                        onChange={(e) => setFields({ ...fields, err: '', email: e.target.value })}
                        type="text"
                        label={"Email*"}

                    />}

                    {<CustomInput
                        disabled={loading}
                        value={fields.password}
                        onChange={(e) => setFields({ ...fields, err: '', password: e.target.value })}
                        type="password"
                        label={"Password"}

                    />}
                </>}

        </CustomDialog>
    </>
}
export default memo(CreateUi)