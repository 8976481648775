import { Alert, Autocomplete, Box, Button, ButtonBase, CircularProgress, IconButton, MenuItem, Paper, Tooltip } from "@mui/material";
import AsyncDropDown from "../../../components/inputs/AsyncDropDown";
import { ENERGY_PARTNER_TYPE } from "../../../utils/constants/energypartner.constant";
import { getEnergyPartnerApi, getPartnerFromPinCodesApi } from "../../../apis/energy-partners.api";
import CustomDialog from "../../../components/layouts/common/CustomDialog";
import { MODAL_KEYS } from "../../../utils/constants/modal.constant";
import { AutoAwesome } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { callApiAction } from "../../../store/actions/commonAction";
import { callSnackBar } from "../../../store/actions/snackbarAction";
import { SNACK_BAR_VARIETNS } from "../../../utils/constants/constants";
import { closeModal, openModal } from "../../../store/actions/modalAction";
import CustomInput from "../../../components/inputs/CustomInput";

const PartnerSelectionDropDown = ({ partners, onChange }) => {


    return <CustomDialog
        id={MODAL_KEYS.ENERGY_PARTNER_POSTCODE_ADD}
        title="Select Energy Partner"
    >
        <Alert severity="success" >
            Total {partners.length} partners detected. Select One of them.

        </Alert>
        <Box mt={2} />
        {
            partners.map((item) => <Button sx={{ width: "100%",mb:2 }} variant="outlined" mb={2} key={item?._id} onClick={() => onChange(item)} >
                {item?.name}
            </Button>)
        }
    </CustomDialog>
}

const EnergyPartnerDropDown = ({ postcode, value, type, onChange }) => {

    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const [randomKey, setRandomKey] = useState(Math.random())
    const onAutoDetact = () => {
        setLoading(true)
        dispatch(
            callApiAction(
                async () => await getPartnerFromPinCodesApi({ type, postcode }),
                async (response) => {

                    setLoading(false)
                    if (response && Array.isArray(response) && response.length > 0) {
                        if (response.length == 1) {
                            onChange(response[0])
                            setRandomKey(Math.random())
                        }
                        if (response.length > 1)
                            dispatch(openModal(<PartnerSelectionDropDown partners={response} onChange={(selectedItem) => {
                                onChange(selectedItem)
                                setRandomKey(Math.random())
                                dispatch(closeModal(MODAL_KEYS.ENERGY_PARTNER_POSTCODE_ADD))
                            }} />, 'xs', undefined, MODAL_KEYS.ENERGY_PARTNER_POSTCODE_ADD))
                    } else {
                        dispatch(callSnackBar("Cant detect energy partner for this postcode. Select it manual", SNACK_BAR_VARIETNS.error))
                    }


                },
                (err) => {
                    dispatch(callSnackBar("Cant detect energy partner for this postcode. Select it manual", SNACK_BAR_VARIETNS.error))
                    setLoading(false)

                }
            )
        )
    }
    return <Box display="flex" alignItems="center" >
        <Box display="flex" flex={1} >
            
            <AsyncDropDown
            disableClearable
                id={"energy-partners-" + type}
                key={randomKey}
                value={value}
                lazyFun={async (params) => {
                    return await getEnergyPartnerApi({
                        ...params,
                        sort: 'comapny_id',
                        sortDirection: -1,
                        type
                    });
                }}
                OptionComponent={({ option, ...rest }) => {
                    return <MenuItem {...rest}>{option.name}</MenuItem>;
                }}
                onChange={onChange}
                titleKey={"name"}
                valueKey={"_id"}
                InputComponent={(params) => (
                    <CustomInput
                        {...params}
                        label={"Energy " + (type == ENERGY_PARTNER_TYPE.DISTRIBUTOR ? "Distributor" : "Retailer")}
                        size="small"
                    />
                )}
            />
        </Box>
        {
            postcode && postcode != '' && <>
                {
                    loading ? <CircularProgress /> :
                        <Tooltip arrow  title="Auto Detact" >
                            <IconButton onClick={onAutoDetact} size="small" >
                                <AutoAwesome fontSize="inherit" color="info" />
                            </IconButton>
                        </Tooltip>
                }
            </>
        }
    </Box>
}

export default EnergyPartnerDropDown