import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../../../store/actions/commonAction";
import {
    addProjectNotesApi,
    getProjectNotesApi,
    updateProjectsNoteFavorite,
} from "../../../../apis/projects.api";
import { callSnackBar } from "../../../../store/actions/snackbarAction";
import {
    SNACK_BAR_VARIETNS,
    USER_ROLES,
} from "../../../../utils/constants/constants";
import {
    Box,
    Button,
    ButtonGroup,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Rating,
    Select,
    styled,
    Switch,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import CustomInput from "../../../../components/inputs/CustomInput";
import SubmitButton from "../../../../components/button/SubmitButton";
import getNotesTypeApi from "../../../../apis/notesType.api";
import AsyncDropDown from "../../../../components/inputs/AsyncDropDown";


const CreateProjectNotesController = ({
    project_id,
    onNewNoteAdd = () => { },
}) => {

    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();


    const [note, setNote] = useState("");
    const [type, setType] = useState(null);


    const createNote = () => {
        if (!note || note == "") {
            dispatch(
                callSnackBar("Please write Something.", SNACK_BAR_VARIETNS.error)
            );
            return;
        }
        setLoading(true);
        dispatch(
            callApiAction(
                async () =>
                    await addProjectNotesApi({ project_id, note, note_type: type, mark: false }),
                async (response) => {
                    setNote("");
                    onNewNoteAdd(response)
                    setLoading(false);
                },
                (err) => {
                    setLoading(false);
                    dispatch(
                        callSnackBar("Notes Cant be created", SNACK_BAR_VARIETNS.error)
                    );
                }
            )
        );
    };



    return (
        <>
            <Box
                component={Paper}
                sx={{
                    width: "100%",
                    position: "sticky",
                    top: "0px",
                    background: "#f2f2f2",
                }}
                p={3}
            >
                <CustomInput
                    disabled={loading}
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    multiline
                    rows={3}
                    type="textarea"
                    label={"Write Note*"}
                />
                <FormControl
                    sx={{ background: "white" }}
                    fullWidth
                    margin="dense"
                >
                    <AsyncDropDown
                        id={"notes-type"}
                        key={loading}
                        lazyFun={async (params) => {
                            return await getNotesTypeApi({ ...params });
                        }}
                        label="Select Notes Type*"
                        OptionComponent={({ option, ...rest }) => {
                            return <MenuItem {...rest}>{option.title}</MenuItem>;
                        }}
                        onChange={async (changedVal) => {
                            setType({
                                ...type,
                                type: changedVal ? changedVal._id : null,
                            });
                        }}
                        titleKey={"title"}
                        valueKey={"_id"}
                        margin="none"
                        InputComponent={(params) => (
                            <CustomInput {...params} size="small" margin="none" />
                        )}
                    />
                </FormControl>
                <SubmitButton
                    title="Create Note"
                    loading={loading}
                    onClick={createNote}
                />
            </Box>
        </>
    );
};
export default CreateProjectNotesController;
