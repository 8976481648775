import { Box, CircularProgress, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material"

import { Fragment, memo } from "react"
import { useDispatch, useSelector } from 'react-redux'



import CustomInput from "../../components/inputs/CustomInput"
import CustomDialog from "../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../components/layouts/OneViewBox"
import { getActivityApi } from "../../apis/activity.api"
import AsyncDropDown from "../../components/inputs/AsyncDropDown"
import { PreviewIcon } from "../../components/inputs/IconSelectionDropDown"



const CreateUi = ({ title, isUpdate, fields, setFields, loading, onSubmit, modalKey }) => {



  return <>

    <CustomDialog
      id={modalKey}
      loading={loading}
      err={fields.err}
      onSubmit={onSubmit}
      title={`${isUpdate ? "Update" : "Add"} ${title}`}
      closeText="Close"
      confirmText={`${isUpdate ? "Update" : "Add"}`}
    >

      {loading ? <CenteredBox><CircularProgress /></CenteredBox> :
        <>

          <AsyncDropDown
            id={"parent-activity"}

            value={fields?.parent_activity}
            lazyFun={async (params) => {
              return await getActivityApi({
                ...params,
                active: true,
                searchable: ["title"],
              });
            }}
            OptionComponent={({ option, ...rest }) => {
              return <MenuItem {...rest}>
                <Typography lineHeight="100%" sx={{ color: option.color }} mr={2} >
                  <PreviewIcon value={option.icon} color={option.color} />
                </Typography>
                {option.title}</MenuItem>;
            }}
            onChange={async (changedVal) => {
              setFields({
                ...fields,
                parent_activity: changedVal ? changedVal : null,
                activity_id: null
              });
            }}
            titleKey={"title"}
            valueKey={"_id"}
            InputComponent={(params) => (
              <CustomInput
                {...params}
                label="Select Activity*"
              />
            )}
          />

       {fields.parent_activity&&   <FormControl fullWidth margin="dense">
            <InputLabel id="outcome">Outcome</InputLabel>
            <Select
              labelId="outcome"
              id="outcomee-select"
              value={fields.activity_id}
              label="Outcome"
              onChange={(e)=>{
                setFields({
                  ...fields,
                  
                  activity_id: e.target.value
                });
              }}
            >
              {
                fields.parent_activity?.outcomes?.map((option)=>  <MenuItem key={option?._id} value={option}>
                  <Box display="flex" >
                    <Typography lineHeight="100%" sx={{ color: option.color }} mr={2} >
                  <PreviewIcon value={option.icon} color={option.color} />
                </Typography>
                {option.title}
                </Box>
                </MenuItem>)
              }
            </Select>
          </FormControl>}
          <CustomInput
            multiline
            rows={3}
            disabled={loading}
            value={fields.remarks}
            onChange={(e) => setFields({ ...fields, err: '', remarks: e.target.value })}
            type="text"
            label={"Remarks"}

          />




        </>}

    </CustomDialog>
  </>
}
export default memo(CreateUi)