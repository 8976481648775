import { Box, Button, ButtonBase, Grid, styled } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { openModal } from "../../../../store/actions/modalAction"
import ProjectsNotesController from "../notes/ProjectNotesController"
import { MODAL_KEYS } from "../../../../utils/constants/modal.constant"
import ProjectLogsUI from "../ProjectLogsUI"

import ComplainListDialog from "../../../complain/ComplainListDialog"
import ProjectSystemDetailsUi from "../ProjectSystemDetailsUi"
import OrderListController from "../../order/list/ListController"
import CustomButton from "../../../../components/button/CustomButton"
import { moduleAccessCondition } from "../../../../utils/helpers/helper"
import MODULES from "../../../../utils/constants/module.constants"
import { ActivityLogsModal } from "../../../activity-logs/ListController"

const CustomRoundedButtons = CustomButton
const ProjectViewMoreButtons = ({ projectDetails, setProjectDetails,callBack }) => {
    const { user } = useSelector(state => state)
    const dispatch = useDispatch()
    const onNotesClick = () => {
        dispatch(openModal(<ProjectsNotesController project_id={projectDetails?._id} defaultNotes={projectDetails?.notes ?? []} setDefaultNotes={(resp) => setProjectDetails({ ...projectDetails, notes: resp })} />, 'md', undefined, MODAL_KEYS.NOTES))
    }
    const onLogsButtonClick = () => {
        dispatch(openModal(<ProjectLogsUI project_id={projectDetails?._id} defaultLogs={projectDetails?.logs} setProjectLogs={(resp) => setProjectDetails({ ...projectDetails, logs: resp })} />, 'xs', undefined, MODAL_KEYS.PROJECT_LOGS))
    }
  
    const onComplainButtonClick = () => {
        dispatch(openModal(<ComplainListDialog project_id={projectDetails?._id} />, 'md', undefined, MODAL_KEYS.COMPLAINS_LIST))
    }
    const onSystemsButtonClick = () => {
        dispatch(openModal(<ProjectSystemDetailsUi callBack={callBack} selected_system={projectDetails?.selected_system} project_id={projectDetails?._id} defaultSystems={projectDetails?.systems ?? []} setDefaultSystems={(resp) => setProjectDetails({ ...projectDetails, systems: resp })} />, 'md', undefined, MODAL_KEYS.SYSTEMS))
    }
    const onOrderButtonClick = () => {
        dispatch(openModal(<OrderListController project_id={projectDetails?._id} />, 'md', undefined, MODAL_KEYS.ORDERS))
    }
    const onActivityLogsButtonClick = () => {
        dispatch(openModal(<ActivityLogsModal project_id={projectDetails?._id} defaultLogs={projectDetails?.activity_logs ?? []} setProjectLogs={(resp) => setProjectDetails({ ...projectDetails, activity_logs: resp })} />, 'sm', undefined, MODAL_KEYS.ACTIVITY_LOGS_LIST))
    }
    return <Box sx={{ width: "100%" }} >

        <Grid container spacing={1} >
            <Grid item xs={6} >
                <CustomRoundedButtons onClick={onNotesClick}>
                    Notes
                </CustomRoundedButtons>
            </Grid>
            {moduleAccessCondition(user, [MODULES.VIEW_COMPLAINS], []) && <Grid item xs={6} >
                {<CustomRoundedButtons onClick={onComplainButtonClick} >
                    Complains
                </CustomRoundedButtons>}
            </Grid>}
            {moduleAccessCondition(user, [MODULES.PROJECT_INSTALLATION_ACTIONS,MODULES.PROJECT_ADMIN], []) &&  <Grid item xs={6} >
                <CustomRoundedButtons onClick={onOrderButtonClick} >
                    Orders
                </CustomRoundedButtons>
            </Grid>}

            <Grid item xs={6} >
                <CustomRoundedButtons onClick={onSystemsButtonClick} >
                    Systems
                </CustomRoundedButtons>
            </Grid>
           
            {moduleAccessCondition(user, [MODULES.HISTORY_LOGS], []) && <Grid item xs={6} >
                <CustomRoundedButtons onClick={onLogsButtonClick} >
                    Logs
                </CustomRoundedButtons>
            </Grid>}
            {moduleAccessCondition(user, [MODULES.ACTIVITY_LOGS_VIEW], []) && <Grid item xs={6} >
                <CustomRoundedButtons onClick={onActivityLogsButtonClick} >
                    Activity Logs
                </CustomRoundedButtons>
            </Grid>}
        </Grid>
    </Box>
}
export default ProjectViewMoreButtons