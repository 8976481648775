import { MenuItem } from "@mui/material"

import AsyncDropDown from "../inputs/AsyncDropDown"
import { StyledSearchBar } from "../inputs/SearchBar"
import { useSelector } from "react-redux"
import { moduleAccessCondition } from "../../utils/helpers/helper"
import MODULES from "../../utils/constants/module.constants"
import { getUserApi } from "../../apis/user.api"
import { USER_ROLES } from "../../utils/constants/constants"

const SalesPersonFilterDropDown = ({ titleComponent, filters, setFilters, returnFullObj, filtersKey = "assigned_sales_person", ...props }) => {

    const { user } = useSelector(state => state)

    if (!moduleAccessCondition(user, [], Object.values(USER_ROLES).filter((item) => item != USER_ROLES.sales)))
        return <></>
    return <>
        {titleComponent}
        <AsyncDropDown

            margin={0}

            InputComponent={(props) => <StyledSearchBar placeholder="Select Sales Person"  {...props} margin="none" size="small" />}
            lazyFun={async (params) => { return await getUserApi({ ...params, role: USER_ROLES.sales, team: filters.team?._id ?? filters.team }) }}

            OptionComponent={({ option, ...rest }) => {
                return <MenuItem {...rest}>{option.name}</MenuItem>
            }}
            onChange={async (changedVal) => {

                setFilters({ ...filters, [filtersKey]: changedVal ? (returnFullObj ? changedVal : changedVal._id) : null })
            }}
            titleKey={'name'}
            valueKey={"_id"}
            {...props}
        />
    </>

}

export default SalesPersonFilterDropDown