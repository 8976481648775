import {
  Autocomplete,
  Button,
  ButtonGroup,
  MenuItem,
  Paper,
  Tab,
  Tabs,
  Typography,
  styled,
} from "@mui/material";
import { Box } from "@mui/system";
import DataTable from "../../components/tables/DataTable";
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxInDesktop";
import AsyncSearchBar from "../../components/inputs/AsynSearchBar";


const FilterTitleBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));
const FiltersBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",

  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const ListUi = ({
  title,
  filters,
  exportLoading,
  setFilters,
  list,
  modal,
  loading,
  onCreateBtnClick,
  onExportBtnClick,
  columns,
}) => {

  return (
    <>
      {/* {!modal && <Box mb={2}>
                <Paper elevation={0} sx={{ width: "100%", p: 2 }}  >
                   
                </Paper>
            </Box>} */}
      <Box mb={3}>
        <Paper elevation={0} sx={{ width: "100%", padding: modal ? 0 : 2 }}>
          {
            <Box mb={2} p={2}>
              {!modal && (
                <>
                  <Box p={2}>
                    {
                      <FilterTitleBox>
                        <Typography variant="h5" mb={2}>
                          {title}
                        </Typography>
                      </FilterTitleBox>
                    }
                          </Box>
                    {/* <Box sx={{display:"flex",justifyContent:"space-between"}}>
                    <FiltersBox mt={1}>
                      <PaddingBoxInDesktop sx={{ display: "flex",justifyContent:"flex-start" }}>
                        <LeadTimeFilters
                          filters={filters}
                          setFilters={setFilters}
                          forPannel
                        />
                      </PaddingBoxInDesktop>
                    </FiltersBox> */}
                  {/* <FilterTitleBox p={2} pb={0}>
                                <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1 }}  >


                                    <TimeRangeSelector placeHolder="Last Updated Date" onChange={(newRange) => { setFilters({ ...filters, lastUpdateStartDate: newRange.startDate, LastUpdateEndDate: newRange.endDate }) }} />

                                </PaddingBoxInDesktop>
                                <PaddingBoxInDesktop pl={3} mb={2} sx={{ display: "flex", flex: 1 }}  >


                                    <TimeRangeSelector placeHolder="Created Date" onChange={(newRange) => { setFilters({ ...filters, ...newRange }) }} />

                                </PaddingBoxInDesktop>
                            </FilterTitleBox> */}
              <FiltersBox sx={{ display: "flex", width: "100%" }}>
                {/* <Box  >
                                {(user.data.role == USER_ROLES.admin || user.data.role == USER_ROLES.sub_admin || user.data.role == USER_ROLES.partner_admin) && <SubmitButton loading={exportLoading} variant="contained" onClick={onExportBtnClick} title={"Export CSV"} />}
                            </Box> */}
                {/* <PaddingBoxInDesktop
                  sx={{ display: "flex", justifyContent: "flex-end", flex: 1 }}
                ></PaddingBoxInDesktop> */}
                <PaddingBoxInDesktop
                //   sx={{ display: "flex", flex: 1, maxWidth: "400px" }}
                  pl={3}
                >
                  <AsyncSearchBar
                    fullWidth
                    title="Search Lead "
                    size="small"
                    placeholder={"Search Lead"}
                    defaultValue={filters.search}
                    onChange={(changedVal) => {
                      setFilters({ ...filters, search: changedVal });
                    }}
                  />
                  {/* <StyledSearchBar fullWidth title="Search by Name | Email | Phone " size="small" placeholder={"Search Name | Email | Phone"} value={filters.search} onChange={(e) => { setFilters({ ...filters, search: e.target.value }) }} /> */}
                </PaddingBoxInDesktop>
              </FiltersBox>
              {/* </Box> */}
                </>
              )}
            </Box>
          }

          <Box sx={{ minHeight: "300px" }} p={2} pt={0}>
            <DataTable
              columns={columns}
              rows={list.result ? list.result : []}
              count={list.total ?? 0}
              filters={filters}
              setFilters={setFilters}
              loading={loading}
            />
          </Box>
        </Paper>
      </Box>
    </>
  );
};
export default ListUi;
