import { useCallback, useEffect, useState } from "react"

import { useDispatch, useSelector } from "react-redux"
import { callApiAction } from "../../../store/actions/commonAction"
import {  getLeadAnalyticsByStateApi, getLeadAnalytisByStateApi } from "../../../apis/lead.api"
import LeadAnalyticsByStateUi from "./LeadAnalyticsByStateUi"
import moment from "moment"


const LeadAnalyticsByStateController = () => {

    const dispatch = useDispatch()
    const fetchAPi = getLeadAnalyticsByStateApi
    const [loading, setLoading] = useState(false)
    const [list, setList] = useState({})

    const [filters, setFilters] = useState({
        dateField: 'createdAt',
        dateTitle: 'this_month',
        startDate: moment().startOf('month').valueOf(),
        endDate: moment().endOf('month').valueOf(),
        state:null

    })
    const fetchDetails = useCallback(() => {
        const filtersToBePassed = {}

        for (let item in { ...filters }) {
            if (filters[item] && typeof filters[item] == "object" && filters[item]?._id) {
                filtersToBePassed[item] = filters[item]?._id
            } else if (filters[item]) {
                filtersToBePassed[item] = filters[item]
            }
        }
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchAPi(filtersToBePassed),
            (response) => {
                setList(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }, [filters])
    useEffect(() => { fetchDetails() }, [filters])
    return <LeadAnalyticsByStateUi list={list} loading={loading} filters={filters} setFilters={setFilters} />
}
export default LeadAnalyticsByStateController