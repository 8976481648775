import { Alert, Button, ButtonBase, ButtonGroup, Chip, CircularProgress, Grid, IconButton, Pagination, Paper, Tooltip, Typography, styled } from "@mui/material"
import { Box } from "@mui/system"
import { useDispatch } from "react-redux"

import { memo, useState } from "react"
import { CenteredBox } from "../../../components/layouts/OneViewBox"

import { callApiAction } from "../../../store/actions/commonAction"
import CustomDialog from "../../../components/layouts/common/CustomDialog"
import { addOrRemovePipelineUsers } from "../../../apis/pipeline.api"
import { closeModal, openModal } from "../../../store/actions/modalAction"
import MessageDilog from "../../../components/MessageDilog"
import AsynSearchBar from "../../../components/inputs/AsynSearchBar"
import { RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material"




const UserItems = memo(({ _id, pipeline_id, name, pipelineusers }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [params, setParams] = useState({
        name,
        isSelected: pipelineusers ? true : false
    })


    const actionFun = async () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await addOrRemovePipelineUsers({ pipeline_id, user_id: _id, action: params?.isSelected ? 'delete' : 'add' }),
            (response) => {
                setLoading(false)
                setParams({ ...params, isSelected: !params.isSelected })
            },
            (err) => {
                setLoading(false)
            }
        ))
        dispatch(closeModal())
    }
    const onBtnClick = () => {
        dispatch(openModal(<MessageDilog onSubmit={actionFun} title="Alert!" message={`Are you sure to ${params.isSelected ? "remove" : "add"} "${name}" ?`} />, "sm"))
    }


    return <Grid item xs={6} sm={3} >
        <ButtonBase sx={{ width: "100%" }} onClick={onBtnClick} disabled={loading}>
            <Paper display={"flex"} alignItems="center" sx={{ width: "100%", borderColor: params.isSelected ? "primary.main" : "divider" }} variant="outlined" component={Box} p={2} >
                <Typography mr={2} lineHeight="100%" >
                    {params?.isSelected ? <RadioButtonChecked fontSize="inherit" color="primary" /> : <RadioButtonUnchecked fontSize="inherit" color="primary" />}
                </Typography>
                <Typography variant="body2" >
                    {name}
                </Typography>
            </Paper>
        </ButtonBase>
    </Grid>


})


const ListPipelineUsersUi = ({ title, list, filters, setFilters, loading, modalKey }) => {


    return (
        <>
            <CustomDialog
                id={modalKey}
                title={title}
                loading={loading}
            >
                <Alert severity="warning">
                    When you allocate user to pipeline, only that pipelines will be visible to that user.
                    otherwise for sales user only default pipeline will be visible and for other users all active pipeline will be visible
                </Alert>
                <Paper component={Box} p={2} mt={2} >
                    <Box  >
                        <AsynSearchBar

                            fullWidth
                            title="Search Name " size="small" placeholder={"Search Name "}
                            defaultValue={filters.search}
                            onChange={(changedVal) => { setFilters({ ...filters, search: changedVal }) }}
                        />
                    </Box>

                    <Box sx={{ minHeight: "300px" }} p={2} mt={2}>
                        {loading && (
                            <CenteredBox>
                                <CircularProgress />
                            </CenteredBox>
                        )}
                        {!loading &&
                            list &&
                            list?.result?.length > 0 &&
                            <Grid container spacing={2} >
                                {list?.result.map((item, index) => {
                                    return (
                                        <UserItems key={item?._id} pipeline_id={filters?.pipeline_id} {...item} />
                                    );
                                })}

                            </Grid>}
                    </Box>
                    <Box>
                        <Pagination
                            page={filters.pageNo}
                            count={Math.ceil((list?.total ?? 0) / filters.pageSize)}
                            onChange={(e, val) => { setFilters({ ...filters, pageNo: val }) }}
                        >

                        </Pagination>
                    </Box>
                </Paper>
            </CustomDialog>


        </>
    )
}
export default ListPipelineUsersUi