import { useTheme } from "@mui/material";
import { USER_ROLES } from "../constants/constants";
import { PROJECT_FLAGS, PROJECT_PARENT_STATUS, PROJECT_STATUS } from "../constants/project.constant"

export const useProjectStatusColor = () => {
  const theme = useTheme()
  return (currentStatus) => {

    switch (parseInt(currentStatus)) {

      case PROJECT_STATUS.NEW: return {
        bgColor: theme.palette.projectStatusCodes.initial,
        color: theme.palette.light.main
      };
     
      case PROJECT_STATUS.UNDER_PRE_APPROVAL: return {
        bgColor: theme.palette.projectStatusCodes.underpre,
        color: theme.palette.dark.main
      };
      case PROJECT_STATUS.PRE_APPROVAL_COMPLETED: return {
        bgColor: theme.palette.projectStatusCodes.completedpre,
        color: theme.palette.light.main
      };

      case PROJECT_STATUS.UNDER_INSTALLATION: return {
        bgColor: theme.palette.projectStatusCodes.underinst,
        color: theme.palette.dark.main
      };

      case PROJECT_STATUS.INSTALLATION_COMPLETED: return {
        bgColor: theme.palette.projectStatusCodes.completedinst,
        color: theme.palette.light.main
      };
      case PROJECT_STATUS.UNDER_POST_INSTALLATION: return {
        bgColor: theme.palette.projectStatusCodes.underpost,
        color: theme.palette.dark.main
      };
      case PROJECT_STATUS.POST_INSTALLATION_COMPLETED: return {
        bgColor: theme.palette.projectStatusCodes.completedpost,
        color: theme.palette.light.main
      };
      case PROJECT_STATUS.COMPLETED: return {
        bgColor: theme.palette.success.main,
        color: theme.palette.light.main
      };
      case PROJECT_STATUS.REJECTED: return {
        bgColor: theme.palette.error.light,
        color: theme.palette.light.main
      };
     
      case PROJECT_STATUS.REJECTED_AND_VERIFIED: return {
        bgColor: theme.palette.error.main,
        color: theme.palette.light.main
      };
      default: return {
        bgColor: theme.palette.primary.main,
        color: theme.palette.light.main
      };
    }
  }
}

export const allowedProjectStatusDropDownList = (role) => {

  if (role == USER_ROLES.admin || role == USER_ROLES.sub_admin || role == USER_ROLES.partner_admin || role == USER_ROLES.sales || role == USER_ROLES.tl) {
    return [
      {
        title: "Leads",
        defaultStatus: null,
        value: PROJECT_PARENT_STATUS.LEADS
      },
      {
        title: "Pre Approval",
        defaultStatus: null,
        value: PROJECT_PARENT_STATUS.PRE_APPROVALS
      },
      {
        title: "Installation",
        defaultStatus: null,
        value: PROJECT_PARENT_STATUS.INSTALLATION
      },
      {
        title: "Post Installation",
        defaultStatus: null,
        value: PROJECT_PARENT_STATUS.POST_INSTALLATION
      },
      {
        title: "Completed",
        defaultStatus: PROJECT_STATUS.COMPLETED,
        value: PROJECT_PARENT_STATUS.COMPLETED
      },
      {
        title: "Rejected",
        defaultStatus: null,
        value: PROJECT_PARENT_STATUS.REJECTED
      }

    ]
  }

  if (role == USER_ROLES.installer) {
    return [
      {
        title: "Installation",
        defaultStatus: null,
        value: PROJECT_PARENT_STATUS.INSTALLATION
      },
      {
        title: "Post Installation",
        defaultStatus: null,
        value: PROJECT_PARENT_STATUS.POST_INSTALLATION
      },
      {
        title: "Completed",
        defaultStatus: PROJECT_STATUS.COMPLETED,
        value: PROJECT_PARENT_STATUS.COMPLETED
      }
    ]
  }

  

  return []
}



export const fetchAllowedSubTab = (tab) => {

  switch (tab) {
    case PROJECT_PARENT_STATUS.LEADS: return [
      {
        title: "All",
        value: null
      },
      {
        title: "New",
        value: PROJECT_STATUS.NEW
      },

      {
        title: "In Discission",
        value: PROJECT_STATUS.IN_DISCUSSION
      }
    ]
    case PROJECT_PARENT_STATUS.PRE_APPROVALS: return [
      {
        title: "All",
        value: null
      },
      {
        title: "Under Pre Approval",
        value: PROJECT_STATUS.UNDER_PRE_APPROVAL
      },

      {
        title: "Pre Approval Completed",
        value: PROJECT_STATUS.PRE_APPROVAL_COMPLETED
      }
    ];



    case PROJECT_PARENT_STATUS.INSTALLATION: return [
      {
        title: "All",
        value: null
      },
      {
        title: "Under Installlation",
        value: PROJECT_STATUS.UNDER_INSTALLATION
      },

      {
        title: "Installation Completed",
        value: PROJECT_STATUS.INSTALLATION_COMPLETED
      }
    ];

    case PROJECT_PARENT_STATUS.POST_INSTALLATION: return [
      {
        title: "All",
        value: null
      },
      {
        title: "Under Post Installlation",
        value: PROJECT_STATUS.UNDER_POST_INSTALLATION
      },

      {
        title: "Post Installation Completed",
        value: PROJECT_STATUS.POST_INSTALLATION_COMPLETED
      }
    ];

    case PROJECT_PARENT_STATUS.REJECTED: return [
      {
        title: "All",
        value: null
      },
      {
        title: "Rejected",
        value: PROJECT_STATUS.REJECTED
      },

      {
        title: "Rejected & Verified",
        value: PROJECT_STATUS.REJECTED_AND_VERIFIED
      },
      {
        title: "Junk",
        value: PROJECT_STATUS.JUNK
      }
    ];


    default: return null
  }

}

const getFlagsUnderProjectStatus = (status) => {
  // switch (flag) {
  //     case PROJECT_STATUS.UNDER_PRE_APPROVAL: return []

  //     default: []
  // }
}
const getStatusOfProjectFlags = (flag) => {
  switch (flag) {
    case PROJECT_FLAGS.DEPOSIT: return {
      PENDING: 1,
      RECIEVED: 2
    }
    case PROJECT_FLAGS.GRID_PRE_APPROVAL: return {
      PENDING: 1,
      APPLIED: 2,
      APPROVED: 3,
      REJECTED: 4
    }
    case PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL: return {
      NOT_APPLICABLE: -1,
      PENDING: 1,
      APPLIED: 2,
      APPROVED: 3,
      REJECTED: 4
    }
    case PROJECT_FLAGS.SOLAR_VICTORIA_QR: return {
      PENDING: 1,
      SCANNED: 2

    }
    case PROJECT_FLAGS.PURCHASE_ORDER: return {
      PENDING: 1,
      CREATED: 2,
      RECIEVED: 3,

    }
    case PROJECT_FLAGS.INSTALLATION: return {
      PENDING: 1,
      SCHEDULED: 2,
      COMPLETED: 3

    }
    case PROJECT_FLAGS.CES_CERTI: return {
      PENDING: 1,
      RECIEVED: 2

    }
    case PROJECT_FLAGS.STC_CLAIMED: return {
      PENDING: 1,
      APPLIED: 2,
      CLAIMED: 3,
      APPROVED: 4

    }
    case PROJECT_FLAGS.SOLAR_VICTORIA_POST_APPROVAL: return {
      PENDING: 1,
      CLAIMED: 2,
      APPROVED: 3

    }
    case PROJECT_FLAGS.GRID_POST_APPROVAL: return {
      PENDING: 1,
      APPLIED: 2,
      COMPLETED: 3

    }
    default: return {}
  }
}
const getPreviousStatus = (status) => {

  switch (status) {

    default: return status - 1
  }
}
const calculateProfit = (items) => {
  const incomes = [
    items.customer,
    items.stc,
    items.solar_victoria,
    items.general_charge,
    items.extraa_charge,
  ]
  const expense = [
    items.app_and_delivery,
    items.loss,
    items.refund,
    items.electricians,
    items.suppliers,
  ]

  // const netProfit = grossProfit - Number(items.man_amount) - Number(items.sales_commision)
  return Number(Number(incomes.reduce((currVal, item) => currVal + Number(item ?? 0), 0) - expense.reduce((currVal, item) => currVal + Number(item ?? 0), 0)).toFixed(2))
}

export { getFlagsUnderProjectStatus, getStatusOfProjectFlags, calculateProfit, getPreviousStatus }