import { Paper, Typography, styled } from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import SubmitButton from "../../components/button/SubmitButton";
import DataTable from "../../components/tables/DataTable";
import AsynSearchBar from "../../components/inputs/AsynSearchBar";
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxInDesktop";
import { moduleAccessCondition } from "../../utils/helpers/helper";
import MODULES from "../../utils/constants/module.constants";

const FilterTitleBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));
const FiltersBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",

  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const ListUi = ({
  title,
  filters,
  setFilters,
  list,
  roles,
  loading,
  onCreateBtnClick,
  columns,
}) => {
  const user = useSelector((state) => state.user);

  return (
    <>
      <Box mb={3}>
        <Paper elevation={2} sx={{ width: "100%", padding: 4 }}>
          <Box mb={4}>
            <FilterTitleBox>
              <Typography variant="h5" mb={2}>
                {title}
              </Typography>

              <Box mb={2}>
                {/* {moduleAccessCondition(user, [MODULES.CREATE_TEAMS], []) && ( */}
                  <SubmitButton
                    variant="contained"
                    onClick={onCreateBtnClick}
                    title={"Add Announcement"}
                  />
                {/* )} */}
              </Box>
            </FilterTitleBox>

            <FiltersBox mt={3}>
              <PaddingBoxInDesktop
                mb={2}
                sx={{ display: "flex", justifyContent: "flex-end" }}
                pl={3}
              >
                {/* <AsynSearchBar
                  fullWidth
                  title="Search By Team Name "
                  size="small"
                  placeholder={"Search By Team Name "}
                  defaultValue={filters.search}
                  onChange={(changedVal) => {
                    setFilters({ ...filters, search: changedVal });
                  }}
                /> */}
                {/* <StyledSearchBar  title="Search By Key Name " size="small" placeholder={"Search By key Name "} value={filters.search} onChange={(e) => { setFilters({ ...filters, search: e.target.value }) }} /> */}
              </PaddingBoxInDesktop>
            </FiltersBox>
          </Box>

          <Box sx={{ minHeight: "300px" }}>
            <DataTable
              columns={columns}
              rows={list.result ? list.result : []}
              count={list.total ?? 0}
              filters={filters}
              setFilters={setFilters}
              loading={loading}
            />
          </Box>
        </Paper>
      </Box>
    </>
  );
};
export default ListUi;
