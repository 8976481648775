import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { callApiAction } from "../../store/actions/commonAction"
import { getProjectCountsApi } from "../../apis/projects.api"
import { Box, Button, ButtonGroup, Grid, Skeleton } from "@mui/material"
import ClickButton from "../../components/button/ClickButton"

const ProjectCounts = ({ filters, setFilters }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState()
    const [data, setData] = useState([])
    const [subStatus, setSubStatus] = useState([])
    const fetchCounts = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await getProjectCountsApi({ ...filters }),
            (response) => {
                setData(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }
    useEffect(() => {
        fetchCounts()
    }, [filters.assigned_sales_person,filters.documents,filters.state,filters.assigned_installer,filters.type,filters.approvalStartDate,filters.approvalEndDate,filters.installationStartDate,filters.installationEndDate,filters.startDate,filters.endDate,filters.filterKey])

    if (loading)
        return <Box>
            <Grid container spacing={2} >
                {[1, 2, 3, 4, 5, 6, 7].map((item) => <Grid key={item?.val} item xs={12 / 7} ><Skeleton sx={{ height: "80px" }} variant="rounded" /></Grid>)}

            </Grid>
        </Box>
    return <>
        <Grid container spacing={2} >
            {data?.map((item) => <Grid key={item?.val} item xs={4} md={12 / data?.length} >
                <ClickButton onClick={() => {
                    setSubStatus(
                        item.subStatus?.length > 0 ?
                            [{ count: item?.count, title: "All", value: null }, ...item?.subStatus]

                            : [])
                    setFilters({ ...filters, parentStatus: item?.value, status: item?.defaultStatus })
                }}
                    active={filters?.parentStatus == item?.value} title={`${item?.count}`} midTitle={`(${item.kw_stc?.toFixed(2)}kw)`} subTitle={item?.title} variant="rounded" />
            </Grid>)}
        </Grid>
        {
            subStatus?.length > 0 && <Box mt={2} >

                
                <ButtonGroup size="small" disableElevation  >

                    {
                        subStatus.map((item) => {
                            return <Button onClick={() => setFilters({ ...filters, status: item?.value })} variant={filters?.status == item?.value ? "contained" : "outlined"} key={item?.value}>{item?.title} | {item?.count} | {item.kw_stc?.toFixed(2)}kw</Button>
                        })
                    }
                </ButtonGroup>

            </Box>
        }



    </>
}
export default ProjectCounts