

import { ButtonBase, CircularProgress, IconButton, Tooltip, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { memo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { openModal } from "../../../store/actions/modalAction"
import CreateController from "../invoice/CreateController"

import { INVOICE_TYPE, INVOICES_NAME } from "../../../utils/constants/invoice.constant"
import { moduleAccessCondition } from "../../../utils/helpers/helper"
import { Add, AddCircle, Share } from "@mui/icons-material"

import { useTheme } from "@mui/material"
import useInovice from "../invoice/useInvoice"
import { MODAL_KEYS } from "../../../utils/constants/modal.constant"
import { TitleBox } from "../../../components/layouts/common/boxes"
import InvoiceDetailsController from "../../invoice/detailedview/InvoiceDetailedViewController"
import { CenteredBox } from "../../../components/layouts/OneViewBox"
import { isInvoiceAllowed } from "../../../utils/helpers/invoice.helper"
import MODULES from "../../../utils/constants/module.constants"





const InvoiceRow = memo(({ invoice }) => {

    const { user } = useSelector(state => state)
    const theme = useTheme()
    const dispatch = useDispatch()
    const [invoiceRow, setInvoiceRows] = useState(invoice)

    const invoiceColor = useInovice(invoiceRow ?? {})

    const callBack = (project, fields, response) => {

        if (!fields)
            setInvoiceRows(null)
        else
            setInvoiceRows({ ...invoiceRow, ...fields })
    }

    const onClick = () => {
        dispatch(openModal(<InvoiceDetailsController id={invoice?._id} invoiceNo={invoiceRow?.invoice_no} type={invoiceRow?.type}

            callBack={callBack}
        />, 'sm', undefined, MODAL_KEYS.INVOICE_DETAILS))
    }
    if (!invoiceRow || !isInvoiceAllowed(user?.data?.modules ?? [], invoice?.type))
        return <></>

    return <>
        {invoiceRow?.type == INVOICE_TYPE.INCENTIVES_INVOICE && <Box p={0} sx={{ background: theme.palette.secondary.main, border: 1, borderBottom: 0, borderColor: "primary.main", borderTopLeftRadius: theme.shape.borderRadius }}>
            <Typography color="primary" variant="caption" display="block" align="center" fontFamily="Public Sans" fontWeight={600} >
                {invoiceRow?.incentive_type}
            </Typography>
        </Box >}
        <ButtonBase onClick={onClick} sx={{ width: "100%", borderRadius: 1, mb: 1, alignItems: "stretch", opacity: invoiceRow?.disabled ? 0.5 : 1 }} component={Box}  >
            <Box display="flex" flex="1" p={1} pl={3} sx={{ background: theme.palette.secondary.main, border: 1, borderColor: "primary.main", borderTopLeftRadius: invoiceRow?.type == INVOICE_TYPE.INCENTIVES_INVOICE ? "" : theme.shape.borderRadius, borderBottomLeftRadius: theme.shape.borderRadius }}>
                <Typography color="primary" variant="caption" fontFamily="Public Sans" fontWeight={600} >
                    {INVOICES_NAME[invoiceRow?.type]}
                </Typography>
            </Box >
            <Box display="flex" flex="2" p={1} pl={2} sx={{ border: 1, borderLeft: 0, borderColor: invoiceColor.bg, position: "relative" }} alignItems="center" >

                <Box sx={{ position: "absolute", top: "0px", left: "0px", height: "100%", width: "100%", backgroundColor: invoiceColor.bg, opacity: 0.1 }} />
                <Typography color={invoiceColor.color} variant="body2" >
                    {invoiceRow?.paid_amount} / {invoiceRow.amount}
                </Typography>
            </Box>

        </ButtonBase>
    </>

}
)
const InvoiceTitleComponent = ({ data, setData, projectId, projectStatus, viewOnly }) => {
    const { user } = useSelector(state => state)
    const dispatch = useDispatch()

    const onCreateBtnClick = () => {
        dispatch(openModal(<CreateController project={projectId} project_status={projectStatus} callBack={(projectId, updatedFields, response) => {
            setData({ ...data, invoices: [...data.invoices, response] })
        }} />, 'sm', undefined, MODAL_KEYS.CREATE_INVOICE))
    }
    return <Box display="flex" justifyContent="space-between" alignItems={"center"}>
        <Typography lineHeight="100%">
            Invoices
        </Typography>
        {moduleAccessCondition(user, [MODULES.CREATE_INVOICE]) && <Tooltip arrow title="Add Invoice" >
            <IconButton sx={{ padding: 0 }} onClick={onCreateBtnClick}>
                <Typography variant="subtitle1" lineHeight="100%">
                    <AddCircle fontSize="inherit" color="primary" />
                </Typography>
            </IconButton>
        </Tooltip>}
    </Box>
}
const ProjectInvoicesUi = ({
    data, setData, viewOnly
    // invoices, project, callBack, project_status, id 


}) => {

    const { invoices } = data

    return <TitleBox title={<InvoiceTitleComponent viewOnly={viewOnly} projectId={data?._id} setData={setData} data={data} projectStatus={data?.status} />} >
        <Box sx={{ minHeight: "100%" }}>
            {
                invoices && Array.isArray(invoices) && invoices.length > 0 ?
                    <>
                        {[...invoices?.filter(item => item?.type != INVOICE_TYPE.INCENTIVES_INVOICE)?.map(item => ({
                            ...item,
                            name: INVOICES_NAME[item?.type] ?? ""
                        }))?.sort((a, b) => {
                            return a.name?.localeCompare?.(b.name)
                        })]?.map((invoice, index) => (
                            <InvoiceRow key={invoice._id} invoice={invoice} project={data?.id} />

                        ))}


{
    invoices?.find((item)=>item.type==INVOICE_TYPE.INCENTIVES_INVOICE) && <Typography variant="caption" fontWeight={600} >Incentives:</Typography>
}
                        {[...invoices?.filter(item => item?.type == INVOICE_TYPE.INCENTIVES_INVOICE)?.map(item => ({
                            ...item,
                            name: INVOICES_NAME[item?.type] ?? ""
                        }))?.sort((a, b) => {
                            return a.name?.localeCompare?.(b.name)
                        })]?.map((invoice, index) => (
                            <InvoiceRow key={invoice._id} invoice={invoice} project={data?.id} />

                        ))}
                    </>
                    :
                    <CenteredBox flex={1} sx={{ height: "100%" }}>
                        <Typography variant="body2" color="grey" align="center">No invoices Attached</Typography>
                    </CenteredBox>
            }

        </Box>
    </TitleBox>
    


}
export default memo(ProjectInvoicesUi)