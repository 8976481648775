import {
  Badge,
  Box,
  Grid,
  Link,
  ListItemButton,
  Typography,
  
} from "@mui/material";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAnnouncementAction,
} from "../../store/actions/announcementAction";
import {  openModal } from "../../store/actions/modalAction";
import AnnouncementModal from "./AnnouncementModal";
import DashBox from "./Dashbox";

import AllAnnouncementShowController from "./AllAnnouncemntShowController";



function DashboardAnnouncement() {
  const [Loading, setLoading] = useState(true);
  
  const dispatch = useDispatch();
  const { announcements } = useSelector((state) => state);
  
  const [ids, setIds] = useState([]);
  const [storedIds, setStoredIds] = useState([]);


  const handleClick = (announcement) => {
    

    const updatedIds = [...storedIds, announcement._id];
    setIds(updatedIds);

    const stored = JSON.parse(localStorage.getItem("announcementIds") || "[]");

    if (!stored.includes(announcement._id)) {
      localStorage.setItem("announcementIds", JSON.stringify(updatedIds));
      
    }

    dispatch(
      openModal(
        <AnnouncementModal announcement={announcement} />,
        "md",
        undefined,
        "announcement"
      )
    );
  };
  useEffect(() => {
    setLoading(false);
    dispatch(fetchAnnouncementAction({ pageSize: 3 }));
    const retrievedIds = JSON.parse(
      localStorage.getItem("announcementIds") || "[]"
    );
    setStoredIds(retrievedIds);
  }, []);

  

  const onViewMoreAnnouncement = () => {
    dispatch(
      openModal(<AllAnnouncementShowController />, "sm", undefined, "view-more")
    );
  };
  
  return (
    <DashBox title={"Latest Announcements"} onViewMoreClick={onViewMoreAnnouncement}>
     
      {announcements.data.length === 0 ? (
        <Grid container spacing={2} p={4}>
          <Typography variant="body1" color="text.primary" align="center">
            No New Announcements Yet.
          </Typography>
        </Grid>
      ) : (
        <>
          {!Loading &&
            announcements &&
            announcements.data &&
            Array.isArray(announcements.data) &&
            announcements.data.map((announcement) => (
              <Box
                sx={{
                  display: "flex",
                  bgcolor: "#F5F5F5",
                  borderRadius: "10px",
                  borderColor: "lightgrey",
                  margin: 3,
                  alignItems: "center",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <Link
                    sx={{ textDecoration: "none" }}
                    onClick={() => handleClick(announcement)}
                    key={announcement._id}
                  >
                    <ListItemButton
                      disableTouchRipple
                      sx={{}}
                      key={announcement._id}
                    >
                      <Box
                        p={1}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Badge
                          color="error"
                          overlap="circular"
                          badgeContent=""
                          variant="dot"
                          invisible={
                            storedIds.includes(announcement._id) ? true : false
                          }
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        >
                          <Box
                            sx={{
                              backgroundColor: "#ccc",
                              width: 45,
                              height: 45,
                              borderRadius: "50%",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              p: 1.5,
                              // mr: 2,
                            }}
                          >
                            <Typography variant="body2" sx={{}}>
                              {" "}
                              {moment(announcement.announcement_date).format(
                                "DD"
                              )}
                            </Typography>

                            <Typography variant="caption" lineHeight={"100%"}>
                              {" "}
                              {moment(announcement.announcement_date).format(
                                "MMM"
                              )}
                            </Typography>
                          </Box>
                        </Badge>
                      </Box>

                      <Box
                        sx={{
                          width: "70%",
                          ml: 2,

                          flexDirection: "row",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          color="text.200"
                          sx={{ flexGrow: 1, width: "100%" }}
                        >
                          {announcement.announcement_title.toUpperCase()}
                        </Typography>
                        <Typography
                          variant="caption"
                          color="text.200"
                          sx={{ justifySelf: "flex-end" }}
                        >
                          {"" +
                            moment(announcement.announcement_date).fromNow(
                              true
                            ) +
                            " Ago"}
                        </Typography>
                      </Box>
                      <Box variant="p" sx={{ justifySelf: "flex-end" }}></Box>
                    </ListItemButton>
                  </Link>
                </Box>
              </Box>
            ))}
        </>
      )}
    </DashBox>
  );
}

export default DashboardAnnouncement;
