import { useState } from "react"
import CustomDialog from "../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../components/layouts/OneViewBox"
import { Box, CircularProgress, Grid, IconButton, Paper, Typography } from "@mui/material"
import NoDataComponent from "../../components/layouts/NoDataComponent"
import { Call, Delete, Email, Language, Person } from "@mui/icons-material"
import { Link } from "react-router-dom"
import { TitleBox } from "../../components/layouts/common/boxes"
import { useDispatch } from "react-redux"
import { callApiAction } from "../../store/actions/commonAction"
import { deleteEnergyPartnerPostCodeRangeApi } from "../../apis/energy-partners.api"
import MessageDilog from "../../components/MessageDilog"
import { closeModal, openModal } from "../../store/actions/modalAction"
import AddPostCodeRangeButton from "./postcode-range/AddPostCodeRangeButton"

const RangeItem = ({ start, end, id }) => {
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)
    const [params, setParams] = useState({
        start,
        end, id
    })
    const deleteFun = async () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await deleteEnergyPartnerPostCodeRangeApi({ id }),
            (response) => {
                setParams({})
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
        dispatch(closeModal())
    }


    const onDelete = () => {
        dispatch(openModal(<MessageDilog onSubmit={deleteFun} title="Alert!" message={`Are you sure to delete range ?`} />, "sm"))
    }
    if (Object.keys(params).length == 0)
        return <></>
    return <Box component={Paper} variant="outlined" p={2} mb={2}>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={1} >

            <Typography variant="body1" lineHeight="100%" > {params.start} - {params.end}</Typography>

            <Box>
                {loading ? <CircularProgress /> : <IconButton onClick={onDelete} size="small" >
                    <Delete fontSize="inherit" color="error" />
                </IconButton>}
            </Box>
        </Box>
    </Box>
}
const ViewItem = ({ title, Icon, value, linkUrl }) => {

    return <Box mb={2}>
        <Box display="flex" alignItems="center" mb={1} >
            <Typography variant="body1" mr={2} lineHeight="100%" ><Icon fontSize="inherit" /></Typography>
            <Typography variant="body1" > {title}:</Typography>
        </Box>
        <Box display="flex" alignItems="center" mb={1} >

            {!linkUrl && <Typography variant="body2" >{value ?? "NA"}</Typography>}
            {linkUrl && <a href={linkUrl} target="_blank" ><Typography variant="body2" color="primary" >{value ?? "NA"}</Typography></a>}
        </Box>
    </Box>
}
const DetailedViewUi = ({ id, title, modalKey, data, setData, loading }) => {




    return <CustomDialog
        id={modalKey}
        title={title}

    >
        {
            loading && <CenteredBox>
                <CircularProgress />
            </CenteredBox>
        }
        {
            !loading && data && <Box>
                <Grid container spacing={2} >
                    <Grid item xs={6} >
                        <TitleBox title="Company Details" >
                            <ViewItem
                                title="Name"
                                Icon={Person}
                                value={data?.name}

                            />
                            <ViewItem
                                title="Website"
                                Icon={Language}
                                value={data?.website}
                                linkUrl={data?.website ?? undefined}
                            />
                            <ViewItem
                                title="Phone"
                                Icon={Call}
                                value={data?.phone}
                                linkUrl={data?.phone ? "tel:" + data?.phone : undefined}
                            />
                            <ViewItem
                                title="Email"
                                Icon={Email}
                                value={data?.email}
                                linkUrl={data?.email ? "mailto:" + data?.email : undefined}
                            />

                        </TitleBox>
                    </Grid>
                    <Grid item xs={6} >
                        <TitleBox title={
                            <Box display="flex" justifyContent="space-between">

                                <Typography lineHeight="100%" fontSize="inherit" >
                                Postcode Ranges
                                </Typography>
                                <AddPostCodeRangeButton data={data} setData={setData} />
                            </Box>
                        }  >
                            {
                                data?.postcode_ranges?.map((item) => <RangeItem key={item?._id} id={item?._id} start={item?.start} end={item?.end} />)
                            }
                        </TitleBox>
                    </Grid>
                </Grid>


            </Box>
        }
        {
            !loading && !data && <CenteredBox>
                <NoDataComponent title="Data Not Found" variant="body1" />
            </CenteredBox>
        }
    </CustomDialog>
}
export default DetailedViewUi