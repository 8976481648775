import { memo, useMemo, useState } from "react"
import useValidate from "../../../../store/hooks/useValidator"

import { useDispatch } from 'react-redux'
import { callApiAction } from "../../../../store/actions/commonAction"
import { closeModal, openModal } from "../../../../store/actions/modalAction"


import { updateLeadContactsApi, updateLeadSourceApi } from "../../../../apis/lead.api"

import LeadUpdateContactDetailsUi from "./LeadUpdateContactDetailsUi"
import { callSnackBar } from "../../../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../../../utils/constants/constants"
import { MODAL_KEYS } from "../../../../utils/constants/modal.constant"
import { validateEmail, validatePhoneNumber } from "../../../../utils/helpers/helper"
import DuplicateLeadComponent from "../../duplicate/DuplicateLeadComponent"
const customerObj =    {
    f_name: '',
    l_name: '',
    m_name: '',
    dob: null,
    phone: '',
    email: '',
    customer_id: null,
    customer_uid: null
}
const LeadUpdateContactDetailsController = ({ callBack = () => { }, id, contacts = null }) => {
    const validate = useValidate()
    const dispatch = useDispatch()

    const title = "Update Contacts"
    const modalKey = MODAL_KEYS.LEAD_UPDATE_MODAL

    const updateApi = updateLeadContactsApi

    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',
        id,
        customerDetails: contacts?.[0] ?? customerObj,
        partnerDetails: contacts?.[1] ?? customerObj,

    })



    const updateValidationSchema = useMemo(() => ([
        {
            value: fields.customerDetails,
            field: 'Customer Details',
            custom: () => {

                if (!fields.customerDetails.f_name || fields.customerDetails.f_name == '')
                    return "Customer first name is required."
                /* checking phone no */
                if ((!fields.customerDetails.email || fields.customerDetails.email?.trim() == '') && (!fields.customerDetails.phone || fields.customerDetails.phone?.trim() == '')) {
                    return "Enter atleast one field from email and phone"
                }
                if (fields.customerDetails.email != '' && !validateEmail(fields.customerDetails.email)) {
                    return "Email is invalid."
                }
                if (fields.customerDetails.phone && fields.customerDetails.phone.trim() != '') {
                    if (!validatePhoneNumber(fields.customerDetails.phone)) {
                        return "Phone no should be 10 letters & starts with 0"
                    }
                }
                return true
            }
        },

        {
            value: fields.partnerDetails,
            field: 'Partner Details',
            custom: () => {

                let partnerHasDetails = false
                for (let item in fields.partnerDetails) {
                    if (fields.partnerDetails[item] && fields.partnerDetails[item]?.trim?.() != '') {
                        partnerHasDetails = true
                        break;
                    }
                }
                if (partnerHasDetails) {

                    if (!fields.partnerDetails.f_name || fields.partnerDetails.f_name == '')
                        return "Customer first name is required."

                    if ((!fields.partnerDetails.email || fields.partnerDetails.email?.trim() == '') && (!fields.partnerDetails.phone || fields.partnerDetails.phone?.trim() == '')) {
                        return "Enter atleast one field from email and phone for partner"
                    }
                    if (fields.partnerDetails.email != '' && !validateEmail(fields.partnerDetails.email)) {
                        return "Partner email is invalid."
                    }
                    if (fields.partnerDetails.phone && fields.partnerDetails.phone.trim() != '') {
                        if (!validatePhoneNumber(fields.partnerDetails.phone)) {
                            return "Partner phone no should be 10 letters & starts with 0"
                        }
                    }
                }
                return true
            }
        },
    ]), [fields])





    const updateFun = async () => {
        const validationResponse = validate(updateValidationSchema)

        if (validationResponse === true) {

            const dataToBeSend = {

            }
            const dataCopy = JSON.parse(JSON.stringify(fields))        
            const contacts = [
                dataCopy.customerDetails
            ]
            if (dataCopy.partnerDetails?.f_name && dataCopy.partnerDetails?.f_name != '') {
                contacts.push(dataCopy.partnerDetails)
            }

            dataToBeSend['contacts'] = contacts
            delete dataCopy['customerDetails']
            delete dataCopy['partnerDetails']

            for(let item in dataCopy){
                if(typeof dataCopy[item]==="object" && !Array.isArray(dataCopy[item])){
                    dataToBeSend[item] =  dataCopy[item]?._id
                }else
                dataToBeSend[item] =  dataCopy[item]
            }

            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await updateApi(dataToBeSend),
                    async (response) => {

                        await callBack(response)
                        setLoading(false)
                        dispatch(callSnackBar("contacts updated successfully", SNACK_BAR_VARIETNS.suceess))
                        dispatch(closeModal(modalKey))
                    },
                    (err, code, responseData) => {

                        setLoading(false)
                        setFields({ ...fields, err })

                        dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))

                        if (code === 406) {
                            // setAlreadyExistData(responseData)
                            dispatch(openModal(<DuplicateLeadComponent leads={responseData} onReassignLead={()=>{
                                dispatch(closeModal(modalKey))
                                dispatch(closeModal(MODAL_KEYS.DUPLICATE_LEAD))
                                callBack()
                            }}  />,'sm',true,MODAL_KEYS.DUPLICATE_LEAD))
                        } else {
                            // setAlreadyExistData([])
                        }
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }




    const onSubmit = async (e) => {
        e.preventDefault()
        updateFun()


    }




    return <LeadUpdateContactDetailsUi modalKey={modalKey} title={title} isUpdate={id} loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
}
export default memo(LeadUpdateContactDetailsController)