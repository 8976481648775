import axios from "axios";
import { getFileHeaders, getHeaders } from "../utils/helpers/helper";
import endpoints from "./endpoints";



export const emailListeningApi = async data =>
{
    const callResponse = await axios({
        url: endpoints.emailListening,
        method: "POST",
        headers: getHeaders(),

        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const getEmailIntegrationsApi = async params =>
{
    const callResponse = await axios({
        url: endpoints.emailIntegration,
        method: "get",
        headers: getHeaders(),
        params,

    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const deleteEmailIntegrationApi = async params =>
{
    const callResponse = await axios({
        url: endpoints.emailIntegration,
        method: "DELETE",
        headers: getHeaders(),
        params,

    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};


