import { Autocomplete, Box, Button, Checkbox, CircularProgress, Collapse, IconButton, MenuItem, Typography } from "@mui/material"
import { memo, useContext, useMemo, useState } from "react"
import { useSelector } from 'react-redux'

import CustomDialog from "../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../components/layouts/OneViewBox"


import modulesConfig from "../../config/modules.config"
import { ExpandMore, Remove } from "@mui/icons-material"
import { indentifyAndCollectModulesFromChildren, moduleAccessAction, ModuleAccessContext } from "./moduleStore"

const ModuleAccessComponent = memo(({ title, ismain, value, children, dependentOn, role, dispatchModules, selected, disabled }) => {

    const [collapsed, setCollapsed] = useState(true)

    return <Box pl={2} pr={2} sx={{ width: (children && children?.length > 0) ? "100%" : "unset", background: (children && children?.length > 0) ? "#f5f5f5" : "", borderLeft: 2, borderLeftColor: (children && !collapsed)?"divider":"transparent"}} my={1} >
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} >
            {children && children?.length > 0 && <IconButton onClick={() => setCollapsed(!collapsed)} >
                {collapsed ? <ExpandMore color="primary" /> : <Remove color="primary" />}
            </IconButton>
            }

            <Box sx={{ display: "flex", alignItems: "center", flex: 1, cursor: disabled ? "not-allowed" : "pointer" }} >
                <Checkbox

                    disabled={disabled}
                    onChange={(e) => dispatchModules(moduleAccessAction.selectModule(value, e.target.checked, role, children, dependentOn))}
                    checked={selected}
                />
                <Typography color={disabled && "lightgray"} fontWeight={(children && children?.length > 0) || ismain ? 600 : 500} variant="subtitle2" lineHeight={"100%"} >
                    {title}
                </Typography>
            </Box>

        </Box>


        {
            children && children?.length > 0 &&
            <Collapse in={!collapsed} >
                <Box sx={{ display: "flex", flex: 1, flexWrap: "wrap" }} pl={4} >
                    {children?.map((item, index) => <ModuleAccessItem dependentOn={item?.dependent_on} notAllowed={item?.not_allowed} key={title + "-" + value + "-" + index} role={role} dispatchModules={dispatchModules} title={item?.title} value={item.value} children={item?.children} />)}
                </Box>
            </Collapse>
        }
    </Box>
})
const ModuleAccessItem = memo(({ title, ismain, dependentOn, value, children, role, dispatchModules, notAllowed }) => {

    const modules = useContext(ModuleAccessContext)
    let selected = modules.includes(value)
    if (!value || children?.length > 0) {
        const subModules = indentifyAndCollectModulesFromChildren(role, children)
        let allExist = subModules.length > 0
        for (let subModule of subModules) {
            if (!modules?.includes(subModule)) {
                allExist = false
                break
            }
        }

        selected = allExist
    }


    return <ModuleAccessComponent
        role={role}
        dependentOn={dependentOn}
        disabled={notAllowed?.includes(role)}
        dispatchModules={dispatchModules}
        title={title}
        value={value}
        children={children}
        ismain={ismain}
        selected={selected}
    />
})
const UpdateModuleAccessUi = ({ title, modalKey, loading, dispatchModules, role, onSubmit }) => {



    const moduleConfiguration = useMemo(() => modulesConfig, [])

    return <>

        <CustomDialog
            id={modalKey}
            loading={loading}

            onSubmit={onSubmit}
            title={`${title}`}
            closeText="Close"
            confirmText={`Submit`}
        >

            <Box sx={{ display: "flex", flexDirection: "column", }} >
                {loading ? <CenteredBox><CircularProgress /></CenteredBox> :

                    <>
                        <Box display="flex" justifyContent="flex-end" >
                            <Button variant="contained" disableElevation onClick={() => dispatchModules(moduleAccessAction.resetModule(role))} >Reset To Default</Button>

                        </Box>
                        {
                            moduleConfiguration.map((moduleItem, index) => <ModuleAccessItem dependentOn={moduleItem?.dependent_on ?? []} notAllowed={moduleItem?.not_allowed} role={role} dispatchModules={dispatchModules} ismain key={moduleItem.title + "" + moduleItem.value + "-" + index} value={moduleItem.value} title={moduleItem.title} children={moduleItem?.children} />)
                        }

                    </>
                }
            </Box>
        </CustomDialog>


    </>
}
export default memo(UpdateModuleAccessUi)