import { memo, useMemo, useState } from "react"
import useValidate from "../../../../store/hooks/useValidator"
import AttchOpenSolarProjectUi from "./AttchOpenSolarProjectUi"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../../../store/actions/commonAction"
import { closeModal, openModal } from "../../../../store/actions/modalAction"
import { SNACK_BAR_VARIETNS } from "../../../../utils/constants/constants"
import { attachLeadWithOpenSolarApi } from "../../../../apis/lead.api"

import { callSnackBar } from "../../../../store/actions/snackbarAction"
import { MODAL_KEYS } from "../../../../utils/constants/modal.constant"
import { MenuItem } from "@mui/material"
import { Link } from "@mui/icons-material"

export const AttchOpenSolarProjectButton = ({ id,open_solar_id,callBack }) => {
    const dispatch = useDispatch()
    const onClick = () => {
      dispatch(openModal(
        <AttchOpenSolarProjectController
         id={id}
         open_solar_id={open_solar_id}
         callBack={callBack}
        />,
        "sm",
        undefined,
        MODAL_KEYS.LEAD_OPEN_SOLAR_MODAL
      ))
    }
  
    return <MenuItem onClick={onClick}>
  
      <Link fontSize='inherit' /> &nbsp;Attach with Existing</MenuItem>
  }
const AttchOpenSolarProjectController = ({ id, open_solar_id, callBack }) => {


    const modalKey = MODAL_KEYS.LEAD_OPEN_SOLAR_MODAL

    const { user } = useSelector(state => state)
    const validate = useValidate()
    const dispatch = useDispatch()


    const title = "Attach project with Opensolar"
    const createApi = attachLeadWithOpenSolarApi


    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',
        id,
        open_solar_id
    })





    const validationSchemaForCreate = useMemo(() => ([


        {
            required: true,
            value: fields.open_solar_id,
            field: 'Opensolar id',
        }
    ]), [fields])





    const createFunction = async () => {
        const validationResponse = validate(validationSchemaForCreate)

        if (validationResponse === true) {

            const dataToBeSend = {
                ...fields
            }
            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await createApi({ ...dataToBeSend }),
                    async (response) => {
                        await callBack(response)
                        setLoading(false)
                        dispatch(closeModal(modalKey))
                    },
                    (err, code, responseData) => {

                        setLoading(false)
                        setFields({ ...fields, err })

                        dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }





    const onSubmit = async (e) => {
        e.preventDefault()

        createFunction()

    }


    return <AttchOpenSolarProjectUi modalKey={modalKey} createFunction={createFunction}  title={title} loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
}
export default memo(AttchOpenSolarProjectController)