import { memo, useCallback, useEffect, useState } from "react"
import ListUi from "./ListUi"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../../store/actions/commonAction"
import {  getLeadSearchApi } from "../../../apis/lead.api"
import { useMemo } from "react"



const ListController = () => {
    const dispatch = useDispatch()



    const title = "Find Assigned Person"
    const fetchApi = getLeadSearchApi
    

    const columns = useMemo(() => [
        
        { id: 1, fieldName: 'customer_name', label: 'Customer Name', align: "left" ,renderValue:(params)=>params.customer_name+ " "+ params.customer_family_name},

        {
            id: 3, fieldName: 'customer_phone', minWidth: "200px", label: 'Customer Phone No.', align: "left"
        },

        {
            id: 5, fieldName: 'assigned_sales_person', minWidth: 150, label: 'Assigned To', align: "left", renderValue: (params) => params.assigned_sales_person && params.assigned_sales_person.length > 0 ?
            params.assigned_sales_person[0].name
                : "NA",
        },


    ], [dispatch]);


    const [filters, setFilters] = useState({
       
        sort: 'lead_updated_date',        
        sortDirection: -1

    })





    // do not change 

    const [loading, setLoading] = useState(false)
    
    const [list, setList] = useState([])









    const fetchList = () => {

        if (filters.search != '') {
            setLoading(true)
            dispatch(callApiAction(
                async () => await fetchApi({ ...filters }),
                (response) => {
                    setList(response)
                    setLoading(false)
                },
                (err) => {
                    setLoading(false)
                }
            ))
        }

    }




    

    useEffect(() => {
        fetchList()
    }, [filters])





    return (
        <>
            <ListUi
                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}
               


            />

        </>
    )
}
export default ListController