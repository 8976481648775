import axios from "axios";
import { getHeaders } from "../utils/helpers/helper";
import endpoints from "./endpoints";

export const getSupplierApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.supplierFetch,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const getSupplierByIdApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.supplierFetchByid,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const addSupplierApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.supplierBase,
    method: "POST",
    headers: getHeaders(),

    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const updateSupplierField = async (data) => {
  const callResponse = await axios({
    url: endpoints.supplierUpdate,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const deleteSupplierApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.supplierDelete,
    method: "DELETE",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const undoSupplierDeleteApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.supplierDeleteUndo,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const updateCompanySupplier = async (data) => {
  const callResponse = await axios({
    url: endpoints.isCompanySupplier,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export default getSupplierApi;
