import { Box, Grid, MenuItem, Paper, Skeleton, Typography, useMediaQuery, useTheme } from "@mui/material"
import { toTitleCase } from "../../../utils/helpers/helper"
import { USER_ROLES } from "../../../utils/constants/constants"
import PaddingBoxInDesktop from "../../../components/layouts/PaddingBoxInDesktop"
import { Pie } from "react-chartjs-2"
import { Chart, ArcElement } from 'chart.js'
import TimeRangeSelector from "../../../components/layouts/common/TimeRangeSelector"
import { useSelector } from "react-redux"

import { CenteredBox } from "../../../components/layouts/OneViewBox"
import SalesPersonFilterDropDown from "../../../components/filterdropdowns/SalesPersonFilter"
import TeamFilterDropDown from "../../../components/filterdropdowns/TeamFilterDropDown"
import LeadTimeFilters from "../../lead/filters/LeadTimeFilter"
import FilterButton from "../../lead/filters/FilterButton"

Chart.register(ArcElement);
const LeadAnalyticsBySourceUI = ({ loading, list, filters, setFilters }) => {

    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"))


    return <>

        <Paper component={Box} elevation={0} sx={{ width: "100%", p: 2 }} mb={2} >
            <Box p={2} width="100%">
                <Grid container spacing={2}>
                    <Grid item xs={12} >
                        <LeadTimeFilters forPannel filters={filters} setFilters={setFilters} />
                    </Grid>
                    {<Grid item xs={6} >



                        <TeamFilterDropDown
                            id={'teams-lead-analytics-status'}
                            filters={filters}
                            setFilters={setFilters}
                        />


                    </Grid>}
                    <Grid item xs={6} >

                        <SalesPersonFilterDropDown
                            id={'sales-lead-analytics-status'}
                            filters={filters}
                            setFilters={setFilters}
                            key={filters.team}
                        />
                    </Grid>

                </Grid>
                <Box display="flex" justifyContent="flex-end" width="100%" mt={1}>
                    <FilterButton notApplicableFilters={['source']} defaultFilters={filters} setDefaultFilters={setFilters} />
                </Box>
            </Box>
        </Paper>
        <Box  >
            <Grid container spacing={2} >
                <Grid item xs={12} md={6} >
                    <Paper sx={{ height: '100%' }}>
                        <CenteredBox flex="none" sx={{ flex: 0, height: "100%" }} >
                            <Box sx={{ width: "400px", maxWidth: "100%" }}>
                                {
                                    loading && <Skeleton width={"100%"} variant="circular" height={isSmallScreen ? "250px" : "400px"} />
                                }
                                {
                                    !loading && list && list && list.length > 0 &&

                                    <Pie
                                        options={{
                                            width: isSmallScreen ? "250px" : "400px",
                                            height: isSmallScreen ? "250px" : "400px",
                                        }}


                                        data={{
                                            labels: list.map((item) => toTitleCase(item.title?.name)),
                                            datasets: [
                                                {
                                                    data: list.map((item) => item?.counts?.total_lead),
                                                    backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56","#4BC0C0","#9966FF","#FF9F40","#66FF66","#C9CBCF "]
                                                }
                                            ]
                                        }}
                                    >

                                    </Pie>

                                }

                            </Box>
                        </CenteredBox>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6} >
                <Grid container spacing={1}>
                            {
                                !loading && list && list && list.length > 0 && list.sort((a, b) => b.counts?.total_lead - a.counts?.total_lead).map((item) =>


                                    <Grid key={item.source} item xs={6} md={4}>
                                        <Paper variant="outlined" sx={{ height: "100%" }}>
                                            <Box p={2} sx={{ height: "100%" }} display="flex" justifyContent="space-between" flexDirection="column" >
                                                <Typography mb={2} align="center" textTransform={"capitalize"} variant="body1" fontWeight={"bold"}>{toTitleCase(item.title?.name??"")}</Typography>

                                                <Typography mt={2} align="center" textTransform={"capitalize"} variant="h5" color="gray" fontWeight={"bold"}>{item.counts?.total_lead??0} </Typography>
                                            </Box>
                                        </Paper>
                                    </Grid>

                                )
                            }
                        </Grid>
                </Grid>
            </Grid>
        </Box>
    </>
 
}
export default LeadAnalyticsBySourceUI