import axios from "axios";
import { getHeaders } from "../utils/helpers/helper";
import endpoints from "./endpoints";

export const getLeadApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.leadBase,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};
export const getLeadPiplineViewApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.leadPipelineCounts,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const getLeadSearchApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.leadSearch,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const getLeadCallsApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.leadCallBase,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const getLeadAnalytisByStatusApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.leadAnalysisByStatus,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const getLeadAnalytisBySourceAndSalesPersonApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.leadAnalysisBySourceAndSalesPerson,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};
export const getLeadAnalytisByStatusAndSalesPersonApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.leadAnalysisByStatusesAndSalesPerson,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};
export const getLeadAnalytisByStatusAndSourceApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.leadAnalysisByStatusesAndSource,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const getLeadByIdApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.leadById,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const getLeadAnalysisApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.leadAnalysis,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const addLeadApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.leadBase,
    method: "POST",
    headers: getHeaders(),

    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const updateLeadField = async (data) => {
  const callResponse = await axios({
    url: endpoints.leadBase,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const updateLeadData = async (data) => {
  const callResponse = await axios({
    url: endpoints.leadBase,
    method: "PUT",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const deleteLeadApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.leadBase,
    method: "DELETE",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const getActivityLogsApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.leadActivityLogsBase,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};
export const createActivityLogsApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.leadActivityLogsBase,
    method: "POST",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const updateLeadSalesPersonApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.leadSalesPersonUpdate,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const updateLeadSourceApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.leadSourceUpdate,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const updateLeadTagsApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.leadTagsUpdate,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const updateLeadLastUpdatedDateApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.leadLastUpdatedDateUpdate,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const updateLeadContactsApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.leadContactsUpdate,
    method: "PUT",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};
export const updateLeadSiteDetailsApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.leadSiteDetailsUpdate,
    method: "PUT",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};
export const updateLeadUIDApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.leadUIDUpdate,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const updateLeadStepApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.leadStepUpdate,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const updateLeadStatusApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.leadStatusUpdate,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

//open-solar

export const addLeadInOpenSolarApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.leadOpenSolarBase,
    method: "POST",
    headers: getHeaders(),

    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const attachLeadWithOpenSolarApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.leadOpenSolarBase,
    method: "PUT",
    headers: getHeaders(),

    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const refreshLeadInOpenSolarApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.leadOpenSolarBase,
    method: "get",
    headers: getHeaders(),

    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};
export const getOpenSoalrFilesFilesApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.leadOpenSolarFiles,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};
//reports

export const getLeadIndividualSalesReportApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.leadIndividualSalesReport,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const getSalesReportApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.leadSalesReport,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};
export const getLeadAnalytisBySourceApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.leadSourceReport,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};
export const getLeadAnalyticsByStateApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.leadStateReport,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};
export const getLeadAnalyticsByRejectionReasonApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.leadRejectionReasonReport,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const updateActivityFavouriteApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.favouriteActivity,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const updateFavouriteLeadApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.favouriteLead,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const getUnassignLeadApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.unassignLead,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};
