import { MenuItem } from "@mui/material"
import getSourceApi from "../../apis/sources.api"
import AsyncDropDown from "../inputs/AsyncDropDown"
import { StyledSearchBar } from "../inputs/SearchBar"
import { useSelector } from "react-redux"
import { moduleAccessCondition } from "../../utils/helpers/helper"
import MODULES from "../../utils/constants/module.constants"

const SourceFilterDropDown = ({ titleComponent, filters, setFilters, returnFullObj, ...props }) => {

    const { user } = useSelector(state => state)


    return <>
        {titleComponent}
        <AsyncDropDown

            lazyFun={async (params) => { return await getSourceApi({ ...params }) }}

            OptionComponent={({ option, ...rest }) => {
                return <MenuItem {...rest}>{option.name}</MenuItem>
            }}
            onChange={async (changedVal) => { setFilters({ ...filters, source: changedVal ? (returnFullObj ? changedVal : changedVal._id) : null }) }}
            titleKey={'name'}
            valueKey={"_id"}
            InputComponent={(params) => <StyledSearchBar placeholder="Select Source" {...params} margin="none" size="small" />}
            {...props}
            margin={0}
        />
    </>
}

export default SourceFilterDropDown