import { AccountCircle, Edit } from "@mui/icons-material"
import { Avatar, Box, ButtonBase, IconButton, Paper, Tooltip, Typography, useTheme } from "@mui/material"
import { memo, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { openModal } from "../../../store/actions/modalAction"
import LeadReassignController from "../update/sales-person/LeadReassignController"
import { MODAL_KEYS } from "../../../utils/constants/modal.constant"
import { callSnackBar } from "../../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS, USER_ROLES } from "../../../utils/constants/constants"
import { moduleAccessCondition } from "../../../utils/helpers/helper"
import MODULES from "../../../utils/constants/module.constants"

const AssignedSalesPerson = ({ salesPersonId, projectId, onChangeSalesPerson = () => { }, title, size, editable }) => {
    const dispatch = useDispatch()
    const { user } = useSelector(state => state)

    const shortNameSplitted = title?.split(' ') ?? ""
    const shortName = ((shortNameSplitted[0]?.slice?.(0, 1) ?? "") + (shortNameSplitted[1]?.slice?.(0, 1) ?? ""))
    const theme = useTheme()


    const allowedToEdit = useMemo(() =>
        editable && ((user.data.role == USER_ROLES.sales && salesPersonId != user?.data?._id) || moduleAccessCondition(user, [MODULES.LEAD_ADMIN]))
        , [editable, user.data?.role, user.data?._id, salesPersonId])

    const onClick = () => {
        if (allowedToEdit) {
            dispatch(openModal(<LeadReassignController id={projectId} callBack={onChangeSalesPerson} assigned_sales_person={{
                _id: salesPersonId,
                name: title
            }} />, 'xs', undefined, MODAL_KEYS.LEAD_UPDATE_SALES_PERSON))
        } else {
            if (editable)
                dispatch(callSnackBar("You are not allowed to reassign sales person", SNACK_BAR_VARIETNS.error))
        }
    }
    if (size == "large")
        return (
            <Box >
                <Box display="flex" justifyContent="space-between" alignItems="center" >
                    <Typography lineHeight="100%" variant="body1" display="flex" alignItems="center" mb={2}>
                        <AccountCircle fontSize="inherit" />&nbsp;Lead Owner
                    </Typography>
                    <IconButton size="small" onClick={onClick} >
                        {allowedToEdit && <Edit fontSize="inherit" />}
                    </IconButton>
                </Box>
                <Paper variant="outlined" component={Box} p={2} display="flex" alignItems="center" >
                    <Tooltip arrow title={title} >
                        <Avatar sx={{ height: "30px", width: "30px", background: theme.palette.primary.light }}>
                            <Typography color="primary"  >
                                {shortName}
                            </Typography>
                        </Avatar>
                    </Tooltip>
                    <Typography ml={2} variant="body1" color="primary">{title}</Typography>
                </Paper>
            </Box>
        )

    return <Tooltip arrow title={title} slotProps={{ popper: { disablePortal: true } }}  >
        <Avatar sx={{ height: "25px", width: "25px", background: theme.palette.primary.light }} component={ButtonBase} onDoubleClick={onClick}>
            <Typography color="primary" sx={{ fontSize: "12px" }} >
                {shortName}
            </Typography>
        </Avatar>
    </Tooltip>
}
export default memo(AssignedSalesPerson)