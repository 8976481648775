import {
    Avatar,
    Badge,
    Box,
    Divider,
    Grid,
    Link,
    List,
    ListItemButton,
    ListSubheader,
    Paper,
    Skeleton,
    Typography,
    styled,
  } from "@mui/material";
  import CircleIcon from "@mui/icons-material/Circle";
  import React, { useState, useEffect } from "react";
  import { CenteredBox } from "../../components/layouts/common/boxes";
  import moment from "moment";
  import AcUnitIcon from "@mui/icons-material/AcUnit";
  import { center } from "../../assets/css/theme/common";
  import { deepOrange, deepPurple } from "@mui/material/colors";
  import { useDispatch, useSelector } from "react-redux";
 
  import { closeModal, openModal } from "../../store/actions/modalAction";

  import DashBox from "./Dashbox";
  import { red } from "@mui/material/colors";
import BlogModal from "./BlogModel";
import { fetchBlogAction } from "../../store/actions/blogAction";
import AllBlogShowController from "./AllBlogShowController";
  
  function DashboardBlog() {
    const [Loading, setLoading] = useState(true);
    
    const dispatch = useDispatch();
    const { blog } = useSelector((state) => state);
    console.log("blog sss", blog);
    const [ids, setIds] = useState([]);
    const [storedIds, setStoredIds] = useState([]);
  
    // const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
  
    const handleClick = (blog) => {
      console.log("connn", blog._id);
  
      const updatedIds = [...storedIds, blog._id];
      setIds(updatedIds);
  
      const stored = JSON.parse(localStorage.getItem("blogIds") || "[]");
  
      if (!stored.includes(blog._id)) {
        //   const updatedIds = [...storedIds, blog._id];
        // setIds(updatedIds);
        localStorage.setItem("blogIds", JSON.stringify(updatedIds));
        console.log("annou", storedIds); // Will still only show latest value
      }
  
      dispatch(
        openModal(
          <BlogModal blog={blog} />,
          "sm",
          undefined,
          "blog"
        )
      );
    };
    useEffect(() => {
      setLoading(false);
      dispatch(fetchBlogAction({ pageSize: 3 }));
      const retrievedIds = JSON.parse(
        localStorage.getItem("blogIds") || "[]"
      );
      setStoredIds(retrievedIds);
  
    }, []);
    const onViewMoreBlog = () => {
      dispatch(
        openModal(<AllBlogShowController />, "sm", undefined, "blog-view-more")
      );
    };
    return (
      <DashBox title={"Latest Blog"} onViewMoreClick={onViewMoreBlog}>
  
        {blog.data.length === 0 ? (
          <Grid container spacing={2} p={4}>
            <Typography variant="body1" color="text.primary" align="center">
              No New Blog Found.
            </Typography>
          </Grid>
        ) : (
          <>
            {!Loading &&
              blog &&
              blog.data &&
              Array.isArray(blog.data) &&
              blog.data.map((blog) => (
                <Box
                  sx={{
                    display: "flex",
                    bgcolor: "#F5F5F5",
                    borderRadius: "10px",
                    borderColor: "lightgrey",
                    margin: 3,
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <Link
                      sx={{ textDecoration: "none" }}
                      onClick={() => handleClick(blog)}
                      key={blog._id}
                    >
                      <ListItemButton
                        disableTouchRipple
                        sx={{}}
                        key={blog._id}
                      >
                        <Box
                          p={1}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Badge
                            color="error"
                            overlap="circular"
                            badgeContent=""
                            variant="dot"
                            invisible={
                              storedIds.includes(blog._id) ? true : false
                            }
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          >
                            <Box
                              sx={{
                                backgroundColor: "#ccc",
                                width: 45,
                                height: 45,
                                borderRadius: "50%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                p: 1.5,
                                // mr: 2,
                              }}
                            >
                              <Typography variant="body2" sx={{}}>
                                {" "}
                                {moment(blog.blog_date).format(
                                  "DD"
                                )}
                              </Typography>
  
                              <Typography variant="caption" lineHeight={"100%"}>
                                {" "}
                                {moment(blog.blog_date).format(
                                  "MMM"
                                )}
                              </Typography>
                            </Box>
                          </Badge>
                        </Box>
  
                        <Box
                          sx={{
                            width: "70%",
                            ml: 2,
  
                            flexDirection: "row",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography
                            variant="subtitle2"
                            color="text.200"
                            sx={{ flexGrow: 1, width: "100%" }}
                          >
                            {blog?.title?.toUpperCase()}
                          </Typography>
                          <Typography
                            variant="caption"
                            color="text.200"
                            sx={{ justifySelf: "flex-end" }}
                          >
                            {"" +
                              moment(blog.date).fromNow(
                                true
                              ) +
                              " Ago"}
                          </Typography>
                        </Box>
                        <Box variant="p" sx={{ justifySelf: "flex-end" }}></Box>
                      </ListItemButton>
                    </Link>
                  </Box>
                </Box>
              ))}
          </>
        )}
      </DashBox>
    );
  }
  
  export default DashboardBlog;
  