import { Box, Button, Checkbox } from "@mui/material"
import { createContext, memo, useContext, useMemo, useReducer } from "react"
import { useDispatch, useSelector } from "react-redux"
import { openModal } from "../../store/actions/modalAction"
import BulkCreateController from "./BulkCreateController"
import { INVOICE_STATUS, INVOICE_TYPE } from "../../utils/constants/invoice.constant"
import { moduleAccessCondition } from "../../utils/helpers/helper"
import MODULES from "../../utils/constants/module.constants"
import { PROJECT_FLAGS } from "../../utils/constants/project.constant"
import { getStatusOfProjectFlags } from "../../utils/helpers/project.helper"

const BULK_INVOICE_SELECTION_ACTIONS = {
    "START_BULK_SELECTION": "START_BULK_SELECTION",
    "END_BULK_SELECTION": "END_BULK_SELECTION",
    "SELECT_INVOICE": "SELECT_INVOICE"
}

export const startBulkSelection = () => {
    return { type: BULK_INVOICE_SELECTION_ACTIONS.START_BULK_SELECTION }
}
export const endBulkSelection = () => {
    return { type: BULK_INVOICE_SELECTION_ACTIONS.END_BULK_SELECTION }
}
export const selectInvoice = (invoiceData) => {
    return { type: BULK_INVOICE_SELECTION_ACTIONS.SELECT_INVOICE, value: invoiceData }
}


const defaultBulkInvoiceSelectionData = {
    allow_selection: false,
    data: {
        //"invoice_id":{...invoceInfo}
    }

}
const defaultBulkInvoiceSelectionContextData = {
    ...defaultBulkInvoiceSelectionData,
    dispatch: () => { }

}
const bulkInvoiceReducer = (state, action) => {

    switch (action.type) {
        case BULK_INVOICE_SELECTION_ACTIONS.START_BULK_SELECTION: return { ...state, allow_selection: true, data: {} };
        case BULK_INVOICE_SELECTION_ACTIONS.END_BULK_SELECTION: return { ...state, allow_selection: false, data: {} };
        case BULK_INVOICE_SELECTION_ACTIONS.SELECT_INVOICE:
            const temp = { ...state.data }
            if (action?.value?._id) {
                if (temp[action.value._id])
                    delete temp[action.value._id]
                else
                    temp[action.value._id] = action.value
            }
            return { ...state, data: temp }
    }
}
export const BulkInvoiceContext = createContext(defaultBulkInvoiceSelectionContextData)



export const BulkInvoiceSelectionInitiateButton = ({ allowed, type }) => {
    const context = useContext(BulkInvoiceContext)
    const { user } = useSelector(state => state)

    const dispatch = useDispatch()

    const onProccedBulkSelection = () => {
        dispatch(openModal(<BulkCreateController type={type} invoices={Object.values(context.data)} callBack={() => { context.dispatch(endBulkSelection()) }} />))
    }

    if (!allowed || !moduleAccessCondition(user, [MODULES.BULK_INVOICE], []))
        return null
    if (!context.allow_selection) {
        return <Button disableElevation sx={{ mt: 2 }} size="small" variant="contained" onClick={() => context.dispatch(startBulkSelection())} >
            Start Bulk Selection
        </Button>
    }

    return <Box display="flex" mt={2} >
        <Button size="small" variant="contained" color="error" disableElevation onClick={() => context.dispatch(endBulkSelection())}  >
            Cancel
        </Button>
        <Button size="small" variant="contained" color="success" disableElevation ml={2} onClick={onProccedBulkSelection} >
            Proceed ( {Object.keys(context.data)?.length} )
        </Button>
    </Box>
}
export const InvoiceSelectionCheckBox = memo(({ ...invoiceProps }) => {
    const context = useContext(BulkInvoiceContext)

    let firstSelectedData = typeof context.data == "object" ? Object?.values(context.data)?.[0] : null

    let disabled = false
    if (firstSelectedData) {

        if (invoiceProps?.type == INVOICE_TYPE.ELECTRICIAN_INVOICE)
            disabled = firstSelectedData?.installer_id?._id != invoiceProps?.installer_id?._id
        if (invoiceProps?.type == INVOICE_TYPE.SUPPLIER_INVOICE)
            disabled = firstSelectedData?.supplier_id?._id != invoiceProps?.supplier_id?._id
    }
    if ((invoiceProps.payments?.find(item=>!item?.verified))) {
        disabled = true
    }
    // if ((invoiceProps.type == INVOICE_TYPE.STC_INVOICE && invoiceProps.project_details?.flags?.[PROJECT_FLAGS.STC_CLAIMED] != getStatusOfProjectFlags(PROJECT_FLAGS.STC_CLAIMED).CLAIMED)) {
    //     disabled = true
    // }
    // if (invoiceProps.type == INVOICE_TYPE.SOLAR_VICTORIA_INVOICE && invoiceProps.project_details?.flags?.[PROJECT_FLAGS.SOLAR_VICTORIA_POST_APPROVAL] != getStatusOfProjectFlags(PROJECT_FLAGS.SOLAR_VICTORIA_POST_APPROVAL).CLAIMED) {
    //     disabled = true
    // }
    if (invoiceProps.status != INVOICE_STATUS.PAID)
        return <Checkbox disabled={disabled} checked={context.data[invoiceProps?._id] ? true : false} onClick={() => context.dispatch(selectInvoice(invoiceProps))} />
    return <></>
})
const BulkInvoiceSelectionWrapper = ({ ...props }) => {
    const [selectedInvoices, dispatch] = useReducer(bulkInvoiceReducer, defaultBulkInvoiceSelectionData)


    const store = useMemo(() => (
        {
            ...selectedInvoices,
            dispatch
        }
    ), [selectedInvoices, dispatch])

    return <BulkInvoiceContext.Provider value={store} >
        {props.children}
    </BulkInvoiceContext.Provider>
}

export default memo(BulkInvoiceSelectionWrapper)