import { memo, useEffect, useMemo, useState } from "react"
import useValidate from "../../store/hooks/useValidator"
import CreateUi from "./CreateUi"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { closeModal } from "../../store/actions/modalAction"
import { useParams } from "react-router-dom"

import { addSourceApi, getSourceByIdApi, updateSourceField } from "../../apis/sources.api"
import { MODAL_KEYS } from "../../utils/constants/modal.constant"
import { addLeadFormApi, getLeadFormByIdApi, updateLeadFormData } from "../../apis/leadform.api"
import { LEAD_FORMS_FIELDS } from "../../utils/constants/leaforms.constants"

const CreateController = ({ modalKey = MODAL_KEYS.SOURCES_CREATE, parent, callBack, id }) => {
    const validate = useValidate()
    const dispatch = useDispatch()


    const title = "Lead Forms "
    const createApi = addLeadFormApi
    const updateApi = updateLeadFormData
    const getByIdApi = getLeadFormByIdApi

    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',
        id,
        logo: '',
        form_title: '',
        form_description: '',
        form_width: '400px',
        form_height: 'auto',
        form_title_color: '#000000',
        form_description_color: '#D3D3D3',
        form_border_color: '#000000',
        form_bg_color: '#ffffff',
        submit_btn_color: '#000000',
        submit_btn_font_color: "#ffffff",
        success_html: `<h1 color='green' >Thanks! for your response</h1>`,
        submit_btn_text: 'Submit',
        default_source: null,
        fields: LEAD_FORMS_FIELDS.filter((item) => item.isDefault).map((item) => ({ label: '', ...item }))

    })
    const [originalDocument, setOriginalDocument] = useState({


    })


    const validationSchemaForCreate = useMemo(() => ([
        {
            required: true,
            value: fields.logo,
            field: 'Logo',
        },
        {
            required: true,
            value: fields.form_title,
            field: 'Form Title',
        },
        {
            required: true,
            value: fields.form_description,
            field: 'Form Title',
        },
        {
            required: true,
            value: fields.default_source,
            field: 'Default Source',
        },
        {
            required: true,
            value: fields.submit_btn_text,
            field: 'Submit Button Text',
        }
        ,
        {
            required: true,
            value: fields.success_html,
            field: 'Success Html',
        }
    ]), [fields])

    const validationSchemaForUpdate = useMemo(() => ([
        {
            required: true,
            value: fields.logo,
            field: 'Logo',
        },
        {
            required: true,
            value: fields.form_title,
            field: 'Form Title',
        },
        {
            required: true,
            value: fields.form_description,
            field: 'Form Title',
        },
        {
            required: true,
            value: fields.default_source,
            field: 'Default Source',
        },
        {
            required: true,
            value: fields.submit_btn_text,
            field: 'Submit Button Text',
        },
        {
            required: true,
            value: fields.success_html,
            field: 'Success Html',
        }
    ]), [fields])



    const createFunction = async () => {
        const validationResponse = validate(validationSchemaForCreate)

        if (validationResponse === true) {

            const paasData = { ...fields }
            paasData['default_source'] = fields?.default_source?._id
            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await createApi(paasData),
                    async (response) => {

                        await callBack(response)
                        setLoading(false)
                        dispatch(closeModal(modalKey))
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }


    const updateFunction = async () => {
        const validationResponse = validate(validationSchemaForUpdate)
        let updatedData = { id }

        for (let field in fields) {


            if (originalDocument[field] && fields[field] && fields[field] != originalDocument[field]) {
                if (typeof fields[field] == "object" && !Array.isArray(fields[field]))
                    updatedData[field] = fields[field]?._id
                else
                    updatedData[field] = fields[field]
            }
            if (!originalDocument[field]) {
                updatedData[field] = fields[field]
            }
        }

        if (validationResponse === true) {


            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await updateApi(updatedData),
                    async (response) => {

                        await callBack(response, updatedData)
                        setLoading(false)
                        dispatch(closeModal(modalKey))
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }



    const onSubmit = async (e) => {
        e.preventDefault()
        if (id)
            updateFunction()
        else
            createFunction()

    }

    const fetchById = (id) => {
        setLoading(true)
        dispatch(
            callApiAction(
                async () => await getByIdApi({ id }),
                async (response) => {


                    setFields({ ...fields, ...response })
                    setOriginalDocument(response)
                    setLoading(false)

                },
                (err) => {
                    setFields({ ...fields, err })
                    setLoading(false)

                }
            )
        )
    }

    useEffect(() => {
        if (id)
            fetchById(id)

    }, [id])


    return <CreateUi title={title} modalKey={modalKey} isUpdate={id} loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
}
export default memo(CreateController)