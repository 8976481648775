import { getPreDefinedTagsCategoryApi } from "../../apis/predefinedTags.api"
import { actions } from "../../utils/constants/constants"
import { callApiAction } from "./commonAction"

export const fetchPreDefinedTags = (data, onError, onSuccess) => {
    return async (dispatch, getState) => {
        dispatch(callApiAction(
            async () => await getPreDefinedTagsCategoryApi(data),
            (response) => {
                dispatch({ type: actions.ADD_TAG_DATA, value: response, tagType: data?.type })
                onSuccess()
            },
            (err) => {

                onError(err)
            }
        ))
    }


}