
import { useDispatch } from "react-redux"
import { openModal } from "../../../store/actions/modalAction"

import { IconButton, Tooltip } from "@mui/material"
import {  Edit } from "@mui/icons-material"

const EditButton = ({title, id,EditComponent,callBack }) => {
    const dispatch = useDispatch()
    const onEdit = () => {
        dispatch(openModal(<EditComponent id={id} callBack={callBack} />, "sm"))
    }

    return <>
        <Tooltip arrow  title={"Edit "+title} >

            <IconButton size="small" onClick={onEdit}>
                <Edit color="info" fontSize="inherit" />
            </IconButton>
        </Tooltip>
    </>
}
export default EditButton