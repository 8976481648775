import { Box, ButtonBase, CardHeader, Chip, IconButton, Paper, Tooltip, Typography } from "@mui/material"
import CustomerView from "./CustomerView"
import { useTheme } from "@emotion/react"
import AssignedSalesPerson from "./AssignedSalesPerson"
import { useDispatch } from "react-redux"
import LeadViewButton from "./LeadViewButton"
import { AccountCircle, CalendarMonth, Call, Email, LocationOn, MyLocation, Star, Start } from "@mui/icons-material"
import { StatusIcon } from "./StatusComponent"
import moment from "moment"
import { memo, useState } from "react"
import { viewLimitedLengthName } from "../../../utils/helpers/helper"
import { LEAD_STATUS_COLOR, LEAD_STATUS_ICON } from "../../../utils/constants/lead.constant"
import { CenteredBox } from "../../../components/layouts/OneViewBox"

const LeadCard = ({ uid, projectId, name, address, status, customerId,favourite, createdAt, updatedAt, assignedSalesPerson, step, phone, email, onSalesPersonChange = () => { }, onDataChange = () => { }, size = "large" }) => {
    console.log('loaded')
    const [salesPersonDetails, setSalesPersonDetail] = useState(assignedSalesPerson ?? null)
    const dispatch = useDispatch()
    const theme = useTheme()

    const onSalesPersonChangeFun = ({ assigned_sales_person }) => {
        setSalesPersonDetail(assigned_sales_person ?? null)
        onSalesPersonChange(assigned_sales_person ?? null)
    }

    if (size == "small")
        return <Box width="100%" >
            <LeadViewButton id={projectId} sx={{ width: "100%", p: 0 }} textProps={{ sx: { width: "100%", p: 0 } }} title={<Paper component={Box} width="100%" p={0} display="flex" elevation={0} sx={{ border: 1, borderColor: LEAD_STATUS_COLOR[status] ?? "#000",  justifyContent: "flex-start" }}>
                <Box display="flex" alignItems="center" >
                    <CenteredBox sx={{ background: LEAD_STATUS_COLOR[status] ?? "#000",width:"20px"}}>
                        <Typography  fontSize={12} fontWeight={600} sx={{ color: "white" }} lineHeight="100%" textAlign="left" >
                            {
                                LEAD_STATUS_ICON[status] 
                            }
                        </Typography>
                    </CenteredBox>
                    <Tooltip title={`${uid}-${name}`} arrow >
                        <Typography fontSize={12} ml={2} fontWeight={600} sx={{ color:LEAD_STATUS_COLOR[status] ?? "#000"}} lineHeight="100%" textAlign="left" >
                            {viewLimitedLengthName(`${uid}-${name}`, 15)}
                        </Typography>
                    </Tooltip>
                </Box>
            </Paper>} >

            </LeadViewButton>
        </Box>
    return <Box elevation={2} component={Paper} p={2} width="100%" sx={{ borderLeft: '10px solid', borderColor: step?.color ?? "#000" }} >
        <Box display="flex" justifyContent="space-between" mb={1} sx={{ width: "100%" }} >


            <Box >
                <LeadViewButton id={projectId} title={`${uid}`} />
            </Box>

            <Box display="flex" justifyContent="flex-end" >
                <IconButton size="small" sx={{ p: 0 }} mr={2} >
                    <Star fontSize="inherit" color={favourite?"primary":""} />
                </IconButton>
                <Box mx={2} >
                    <AssignedSalesPerson
                        editable
                        salesPersonId={salesPersonDetails?.id}
                        title={salesPersonDetails?.name}
                        projectId={projectId}
                        size="small"
                        onChangeSalesPerson={onSalesPersonChangeFun}

                    />
                </Box>
                <StatusIcon id={projectId} status={status} />
            </Box>
        </Box>
        <Box display="flex" mb={1} alignItems="flex-start" >
            <Typography color="grey" variant="caption" lineHeight="100%" >
                <AccountCircle fontSize="inherit" />
            </Typography>
            <Typography color="grey" ml={2} variant="caption" lineHeight="100%" >
                #{customerId}- {name}
            </Typography>
        </Box>
        {size == 'large' && <>
            <Box display="flex" mb={1} alignItems="flex-start" >
                <Typography color="grey" variant="caption" lineHeight="100%" >
                    <Email fontSize="inherit" />
                </Typography>
                <Typography color="grey" ml={2} variant="caption" lineHeight="100%" >
                    {email}
                </Typography>
            </Box>
            <Box display="flex" mb={1} alignItems="flex-start" >
                <Typography color="grey" variant="caption" lineHeight="100%" >
                    <Call fontSize="inherit" />
                </Typography>
                <Typography color="grey" ml={2} variant="caption" lineHeight="100%" >
                    {phone}
                </Typography>
            </Box>

            <Box display="flex" mb={1} alignItems="flex-start" >
                <Typography color="grey" variant="caption" lineHeight="100%" >
                    <LocationOn fontSize="inherit" />
                </Typography>
                <Typography color="grey" ml={2} variant="caption" lineHeight="100%" >
                    {address}
                </Typography>
            </Box>
        </>}
        <Box display="flex" mb={1} alignItems="flex-start">
            <Box display="flex" alignItems="flex-start">
                <Typography color="grey" variant="caption" lineHeight="100%" >
                    <CalendarMonth fontSize="inherit" />
                </Typography>
                <Typography color="grey" ml={2} variant="caption" lineHeight="100%" >
                    {moment(updatedAt).format('DD/MM/YYYY HH:mm')}
                </Typography>
            </Box>

        </Box>

    </Box>
}
export default memo(LeadCard)