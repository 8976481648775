import { Box, Paper, Typography } from "@mui/material"
import { TitleBox } from "../../../components/layouts/common/boxes"
import { CenteredBox } from "../../../components/layouts/OneViewBox"
const IncentivesRow = ({ title, value, invoice }) => {
    return <Box component={Paper} border={1} borderLeft={5} mb={2} p={2} >
        <Typography lineHeight="100%" variant="body1">
            {value}
        </Typography>
        <Typography lineHeight="100%" variant="caption">
            {title} 
        </Typography>
    </Box>
}
const LeadIncentivesDetailsUI = ({ data, titleBoxProps }) => {
    const selectedSystem = data?.selected_system

    return <TitleBox title={"Incentives"} {...titleBoxProps} >
        {
            selectedSystem?.incentives?.map((item) => <IncentivesRow {...item} key={item?._id} />) ?? <CenteredBox>
                <Typography variant="body1" >
                    No Incentives
                </Typography>
            </CenteredBox>
        }
    </TitleBox>
}
export default LeadIncentivesDetailsUI