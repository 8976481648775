import { useCallback, useEffect, useState,useMemo, memo } from "react"
import ListUi from "./ListUi"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { closeModal, openModal } from "../../store/actions/modalAction"

import CreateController from "./CreateController"

import { MODAL_KEYS } from "../../utils/constants/modal.constant"
import { getActivityApi } from "../../apis/activity.api"
import FileDownloadComponent from "../../components/FileDownloadComponent"
import getAgreementApi, { deleteAgreementApi } from "../../apis/aggrement.api"
import moment from "moment"
import MessageDilog from "../../components/MessageDilog"
import { Box, CircularProgress, IconButton } from "@mui/material"
import { Delete, Edit } from "@mui/icons-material"
import { getInstalleByIdApi } from "../../apis/user.api"
import UpdateActiveAgreementButton from "./UpdateActiveAgreementButton"
import { moduleAccessCondition } from "../../utils/helpers/helper"
import MODULES from "../../utils/constants/module.constants"

const ActionComponent = memo(({ params, setParams }) => {
    const dispatch = useDispatch()
    const {user} = useSelector(state=>state)
    const [loading, setLoading] = useState(0)
    const onDelete = (e) => {
        e?.preventDefault?.()
        setLoading(true)
        dispatch(callApiAction(
            async () => await deleteAgreementApi({ id: params._id }),
            (response) => {
                setParams({})
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
        dispatch(closeModal())
    }
    const onDeleteBtnClick = () => {
        dispatch(openModal(<MessageDilog onSubmit={onDelete} title="Alert!" message="Are you sure to delete??" />, "xs", undefined))
    }
    const onEditBtnClick = () => {
        dispatch(openModal(<CreateController id={params?._id} callBack={(res,editableData)=>setParams({...params,...editableData})} />, "xs", undefined,"add-aggrement"))
    }

    if (loading)
        return <CircularProgress />
    return <Box sx={{ width: "100%", display: "flex" }}>

        {moduleAccessCondition(user,[MODULES.UPDATE_INSTALLER_AGGREMENT]) &&<IconButton disableElevation variant="contained" size="small" onClick={onEditBtnClick}>
            <Edit color="info" />
        </IconButton>}
        {moduleAccessCondition(user,[MODULES.DELETE_INSTALLER_AGGREMENT]) &&<IconButton disableElevation variant="contained" size="small" onClick={onDeleteBtnClick}>
            <Delete color="error" />
        </IconButton>}



    </Box>
})

const ListController = ({installer_id}) => {
    console.log("idddd",installer_id)
    const dispatch = useDispatch()

    const title = "Agreement";
    const modalKey = "aggrement-list";
    const fetchApi = getAgreementApi
    const fetchByIdApi = getInstalleByIdApi;

    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 10,
        search: '',
        searchable: ['title'],
        sort: 'date',
        installer_id,
        sortDirection: -1,

    })
    // do not change 

    
  const columns = useMemo(() => {
    const arr = [

      { id: 1, fieldName: "title", label: "Title", align: "left" },
      {
        id: 2,
        fieldName: "date",
        label: "Date",
        align: "left",
        sort: true,
        renderValue: (params) => moment(params.date).format("DD/MM/YYYY")
      },
      {
        id: 3,
        fieldName: "expiry_date",
        label: "Expiry Date",
        align: "left",
        sort: true,
        renderValue: (params) => moment(params.expiry_date).format("DD/MM/YYYY")
      },

      {
        id: 4,
        fieldName: "aggrement_file",
        label: "Agreement File",
        align: "left",
        renderValue: (params) => {
            return <FileDownloadComponent src={params.aggrement_file} />;
          },
        // sort: true,
      },
      {
        id: 5,
        fieldName: "",
        label: "Active Agreement",
        align: "right",
        minWidth: "100px",
        // hide: user.data.role == USER_ROLES.employee,
        renderValue: (params, setParams) => (
          <UpdateActiveAgreementButton params={params} />
        ),
      },

      { id: 6, fieldName: '', label: 'Action', align: "left", renderValue: (params, setParams) => <ActionComponent params={params} setParams={setParams} /> },

    ];

    // arr.push({
    //   id: 6,
    //   fieldName: "deletedaction",
    //   label: "Action",

    //   align: "right",
    //   renderValue: (params, setParams) => (
    //     <ActionComponent
    //       key={Math.random()}
    //       deleted={params.deleted}
    //       deleteApi={deleteApi}
    //       params={{ ...params, deleted: true }}
    //       setParams={setParams}
    //     />
    //   ),
    // });

    return arr;
  }, [dispatch, filters.deleted]);

    const [loading, setLoading] = useState(false)
    const [list, setList] = useState({})

    const fetchList =() => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchApi({...filters, }),
            (response) => {
                console.log("resposness",response)
                setList(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }

    console.log("FILTERSSS",filters)
    // const fetchByIdList =(id) => {
    //     setLoading(true)
    //     dispatch(callApiAction(
    //         async () => await fetchByIdApi({id}),
    //         (response) => {
    //             console.log("oooooo",response)
    //             setList(response)
    //             setLoading(false)
    //         },
    //         (err) => {
    //             setLoading(false)
    //         }
    //     ))
    // }
    useEffect(() => {
        fetchList()
    }, [filters])

    // useEffect(()=>{
    //     if(id){
    //         fetchByIdList(id)
    //     }
    // },[id])

    const onCreateBtnClick = () => {
        dispatch(openModal(<CreateController installer_id={installer_id} callBack={fetchList} />, 'xs', undefined,"add-aggrement"))
    }



    return (
        <>
            <ListUi
                title={title}
                modalKey={modalKey}
                // modal={modal}
                onCreateBtnClick={onCreateBtnClick}
                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}
                setList={setList}
                columns={columns}
                callBack={fetchList}

            />

        </>
    )
}
export default ListController