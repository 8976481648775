import {
  Box,
  Collapse,
  Paper,
  SwipeableDrawer,
  Tab,
  Tabs,
  useMediaQuery,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { memo } from "react";
import AppModeLabel from "../../texts/AppModeLabel";
import PopUpModal from "../../Modal";
import Logo from "./Logo";
import Header from "./Header";
import Navbar from "./Navbar";
import CheckInCheckoutButton from "../../../pages/attendences/CheckInCheckoutButton";
import VersionDetailsController from "../../../pages/versions/VersionDetailsController";
import { openModal } from "../../../store/actions/modalAction";
import { useEffect, useMemo, useState } from "react";
import { fetchVersionDataAction } from "../../../store/actions/fetchVersionAction";
import { lastReadVersion } from "../../../utils/helpers/helper";
import defineRoutes from "../../../routes";



const AppSubContainer = ({ overflow, ...props }) => {
  const { user } = useSelector((state) => state);


  const dispatch = useDispatch();
  const navigate = useNavigate()
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const path = useLocation()
  const splittedPath = useMemo(() => path.pathname.split('/'), [path])
  const basePath = splittedPath.slice(0, 3)?.join('/')
  
  const activePath = splittedPath.slice(3,4)?.join('')
  
  const routes = useMemo(() => {
    return defineRoutes(user).find(item => item.path == splittedPath[1])?.children?.find(item => item.path == splittedPath[2])?.children
  }, [user, splittedPath])
  
  return (
    <>
      <Box mb={3} >
        {<Paper sx={{ mb: 3 }}>
          {routes && routes.length > 0 && <Tabs value={activePath}  variant="scrollable" allowScrollButtonsMobile scrollButtons sx={{ width: "100%",height:"100%",minHeight:"50px" }} >
            {
              routes?.filter(item => !item?.hideInPannel)?.map((item) => <Tab key={item?.path} value={ item.path} LinkComponent={Link} iconPosition="start" ico icon={item.icon} label={item.title} to={basePath + "/" + item.path} sx={{minHeight:"50px"}} />)
            }
            {/* { <Tab label="main" value={'main'} />}
                     <Tab label="Finance" value={'finance'} />
                     <Tab label="Installation Calendar" value={'intsallation-calendar'} />
                     <Tab label="Source ⨯ Sales Person" value={'sources-cross-sp'} />
                     <Tab label="Status ⨯ Sales Person" value={'status-cross-sp'} />
                     <Tab label="Source ⨯ Status" value={'sources-cross-status'} /> */}

          </Tabs>}
        </Paper>}

        <Outlet />
        {
          props.children
        }


      </Box>

    </>
  );
};
export default memo(AppSubContainer);
