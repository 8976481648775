import CustomDialog from "../../../components/layouts/common/CustomDialog";
import { MODAL_KEYS } from "../../../utils/constants/modal.constant";
import {
  Box,
  Button,
  Grid,
  LinearProgress,
  Paper,
  Skeleton,
  styled,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import ImageComponent from "../../../components/ImageComponent";
import { CenteredBox } from "../../../components/layouts/OneViewBox";
import NoDataComponent from "../../../components/layouts/NoDataComponent";

import ProjectViewMoreButtons from "../../projects/projectdetails/buttons/ProjectViewMoreButtons";
import ProjectContactDetailsUi, {
  ProjectSelectedSystemDetailsUi,
  ProjectSiteDetailsUi,
} from "../../projects/projectdetails/ProjectContactDetailsUi";
import ProjectInvoiceDetailsUi from "../../projects/projectdetails/ProjectInvoiceDetailsUi";
import ProjectDocumentMain from "../../projects/projectdocuments/ProjectDocumentMain.controller";
import { StatusIcon } from "../components/StatusComponent";
import TagsEditor from "../../../components/inputs/TagsEditor";
import AssignedSalesPerson from "../components/AssignedSalesPerson";
import { CalendarMonth, MyLocation } from "@mui/icons-material";
import { TAG_TYPE } from "../../../utils/constants/tags.constant";
import StepViewComponent, {
  PipelineLinearProgress,
} from "../components/StepViewComponent";
import ProjectIconButtons from "../../projects/projectdetails/buttons/ProjectIconButtons";
import LeadIconButtons from "./LeadIconButtons";
import LeadViewMoreButtons from "./LeadViewMoreButtons";
import LeadSourceComponent from "../components/LeadSourceComponent";
import LeadTagEditor from "../update/tag/LeadTagEditor";
import StatusUpdateController from "../update/status-update/StatusUpdateController";
import { useState } from "react";
import CreateProjectButton from "../components/CreateProjectButton";
import { LEAD_STATUS } from "../../../utils/constants/lead.constant";
import { PROJECT_STATUS } from "../../../utils/constants/project.constant";
import { moduleAccessCondition } from "../../../utils/helpers/helper";
import { useSelector } from "react-redux";
import MODULES from "../../../utils/constants/module.constants";
import LeadIncentivesDetailsUI from "./LeadIncentiveDetailesUi";

const FlexedBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flex: 1,

  overflow: "hidden",
  [theme.breakpoints.down("md")]: {
    overflow: "auto",
  },
}));

const SkeletonLoader = () => {
  const overflow = "hidden";
  return (
    <CenteredBox>
      <Grid
        container
        sx={{ height: "100%", width: "100%", overflow: "hidden" }}
        spacing={2}
      >
        <Grid item sx={{ height: "100%", overflow: "hidden" }} md={8.5} xs={12}>
          <FlexedBox
            sx={{ height: "100%", overflow: "hidden" }}
            flexDirection="column"
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Skeleton
                  sx={{ height: "150px" }}
                  variant="rounded"
                  animation="wave"
                />
              </Grid>
              <Grid item xs={6}>
                <Skeleton
                  sx={{ height: "150px" }}
                  variant="rounded"
                  animation="wave"
                />
              </Grid>
            </Grid>
            <Box mt={2}>
              <Skeleton
                sx={{ height: "100px" }}
                variant="rounded"
                animation="wave"
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                flex: 1,
                overflow: overflow,
                flexDirection: "column",
              }}
              mt={2}
            >
              <Grid
                container
                spacing={2}
                sx={{ height: "100%", overflow: "hidden" }}
              >
                <Grid item xs={3}>
                  <Skeleton
                    sx={{ height: "100%" }}
                    variant="rounded"
                    animation="wave"
                  />
                </Grid>
             
                <Grid item xs={4.5}>
                  <Skeleton
                    sx={{ height: "100%" }}
                    variant="rounded"
                    animation="wave"
                  />
                </Grid>
                <Grid item xs={4.5}>
                  <Skeleton
                    sx={{ height: "100%" }}
                    variant="rounded"
                    animation="wave"
                  />
                </Grid>
              </Grid>
            </Box>
          </FlexedBox>
        </Grid>

        {/* <Grid item sx={{ height: "100%", overflow: overflow }} md={2.5} xs={12}>
          <FlexedBox
            sx={{ height: "100%", overflow: overflow, flexDirection: "column" }}
          >
            <Box display="flex" flex={1} width="100%">
              <Skeleton
                sx={{ height: "100%", width: "100%" }}
                variant="rounded"
                animation="wave"
              />
            </Box>

            <Box display="flex" flex={1} width="100%" mt={2}>
              <Skeleton
                sx={{ height: "100%", width: "100%" }}
                variant="rounded"
                animation="wave"
              />
            </Box>
          </FlexedBox>
        </Grid> */}
        <Grid item sx={{ height: "100%", overflow: overflow }} md={3.5} xs={12}>
          {
            <FlexedBox
              sx={{
                height: "100%",
                overflow: overflow,
                flexDirection: "column",
              }}
            >
              <Box>
                <Skeleton
                  sx={{ height: "40px" }}
                  variant="rounded"
                  animation="wave"
                />
              </Box>
              <Box mt={2}>
                <Skeleton
                  sx={{ height: "30px" }}
                  variant="rounded"
                  animation="wave"
                />
              </Box>
              <Box mt={2}>
                <Skeleton
                  sx={{ height: "100px" }}
                  variant="rounded"
                  animation="wave"
                />
              </Box>
              <FlexedBox sx={{ overflow: overflow, width: "100%" }} mt={2}>
                <Skeleton
                  sx={{ height: "100%", width: "100%" }}
                  variant="rounded"
                  animation="wave"
                />
              </FlexedBox>
            </FlexedBox>
          }
        </Grid>
      </Grid>
    </CenteredBox>
  );
};

export const LeadDetailDialog = ({
  loading,
  data,
  setData,
  title,
  viewOnly,
  callBack = () => { },
  children,
}) => {
  const overflow = { md: "hidden", xs: "auto" };
  return (
    <CustomDialog
      onSubmit={(e) => e?.preventDefault()}
      hideActionButtons={true}
      title={
        title ??
        `Lead Details: ${loading
          ? "Loading..."
          : `${data?.contacts?.[0]?.full_name ?? ""}-${data?.uid ?? ""}`
        }`
      }
      id={MODAL_KEYS.LEAD_DETAILS}
      dialogProps={{ sx: { height: "1000px", overflow: overflow } }}
    >
      <LeadDetailedViewUi
        title={title}
        children={children}
        viewOnly={viewOnly}
        data={data}
        loading={loading}
        setData={setData}
        callBack={callBack}
      />
    </CustomDialog>
  );
};
const LeadDetailedViewUi = ({
  loading,
  data,
  setData,
  title,
  viewOnly,
  callBack = () => { },
  children,
}) => {
  const theme = useTheme();
  const { user } = useSelector(state => state)
  const overflow = { md: "hidden", xs: "auto" };
  const leadOwner = moduleAccessCondition(user, [MODULES.LEAD_ADMIN]) || data?.assigned_sales_person?.id == user.data?._id
  const isEditable = moduleAccessCondition(user, [MODULES.UPDATE_LEADS]) && leadOwner 
  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: "0px",
          left: "0px",
          width: "100%",
          transform: "translate()",
        }}
      >
        <PipelineLinearProgress
          sx={{ height: "5px" }}
          variant={loading ? "indeterminate" : "determinate"}
          value={(data?.step?.step_no / data?.pipeline_id?.steps?.length) * 100}
        />
      </Box>
      {loading && <SkeletonLoader />}
      {!loading && !data?._id && (
        <CenteredBox>
          <NoDataComponent message="OOPS! Lead Does Not Exist" />
        </CenteredBox>
      )}

      {!loading && data?._id && (
        <FlexedBox sx={{ height: "100%", width: "100%" }}>
          <Grid
            container
            sx={{ height: "100%", width: "100%", overflow: overflow }}
            spacing={2}
            columnSpacing={3}
          >
            <Grid
              item
              sx={{ height: "100%", overflow: overflow }}
              md={8.5}
              xs={12}
            >
              <FlexedBox
                sx={{ height: "100%", overflow: overflow }}
                flexDirection="column"
              >
                {/* <Grid container>
                  <Switch
                    checked={true}
                    onChange={(e) => {
                      const isStarred = e.target.checked;
                      setLeadFilter(isStarred);
                      // fetchNotesByProjectId(project_id, isStarred, type);
                    }}
                    color="primary"
                  />
                </Grid> */}
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <ProjectContactDetailsUi showDates data={data} />
                  </Grid>
                  <Grid item xs={6}>
                    <ProjectSelectedSystemDetailsUi data={data} />
                  </Grid>
                </Grid>
                <Box mt={2}>
                  <ProjectSiteDetailsUi data={data} />
                </Box>

                <Box
                  sx={{ display: "flex", flex: 1, overflow: overflow }}
                  mt={2}
                >
                  <LeadIncentivesDetailsUI data={data}  titleBoxProps={{ mr: 2 }} />
                  {<ProjectInvoiceDetailsUi  data={data} setData={setData} />}

                  <ProjectDocumentMain
                    disabledEdit={!isEditable}
                    titleBoxProps={{ ml: 2 }}
                    viewOnly={true}
                    project_id={data?._id}
                    defaultList={data?.documents ?? []}
                    setDefaultList={(documents) =>
                      setData({ ...data, documents })
                    }
                  />
                </Box>
              </FlexedBox>
            </Grid>

            <Grid
              item
              sx={{ height: "100%", overflow: overflow }}
              md={3.5}
              xs={12}
            >
              {
                <FlexedBox
                  sx={{
                    height: "100%",
                    overflow: "auto",
                    flexDirection: "column",
                  }}
                  pr={1}
                >
                  <CreateProjectButton
                    id={data?._id}
                    created={ data.status != PROJECT_STATUS.NEW}
                    allowCreate={data?.lead_status == LEAD_STATUS.WON && data.status == PROJECT_STATUS.NEW && isEditable}
                    projectDetails={data}
                    callBack={(response) => setData({ ...data, ...(response ?? {}) })}
                  />
                  <Box display="flex">
                    <Box display="flex" flexDirection="column" flex={1}>
                      <StatusUpdateController
                        salesPerson={data?.assigned_sales_person?.id}
                        ButtonComponent={(props) => (
                          <StatusIcon
                            id={data?._id}
                            status={data?.lead_status}
                            size="large"
                            {...props}
                          />
                        )}
                        id={data?._id}
                        projectStatus={data?.status}
                        status={data?.lead_status}
                        onStatusChangeCallBack={(res) => {
                          setData({ ...data, ...(res ?? {}) });
                        }}
                      />

                    </Box>
                    <Box display="flex" flex={1} ml={2}>
                      <StepViewComponent
                        disabledEdit={!isEditable}
                        id={data?._id}
                        stepObj={data?.step}
                        pipelineObj={data?.pipeline_id}
                        size="large"
                        onChange={(stepObj, pipeLineObj) => {
                          setData({
                            ...data,
                            step: stepObj,
                            pipeline_id: pipeLineObj,
                          });
                        }}
                      />
                    </Box>
                  </Box>
                  <Box width="100%" mt={2}>
                    <LeadIconButtons
                      isEditable={isEditable}
                      params={data}
                      setParams={setData}
                      refreshProjectFun={callBack}
                    />
                  </Box>
                  <Box width="100%" mt={2}>
                    <LeadViewMoreButtons
                    isEditable={isEditable}
                      callBack={callBack}
                      projectDetails={data}
                      setProjectDetails={setData}
                    />
                  </Box>
                  <Box width="100%" mt={4}>
                    <AssignedSalesPerson
                      editable
                      projectId={data?._id}
                      salesPersonId={data?.assigned_sales_person?.id}
                      title={data?.assigned_sales_person?.name}
                      size="large"
                      onChangeSalesPerson={(res) =>
                        setData({ ...data, ...(res ?? {}) })
                      }
                    />
                  </Box>
                  <Box mt={2}>
                    <LeadSourceComponent
                      editable
                      projectId={data?._id}
                      sourceId={data?.source?.id}
                      name={data?.source?.name}
                      size="large"
                      onSourceChange={(res) =>
                        setData({ ...data, ...(res ?? {}) })
                      }
                    />
                  </Box>

                  <Box mt={2}>
                    <LeadTagEditor
                    disabled={!isEditable}
                      projectId={data?._id}
                      tags={data?.tags}
                      callBack={(res) => setData({ ...data, ...(res ?? {}) })}
                    />
                  </Box>

                  <CenteredBox
                    flex={0}
                    width="100%"
                    sx={{ justifyContent: "flex-end" }}
                  >
                    <Box sx={{}}>
                      {data.signature && data.signature != "" && (
                        <ImageComponent
                          src={data.signature}
                          sx={{ height: "100%", objectFit: "contain" }}
                        />
                      )}
                    </Box>
                  </CenteredBox>
                </FlexedBox>
              }
            </Grid>
          </Grid>
        </FlexedBox>
      )}
    </>
  );
};
export default LeadDetailedViewUi;
