import { useState } from "react"
import { useDispatch } from "react-redux"
import { closeModal, openModal } from "../../../store/actions/modalAction"
import { callApiAction } from "../../../store/actions/commonAction"

import MessageDilog from "../../../components/MessageDilog"
import { Box, Button, ButtonBase, CircularProgress, IconButton, Paper, Tooltip, Typography } from "@mui/material"
import { Delete, RemoveRedEye } from "@mui/icons-material"
import { deleteLeadApi } from "../../../apis/lead.api"
import { createProjectAPIController } from "../../../apis/projects.api"
import { callSnackBar } from "../../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../../utils/constants/constants"
import SubmitButton from "../../../components/button/SubmitButton"
import ProjectDetailsController from "../../projects/projectdetails/ProjectDetailsController"
import { MODAL_KEYS } from "../../../utils/constants/modal.constant"

const CreateProjectButton = ({ id, allowCreate, created, callBack = () => { }, projectDetails = {} }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const onSubmitFun = async () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await createProjectAPIController({ id }),
            (response) => {

                setLoading(false)
                callBack(response)
            },
            (err) => {
                setLoading(false)
                dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
            }
        ))
        dispatch(closeModal())
    }
    const onBtnClick = () => {
        dispatch(openModal(<MessageDilog onSubmit={onSubmitFun} title="Alert!" message={`Are you sure to send lead ${projectDetails?.uid} to project ?. `} />, "sm"))
    }
    const onViewProjectClick = () => {
        dispatch(openModal(<ProjectDetailsController project={id} onSubmit={onSubmitFun} />, "xl", undefined, MODAL_KEYS.PROJECT))
    }

    if (created) {
        return <Tooltip arrow title={"View Project"} >
            <Box display="flex" mb={1}  alignItems="center" justifyContent="flex-end" >
                <Typography variant="subtitle2" color="primary" sx={{textDecoration:"underline"}} onClick={onViewProjectClick} component={ButtonBase}>
                Open Project View {">>"}
                </Typography>

                {/* <IconButton size="small" onClick={onViewProjectClick} >
                    <RemoveRedEye fontSize="inherit" color="primary" />
                </IconButton> */}
            </Box>
            {/* <SubmitButton sx={{mb:1}} size="small" title={"View as Project"} variant="outlined" loading={loading} color="primary" onClick={onViewProjectClick} >
            
        </SubmitButton> */}
        </Tooltip>
    }
    if (!allowCreate)
        return <></>
    return <>

        {<>
            {loading && <CircularProgress color="primary" fontSize="inherit" />}
            {!loading && <Tooltip arrow title={"Create Project"} >
                <SubmitButton sx={{ mb: 1 }} size="small" title={"Create Project"} loading={loading} color="warning" onClick={onBtnClick} >

                </SubmitButton>
            </Tooltip>}
        </>}
    </>
}
export default CreateProjectButton