import { memo, useEffect, useMemo, useState } from "react";
import useValidate from "../../store/hooks/useValidator";
import CreateUi from "./CreateUi";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { closeModal } from "../../store/actions/modalAction";
import { useParams } from "react-router-dom";
import { addNotesTypeApi } from "../../apis/notesType.api";
import { addCompanyCertificateApi, updateCompanyCertificateApi } from "../../apis/companycertificate.api";
import { getCompanyByIdApi } from "../../apis/company.api";

const CreateController = ({ callBack, id }) => {
  const validate = useValidate();
  const dispatch = useDispatch();
  const params = useParams();

  const title = "Company Certificate ";
  const createApi = addCompanyCertificateApi;
    const updateApi = updateCompanyCertificateApi;
    const getByIdApi = getCompanyByIdApi;

   const user = useSelector(state=>state.user) 
  const [loading, setLoading] = useState(false);

  const [fields, setFields] = useState({
    err: "",
    id,
    company_id:user.data.company_id._id,
    title: "",
    logo:"",
  });
  const [originalDocument, setOriginalDocument] = useState({});

  const validationSchemaForCreate = useMemo(
    () => [
      {
        required: true,
        value: fields.title,
        field: "Title",
      },
      {
        required: true,
        value: fields.logo,
        field: "Logo",
      },
    ],
    [fields]
  );

  const validationSchemaForUpdate = useMemo(
    () => [
      {
        required: true,
        value: fields.title,
        field: "Title",
      },
      {
        required: true,
        value: fields.logo,
        field: "Logo",
      },
    ],
    [fields]
  );

  const createFunction = async () => {
    const validationResponse = validate(validationSchemaForCreate);

    if (validationResponse === true) {
      setLoading(true);
      dispatch(
        callApiAction(
          async () => await createApi(fields),
          async (response) => {
            await callBack(response);
            setLoading(false);
            dispatch(closeModal());
          },
          (err) => {
            setLoading(false);
            setFields({ ...fields, err });
          }
        )
      );
    } else {
      setFields({ ...fields, err: validationResponse });
    }
  };

  //   const updateFunction = async () => {
  //     const validationResponse = validate(validationSchemaForUpdate);
  //     let updatedData = { id };

  //     for (let field in originalDocument) {
  //       if (
  //         originalDocument[field] &&
  //         fields[field] &&
  //         fields[field] != originalDocument[field]
  //       ) {
  //         updatedData[field] = fields[field];
  //       }
  //     }

  //     if (validationResponse === true) {
  //       setLoading(true);
  //       dispatch(
  //         callApiAction(
  //           async () => await updateApi(updatedData),
  //           async (response) => {
  //             await callBack(response, updatedData);
  //             setLoading(false);
  //             dispatch(closeModal());
  //           },
  //           (err) => {
  //             setLoading(false);
  //             setFields({ ...fields, err });
  //           }
  //         )
  //       );
  //     } else {
  //       setFields({ ...fields, err: validationResponse });
  //     }
  //   };

  const onSubmit = async (e) => {
    e.preventDefault();
    createFunction();
  };

  //   const fetchById = (id) => {
  //     setLoading(true);
  //     dispatch(
  //       callApiAction(
  //         async () => await getByIdApi({ id }),
  //         async (response) => {
  //           setFields({ ...fields, ...response });
  //           setOriginalDocument(response);
  //           setLoading(false);
  //         },
  //         (err) => {
  //           setFields({ ...fields, err });
  //           setLoading(false);
  //         }
  //       )
  //     );
  //   };

  //   useEffect(() => {
  //     if (id) fetchById(id);
  //   }, [id]);

  return (
    <CreateUi
      title={title}
      isUpdate={id}
      loading={loading}
      fields={fields}
      onSubmit={onSubmit}
      setFields={setFields}
    />
  );
};
export default memo(CreateController);
