import {
  Button,
  ButtonGroup,
  CircularProgress,
  Collapse,
  IconButton,
  Paper,
  Skeleton,
  Switch,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { Box } from "@mui/system";
import { isHTML, moduleAccessCondition, titleCase } from "../../utils/helpers/helper";

import {
  AccountCircle,
  AddCircle,
  Notes,
  Refresh,
  Star,
  StarBorder,
  StarBorderOutlined,
  StarOutline,
  StartOutlined,
} from "@mui/icons-material";
import { CenteredBox } from "../../components/layouts/OneViewBox";
import moment from "moment";
import { PreviewIcon } from "../../components/inputs/IconSelectionDropDown";
import FileDownloadComponent from "../../components/FileDownloadComponent";
import { useState } from "react";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { callSnackBar } from "../../store/actions/snackbarAction";
import { SNACK_BAR_VARIETNS } from "../../utils/constants/constants";
import { updateActivityFavouriteApi } from "../../apis/lead.api";
import MODULES from "../../utils/constants/module.constants";

const ActivityLogComponent = ({
  activity_id,
  date,
  changed_by,
  remarks,
  refrence_urls,
  custom,
  is_favourite,
  _id,
}) => {
  const [open, setOpen] = useState(false);
  const [favourite, setFavourite] = useState(is_favourite);
  const theme = useTheme();
  const dispatch = useDispatch();
  const handleOpen = () => {
    setOpen(!open);
  };

  const handleFavourite = (id) => {
    // setLoading(true);
    dispatch(
      callApiAction(
        async () => await updateActivityFavouriteApi({ id }),
        (response) => {
          // setLoading(false);
          setFavourite(!favourite);
          dispatch(
            callSnackBar("Favourite changed.", SNACK_BAR_VARIETNS.suceess)
          );
        },
        (err) => {
          // setLoading(false);
          dispatch(
            callSnackBar("Favourite cant be changed.", SNACK_BAR_VARIETNS.error)
          );
        }
      )
    );
  };
  return (
    <Box
      mb={1}
      component={Paper}
      p={2}
      variant="outlined"
      sx={{ borderLeft: 5, borderLeftColor: custom ? "green" : "undefined" }}
    >
      {/* */}

      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Typography
            variant="body1"
            lineHeight="100%"
            sx={{ color: activity_id?.color }}
          >
            <PreviewIcon
              value={activity_id?.icon}
              fontSize="inherit"
            ></PreviewIcon>
          </Typography>
          <Typography variant="body2" lineHeight="100%" fontWeight={600} ml={2}>
            {titleCase(activity_id?.title)}
          </Typography>
          <Typography variant="caption" lineHeight="100%" ml={2}>
            {moment(date).format("DD/MM/YYYY HH:mm")}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" ml={3}>
          <Typography variant="body1" lineHeight="100%">

            {favourite === true ? (
              <Star
                fontSize="inherit"
                sx={{
                  color: theme.palette.primary.main,
                }}
                onClick={() => handleFavourite(_id)}
              />
            ) : (
              <StarBorder
                fontSize="inherit"
                onClick={() => handleFavourite(_id)}
              />
            )}
          </Typography>
          <Typography variant="body1" lineHeight="100%">
            <AccountCircle fontSize="inherit" />
          </Typography>
          <Typography variant="body2" lineHeight="100%" ml={2}>
            {changed_by?.name}
          </Typography>
        </Box>
      </Box>
      {remarks && (
        <Tooltip title="This log has remarks">
          <IconButton size="small" onClick={handleOpen}>
            <Notes fontSize="inherit" />
          </IconButton>
        </Tooltip>
      )}
      {
        <Collapse in={open}>
          <Box display="flex" mt={2} sx={{ width: "100%", overflow: "hidden" }}>
            {isHTML(remarks) ? (
              <Box
                p={2}
                component={Paper}
                variant="outlined"
                sx={{ width: "100%" }}
              >
                <Box
                  dangerouslySetInnerHTML={{
                    __html: remarks,
                  }}
                ></Box>
              </Box>
            ) : (
              <Typography variant="caption" sx={{ whiteSpace: "pre-wrap" }} lineHeight="100%">
                {remarks}
              </Typography>
            )}
            <Typography variant="caption" lineHeight="100%"></Typography>
          </Box>
        </Collapse>
      }
      <Box display="flex">
        {refrence_urls?.map((item) => (
          <FileDownloadComponent src={item} />
        ))}
      </Box>
    </Box>
  );
};

const ListUi = ({ filterfavourite, handleSwitchToggle, filters, onRefresh, list, loading, onCreateBtnClick }) => {
  const theme = useTheme()
  const { user } = useSelector(state => state)
  return (
    <>
      <Box
        display="flex"
        zIndex={2}
        pb={1}
        justifyContent="space-between"
        sx={{
          position: "sticky",
          top: "0px",
          backgroundColor: "inherit",
          background: "white",
        }}
      >
        <Tooltip arrow title="Refresh">
          <IconButton size="small" onClick={onRefresh}>
            <Refresh color="primary" />
          </IconButton>
        </Tooltip>
        <Box sx={{ display: "flex" }}>
          <Tooltip title="Only Favorite Notes">
            <IconButton>
              <Star
                fontSize="inherit"
                sx={{
                  color: theme.palette.primary.main,
                }}
              />
            </IconButton>
          </Tooltip>
          <Switch
            checked={filterfavourite}
            onChange={handleSwitchToggle}
            color="primary"
          />
        </Box>
        <Box>
          {moduleAccessCondition(user, [MODULES.ACTIVITY_LOGS_VIEW]) && <Button
            disableElevation
            startIcon={<AddCircle />}
            onClick={onCreateBtnClick}
            variant={"contained"}
            size="small"
          >
            Add
          </Button>}
        </Box>
      </Box>
      {loading ? (
        <CenteredBox>
          <Box width="100%">
            <Skeleton
              height="70px"
              component={Box}
              mt={1}
              width="100%"
              variant="rounded"
            ></Skeleton>
            <Skeleton
              height="70px"
              component={Box}
              mt={1}
              width="100%"
              variant="rounded"
            ></Skeleton>
            <Skeleton
              height="70px"
              component={Box}
              mt={1}
              width="100%"
              variant="rounded"
            ></Skeleton>
            <Skeleton
              height="70px"
              component={Box}
              mt={1}
              width="100%"
              variant="rounded"
            ></Skeleton>
            <Skeleton
              height="70px"
              component={Box}
              mt={1}
              width="100%"
              variant="rounded"
            ></Skeleton>
            <Skeleton
              height="70px"
              component={Box}
              mt={1}
              width="100%"
              variant="rounded"
            ></Skeleton>
          </Box>
        </CenteredBox>
      ) : list && Array.isArray(list) && list.length > 0 ? (
        <Box mt={3}>
          {list
            .sort((a, b) => moment(b.date).valueOf() - moment(a.date).valueOf())
            .map((log, index) => (
              
              <ActivityLogComponent key={log?._id} {...log} />
            ))}
          {list?.find((item) => item.custom) && (
            <Typography variant="caption">
              Here green left border shows that log is marked by user i.e. it is
              not auto log
            </Typography>
          )}
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h4" color="grey.main">
            No Logs
          </Typography>
        </Box>
      )}
    </>
  );
};
export default ListUi;
