import { Button } from "@mui/material"
import { useState } from "react"
import SubmitButton from "../../button/SubmitButton"
import { callApiAction } from "../../../store/actions/commonAction"
import fileDownload from "js-file-download"
import { useDispatch } from "react-redux"

const DownloadDemoCSVButton = ({ lazyFun = async () => { }, title = "Download Demo" }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)

    const fetch = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await lazyFun(),
            (response) => {
                fileDownload(response, "demo.csv")
                setLoading(false)
            },
            (err) => {

                setLoading(false)
            },
            true
        ))
    }
    return <SubmitButton disableElevation title={title} loading={loading} onClick={fetch} >

    </SubmitButton>


}
export default DownloadDemoCSVButton