import { memo, useEffect, useMemo, useState } from "react"
import useValidate from "../../store/hooks/useValidator"
import CreateUi from "./CreateUi"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { closeModal } from "../../store/actions/modalAction"
import { MODAL_KEYS } from "../../utils/constants/modal.constant"
import { addRejectionReasonApi } from "../../apis/rejectionreasons.api"

const CreateController = ({ callBack, id }) => {
    const validate = useValidate()
    const dispatch = useDispatch()
    const modalKey = MODAL_KEYS.REJECTION_REASON
    const title = "Rejection Reason "
    const createApi = addRejectionReasonApi

    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',
        title: '',

    })
    
    const validationSchemaForCreate = useMemo(() => ([

        {
            required: true,
            value: fields.title,
            field: 'Reason',
        }
    ]), [fields])

 
    const createFunction = async () => {
        const validationResponse = validate(validationSchemaForCreate)

        if (validationResponse === true) {


            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await createApi(fields),
                    async (response) => {

                        await callBack(response)
                        setLoading(false)
                        dispatch(closeModal(modalKey))
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }




    const onSubmit = async (e) => {
        e.preventDefault()
     
            createFunction()

    }


    return <CreateUi modalKey={modalKey} title={title}  loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
}
export default memo(CreateController)