import { MenuItem } from "@mui/material"

import AsyncDropDown from "../inputs/AsyncDropDown"
import { StyledSearchBar } from "../inputs/SearchBar"
import { useSelector } from "react-redux"
import { moduleAccessCondition } from "../../utils/helpers/helper"
import MODULES from "../../utils/constants/module.constants"
import getTeamApi from "../../apis/team.api"

const TeamFilterDropDown = ({ titleComponent, filters, setFilters, returnFullObj, ...props }) => {

    const { user } = useSelector(state => state)

    if (!moduleAccessCondition(user, [MODULES.VIEW_TEAMS]))
        return <></>
    return <>
        {titleComponent}
        <AsyncDropDown

            InputComponent={(props) => <StyledSearchBar placeholder="Select Team"  {...props} margin="none" size="small" />}
            lazyFun={async (params) => { return await getTeamApi({ ...params }) }}

            OptionComponent={({ option, ...rest }) => {
                return <MenuItem {...rest}>{option.name}</MenuItem>
            }}
            onChange={async (changedVal) => {

                setFilters({ ...filters, team: changedVal ? (returnFullObj ? changedVal : changedVal._id) : null })
            }}
            titleKey={'name'}
            valueKey={"_id"}
            {...props}
        />
    </>


}

export default TeamFilterDropDown