import { useCallback, useEffect, useState, useMemo } from "react"
import ListUi from "./ListUi"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { getEmailIntegrationsApi, deleteEmailIntegrationApi } from "../../apis/emailIntegration.api"
import { openModal } from "../../store/actions/modalAction"
import { findNameByRole, moduleAccessCondition } from "../../utils/helpers/helper"
import { Box, CircularProgress, IconButton } from "@mui/material"
import { Delete, Edit, Undo } from "@mui/icons-material"
import MessageDilog from "../../components/MessageDilog"
import MODULES from "../../utils/constants/module.constants"
import CreateController from "./CreateController" 
import { closeModal } from "../../store/actions/modalAction"

const ActionComponent = ({ params, setParams, deleteApi, deleted }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const { user } = useSelector(state => state)

    const onEdit = () => {
        dispatch(openModal(<CreateController id={params._id} callBack={(response, updatedData) => {
            setParams({ ...params, ...updatedData })
        }} />, "sm"))
    }
    const deleteFun = async () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await deleteEmailIntegrationApi({user : user.data.email}),
            (response) => {
                setParams({})
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
        dispatch(closeModal())
    }



    const onDelete = () => {
        dispatch(openModal(<MessageDilog onSubmit={deleteFun} title="Alert!" message={`Are you sure to delete listening of "${params.user || params.title}"?`} />, "sm"))
    }


    // const onUndo = () => {
    //     dispatch(openModal(<MessageDilog onSubmit={undoDeleteFun} title="Alert!" message={`Are you sure to undo delete for "${params.name || params.title}"?`} />, "sm"))
    // }

    // if (params.deleted && moduleAccessCondition(user, [MODULES.UNDO_DELETED_USERS], []))
    //     return <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}> <IconButton onClick={onUndo}>
    //         <Undo color="error" />
    //     </IconButton>
    //     </Box>

    return <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
        {/* {moduleAccessCondition(user, [MODULES.UPDATE_USERS], []) && <IconButton disabled={loading} size="inherit" onClick={onEdit}>
            <Edit color="info" fontSize="inherit" />
        </IconButton>} */}
        {moduleAccessCondition(user, [MODULES.DELETE_USERS], []) && <>
            {loading && <CircularProgress color="primary" fontSize="inherit" />}
            {!loading && <IconButton disabled={loading} size="inherit" onClick={onDelete}>
                <Delete color="error" fontSize="inherit" />
            </IconButton>}
        </>}
    </Box>
}

const ListController = () => {
    const dispatch = useDispatch()
    const { user } = useSelector(state => state)

    const title = "Email Integration" // Updated title
    const deleteApi = deleteEmailIntegrationApi
    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 10,
        search: '',
        role: '',
        sort: '',
        sortDirection: 1,
        deleted: null
    })

    const handleDeleteIntegration =  () => {
        dispatch(callApiAction(
            async () => await deleteEmailIntegrationApi({user: user.data.email}),
            (response) => {
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
  };

    const columns = useMemo(() => {
        const arr = [
            { id: 1, fieldName: 'user', label: 'Email', align: "left", sort: true },
            { id: 2, fieldName: 'host', label: 'Host', align: "left", sort: true },
            { id: 3, fieldName: 'port', label: 'Port', align: "left", sort: true },
        ]

        if (filters.deleted) {
            arr.push({
                id: 6,
                fieldName: 'deletedaction',
                label: 'Action',
                align: "right",
                renderValue: (params, setParams) => <ActionComponent key={Math.random()} deleted={true} deleteApi={deleteEmailIntegrationApi} params={{ ...params, deleted: true }} setParams={setParams} />,
            })
        } else {
            arr.push({
                id: 5,
                fieldName: 'action',
                label: 'Action',
                align: "right",
                renderValue: (params, setParams) => <ActionComponent key={Math.random()} deleteApi={deleteEmailIntegrationApi} params={params} setParams={setParams} />,
            })
        }

        return arr
    }, [dispatch, filters.deleted])

    const [loading, setLoading] = useState(false)
    const [list, setList] = useState({})

    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await getEmailIntegrationsApi({user: user.data.email}),
            (response) => {
                setList(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }

    const onCreateBtnClick = () => {
        dispatch(openModal(<CreateController
            callBack={async () => { fetchList() }}
            fields={[
                { name: 'email', label: 'Email' },
                { name: 'password', label: 'Password', type: 'password' },
                { name: 'port', label: 'Port', type: 'number' },
                { name: 'host', label: 'Host' }
            ]}
        />, "sm"))
    }

    useEffect(() => {
        fetchList()
    }, [filters])

    return (
        <>
            <ListUi
                title={title}
                onCreateBtnClick={onCreateBtnClick}
                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}
                deleteApi = {handleDeleteIntegration}
            />
        </>
    )
}
export default ListController;
