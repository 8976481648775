
import { Autocomplete, Paper, Typography, styled } from "@mui/material"
import { Box } from "@mui/system"
import { useSelector } from "react-redux"

import SubmitButton from "../../components/button/SubmitButton"
import { USER_ROLES } from "../../utils/constants/constants"
import DataTable from "../../components/tables/DataTable"
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxInDesktop"
import AsyncSearchBar from "../../components/inputs/AsynSearchBar"

import { findObjectKeyByValue, moduleAccessCondition, titleCase } from "../../utils/helpers/helper"
import SalesPersonFilterDropDown from "../../components/filterdropdowns/SalesPersonFilter"
import TeamFilterDropDown from "../../components/filterdropdowns/TeamFilterDropDown"
import FilterButton from "./filters/FilterButton"
import { LEAD_STATUS } from "../../utils/constants/lead.constant"
import { StyledSearchBar } from "../../components/inputs/SearchBar"
import LeadTimeFilters from "./filters/LeadTimeFilter"
import MODULES from "../../utils/constants/module.constants"



const FilterTitleBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }

}))
const FiltersBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",

    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }
}))


const ListUi = ({ title, filters, exportLoading, setFilters, list, modal, loading, onCreateBtnClick, onExportBtnClick, columns }) => {

    const user = useSelector(state => state.user)

    return (
        <>
            {!modal && <Box mb={2}>
                <Paper elevation={0} sx={{ width: "100%", p: 2 }}  >
                    <Box p={2}>
                        {<FilterTitleBox >
                            <Typography variant="h5" mb={2} >{title}</Typography>

                            <Box mb={2} sx={{ display: "flex" }}>
                                {moduleAccessCondition(user, [MODULES.CREATE_LEADS,MODULES.LEAD_ADMIN]) && <SubmitButton variant="contained" onClick={onCreateBtnClick} title={"Create Lead"} />}
                            </Box>


                        </FilterTitleBox>}


                        <FiltersBox mt={1} >

                            <PaddingBoxInDesktop sx={{ display: "flex" }}>
                                <LeadTimeFilters filters={filters} setFilters={setFilters} forPannel />
                            </PaddingBoxInDesktop>

                            {<PaddingBoxInDesktop sx={{ display: "flex", flex: 1, flexDirection: "column" }} >
                                <FiltersBox sx={{ width: "100%" }} >
                                    <PaddingBoxInDesktop sx={{ display: "flex", flex: 1 }} pl={3}>
                                        <TeamFilterDropDown
                                            key={filters?.refreshKey}
                                            value={filters?.team}
                                            returnFullObj
                                            id={'teams-leads'}
                                            filters={filters}
                                            setFilters={setFilters}
                                        />
                                    </PaddingBoxInDesktop>
                                    {<PaddingBoxInDesktop sx={{ display: "flex", flex: 1 }} pl={3}>
                                        <SalesPersonFilterDropDown
                                            value={filters?.assigned_sales_person}
                                            returnFullObj
                                            id={'sales-leads'}
                                            filters={filters}
                                            setFilters={setFilters}
                                            key={filters.team?._id + filters?.refreshKey}
                                        />
                                    </PaddingBoxInDesktop>}

                                </FiltersBox>
                                <FiltersBox sx={{ width: "100%" }} mt={2} >
                                    <PaddingBoxInDesktop sx={{ display: "flex", flex: 1 }} pl={3} >
                                        <Autocomplete
                                            multiple
                                            key={filters?.refreshKey}
                                            size="small"
                                            value={filters?.lead_status?.map?.(item => ({ _id: item, label: findObjectKeyByValue(item, LEAD_STATUS) }))}

                                            onChange={(e, newVal) => {
                                                console.log(newVal)
                                                setFilters({ ...filters, lead_status: newVal ? newVal?.map((item) => item?._id) : [] })
                                            }}
                                            options={[...Object.keys(LEAD_STATUS).map((key) => ({ label: titleCase(key), _id: LEAD_STATUS[key] }))]}

                                            sx={{ width: "100%", display: "flex", "*": { display: "flex", justifyContent: "center" } }}
                                            renderInput={(params) => <StyledSearchBar placeholder="Select Status" {...params} size="small" />}
                                        />
                                    </PaddingBoxInDesktop>
                                </FiltersBox>
                            </PaddingBoxInDesktop>}



                        </FiltersBox>

                        <Box display="flex" justifyContent="flex-end" mt={1}>
                            <FilterButton defaultFilters={filters} setDefaultFilters={setFilters} />
                        </Box>
                    </Box>
                </Paper>
            </Box>}
            <Box mb={3}>
                <Paper elevation={0} sx={{ width: "100%", padding: modal ? 0 : 2 }} >


                    {<Box mb={2} p={2} >
                        {!modal && <>



                            {/* <FilterTitleBox p={2} pb={0}>
                                <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1 }}  >


                                    <TimeRangeSelector placeHolder="Last Updated Date" onChange={(newRange) => { setFilters({ ...filters, lastUpdateStartDate: newRange.startDate, LastUpdateEndDate: newRange.endDate }) }} />

                                </PaddingBoxInDesktop>
                                <PaddingBoxInDesktop pl={3} mb={2} sx={{ display: "flex", flex: 1 }}  >


                                    <TimeRangeSelector placeHolder="Created Date" onChange={(newRange) => { setFilters({ ...filters, ...newRange }) }} />

                                </PaddingBoxInDesktop>
                            </FilterTitleBox> */}



                        </>}
                        <FiltersBox sx={{ display: "flex", width: "100%" }}  >

                            <Box  >
                                {moduleAccessCondition(user, [MODULES.LEAD_DATA_EXPORT]) && <SubmitButton loading={exportLoading} variant="contained" onClick={onExportBtnClick} title={"Export CSV"} />}
                            </Box>
                            <PaddingBoxInDesktop sx={{ display: "flex", justifyContent: "flex-end", flex: 1 }}  >


                            </PaddingBoxInDesktop>
                            <PaddingBoxInDesktop sx={{ display: "flex", flex: 1, maxWidth: "400px" }} pl={3} >
                                <AsyncSearchBar

                                    fullWidth
                                    title="Search Lead "
                                    size="small"
                                    placeholder={"Search Lead"}
                                    defaultValue={filters.search}
                                    onChange={(changedVal) => { setFilters({ ...filters, search: changedVal }) }}
                                />
                                {/* <StyledSearchBar fullWidth title="Search by Name | Email | Phone " size="small" placeholder={"Search Name | Email | Phone"} value={filters.search} onChange={(e) => { setFilters({ ...filters, search: e.target.value }) }} /> */}
                            </PaddingBoxInDesktop>
                        </FiltersBox>

                    </Box>}

                    <Box sx={{ minHeight: "300px" }} p={2} pt={0}>
                        <DataTable columns={columns} rows={list.result ? list.result : []} count={list.total ?? 0} filters={filters} setFilters={setFilters} loading={loading} />
                    </Box>
                </Paper >
            </Box >

        </>
    )
}
export default ListUi