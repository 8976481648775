import { useRef, useState } from "react"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchLeaderBoardData } from "../../../store/actions/leaderboardActions"
import SalesLeaderBoardUi from "./SaleLeaderBoardUi"

const SalesLeaderBoardController = () => {
    const dispatch = useDispatch()
    const { leaderboard } = useSelector(state => state)
    const { filters, data } = leaderboard
    const [loading, setLoading] = useState(false)
    const loaded = useRef(false)
    useEffect(() => {
        if (loaded.current) {
            setLoading(true)
            dispatch(fetchLeaderBoardData(undefined, () => setLoading(false), () => setLoading(false)))
        } else {
            if (data && Array.isArray(data) && data.length == 0) {
                setLoading(true)
                dispatch(fetchLeaderBoardData(undefined, () => setLoading(false), () => setLoading(false)))
            }
            loaded.current = true
        }




    }, [
        filters
    ])

    console.log('filters', filters)
    return <SalesLeaderBoardUi
        loading={loading}
    />
}
export default SalesLeaderBoardController