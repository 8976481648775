import { CircularProgress, MenuItem } from "@mui/material"
import { memo } from "react"
import { useSelector } from 'react-redux'


import AsyncDropDown from "../../../../components/inputs/AsyncDropDown"

import CustomDialog from "../../../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../../../components/layouts/OneViewBox"
import getSourceApi from "../../../../apis/sources.api"




const LeadUpdateSourceUi = ({ title, fields, setFields, loading, onSubmit, modalKey }) => {
    const { user } = useSelector(state => state)

    return <>

        <CustomDialog
            id={modalKey}

            loading={loading}
            err={fields.err}

            onSubmit={onSubmit}
            title={` ${title}`}
            closeText="Close"
            confirmText={`Update`}
        >

            {loading ? <CenteredBox><CircularProgress /></CenteredBox> :
                <>
                    <AsyncDropDown
                        id={'lead-source-update'}
                        defaultVal={fields.source}
                        lazyFun={async (params) => { return await getSourceApi({ ...params}) }}
                        label="Source*"
                        OptionComponent={({ option, ...rest }) => {
                            return <MenuItem {...rest}>{option.name}</MenuItem>
                        }}
                        onChange={async (changedVal) => { setFields({ ...fields, source: changedVal ? changedVal : null }) }}
                        titleKey={'name'}
                        valueKey={"_id"}
                    />

                </>}

        </CustomDialog>
    </>
}
export default memo(LeadUpdateSourceUi)