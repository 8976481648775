
import CustomDialog from "../../../components/layouts/common/CustomDialog"
import { MODAL_KEYS } from "../../../utils/constants/modal.constant"
import { Box, Button, Grid, Paper, Skeleton, styled, useTheme } from "@mui/material"
import ImageComponent from "../../../components/ImageComponent"
import { CenteredBox } from "../../../components/layouts/OneViewBox"
import NoDataComponent from "../../../components/layouts/NoDataComponent"
import ProjectStatus from "./ProjectStatus"
import ProjectIconButtons from "./buttons/ProjectIconButtons"
import ProjectViewMoreButtons from "./buttons/ProjectViewMoreButtons"
import ProjectInvoiceDetailsUi from "./ProjectInvoiceDetailsUi"
import ProjectDocumentMain from "../projectdocuments/ProjectDocumentMain.controller"

import ProjectContactDetailsUi, { ProjectSelectedSystemDetailsUi, ProjectSiteDetailsUi } from "./ProjectContactDetailsUi"
import ProjectFlagTrakker from "./ProjectFlagTrakker"
import UpdateStatusButton from "./buttons/UpdateStatusButton"
import UpdateFlagsButtons from "./buttons/UpdateFlagsButtons"
const FlexedBox = styled(Box)(({ theme }) => ({
    display: "flex",
    flex: 1,

    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
        overflow: "auto",
    }
}))

const SkeletonLoader = () => {
    const overflow = "hidden"
    return <CenteredBox>
        {/* <Grid container spacing={2} sx={{ height: "100%", width: "100%" }} >
            <Grid item sx={{ height: "100%" }} md={9} xs={12} >
                <Skeleton sx={{ height: "100%" }} variant="rounded" animation="wave" />
            </Grid>
            <Grid item sx={{ height: "100%" }} md={3} xs={12}>
                <FlexedBox sx={{ height: "100%", flexDirection: "column" }}>
                    <Box mb={2} sx={{ height: "30px" }}>
                        <Skeleton sx={{ height: "100%", width: "100%" }} variant="rounded" animation="wave" />
                    </Box>
                    <FlexedBox>
                        <Skeleton sx={{ height: "100%", width: "100%" }} variant="rounded" animation="wave" />
                    </FlexedBox>
                    <Box mt={2} sx={{ height: "80px" }}>
                        <Skeleton sx={{ height: "100%", width: "100%" }} variant="rounded" animation="wave" />
                    </Box>
                </FlexedBox>

            </Grid>
        </Grid> */}
        <Grid container sx={{ height: "100%", width: "100%", overflow: 'hidden' }} spacing={2}>
            <Grid item sx={{ height: "100%", overflow: 'hidden' }} md={7} xs={12} >
                <FlexedBox sx={{ height: "100%", overflow: 'hidden' }} flexDirection="column" >
                    <Grid container spacing={2} >
                        <Grid item xs={6} ><Skeleton sx={{ height: "150px" }} variant="rounded" animation="wave" /></Grid>
                        <Grid item xs={6} ><Skeleton sx={{ height: "150px" }} variant="rounded" animation="wave" /></Grid>
                    </Grid>
                    <Box mt={2} >
                        <Skeleton sx={{ height: "100px" }} variant="rounded" animation="wave" />
                    </Box>

                    <Box sx={{ display: "flex", flex: 1, overflow: overflow, flexDirection: "column" }} mt={2} >
                        <Grid container spacing={2} sx={{ height: "100%", overflow: 'hidden' }}>
                            <Grid item xs={3}  >
                                <Skeleton sx={{ height: "100%" }} variant="rounded" animation="wave" />
                            </Grid>
                            <Grid item xs={3} >
                                <Skeleton sx={{ height: "100%" }} variant="rounded" animation="wave" />
                            </Grid>
                            <Grid item xs={3} >
                                <Skeleton sx={{ height: "100%" }} variant="rounded" animation="wave" />
                            </Grid>
                            <Grid item xs={3} >
                                <Skeleton sx={{ height: "100%" }} variant="rounded" animation="wave" />
                            </Grid>
                        </Grid>
                    </Box>
                </FlexedBox>
            </Grid>

            <Grid item sx={{ height: "100%", overflow: overflow }} md={2.5} xs={12} >
                <FlexedBox sx={{ height: "100%", overflow: overflow, flexDirection: "column" }}>
                    <Box display="flex" flex={1} width="100%">
                        <Skeleton sx={{ height: "100%", width: "100%" }} variant="rounded" animation="wave" />
                    </Box>

                    <Box display="flex" flex={1} width="100%" mt={2}>
                        <Skeleton sx={{ height: "100%", width: "100%" }} variant="rounded" animation="wave" />
                    </Box>
                </FlexedBox>
            </Grid>
            <Grid item sx={{ height: "100%", overflow: overflow }} md={2.5} xs={12}>
                {<FlexedBox sx={{ height: "100%", overflow: overflow, flexDirection: "column" }}>
                    <Box >
                        <Skeleton sx={{ height: "40px" }} variant="rounded" animation="wave" />
                    </Box>
                    <Box mt={2}>
                        <Skeleton sx={{ height: "30px" }} variant="rounded" animation="wave" />
                    </Box>
                    <Box mt={2}>
                        <Skeleton sx={{ height: "100px" }} variant="rounded" animation="wave" />
                    </Box>
                    <FlexedBox sx={{ overflow: overflow, width: "100%" }} mt={2}>
                        <Skeleton sx={{ height: "100%", width: "100%" }} variant="rounded" animation="wave" />
                    </FlexedBox>

                </FlexedBox>}
            </Grid>
        </Grid>
    </CenteredBox>
}


const ProjectActionsSection = ({ projectDetails, setProjectDetails, callBack }) => {
    const theme = useTheme()
    return <Paper component={FlexedBox} width="100%" variant="outlined" sx={{ height: "100%", borderColor: "primary.main", flexDirection: "column", overflow: "hidden" }} >
        <Box sx={{ background: theme.palette.primary.light, borderBottom: 1 }} p={2} >
            Actions
        </Box>
        <FlexedBox p={2} sx={{ overflow: "auto" }} flexDirection="column">
            <UpdateFlagsButtons callBack={callBack} projectDetails={projectDetails} />
        </FlexedBox>
        <Box sx={{ borderTop: "2px dashed " + theme.palette.primary.main, background: theme.palette.secondary.main }} p={2}>
            <UpdateStatusButton projectDetails={projectDetails} callBack={callBack} />
        </Box>
    </Paper>
}

export const ProjectDetailsDialog = ({ loading, data, setData, title, viewOnly, callBack = () => { }, children }) => {
    const overflow = { md: "hidden", xs: "auto" }
    return <CustomDialog
        onSubmit={(e) => e?.preventDefault()}
        hideActionButtons={true}
        title={title ?? `Project Details: ${loading ? "Loading..." : (`${data?.contacts?.[0]?.full_name ?? ""}-${data?.uid ?? ""}`)}`}
        id={MODAL_KEYS.PROJECT}
        dialogProps={{ sx: { height: "1000px", overflow: overflow } }}

    >
        <ProjectDetailsUi
            title={title}
            children={children}
            viewOnly={viewOnly}
            data={data}
            loading={loading}
            setData={setData}
            callBack={callBack}
        />
    </CustomDialog>
}
const ProjectDetailsUi = ({ loading, data, setData, title, viewOnly, callBack = () => { }, children }) => {
    const theme = useTheme()
    // const break
    const overflow = { md: "hidden", xs: "auto" }
    return (
        <>
            {loading && <SkeletonLoader />}
            {!loading && !data?._id && <CenteredBox>
                <NoDataComponent message="OOPS! Project Does Not Exist" />
            </CenteredBox>}
            {!loading && data?._id && <FlexedBox sx={{ height: "100%", width: "100%" }} >
                <Grid container sx={{ height: "100%", width: "100%", overflow: overflow }} spacing={2}>
                    <Grid item sx={{ height: "100%", overflow: overflow }} md={7} xs={12} >
                        <FlexedBox sx={{ height: "100%", overflow: overflow }} flexDirection="column" >
                            <Grid container spacing={2} >
                                <Grid item xs={6} ><ProjectContactDetailsUi data={data} /></Grid>
                                <Grid item xs={6} ><ProjectSelectedSystemDetailsUi data={data} /></Grid>
                            </Grid>
                            <Box mt={2} >
                                <ProjectSiteDetailsUi data={data} />
                            </Box>

                            <Box sx={{ display: "flex", flex: 1, overflow: overflow, flexDirection: "column" }} mt={2} >
                                <ProjectFlagTrakker overflow={overflow} data={data} />
                            </Box>
                        </FlexedBox>
                    </Grid>
                    {/* <Grid item sx={{ height: "100%", overflow: overflow }} md={0.2} xs={12} >
                    </Grid> */}
                    <Grid item sx={{ height: "100%", overflow: overflow }} md={2.5} xs={12} >
                        <FlexedBox sx={{ height: "100%", overflow: overflow, flexDirection: "column" }}>

                            {<ProjectInvoiceDetailsUi data={data} setData={setData} />}
                            <Box mt={2} />
                            <ProjectDocumentMain viewOnly={viewOnly} project_id={data?._id} defaultList={data?.documents ?? []} setDefaultList={(documents) => setData({ ...data, documents })} />
                        </FlexedBox>
                    </Grid>
                    <Grid item sx={{ height: "100%", overflow: overflow }} md={2.5} xs={12}>
                        {!viewOnly ? <FlexedBox sx={{ height: "100%", overflow: overflow, flexDirection: "column" }}>
                            <ProjectStatus status={data?.status} />
                            <Box mt={2}>
                                <ProjectIconButtons params={data} setParams={setData} />
                            </Box>
                            <Box mt={2}>
                                <ProjectViewMoreButtons callBack={callBack} projectDetails={data} setProjectDetails={setData} />
                            </Box>
                            <FlexedBox sx={{ overflow: overflow }} mt={2}>
                                <ProjectActionsSection projectDetails={data} setProjectDetails={setData} callBack={callBack} />
                            </FlexedBox>
                            <CenteredBox flex={0} width="100%" sx={{ justifyContent: "flex-end" }}>
                                <Box sx={{ height: "50px" }} p={2}>
                                    {data.signature && data.signature != '' && <ImageComponent src={data.signature} sx={{ height: "100%", objectFit: "contain" }} />}
                                </Box>
                            </CenteredBox>
                        </FlexedBox> : children}
                    </Grid>
                </Grid>
            </FlexedBox>}

        </>)
}
export default ProjectDetailsUi