import * as React from 'react';

import Menu from '@mui/material/Menu';
import { IconButton, MenuItem, MenuList, Tooltip, Typography } from '@mui/material';
import { WbTwilight } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import LeadCreateInOpenSolarButton from './LeadCreateInOpenSolar';
import OpenInOpenSolarButton from './OpenInOpenSolarButton';
import LeadRefreshFromOpenSolarButton from './LeadRefreshFromOpenSolarButton';
import { OpenSolarFilesViewButton } from './documents/OpenSolarFilesController';
import { AttchOpenSolarProjectButton } from './attach-existing/AttchOpenSolarProjectController';
import { USER_ROLES } from '../../../utils/constants/constants';
import { moduleAccessCondition } from '../../../utils/helpers/helper';
import MODULES from '../../../utils/constants/module.constants';

const OpenSolarButton = ({ disableEdit,projectId, openSolarId, projectDetails, setProjectDetails, callBack = () => { } }) => {
  const { user } = useSelector(state => state)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


const allowedToEdit = moduleAccessCondition(user,[MODULES.UPDATE_LEADS])



  return (
    <div>
      <Tooltip title="Open Solar" >

        <IconButton
          id="open-solar-button"
          aria-controls={open ? 'edit-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <WbTwilight color='warning' />
        </IconButton>
      </Tooltip>
      <Menu
        title='Open SOlar'
        id="edit-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'open-solar-button',
        }}
      >
        <MenuList

        >
          <MenuItem disableRipple disabled sx={{ py: 0 }} >
            <Typography variant='caption' >
              Open Solar
            </Typography>
          </MenuItem>
          {(!openSolarId || user?.data?.role == USER_ROLES.admin) && !disableEdit && allowedToEdit && <AttchOpenSolarProjectButton callBack={callBack} open_solar_id={openSolarId} id={projectId} />}
          {!openSolarId && <LeadCreateInOpenSolarButton projectId={projectId} callBack={callBack} />}
        
          {openSolarId && <OpenInOpenSolarButton openSolarProjectId={openSolarId} />}
         
          {openSolarId && !disableEdit && <LeadRefreshFromOpenSolarButton openSolarProjectId={openSolarId} callBack={() => {
            callBack()
            handleClose()
          }} />}
           {openSolarId && !disableEdit&& <LeadCreateInOpenSolarButton isUpdate projectId={projectId} callBack={callBack} />}
          {openSolarId && !disableEdit&& <OpenSolarFilesViewButton projectId={projectDetails?._id} openSolarFiles={projectDetails?.open_solar_files ?? []} setOpenSolarFiles={(files) => {
            setProjectDetails({ ...projectDetails, open_solar_files: files })
          }} />}
        </MenuList>
      </Menu>
    </div>
  );
}
export default OpenSolarButton