import axios from "axios";
import { getHeaders } from "../utils/helpers/helper";
import endpoints from "./endpoints";

export const getNotesTypeApi = async (params) => {
  const callResponse = await axios({
    url: endpoints.NotesTypeFetch,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const addNotesTypeApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.NotesTypeBase,
    method: "POST",
    headers: getHeaders(),

    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const deleteNotesTypeApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.NotesTypeDelete,
    method: "DELETE",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const updateShowInstallerField = async (data) => {
  const callResponse = await axios({
    url: endpoints.showInstallerUpdate,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

// export const deleteOfficesField = async data => {
//   const callResponse = await axios({
//     url: endpoints.OfficesBase,
//     method: "DELETE",
//     headers: getHeaders(),
//     data,
//   })
//     .then(response => response.data)
//     .catch(err => err.response.data);

//   return callResponse;
// };

// export const getOfficesByIdApi = async (params) => {
//     const callResponse = await axios({
//       url: endpoints.OfficesById,
//       method: "get",
//       headers: getHeaders(),
//       params,
//     })
//       .then((response) => response.data)
//       .catch((err) => err.response.data);

//     return callResponse;
//   };

export default getNotesTypeApi;
