import { actions } from "../../utils/constants/constants";



export const initialLeadFilters = {

    dateField: 'createdAt',
    dateTitle:null,
    startDate: null,
    step: null,
    endDate: null,
    lead_status: [],
    team: null,
    assigned_sales_person: null,
    source: null,
    tags: [],
    phase: null,
    property_type: null,
    roof_type: null,
    roof_pitch: null,
    switch_board: null,
    no_of_storeys: null,
    e_distributor: null,
    e_retailer: null,
    state: null

}

const leadFiltersReducer = (state = initialLeadFilters, action) => {

    switch (action.type) {

        case actions.LEAD_FILTER_CHANGE:
            
            return {...state,...(action.value??{})}

        default: return {...state}
    }

}
export default leadFiltersReducer