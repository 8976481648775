import { Badge, Box, Typography } from "@mui/material"
import { CallButton, MailButton } from "../../projects/projectdetails/buttons/ProjectIconButtons"
import { CenteredBox } from "../../../components/layouts/OneViewBox"
import { viewLimitedLengthName } from "../../../utils/helpers/helper"
import { LEAD_REPEAT_PARAMETERS } from "../../../utils/constants/lead.constant"
import { EMAIL_TEMPLATE_TYPE } from "../../../utils/constants/emailTemplate.constant"

const CustomerView = ({ column = true, name, email, phone, lead_repeat_paramaters = [], projectId ,projectUID}) => {
    return <Box display="flex" flexDirection={column ? "column" : 'row'} >
        <Typography variant="subtitle2" lineHeight="100%">
            {viewLimitedLengthName(name, 15)}
        </Typography>
        <CenteredBox sx={{ justifyContent: "flex-start" }}>

            <Badge color="error" variant="dot" badgeContent={lead_repeat_paramaters?.includes(LEAD_REPEAT_PARAMETERS.EMAIL) ? '' : 0} >
            </Badge>
            <MailButton email={email} emailTemplateType={EMAIL_TEMPLATE_TYPE.LEAD} subjectSuffix={projectUID} projectId={projectId} iconProps={{ fontSize: "inherit" }} btnProps={{ size: "small", disabled: !email || email == '' }} />


            <CallButton phone={phone} disabled iconProps={{ fontSize: "inherit", color: !phone || phone == '' ? "disabled" : "primary" }} btnProps={{ size: "small", disabled: !phone || phone == '' }} />
            <Badge color="error" variant="dot" badgeContent={lead_repeat_paramaters?.includes(LEAD_REPEAT_PARAMETERS.PHONE) ? '' : 0} >

            </Badge>
        </CenteredBox>
    </Box>
}
export default CustomerView