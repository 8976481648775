import { useState } from "react"
import { useDispatch } from "react-redux"
import { closeModal, openModal } from "../../../store/actions/modalAction"
import { callApiAction } from "../../../store/actions/commonAction"

import MessageDilog from "../../../components/MessageDilog"
import { CircularProgress, IconButton, Tooltip } from "@mui/material"
import { Delete, FlashOn } from "@mui/icons-material"

const DeleteButton = ({ title = "", deletApi, id, deleted, apiParams = {}, onDelete = () => { } }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const deleteFun = async () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await deletApi({ id, deleted: !deleted, ...apiParams }),
            (response) => {

                setLoading(false)
                onDelete(id,!deleted)
            },
            (err) => {
                setLoading(false)
            }
        ))
        dispatch(closeModal())
    }
    const onDeleteBtnClick = () => {
        dispatch(openModal(<MessageDilog onSubmit={deleteFun} title="Alert!" message={`Are you sure to ${deleted ? 'activate' : 'delete'} "${title}" ?`} />, "sm"))
    }
    return <>

        {<>
            {loading && <CircularProgress color="primary" fontSize="inherit" />}
            {!loading && <Tooltip arrow  title={(deleted ? "Activate " : "Delete ") + title} >
                <IconButton disabled={loading} size="small" onClick={onDeleteBtnClick}>
                    {deleted ? <FlashOn color="success" fontSize="inherit" /> : <Delete color="error" fontSize="inherit" />}
                </IconButton>
            </Tooltip>}
        </>}
    </>
}
export default DeleteButton