import { Button, ButtonBase, Typography } from "@mui/material"
import { useDispatch } from "react-redux"
import { openModal } from "../../../store/actions/modalAction"
import LeadDetailedViewController from "../detailed-view/LeadDetailedViewController"
import { MODAL_KEYS } from "../../../utils/constants/modal.constant"


const LeadViewButton = ({ id, title,setParams,params,textProps={},...props }) => {
    const dispatch = useDispatch()

    const onClick = () => {
        dispatch(openModal(<LeadDetailedViewController onDataChange={(data)=>{
            if(setParams)
            setParams({...params,...data})
        }} project={id} />,'lg',undefined,MODAL_KEYS.LEAD_DETAILS))
    }
    return <ButtonBase size="small" onClick={onClick} {...props}>
        <Typography fontSize="inherit" sx={{ textDecoration: "underline", textUnderlineOffset: "2px" }} {...textProps} >
            {title}
        </Typography>
    </ButtonBase>
}
export default LeadViewButton