import { Box, IconButton, Tooltip } from "@mui/material"

import { useDispatch, useSelector } from "react-redux"
import { AddressButton, CallButton, FavouriteButton, MailButton, ScheduleCallButton } from "../../projects/projectdetails/buttons/ProjectIconButtons"
import EditButton from "../components/EditButton"
import OpenSolarButton from "../open-solar/OpenSolarButton"
import { EMAIL_TEMPLATE_TYPE } from "../../../utils/constants/emailTemplate.constant"
import { Star, StarBorderOutlined, WhatsApp } from "@mui/icons-material"

import DeleteLeadButton from "../delete/DeleteLeadButton"
import { USER_ROLES } from "../../../utils/constants/constants"
import { LEAD_STATUS } from "../../../utils/constants/lead.constant"
import ReactWhatsapp from "react-whatsapp"
import { moduleAccessCondition } from "../../../utils/helpers/helper"
import MODULES from "../../../utils/constants/module.constants"


const LeadIconButtons = ({ params, setParams, refreshProjectFun,isEditable }) => {
    const { user } = useSelector(state => state)

    

    return <Box sx={{ display: "flex", justifyContent: "space-around", flexWrap: "wrap" }} >
        <EditButton projectDetails={params} setProjectDetails={setParams} disableEdit={!isEditable} />

        <MailButton email={params.contacts?.[0]?.email} subjectSuffix={params?.uid} projectId={params?._id} emailTemplateType={EMAIL_TEMPLATE_TYPE.LEAD} />
        <CallButton phone={params.contacts?.[0]?.phone} />
        <ScheduleCallButton projectId={params._id} />
        <FavouriteButton disabled={!isEditable} favourite={params.is_favourite} project_id={params._id} callBack={() => { refreshProjectFun?.() }} />
        {/* <AddressButton lat={params.lat} lon={params?.lon} /> */}
        <OpenSolarButton disableEdit={!isEditable} projectDetails={params} setProjectDetails={setParams} projectId={params?._id} openSolarId={params?.third_party?.open_solar_project_id} callBack={() => { refreshProjectFun?.() }} />

        {/* <WhatsappShareButton title="Hii" separator=" " url={"+919377453770"} >

            <IconButton size="small" >
                <WhatsappIcon round size={20} />
            </IconButton>

        </WhatsappShareButton> */}
       {params.contacts?.[0]?.phone && <ReactWhatsapp number={params.contacts?.[0]?.phone} element="div" message="Hii" ><IconButton>
            <WhatsApp color="success" />
        </IconButton>
        </ReactWhatsapp>}
        <DeleteLeadButton id={params?._id} allowedDelete={ params.lead_status == LEAD_STATUS.UNHANDLED} onDelete={() => setParams(null)} projectDetails={params} />

    </Box >
}
export default LeadIconButtons