export const LEAD_FORMS_FIELDS = [
  {
    title: "First Name",
    field_name: "f_name",
    type: 'text',
    required:true,
    isDefault: true
  },
  {
    title: "Email",
    field_name: "email",
    type: 'email',
    required:true,
    isDefault: true
  },
  {
    title: "Phone",
    field_name: "phone",
    type: 'tel',
    required:true,
    isDefault: true
  },
  {
    title: "Middle Name",
    field_name: "m_name",
    type: 'text',
    
  },
  {
    title: "Last Name",
    field_name: "l_name",
    type: 'text',
    
  },
 
  {
    title: "Message",
    field_name: "remarks",
    type: 'textarea',      
  },
  {
    title: "Address",
    field_name: "address",
    type: 'textarea',
    
  },
  
]